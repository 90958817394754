import React from 'react'

const STATUS_COLORS = {
  CREADO: 'dark',
  EN_CREDITO: 'info',
  FINALIZADO: 'success',
  EN_SOLICITUD_DE_CREDITO: 'warning'
}

export const BadgeStatusCotAl = ({ status }) => {
  const color = STATUS_COLORS[status] || 'light'

  return (
    <span className={`badge badge-soft-${color} fs-11`} id='payment-status'>
      {status.replace(/_/g, ' ')}
    </span>
  )
}
