import React from 'react'
import { Badge } from 'reactstrap'
import { ICONS_STATUS, STATUS_CREDITS_OBJ } from '../../helpers/consts'

export const StatusOneCredit = ({ status }) => {
  const icon = ICONS_STATUS[STATUS_CREDITS_OBJ[status]] || [
    'mdi-information-outline',
    'dark'
  ]
  return (
    <div className='d-flex mt-1'>
      <div className='flex-shrink-0 avatar-xs align-self-center me-3'>
        <div
          className={`avatar-title bg-light rounded-circle fs-3 text-${icon[1]}`}
        >
          <i className={`mdi ${icon[0]}`} />
        </div>
      </div>
      <div className='flex-grow-1 overflow-hidden'>
        <p className='mb-1'>
          <strong>Estado:</strong>
        </p>
        <h6 className='text-truncate mb-0'>
          <Badge color={icon[1]}>{STATUS_CREDITS_OBJ[status] || status}</Badge>
        </h6>
      </div>
    </div>
  )
}
