import { useLazyQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap'
import classnames from 'classnames'

import { GET_CREDIT_DISBURSEMENT } from '../../../graphql/queries/getDisbursements'
import {
  GET_DOCS_CREDIT,
  GET_FORM_DATA
} from '../../../graphql/queries/getCredits'
import { toast } from 'react-toastify'
import { parseId } from '../../../helpers'
import { FormDataView } from '../creditPage/FormDataView'
import { TbodyInfoBasic } from './TbodyInfoBasic'
import { Link } from 'react-router-dom'
import { useGetUser } from '../../../hooks/user'
import { API } from '../../../graphql/client'
/**
 *
 * @param {{isOpen: boolean; onClose: ()=>void; idCredit: string}} props
 * @returns {JSX.Element}
 */
export const ModalViewCreditInfo = ({ isOpen, onClose, idCredit }) => {
  const [
    gqlGetCredit,
    { data: dataCredit, loading: loadingCredit, error: errorCredit }
  ] = useLazyQuery(GET_CREDIT_DISBURSEMENT)
  const [
    gqlGetForm,
    { data: dataForm, loading: loadingForm, error: errorForm }
  ] = useLazyQuery(GET_FORM_DATA)
  const [
    gqlGetDocs,
    { data: dataDocs, loading: loadingDocs, error: errorDocs }
  ] = useLazyQuery(GET_DOCS_CREDIT, {
    fetchPolicy: 'no-cache',
    variables: {
      creditId: parseId(idCredit)
    }
  })

  /** @type {[{node: {name: string, legibleName: string, id: string}}[], React.Dispatch<React.SetStateAction<{node: {}}[]>>]} */
  const [docs, setDocs] = useState([])

  useEffect(() => {
    if (!dataDocs || !dataDocs.listDocuments) return
    if (dataDocs.listDocuments.edges.length > 0) {
      setDocs(dataDocs.listDocuments.edges)
    }
  }, [dataDocs])

  useEffect(() => {
    if (errorDocs) {
      toast.error(`ERROR: ${errorDocs.message}`)
    }
  }, [errorDocs])

  const { user } = useGetUser()

  const [activeTab, setActiveTab] = useState('1')
  const [loadingExport, setLoadingExport] = useState(false)

  useEffect(() => {
    if (isOpen) {
      gqlGetCredit({
        variables: {
          creditId: parseId(idCredit)
        }
      })
      gqlGetForm({
        variables: {
          id: parseId(idCredit),
          codename: 'Credit'
        }
      })
      gqlGetDocs()
    }
  }, [isOpen])

  useEffect(() => {
    if (!errorCredit) return
    toast.error('Error: ' + errorCredit.message)
  }, [errorCredit])
  useEffect(() => {
    if (!errorForm) return
    toast.error('Error: ' + errorForm.message)
  }, [errorForm])

  const toggleTab = (tab) => {
    setActiveTab(tab)
  }
  const handleDownload = async (name, legibleName, creditId) => {
    if (!name || !creditId) return
    const { token } = user.tokenAuth
    if (!token) return
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    const requestOptions = {
      method: 'GET',
      headers: myHeaders
    }

    setLoadingExport(true)

    try {
      const res = await fetch(
        `${API}/export/${name}/${parseId(creditId)}`,
        requestOptions
      )
      if (!res.ok) throw new Error('Error al exportar')
      const blob = await res.blob()
      // console.log('blob', blob)
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `${legibleName}-${parseId(creditId)}.${blob.type.replace(
        'application/',
        ''
      )}`
      document.body.appendChild(link)
      setLoadingExport(false)
      link.click()
    } catch (err) {
      setLoadingExport(false)
      toast.error(`ERROR: ${err.message}`)
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} size='xl'>
      <ModalHeader toggle={onClose}>Crédito</ModalHeader>
      <ModalBody>
        {loadingCredit || loadingForm || dataForm == null ? (
          <div style={{ height: '40vh' }}>
            <Spinner />
          </div>
        ) : (
          <div>
            <Nav className='nav-tabs-custom border-bottom-0' role='tablist'>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === '1',
                    'fw-semibold': activeTab === '1'
                  })}
                  onClick={() => {
                    toggleTab('1')
                  }}
                  href='#'
                >
                  Información personal
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === '2',
                    'fw-semibold': activeTab === '2'
                  })}
                  onClick={() => {
                    toggleTab('2')
                  }}
                  href='#'
                >
                  Información de codeudores
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === '3',
                    'fw-semibold': activeTab === '3'
                  })}
                  onClick={() => {
                    toggleTab('3')
                  }}
                  href='#'
                >
                  Documentos
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className='text-muted'>
              <TabPane tabId='1'>
                <Table>
                  <TbodyInfoBasic
                    dateBirth={dataCredit.getCredit.dateBirth}
                    documentNumber={dataCredit.getCredit.documentNumber}
                    documentType={dataCredit.getCredit.documentType}
                    email={dataCredit.getCredit.email}
                    expeditionDate={dataCredit.getCredit.expeditionDate}
                    firstName={dataCredit.getCredit.firstName}
                    firstSurname={dataCredit.getCredit.firstSurname}
                    phoneNumber={dataCredit.getCredit.phoneNumber}
                    secondName={dataCredit.getCredit.secondName}
                    secondSurname={dataCredit.getCredit.secondSurname}
                  />
                </Table>
                {dataForm && dataForm.getEntityResponse?.length > 0 && (
                  <FormDataView
                    data={JSON.stringify(
                      dataForm.getEntityResponse.map((el) => JSON.parse(el))
                    )}
                  />
                )}
              </TabPane>

              <TabPane tabId='2'>
                {dataCredit.getCredit.cosignerSet.edges.length > 0 ? (
                  <div>
                    {dataCredit.getCredit.cosignerSet.edges.map(
                      ({ node }, idx) => (
                        <div key={node.id}>
                          <h6
                            className='fw-bold fs-4'
                            style={{ color: '#0833a2', marginTop: '10px' }}
                          >
                            Codeudor #{idx + 1}
                          </h6>
                          <div>
                            <Table>
                              <TbodyInfoBasic
                                dateBirth={node.dateBirth}
                                documentNumber={node.documentNumber}
                                documentType={node.documentType}
                                email={node.email}
                                expeditionDate={node.expeditionDate}
                                firstName={node.firstName}
                                firstSurname={node.firstSurname}
                                phoneNumber={node.phoneNumber}
                                secondName={node.secondName}
                                secondSurname={node.secondSurname}
                              />
                            </Table>
                            {node.formData && (
                              <FormDataView data={node.formData} />
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <div
                    style={{ height: '30vh' }}
                    className='d-flex align-items-center justify-content-center'
                  >
                    No hay codeudores registrados
                  </div>
                )}
              </TabPane>
              <TabPane tabId='3'>
                <div className='table-responsive'>
                  {loadingDocs && (
                    <div
                      className='d-flex align-items-center justify-content-center'
                      style={{ height: '20vh' }}
                    >
                      <Spinner />
                    </div>
                  )}
                  {!loadingDocs &&
                  docs.length > 0 &&
                  dataCredit?.getCredit?.closedDate ? (
                    <Table className='table-borderless align-middle mb-0'>
                      <thead className='table-light'>
                        <tr>
                          <th scope='col' style={{ width: '60px' }}>
                            #
                          </th>
                          <th scope='col'>Nombre</th>
                          <th scope='col' />
                        </tr>
                      </thead>
                      <tbody>
                        {docs.map(({ node }, idx) => (
                          <tr key={node.id}>
                            <td>{idx + 1 < 10 ? `0${idx + 1}` : idx}</td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='avatar-sm'>
                                  <div className='avatar-title bg-danger text-light rounded fs-36'>
                                    <i className='mdi mdi-file-document-outline' />
                                  </div>
                                </div>
                                <div className='ms-3 flex-grow-1'>
                                  <h6 className='fs-15 mb-0'>
                                    {node.legibleName}
                                  </h6>
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                className='border-0 p-0 m-0 bg-transparent'
                                onClick={() => {
                                  handleDownload(
                                    node.name,
                                    node.legibleName,
                                    dataCredit?.getCredit?.id
                                  )
                                }}
                              >
                                {loadingExport ? (
                                  <Spinner size='sm' color='dark' />
                                ) : (
                                  <i className='mdi mdi-download fs-24 text-secondary' />
                                )}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <div
                      className='d-flex align-items-center justify-content-center'
                      style={{ height: '30vh' }}
                    >
                      No hay documentos para este crédito
                    </div>
                  )}
                </div>
              </TabPane>
            </TabContent>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        {dataCredit?.getCredit && (
          <Link
            className='btn btn-soft-dark'
            to={`/credito/${parseId(dataCredit.getCredit.id)}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            Ver crédito
          </Link>
        )}
        <Button color='primary' onClick={onClose}>
          Aceptar
        </Button>
      </ModalFooter>
    </Modal>
  )
}
