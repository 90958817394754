import { useMutation } from '@apollo/client'
import React, { useEffect } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from 'reactstrap'
import { parseId } from '../../../helpers'
import { toast } from 'react-toastify'
import { ACTIVE_USER } from '../../../graphql/queries/getUsuarios'

export const ModalActiveUser = ({ isOpen, toggle, userInfo, refetch }) => {
  const [gqlActiveUser, { data, error, loading }] = useMutation(ACTIVE_USER)
  useEffect(() => {
    if (!error) return
    toast.error('Error: ' + error.message)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.activateUser) {
      if (data.activateUser.success) {
        toast.success(
          `Usuario ${
            userInfo.status ? 'desactivado' : 'activado'
          } correctamente`
        )
        toggle()
        refetch()
      } else if (data?.activateUser?.errors) {
        toast.error(
          'Error: ' + data.activateUser?.errors?.message ||
            'No se pudo actualizar usuario'
        )
      }
    }
  }, [data])

  const handleClick = () => {
    gqlActiveUser({
      variables: {
        input: {
          userId: parseId(userInfo.creditId),
          active: !userInfo.status
        }
      }
    })
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader className='p-4 bg-soft-info' toggle={toggle}>
        {userInfo.status ? 'Desactivar' : 'Activar'} usuario
      </ModalHeader>
      <ModalBody>
        ¿Seguro que desea {userInfo.status ? 'desactivar' : 'activar'} a{' '}
        <span style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
          {userInfo.name}
        </span>{' '}
        ?
      </ModalBody>
      <ModalFooter>
        <Button
          color='light'
          onClick={() => {
            toggle()
          }}
        >
          Cancelar
        </Button>
        <Button
          color={userInfo.status ? 'danger' : 'info'}
          disabled={loading}
          onClick={handleClick}
          className='d-flex align-items-center gap-1'
        >
          {loading && <Spinner color='light' size='sm' />}
          {userInfo.status ? 'Desactivar' : 'Activar'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
