import { gql } from '@apollo/client'

export const GET_ALL_FORMATS = gql`
  query listFormats(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    listFormats(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          name
          document
        }
      }
    }
  }
`
