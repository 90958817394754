import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap'
import { API } from '../../graphql/client'
import { useQuery } from '@apollo/client'
import {
  GET_AGENCIAS_TO_EXPORT,
  GET_CATEGORIES_TO_EXPORT
} from '../../graphql/queries/getProducts'
import { parseId } from '../../helpers'
import { useFormik } from 'formik'
import * as Yup from 'yup'

export const ModalExportProducts = ({
  modalExport,
  toggleModalExport,
  // openModalExport,
  closeModalExport
}) => {
  const { data: dataAgencies, loading: loadingAgencies } = useQuery(
    GET_AGENCIAS_TO_EXPORT,
    { fetchPolicy: 'network-only' }
  )
  const { data: dataCategories, loading: loadingCategories } = useQuery(
    GET_CATEGORIES_TO_EXPORT,
    { fetchPolicy: 'network-only' }
  )

  const [optionsAgencies, setOptionsAgencies] = useState([])
  const [optionsCategories, setOptionsCategories] = useState([])
  const [loadingExport, setLoadingExport] = useState(false)

  const handleClickFetch = async (category, agency) => {
    const token = JSON.parse(window.localStorage.getItem('token'))
    if (!token) return
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    myHeaders.append(
      'Cookie',
      'csrftoken=Od45hbfeAuJX7uEQBccJfgkXhva8KgvocUGB9FUlIlL5zavQTjre5fyld4D7CPt3'
    )

    const requestOptions = {
      method: 'GET',
      headers: myHeaders
    }

    setLoadingExport(true)

    try {
      const res = await fetch(
        `${API}/export/products/${category}/${agency}`,
        requestOptions
      )
      if (!res.ok) throw new Error('Error al exportar')
      const blob = await res.blob()
      // console.log('blob', blob)
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `productos-${new Date().toISOString().split('T')[0]}.xlsx`
      document.body.appendChild(link)
      setLoadingExport(false)
      link.click()
    } catch (err) {
      setLoadingExport(false)
      toast.error(`ERROR: ${err.message}`)
    }
  }

  const validation = useFormik({
    initialValues: {
      agency: '',
      category: ''
    },
    validationSchema: Yup.object({
      agency: Yup.string().required('Agencia requerida'),
      category: Yup.string().required('Categoría requerida')
    }),
    onSubmit: (values) => {
      // console.log('values', values)
      if (values.agency && values.category) {
        handleClickFetch(values.category, values.agency)
      }
    }
  })

  useEffect(() => {
    if (dataAgencies) {
      const arr = dataAgencies.listAgency.edges.map(({ node }) => ({
        label: node.name,
        value: parseId(node.id)
      }))
      setOptionsAgencies(arr)
    }
  }, [dataAgencies])

  useEffect(() => {
    if (dataCategories) {
      const arr = dataCategories.categoryList.edges.map(({ node }) => ({
        label: node.name,
        value: parseId(node.id)
      }))
      setOptionsCategories(arr)
    }
  }, [dataCategories])

  return (
    <Modal
      isOpen={modalExport}
      id='showModal'
      toggle={toggleModalExport}
      centered
      size='lg'
    >
      <ModalHeader>Exportar</ModalHeader>
      {loadingAgencies || loadingCategories ? (
        <Spinner />
      ) : (
        <Form
          className='tablelist-form'
          onSubmit={(e) => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <ModalBody>
            <input type='hidden' id='id-field' />
            <Row className='g-3'>
              <Col lg={12}>
                <div>
                  <Label htmlFor='name-field' className='form-label'>
                    Categoría
                  </Label>

                  <Input
                    name='category'
                    id='customername-field'
                    className='form-control'
                    placeholder='seleccione categoría'
                    type='select'
                    validate={{
                      required: { value: true }
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.category}
                    invalid={Boolean(
                      validation.touched.category && validation.errors.category
                    )}
                  >
                    <option value=''>Seleccione categoría</option>
                    {optionsCategories.map(({ label, value }) => (
                      <option key={label} value={value}>
                        {label}
                      </option>
                    ))}
                  </Input>
                  {validation.touched.category && validation.errors.category ? (
                    <FormFeedback type='invalid'>
                      {validation.errors.category}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={12}>
                <div>
                  <Label htmlFor='name-field' className='form-label'>
                    Agencia
                  </Label>

                  <Input
                    name='agency'
                    id='customername-field'
                    className='form-control'
                    placeholder='Seleccione agencia'
                    type='select'
                    validate={{
                      required: { value: true }
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.agency}
                    invalid={Boolean(
                      validation.touched.agency && validation.errors.agency
                    )}
                  >
                    <option value=''>Seleccione agencia</option>
                    {optionsAgencies.map(({ label, value }) => (
                      <option key={label} value={value}>
                        {label}
                      </option>
                    ))}
                  </Input>
                  {validation.touched.agency && validation.errors.agency ? (
                    <FormFeedback type='invalid'>
                      {validation.errors.agency}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className='hstack gap-2 justify-content-end'>
              <Button
                color='light'
                onClick={() => {
                  validation.resetForm()
                  closeModalExport()
                }}
              >
                Cancelar{' '}
              </Button>
              <Button
                type='submit'
                color='success'
                id='add-btn'
                disabled={loadingExport}
              >
                {loadingExport ? (
                  <Spinner size='sm' color='light' />
                ) : (
                  <i className='bx bx-download' />
                )}{' '}
                Descargar
              </Button>
            </div>
          </ModalFooter>
        </Form>
      )}
    </Modal>
  )
}
