import { gql } from '@apollo/client'

export const GET_ALL_NOTIFICATIONS = gql`
  query ($offset: Int, $isRead: Boolean) {
    listNotifications(offset: $offset, isRead: $isRead) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          extraData
          createDate
          isRead
        }
      }
    }
  }
`
export const GET_ALL_NOTIFICATIONS_PAGE = gql`
  query ($offset: Int) {
    listNotifications(offset: $offset) {
      pageInfo {
        hasNextPage
        startCursor
      }
      edges {
        node {
          id
          url
          extraData
          isRead
          createDate
        }
      }
    }
  }
`

export const GET_TOTAL_COUNT_NOTIFICATIONS = gql`
  query getTotCountNotifications {
    countNotifications
  }
`
