import React, { useState, useEffect, Fragment } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Spinner,
  TabContent,
  Table,
  TabPane,
  UncontrolledDropdown
} from 'reactstrap'
import classnames from 'classnames'
// import { Swiper, SwiperSlide } from 'swiper/react'
// import SwiperCore, { Autoplay } from 'swiper'
import { toast } from 'react-toastify'

// Images
/* import avatar6 from '../../../assets/images/users/avatar-6.jpg'
import avatar7 from '../../../assets/images/users/avatar-7.jpg'
import smallImage7 from '../../../assets/images/small/img-7.jpg'
import smallImage9 from '../../../assets/images/small/img-9.jpg'
import smallImage3 from '../../../assets/images/small/img-3.jpg'
import avatar8 from '../../../assets/images/users/avatar-8.jpg'
import smallImage4 from '../../../assets/images/small/img-4.jpg'
import smallImage5 from '../../../assets/images/small/img-5.jpg'
import smallImage6 from '../../../assets/images/small/img-6.jpg'
import avatar3 from '../../../assets/images/users/avatar-3.jpg'
import avatar2 from '../../../assets/images/users/avatar-2.jpg'
import avatar4 from '../../../assets/images/users/avatar-4.jpg'
import avatar5 from '../../../assets/images/users/avatar-5.jpg'
import smallImage2 from '../../../assets/images/small/img-2.jpg' */
import profileBg from '../../../assets/images/bg.png'
import avatar1 from '../../../assets/images/generic-moto.png'

import errorImg from '../../../assets/images/error.svg'

import { projects, document } from '../../../common/data'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  GET_CELLARS_MOTO_USADAS,
  GET_ONE_MOTO_USADA
} from '../../../graphql/queries/getMotosUsadas'
import { hasPermission, parseId, parsePrice } from '../../../helpers'
import moment from 'moment'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  CREATE_COMMENT_USED_MOTOS,
  CREATE_MOTO_USADA
} from '../../../graphql/mutations/motosUsadas'
// import CurrencyInput from 'react-currency-input-field'
import { useGetUser } from '../../../hooks/user'
import { ALL_PERMISSIONS, ERROR_MESSAGES } from '../../../helpers/consts'
import { InputCurrency } from '../../../Components/Own/InputCurrency'
import { ModalChangeCellar } from '../../../Components/Own/MotoUsada/ModalChangeCellar'

export default function OneMotoUsada() {
  const { id } = useParams()
  const [gqlGetUsedMoto, { data, loading, error, refetch }] = useLazyQuery(
    GET_ONE_MOTO_USADA,
    { fetchPolicy: 'network-only' }
  )
  const {
    data: dataCellar,
    loading: loadingCellar,
    error: errorCellar
  } = useQuery(GET_CELLARS_MOTO_USADAS)
  const navigate = useNavigate()

  const [
    gqlCreateUsedMoto,
    { data: dataCreate, loading: loadingCreate, error: errorCreate }
  ] = useMutation(CREATE_MOTO_USADA, { refetchQueries: [GET_ONE_MOTO_USADA] })

  const [
    gqlCreateComment,
    { data: dataComment, loading: loadingComment, error: errorComment }
  ] = useMutation(CREATE_COMMENT_USED_MOTOS, {
    refetchQueries: [GET_ONE_MOTO_USADA]
  })

  const { logout, permissions } = useGetUser()

  // SwiperCore.use([Autoplay])

  const [modal, setModal] = useState(false)
  const [modalComment, setModalComment] = useState(false)

  const [activeTab, setActiveTab] = useState('1')

  const [cellars, setCellars] = useState([])
  const [showCellar, setShowCellar] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (company && company.img) || '',
      trademark: '',
      line: '',
      model: '',
      color: '',
      plaque: '',
      chassis: '',
      engine: '',
      cellar: '',
      basePrice: '',
      forSale: false,
      salePrice: '',
      hasKey: false,
      haveOwnershipCard: true,
      hasSoat: false,
      hasTechnomechanics: false,
      soatExpirationDate: '',
      technomechanicsExpirationDate: '',
      vehicleDelivery: false,
      vehicleDeliveryDate: '',
      finesViolations: false,
      returnDate: '',
      ownerDocument: '',
      ownerName: '',
      taxDebt: 0,
      additionalDescription: '',
      status: ''
    },
    validationSchema: Yup.object({
      cellar: Yup.string().required('La bodega es obligatoria'),
      trademark: Yup.string().required('La marca es obligatoria'),
      line: Yup.string().required('La línea es obligatoria'),
      model: Yup.string().required('El modelo es obligatorio'),
      chassis: Yup.string().required('El chassis es obligatorio'),
      plaque: Yup.string().required('La placa es obligatoria'),
      color: Yup.string().required('El color es obligatorio'),
      engine: Yup.string().required('El motor es obligatorio'),
      status: Yup.string().required('Obligatorio')
    }),
    onSubmit: (values) => {
      // console.log('values', values)
      if (!id) return
      const newMotoUsed = { ...values, id }

      Object.keys(newMotoUsed).forEach((key) => {
        if (key !== 'additionalDescription') {
          newMotoUsed[key] =
            newMotoUsed[key] === '' || newMotoUsed[key] === undefined
              ? undefined
              : newMotoUsed[key]
        }
      })
      // console.log('newMotoUsed', newMotoUsed)

      gqlCreateUsedMoto({
        variables: {
          input: newMotoUsed
        },
        refetchQueries: [GET_ONE_MOTO_USADA]
      })
    }
  })

  const formComment = useFormik({
    initialValues: {
      comment: ''
    },
    validationSchema: Yup.object({
      comment: Yup.string().required('Campo obligatorio')
    }),
    onSubmit: ({ comment }) => {
      if (Number(id) && comment) {
        const input = {
          comment,
          usedMotorcycle: id
        }
        gqlCreateComment({
          variables: { input },
          refetchQueries: [GET_ONE_MOTO_USADA]
        })
      }
    }
  })

  useEffect(() => {
    if (!id || !Number(id)) {
      navigate('/')
      return
    }
    gqlGetUsedMoto({
      variables: {
        id
      }
    })
  }, [id])

  useEffect(() => {
    if (
      !hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_MOTOCICLETA_USUADA)
    ) {
      navigate('/')
    }
  }, [permissions])

  useEffect(() => {
    if (data) {
      if (data.listUsedMotorcycle.edges.length > 0) {
        const { node } = data.listUsedMotorcycle.edges[0]
        const {
          additionalDescription,
          basePrice,
          chassis,
          color,
          engine,
          finesViolations,
          forSale,
          hasKey,
          hasSoat,
          hasTechnomechanics,
          haveOwnershipCard,
          line,
          cellar,
          model,
          ownerDocument,
          ownerName,
          plaque,
          returnDate,
          salePrice,
          soatExpirationDate,
          status,
          taxDebt,
          technomechanicsExpirationDate,
          trademark,
          vehicleDelivery,
          vehicleDeliveryDate
        } = node
        validation.setValues({
          additionalDescription,
          basePrice,
          chassis,
          color,
          engine,
          finesViolations,
          forSale,
          hasKey,
          hasSoat,
          hasTechnomechanics,
          haveOwnershipCard,
          line,
          cellar: parseId(cellar.id),
          model,
          ownerDocument,
          ownerName,
          plaque,
          returnDate,
          salePrice,
          soatExpirationDate,
          status: status.replace(/_/g, ' '),
          taxDebt,
          technomechanicsExpirationDate,
          trademark,
          vehicleDelivery,
          vehicleDeliveryDate
        })
      }
    }
  }, [data])

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error.message}`)
      if (error.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [error])

  useEffect(() => {
    if (dataCreate) {
      if (dataCreate.usedMotorcycleCreateUpdate.usedMotorcycle) {
        toast.success('Moto actualizada correctamente')
        setModal(false)
        validation.resetForm()
        return
      }
      if (dataCreate.usedMotorcycleCreateUpdate.errors.length > 0) {
        toast.error(
          `ERROR! ${dataCreate.usedMotorcycleCreateUpdate?.errors[0]?.messages[0]}`
        )
      }
    }
  }, [dataCreate])
  useEffect(() => {
    if (errorCreate) {
      toast.error(`ERROR! ${errorCreate.message}`)
      if (errorCreate.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorCreate])
  useEffect(() => {
    if (dataComment) {
      if (dataComment.usedMotorcycleComment.comment) {
        toast.success('Comentario añadido correctamente')
        setModalComment(false)
        formComment.resetForm()
        return
      }
      if (dataComment.usedMotorcycleComment.errors.length > 0) {
        toast.error(
          `ERROR! ${dataComment.usedMotorcycleComment?.errors[0]?.messages[0]}`
        )
      }
    }
  }, [dataComment])
  useEffect(() => {
    if (errorComment) {
      toast.error(`ERROR! ${errorComment.message}`)
    }
  }, [errorComment])

  useEffect(() => {
    if (!dataCellar || !dataCellar.listCellar) return
    if (dataCellar.listCellar.length > 0) {
      setCellars(dataCellar.listCellar)
    }
  }, [dataCellar])

  useEffect(() => {
    if (errorCellar) {
      toast.error(`ERROR! ${errorCellar.message}`)
      if (errorCellar.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorCellar])

  const selectAllToggle = (e) => {
    validation.setFieldValue('forSale', e.target.checked)
    validation.setFieldValue('hasKey', e.target.checked)
    validation.setFieldValue('haveOwnershipCard', e.target.checked)
    validation.setFieldValue('hasSoat', e.target.checked)
    validation.setFieldValue('hasTechnomechanics', e.target.checked)
    validation.setFieldValue('vehicleDelivery', e.target.checked)
    validation.setFieldValue('finesViolations', e.target.checked)
  }

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const getColor = (type) => {
    switch (type) {
      case 'EN_INVENTARIO':
        return 'primary'
      case 'NOTIFICADA':
        return 'secondary'
      case 'VENDIDA':
        return 'success'
      case 'ENTREGADA_AL_CLIENTE':
        return 'info'
      default:
        return 'light'
    }
  }

  const toggle = () => {
    setModal((prev) => !prev)
  }
  const toggleComment = () => {
    setModalComment((prev) => !prev)
  }
  const toggleCellar = () => {
    setShowCellar(false)
  }

  document.title = 'Moto usada | TOTAL SALES'

  if (loading) {
    return (
      <div className='page-content'>
        <Container fluid>
          <div
            className='profile-foreground position-relative mx-n4 mt-n4'
            aria-hidden='true'
          >
            <div className='profile-wid-bg'>
              <img src={profileBg} alt='' className='profile-wid-img' />
            </div>
          </div>
          <div className='pt-4 mb-4 mb-lg-3 pb-lg-4'>
            <Row className='g-4'>
              <div className='col-auto'>
                <div className='avatar-lg'>
                  <img
                    src='https://dev-to-uploads.s3.amazonaws.com/i/mrvsmk2pl3l8fwocbfhy.gif'
                    alt='user-img'
                    className='img-thumbnail rounded-circle'
                  />
                </div>
              </div>

              <Col className='col-6'>
                <div className='p-2'>
                  <h3 className='text-white mb-1 placeholder-glow'>
                    <span className='placeholder col-6' />
                  </h3>
                  <p className='text-white-75 placeholder-glow'>
                    <span className='placeholder col-7' />
                  </p>
                  <div className='hstack text-white-50 gap-1'>
                    <div className='me-2 placeholder-glow'>
                      <span className='placeholder col-8' />
                    </div>
                    <div className='placeholder-glow'>
                      <span className='placeholder col-4' />
                    </div>
                  </div>
                </div>
              </Col>

              <Col md={12} lg={4} className='order-last order-lg-0'>
                <Row className='text text-white-50 text-center'>
                  <Col lg={6} xs={4}>
                    <div className='p-2'>
                      <h4 className='text-white mb-1 placeholder-glow'>
                        <span className='placeholder col-5' />
                      </h4>
                      <p className='fs-14 mb-0 placeholder-glow'>
                        <span className='placeholder col-3' />
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Row>
            <Col lg={12}>
              <div>
                <div className='d-flex gap-2 gap-lg-3 flex-grow-1 mt-4'>
                  <div style={{ width: '200px' }}>
                    <h1 className='placeholder-glow'>
                      <span className='placeholder col-8 bg-light' />
                    </h1>
                  </div>
                  <div style={{ width: '200px' }}>
                    <h1 className='placeholder-glow'>
                      <span className='placeholder col-8 bg-light' />
                    </h1>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className='mt-2'>
              <Card>
                <CardBody>
                  <h5 className='card-title mb-5 placeholder-glow'>
                    <span className='placeholder col-4' />
                  </h5>
                  <div
                    className='d-flex align-items-center justify-content-center'
                    style={{ height: '30vh' }}
                  >
                    <Spinner
                      style={{
                        height: '3rem',
                        width: '3rem'
                      }}
                      color='dark'
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  if (!data) return null

  if (data.listUsedMotorcycle.edges.length === 0) {
    return (
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col className='mt-2'>
              <Card>
                <CardBody>
                  <h5 className='card-title mb-5'>Motocicleta no encontrada</h5>
                  <div
                    className='d-flex align-items-center justify-content-center flex-column'
                    // style={{ height: '30vh' }}
                  >
                    <div style={{ width: '40%' }}>
                      <img
                        src={errorImg}
                        alt=''
                        className='error-basic-img move-animation'
                      />
                    </div>
                    <div>
                      <Link
                        to='/motocicletas-usadas'
                        className='btn btn-primary'
                      >
                        Ver Motos usadas
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  /**
   * @typedef {Object} InfoItem
   * @property {string} additionalDescription
   * @property {string} basePrice
   * @property {string} chassis
   * @property {string} color
   * @property {string} engine
   * @property {string} id
   * @property {string} line
   * @property {string} location
   * @property {string} model
   * @property {string} ownerName
   * @property {string} ownerDocument
   * @property {string} plaque
   * @property {string} returnDate
   * @property {string} soatExpirationDate
   * @property {string} status
   * @property {string} trademark
   * @property {string} salePrice
   * @property {string} technomechanicsExpirationDate
   * @property {boolean} active
   * @property {boolean} finesViolations
   * @property {boolean} forSale
   * @property {boolean} hasKey
   * @property {boolean} hasSoat
   * @property {{id: string, name: string, city: string}} cellar
   * @property {hasTechnomechanics} boolean
   * @property {haveOwnershipCard} boolean
   * @property {vehicleDelivery} boolean
   * @property {{id: string, name: string}} commercialCompany
   */
  /** @type {InfoItem} */

  const oneMoto = data.listUsedMotorcycle.edges[0].node
  /** @type {{node: {id: string, created: string, comment: string, user:{firstName: string, lastName: string}}}[]} */
  const comments =
    data.listUsedMotorcycle.edges[0].node.usedmotorcyclecommentSet.edges

  /** @type {{date: string, comments: {comment: string, date: string, name: string}[]}[]} */
  const orderComments = comments.reduce((prev, current) => {
    const date = moment(current.node.created).format('DD MM YY')
    const dateFind = prev.find(({ date: datePrev }) => datePrev === date)

    if (dateFind) {
      dateFind.comments.push({
        comment: current.node.comment,
        date: current.node.created,
        name: current.node.user.firstName + ' ' + current.node.user.lastName
      })
    } else {
      prev.push({
        date,
        comments: [
          {
            comment: current.node.comment,
            date: current.node.created,
            name: current.node.user.firstName + ' ' + current.node.user.lastName
          }
        ]
      })
    }
    return prev
  }, [])

  return (
    <div className='page-content'>
      <Container fluid>
        <div className='profile-foreground position-relative mx-n4 mt-n4'>
          <div className='profile-wid-bg'>
            <img src={profileBg} alt='' className='profile-wid-img' />
          </div>
        </div>
        <div className='pt-4 mb-4 mb-lg-3 pb-lg-4'>
          <Row className='g-4'>
            <div className='col-auto'>
              <div className='avatar-lg'>
                <img
                  src={avatar1}
                  alt='user-img'
                  className='img-thumbnail rounded-circle'
                />
              </div>
            </div>

            <Col>
              <div className='p-2'>
                <h3 className='text-white mb-1'>{`${oneMoto.trademark} ${oneMoto.line} ${oneMoto.model}`}</h3>
                {oneMoto?.commercialCompany?.name && (
                  <p className='text-white-75 fw-bold'>
                    {oneMoto?.commercialCompany?.name}
                  </p>
                )}
                <div className='hstack text-white-50 gap-1'>
                  <div className='me-2 d-flex align-items-center'>
                    <i className='ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle' />
                    {oneMoto?.cellar?.name || 'N/A'}
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={12} className='col-lg-auto order-last order-lg-0'>
              <Row className='text text-white-50 text-center'>
                <Col lg={12}>
                  <div className='p-2'>
                    <h4 className='text-white mb-1'>
                      {parsePrice(oneMoto.basePrice)}
                    </h4>
                    <p className='fs-14 mb-0'>Precio base</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <Row>
          <Col lg={12}>
            <div>
              <div className='d-flex'>
                <Nav
                  pills
                  className='animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1'
                  role='tablist'
                >
                  <NavItem>
                    <NavLink
                      className={classnames(
                        { active: activeTab === '1' },
                        'cursor-pointer'
                      )}
                      onClick={() => {
                        toggleTab('1')
                      }}
                    >
                      <i className='ri-airplay-fill d-inline-block d-md-none' />{' '}
                      <span className='d-none d-md-inline-block'>
                        Información
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames(
                        { active: activeTab === '2' },
                        'cursor-pointer'
                      )}
                      onClick={() => {
                        toggleTab('2')
                      }}
                    >
                      <i className='ri-list-unordered d-inline-block d-md-none' />{' '}
                      <span className='d-none d-md-inline-block'>
                        Comentarios
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
                {hasPermission(
                  permissions,
                  ALL_PERMISSIONS.PUEDE_CAMBIAR_MOTOCICLETA_USUADA
                ) && (
                  <div className='flex-shrink-0'>
                    <button
                      to='/pages-profile-settings'
                      className='btn btn-success'
                      onClick={() => setModal(true)}
                    >
                      <i className='ri-edit-box-line align-bottom' /> Editar
                      Moto
                    </button>
                  </div>
                )}
              </div>

              <TabContent activeTab={activeTab} className='pt-4'>
                <TabPane tabId='1'>
                  <Row>
                    <Col xxl={4}>
                      <Card>
                        <CardBody>
                          <p className='card-title mb-1'>Placa</p>
                          <h2 className='fs-1 text-center text-uppercase'>
                            {oneMoto.plaque}
                          </h2>
                        </CardBody>
                      </Card>

                      <Card>
                        <CardBody>
                          <h5 className='card-title mb-3'>
                            Información propietario
                          </h5>
                          <div className='table-responsive'>
                            <Table className='table-borderless mb-0'>
                              <tbody>
                                <tr>
                                  <th className='ps-0' scope='row'>
                                    Nombre:
                                  </th>
                                  <td className='text-muted'>
                                    {oneMoto.ownerName}
                                  </td>
                                </tr>
                                <tr>
                                  <th className='ps-0' scope='row'>
                                    Cédula:
                                  </th>
                                  <td className='text-muted'>
                                    {oneMoto.ownerDocument}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </CardBody>
                      </Card>

                      <Card>
                        <CardBody>
                          <h5 className='card-title mb-4'>Estado</h5>
                          <div className='d-flex flex-wrap gap-2'>
                            <Alert color={getColor(oneMoto.status)}>
                              {oneMoto.status.replace('_', ' ')}
                            </Alert>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xxl={8}>
                      <Card>
                        <CardBody>
                          <h5 className='card-title mb-3'>
                            Información básica
                          </h5>
                          <Table>
                            <tbody>
                              <tr>
                                <th scope='row' style={{ width: '200px' }}>
                                  Chasis
                                </th>
                                <td>{oneMoto.chassis}</td>
                              </tr>
                              <tr>
                                <th scope='row'>Modelo</th>
                                <td>{oneMoto.model}</td>
                              </tr>
                              <tr>
                                <th scope='row'>Color</th>
                                <td>{oneMoto.color}</td>
                              </tr>
                              <tr>
                                <th scope='row'>Linea</th>
                                <td>{oneMoto.line}</td>
                              </tr>
                              <tr>
                                <th scope='row'>Motor</th>
                                <td>{oneMoto.engine}</td>
                              </tr>
                              <tr>
                                <th scope='row'>Ubicación</th>
                                <td>
                                  {oneMoto.cellar.name} - {oneMoto.cellar.city}
                                </td>
                                {hasPermission(
                                  permissions,
                                  ALL_PERMISSIONS.PUEDE_CAMBIAR_MOTOCICLETA_USUADA
                                ) && (
                                  <td>
                                    <Button
                                      color='soft-danger'
                                      className='d-flex align-items-center gap-1'
                                      onClick={() => setShowCellar(true)}
                                    >
                                      <i className='mdi mdi-alert' />
                                      Cambiar
                                    </Button>
                                  </td>
                                )}
                              </tr>
                              <tr>
                                <th scope='row'>Precio base</th>
                                <td>{parsePrice(oneMoto.basePrice)}</td>
                              </tr>
                              <tr>
                                <th scope='row'>Precio de venta</th>
                                <td>{parsePrice(oneMoto.salePrice)}</td>
                              </tr>
                              <tr>
                                <th scope='row'>Venta</th>
                                <td>
                                  <span
                                    className={`badge badge-soft-${
                                      oneMoto.forSale ? 'info' : 'dark'
                                    } fs-6`}
                                  >
                                    {oneMoto.forSale ? 'SI' : 'NO'}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th scope='row'>Llaves</th>
                                <td>
                                  <span
                                    className={`badge badge-soft-${
                                      oneMoto.hasKey ? 'success' : 'danger'
                                    } fs-6`}
                                  >
                                    {oneMoto.hasKey ? 'SI' : 'NO'}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th scope='row'>Tarjeta de propiedad</th>
                                <td>
                                  <span
                                    className={`badge badge-soft-${
                                      oneMoto.haveOwnershipCard
                                        ? 'success'
                                        : 'danger'
                                    } fs-6`}
                                  >
                                    {oneMoto.haveOwnershipCard ? 'SI' : 'NO'}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th scope='row'>SOAT</th>
                                <td>
                                  <span
                                    className={`badge badge-soft-${
                                      oneMoto.hasSoat ? 'success' : 'danger'
                                    } fs-6`}
                                  >
                                    {oneMoto.hasSoat ? 'SI' : 'NO'}
                                  </span>
                                </td>
                              </tr>
                              {oneMoto.soatExpirationDate && (
                                <tr>
                                  <th scope='row'>SOAT</th>
                                  <td>
                                    {moment(oneMoto.soatExpirationDate).format(
                                      'DD MMM [del] YYYY'
                                    )}
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <th scope='row'>Tecnomecánica</th>
                                <td>
                                  <span
                                    className={`badge badge-soft-${
                                      oneMoto.hasTechnomechanics
                                        ? 'success'
                                        : 'danger'
                                    } fs-6`}
                                  >
                                    {oneMoto.hasTechnomechanics ? 'SI' : 'NO'}
                                  </span>
                                </td>
                              </tr>
                              {oneMoto.technomechanicsExpirationDate && (
                                <tr>
                                  <th scope='row'>Vencimiento Tecno.</th>
                                  <td>
                                    {moment(
                                      oneMoto.technomechanicsExpirationDate
                                    ).format('DD MMM [del] YYYY')}
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <th scope='row'>Infracciones</th>
                                <td>
                                  <span
                                    className={`badge badge-soft-${
                                      oneMoto.finesViolations
                                        ? 'danger'
                                        : 'success'
                                    } fs-6`}
                                  >
                                    {oneMoto.finesViolations ? 'SI' : 'NO'}
                                  </span>
                                </td>
                              </tr>
                              {oneMoto.taxDebt && (
                                <tr>
                                  <th scope='row'>Valor multas</th>
                                  <td>{parsePrice(oneMoto.taxDebt)}</td>
                                </tr>
                              )}
                              <tr>
                                <th scope='row'>Vehículo entregado</th>
                                <td>
                                  <span
                                    className={`badge badge-soft-${
                                      oneMoto.vehicleDelivery
                                        ? 'success'
                                        : 'info'
                                    } fs-6`}
                                  >
                                    {oneMoto.vehicleDelivery ? 'SI' : 'NO'}
                                  </span>
                                </td>
                              </tr>
                              {oneMoto.returnDate && (
                                <tr>
                                  <th scope='row'>Fecha vehículo entregado</th>
                                  <td>
                                    {moment(oneMoto.returnDate).format(
                                      'DD MMM [del] YY'
                                    )}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </CardBody>
                      </Card>

                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardHeader className='align-items-center d-flex'>
                              <h4 className='card-title mb-0  me-2'>
                                Descripción adicional
                              </h4>
                            </CardHeader>
                            <CardBody>
                              <p>{oneMoto.additionalDescription || 'N/R'}</p>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId='2'>
                  <Card style={{ backgroundColor: '#f3f3f9' }}>
                    <CardBody>
                      <div className='d-flex justify-content-between align-items-center my-4'>
                        <h5 className='card-title'>Comentarios</h5>
                        <div>
                          <Button
                            color='info'
                            onClick={() => setModalComment(true)}
                          >
                            Añadir comentario
                          </Button>
                        </div>
                      </div>
                      <div className='timeline-2'>
                        {orderComments.map(({ date, comments }, i) => (
                          <Fragment key={`${date}-${i}`}>
                            <div className='timeline-year'>
                              <p>{date.replace(/\s+/g, '/')}</p>
                            </div>
                            <div className='timeline-continue'>
                              {comments.map(
                                ({ comment, date: dateComment, name }) => (
                                  <Row
                                    className='timeline-right'
                                    key={dateComment}
                                  >
                                    <Col xs={12}>
                                      <p className='timeline-date'>
                                        {moment(dateComment).format('hh:mm A')}
                                      </p>
                                    </Col>
                                    <Col xs={12}>
                                      <div className='timeline-box'>
                                        <div className='timeline-text'>
                                          <div className='d-flex'>
                                            <div className='flex-grow-1 ms-3'>
                                              <h6 className='m-0 p-0'>
                                                <i className='mdi mdi-account-circle-outline me-2' />
                                                {name}
                                              </h6>
                                              <p className='text-muted mb-0'>
                                                {comment}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                )
                              )}
                              {/*  <Row className='timeline-right'>
                                <Col xs={12}>
                                  <p className='timeline-date'>
                                    02:35AM to 04:30PM
                                  </p>
                                </Col>
                                <Col xs={12}>
                                  <div className='timeline-box'>
                                    <div className='timeline-text'>
                                      <h5>Trip planning</h5>
                                      <p className='text-muted'>
                                        In the trip planner, keep only one or
                                        two activities in a day if the purpose
                                        of the trip is to relax and take it easy
                                        during the vacation :
                                      </p>
                                      <Row className='border border-dashed rounded gx-2 p-2'>
                                        <Col xs={3}>
                                          <Link to='#'>
                                            <img
                                              src={smallImage2}
                                              alt=''
                                              className='img-fluid rounded'
                                            />
                                          </Link>
                                        </Col>
                                        <Col xs={3}>
                                          <Link to='#'>
                                            <img
                                              src={smallImage2}
                                              alt=''
                                              className='img-fluid rounded'
                                            />
                                          </Link>
                                        </Col>
                                        <Col xs={3}>
                                          <Link to='#'>
                                            <img
                                              src={smallImage2}
                                              alt=''
                                              className='img-fluid rounded'
                                            />
                                          </Link>
                                        </Col>
                                        <Col xs={3}>
                                          <Link to='#'>
                                            <img
                                              src={smallImage2}
                                              alt=''
                                              className='img-fluid rounded'
                                            />
                                          </Link>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row className='timeline-right'>
                                <Col xs={12}>
                                  <p className='timeline-date'>
                                    02:35AM to 04:30PM
                                  </p>
                                </Col>
                                <Col xs={12}>
                                  <div className='timeline-box'>
                                    <div className='timeline-text'>
                                      <h5>Velzon - Project Discussion</h5>
                                      <p className='text-muted mb-0'>
                                        The purpose of the discussion is to
                                        interpret and describe the significance
                                        of your findings in light of what was
                                        already known about the research problem
                                        being investigated, and to explain any
                                        new understanding or fresh insights
                                        about the problem after you've taken the
                                        findings into consideration.
                                      </p>
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row className='timeline-right'>
                                <Col xs={12}>
                                  <p className='timeline-date'>
                                    10:24AM to 11:15PM
                                  </p>
                                </Col>
                                <Col xs={12}>
                                  <div className='timeline-box'>
                                    <div className='timeline-text'>
                                      <h5>Client Meeting (Nancy Martino)</h5>
                                      <p className='text-muted mb-0'>
                                        A client meeting, meaning, direct
                                        collaboration and communication with a
                                        customer, is the best way to understand
                                        their needs and how you can help support
                                        them.
                                      </p>
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row className='timeline-right'>
                                <Col xs={12}>
                                  <p className='timeline-date'>
                                    9:20AM to 10:05PM
                                  </p>
                                </Col>
                                <Col xs={12}>
                                  <div className='timeline-box'>
                                    <div className='timeline-text'>
                                      <h5>Designer & Developer Meeting</h5>
                                      <ul className='mb-0 text-muted vstack gap-2'>
                                        <li>Last updates</li>
                                        <li>Weekly Planning</li>
                                        <li>Work Distribution</li>
                                      </ul>
                                    </div>
                                  </div>
                                </Col>
                              </Row> */}
                              {i + 1 === orderComments.length && (
                                <Row className='timeline-right'>
                                  <div className='timeline-year'>
                                    <p>Inicio</p>
                                  </div>
                                </Row>
                              )}
                            </div>
                          </Fragment>
                        ))}
                        {comments.length === 0 && (
                          <>
                            <div className='timeline-right'>
                              <div className='timeline-year'>
                                <p>{moment().format('DD/MM/YY')}</p>
                              </div>
                              <div className='timeline-box'>
                                <div className='timeline-text'>
                                  <h5>Ups! 🤔</h5>
                                  <p className='text-muted mb-0'>
                                    No se registra comentarios para{' '}
                                    <span className='text-capitalize'>
                                      "{oneMoto.trademark} {oneMoto.line}"
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div className='timeline-year'>
                                <p>FIN</p>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </TabPane>

                <TabPane tabId='3'>
                  <Card>
                    <CardBody>
                      <Row>
                        {(projects || []).map((item, key) => (
                          <Col xxl={3} sm={6} key={key}>
                            <Card
                              className={`profile-project-card shadow-none profile-project-${item.cardBorderColor}`}
                            >
                              <CardBody className='p-4'>
                                <div className='d-flex'>
                                  <div className='flex-grow-1 text-muted overflow-hidden'>
                                    <h5 className='fs-14 text-truncate'>
                                      <Link to='#' className='text-dark'>
                                        {item.title}
                                      </Link>
                                    </h5>
                                    <p className='text-muted text-truncate mb-0'>
                                      Last Update :{' '}
                                      <span className='fw-semibold text-dark'>
                                        {item.updatedTime}
                                      </span>
                                    </p>
                                  </div>
                                  <div className='flex-shrink-0 ms-2'>
                                    <div
                                      className={`badge badge-soft-${item.badgeClass} fs-10`}
                                    >
                                      {item.badgeText}
                                    </div>
                                  </div>
                                </div>

                                <div className='d-flex mt-4'>
                                  <div className='flex-grow-1'>
                                    <div className='d-flex align-items-center gap-2'>
                                      <div>
                                        <h5 className='fs-12 text-muted mb-0'>
                                          Members :
                                        </h5>
                                      </div>
                                      <div className='avatar-group'>
                                        {(item.member || []).map(
                                          (subitem, key) => (
                                            <div
                                              className='avatar-group-item'
                                              key={key}
                                            >
                                              <div className='avatar-xs'>
                                                <img
                                                  src={subitem.img}
                                                  alt=''
                                                  className='rounded-circle img-fluid'
                                                />
                                              </div>
                                            </div>
                                          )
                                        )}

                                        {(item.memberName || []).map(
                                          (element, key) => (
                                            <div
                                              className='avatar-group-item'
                                              key={key}
                                            >
                                              <div className='avatar-xs'>
                                                <div className='avatar-title rounded-circle bg-light text-primary'>
                                                  {element.memberText}
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                        <Col lg={12}>
                          <Pagination
                            listClassName='justify-content-center'
                            className='pagination-separated mb-0'
                          >
                            <PaginationItem disabled>
                              {' '}
                              <PaginationLink to='#'>
                                {' '}
                                <i className='mdi mdi-chevron-left' />{' '}
                              </PaginationLink>{' '}
                            </PaginationItem>
                            <PaginationItem active>
                              {' '}
                              <PaginationLink to='#'> 1 </PaginationLink>{' '}
                            </PaginationItem>
                            <PaginationItem>
                              {' '}
                              <PaginationLink to='#'> 2 </PaginationLink>{' '}
                            </PaginationItem>
                            <PaginationItem>
                              {' '}
                              <PaginationLink to='#'> 3 </PaginationLink>{' '}
                            </PaginationItem>
                            <PaginationItem>
                              {' '}
                              <PaginationLink to='#'> 4 </PaginationLink>{' '}
                            </PaginationItem>
                            <PaginationItem>
                              {' '}
                              <PaginationLink to='#'> 5 </PaginationLink>{' '}
                            </PaginationItem>
                            <PaginationItem>
                              {' '}
                              <PaginationLink to='#'>
                                {' '}
                                <i className='mdi mdi-chevron-right' />{' '}
                              </PaginationLink>{' '}
                            </PaginationItem>
                          </Pagination>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </TabPane>

                <TabPane tabId='4'>
                  <Card>
                    <CardBody>
                      <div className='d-flex align-items-center mb-4'>
                        <h5 className='card-title flex-grow-1 mb-0'>
                          Documents
                        </h5>
                        <div className='flex-shrink-0'>
                          <Input
                            className='form-control d-none'
                            type='file'
                            id='formFile'
                          />
                          <Label htmlFor='formFile' className='btn btn-danger'>
                            <i className='ri-upload-2-fill me-1 align-bottom' />{' '}
                            Upload File
                          </Label>
                        </div>
                      </div>
                      <Row>
                        <Col lg={12}>
                          <div className='table-responsive'>
                            <Table className='table-borderless align-middle mb-0'>
                              <thead className='table-light'>
                                <tr>
                                  <th scope='col'>File Name</th>
                                  <th scope='col'>Type</th>
                                  <th scope='col'>Size</th>
                                  <th scope='col'>Upload Date</th>
                                  <th scope='col'>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(document || []).map((item, key) => (
                                  <tr key={key}>
                                    <td>
                                      <div className='d-flex align-items-center'>
                                        <div className='avatar-sm'>
                                          <div
                                            className={`avatar-title bg-soft-${item.iconBackgroundClass} text-${item.iconBackgroundClass} rounded fs-20`}
                                          >
                                            <i className={item.icon} />
                                          </div>
                                        </div>
                                        <div className='ms-3 flex-grow-1'>
                                          <h6 className='fs-15 mb-0'>
                                            <Link to='#'>{item.fileName}</Link>
                                          </h6>
                                        </div>
                                      </div>
                                    </td>
                                    <td>{item.fileType}</td>
                                    <td>{item.fileSize}</td>
                                    <td>{item.updatedDate}</td>
                                    <td>
                                      <UncontrolledDropdown direction='start'>
                                        <DropdownToggle
                                          tag='a'
                                          className='btn btn-light btn-icon'
                                          id='dropdownMenuLink15'
                                          role='button'
                                        >
                                          <i className='ri-equalizer-fill' />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <DropdownItem>
                                            <i className='ri-eye-fill me-2 align-middle text-muted' />
                                            View
                                          </DropdownItem>
                                          <DropdownItem>
                                            <i className='ri-download-2-fill me-2 align-middle text-muted' />
                                            Download
                                          </DropdownItem>
                                          <DropdownItem divider />
                                          <DropdownItem>
                                            <i className='ri-delete-bin-5-line me-2 align-middle text-muted' />
                                            Delete
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                          <div className='text-center mt-3'>
                            <Link to='#' className='text-success '>
                              <i className='mdi mdi-loading mdi-spin fs-20 align-middle me-2' />
                              Load more{' '}
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </TabPane>
              </TabContent>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal id='showModal' isOpen={modal} toggle={toggle} centered size='lg'>
        <ModalHeader className='bg-soft-info p-3' toggle={toggle}>
          Editar motocicleta
        </ModalHeader>
        <Form
          className='tablelist-form'
          onSubmit={(e) => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <ModalBody>
            <input type='hidden' id='id-field' />
            <Row className='g-3'>
              <Col lg={6}>
                <div>
                  <Label htmlFor='name-field' className='form-label'>
                    Marca
                  </Label>

                  <Input
                    name='trademark'
                    id='customername-field'
                    className='form-control'
                    placeholder='Marca...'
                    type='text'
                    validate={{
                      required: { value: true }
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.trademark}
                    invalid={
                      !!(
                        validation.touched.trademark &&
                        validation.errors.trademark
                      )
                    }
                  />
                  {validation.touched.trademark &&
                    validation.errors.trademark && (
                      <FormFeedback type='invalid'>
                        {validation.errors.trademark}
                      </FormFeedback>
                    )}
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <Label htmlFor='line' className='form-label'>
                    Linea
                  </Label>

                  <Input
                    name='line'
                    id='line'
                    className='form-control'
                    placeholder='Ingrese linea'
                    type='text'
                    validate={{
                      required: { value: true }
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.line}
                    invalid={
                      !!(validation.touched.line && validation.errors.line)
                    }
                  />
                  {validation.touched.line && validation.errors.line && (
                    <FormFeedback type='invalid'>
                      {validation.errors.line}
                    </FormFeedback>
                  )}
                </div>
              </Col>

              <Col lg={4}>
                <div>
                  <Label htmlFor='Modelomotocicleta' className='form-label'>
                    Modelo de motocicleta
                  </Label>

                  <Input
                    name='model'
                    id='Modelomotocicleta'
                    className='form-control'
                    placeholder='Modelo...'
                    type='text'
                    validate={{
                      required: { value: true }
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.model}
                    invalid={
                      !!(validation.touched.model && validation.errors.model)
                    }
                  />
                  {validation.touched.model && validation.errors.model && (
                    <FormFeedback type='invalid'>
                      {validation.errors.model}
                    </FormFeedback>
                  )}
                </div>
              </Col>
              <Col lg={4}>
                <div>
                  <Label htmlFor='color' className='form-label'>
                    Color
                  </Label>

                  <Input
                    name='color'
                    id='color'
                    className='form-control'
                    placeholder='color...'
                    type='text'
                    validate={{
                      required: { value: true }
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.color}
                    invalid={
                      !!(validation.touched.color && validation.errors.color)
                    }
                  />
                  {validation.touched.color && validation.errors.color && (
                    <FormFeedback type='invalid'>
                      {validation.errors.color}
                    </FormFeedback>
                  )}
                </div>
              </Col>
              <Col lg={4}>
                <div>
                  <Label htmlFor='plaque' className='form-label'>
                    Placa
                  </Label>

                  <Input
                    name='plaque'
                    id='plaque'
                    className='form-control text-uppercase'
                    placeholder='Placa...'
                    type='text'
                    validate={{
                      required: { value: true }
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.plaque}
                    invalid={
                      !!(validation.touched.plaque && validation.errors.plaque)
                    }
                  />
                  {validation.touched.plaque && validation.errors.plaque && (
                    <FormFeedback type='invalid'>
                      {validation.errors.plaque}
                    </FormFeedback>
                  )}
                </div>
              </Col>

              <Col lg={6}>
                <div>
                  <Label htmlFor='chassis' className='form-label'>
                    Chasis
                  </Label>

                  <Input
                    name='chassis'
                    id='chassis'
                    className='form-control'
                    placeholder='Chasis...'
                    type='text'
                    validate={{
                      required: { value: true }
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.chassis}
                    invalid={
                      !!(
                        validation.touched.chassis && validation.errors.chassis
                      )
                    }
                  />
                  {validation.touched.chassis && validation.errors.chassis && (
                    <FormFeedback type='invalid'>
                      {validation.errors.chassis}
                    </FormFeedback>
                  )}
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <Label htmlFor='engine' className='form-label'>
                    Motor
                  </Label>

                  <Input
                    name='engine'
                    id='engine'
                    className='form-control'
                    placeholder='Motor'
                    type='text'
                    validate={{
                      required: { value: true }
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.engine}
                    invalid={
                      !!(validation.touched.engine && validation.errors.engine)
                    }
                  />
                  {validation.touched.engine && validation.errors.engine && (
                    <FormFeedback type='invalid'>
                      {validation.errors.engine}
                    </FormFeedback>
                  )}
                </div>
              </Col>

              <Col lg={6}>
                <div>
                  <Label htmlFor='cellar' className='form-label'>
                    Ubicación
                  </Label>

                  {loadingCellar ? (
                    <Input disabled />
                  ) : (
                    <Input
                      name='cellar'
                      id='cellar'
                      className='form-control'
                      placeholder='Ingrese ubicación'
                      type='select'
                      disabled
                      readOnly
                      validate={{
                        required: { value: true }
                      }}
                      // onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.cellar}
                      invalid={
                        !!(
                          validation.touched.cellar && validation.errors.cellar
                        )
                      }
                      style={{ cursor: 'not-allowed' }}
                    >
                      <option value=''>Seleccione bodega</option>
                      {cellars.map(({ id, city, name }) => (
                        <option key={id} value={parseId(id)}>
                          {name} - {city}
                        </option>
                      ))}
                    </Input>
                  )}
                  {validation.touched.cellar && validation.errors.cellar && (
                    <FormFeedback type='invalid'>
                      {validation.errors.cellar}
                    </FormFeedback>
                  )}
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <Label htmlFor='statusForm' className='form-label'>
                    Estado
                  </Label>

                  <Input
                    name='status'
                    type='select'
                    className='form-select'
                    id='statusForm'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.status}
                  >
                    <option value=''>Seleccione el estado</option>
                    <option value='EN INVENTARIO'>EN INVENTARIO</option>
                    <option value='NOTIFICADA'>NOTIFICADA</option>
                    <option value='VENDIDA'>VENDIDA</option>
                    <option value='ENTREGADA AL CLIENTE'>
                      ENTREGADA AL CLIENTE
                    </option>
                  </Input>
                  {validation.touched.status && validation.errors.status ? (
                    <FormFeedback type='invalid'>
                      {validation.errors.status}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col lg={6}>
                <div>
                  <Label htmlFor='basePrice' className='form-label'>
                    Precio base
                  </Label>

                  <InputCurrency
                    id='basePrice'
                    name='basePrice'
                    placeholder='Precio base'
                    className={`form-control ${
                      validation.touched.basePrice &&
                      validation.errors.basePrice
                        ? 'is-invalid'
                        : ''
                    } `}
                    // prefix='$ '
                    value={validation.values.basePrice}
                    // decimalsLimit={0}
                    // decimalSeparator=','
                    // groupSeparator='.'
                    onValueChange={(value, name) => {
                      validation.setFieldValue(name, value)
                    }}
                    // allowNegativeValue={false}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.basePrice &&
                    validation.errors.basePrice && (
                      <div
                        style={{
                          width: '100%',
                          marginTop: '0.25rem',
                          fontSize: '0.875em',
                          color: '#f06548'
                        }}
                      >
                        {validation.errors.basePrice}
                      </div>
                    )}
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <Label htmlFor='salePrice' className='form-label'>
                    Precio de venta
                  </Label>

                  <InputCurrency
                    id='salePrice'
                    name='salePrice'
                    placeholder='Precio base'
                    className={`form-control ${
                      validation.touched.salePrice &&
                      validation.errors.salePrice
                        ? 'is-invalid'
                        : ''
                    } `}
                    // prefix='$ '
                    value={validation.values.salePrice}
                    // decimalsLimit={0}
                    // decimalSeparator=','
                    // groupSeparator='.'
                    onValueChange={(value, name) => {
                      validation.setFieldValue(name, value)
                    }}
                    // allowNegativeValue={false}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.salePrice &&
                    validation.errors.salePrice && (
                      <div
                        style={{
                          width: '100%',
                          marginTop: '0.25rem',
                          fontSize: '0.875em',
                          color: '#f06548'
                        }}
                      >
                        {validation.errors.salePrice}
                      </div>
                    )}
                </div>
              </Col>

              <Col lg={12}>
                <div>
                  <label className='form-label label'>
                    Atributos de la motocicleta
                  </label>
                </div>
                <div className='d-flex justify-content-between flex-sm-column flex-md-row'>
                  <span className='block text-muted flex-fill'>
                    Selecciona con un clic los atributos que tiene la
                    motocicleta
                  </span>
                  <div className='flex-fill'>
                    <Input
                      name='selectAll'
                      id='selectAll'
                      className=''
                      type='checkbox'
                      onChange={selectAllToggle}
                    />
                    <Label htmlFor='selectAll' className='ms-2'>
                      Seleccionar todo
                    </Label>
                  </div>
                </div>
              </Col>

              <Col lg={4}>
                <div>
                  <Input
                    name='forSale'
                    id='forSale'
                    className=''
                    type='checkbox'
                    onChange={validation.handleChange}
                    value={validation.values.forSale}
                    checked={validation.values.forSale}
                  />
                  <Label htmlFor='forSale' className='ms-2'>
                    Disponible para la venta ?{' '}
                    {validation.values.forSale ? (
                      <span className='text-muted'>(SI)</span>
                    ) : (
                      <span className='text-muted'>(NO)</span>
                    )}
                  </Label>
                </div>
              </Col>
              <Col lg={4}>
                <div>
                  <Input
                    name='hasKey'
                    id='hasKey'
                    className=''
                    type='checkbox'
                    onChange={validation.handleChange}
                    value={validation.values.hasKey}
                    checked={validation.values.hasKey}
                  />
                  <Label htmlFor='hasKey' className='ms-2'>
                    Tiene llaves ?{' '}
                    {validation.values.hasKey ? (
                      <span className='text-muted'>(SI)</span>
                    ) : (
                      <span className='text-muted'>(NO)</span>
                    )}
                  </Label>
                </div>
              </Col>
              <Col lg={4}>
                <div>
                  <Input
                    name='haveOwnershipCard'
                    id='haveOwnershipCard'
                    className=''
                    type='checkbox'
                    onChange={validation.handleChange}
                    value={validation.values.haveOwnershipCard}
                    checked={validation.values.haveOwnershipCard}
                  />
                  <Label htmlFor='haveOwnershipCard' className='ms-2'>
                    Tiene tarjeta de propiedad ?{' '}
                    {validation.values.haveOwnershipCard ? (
                      <span className='text-muted'>(SI)</span>
                    ) : (
                      <span className='text-muted'>(NO)</span>
                    )}
                  </Label>
                </div>
              </Col>

              <Col lg={4}>
                <div>
                  <Input
                    name='hasSoat'
                    id='hasSoat'
                    className=''
                    type='checkbox'
                    onChange={validation.handleChange}
                    value={validation.values.hasSoat}
                    checked={validation.values.hasSoat}
                  />
                  <Label htmlFor='hasSoat' className='ms-2'>
                    Tiene SOAT ?{' '}
                    {validation.values.hasSoat ? (
                      <span className='text-muted'>(SI)</span>
                    ) : (
                      <span className='text-muted'>(NO)</span>
                    )}
                  </Label>
                </div>
              </Col>
              <Col lg={4}>
                <div>
                  <Input
                    name='hasTechnomechanics'
                    id='hasTechnomechanics'
                    className=''
                    type='checkbox'
                    onChange={validation.handleChange}
                    value={validation.values.hasTechnomechanics}
                    checked={validation.values.hasTechnomechanics}
                  />
                  <Label htmlFor='hasTechnomechanics' className='ms-2'>
                    Tiene tecnomecánica ?{' '}
                    {validation.values.hasTechnomechanics ? (
                      <span className='text-muted'>(SI)</span>
                    ) : (
                      <span className='text-muted'>(NO)</span>
                    )}
                  </Label>
                </div>
              </Col>
              <Col lg={4}>
                <div>
                  <Input
                    name='finesViolations'
                    id='finesViolations'
                    className=''
                    type='checkbox'
                    onChange={validation.handleChange}
                    value={validation.values.finesViolations}
                    checked={validation.values.finesViolations}
                  />
                  <Label htmlFor='finesViolations' className='ms-2'>
                    Multas o infracciones ?{' '}
                    {validation.values.finesViolations ? (
                      <span className='text-muted'>(SI)</span>
                    ) : (
                      <span className='text-muted'>(NO)</span>
                    )}
                  </Label>
                </div>
              </Col>

              <Col lg={4}>
                <div>
                  <Input
                    name='vehicleDelivery'
                    id='vehicleDelivery'
                    className=''
                    type='checkbox'
                    onChange={validation.handleChange}
                    value={validation.values.vehicleDelivery}
                    checked={validation.values.vehicleDelivery}
                  />
                  <Label htmlFor='vehicleDelivery' className='ms-2'>
                    Vehiculo entregado ?{' '}
                    {validation.values.vehicleDelivery ? (
                      <span className='text-muted'>(SI)</span>
                    ) : (
                      <span className='text-muted'>(NO)</span>
                    )}
                  </Label>
                </div>
              </Col>
              <Col lg={4} />
              <Col lg={4} />

              <Col lg={6}>
                {validation.values.hasSoat && (
                  <div>
                    <Label htmlFor='soatExpirationDate' className='form-label'>
                      Fecha de expiración de soat
                    </Label>

                    <Input
                      name='soatExpirationDate'
                      id='soatExpirationDate'
                      className='form-control'
                      placeholder='Fecha de expiración de soat'
                      type='date'
                      validate={{
                        required: { value: true }
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.soatExpirationDate}
                      invalid={
                        !!(
                          validation.touched.soatExpirationDate &&
                          validation.errors.soatExpirationDate
                        )
                      }
                    />
                    {validation.touched.soatExpirationDate &&
                      validation.errors.soatExpirationDate && (
                        <FormFeedback type='invalid'>
                          {validation.errors.soatExpirationDate}
                        </FormFeedback>
                      )}
                  </div>
                )}
              </Col>
              <Col lg={6}>
                {validation.values.hasTechnomechanics && (
                  <div>
                    <Label
                      htmlFor='technomechanicsExpirationDate'
                      className='form-label'
                    >
                      Fecha de expiración de tecnomecánica
                    </Label>

                    <Input
                      name='technomechanicsExpirationDate'
                      id='technomechanicsExpirationDate'
                      className='form-control'
                      placeholder='Fecha de expiración de tecnomecánica'
                      type='date'
                      validate={{
                        required: { value: true }
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.technomechanicsExpirationDate}
                      invalid={
                        !!(
                          validation.touched.technomechanicsExpirationDate &&
                          validation.errors.technomechanicsExpirationDate
                        )
                      }
                    />
                    {validation.touched.technomechanicsExpirationDate &&
                      validation.errors.technomechanicsExpirationDate && (
                        // eslint-disable-next-line react/jsx-indent
                        <FormFeedback type='invalid'>
                          {validation.errors.technomechanicsExpirationDate}
                        </FormFeedback>
                      )}
                  </div>
                )}
              </Col>

              <Col lg={6}>
                <div>
                  <Label htmlFor='returnDate' className='form-label'>
                    Fecha de restitución
                  </Label>

                  <Input
                    name='returnDate'
                    id='returnDate'
                    className='form-control'
                    placeholder='Fecha de restitución'
                    type='date'
                    validate={{
                      required: { value: true }
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.returnDate}
                    invalid={
                      !!(
                        validation.touched.returnDate &&
                        validation.errors.returnDate
                      )
                    }
                  />
                  {validation.touched.returnDate &&
                    validation.errors.returnDate && (
                      // eslint-disable-next-line react/jsx-indent
                      <FormFeedback type='invalid'>
                        {validation.errors.returnDate}
                      </FormFeedback>
                    )}
                </div>
              </Col>
              <Col lg={6}>
                {validation.values.vehicleDelivery && (
                  <div>
                    <Label htmlFor='vehicleDeliveryDate' className='form-label'>
                      Fecha de entrega de vehículo
                    </Label>

                    <Input
                      name='vehicleDeliveryDate'
                      id='vehicleDeliveryDate'
                      className='form-control'
                      placeholder='Fecha de entrega de vehículo'
                      type='date'
                      validate={{
                        required: { value: true }
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.vehicleDeliveryDate}
                      invalid={
                        !!(
                          validation.touched.vehicleDeliveryDate &&
                          validation.errors.vehicleDeliveryDate
                        )
                      }
                    />
                    {validation.touched.vehicleDeliveryDate &&
                      validation.errors.vehicleDeliveryDate && (
                        // eslint-disable-next-line react/jsx-indent
                        <FormFeedback type='invalid'>
                          {validation.errors.vehicleDeliveryDate}
                        </FormFeedback>
                      )}
                  </div>
                )}
              </Col>

              {validation.values.haveOwnershipCard && (
                <>
                  <Col lg={6}>
                    <div>
                      <Label htmlFor='ownerDocument' className='form-label'>
                        Documento del propietario
                      </Label>

                      <Input
                        name='ownerDocument'
                        id='ownerDocument'
                        className='form-control'
                        placeholder='Ingrese documento'
                        type='number'
                        validate={{
                          required: { value: true }
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.ownerDocument}
                        invalid={
                          !!(
                            validation.touched.ownerDocument &&
                            validation.errors.ownerDocument
                          )
                        }
                      />
                      {validation.touched.ownerDocument &&
                        validation.errors.ownerDocument && (
                          <FormFeedback type='invalid'>
                            {validation.errors.ownerDocument}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div>
                      <Label htmlFor='ownerName' className='form-label'>
                        Nombre del propietario
                      </Label>

                      <Input
                        name='ownerName'
                        id='ownerName'
                        className='form-control'
                        placeholder='Ingrese nombre del propietario'
                        type='text'
                        validate={{
                          required: { value: true }
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.ownerName}
                        invalid={
                          !!(
                            validation.touched.ownerName &&
                            validation.errors.ownerName
                          )
                        }
                      />
                      {validation.touched.ownerName &&
                        validation.errors.ownerName && (
                          <FormFeedback type='invalid'>
                            {validation.errors.ownerName}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                </>
              )}

              <Col lg={6}>
                {validation.values.finesViolations && (
                  <div>
                    <Label htmlFor='taxDebt' className='form-label'>
                      Deuda en impuestos
                    </Label>

                    <InputCurrency
                      id='taxDebt'
                      name='taxDebt'
                      placeholder='Precio base'
                      className={`form-control ${
                        validation.touched.taxDebt && validation.errors.taxDebt
                          ? 'is-invalid'
                          : ''
                      } `}
                      // prefix='$ '
                      value={validation.values.taxDebt}
                      // decimalsLimit={0}
                      // decimalSeparator=','
                      // groupSeparator='.'
                      onValueChange={(value, name) => {
                        validation.setFieldValue(name, value)
                      }}
                      // allowNegativeValue={false}
                      onBlur={validation.handleBlur}
                    />
                    {validation.touched.taxDebt &&
                      validation.errors.taxDebt && (
                        <div
                          style={{
                            width: '100%',
                            marginTop: '0.25rem',
                            fontSize: '0.875em',
                            color: '#f06548'
                          }}
                        >
                          {validation.errors.taxDebt}
                        </div>
                      )}
                  </div>
                )}
              </Col>
              <Col lg={6} />

              <Col lg={12}>
                <div>
                  <Label htmlFor='additionalDescription' className='form-label'>
                    Descripción
                  </Label>
                  <textarea
                    rows='3'
                    name='additionalDescription'
                    id='additionalDescription'
                    className='form-control'
                    placeholder='Ingrese una descripción'
                    type='text'
                    validate={{
                      required: { value: true }
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.additionalDescription}
                    invalid={
                      validation.touched.additionalDescription &&
                      validation.errors.additionalDescription
                        ? 'true'
                        : 'false'
                    }
                  />
                  {validation.errors.additionalDescription ? (
                    <FormFeedback type='invalid'>
                      {validation.errors.additionalDescription}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className='hstack gap-2 justify-content-end'>
              <Button
                color='light'
                onClick={() => {
                  setModal(false)
                }}
              >
                Cancelar{' '}
              </Button>
              <Button
                type='submit'
                color='success'
                id='add-btn'
                disabled={loadingCreate}
              >
                {loadingCreate && <Spinner size='sm' color='light' />}
                Editar motocicleta
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal
        id='showModalComment'
        isOpen={modalComment}
        toggle={toggleComment}
        centered
        size='lg'
      >
        <ModalHeader className='bg-soft-info p-3' toggle={toggleComment}>
          Agregar comentario
        </ModalHeader>
        <Form
          className='tablelist-form'
          onSubmit={(e) => {
            e.preventDefault()
            formComment.handleSubmit()
            return false
          }}
        >
          <ModalBody>
            <input type='hidden' id='id-field' />
            <Row className='g-3'>
              <Col lg={12}>
                <FormGroup>
                  <Label htmlFor='comment' className='form-label'>
                    Comentario
                  </Label>
                  <Input
                    rows='3'
                    name='comment'
                    id='comment'
                    className='form-control'
                    placeholder='Ingrese un comentario'
                    type='textarea'
                    onChange={formComment.handleChange}
                    onBlur={formComment.handleBlur}
                    value={formComment.values.comment}
                    invalid={Boolean(
                      formComment.touched.comment && formComment.errors.comment
                    )}
                  />
                  {formComment.errors.comment ? (
                    <FormFeedback type='invalid'>
                      {formComment.errors.comment}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className='hstack gap-2 justify-content-end'>
              <Button
                color='light'
                onClick={() => {
                  toggleComment()
                }}
              >
                Cancelar{' '}
              </Button>
              <Button
                type='submit'
                color='success'
                id='add-btn'
                disabled={loadingCreate}
              >
                {loadingComment && <Spinner size='sm' color='light' />}
                Agregar comentario
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>

      <ModalChangeCellar
        showCellar={showCellar}
        toggle={toggleCellar}
        cellars={cellars}
        currentCellar={oneMoto.cellar.name}
        toggleCellar={toggleCellar}
        refetchMoto={refetch}
        usedMotorcycleId={oneMoto.id}
      />
    </div>
  )
}
