import React, { useState } from 'react'
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap'
import classnames from 'classnames'
import { SubTabPayCoutas } from './tabsPagos/SubTabPayCoutas'
import { SubTabPayAbono } from './tabsPagos/SubTabPayAbono'
/**
 *
 * @param {{tabId: string, pagos: {node:{value: string, id: string, status: 'pendiente' | 'activa'}}[], refetch: () => void, coutaTotal: string, obligationId: string,backToResume: ()=>void, crediId: string}} props
 * @returns
 */
export const TabPagos = ({
  tabId,
  pagos,
  refetch,
  coutaTotal,
  backToResume,
  creditId,
  obligationId
}) => {
  const [activeTab, setActiveTab] = useState('1')
  const toggleTab = (tab) => setActiveTab(tab)

  return (
    <>
      <TabPane tabId={tabId}>
        <Card>
          <CardBody>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === '1' },
                    'cursor-pointer'
                  )}
                  onClick={() => {
                    toggleTab('1')
                  }}
                >
                  Cuotas
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === '2' },
                    'cursor-pointer'
                  )}
                  onClick={() => {
                    toggleTab('2')
                  }}
                >
                  Abonos
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    { active: activeTab === '3' },
                    'cursor-pointer'
                  )}
                  onClick={() => {
                    toggleTab('3')
                  }}
                >
                  Otros
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <SubTabPayCoutas
                backToResume={backToResume}
                coutaTotal={coutaTotal}
                pagos={pagos}
                refetch={refetch}
                creditId={creditId}
                obligationId={obligationId}
                tabId={activeTab}
              />
              <SubTabPayAbono
                tabId={activeTab}
                backToResume={backToResume}
                creditId={creditId}
                obligationId={obligationId}
                refetch={refetch}
              />
              <TabPane tabId='3' className='p-4'>
                <div
                  className='d-flex align-items-center justify-content-center'
                  style={{ height: '60vh' }}
                >
                  Próximamente
                </div>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </TabPane>
    </>
  )
}
