import React, { useEffect } from 'react'
import {
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  Table
} from 'reactstrap'
import { parseId } from '../../../helpers'
import { useLazyQuery } from '@apollo/client'
import { GET_USER_CREDIT_WALLET } from '../../../graphql/queries/getCreditsWallet'
import { toast } from 'react-toastify'
import moment from 'moment'

const TYPES_DOCUMENT = {
  CC: 'Cédula de cuidadanía',
  CE: 'Cédula de extranjería'
}

export const ModalShowInfoUser = ({ showInfoUser, idCreditWallet, toggle }) => {
  const [gqlGetInfoUser, { data, loading, error }] = useLazyQuery(
    GET_USER_CREDIT_WALLET,
    { fetchPolicy: 'no-cache' }
  )
  useEffect(() => {
    if (!idCreditWallet) return
    gqlGetInfoUser({
      variables: {
        id: parseId(idCreditWallet)
      }
    })
  }, [idCreditWallet])

  useEffect(() => {
    if (!error) return
    toast.error('Error: ' + error.message)
  }, [error])

  if (loading || !data) {
    return (
      <Modal isOpen={showInfoUser} toggle={toggle}>
        <ModalHeader toggle={toggle}>Información personal</ModalHeader>
        <ModalBody
          className='d-flex align-items-center justify-content-center'
          style={{ height: '30vh' }}
        >
          <Spinner color='dark' style={{ width: '3em', height: '3em' }} />
        </ModalBody>
      </Modal>
    )
  }
  /** @type {{id: string, firstName: string, firstSurname: string, secondName: string, secondSurname: string, documentNumber: string, documentType: string, expeditionDate: Date, dateBirth: Date, email: string, phoneNumber: string, firmaAutorizacion: boolean, spouse: {node: {id: string}}, referenceSet: {edges: {node: {id: string}}[]}, cosignerSet: {edges: {node: {id: string}}[]}}} */
  const user = data.walletCredits.edges[0]?.node

  return (
    <Modal isOpen={showInfoUser} toggle={toggle}>
      <ModalHeader toggle={toggle} className='p-4 bg-soft-info'>
        Información personal
      </ModalHeader>
      <ModalBody>
        <Table>
          <tbody>
            <tr>
              <th>Nombre(s)</th>
              <td>
                {user.firstName} {user.secondName}
              </td>
            </tr>
            <tr>
              <th>Apellido(s)</th>
              <td>
                {user.firstSurname} {user.secondSurname}
              </td>
            </tr>
            <tr>
              <th>Tipo documento</th>
              <td>{TYPES_DOCUMENT[user.documentType]}</td>
            </tr>
            <tr>
              <th>No. documento</th>
              <td>{user.documentNumber}</td>
            </tr>
            <tr>
              <th>Fecha de nacimiento</th>
              <td>{moment(user.dateBirth).format('DD MMM [del] YYYY')}</td>
            </tr>
            <tr>
              <th>Fecha de expedición</th>
              <td>{moment(user.expeditionDate).format('DD MMM [del] YYYY')}</td>
            </tr>
            <tr>
              <th>Correo electrónico</th>
              <td>{user.email}</td>
            </tr>
            <tr>
              <th>Teléfono</th>
              <td>{user.phoneNumber}</td>
            </tr>
            <tr>
              <th>¿Ha firmado?</th>
              <td>
                {user.firmaAutorizacion ? (
                  <Badge color='success'>SI</Badge>
                ) : (
                  <Badge color='danger'>NO</Badge>
                )}
              </td>
            </tr>
            <tr>
              <th>¿Conyugé?</th>
              <td>
                {user.spouse ? (
                  <Badge color='success'>SI</Badge>
                ) : (
                  <Badge color='danger'>NO</Badge>
                )}
              </td>
            </tr>
            <tr>
              <th>Codeudores</th>
              <td>{user.cosignerSet.edges.length}</td>
            </tr>
            <tr>
              <th>Referencias</th>
              <td>{user.referenceSet.edges.length}</td>
            </tr>
          </tbody>
        </Table>
      </ModalBody>
    </Modal>
  )
}
