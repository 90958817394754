import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { TabPane, Card, CardBody, Row, Table, Col, Spinner } from 'reactstrap'
import { GET_DOCS_CREDIT } from '../../../graphql/queries/getCredits'
import { toast } from 'react-toastify'
import { useGetUser } from '../../../hooks/user'
import { parseId } from '../../../helpers'
import { API } from '../../../graphql/client'

export const TabDocuments = ({ creditId }) => {
  const { data, loading, error } = useQuery(GET_DOCS_CREDIT, {
    variables: {
      creditId: parseId(creditId)
    },
    fetchPolicy: 'no-cache'
  })

  /** @type {[{node: {name: string, legibleName: string, id: string}}[], React.Dispatch<React.SetStateAction<{node: {}}[]>>]} */
  const [docs, setDocs] = useState([])

  useEffect(() => {
    if (!data || !data.listDocuments) return
    if (data.listDocuments.edges.length > 0) {
      setDocs(data.listDocuments.edges)
    }
  }, [data])

  useEffect(() => {
    if (error) {
      toast.error(`ERROR: ${error.message}`)
    }
  }, [error])

  const { user } = useGetUser()

  const [loadingExport, setLoadingExport] = useState(false)

  const handleDownload = async (name, legibleName, id) => {
    if (!name || !creditId) return
    const { token } = user.tokenAuth
    if (!token) return
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    const requestOptions = {
      method: 'GET',
      headers: myHeaders
    }

    setLoadingExport(true)

    try {
      const res = await fetch(
        `${API}/export/${name}/${parseId(creditId)}`,
        requestOptions
      )
      if (!res.ok) throw new Error('Error al exportar')
      const blob = await res.blob()
      // console.log('blob', blob)
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `${legibleName}-${parseId(creditId)}.${blob.type.replace(
        'application/',
        ''
      )}`
      document.body.appendChild(link)
      setLoadingExport(false)
      link.click()
    } catch (err) {
      setLoadingExport(false)
      toast.error(`ERROR: ${err.message}`)
    }
  }

  return (
    <TabPane tabId='8'>
      <Card>
        {loading || !data ? (
          <CardBody
            className='d-flex align-items-center justify-content-center'
            style={{ height: '30vh' }}
          >
            <Spinner color='dark' style={{ width: '3em', height: '3em' }} />
          </CardBody>
        ) : (
          <CardBody>
            <div className='d-flex align-items-center mb-4'>
              <h5 className='card-title flex-grow-1 mb-0'>
                Documentos del crédito
              </h5>
              {/* <div className='flex-shrink-0'>
              <Input
                className='form-control d-none'
                type='file'
                id='formFile'
              />
              <Label htmlFor='formFile' className='btn btn-danger'>
                <i className='ri-upload-2-fill me-1 align-bottom' /> Añadir
                soporte
              </Label>
            </div> */}
            </div>
            <Row>
              <Col lg={12}>
                <div className='table-responsive'>
                  {docs.length > 0 ? (
                    <Table className='table-borderless align-middle mb-0'>
                      <thead className='table-light'>
                        <tr>
                          <th scope='col' style={{ width: '60px' }}>
                            #
                          </th>
                          <th scope='col'>Nombre</th>
                          <th scope='col' />
                        </tr>
                      </thead>
                      <tbody>
                        {docs.map(({ node }, idx) => (
                          <tr key={node.id}>
                            <td>{idx + 1 < 10 ? `0${idx + 1}` : idx}</td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='avatar-sm'>
                                  <div className='avatar-title bg-danger text-light rounded fs-36'>
                                    <i className='mdi mdi-file-document-outline' />
                                  </div>
                                </div>
                                <div className='ms-3 flex-grow-1'>
                                  <h6 className='fs-15 mb-0'>
                                    {node.legibleName}
                                  </h6>
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                className='border-0 p-0 m-0 bg-transparent'
                                onClick={() => {
                                  handleDownload(node.name, node.legibleName)
                                }}
                              >
                                {loadingExport ? (
                                  <Spinner size='sm' color='dark' />
                                ) : (
                                  <i className='mdi mdi-download fs-24 text-secondary' />
                                )}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <div style={{ height: '30vh' }}>
                      No hay documentos para este crédito
                    </div>
                  )}
                </div>
                {/* <div className='text-center mt-3'>
                              <Link to='#' className='text-success '>
                                <i className='mdi mdi-loading mdi-spin fs-20 align-middle me-2' />
                                Load more{' '}
                              </Link>
                            </div> */}
              </Col>
            </Row>
          </CardBody>
        )}
      </Card>
    </TabPane>
  )
}
