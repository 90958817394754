import { gql } from '@apollo/client'

export const CREATE_PAZ_Y_SALVO = gql`
  mutation complianceCertifcate($input: ComplianceCertificateInput!) {
    complianceCertificateCreate(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`
export const CHANGE_STATUS_CERTIFICATE = gql`
  mutation complianceCertificateChangeStatus(
    $input: ComplianceChangeStatusInput!
  ) {
    complianceCertificateChangeStatus(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`
