import { gql } from '@apollo/client'

export const GET_ALL_MOTOS_USADAS = gql`
  query listUsedMotorcycle(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id: Float
    $status_Iexact: String
    $created_Date_Gte: DateTime
    $created_Date_Lte: DateTime
    $ownerDocument: String
    $name: String
  ) {
    listUsedMotorcycle(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      id: $id
      status_Iexact: $status_Iexact
      created_Date_Gte: $created_Date_Gte
      created_Date_Lte: $created_Date_Lte
      ownerDocument: $ownerDocument
      name: $name
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          trademark
          model
          line
          plaque
          status
          forSale
          cellar {
            id
            name
            city
          }
          ownerDocument
          ownerName
        }
      }
    }
  }
`

export const GET_ONE_MOTO_USADA = gql`
  query listUsedMotorcycle(
    $id: Float
    $status: String
    $documentNumber: String
    $createdDateGte: DateTime
    $createdDateLte: DateTime
  ) {
    listUsedMotorcycle(
      id: $id
      status_Iexact: $status
      ownerDocument: $documentNumber
      created_Date_Gte: $createdDateGte
      created_Date_Lte: $createdDateLte
    ) {
      edges {
        node {
          id
          created
          updated
          trademark
          line
          model
          color
          plaque
          chassis
          engine
          cellar {
            id
            name
            city
          }
          basePrice
          forSale
          hasKey
          haveOwnershipCard
          hasSoat
          hasTechnomechanics
          soatExpirationDate
          technomechanicsExpirationDate
          vehicleDelivery
          vehicleDeliveryDate
          finesViolations
          returnDate
          ownerDocument
          ownerName
          taxDebt
          additionalDescription
          status
          salePrice
          commercialCompany {
            id
            name
          }
          usedmotorcyclecommentSet {
            edges {
              cursor
              node {
                id
                created
                comment
                user {
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    }
  }
`
export const GET_CELLARS_MOTO_USADAS = gql`
  query listCellar {
    listCellar {
      id
      address
      city
      departament
      name
      phoneNumber
    }
  }
`
