import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
  UncontrolledTooltip
} from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import SimpleBar from 'simplebar-react'
import { useQuery } from '@apollo/client'
import { GET_ALL_NOTIFICATIONS_PAGE } from '../../../graphql/queries/getNotifications'
import { toast } from 'react-toastify'
import bell from '../../../assets/images/svg/bell.svg'
import { parseId } from '../../../helpers'
import moment from 'moment'

const Notifications = () => {
  const { data, loading, error } = useQuery(GET_ALL_NOTIFICATIONS_PAGE, {
    fetchPolicy: 'no-cache'
  })
  /** @type {[{ id: number; msg: string; title: string; url: string; isReaded: boolean; createDate: string;}[], React.Dispatch<React.SetStateAction<{ id: number; msg: string; title: string; url: string; isReaded: boolean; createDate: string;}[]>>]} */
  const [allNotifications, setAllNotifications] = useState([])
  useEffect(() => {
    if (error) {
      toast.error(`ERROR: ${error.message}`)
    }
  }, [error])
  useEffect(() => {
    if (!data) return
    const parseNotifications = data.listNotifications.edges.map(({ node }) => {
      const parse = JSON.parse(node.extraData)
      return {
        id: parseId(node.id),
        msg: parse.context.message.message,
        title: parse.context.message.title,
        url: parse.context.message.url,
        isReaded: node.isRead,
        createDate: node.createDate
      }
    })
    setAllNotifications(parseNotifications)
  }, [data])

  if (loading) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='Todas las notificaciones'
            pageTitle='Notificaciones'
          />

          <Row className='mx-auto'>
            <Col xl={8} lg={8} className='mx-auto'>
              <Card>
                <CardHeader>
                  <h4 className='card-title mb-0'>Todas las notificaciones</h4>
                </CardHeader>
                <CardBody>
                  <div
                    style={{ height: '40vh' }}
                    className='d-flex align-items-center justify-content-center'
                  >
                    <Spinner style={{ height: '3em', width: '3em' }} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
  if (data.listNotifications.edges === 0) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='Todas las notificaciones'
            pageTitle='Notificaciones'
          />

          <Row className='mx-auto'>
            <Col xl={8} lg={8} className='mx-auto'>
              <Card>
                <CardHeader>
                  <h4 className='card-title mb-0'>Todas las notificaciones</h4>
                </CardHeader>
                <CardBody>
                  <div className='w-25 w-sm-50 pt-3 mx-auto'>
                    <img src={bell} className='img-fluid' alt='user-pic' />
                  </div>
                  <div className='text-center pb-5 mt-2'>
                    <h6 className='fs-18 fw-semibold lh-base'>
                      No tienes Notificaciones
                    </h6>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb
          title='Todas las notificaciones'
          pageTitle='Notificaciones'
        />

        <Row className='mx-auto'>
          <Col xl={8} lg={8} className='mx-auto'>
            <Card>
              <CardHeader>
                <h4 className='card-title mb-0'>Todas las notificaciones</h4>
              </CardHeader>
              <CardBody>
                <p className='text-muted'>
                  A continuación se muestran todas tus notificaciones
                </p>

                <div className='mx-n3'>
                  <SimpleBar
                    autoHide={false}
                    className='simplebar-track-primary'
                    style={{ maxHeight: '60vh' }}
                  >
                    <div className='list-group list-group-flush'>
                      {allNotifications.map((noti) => (
                        <button
                          className='text-reset notification-item d-block dropdown-item list-group-item'
                          key={noti.id}
                        >
                          <div className='d-flex'>
                            <div className='flex-shrink-0 avatar-xs me-3'>
                              <span
                                className={`avatar-title ${
                                  noti.isReaded
                                    ? 'bg-soft-dark text-dark'
                                    : 'bg-soft-info text-info'
                                } rounded-circle fs-16`}
                              >
                                {noti.isReaded ? (
                                  <i className='mdi mdi-email-open-multiple-outline' />
                                ) : (
                                  <i className='mdi mdi-email-alert-outline' />
                                )}
                              </span>
                            </div>
                            <div className='flex-grow-1 text-muted'>
                              <p
                                className='fs-bold fs-5'
                                style={{ color: '#111' }}
                              >
                                {noti.title}
                              </p>
                              <p className='m-0'>{noti.msg}</p>
                            </div>
                            <div className='flex-shrink-0 ms-2'>
                              <div
                                className='fs-11 text-muted'
                                id={`date-${noti.id}`}
                              >
                                <i className='mdi mdi-clock-outline' />{' '}
                                {moment(noti.createDate).fromNow()}
                              </div>
                              <UncontrolledTooltip
                                placement='right'
                                target={`date-${noti.id}`}
                              >
                                {moment(noti.createDate).format(
                                  'DD MMM [del] YY [a las] hh:mm A'
                                )}
                              </UncontrolledTooltip>
                            </div>
                          </div>
                        </button>
                      ))}
                    </div>
                    {data.listNotifications.pageInfo.hasNextPage && (
                      <div className='text-center my-2'>
                        <div className='text-success'>
                          <i className='mdi mdi-loading mdi-spin fs-20 align-middle me-2' />{' '}
                          Cargando
                        </div>
                      </div>
                    )}
                  </SimpleBar>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Notifications
