/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import {
  Button,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Spinner
} from 'reactstrap'
import { GET_ROLES_TO_USER } from '../../graphql/queries/getUsuarios'
import { ROLES } from '../../helpers/consts'
import { CHANGE_ROL } from '../../graphql/mutations/userMutations'
// import { toast } from 'react-toastify'
import { useGetUser } from '../../hooks/user'
import { useNavigate } from 'react-router-dom'

export const OffCanvaRol = ({
  showOffCanva,
  toggleCanvas,
  closeOffCanvas,
  showMessage
}) => {
  const [
    gqlChangeRol,
    { data: dataChange, loading: loadingChange, error: errorChange, reset }
  ] = useMutation(CHANGE_ROL, { fetchPolicy: 'no-cache' })
  const [gqlGetRoles, { data: dataRol, loading: loadingRol }] = useLazyQuery(
    GET_ROLES_TO_USER,
    { nextFetchPolicy: 'no-cache' }
  )

  const navigate = useNavigate()

  const {
    changeRolAndPermissions,
    openModalChangeZoneOrAgencie,
    updateOptionAgencieOrZone,
    rol
  } = useGetUser()

  const [checkRol, setCheckRol] = useState({
    id: 0,
    name: ''
  })

  useEffect(() => {
    gqlGetRoles({ fetchPolicy: 'no-cache' })
    return () => {
      reset()
    }
  }, [])

  useEffect(() => {
    let idTime = 0
    if (dataChange) {
      if (dataChange.switchRole.success) {
        // toast.success('Rol cambiado exitosamente a: ' + checkRol.name)
        showMessage('Rol cambiado exitosamente a: ' + checkRol.name, true)
        changeRolAndPermissions(
          checkRol.name,
          dataChange.switchRole.permissions
        )
        closeOffCanvas()
        // refetch()
        idTime = setTimeout(() => {
          navigate('/dashboard')
        }, 400)
        if (dataChange.switchRole.agenciesZones) {
          openModalChangeZoneOrAgencie(true)
        } else {
          updateOptionAgencieOrZone(null)
        }
      } else {
        closeOffCanvas()
        setCheckRol({
          id: 0,
          name: ''
        })
        showMessage(`ERROR: ${dataChange.switchRole?.errors?.message}`, false)
        // toast.error(`ERROR: ${dataChange.switchRole?.errors?.message}`)
      }
    }
    return () => {
      clearTimeout(idTime)
    }
  }, [dataChange])

  useEffect(() => {
    if (errorChange) {
      // toast.error(`ERROR: ${errorChange.message}`)
      closeOffCanvas()
      setCheckRol({
        id: 0,
        name: ''
      })
      showMessage(`ERROR: ${errorChange.message}`, false)
    }
  }, [errorChange])

  useEffect(() => {
    if (!dataRol) return
    if (dataRol.listRol.length > 0) {
      const find = dataRol.listRol.find((rolItem) => rolItem.name === rol)
      if (find) {
        setCheckRol({
          id: Number(find.id),
          name: find.name
        })
      }
    }
  }, [dataRol])

  const hanldeChangeRol = () => {
    if (checkRol.id) {
      // console.log('checkRol', checkRol)
      gqlChangeRol({
        variables: {
          id: checkRol.id
        }
      })
    }
  }

  if (loadingRol) {
    return (
      <Offcanvas
        isOpen={showOffCanva}
        toggle={toggleCanvas}
        id='offcanvasRight'
        direction='end'
      >
        <OffcanvasHeader
          toggle={toggleCanvas}
          id='offcanvasRightLabel'
          className='d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header-dark'
        >
          <span className='m-0 me-2 text-white'>Cambiar Rol</span>
        </OffcanvasHeader>
        <OffcanvasBody>
          <div
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column'
            }}
          >
            <div className='p-4'>
              <h6 className='mb-0 fw-semibold text-uppercase placeholder-glow'>
                <span className='placeholder col-4' />
              </h6>
              <p className='text-muted placeholder-glow'>
                <span className='placeholder col-12' />
              </p>

              <div className='row gy-3'>
                <div className='col-4'>
                  <div className='form-check card-radio'>
                    <div
                      className='placeholder-glow rounded'
                      style={{ height: '80px' }}
                    >
                      <span className='placeholder col-12 h-100 rounded' />
                    </div>
                  </div>
                  <h5 className='fs-13 text-center mt-2 placeholder-glow'>
                    <span className='placeholder col-10' />
                  </h5>
                </div>
              </div>
            </div>

            <div className='d-flex justify-content-end'>
              <div className='d-flex gap-2'>
                <div
                  className='btn btn-light placheholder disabled'
                  style={{ width: '128px', height: '38px' }}
                />
                <div
                  className='btn btn-info placheholder disabled'
                  style={{ width: '128px', height: '38px' }}
                />
              </div>
            </div>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    )
  }

  if (!dataRol) {
    return (
      <Offcanvas
        isOpen={showOffCanva}
        toggle={toggleCanvas}
        id='offcanvasRight'
        direction='end'
      >
        <OffcanvasHeader
          toggle={toggleCanvas}
          id='offcanvasRightLabel'
          className='d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header-dark'
        >
          <span className='m-0 me-2 text-white'>Cambiar Rol</span>
        </OffcanvasHeader>
        <OffcanvasBody>noe se puedo cambiar de rol</OffcanvasBody>
      </Offcanvas>
    )
  }
  /** @param {string} id */
  const renderIcons = (id) => {
    if (id === ROLES.GERENTE) {
      return (
        <span className='d-flex gap-1 h-100'>
          <span className='flex-shrink-0'>
            <span className='bg-light d-flex h-100 flex-column gap-1 p-1'>
              <span className='d-block p-1 px-2 bg-soft-primary rounded mb-2' />
              <span className='d-block p-1 px-2 pb-0 bg-soft-primary' />
              <span className='d-block p-1 px-2 pb-0 bg-soft-primary' />
              <span className='d-block p-1 px-2 pb-0 bg-soft-primary' />
            </span>
          </span>
          <span className='flex-grow-1'>
            <span className='d-flex h-100 flex-column'>
              <span className='bg-light d-block p-1' />
              <span className='d-flex justify-content-center align-items-center h-100'>
                <i className='mdi mdi-account-tie text-primary fs-4' />{' '}
              </span>
              <span className='bg-light d-block p-1 mt-auto' />
            </span>
          </span>
        </span>
      )
    }
    if (id === ROLES.ADMIN) {
      return (
        <span className='d-flex gap-1 h-100'>
          <span className='flex-shrink-0'>
            <span className='bg-light d-flex h-100 flex-column gap-1 p-1'>
              <span className='d-block p-1 px-2 bg-soft-primary rounded mb-2' />
              <span className='d-block p-1 px-2 pb-0 bg-soft-primary' />
              <span className='d-block p-1 px-2 pb-0 bg-soft-primary' />
              <span className='d-block p-1 px-2 pb-0 bg-soft-primary' />
            </span>
          </span>
          <span className='flex-grow-1'>
            <span className='d-flex h-100 flex-column'>
              <span className='bg-light d-block p-1' />
              <span className='d-flex justify-content-center align-items-center h-100'>
                <i className='ri-shield-user-line text-primary fs-4' />{' '}
              </span>
              <span className='bg-light d-block p-1 mt-auto' />
            </span>
          </span>
        </span>
      )
    }
    if (id === ROLES.JEFE_ZONA) {
      return (
        <span className='d-flex h-100 flex-column gap-1'>
          <span className='bg-light d-flex p-1 gap-1 align-items-center'>
            <span className='d-block p-1 bg-soft-primary rounded me-1' />
            <span className='d-block p-1 pb-0 px-2 bg-soft-primary ms-auto' />
            <span className='d-block p-1 pb-0 px-2 bg-soft-primary' />
          </span>
          <span className='bg-light d-block p-1' />
          <span className='d-flex justify-content-center align-items-center h-100'>
            <i className='bx bx-map-pin text-primary fs-4' />{' '}
          </span>
          <span className='bg-light d-block p-1 mt-auto' />
        </span>
      )
    }
    if (id === ROLES.ASESOR) {
      return (
        <span className='d-flex gap-1 h-100'>
          <span className='flex-shrink-0'>
            <span className='bg-light d-flex h-100 flex-column gap-1'>
              <span className='d-block p-1 bg-soft-primary mb-2' />
              <span className='d-block p-1 pb-0 bg-soft-primary' />
              <span className='d-block p-1 pb-0 bg-soft-primary' />
              <span className='d-block p-1 pb-0 bg-soft-primary' />
            </span>
          </span>
          <span className='flex-shrink-0'>
            <span className='bg-light d-flex h-100 flex-column gap-1 p-1'>
              <span className='d-block p-1 px-2 pb-0 bg-soft-primary' />
              <span className='d-block p-1 px-2 pb-0 bg-soft-primary' />
              <span className='d-block p-1 px-2 pb-0 bg-soft-primary' />
              <span className='d-block p-1 px-2 pb-0 bg-soft-primary' />
            </span>
          </span>
          <span className='flex-grow-1'>
            <span className='d-flex h-100 flex-column'>
              <span className='bg-light d-block p-1' />
              <span className='d-flex justify-content-center align-items-center h-100'>
                <i className='bx bx-group text-primary fs-4' />{' '}
              </span>
              <span className='bg-light d-block p-1 mt-auto' />
            </span>
          </span>
        </span>
      )
    }
    if (id === ROLES.FACTURADOR) {
      return (
        <span className='d-flex gap-1 h-100'>
          <span className='d-flex h-100 flex-column w-100'>
            <span className='bg-light d-flex p-1 gap-1 align-items-center px-2'>
              <span className='d-block p-1 bg-soft-primary rounded me-1' />
              <span className='d-block p-1 pb-0 px-2 bg-soft-primary ms-auto' />
              <span className='d-block p-1 pb-0 px-2 bg-soft-primary' />
            </span>
            <span className='d-flex gap-1 h-100 p-1 px-2 justify-content-center'>
              <span className='flex-shrink-0'>
                <span className='bg-light d-flex h-100 flex-column gap-1 p-1'>
                  <span className='d-block p-1 px-3 pb-0 bg-soft-primary' />
                  <span className='d-block p-1 px-3 pb-0 bg-soft-primary' />
                  <span className='d-block p-1 px-3 pb-0 bg-soft-primary' />
                  <span className='d-block p-1 px-3 pb-0 bg-soft-primary' />
                </span>
              </span>
            </span>
            <span className='bg-light d-block p-1 mt-auto px-2' />
          </span>
        </span>
      )
    }

    if (id === ROLES.ANALISTA) {
      return (
        <span className='d-flex gap-1 h-100'>
          <span className='d-flex h-100 flex-column w-100'>
            <span className='bg-light d-flex p-1 gap-1 align-items-center px-2'>
              <span className='d-block p-1 bg-soft-primary rounded me-1' />
              <span className='d-block p-1 pb-0 px-2 bg-soft-primary ms-auto' />
              <span className='d-block p-1 pb-0 px-2 bg-soft-primary' />
            </span>
            <span className='d-flex gap-1 h-100 p-1 px-2 justify-content-center'>
              <span className='flex-shrink-0'>
                <span className='d-flex h-100 flex-column gap-1 p-1 justify-content-center align-items-center'>
                  <i className='mdi mdi-table-account fs-4 text-primary' />
                </span>
              </span>
            </span>
            <span className='bg-light d-block p-1 mt-auto px-2' />
          </span>
        </span>
      )
    }
    if (id === ROLES.JEFE_CREDITO) {
      return (
        <span className='d-flex gap-1 h-100'>
          <span className='d-flex h-100 flex-column w-100'>
            <span className='bg-light d-flex p-1 gap-1 align-items-center px-2'>
              <span className='d-block p-1 bg-soft-primary rounded me-1' />
              <span className='d-block p-1 pb-0 px-2 bg-soft-primary ms-auto' />
              <span className='d-block p-1 pb-0 px-2 bg-soft-primary' />
            </span>
            <span className='d-flex gap-1 h-100 p-1 px-2 justify-content-center'>
              <span className='flex-shrink-0'>
                <span className='d-flex h-100 flex-column gap-1 p-1 justify-content-center align-items-center'>
                  <i className='mdi mdi-credit-card-edit-outline fs-4 text-primary' />
                </span>
              </span>
            </span>
            <span className='bg-light d-block p-1 mt-auto px-2' />
          </span>
        </span>
      )
    }
    if (id === ROLES.CARTERA) {
      return (
        <span className='d-flex gap-1 h-100'>
          <span className='flex-shrink-0'>
            <span className='bg-light d-flex h-100 flex-column gap-1'>
              <span className='d-block p-1 bg-soft-primary mb-2' />
              <span className='d-block p-1 pb-0 bg-soft-primary' />
              <span className='d-block p-1 pb-0 bg-soft-primary' />
              <span className='d-block p-1 pb-0 bg-soft-primary' />
            </span>
          </span>
          <span className='flex-shrink-0'>
            <span className='bg-light d-flex h-100 flex-column gap-1 p-1'>
              <span className='d-block p-1 px-2 pb-0 bg-soft-primary' />
              <span className='d-block p-1 px-2 pb-0 bg-soft-primary' />
              <span className='d-block p-1 px-2 pb-0 bg-soft-primary' />
              <span className='d-block p-1 px-2 pb-0 bg-soft-primary' />
            </span>
          </span>
          <span className='flex-grow-1'>
            <span className='d-flex h-100 flex-column'>
              <span className='bg-light d-block p-1' />
              <span className='d-flex justify-content-center align-items-center h-100'>
                <i className='mdi mdi-account-cash-outline fs-4 text-primary' />{' '}
              </span>
              <span className='bg-light d-block p-1 mt-auto' />
            </span>
          </span>
        </span>
      )
    }

    if (id === ROLES.CONTABILIDAD) {
      return (
        <span className='d-flex h-100 flex-column gap-1'>
          <span className='bg-light d-flex p-1 gap-1 align-items-center'>
            <span className='d-block p-1 bg-soft-primary rounded me-1' />
            <span className='d-block p-1 pb-0 px-2 bg-soft-primary ms-auto' />
            <span className='d-block p-1 pb-0 px-2 bg-soft-primary' />
          </span>
          <span className='bg-light d-block p-1' />
          <span className='d-flex justify-content-center align-items-center h-100'>
            <i className='mdi mdi-bank-transfer text-primary fs-4' />{' '}
          </span>
          <span className='bg-light d-block p-1 mt-auto' />
        </span>
      )
    }

    return null
  }

  // console.log('dataRol', dataRol)

  return (
    <Offcanvas
      isOpen={showOffCanva}
      toggle={toggleCanvas}
      id='offcanvasRight'
      direction='end'
    >
      <OffcanvasHeader
        toggle={toggleCanvas}
        id='offcanvasRightLabel'
        className='d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header-dark'
      >
        <span className='m-0 me-2 text-white'>Cambiar Rol</span>
      </OffcanvasHeader>
      <OffcanvasBody>
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column'
          }}
        >
          <div className='p-4'>
            <h6 className='mb-0 fw-semibold text-uppercase'>ROLES</h6>
            <p className='text-muted'>
              Seleccione el rol al cual desea cambiarse
            </p>

            <div className='row gy-3'>
              {dataRol.listRol.map((rol, i) => (
                <div className='col-4' key={rol.name + i}>
                  <div className='form-check card-radio'>
                    <input
                      id={`customizer-role0${rol.id}`}
                      name='data-role'
                      type='radio'
                      value={rol.id}
                      checked={checkRol.id === rol.id}
                      onChange={(e) => {
                        setCheckRol({
                          id: Number(e.target.value),
                          name: rol.name
                        })
                      }}
                      className='form-check-input'
                    />
                    <label
                      className='form-check-label p-0 avatar-md w-100'
                      htmlFor={`customizer-role0${rol.id}`}
                    >
                      {renderIcons(rol.name)}
                      {/* Icono de Administrador */}
                    </label>
                  </div>
                  <h5 className='fs-13 text-center mt-2'>{rol.name}</h5>
                </div>
              ))}
            </div>
          </div>

          <div className='d-flex justify-content-end'>
            <div className='d-flex gap-2'>
              <Button onClick={() => closeOffCanvas()} color='light'>
                Cancelar
              </Button>
              <Button
                onClick={hanldeChangeRol}
                disabled={!checkRol || loadingChange}
                className='d-flex align-items-center gap-1'
              >
                {loadingChange ? (
                  <>
                    <Spinner size='sm' color='light' /> Cambiando
                  </>
                ) : (
                  <>
                    <i className='mdi mdi-cog-transfer-outline' /> Cambiar rol
                  </>
                )}
              </Button>
            </div>
          </div>
        </div>
      </OffcanvasBody>
    </Offcanvas>
  )
}
