import { useQuery } from '@apollo/client'
import { GET_RESUME_OBLIGATION } from '../../../graphql/queries/getCreditsWallet'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { parseId, parsePrice } from '../../../helpers'
import SimpleBar from 'simplebar-react'
import { ListGroup, ListGroupItem, Spinner } from 'reactstrap'
import { INFO_ARR_KEYS_WALLET_RESUME } from '../../../helpers/consts'

export const ResumeInfoDebt = ({
  obligationId,
  setCapitalValue,
  setIsAbono,
  setInfoObligation
}) => {
  const { data, loading, error } = useQuery(GET_RESUME_OBLIGATION, {
    variables: {
      obligationId: parseId(obligationId)
    },
    fetchPolicy: 'no-cache'
  })
  useEffect(() => {
    if (error) toast.error('Error: ' + error.message)
  }, [error])

  useEffect(() => {
    if (!data) return
    const {
      capital,
      endorsement,
      interest,
      penaltyInterest,
      paymentManagement,
      total
    } = data.obligationResume
    setCapitalValue(capital)
    setIsAbono(
      Number(endorsement) === 0 &&
        Number(interest) === 0 &&
        Number(penaltyInterest) === 0 &&
        Number(paymentManagement) === 0
    )
    setInfoObligation({
      capital,
      endorsement,
      interest,
      penaltyInterest,
      paymentManagement,
      total
    })
  }, [data])

  if (loading || !data) {
    return (
      <div className='my-4'>
        <Spinner />
      </div>
    )
  }

  return (
    <div className='my-4'>
      <h5 className='my-2'>Deuda actual</h5>
      <SimpleBar style={{ maxHeight: '215px' }}>
        <ListGroup className='mb-1'>
          {INFO_ARR_KEYS_WALLET_RESUME.map((obj) => (
            <ListGroupItem key={obj.key}>
              <div className='d-flex align-items-center'>
                <div className='flex-grow-1'>
                  <div className='d-flex'>
                    <div className='flex-shrink-0 avatar-xs'>
                      <div
                        className={`avatar-title bg-soft-${obj.color} text-${obj.color} rounded`}
                      >
                        <i className={obj.icon + ' fs-4'} />
                      </div>
                    </div>
                    <div className='flex-shrink-0 ms-2'>
                      <h6 className='fs-14 mb-0'>{obj.name}</h6>
                      <small className='text-muted'>{obj.description}</small>
                    </div>
                  </div>
                </div>
                <div className='flex-shrink-0'>
                  <span className='fw-bold'>
                    {parsePrice(data.obligationResume[obj.key])}
                  </span>
                </div>
              </div>
            </ListGroupItem>
          ))}
        </ListGroup>
      </SimpleBar>
    </div>
  )
}
