import React, { useEffect } from 'react'
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap'
import { InputCurrency } from '../InputCurrency'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { CREATE_BUDGET } from '../../../graphql/mutations/userMutations'

export const ModalAddBudget = ({ infoBudget, isOpen, onClose, refetch }) => {
  console.log('infoBudget', infoBudget)
  const [gqlCreate, { data, loading, error }] = useMutation(CREATE_BUDGET)
  const formik = useFormik({
    initialValues: {
      budgetId: '',
      userId: '',
      value: ''
    },
    validationSchema: Yup.object({
      value: Yup.string().required('El valor es requerido')
    }),
    onSubmit: (values) => {
      console.log('values', values)
      if (!infoBudget.idAgency) return
      const input = {
        userId: values.userId,
        value: values.value,
        agencyId: infoBudget.idAgency
      }
      if (values.budgetId) {
        input.budgetId = values.budgetId
      }
      gqlCreate({
        variables: { input }
      })
    }
  })

  useEffect(() => {
    if (!infoBudget) return formik.resetForm()

    formik.setValues({
      budgetId: infoBudget?.budgetId || '',
      userId: infoBudget.userId,
      value: infoBudget?.budgetValue || ''
    })
  }, [infoBudget])

  useEffect(() => {
    if (error) toast.error('Error: ' + error.message)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.budgetCreateUpdate.success) {
      toast.success('Presupuesto actualizado correctamente')
      refetch()
      onClose()
    } else if (data.budgetCreateUpdate?.errors) {
      toast.error(
        `Error ${
          data.budgetCreateUpdate?.errors?.message ||
          'No actualizar presupuesto'
        }`
      )
    }
  }, [data])

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalHeader className='p-4 bg-soft-info' toggle={onClose}>
        {infoBudget.budgetValue ? 'Editar' : 'Crear'} presupuesto
      </ModalHeader>
      <form onSubmit={formik.handleSubmit}>
        <ModalBody>
          <Row>
            <Col lg={12} className='mb-4'>
              Complete el siguiente campo para{' '}
              {infoBudget.budgetValue ? 'editar' : 'crear'} el presupuesto a{' '}
              <span className='text-uppercase fw-bold'>
                {infoBudget.nameUser}
              </span>{' '}
              perteneciente a la agencia{' '}
              <span className='text-uppercase fw-bold text-info'>
                "{infoBudget.name}"
              </span>
            </Col>
            <Col lg={12}>
              <FormGroup id='value'>
                <Label htmlFor='value-ProductInfo'>Valor</Label>
                <InputCurrency
                  name='value'
                  value={formik.values.value}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value)
                  }}
                  onBlur={() => formik.setFieldTouched('value', true)}
                  className={`form-control ${
                    formik.touched.value && formik.errors.value
                      ? 'is-invalid'
                      : ''
                  } `}
                />
                {formik.touched.value && formik.errors.value && (
                  <FormFeedback>{formik.errors.value}</FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color='light'
            onClick={() => {
              onClose()
            }}
            type='button'
          >
            Cancelar
          </Button>
          <Button
            color='info'
            type='submit'
            disabled={loading}
            className='d-flex align-items-center gap-1'
          >
            {loading && <Spinner color='light' size='sm' />}
            Guardar
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
