import { useRef, useState } from 'react'
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import html2pdf from 'html2pdf.js'

import imgLogoPrecision from '../../../assets/images/totalsales/precision.JPG'
import moment from 'moment'
import { InfoEndeudamiento } from './InfoEndeudamiento'
import { parsePrice, trasnformToArr } from '../../../helpers'
import { InfoSectorReal } from './InfoSectorReal'

export const ModalCentralesJson = ({ isOpen, info, onClose, document }) => {
  const [loadingDown, setLoadingDown] = useState(false)
  const contentRef = useRef(null)
  const json = JSON.parse(info || '{}')

  console.log('json', json)

  if (Object.keys(json).length === 0) {
    return (
      <Modal isOpen={isOpen} toggle={onClose} fullscreen>
        <ModalHeader toggle={onClose}>Consulta</ModalHeader>
        <ModalBody>
          <p className='fs-2'>No se encontró información para este usuario</p>
        </ModalBody>
      </Modal>
    )
  }

  const {
    transunion: { PEC },
    quota_prevalidate: quotaPre
  } = json

  const downloadPdf = () => {
    setLoadingDown(true)
    if (contentRef.current) {
      html2pdf()
        .set({
          pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
          margin: [2, 2],
          image: { type: 'jpeg' },
          html2canvas: {
            scale: 2,
            letterRendering: true,
            useCORS: true
          },
          jsPDF: {
            orientation: 'l'
          },
          filename: `${moment().format('DD-MM')}-${document}.pdf`
        })
        .from(contentRef.current)
        .save()
        .then(() => {
          setLoadingDown(false)
        })
    }
  }

  if (!PEC) {
    return (
      <Modal isOpen={isOpen} toggle={onClose} fullscreen>
        <ModalHeader toggle={onClose}>Consulta</ModalHeader>
        <ModalBody>
          <p className='fs-2'>No se encontró información para este usuario</p>
        </ModalBody>
      </Modal>
    )
  }

  const { response_service: resServ } = PEC || {}

  /** @type {{Tercero: {CodigoCiiu:string;CodigoTipoIndentificacion: string;Entidad:string;Estado:string;Fecha:Date;FechaExpedicion:Date;Hora:string;LugarExpedicion:string;NombreCiiu: string;NombreTitular:string;NumeroIdentificacion:string;NumeroInforme:string;RangoEdad:string;RespuestaConsulta:string;TipoIdentificacion:        string;}, CreditVision_5694: {fechaCorte: {variables: {id: number, nombre: string, valor: string}[]}[]}}} */
  const {
    Tercero,
    CreditVision_5694: creditVision5,
    Informacion_Comercial_154: infoComercial154
  } = resServ || {}

  if (!Tercero) {
    return (
      <Modal isOpen={isOpen} toggle={onClose} fullscreen>
        <ModalHeader toggle={onClose}>Consulta</ModalHeader>
        <ModalBody>
          <p className='fs-2'>No se encontró información para este usuario</p>
        </ModalBody>
      </Modal>
    )
  }

  const infoScore = creditVision5
    ? creditVision5.fechaCorte.find(({ variables }) =>
        variables.find(({ nombre }) => nombre === 'CREDITVISION')
      )
    : null

  const infoScoreValue = infoScore?.variables?.[0]?.valor

  /** @type { {CantidadObligacionesMora:string; CuotaObligacionesDia:string; CuotaObligacionesMora:string; NumeroObligaciones:string; NumeroObligacionesDia:string; PaqueteInformacion:string; ParticipacionDeuda:string; SaldoObligacionesDia:string; SaldoObligacionesMora:string; TotalSaldo:string; ValorMora:string;}[]} */
  const dEndeudamiento = trasnformToArr(
    infoComercial154?.Consolidado?.ResumenPrincipal?.Registro
  )

  /** @type {{CantidadObligacionesMora: string;CuotaObligacionesDia: string;CuotaObligacionesMora: string;NumeroObligaciones: string;NumeroObligacionesDia: string;PaqueteInformacion: string;ParticipacionDeuda: string;SaldoObligacionesDia: string;SaldoObligacionesMora: string;TotalSaldo: string;ValorMora: string;}} */
  const dEndTotal = infoComercial154?.Consolidado?.Registro

  /** @type {{ChequesDevueltos: string;Ciudad: string;Comportamientos: string;DiasCartera: string;EstadoObligacion: string;FechaApertura: string;FechaCorte: string;FechaPermanencia: string;FechaTerminacion: string;IdentificadorLinea: string;NombreEntidad: string;NumeroObligacion: string;PaqueteInformacion: string;Sucursal: string;TipoContrato: string;TipoEntidad: string;ValorInicial: string;}[]}  */
  const cuVigentes = trasnformToArr(
    infoComercial154?.CuentasVigentes?.Obligacion
  )

  /** @type {cuVigentes} */
  const cuNoVigentes = trasnformToArr(
    infoComercial154?.CuentasExtinguidas?.Obligacion
  )

  const vigAlDia = infoComercial154?.SectorFinancieroAlDia?.Obligacion
  const vigExting = infoComercial154?.SectorFinancieroExtinguidas?.Obligacion

  const vigAlDiaReal = infoComercial154?.SectorRealAlDia?.Obligacion
  const vigExtingReal = infoComercial154?.SectorRealExtinguidas?.Obligacion

  const huella = trasnformToArr(infoComercial154?.HuellaConsulta?.Consulta)

  return (
    <Modal isOpen={isOpen} toggle={onClose} fullscreen>
      <ModalHeader toggle={onClose}>Consulta</ModalHeader>
      <ModalBody>
        <div
          className='bg-white p-4'
          ref={contentRef}
          // style={{ fontSize: loadingDown ? '10px' : undefined }}
        >
          <div className='w-100 d-flex align-items-center justify-content-center mb-5'>
            <div className='d-flex align-items-end gap-4 flex-column flex-md-row'>
              <img src={imgLogoPrecision} alt='logo precision' width={200} />
              <div>
                <h2 className='m-0 p-0 fs-4'>SOLUCIONES INTEGRALES OB SAS</h2>
                <h6 className='m-0 p-0 fs-6'>
                  {moment().format('DD/MM/YY')} {moment().format('hh:mm:ss A')}
                </h6>
              </div>
            </div>
          </div>
          <div style={{ overflowX: 'auto', width: '100%' }}>
            <table
              className='w-100'
              style={{ borderCollapse: 'separate' }}
              cellSpacing='4px'
            >
              <thead>
                <tr style={{ backgroundColor: '#006685' }}>
                  <th colSpan={6} className='text-center py-1 text-white fs-5'>
                    Resultado de la Consulta
                  </th>
                </tr>
              </thead>
              <tbody style={{ paddingInline: '3px' }}>
                <tr>
                  <th
                    style={{
                      backgroundColor: '#666666',
                      color: 'white',
                      paddingInline: '7px'
                    }}
                  >
                    TIPO IDENTIFICACIÓN
                  </th>
                  <td
                    style={{ backgroundColor: '#ededed', paddingInline: '7px' }}
                  >
                    {Tercero?.TipoIdentificacion || 'N/R'}
                  </td>
                  <th
                    style={{
                      backgroundColor: '#666666',
                      color: 'white',
                      paddingInline: '7px'
                    }}
                  >
                    EST DOCUMENTO
                  </th>
                  <td
                    style={{ backgroundColor: '#ededed', paddingInline: '7px' }}
                  >
                    {Tercero?.Estado || 'N/R'}
                  </td>
                  <th
                    style={{
                      backgroundColor: '#666666',
                      color: 'white',
                      paddingInline: '7px'
                    }}
                  >
                    FECHA
                  </th>
                  <td
                    style={{ backgroundColor: '#ededed', paddingInline: '7px' }}
                  >
                    {Tercero?.Fecha
                      ? moment(Tercero.Fecha).format('DD/MM/YY')
                      : 'N/R'}
                  </td>
                </tr>
                <tr>
                  <th
                    style={{
                      backgroundColor: '#666666',
                      color: 'white',
                      paddingInline: '7px'
                    }}
                  >
                    No. IDENTIFICACIÓN
                  </th>
                  <td
                    style={{ backgroundColor: '#ededed', paddingInline: '7px' }}
                  >
                    {Tercero?.NumeroIdentificacion || 'N/R'}
                  </td>
                  <th
                    style={{
                      backgroundColor: '#666666',
                      color: 'white',
                      paddingInline: '7px'
                    }}
                  >
                    FECHA EXPEDICIÓN
                  </th>
                  <td
                    style={{ backgroundColor: '#ededed', paddingInline: '7px' }}
                  >
                    {Tercero?.FechaExpedicion
                      ? moment(Tercero.FechaExpedicion).format('DD/MM/YY')
                      : 'N/R'}
                  </td>
                  <th
                    style={{
                      backgroundColor: '#666666',
                      color: 'white',
                      paddingInline: '7px'
                    }}
                  >
                    HORA
                  </th>
                  <td
                    style={{ backgroundColor: '#ededed', paddingInline: '7px' }}
                  >
                    {Tercero?.Hora || 'N/R'}
                  </td>
                </tr>
                <tr>
                  <th
                    style={{
                      backgroundColor: '#666666',
                      color: 'white',
                      paddingInline: '7px'
                    }}
                  >
                    NOMBRES APELLIDOS - RAZÓN SOCIAL
                  </th>
                  <td
                    style={{ backgroundColor: '#ededed', paddingInline: '7px' }}
                  >
                    {Tercero?.NombreTitular || 'N/R'}
                  </td>
                  <th
                    style={{
                      backgroundColor: '#666666',
                      color: 'white',
                      paddingInline: '7px'
                    }}
                  >
                    LUGAR DE EXPEDICIÓN
                  </th>
                  <td
                    style={{ backgroundColor: '#ededed', paddingInline: '7px' }}
                  >
                    {Tercero?.LugarExpedicion || 'N/R'}
                  </td>
                  <th
                    style={{
                      backgroundColor: '#666666',
                      color: 'white',
                      paddingInline: '7px'
                    }}
                  >
                    USUARIO
                  </th>
                  <td
                    style={{ backgroundColor: '#ededed', paddingInline: '7px' }}
                  >
                    INTEGRACIÓN WEB SERVICE
                  </td>
                </tr>
                <tr>
                  <th
                    style={{
                      backgroundColor: '#666666',
                      color: 'white',
                      paddingInline: '7px'
                    }}
                  >
                    ACTIVIDAD ECONÓMICA - CIIU
                  </th>
                  <td
                    style={{ backgroundColor: '#ededed', paddingInline: '7px' }}
                  >
                    {Tercero?.NombreCiiu || '-'}
                  </td>
                  <th
                    style={{
                      backgroundColor: '#666666',
                      color: 'white',
                      paddingInline: '7px'
                    }}
                  >
                    RANGO EDAD PROBABLE
                  </th>
                  <td
                    style={{ backgroundColor: '#ededed', paddingInline: '7px' }}
                  >
                    {Tercero?.RangoEdad || '-'}
                  </td>
                  <th
                    style={{
                      backgroundColor: '#666666',
                      color: 'white',
                      paddingInline: '7px'
                    }}
                  >
                    No INFORME
                  </th>
                  <td
                    style={{ backgroundColor: '#ededed', paddingInline: '7px' }}
                  >
                    {Tercero?.NumeroInforme || '-'}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className='w-100 d-flex gap-2 text-muted justify-content-between'
            style={{ fontSize: '11px' }}
          >
            <p className='mt-1'>
              * Todos los valores de la consulta están expresados en miles de
              pesos
            </p>
            <div className='d-flex flex-column align-items-end'>
              <p className='m-0 p-0'>
                Se presenta reporte negativo cuando la(s) persona(s) naturales y
                jurídicas efectivamente se encuentran en mora en sus cuotas u
                obligaciones
              </p>
              <p className='m-0 p-0'>
                Se presenta reporte positivo cuando la(s) persona(s) naturales y
                jurídicas están al día en sus obligaciones.
              </p>
            </div>
          </div>

          {quotaPre && (
            <div className='pt-5'>
              <h4 className='text-center' style={{ color: '#27486a' }}>
                Información del preaprobado
              </h4>
              <div style={{ overflowX: 'auto', width: '100%' }}>
                <table
                  className='w-100'
                  style={{ borderCollapse: 'separate' }}
                  cellSpacing='4px'
                >
                  <thead>
                    <tr style={{ backgroundColor: '#006685' }}>
                      <th colSpan={6} className='text-center text-white fs-5'>
                        Preaprobado
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th
                        style={{
                          backgroundColor: '#00a6ca',
                          padding: '4px 6px',
                          color: 'white',
                          textAlign: 'center'
                        }}
                      >
                        SUELDO
                      </th>
                      <th
                        style={{
                          backgroundColor: '#00a6ca',
                          padding: '4px 6px',
                          color: 'white',
                          textAlign: 'center'
                        }}
                      >
                        OBLIGACIONES
                      </th>
                      <th
                        style={{
                          backgroundColor: '#00a6ca',
                          padding: '4px 6px',
                          color: 'white',
                          textAlign: 'center'
                        }}
                      >
                        MONTO DISPONIBLE
                      </th>
                    </tr>
                    <tr>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {quotaPre.sueldo ? parsePrice(quotaPre.sueldo) : '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {quotaPre.obligaciones
                          ? parsePrice(quotaPre.obligaciones)
                          : '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {quotaPre['monto disponible']
                          ? parsePrice(quotaPre['monto disponible'])
                          : '-'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <div className='pt-5'>
            <h4 className='text-center' style={{ color: '#27486a' }}>
              Información Score
            </h4>
            <div style={{ overflowX: 'auto', width: '100%' }}>
              <table
                className='w-100'
                style={{ borderCollapse: 'separate' }}
                cellSpacing='4px'
              >
                <thead>
                  <tr style={{ backgroundColor: '#006685' }}>
                    <th colSpan={6} className='text-center text-white fs-5'>
                      Información Score
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      SCORE
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      INDICADOR BUENO / MALO
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CV LINK GROUP
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      NEGATIVE REASON CODE
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      POSITIVE REASON CODE
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      SUBPOBLACION
                    </th>
                  </tr>
                  <tr>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      {infoScoreValue || '-'}
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      -
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className='pt-5'>
            <h4 className='text-center' style={{ color: '#27486a' }}>
              Resumen Endeudamiento
            </h4>
            <div style={{ overflowX: 'auto', width: '100%' }}>
              <table
                className='w-100'
                style={{ borderCollapse: 'separate' }}
                cellSpacing='4px'
              >
                <thead>
                  <tr style={{ backgroundColor: '#006685' }}>
                    <th colSpan={11} className='text-center text-white fs-5'>
                      Resumen de Obligaciones (Como Principal)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      rowSpan={2}
                    >
                      OBLIGACIONES
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      colSpan={3}
                    >
                      TOTALES
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      colSpan={3}
                    >
                      OBLIGACIONES AL DÍA
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      colSpan={4}
                    >
                      OBLIGACIONES EN MORA
                    </th>
                  </tr>
                  <tr>
                    {/* th */}
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CANT
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      SALDO TOTAL
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      PADE
                    </th>

                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CANT
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      SALDO TOTAL
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CUOTA
                    </th>

                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CANT
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      SALDO TOTAL
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CUOTA
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      VALOR EN MORA
                    </th>
                  </tr>

                  {dEndeudamiento.map((end, i) => (
                    <tr
                      key={i}
                      style={{
                        color:
                          end?.PaqueteInformacion === 'Subtotal Principal'
                            ? 'white'
                            : undefined
                      }}
                    >
                      <td
                        style={{
                          backgroundColor:
                            end?.PaqueteInformacion === 'Subtotal Principal'
                              ? '#666666'
                              : '#ededed',
                          padding: '2px 5px',
                          textAlign: 'left',
                          textTransform:
                            end?.PaqueteInformacion === 'Subtotal Principal'
                              ? 'uppercase'
                              : undefined
                        }}
                      >
                        {end?.PaqueteInformacion || ''}
                      </td>

                      <td
                        style={{
                          backgroundColor:
                            end?.PaqueteInformacion === 'Subtotal Principal'
                              ? '#666666'
                              : '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {end?.NumeroObligaciones || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            end?.PaqueteInformacion === 'Subtotal Principal'
                              ? '#666666'
                              : '#ededed',
                          padding: '2px 5px',
                          textAlign: 'right'
                        }}
                      >
                        {end?.TotalSaldo || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            end?.PaqueteInformacion === 'Subtotal Principal'
                              ? '#666666'
                              : '#ededed',
                          padding: '2px 5px',
                          textAlign: 'right'
                        }}
                      >
                        {end?.ParticipacionDeuda || '-'}
                      </td>

                      <td
                        style={{
                          backgroundColor:
                            end?.PaqueteInformacion === 'Subtotal Principal'
                              ? '#666666'
                              : '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {end?.NumeroObligacionesDia || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            end?.PaqueteInformacion === 'Subtotal Principal'
                              ? '#666666'
                              : '#ededed',
                          padding: '2px 5px',
                          textAlign: 'right'
                        }}
                      >
                        {end?.SaldoObligacionesDia || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            end?.PaqueteInformacion === 'Subtotal Principal'
                              ? '#666666'
                              : '#ededed',
                          padding: '2px 5px',
                          textAlign: 'right'
                        }}
                      >
                        {end?.CuotaObligacionesDia || '-'}
                      </td>

                      <td
                        style={{
                          backgroundColor:
                            end?.PaqueteInformacion === 'Subtotal Principal'
                              ? '#666666'
                              : '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {end?.CuotaObligacionesMora || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            end?.PaqueteInformacion === 'Subtotal Principal'
                              ? '#666666'
                              : '#ededed',
                          padding: '2px 5px',
                          textAlign: 'right'
                        }}
                      >
                        {end?.CantidadObligacionesMora || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            end?.PaqueteInformacion === 'Subtotal Principal'
                              ? '#666666'
                              : '#ededed',
                          padding: '2px 5px',
                          textAlign: 'right'
                        }}
                      >
                        {end?.CuotaObligacionesMora || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            end?.PaqueteInformacion === 'Subtotal Principal'
                              ? '#666666'
                              : '#ededed',
                          padding: '2px 5px',
                          textAlign: 'right'
                        }}
                      >
                        {end?.ValorMora || '-'}
                      </td>
                    </tr>
                  ))}
                  {dEndeudamiento.length === 0 && (
                    <tr>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'left'
                        }}
                      />

                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        -
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'right'
                        }}
                      >
                        -
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'right'
                        }}
                      >
                        -
                      </td>

                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        -
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'right'
                        }}
                      >
                        -
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'right'
                        }}
                      >
                        -
                      </td>

                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        -
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'right'
                        }}
                      >
                        -
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'right'
                        }}
                      >
                        -
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'right'
                        }}
                      >
                        -
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td
                      colSpan={11}
                      style={{ backgroundColor: 'white', paddingTop: '22px' }}
                    />
                  </tr>

                  <tr style={{ backgroundColor: '#006685' }}>
                    <th colSpan={11} className='text-center text-white fs-5'>
                      Resumen Total de Obligaciones
                    </th>
                  </tr>
                  <tr style={{ color: 'white' }}>
                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'left',
                        textTransform: 'uppercase'
                      }}
                    >
                      {dEndTotal?.PaqueteInformacion || ''}
                    </td>

                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      {dEndTotal?.NumeroObligaciones || ''}
                    </td>
                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      {dEndTotal?.TotalSaldo || ''}
                    </td>
                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      {dEndTotal?.ParticipacionDeuda || ''}
                    </td>

                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      {dEndTotal?.NumeroObligacionesDia || ''}
                    </td>
                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      {dEndTotal?.SaldoObligacionesDia || ''}
                    </td>
                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      {dEndTotal?.CuotaObligacionesDia || ''}
                    </td>

                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      {dEndTotal?.CuotaObligacionesMora || ''}
                    </td>
                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      {dEndTotal?.CantidadObligacionesMora || ''}
                    </td>
                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      {dEndTotal?.CuotaObligacionesMora || ''}
                    </td>
                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      {dEndTotal?.ValorMora || ''}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className='pt-5' id='end'>
            <h4 className='text-center' style={{ color: '#27486a' }}>
              Informe Detallado
            </h4>
            <div style={{ overflowX: 'auto', width: '100%' }}>
              <table
                className='w-100'
                style={{ borderCollapse: 'separate', fontSize: '12px' }}
                cellSpacing='4px'
              >
                <thead>
                  <tr style={{ backgroundColor: '#006685' }}>
                    <th colSpan={13} className='text-center text-white fs-5'>
                      Información de Cuentas
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      FECHA CORTE
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      TIPO CONTRATO
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      No. CUENTA
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      ESTADO
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      TIPO ENT
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      ENTIDAD
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CIUDAD
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      SUCURSAL
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      FECHA APERTURA
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CUPO SOBREGIRO
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      DIAS AUTOR
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      FECHA PERMANENCIA
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CHEQ DEVUELTOS ÚLTIMO MES
                    </th>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: '#ededed' }} colSpan={13}>
                      <div className='pt-4 d-flex algin-items-start justify-content-start fw-bold'>
                        ESTADO: VIGENTES
                      </div>
                    </td>
                  </tr>
                  {cuVigentes.map((n, idx) => (
                    <tr key={idx}>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.FechaCorte || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.TipoContrato || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.NumeroObligacion || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.EstadoObligacion || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.TipoEntidad || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.NombreEntidad || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.Ciudad || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.Sucursal || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.FechaApertura || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        -
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.DiasCartera || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.FechaPermanencia || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.ChequesDevueltos || '-'}
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <td style={{ backgroundColor: '#ededed' }} colSpan={13}>
                      <div className='pt-4 d-flex algin-items-start justify-content-start fw-bold'>
                        ESTADO: NO VIGENTES
                      </div>
                    </td>
                  </tr>
                  {cuNoVigentes.map((n, idx) => (
                    <tr key={idx}>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.FechaCorte || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.TipoContrato || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.NumeroObligacion || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.EstadoObligacion || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.TipoEntidad || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.NombreEntidad || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.Ciudad || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.Sucursal || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.FechaApertura || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        -
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.DiasCartera || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.FechaPermanencia || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.ChequesDevueltos || '-'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <InfoEndeudamiento
            vigAlDia={vigAlDia}
            vigExting={vigExting}
            loading={loadingDown}
          />

          <InfoSectorReal vigentes={vigAlDiaReal} extinguidas={vigExtingReal} />

          <div className='pt-5 px-5'>
            <h4 className='text-center' style={{ color: '#27486a' }}>
              Huella de Consulta
            </h4>
            <div style={{ overflowX: 'auto', width: '100%', fontSize: '10px' }}>
              <table
                className='w-100'
                style={{ borderCollapse: 'separate' }}
                cellSpacing='4px'
              >
                <thead>
                  <tr style={{ backgroundColor: '#006685' }}>
                    <th colSpan={4} className='text-center text-white fs-5'>
                      Huella de Consulta en los Últimos Seis Meses
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      ENTIDAD
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      FECHA
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      SUCURSAL
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CIUDAD
                    </th>
                  </tr>
                  {huella.map((n, i) => (
                    <tr key={i}>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.NombreEntidad || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.FechaConsulta || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.Sucursal || '-'}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n.Ciudad || '-'}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td
                      colSpan={4}
                      style={{ backgroundColor: 'white', paddingTop: '3px' }}
                    />
                  </tr>
                  {huella?.length > 0 && (
                    <tr>
                      <td
                        colSpan={4}
                        style={{
                          backgroundColor: '#666666',
                          color: 'white',
                          fontWeight: 'bold'
                        }}
                      >
                        Total consultas: {huella.length}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className='d-flex align-items-center justify-content-center fw-bold fs-3 pt-5'>
            <span style={{ marginTop: '7px', display: 'inline-block' }}>
              {' '}
              * * * * * * * * * * *
            </span>
            <span> F I N </span>
            <span style={{ marginInline: '10px' }}> D E </span>{' '}
            <span>C O N S U L T A</span>
            <span style={{ marginTop: '7px', display: 'inline-block' }}>
              {' '}
              * * * * * * * * * * *
            </span>
          </div>

          <div
            className='py-5 d-flex align-items-center justify-content-end mt-5'
            style={{ backgroundColor: '#006685' }}
          >
            <div
              className='d-flex align-items-center gap-3 fs-4 pe-4'
              style={{ opacity: loadingDown ? 0 : undefined }}
            >
              <button
                style={{
                  border: 0,
                  padding: 0,
                  backgroundColor: 'transparent',
                  color: 'white'
                }}
                onClick={downloadPdf}
                disabled={loadingDown}
              >
                {loadingDown && <Spinner size='sm' />}
                DESCARGAR
              </button>
              <button
                style={{
                  border: 0,
                  padding: 0,
                  backgroundColor: 'transparent',
                  color: 'white'
                }}
                onClick={onClose}
              >
                CERRAR
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
