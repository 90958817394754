import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from 'reactstrap'
import { parseId } from '../../../helpers'
import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { CHANGE_TASA_WALLET } from '../../../graphql/mutations/creditWalletMutations'

export const ModalChangeTasa = ({ isOpen, onClose, refetch, infoTasa }) => {
  const [gqlChangeTasa, { data, loading, error }] =
    useMutation(CHANGE_TASA_WALLET)

  useEffect(() => {
    if (error) {
      toast.error(`ERROR: ${error.message}`)
    }
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.changeCreditMonthlyFee?.success) {
      toast.success('Tasa actualizada correctamente')
      onClose()
      refetch()
    } else if (data.changeCreditMonthlyFee?.errors?.message) {
      toast.error(
        `Error ${
          data.changeCreditMonthlyFee?.errors?.message || 'algo ocurrió'
        }`
      )
    }
  }, [data])

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader className='bg-soft-info p-4' toggle={onClose}>
        Cambiar tasa
      </ModalHeader>
      <Form
        id='form-change-revition-credit'
        onSubmit={(e) => {
          e.preventDefault()
          const { monthlyFee } = Object.fromEntries(new FormData(e.target))
          const input = {
            id: parseId(infoTasa.id),
            monthlyFee
          }

          gqlChangeTasa({
            variables: {
              input
            }
          })
        }}
      >
        <ModalBody>
          <Alert color='warning'>
            <h3 className='d-flex align-items-center gap-2'>
              <i className='mdi mdi-alert fs-2' /> ¡ATENCIÓN!
            </h3>
            <p>Este crédito tiene una tasa mensual de: </p>
            <p className='fs-1 text-center fw-bold'>{infoTasa.monthlyFee} %</p>
          </Alert>
          <FormGroup>
            <Label htmlFor='textarea45'>Nueva tasa</Label>
            <Input
              placeholder='Ingresa nueva tasa'
              type='number'
              name='monthlyFee'
              id='temontyh45'
              required
              step='0.01'
              min={0.01}
              defaultValue={infoTasa.monthlyFee || '0'}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color='light'
            type='button'
            onClick={() => {
              onClose()
            }}
          >
            Cancelar
          </Button>
          <Button
            type='submit'
            disabled={loading}
            className='d-flex align-items-center gap-1'
          >
            {loading && <Spinner color='light' size='sm' />}
            Cambiar tasa
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
