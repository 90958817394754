import moment from 'moment'
import React from 'react'
import { Row, Table } from 'reactstrap'
import { parsePrice } from '../../../helpers'

/** @param {{data: string}}  */
export const FormDataView = ({ data }) => {
  /**
   * @param {string} value
   * @param {string} type
   * @returns {string}
   */
  const getValueParse = (value, type) => {
    const TYPES_SOFTS = ['text', 'int', 'float', 'email', 'select']
    if (TYPES_SOFTS.includes(type)) return value
    if (type === 'date') return moment(value).format('DD MMM [del] YYYY')
    if (type === 'datetime') {
      return moment(value).format('DD MMM [del] YYYY [-] hh:mm A')
    }
    if (type === 'money') {
      if (/^\d+(\.\d+)?$/.test(value)) {
        return parsePrice(value)
      }
      return value
    }
    if (type === 'boolean') return value ? 'SI' : 'NO'
  }

  /** @type {{form:string, sections: {section: string, fields: {name: string, value: string, attrs: {type: string}}[]}[]}[]} */
  const dataParse = JSON.parse(data)
  console.log('dataParse', dataParse)
  return dataParse.map(({ form, sections }) => (
    <Row key={form}>
      <h5 className='text-center my-3'>{form}</h5>
      {sections.map(({ section, fields }) => (
        <div
          key={section}
          style={{ borderTop: '1px solid #c4c4c4', padding: '0.85em 0' }}
        >
          <h6 className='ms-4 text-info fw-bold'>{section}</h6>
          <Table className='table table-borderless mb-0'>
            <tbody>
              {fields.map(({ name, value, attrs }) => (
                <tr key={name}>
                  <th>{attrs.label}</th>
                  <td
                    style={{
                      color:
                        attrs.type === 'boolean'
                          ? value
                            ? 'green'
                            : 'red'
                          : undefined
                    }}
                  >
                    {getValueParse(value, attrs.type)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ))}
    </Row>
  ))
}
