import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Input,
  Spinner,
  Table,
  UncontrolledTooltip
} from 'reactstrap'
// import TableContainer from '../../../Components/Common/TableContainer'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import Flatpickr from 'react-flatpickr'
import { Spanish } from 'flatpickr/dist/l10n/es'
/*
import {
    useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table' */
import { useLazyQuery, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { hasPermission, parseId } from '../../../helpers'
import { ALL_PERMISSIONS, ERROR_MESSAGES } from '../../../helpers/consts'
import { useGetUser } from '../../../hooks/user'
import { GET_AGENCIAS_TO_CREDITS } from '../../../graphql/queries/getCredits'
import moment from 'moment'
import { ALL_CREDITS_WALLET } from '../../../graphql/queries/getCreditsWallet'
import { ModalShowInfoUser } from '../../../Components/Own/CreditWalletPage/ModalShowInfoUser'
import { ModalShowInfoCosingers } from '../../../Components/Own/CreditWalletPage/ModalShowInfoCosingers'
import { ModalInfoComment } from '../../../Components/Own/CreditWalletPage/ModalInfoComment'
import Select from 'react-select'
// import { DefaultColumnFilter } from '../../../Components/Common/filters'

// import TableContainer from '../../../Components/Common/TableContainer'
// import { Type, Quantity, OrderValue, AvgPrice, Price, Status } from './OrderCol'

export default function CreditsWalletPage() {
  const [gqlGetCredits, { data, loading, error }] = useLazyQuery(
    ALL_CREDITS_WALLET,
    {
      fetchPolicy: 'no-cache'
    }
  )
  const { data: dataAgencies, loading: loadingAgencies } = useQuery(
    GET_AGENCIAS_TO_CREDITS
  )
  const navigate = useNavigate()
  const { logout, permissions } = useGetUser()
  /**
   * @typedef {{node: {id: string, firstName: string, firstSurname: string, agency: { id: string, name: string }, productinformationSet: { edges: { node: { id: string, name: string }}[] }, lastComment: { id: string, comment: string, created: string, user: { id: string, firstName: string, lastName: string }},cosignerSet: { edges: {node:{id:string, firstName: string}}[]}}}} IData
   * @type {[IData[], React.Dispatch<React.SetStateAction<IData[]>>]}
   */
  const [dataTable, setDataTable] = useState([])

  const [isSearch, setIsSearch] = useState(false)

  const [searchInput, setSearchInput] = useState('')
  const [searchValue, setSearchValue] = useState({
    key: '',
    value: ''
  })
  const [datesValue, setDatesValue] = useState({
    createdGte: undefined,
    createdLte: undefined
  })
  const [agencySelect, setAgencySelect] = useState('')
  const [pagination, setPagination] = useState({
    offset: 0,
    page: 1
  })
  const [isPagination, setIsPagination] = useState(false)

  const [showInfoUser, setShowInfoUser] = useState(false)
  const [showCosingers, setShowCosingers] = useState(false)
  const [idCreditWallet, setIdCreditWallet] = useState(null)

  const [showInfoComment, setShowInfoComment] = useState(false)
  const [infoComment, setInfoComment] = useState(null)

  useEffect(() => {
    if (!hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_CREDITOS)) {
      navigate('/dashboard')
    }
  }, [permissions])

  useEffect(() => {
    const variables = {
      offset: pagination.offset
    }
    if (searchValue.key) {
      variables[searchValue.key] = searchValue.value
    }
    if (datesValue.createdGte && datesValue.createdLte) {
      variables.created_Gte = datesValue.createdGte
      variables.created_Lte = datesValue.createdLte
    }
    if (agencySelect) {
      variables.agency_Id = agencySelect.split('***')[0]
    }
    // console.log('variables', variables)
    gqlGetCredits({
      variables
    })
  }, [searchValue.value, datesValue, pagination.offset, agencySelect])

  useEffect(() => {
    if (data && data.walletCredits) {
      if (isPagination) {
        setDataTable((prev) => [...prev, ...data.walletCredits.edges])
        setPagination((prev) => ({ ...prev, page: prev.page + 1 }))
        setIsPagination(false)
      } else {
        setDataTable(data.walletCredits.edges)
      }
    }
  }, [data])

  /*   useTable({
    columns,
    data: dataTable.slice(10 * pagination.page - 10, 10 * pagination.page)
  }) */

  useEffect(() => {
    if (error) {
      toast.error(`Error! \n ${error.message}`)
      if (error.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [error])

  const searchForDates = (e) => {
    const data = [...e]
    if (data.length === 0) {
      setPagination({ page: 1, offset: 0 })
      setDatesValue({
        createdGte: undefined,
        createdLte: undefined
      })
      return
    }
    if (e.length < 2) return

    if (e && e.length === 2) {
      setIsSearch(true)
      const createdGte = new Date(e[0]).toISOString()
      const createdLte = new Date(e[1]).toISOString()

      return setDatesValue({
        createdGte,
        createdLte
      })
    }
    setDatesValue({
      createdGte: undefined,
      createdLte: undefined
    })
  }

  const hanldeSubmit = (e) => {
    e.preventDefault()
    if (searchInput.length >= 3) {
      const key = `${Number(searchInput) ? 'documentNumber' : 'name'}`
      setIsSearch(true)
      setPagination({ page: 1, offset: 0 })
      setSearchValue({
        key,
        value: searchInput
      })
    }
  }

  const toggleInfoUser = () => {
    setShowInfoUser(false)
  }
  const toggleInfoCosingers = () => {
    setShowCosingers(false)
  }
  const toggleComment = () => {
    setShowInfoComment(false)
  }

  document.title = 'Créditos | TOTAL SALES'

  if (loading && !isSearch && !isPagination) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Ver créditos' pageTitle='Créditos' />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between border-0 gap-3 flex-md-column flex-lg-row flex-sm-column '>
                  <div className='w-100'>
                    <h5 className='card-title mb-0'>Créditos</h5>
                  </div>
                  <div className='w-100'>
                    <p
                      className='placeholder-glow w-100 text-end'
                      style={{ height: '30px' }}
                    >
                      <span
                        className='placeholder col-4'
                        style={{ height: '100%' }}
                      />
                    </p>
                  </div>
                </CardHeader>
                <CardBody style={{ height: '30vh' }}>
                  <div className='d-flex justify-content-center align-items-center h-100'>
                    <Spinner style={{ width: '3em', height: '3em' }} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Ver créditos' pageTitle='Créditos' />
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className='d-flex align-items-center justify-content-between border-0 gap-3 flex-md-column flex-lg-row flex-sm-column '>
                <h5 className='card-title mb-0 d-block'>Créditos - cartera</h5>
              </CardHeader>
              <CardBody>
                <Row className='mb-3'>
                  <CardBody className='border border-dashed border-end-0 border-start-0'>
                    <Row className='g-4'>
                      <Col lg={4}>
                        <form onSubmit={hanldeSubmit}>
                          <div className='search-box me-2 mb-2 d-inline-block w-100'>
                            <input
                              id='search-bar-0'
                              type='search'
                              value={searchInput}
                              onChange={(e) => {
                                setSearchInput(e.target.value)
                                if (e.target.value === '') {
                                  setPagination({
                                    page: 1,
                                    offset: 0
                                  })
                                  setSearchValue({
                                    key: '',
                                    value: ''
                                  })
                                }
                              }}
                              name='searchInput'
                              className='form-control search /'
                              placeholder='Ingresa cédula o nombre'
                            />
                            <i className='bx bx-search-alt search-icon' />
                          </div>
                        </form>
                      </Col>

                      <Col lg={4}>
                        <div className='input-group'>
                          <span className='input-group-text' id='basic-addon1'>
                            <i className='ri-calendar-2-line' />
                          </span>
                          <Flatpickr
                            placeholder='Seleccione fecha(s)'
                            className='form-control'
                            onChange={(e) => searchForDates(e)}
                            options={{
                              mode: 'range',
                              dateFormat: 'd F y',
                              showMonths: 2,
                              locale: Spanish,
                              position: 'auto center'
                            }}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        {!dataAgencies || loadingAgencies ? (
                          <Spinner size='sm' />
                        ) : (
                          <Select
                            // className='form-select'
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused
                                  ? 'blue'
                                  : 'lightgray'
                              })
                            }}
                            isClearable
                            isSearchable
                            noOptionsMessage={() => 'No se encontró agencia'}
                            placeholder='Agencia'
                            options={[
                              // { label: 'Seleccione agencia', value: '' },
                              ...dataAgencies?.listAgency?.edges?.map(
                                ({ node }) => ({
                                  value: parseId(node.id),
                                  label: node.name
                                })
                              )
                            ]}
                            value={
                              agencySelect
                                ? {
                                    label: agencySelect.split('***')[1],
                                    value: agencySelect.split('***')[0]
                                  }
                                : null
                            }
                            onChange={(e) => {
                              setPagination({ page: 1, offset: 0 })
                              if (e == null) {
                                return setAgencySelect(null)
                              }
                              setAgencySelect(`${e.value}***${e.label}`)
                            }}
                          />
                        )}
                        {/* <select
                          className='form-select'
                          data-choices
                          data-choices-search-false
                          name='choices-single-default'
                          id='choices-single-defaultw3r'
                          value={agencySelect}
                          onChange={(e) => {
                            setAgencySelect(e.target.value)
                            setPagination({ page: 1, offset: 0 })
                          }}
                        >
                          <option value=''>Seleccione agencia</option>
                          {!dataAgencies || loadingAgencies ? (
                            <Spinner size='sm' />
                          ) : (
                            <>
                              {dataAgencies?.listAgency?.edges?.map(
                                ({ node }) => (
                                  <option
                                    key={node.id}
                                    value={parseId(node.id)}
                                  >
                                    {node.name}
                                  </option>
                                )
                              )}
                            </>
                          )}
                        </select> */}
                      </Col>
                    </Row>
                  </CardBody>
                </Row>

                {dataTable.length > 0 && (
                  <>
                    <div className='table-responsive table-card mb-1'>
                      <Table hover className='align-middle table-nowrap'>
                        <thead className='table-light text-muted'>
                          <tr>
                            <th>Agencia</th>
                            <th>ID crédito</th>
                            <th>Productos</th>
                            <th>Nombre</th>
                            <th>Codeudor(es)</th>
                            <th>Último comentario</th>
                            <th>Creado</th>
                            <th />
                          </tr>
                        </thead>

                        <tbody>
                          {dataTable
                            .slice(
                              10 * pagination.page - 10,
                              10 * pagination.page
                            )
                            .map(({ node }) => (
                              <tr key={node.id}>
                                <td>{node.agency.name}</td>
                                <td className='text-center'>
                                  {parseId(node.id)}
                                </td>
                                <td>
                                  {node.productinformationSet.edges.length >
                                  0 ? (
                                    <ul className='mb-0'>
                                      {node.productinformationSet.edges.map(
                                        ({ node: nodeProds }) => (
                                          <li key={nodeProds.id}>
                                            {nodeProds.name}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  ) : (
                                    <span className='text-danger d-flex align-items-center gap-1'>
                                      <i className='mdi mdi-alert-circle-outline' />
                                      No contiene productos
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <Button
                                    className='border-0 p-0 m-0 bg-transparent d-flex align-items-center gap-1 text-primary'
                                    title='Ver'
                                    onClick={() => {
                                      setShowInfoUser(true)
                                      setIdCreditWallet(node.id)
                                    }}
                                  >
                                    <span
                                      className='text-uppercase'
                                      style={{ textDecoration: 'underline' }}
                                    >
                                      {node.firstName} {node.firstSurname}
                                    </span>
                                    <i className='mdi mdi-eye' />
                                  </Button>
                                </td>
                                <td className='text-center'>
                                  {node?.cosignerSet?.edges.length > 0 ? (
                                    <Button
                                      className='d-flex align-items-center gap-1 bg-transparent m-0 p-0 border-0 text-primary justify-content-center w-100'
                                      style={{ textDecoration: 'underline' }}
                                      onClick={() => {
                                        setIdCreditWallet(node.id)
                                        setShowCosingers(true)
                                      }}
                                      title='Ver'
                                    >
                                      <i className='mdi mdi-format-list-bulleted text-center' />
                                      {node.cosignerSet.edges.length}
                                    </Button>
                                  ) : (
                                    <span>No contiene</span>
                                  )}
                                </td>
                                <td className='text-center'>
                                  {node?.lastComment ? (
                                    <Button
                                      className='d-flex align-items-center gap-1 bg-transparent m-0 p-0 border-0 text-primary justify-content-center w-100'
                                      style={{ textDecoration: 'underline' }}
                                      onClick={() => {
                                        setShowInfoComment(true)
                                        setInfoComment({ ...node.lastComment })
                                      }}
                                    >
                                      <i className='mdi mdi-comment-account-outline' />
                                      {node.lastComment.user?.firstName}
                                    </Button>
                                  ) : (
                                    <span>N/A</span>
                                  )}
                                </td>
                                <td>
                                  {moment(node.created).format(
                                    'DD MMM [del] YY'
                                  )}
                                </td>
                                {/* <td>{renderStatus(node.status)}</td> */}
                                <td>
                                  <ul className='list-inline hstack gap-2 mb-0'>
                                    <li className='list-inline-item'>
                                      <Link
                                        to={`/cartera_credito/${parseId(
                                          node.id
                                        )}`}
                                        className='text-primary d-inline-block'
                                        id={
                                          'view' + parseId(node.id).toString()
                                        }
                                        target='_blank'
                                        rel='noopener noreferrer'
                                      >
                                        <i className='ri-eye-fill fs-16' />
                                      </Link>
                                      <UncontrolledTooltip
                                        placement='top'
                                        target={
                                          'view' + parseId(node.id).toString()
                                        }
                                        trigger='hover'
                                      >
                                        Ver
                                      </UncontrolledTooltip>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                    <Row className='justify-content-md-end justify-content-center align-items-center p-2'>
                      <Col className='col-md-auto'>
                        <div className='d-flex gap-1'>
                          <Button
                            color='primary'
                            disabled={pagination.page <= 1}
                            onClick={() => {
                              setPagination((prev) => ({
                                ...prev,
                                page: prev.page - 1
                              }))
                            }}
                            // onClick={previousPage}
                            // disabled={!canPreviousPage}
                          >
                            {'<'}
                          </Button>
                        </div>
                      </Col>
                      <Col className='col-md-auto d-none d-md-block'>
                        Pág{' '}
                        <strong>
                          {pagination.page} de{' '}
                          {Math.ceil(dataTable.length / 10)}
                        </strong>
                      </Col>
                      <Col className='col-md-auto'>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault()
                            if (!Number(pagination.page)) {
                              setPagination((prev) => ({
                                ...prev,
                                page: 1
                              }))
                            }
                            // console.log('pagination', pagination)
                          }}
                        >
                          <Input
                            type='number'
                            min={1}
                            style={{ width: 70 }}
                            // max={pageOptions.length}
                            value={pagination.page}
                            onChange={(e) => {
                              const { value } = e.target
                              if (value === '') {
                                setPagination((prev) => ({
                                  ...prev,
                                  page: ''
                                }))
                                return
                              }
                              if (!Number(value)) {
                                setPagination((prev) => ({
                                  ...prev,
                                  page: 1
                                }))
                                return
                              }
                              if (
                                Number(value) <=
                                Math.ceil(dataTable.length / 10)
                              ) {
                                setPagination((prev) => ({
                                  ...prev,
                                  page: Number(value)
                                }))
                              }
                            }}
                          />
                        </form>
                      </Col>

                      <Col className='col-md-auto'>
                        <div className='d-flex gap-1'>
                          <Button
                            color='primary'
                            disabled={
                              !data?.walletCredits?.pageInfo?.hasNextPage &&
                              pagination.page ===
                                Math.ceil(dataTable.length / 10)
                            }
                            onClick={() => {
                              if (
                                pagination.page <
                                Math.ceil(dataTable.length / 10)
                              ) {
                                return setPagination((prev) => ({
                                  ...prev,
                                  page: prev.page + 1
                                }))
                              }
                              const start = parseId(
                                data?.walletCredits?.pageInfo?.startCursor
                              )
                              setIsPagination(true)
                              setPagination((prev) => ({
                                ...prev,
                                offset: start + 10
                              }))
                            }}
                            // disabled={!canNextPage}
                          >
                            {loading ? (
                              <Spinner size='sm' color='light' />
                            ) : (
                              '>'
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                {loading && isSearch ? (
                  <div
                    className='d-flex justify-content-center align-items-center flex-column gap-2'
                    style={{ height: '20vh' }}
                  >
                    <Spinner style={{ width: '3em', height: '3em' }} />
                    <span className='d-block'>cargando...</span>
                  </div>
                ) : (
                  dataTable.length === 0 && (
                    <div
                      className='d-flex justify-content-center align-items-center'
                      style={{ height: '20vh' }}
                    >
                      No hay Créditos en cartera
                    </div>
                  )
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      {idCreditWallet && (
        <ModalShowInfoUser
          showInfoUser={showInfoUser}
          idCreditWallet={idCreditWallet}
          toggle={toggleInfoUser}
        />
      )}
      {idCreditWallet && (
        <ModalShowInfoCosingers
          showCosingers={showCosingers}
          idCreditWallet={idCreditWallet}
          toggle={toggleInfoCosingers}
        />
      )}

      {infoComment && (
        <ModalInfoComment
          infoComment={infoComment}
          showInfoComment={showInfoComment}
          toggle={toggleComment}
        />
      )}
    </div>
  )
}
