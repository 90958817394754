import React from 'react'
import CurrencyInput from 'react-currency-input-field'
// import { INFO_CURRENCY_OBJ } from '../../helpers/consts'
import { useGetUser } from '../../hooks/user'

export const InputCurrency = ({
  id,
  name = 'InputCurrency',
  className = 'form-control',
  placeholder = 'Ingrese precio',
  // decimalsLimit = 0,
  /* decimalSeparator = ',',
  groupSeparator = '.', */
  value,
  // locale,
  // currency,
  required = false,
  allowNegativeValue = false,
  onValueChange,
  onBlur = () => {},
  defaultValue,
  autoComplete,
  disabled = false
}) => {
  const { user } = useGetUser()

  let objMoney = { locale: 'es-Co', currency: 'COP', minimumfractiondigits: 0 }
  try {
    objMoney = JSON.parse(user.tokenAuth.settings)
  } catch (error) {
    console.log('error', error)
  }
  const { currency, minimumfractiondigits, locale } = objMoney

  // const decimalsLimit = fractions
  const intlConfig = { locale, currency }
  return (
    <CurrencyInput
      decimalsLimit={Number(minimumfractiondigits)}
      // fixedDecimalLength={Number(minimumfractiondigits)}
      intlConfig={intlConfig}
      decimalScale={Number(minimumfractiondigits)}
      /* decimalSeparator={decimalSeparator}
      groupSeparator={groupSeparator} */
      // prefix={prefix}
      autoComplete={autoComplete}
      required={required}
      name={name}
      id={id}
      placeholder={placeholder}
      className={className}
      allowNegativeValue={allowNegativeValue}
      value={value}
      onValueChange={onValueChange}
      onBlur={onBlur}
      defaultValue={defaultValue}
      disabled={disabled}
    />
  )
}
