import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from 'reactstrap'
import { parseId, parsePrice } from '../../../helpers'
import { InputCurrency } from '../InputCurrency'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDisclosure } from '../../../hooks/useDisclosure'
import { useGetUser } from '../../../hooks/user'
import { useMutation } from '@apollo/client'
import { CALCULATOR } from '../../../graphql/mutations/userMutations'
import { toast } from 'react-toastify'
import { CREATE_ABONO_CAPITAL } from '../../../graphql/mutations/creditWalletMutations'

const validationSchema = Yup.object({
  value: Yup.string().required('Debe ingresar el abono a capital'),
  term: Yup.number().min(1, 'Debe ser mayor a cero').required(),
  obligationId: Yup.string().required()
})

export const TabAbonoCapital = ({
  capital,
  obligationId,
  refetch,
  backToResume
}) => {
  const [
    gqlCreateAbono,
    { data: dataCreate, loading: loadingCreate, error: errorCreate }
  ] = useMutation(CREATE_ABONO_CAPITAL)
  const [gqlCalculator, { data, loading, error }] = useMutation(CALCULATOR)

  const { isOpen, onClose, onOpen } = useDisclosure()
  const {
    isOpen: isOpenConfirm,
    onClose: onCloseConfirm,
    onOpen: onOpenConfirm
  } = useDisclosure()

  const [quota, setQuota] = useState(null)
  const [input, setInput] = useState(null)

  const { user } = useGetUser()

  const formik = useFormik({
    initialValues: {
      value: '',
      term: 0,
      obligationId
    },
    onSubmit: ({ obligationId, term, value }) => {
      if (obligationId && term && value) {
        onOpenConfirm()
        setInput({
          term,
          value
        })
      }
    },
    validationSchema
  })

  useEffect(() => {
    if (Number(formik.values.value) > Number(capital)) {
      formik.setFieldError('value', 'No debe superar el valor del capital')
    }
  }, [formik.values.value, capital])

  useEffect(() => {
    if (!error) return
    toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.calculator.success) {
      setQuota(data.calculator.quota)
      onClose()
    } else if (data.calculator?.errors) {
      toast.error(
        'Error: ' + data.calculator.errors?.message ||
          'No se pudo calcular crédito'
      )
    }
  }, [data])

  useEffect(() => {
    if (!errorCreate) return
    toast.error(`Error: ${errorCreate.message}`)
  }, [errorCreate])

  useEffect(() => {
    if (!dataCreate) return
    if (dataCreate.paymentCapital.success) {
      toast.success('Abono a capital creado correctamente')
      onCloseConfirm()
      backToResume()
      refetch()
    } else if (dataCreate.paymentCapital?.errors) {
      toast.error(
        'Error: ' + dataCreate.paymentCapital.errors?.message ||
          'No se pudo abonar capital'
      )
    }
  }, [dataCreate])

  if (!user) {
    return (
      <Card>
        <CardBody>
          <Spinner />
        </CardBody>
      </Card>
    )
  }

  const { max_quotation_fee: maxQuotationFee } = JSON.parse(
    user.tokenAuth.settings
  )

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Abono a capital</CardTitle>
        </CardHeader>
        <form onSubmit={formik.handleSubmit}>
          <CardBody>
            <Alert
              color='info'
              className='alert-border-left d-flex align-items-center gap-2'
            >
              <i className='mdi mdi-alert-outline fs-2' />
              Usa esta opción para refinanciar la obligación, haciendo un abono
              a capital y escogiendo el plazo adecuado
            </Alert>

            <h4 className='text-center'>
              Valor del capital: {parsePrice(capital)}{' '}
            </h4>

            <FormGroup>
              <Label htmlFor='value-field'>Valor del abono</Label>
              <InputCurrency
                id='value-field'
                name='value'
                placeholder='Ingrese valor'
                value={formik.values.value}
                className={`form-control ${
                  formik.touched.value && formik.errors.value
                    ? 'is-invalid'
                    : ''
                } `}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value)
                  setQuota(null)
                }}
                onBlur={formik.handleBlur}
              />
              {formik.touched.value && formik.errors.value && (
                <div
                  style={{
                    width: '100%',
                    marginTop: '0.25rem',
                    fontSize: '0.875em',
                    color: '#f06548'
                  }}
                >
                  {formik.errors.value}
                </div>
              )}
            </FormGroup>
            <div className='d-flex align-items-center gap-1 flex-column flex-md-row w-100 justify-content-between'>
              <FormGroup className='flex-1'>
                <Label>Plazo</Label>
                <Input
                  type='number'
                  disabled
                  value={formik.values.term}
                  invalid={formik.errors.term && formik.touched.term}
                />
                <FormFeedback>Debe seleccionar el plazo</FormFeedback>
              </FormGroup>
              <div>
                <Label />
                <Button
                  className='d-block mb-2'
                  type='button'
                  color='soft-danger'
                  disabled={
                    !Number(formik.values.value) ||
                    Number(formik.values.value) > Number(capital)
                  }
                  onClick={onOpen}
                >
                  Seleccionar plazo
                </Button>
              </div>
            </div>
            {quota && (
              <>
                <FormGroup className='flex-1'>
                  <Label>Valor de couta</Label>
                  <Input disabled readOnly defaultValue={parsePrice(quota)} />
                </FormGroup>
                <FormGroup>
                  <Label>Nuevo capital</Label>
                  <Input
                    disabled
                    readOnly
                    defaultValue={parsePrice(
                      Number(capital) - Number(formik.values.value)
                    )}
                  />
                </FormGroup>
              </>
            )}
          </CardBody>
          <CardFooter>
            <div className='d-flex w-100 align-items-center justify-content-end'>
              <Button type='submit' disabled={quota == null}>
                Refinanciar obligación
              </Button>
            </div>
          </CardFooter>
        </form>
      </Card>
      {isOpen && (
        <Modal isOpen={isOpen} size='sm'>
          <ModalHeader>Calcular crédito</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for='exampleRange'>
                Valor a prestar: (
                {parsePrice(Number(capital) - Number(formik.values.value))})
              </Label>
              <Input
                id='exampleRange'
                name='range'
                type='range'
                readOnly
                min={0}
                max={Number(capital)}
                value={Number(capital) - Number(formik.values.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for='rangeTerm'>Plazo: ({formik.values.term} meses)</Label>
              <Input
                id='rangeTerm'
                type='range'
                name='term'
                min={0}
                max={Number(maxQuotationFee)}
                value={formik.values.term}
                onChange={(e) => {
                  formik.handleChange(e)
                  setQuota(null)
                }}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color='light'
              onClick={() => {
                onClose()
                formik.setFieldValue('term', 0)
              }}
            >
              Cancelar
            </Button>
            <Button
              className='d-flex align-items-center gap-2'
              disabled={
                !Number(formik.values.value) || !Number(capital) || loading
              }
              color='info'
              onClick={() => {
                gqlCalculator({
                  variables: {
                    input: {
                      term: formik.values.term,
                      value: Number(capital) - Number(formik.values.value)
                    }
                  }
                })
              }}
            >
              {loading && <Spinner size='sm' />}
              Ver couta
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {isOpenConfirm && input && (
        <Modal isOpen={isOpenConfirm} centered>
          <ModalHeader>¡CUIDADO!</ModalHeader>
          <ModalBody>
            <p>
              Se va a refinanciar la obligación con saldo capital de{' '}
              <span className='fw-bold'>{parsePrice(capital)}</span>, realizando
              un abono a capital por valor de{' '}
              <span className='fw-bold'>{parsePrice(formik.values.value)}</span>{' '}
              a un plazo de{' '}
              <span className='fw-bold'>{formik.values.term}</span> meses y
              obteniendo una cuota de{' '}
              <span className='fw-bold'>{parsePrice(quota)}</span>.
            </p>
            <p className='fs-3 text-danger'>
              ¿Está seguro que desea continuar?
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              color='light'
              disabled={loadingCreate}
              onClick={onCloseConfirm}
            >
              Cancelar
            </Button>
            <Button
              className='d-flex align-items-center'
              color='danger'
              disabled={loadingCreate}
              onClick={() => {
                gqlCreateAbono({
                  variables: {
                    input: {
                      obligationId: parseId(obligationId),
                      term: input.term,
                      value: input.value
                    }
                  }
                })
              }}
            >
              {loadingCreate && <Spinner size='sm' />}
              Aceptar
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
