/* eslint-disable react/jsx-indent */
import { useEffect, useState, useRef } from 'react'
import {
  CardBody,
  Row,
  Col,
  Card,
  CardHeader,
  Container,
  Spinner,
  ListGroup,
  ListGroupItem
} from 'reactstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import html2pdf from 'html2pdf.js'

import { useLazyQuery, useQuery } from '@apollo/client'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { toast } from 'react-toastify'
import moment from 'moment'

import { hasPermission, parseId, parsePrice } from '../../../helpers'
import { ALL_PERMISSIONS, ERROR_MESSAGES } from '../../../helpers/consts'
import { useGetUser } from '../../../hooks/user'
import { GET_ONE_COTIZACION_ALIADOS } from '../../../graphql/queries/getCotizacionAliados'
import { BadgeStatusCotAl } from '../../../Components/Own/cotizacionAliados/BadgeStatusCotAl'
import { GET_COMPANY_INFO } from '../../../graphql/queries/getPuntos'

const NODE_TEST = {
  id: 'bm9kZToz',
  created: '2024-03-17',
  firstName: 'TestN',
  secondName: null,
  firstSurname: 'TestSur',
  secondSurname: 'TestSeconsur',
  initialFee: '0',
  monthlyFee: '100000',
  status: 'CREDITO',
  productValue: '2000000',
  product: 'tHIS IS A TEST PROD',
  phoneNumber: '3206910123',
  user: {
    firstName: 'test',
    lastName: 'TestL'
  },
  credit: {
    id: 'bm9kZToz',
    status: 'FINZALIZADO'
  },
  agency: {
    id: 'bm9kZToz',
    name: 'Test agency',
    city: 'city'
  },
  paymentPlan: '[{"name": "test", "value": "testV"}]'
}

const OneCotizacionAliado = () => {
  const navigate = useNavigate()
  const [gqlGetCotizacion, { data, loading, error }] = useLazyQuery(
    GET_ONE_COTIZACION_ALIADOS,
    { fetchPolicy: 'network-only' }
  )
  const {
    data: dataComp,
    loading: loadingComp,
    error: errorComp
  } = useQuery(GET_COMPANY_INFO)

  const { logout, permissions, user } = useGetUser()
  const contentRef = useRef()
  const { id } = useParams()
  const [tab, setTab] = useState('1')

  const [loadingDown, setLoadingDown] = useState(false)

  useEffect(() => {
    if (!user || !user?.tokenAuth) return
    const { crm } = user.tokenAuth
    if (
      hasPermission(
        permissions,
        ALL_PERMISSIONS.PUEDE_VER_COTIZACION_ALIADOS
      ) ||
      !crm
    ) {
      return
    }
    navigate('/dashboard')
  }, [permissions, user])

  useEffect(() => {
    if (!id || !Number(id)) {
      navigate('/cotizaciones')
    }
    gqlGetCotizacion({
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    })
  }, [id])

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error.message}`)
      if (error.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [error])
  useEffect(() => {
    if (errorComp) {
      toast.error(`Error: ${errorComp.message}`)
      if (error.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorComp])

  /* const getColorByStatus = (status) => {
    const OPTIONS = {
      CREADO: 'dark',
      EN_CREDITO: 'info',
      FINALIZADO: 'success',
      EN_SOLICITUD_DE_CREDITO: 'warning'
    }
    return OPTIONS[status] || 'secondary'
  } */

  const toggleTab = () => {
    if (tab === '1') {
      setTab('2')
    }
    if (tab === '2' || tab === '3') {
      setTab('1')
    }
  }

  document.title = 'Cotización de aliados | TOTAL SALES'

  if (loading) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            back='/cotizaciones-aliados'
            title='Resumen cotización'
            pageTitle='Cotizaciones de aliados'
          />

          <Row
            className='justify-content-center align-items-center'
            style={{ height: '30vh' }}
          >
            <Spinner
              style={{
                width: '3em',
                height: '3em'
              }}
              color='dark'
            />
          </Row>
        </Container>
      </div>
    )
  }

  if (!data) {
    return null
  }
  const { edges } = data.listAllyQuote

  /**
   * @type {{node: NODE_TEST}[]}
   */
  const edgesTest = edges.length > 0 ? edges : [{ node: NODE_TEST }]

  if (edgesTest.length === 0) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            back='/cotizaciones-aliados'
            title='Resumen cotización de aliados'
            pageTitle='Cotizaciones de aliados'
          />

          <Row
            className='justify-content-center align-items-center'
            style={{ height: '30vh' }}
          >
            <Col
              lg={12}
              className='d-flex align-items-center h-100 justify-content-center bg-white'
            >
              <div>
                <h4>Ups! No se encontró cotización de aliado😞</h4>
                <div className='d-flex justify-content-center'>
                  <Link to='/cotizaciones-aliados' className='btn btn-info'>
                    Ir a cotizaciones de aliado
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  const cotizacion = edgesTest[0].node
  /** @type {{name: string, value: string}[]} */
  const arrPlan = JSON.parse(cotizacion.paymentPlan)
  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb
          back='/aliados-cotizaciones'
          title='Resumen cotización'
          pageTitle='Cotizaciones de aliados'
          className='d-none-print'
        />
        {/*         <div style={{ height: '100vh', width: '100vw', display: 'flex' }}>
          <PDFViewer style={{ flex: 1 }}>
            <MyDocument />
          </PDFViewer>
        </div> */}

        <Row className='justify-content-center'>
          <Col xxl={9}>
            <Card id='demo'>
              <CardHeader className='d-flex justify-content-end d-none-print border-0 gap-2 flex-wrap'>
                {/* {cotizacion.billing && (
                  <Link
                    className='btn btn-outline-info d-none-print'
                    to={`/facturacion/${parseId(cotizacion.billing?.id)}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <span className='align-items-center gap-1 d-flex'>
                      <i className='mdi mdi-file-document-multiple-outline' />
                      Ver facturación
                    </span>
                  </Link>
                )} */}
                {hasPermission(
                  permissions,
                  ALL_PERMISSIONS.PUEDE_PASAR_COTIZACION_ALIADO_CREDITO
                ) &&
                  cotizacion.status === 'CREADO' && (
                    <Link
                      className='btn btn-danger d-none-print'
                      to={`/agregar-credito?cotizacion-aliado=${id}`}
                    >
                      Solicitar crédito
                    </Link>
                  )}
                {cotizacion.credit && (
                  <Link
                    className='btn btn-outline-success d-none-print'
                    to={`/credito/${parseId(cotizacion.credit?.id)}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <span className='align-items-center gap-1 d-flex'>
                      <i className='mdi mdi-credit-card-clock-outline' />
                      Ver crédito
                    </span>
                  </Link>
                )}
                <button
                  className={`btn btn-${
                    tab === '1' ? 'info' : 'primary'
                  } d-none-print`}
                  onClick={toggleTab}
                >
                  {tab === '1' ? 'Ver carta' : 'Ver información'}
                </button>
              </CardHeader>
              {tab === '1' ? (
                <CardBody>
                  <div>
                    <div className='d-sm-flex align-items-center'>
                      <h5 className='card-title flex-grow-1 mb-0'>
                        Información de cotización de aliado
                      </h5>
                      {/* <div className='flex-shrink-0 mt-2 mt-sm-0'>
                        {hasPermission(
                          permissions,
                          ALL_PERMISSIONS.PUEDE_PASAR_COTIZACION_ALIADO_CREDITO
                        ) ? (
                          <Link
                            className='btn btn-danger'
                            to={`/agregar-credito?cotizacion-aliado=${id}`}
                          >
                            Solicitar crédito
                          </Link>
                        ) : null}
                      </div> */}
                    </div>
                  </div>
                  <div className='mt-5'>
                    <h3 className='text-uppercase'>{cotizacion.product}</h3>
                    <div className='d-flex align-items-center justify-content-between my-4'>
                      <div className=''>
                        <h6 className='text-muted text-uppercase fw-semibold'>
                          {cotizacion.firstName} {cotizacion.secondName || ''}{' '}
                          {cotizacion.firstSurname}{' '}
                          {cotizacion.secondSurname || ''}
                        </h6>
                        <p className='text-muted mb-1' id='address-details'>
                          {cotizacion.email}
                        </p>
                        <p className='text-muted mb-0' id='zip-code'>
                          <span>CC: {cotizacion.documentNumber || 'N/R'}</span>
                        </p>
                        <p className='text-muted mb-0' id='zip-code'>
                          <span>Teléfono: {cotizacion.phoneNumber}</span>
                        </p>
                      </div>
                      <div>
                        <p className='text-muted mb-0' id='zip-code'>
                          Agencia:{' '}
                          <span className='fw-bold'>
                            {cotizacion.agency.name || 'N/R'}
                          </span>
                        </p>
                        <p className='text-muted mb-0' id='zip-code'>
                          Ciudad:{' '}
                          <span className='fw-bold'>
                            {' '}
                            {cotizacion.agency.city || 'N/R'}
                          </span>
                        </p>
                        <p className='text-muted mb-0' id='zip-code'>
                          Asesor:{' '}
                          <span className='fw-bold'>
                            {' '}
                            {cotizacion.user.firstName}{' '}
                            {cotizacion.user.lastName}
                          </span>
                        </p>
                        <p className='text-muted mb-0' id='zip-code'>
                          Teléfono:{' '}
                          <span className='fw-bold'>
                            {' '}
                            {cotizacion.user?.profileuser?.phoneNumber || 'N/R'}
                          </span>
                        </p>
                      </div>
                    </div>
                    <Row className='mt-3'>
                      <Col lg={2} xs={6}>
                        <p className='text-muted mb-2 text-uppercase fw-semibold'>
                          ID.
                        </p>
                        <h5 className='fs-14 mb-0'>
                          <span id='invoice-no  '>{id}</span>
                        </h5>
                      </Col>
                      <Col lg={3} xs={6}>
                        <p className='text-muted mb-2 text-uppercase fw-semibold'>
                          Fecha
                        </p>
                        <h5 className='fs-14 mb-0'>
                          <span id='invoice-date'>
                            {moment(cotizacion.created).format(
                              'DD MMM [del] YY'
                            )}
                          </span>{' '}
                          <small className='text-muted' id='invoice-time'>
                            {moment(cotizacion.created).format('hh:mm A')}
                          </small>
                        </h5>
                      </Col>
                      <Col lg={3} xs={6}>
                        <p className='text-muted mb-2 text-uppercase fw-semibold'>
                          Estado
                        </p>
                        <BadgeStatusCotAl status={cotizacion.status} />
                      </Col>
                      <Col lg={2} xs={6}>
                        <p className='text-muted mb-2 text-uppercase fw-semibold'>
                          Valor producto
                        </p>
                        <h5 className='fs-14 mb-0'>
                          <span id='total-amount'>
                            {parsePrice(cotizacion.productValue)}
                          </span>
                        </h5>
                      </Col>
                      <Col lg={2} xs={6}>
                        <p className='text-muted mb-2 text-uppercase fw-semibold'>
                          Tasa
                        </p>
                        <h5 className='fs-14 mb-0'>
                          <span id='total-amount'>{cotizacion.monthlyFee}</span>
                        </h5>
                      </Col>
                    </Row>
                    <Row className='mt-4'>
                      <h6 className='mb-3'>Plan de pago</h6>
                      <div className='ms-4'>
                        <ListGroup
                          className='mb-1'
                          style={{ maxWidth: '300px' }}
                        >
                          {arrPlan.map(({ name, value }) => (
                            <ListGroupItem key={name}>
                              <div className='d-flex align-items-center'>
                                <div className='flex-grow-1'>
                                  <div className='d-flex'>
                                    <div className='flex-shrink-0 ms-2'>
                                      <h6 className='fs-14 mb-0'>{name}</h6>
                                    </div>
                                  </div>
                                </div>
                                <div className='flex-shrink-0'>
                                  <span className='fw-bold'>
                                    {parsePrice(value)}
                                  </span>
                                </div>
                              </div>
                            </ListGroupItem>
                          ))}
                        </ListGroup>
                      </div>
                    </Row>
                  </div>
                </CardBody>
              ) : tab === '2' ? (
                <CardBody>
                  {dataComp && !loadingComp ? (
                    <div
                      ref={(r) => {
                        if (r) contentRef.current = r
                      }}
                    >
                      <Row>
                        <Col lg={12}>
                          <CardHeader className='border-bottom-0 p-4'>
                            <div className='d-flex justify-content-between'>
                              <div className='mt-sm-0 mt-3 flex-1 d-flex justify-content-start'>
                                <div className=''>
                                  <div>
                                    <img
                                      src={
                                        dataComp.getOrganization.image ||
                                        'TotalSales-Negativo.png'
                                      }
                                      width={120}
                                      alt='logo company'
                                      crossOrigin='*'
                                    />
                                  </div>
                                  <h6 className='mb-0'>
                                    <span>
                                      {moment(cotizacion.created).format(
                                        'DD MMMM [de] YYYY'
                                      )}
                                    </span>
                                  </h6>
                                  {/* <h6 className='mb-0'>
                                    <span className='text-uppercase'>
                                      {cotizacion?.firstName}{' '}
                                      {cotizacion?.secondName || ''}{' '}
                                      {cotizacion?.firstSurname}{' '}
                                      {cotizacion?.secondSurname || ''}
                                    </span>
                                  </h6>
                                  */}
                                  <h6 className='mb-0'>
                                    <span className='fw-bold'>Agencia: </span>{' '}
                                    <span className='text-uppercase'>
                                      {cotizacion.agency.name || 'N/R'}
                                    </span>
                                  </h6>
                                  <h6 className='mb-0'>
                                    <span className='fw-bold'>Ciudad: </span>{' '}
                                    <span className='text-uppercase'>
                                      {cotizacion.agency.city || 'N/R'}
                                    </span>
                                  </h6>
                                  <h6 className='mb-0'>
                                    <span className='fw-bold'>Asesor: </span>{' '}
                                    <span className='text-uppercase'>
                                      {cotizacion.user.firstName}{' '}
                                      {cotizacion.user.lastName}
                                    </span>
                                  </h6>
                                  <h6 className='mb-0'>
                                    <span className='fw-bold'>Teléfono: </span>{' '}
                                    <span className='text-uppercase'>
                                      {cotizacion.user?.profileuser
                                        ?.phoneNumber || 'N/R'}
                                    </span>
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className='mt-sm-5 mt-4'>
                              <h6 className=''>
                                Estimado/a{' '}
                                <span className='text-uppercase'>
                                  {cotizacion.firstName}{' '}
                                  {cotizacion.secondName || ''}{' '}
                                  {cotizacion.firstSurname}{' '}
                                  {cotizacion.secondSurname || ''}
                                </span>
                              </h6>
                            </div>
                          </CardHeader>
                        </Col>
                        <Col lg={12}>
                          <CardBody className='p-4'>
                            <p>
                              A continuación, detallamos las condiciones de la
                              cotización para el crédito con{' '}
                              <span className='fw-bold'>
                                {dataComp?.getOrganization?.name ||
                                  'Total Sales'}
                              </span>
                              :
                            </p>
                            <ul className='py-2'>
                              <li>
                                Producto:{' '}
                                <span className='fw-bold'>
                                  {cotizacion.product}
                                </span>
                              </li>
                              <li>
                                Valor del Producto:{' '}
                                <span className='fw-bold'>
                                  {parsePrice(cotizacion.productValue)}
                                </span>
                              </li>
                              <li>
                                Cuota Inicial:{' '}
                                <span className='fw-bold'>
                                  {parsePrice(cotizacion.initialFee)}
                                </span>
                              </li>
                            </ul>
                            <h6>Plan de pagos:</h6>
                            <div className='w-100'>
                              <table
                                className='table'
                                style={{ maxWidth: '350px' }}
                              >
                                <thead>
                                  <tr>
                                    <th className='p-0 text-center'>
                                      Plazo (meses)
                                    </th>
                                    <th className='p-0 text-center'>
                                      Valor cuota
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {arrPlan.slice(1).map(({ name, value }) => (
                                    <tr key={name} className='p-0'>
                                      <td className='p-0 text-center'>
                                        {name}
                                      </td>
                                      <td className='p-0 text-center'>
                                        {parsePrice(value)}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                            <p className='py-1'>
                              Es importante tener en cuenta que estas
                              condiciones están sujetas a análisis crediticio y
                              pueden cambiar sin previo aviso.
                            </p>
                            {/* <p className='py-1'>
                              Por favor, tenga en cuenta que esta cotización es
                              válida hasta{' '}
                              <span className='fw-bold'>
                                {moment(cotizacion.created)
                                  .add(1, 'M')
                                  .format('DD MMMM [de] YYYY')}
                              </span>
                              , y está sujeta a revisión y aprobación final por
                              parte de nuestro departamento de crédito.
                            </p>
                            <p className='py-1'>
                              Agradecemos sinceramente su interés en nuestros
                              servicios financieros. Estamos aquí para ayudarle
                              en cada paso del camino hacia la realización de
                              sus metas financieras.
                            </p> */}
                            <p className='py-1'>Atentamente,</p>
                            <p className='pt-4 fs-bold'>
                              {dataComp?.getOrganization?.name || 'Total Sales'}
                            </p>
                          </CardBody>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div
                      style={{ height: '50vh' }}
                      className='d-flex align-items-center justify-content-center'
                    >
                      <Spinner />
                    </div>
                  )}
                </CardBody>
              ) : null}

              {tab === '2' && (
                <Row className='justify-content-center mx-2 d-none-print'>
                  <Col
                    xxl={9}
                    className='d-flex justify-content-end mb-2 d-none-print gap-2 w-100'
                  >
                    <button
                      className='btn btn-success my-2 d-flex align-items-center d-none-print'
                      onClick={() => {
                        setLoadingDown(true)
                        if (contentRef.current) {
                          html2pdf()
                            .set({
                              margin: 4,
                              image: { type: 'jpeg' },
                              html2canvas: {
                                scale: 2,
                                letterRendering: true,
                                useCORS: true
                              },
                              filename: `cotizacion-aliado-${id}.pdf`
                            })
                            .from(contentRef.current)
                            .save()
                            .then(() => {
                              setLoadingDown(false)
                            })
                        }
                      }}
                      disabled={loadingDown || loadingComp}
                    >
                      {loadingDown ? (
                        <Spinner size='sm' color='light' className='me-1' />
                      ) : (
                        <i className='mdi mdi-file-download-outline me-1 d-none-print' />
                      )}
                      Descargar
                    </button>
                  </Col>
                </Row>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default OneCotizacionAliado
