import { Fragment } from 'react'
import { trasnformToArr } from '../../../helpers'

export const InfoEndeudamiento = ({ vigAlDia, vigExting, loading }) => {
  /** @type {{Calidad: string;  Calificacion: string;  Ciudad: string;  Comportamientos: string;  CubrimientoGarantia: string;  CuotasCanceladas: string;  EntidadOriginadoraCartera: string;  EstadoContrato: string;  EstadoObligacion: string;  EstadoTitular: string;  FechaApertura: string;  FechaCorte: string;  FechaPago: string;  FechaPermanencia: string;  FechaTerminacion: string;  IdentificadorLinea: string;  LineaCredito: string;  ModalidadCredito: string;  ModoExtincion: string;  MoraMaxima: string;  NaturalezaReestructuracion: string;  NombreEntidad: string;  NumeroCuotasMora: string;  NumeroCuotasPactadas: string;  NumeroObligacion: string;  NumeroReestructuraciones: string;  PaqueteInformacion: string;  ParticipacionDeuda: string;  Periodicidad: string;  ProbabilidadNoPago: string;  Reestructurado: string;  SaldoObligacion: string;  Sucursal: string;  TipoContrato: string;  TipoEntidad: string;  TipoEntidadOriginadoraCartera: string;  TipoGarantia: string;  TipoMoneda: string;  TipoPago: string;  ValorCuota: string;  ValorInicial: string;  ValorMora: string;}[]} */
  const arrVigAlDia = trasnformToArr(vigAlDia)

  /** @type {arrVigAlDia} */
  const arrExting = trasnformToArr(vigExting)

  return (
    <div className='pt-5'>
      <div
        style={{ overflowX: 'auto', width: '100%', fontSize: '10px' }}
        id='end'
      >
        <table
          className='w-100'
          style={{ borderCollapse: 'separate' }}
          cellSpacing='2px'
        >
          <thead>
            <tr style={{ backgroundColor: '#006685' }}>
              <th colSpan={21} className='text-center text-white fs-5'>
                Información Endeudamiento en Sectores Financiero, Asegurador y
                Solidario
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th
                rowSpan={2}
                colSpan={2}
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                FECHA CORTE
              </th>
              <th
                rowSpan={2}
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                MODA
              </th>
              <th
                rowSpan={2}
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                No. OBLIG
              </th>
              <th
                rowSpan={2}
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                TIPO ENT
              </th>
              <th
                rowSpan={2}
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                NOMBRE ENTIDAD
              </th>
              <th
                rowSpan={2}
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                CIUDAD
              </th>
              <th
                rowSpan={2}
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                CAL
              </th>
              <th
                rowSpan={2}
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                MRC
              </th>
              <th
                rowSpan={2}
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                TIPO GAR
              </th>
              <th
                rowSpan={2}
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                F INICIO
              </th>
              <th
                rowSpan={1}
                colSpan={3}
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                No. CUOTAS
              </th>
              <th
                rowSpan={2}
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                CUPO APROBVLR INIC
              </th>
              <th
                rowSpan={2}
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                PAGO MINIMVLR CUOTA
              </th>
              <th
                rowSpan={2}
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                SIT OBLIG
              </th>
              <th
                rowSpan={2}
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                NATU REES
              </th>
              <th
                rowSpan={2}
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                No. REE
              </th>
              <th
                rowSpan={2}
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                TIP PAG
              </th>
              <th
                rowSpan={2}
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                F PAGO F EXTIN
              </th>
            </tr>
            <tr>
              {/* others <td> */}
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                PAC
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                PAG
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                MOR
              </th>
              {/* others <td> */}
            </tr>
            <tr>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                TIPO CONT
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                PADE
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                LCRE
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                EST. CONTR
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                CLF
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                ORIGEN CARTERA
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                SUCURSAL
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                EST TITU
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                CLS
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                COB GAR
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                F TERM
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                PER
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
                colSpan={2}
              />
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                CUPO UTILISALDO CORT
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              />

              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                VALOR MORA
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                REES
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                MOR MAX
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                MOD EXT
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                F PERMAN
              </th>
            </tr>
            <tr>
              <td style={{ backgroundColor: '#ededed' }} colSpan={21}>
                <div className='pt-4 d-flex algin-items-start justify-content-start fw-bold'>
                  OBLIGACIONES VIGENTES Y AL DÍA
                </div>
              </td>
            </tr>
            {arrVigAlDia.map((n, idx) => (
              <Fragment key={idx}>
                <tr>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                    colSpan={2}
                  >
                    {n.FechaCorte || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.ModalidadCredito || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.NumeroObligacion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.TipoEntidad || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.NombreEntidad || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.Ciudad || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.Calidad || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    -
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.TipoGarantia || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.FechaApertura || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.NumeroCuotasPactadas || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.CuotasCanceladas || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.NumeroCuotasMora || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.ValorInicial || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.ValorCuota || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.EstadoObligacion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.NaturalezaReestructuracion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.NumeroReestructuraciones || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.TipoPago || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    -
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.TipoContrato || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.ParticipacionDeuda || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.LineaCredito || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.EstadoObligacion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.Calificacion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    -
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.Sucursal}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.EstadoTitular || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    -
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.CubrimientoGarantia || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.FechaTerminacion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.Periodicidad || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                    colSpan={2}
                  />
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'right'
                    }}
                  >
                    {n.SaldoObligacion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  />
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.ValorMora || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.Reestructurado || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.MoraMaxima || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.ModoExtincion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.FechaPermanencia || '-'}
                  </td>
                </tr>

                <tr>
                  <td
                    colSpan={12}
                    style={{
                      backgroundColor: '#ededed',
                      padding: '4px 5px',
                      textAlign: 'center'
                    }}
                  >
                    <div className='d-flex align-items-end w-100'>
                      <div className='d-flex justify-content-end gap-1 w-100'>
                        <div className='d-flex algin-items-center text-muted fw-bold'>
                          {n.Comportamientos.split('|')
                            .map((c) => c.trim())
                            .slice(1, -1)
                            .map((str, i) => (
                              <span
                                key={i}
                                style={{
                                  display: 'inline-block',
                                  border: '1px solid lightblue',
                                  padding: '0 5px'
                                }}
                              >
                                {str || (
                                  <span style={{ paddingInline: '3px' }} />
                                )}
                              </span>
                            ))}
                        </div>
                        {/* <div className='d-flex algin-items-center text-muted fw-bold'>
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((n) => (
                            <span
                              key={n}
                              style={{
                                display: 'inline-block',
                                border: '1px solid lightblue',
                                padding: '0 5px'
                              }}
                            >
                              N
                            </span>
                          ))}
                        </div> */}
                      </div>
                    </div>
                  </td>
                  <td
                    colSpan={4}
                    style={{
                      backgroundColor: '#ededed',
                      padding: '0',
                      textAlign: 'center'
                    }}
                  >
                    <div
                      className='w-100 h-100 fw-bold'
                      style={{
                        backgroundColor: '#00a6ca',
                        color: 'white',
                        clipPath:
                          'polygon(100% 0, 100% 50%, 100% 100%, 7% 99%, 0 54%, 7% 0)',
                        padding: '8px'
                      }}
                    >
                      COMPORTAMIENTOS
                    </div>
                  </td>
                  <td
                    colSpan={5}
                    style={{
                      backgroundColor: '#ededed',
                      padding: '4px 5px',
                      textAlign: 'center'
                    }}
                  />
                </tr>
              </Fragment>
            ))}

            <tr>
              <td style={{ backgroundColor: '#ededed' }} colSpan={21}>
                <div className='pt-4 d-flex algin-items-start justify-content-start fw-bold'>
                  OBLIGACIONES EXTINGUIDAS
                </div>
              </td>
            </tr>
            {arrExting.map((n, idx) => (
              <Fragment key={idx}>
                <tr>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                    colSpan={2}
                  >
                    {n.FechaCorte || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.ModalidadCredito || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.NumeroObligacion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.TipoEntidad || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.NombreEntidad || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.Ciudad || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.Calidad || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    -
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.TipoGarantia || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.FechaApertura || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.NumeroCuotasPactadas || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.CuotasCanceladas || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.NumeroCuotasMora || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.ValorInicial || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.ValorCuota || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.EstadoObligacion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.NaturalezaReestructuracion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.NumeroReestructuraciones || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.TipoPago || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    -
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.TipoContrato || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.ParticipacionDeuda || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.LineaCredito || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.EstadoObligacion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.Calificacion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    -
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.Sucursal}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.EstadoTitular || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    -
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.CubrimientoGarantia || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.FechaTerminacion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.Periodicidad || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                    colSpan={2}
                  />
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'right'
                    }}
                  >
                    {n.SaldoObligacion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  />
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.ValorMora || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.Reestructurado || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.MoraMaxima || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.ModoExtincion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.FechaPermanencia || '-'}
                  </td>
                </tr>

                <tr>
                  <td
                    colSpan={12}
                    style={{
                      backgroundColor: '#ededed',
                      padding: '4px 5px',
                      textAlign: 'center'
                    }}
                  >
                    <div className='d-flex align-items-end w-100'>
                      <div className='d-flex justify-content-end gap-1 w-100'>
                        <div className='d-flex algin-items-center text-muted fw-bold'>
                          {n.Comportamientos.split('|')
                            .map((c) => c.trim())
                            .slice(1, -1)
                            .map((str, i) => (
                              <span
                                key={i}
                                style={{
                                  display: 'inline-block',
                                  border: '1px solid lightblue',
                                  padding: '0 5px'
                                }}
                              >
                                {str || (
                                  <span style={{ paddingInline: '3px' }} />
                                )}
                              </span>
                            ))}
                        </div>
                        {/* <div className='d-flex algin-items-center text-muted fw-bold'>
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((n) => (
                            <span
                              key={n}
                              style={{
                                display: 'inline-block',
                                border: '1px solid lightblue',
                                padding: '0 5px'
                              }}
                            >
                              N
                            </span>
                          ))}
                        </div> */}
                      </div>
                    </div>
                  </td>
                  <td
                    colSpan={4}
                    style={{
                      backgroundColor: '#ededed',
                      padding: '0',
                      textAlign: 'center'
                    }}
                  >
                    <div
                      className='w-100 h-100 fw-bold'
                      style={{
                        backgroundColor: '#00a6ca',
                        color: 'white',
                        clipPath:
                          'polygon(100% 0, 100% 50%, 100% 100%, 7% 99%, 0 54%, 7% 0)',
                        padding: '8px'
                      }}
                    >
                      COMPORTAMIENTOS
                    </div>
                  </td>
                  <td
                    colSpan={5}
                    style={{
                      backgroundColor: '#ededed',
                      padding: '4px 5px',
                      textAlign: 'center'
                    }}
                  />
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
