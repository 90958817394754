import { gql } from '@apollo/client'

export const GET_ALL_DISBURSEMENTS = gql`
  query listDisbursement(
    $after: String
    $id: Float
    $creditId: Float
    $status: String
    $creditDocumentNumber: String
    $disbursementDate: DateTime
    $createdGte: DateTime
    $createdLte: DateTime
    $creditAgencyId: Float
    $name: String
    $offset: Int
  ) {
    listDisbursementRequest(
      after: $after
      id: $id
      credit_Id: $creditId
      status: $status
      credit_DocumentNumber: $creditDocumentNumber
      name: $name
      created_Gte: $createdGte
      created_Lte: $createdLte
      credit_Agency_Id: $creditAgencyId
      disbursementDate: $disbursementDate
      offset: $offset
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          status
          disbursementDate
          disbursementNote
          created
          credit {
            id
            firstName
            firstSurname
            secondName
            secondSurname
            documentType
            documentNumber
            agency {
              id
              name
            }
          }
        }
      }
    }
  }
`
export const GET_ONE_DISBURSEMENT = gql`
  query listDisbursement($id: Float) {
    listDisbursementRequest(id: $id) {
      edges {
        node {
          id
          status
          disbursementDate
          disbursementNote
          created
          credit {
            id
            firstName
            secondName
            firstSurname
            secondSurname
            documentType
            documentNumber
            dateBirth
            expeditionDate
            closedDate
            phoneNumber
            email
            paymentDate
            productValue
            term
            quotaValue
            initialFee
            user {
              firstName
              lastName
            }
            agency {
              id
              name
              city
              zone {
                name
                commercialCompany {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`
export const GET_CHECKS_DISBURSEMENT = gql`
  query listCheckListItems($after: String, $id: Float, $disbursementId: Float) {
    listCheckListItems(
      after: $after
      id: $id
      disbursementRequest_Id: $disbursementId
    ) {
      edges {
        node {
          id
          isChecked
          name
        }
      }
    }
  }
`
export const GET_CREDIT_DISBURSEMENT = gql`
  query getCredit($creditId: Int) {
    getCredit(creditId: $creditId) {
      id
      documentNumber
      documentType
      expeditionDate
      firstName
      secondName
      firstSurname
      secondSurname
      dateBirth
      email
      phoneNumber
      closedDate
      supportSet {
        edges {
          node {
            id
            name
            file
            deleted
          }
        }
      }
      cosignerSet {
        edges {
          node {
            id
            documentNumber
            documentType
            expeditionDate
            firstName
            secondName
            firstSurname
            secondSurname
            dateBirth
            email
            phoneNumber
            formData
          }
        }
      }
    }
  }
`
