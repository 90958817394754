import { useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap'
import parse from 'html-react-parser'
import { useDisclosure } from '../../../hooks/useDisclosure'
import { ModalCentralesJson } from './ModalCentralesJson'
/**
 *
 * @param {{centrales: string}} props
 * @returns {JSX.Element}
 */
export const CardCentrales = ({ centrales, infoCentralesJson, document }) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const {
    isOpen: isOpenCentralesJson,
    onClose: onCloseCentralesJson,
    onOpen: onOpenCentralesJson
  } = useDisclosure()

  const [infoCentralesStr, setInfoCentralesStr] = useState(null)

  const [infoHTML, setInfoHTML] = useState('')
  /** @type {{[key: string]: string}} */
  const json = JSON.parse(centrales)
  const arr = Object.keys(json)

  const handleViewCentrales = (str) => {
    setInfoHTML(json[str] || '')
    onOpen()
  }

  return (
    <>
      <Card>
        <CardBody>
          <h5 className='card-title mb-3'>Información de centrales</h5>
          {arr.length === 0 ? (
            <div>No hay información de centrales</div>
          ) : (
            <ListGroup>
              {arr.map((str) => (
                <ListGroupItem
                  key={str}
                  className='d-flex justify-content-between align-items-center'
                >
                  <h6>{str}</h6>
                  <button
                    title='Ver'
                    className='border-0 p-0 bg-transparent m-0'
                    onClick={() => handleViewCentrales(str)}
                  >
                    <i className='mdi mdi-eye text-info' />
                  </button>
                </ListGroupItem>
              ))}
              <ListGroupItem className='d-flex justify-content-between align-items-center'>
                <h6>Informe</h6>
                <button
                  title='Ver'
                  className='border-0 p-0 bg-transparent m-0'
                  onClick={() => {
                    onOpenCentralesJson()
                    setInfoCentralesStr(infoCentralesJson)
                  }}
                >
                  <i className='mdi mdi-eye text-info' />
                </button>
              </ListGroupItem>
            </ListGroup>
          )}
        </CardBody>
      </Card>

      <Modal
        isOpen={isOpen}
        fullscreen
        toggle={onClose}
        onClosed={() => setInfoHTML('')}
      >
        <ModalHeader toggle={onClose} />
        <ModalBody>
          {parse(infoHTML.replace('class="collapse"', 'class=""'))}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Aceptar</Button>
        </ModalFooter>
      </Modal>

      {infoCentralesStr && isOpenCentralesJson && (
        <ModalCentralesJson
          isOpen={isOpenCentralesJson}
          info={infoCentralesStr}
          onClose={onCloseCentralesJson}
          document={document}
        />
      )}
    </>
  )
}
