import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from 'reactstrap'
import { InputCurrency } from '../InputCurrency'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { ADD_COUTA_WALLET_GESTION } from '../../../graphql/mutations/creditWalletMutations'
import { parseId } from '../../../helpers'

export const ModalAddGestionWallet = ({
  isOpen,
  onClose,
  infoOneGestion,
  refetch
}) => {
  const [gqlAddGestion, { data, loading, error }] = useMutation(
    ADD_COUTA_WALLET_GESTION
  )
  const [value, setValue] = useState('')

  useEffect(() => {
    if (infoOneGestion.value) {
      setValue(infoOneGestion.value)
    }
  }, [infoOneGestion.value])

  useEffect(() => {
    if (error) {
      toast.error(`ERROR: ${error.message}`)
    }
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.addPaymentManagement.success) {
      toast.success('Éxito: gestión actualizada correctamente')
      refetch()
      onClose()
    } else if (data.addPaymentManagement?.errors?.message) {
      toast.error(
        `ERROR: ${data.addPaymentManagement?.errors?.message || 'Algo ocurrió'}`
      )
    }
  }, [data])

  const handleSave = () => {
    if (value === '') return
    gqlAddGestion({
      variables: {
        input: {
          quotaId: parseId(infoOneGestion.id),
          value
        }
      }
    })
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalHeader toggle={onClose}>Gestión de cobro</ModalHeader>
      <ModalBody>
        <h4>
          Couta:{' '}
          <span className='text-uppercase'>
            {' '}
            {moment(infoOneGestion.endDate).format('DD MMMM [del] YYYY')}
          </span>
        </h4>
        <div className='my-4'>
          <label>Ingrese valor</label>
          <InputCurrency
            placeholder='Valor'
            value={value}
            onValueChange={(e) => setValue(e)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color='light' onClick={onClose}>
          Cancelar
        </Button>
        <Button
          className='d-flex align-items-center gap-2'
          onClick={handleSave}
          disabled={!value || loading || Number(value) === 0}
        >
          {loading && <Spinner size='sm' />}
          Guardar
        </Button>
      </ModalFooter>
    </Modal>
  )
}
