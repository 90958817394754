import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane
} from 'reactstrap'
import classnames from 'classnames'
// import Flatpickr from 'react-flatpickr'

// import images
import progileBg from '../../../assets/images/bg-one-user.png'
// import avatar1 from '../../../assets/images/users/avatar-1.jpg'
import { GET_ONE_USER_WIEW } from '../../../graphql/queries/getUsuarios'
import { toast } from 'react-toastify'
import moment from 'moment'
import {
  ALL_PERMISSIONS,
  ERROR_MESSAGES,
  ROLES,
  STATUS_BILLINGS
} from '../../../helpers/consts'
import { useGetUser } from '../../../hooks/user'
import { hasPermission } from '../../../helpers'
import CountUp from 'react-countup'
// import { TabPaneBudget } from '../../../Components/Own/Users/TabPaneBudget'

const OneUser = () => {
  const { id } = useParams()
  const [gqlGetOneUser, { data, loading, error /* refetch */ }] =
    useLazyQuery(GET_ONE_USER_WIEW)

  const { logout, permissions } = useGetUser()

  const navigate = useNavigate()

  useEffect(() => {
    if (!hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_USUARIO)) {
      navigate('/')
    }
  }, [permissions])

  useEffect(() => {
    if (!id || !Number(id)) {
      navigate('/')
      return
    }
    gqlGetOneUser({
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    })
  }, [id])
  const [activeTab, setActiveTab] = useState('1')

  useEffect(() => {
    if (error) {
      toast.error(`ERROR: ${error.message}`)
      if (error.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [error])

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  document.title = 'USUARIO | TOTAL SALES'

  if (loading) {
    return (
      <div className='page-content'>
        <Container fluid>
          <div className='position-relative mx-n4 mt-n4'>
            <div className='profile-wid-bg profile-setting-img'>
              <img src={progileBg} className='profile-wid-img' alt='' />
              <div className='position-absolute top-0 start-0'>
                <div className='text-end p-3'>
                  <div className='p-0 ms-auto rounded-circle profile-photo-edit'>
                    <span className='btn btn-light text-light'>
                      <i className='mdi mdi-account-edit-outline align-bottom me-1' />{' '}
                      Usuarios
                    </span>
                  </div>
                </div>
              </div>
              <div className='overlay-content'>
                <div className='text-end p-3'>
                  <div className='p-0 ms-auto rounded-circle profile-photo-edit'>
                    <span className='btn btn-light text-light'>
                      <i className='mdi mdi-account-edit-outline align-bottom me-1' />{' '}
                      Editar
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Row>
            <Col lg={3}>
              <Card className='mt-n5'>
                <CardBody className='p-4'>
                  <div className='text-center'>
                    <div className='profile-user position-relative d-inline-block mx-auto  mb-4'>
                      <div className='col-auto'>
                        <div className='avatar-lg img-thumbnail rounded-circle flex-shrink-0'>
                          <div className='avatar-title text-uppercase border rounded-circle bg-light text-primary fs-1'>
                            <div className='placeholder-glow'>
                              <span className='placeholder col-12' />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h5 className='fs-16 mb-1'>
                      <div className='placeholder-glow'>
                        <span className='placeholder col-9' />
                      </div>
                    </h5>
                    <p className='text-muted mb-0'>
                      <div className='placeholder-glow'>
                        <span className='placeholder col-5' />
                      </div>
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={9}>
              <Card className='mt-n5'>
                <CardHeader>
                  <div>
                    <div>
                      <div className='placeholder-glow'>
                        <span className='placeholder col-3' />
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody
                  className='d-flex align-items-center justify-content-center'
                  style={{ height: '30vh' }}
                >
                  <Spinner style={{ width: '3em', height: '3em' }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  if (!data) return null

  if (data.listUsers.edges.length === 0) {
    return (
      <div className='page-content'>
        <Container fluid>
          <Row
            className='justify-content-center align-items-center'
            style={{ height: '30vh' }}
          >
            <Col
              lg={12}
              className='d-flex align-items-center h-100 justify-content-center bg-white'
            >
              <div>
                <h4>Ups! No se encontró usuario 😞</h4>
                <div className='d-flex justify-content-center'>
                  <Link to='/usuarios' className='btn btn-info'>
                    Ir a usuarios
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  const user = data.listUsers.edges[0].node
  /**
   * @type {{email: string, firstName: string, lastName: string, quotationcommentSet: {edges:{node:{id:string}}[]}, usedmotorcyclecommentSet: {edges:{node:{id:string}}[]} ,quotationSet: {edges: {node: {id: string, billing: {status: string}}}[]} ,groups: {edges: [{node: {name: string}}]}, profileuser: {agencies: {edges: {node: {name: string}}[]}, zones:{edges: {node: {name: string}}[]}, commercialCompany: null | {name: string}, phoneNumber: string}}}
   */
  const {
    email,
    firstName,
    lastName,
    profileuser,
    quotationSet,
    quotationcommentSet,
    usedmotorcyclecommentSet,
    groups
  } = user

  const totQuotations = quotationSet.edges.length
  const totBillings = quotationSet.edges.filter(
    ({ node }) =>
      STATUS_BILLINGS[node.billing?.status] === STATUS_BILLINGS.FACTURADO
  ).length

  const quotationComments = quotationcommentSet.edges.length
  const motoUsedComments = usedmotorcyclecommentSet.edges.length

  const pertentaje = Math.floor((totBillings * 100) / totQuotations) || 0
  const roles = groups.edges.map(({ node }) => node.name)
  const agencies =
    profileuser?.agencies?.edges?.map(({ node }) => node.name) || []
  const zones = profileuser?.zones?.edges?.map(({ node }) => node.name) || []
  const company = profileuser?.commercialCompany?.name
  // console.log('groups, profileuser', groups, profileuser)
  // const isAsesor = roles.some((str) => str === 'Asesor')
  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <div className='position-relative mx-n4 mt-n4'>
            <div className='profile-wid-bg profile-setting-img'>
              <img src={progileBg} className='profile-wid-img' alt='' />
              <div className='position-absolute top-0 start-0'>
                <div className='text-end p-3'>
                  <div className='p-0 ms-auto rounded-circle profile-photo-edit'>
                    <Link
                      className='profile-photo-edit btn btn-light'
                      to='/usuarios'
                    >
                      <i className='mdi mdi-arrow-left align-bottom me-1 fs-6' />{' '}
                      Usuarios
                    </Link>
                  </div>
                </div>
              </div>
              <div className='overlay-content'>
                <div className='text-end p-3'>
                  <div className='p-0 ms-auto rounded-circle profile-photo-edit'>
                    <Link
                      className='profile-photo-edit btn btn-light'
                      to={`/editar-usuario/${id}`}
                    >
                      <i className='mdi mdi-account-edit-outline align-bottom me-1' />{' '}
                      Editar
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Row>
            <Col lg={3}>
              <Card className='mt-n5'>
                <CardBody className='p-4'>
                  <div className='text-center'>
                    <div className='avatar-lg img-thumbnail rounded-circle flex-shrink-0 mx-auto  mb-4'>
                      <div className='avatar-title text-uppercase border rounded-circle bg-light text-primary fs-1'>
                        {firstName.charAt(0)} {lastName.charAt(0)}
                      </div>
                    </div>
                    <h5 className='fs-16 mb-1'>
                      {firstName} {lastName}
                    </h5>
                    <p className='text-muted mb-0'>{email}</p>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <div className='d-flex align-items-center mb-5'>
                    <div className='flex-grow-1'>
                      <h5 className='card-title mb-0'>
                        Cotizaciones facturadas
                      </h5>
                    </div>
                  </div>
                  <div className='progress animated-progress custom-progress progress-label'>
                    <div
                      className='progress-bar bg-danger'
                      role='progressbar'
                      style={{ width: `${pertentaje}%` }}
                      aria-valuenow={'' + pertentaje}
                      aria-valuemin='0'
                      aria-valuemax='100'
                    >
                      <div className='label'>{pertentaje}%</div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className='d-flex align-items-center mb-4'>
                    <div className='flex-grow-1'>
                      <h5 className='card-title mb-0'>Activdad comentarios</h5>
                    </div>
                  </div>
                  <Row>
                    <Col lg={12} sm={6} xs={6} className='mb-3'>
                      <div className='mt-3 mt-md-0 py-4 px-3'>
                        <h5 className='text-muted text-uppercase fs-13'>
                          Motos usadas
                        </h5>
                        <div className='d-flex align-items-center'>
                          <div className='flex-shrink-0'>
                            <i className='display-6 text-muted ri-motorbike-fill' />
                          </div>
                          <div className='flex-grow-1 ms-3'>
                            <h2 className='mb-0'>
                              <span className='counter-value'>
                                <CountUp
                                  start={0}
                                  prefix=''
                                  suffix=''
                                  separator=''
                                  end={motoUsedComments}
                                  decimals={0}
                                  duration={2}
                                />
                              </span>
                            </h2>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={12} sm={6} xs={6} className='mb-3'>
                      <div className='mt-3 mt-md-0 py-4 px-3'>
                        <h5 className='text-muted text-uppercase fs-13'>
                          Cotizaciones
                        </h5>
                        <div className='d-flex align-items-center'>
                          <div className='flex-shrink-0'>
                            <i className='display-6 text-muted ri-file-text-line' />
                          </div>
                          <div className='flex-grow-1 ms-3'>
                            <h2 className='mb-0'>
                              <span className='counter-value'>
                                <CountUp
                                  start={0}
                                  prefix=''
                                  suffix=''
                                  separator=''
                                  end={quotationComments}
                                  decimals={0}
                                  duration={2}
                                />
                              </span>
                            </h2>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {/* <div className='mb-3 d-flex'>
                    <div className='avatar-xs d-block flex-shrink-0 me-3'>
                      <span className='avatar-title rounded-circle fs-16 bg-primary'>
                        <i className='ri-global-fill' />
                      </span>
                    </div>
                    <Input
                      type='text'
                      className='form-control'
                      id='websiteInput'
                      placeholder='www.example.com'
                      defaultValue='www.velzon.com'
                    />
                  </div>
                  <div className='mb-3 d-flex'>
                    <div className='avatar-xs d-block flex-shrink-0 me-3'>
                      <span className='avatar-title rounded-circle fs-16 bg-success'>
                        <i className='ri-dribbble-fill' />
                      </span>
                    </div>
                    <Input
                      type='text'
                      className='form-control'
                      id='dribbleName'
                      placeholder='Username'
                      defaultValue='@dave_adame'
                    />
                  </div>
                  <div className='d-flex'>
                    <div className='avatar-xs d-block flex-shrink-0 me-3'>
                      <span className='avatar-title rounded-circle fs-16 bg-danger'>
                        <i className='ri-pinterest-fill' />
                      </span>
                    </div>
                    <Input
                      type='text'
                      className='form-control'
                      id='pinterestName'
                      placeholder='Username'
                      defaultValue='Advance Dave'
                    />
                  </div> */}
                </CardBody>
              </Card>
            </Col>

            <Col lg={9}>
              <Card className='mt-lg-n5'>
                <CardHeader>
                  <Nav
                    className='nav-tabs-custom rounded card-header-tabs border-bottom-0'
                    role='tablist'
                  >
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => {
                          tabChange('1')
                        }}
                        type='button'
                      >
                        {/* <i className='mdi mdi-account-circle' /> */}
                        Información básica
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to='#'
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => {
                          tabChange('2')
                        }}
                        type='button'
                      >
                        {/* <i className='' /> */}
                        Roles
                      </NavLink>
                    </NavItem>
                    {/* {isAsesor && (
                      <NavItem>
                        <NavLink
                          // to='#'
                          className={classnames({ active: activeTab === '3' })}
                          onClick={() => {
                            tabChange('3')
                          }}
                          type='button'
                        >
                          <i className='mdi mdi-cash-plus' /> Presupuesto
                        </NavLink>
                      </NavItem>
                    )} */}
                    {/* <NavItem>
                      <NavLink
                        to='#'
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => {
                          tabChange('3')
                        }}
                        type='button'
                      >
                        <i className='far fa-envelope' />
                        Experience
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to='#'
                        className={classnames({ active: activeTab === '4' })}
                        onClick={() => {
                          tabChange('4')
                        }}
                        type='button'
                      >
                        <i className='far fa-envelope' />
                        Privacy Policy
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                </CardHeader>
                <CardBody className='p-4'>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId='1'>
                      <Form autoComplete='off'>
                        <Row>
                          <Col lg={6}>
                            <div className='mb-3'>
                              <Label
                                htmlFor='firstnameInput'
                                className='form-label'
                              >
                                Nombre
                              </Label>
                              <Input
                                type='text'
                                className='form-control'
                                id='firstnameInput'
                                placeholder='Enter your firstname'
                                defaultValue={firstName}
                                readOnly
                                disabled
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className='mb-3'>
                              <Label
                                htmlFor='lastnameInput'
                                className='form-label'
                              >
                                Apellido
                              </Label>
                              <Input
                                type='text'
                                className='form-control'
                                id='lastnameInput'
                                placeholder='Enter your lastname'
                                defaultValue={lastName}
                                readOnly
                                disabled
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className='mb-3'>
                              <Label
                                htmlFor='phonenumberInput'
                                className='form-label'
                              >
                                Teléfono
                              </Label>
                              <Input
                                type='text'
                                className='form-control'
                                id='phonenumberInput'
                                placeholder='Enter your phone number'
                                defaultValue={profileuser?.phoneNumber}
                                readOnly
                                disabled
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className='mb-3'>
                              <Label
                                htmlFor='emailInput'
                                className='form-label'
                              >
                                Correo electrónico
                              </Label>
                              <Input
                                type='email'
                                className='form-control'
                                id='emailInput'
                                placeholder='Enter your email'
                                defaultValue={email}
                                readOnly
                                disabled
                              />
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className='mb-3'>
                              <Label
                                htmlFor='JoiningdatInput'
                                className='form-label'
                              >
                                Fecha de creación
                              </Label>
                              <Input
                                type='text'
                                className='form-control'
                                id='created-form'
                                placeholder='Fecha de creación'
                                defaultValue={moment(
                                  profileuser?.created
                                ).format('DD MMMM [del] YYYY')}
                                readOnly
                                disabled
                              />
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>

                    <TabPane tabId='2'>
                      <div className='my-3'>
                        <div className='hori-sitemap'>
                          <ul className='list-unstyled mb-0'>
                            <li className='p-0 parent-title'>
                              <p className='fw-semibold fs-14'>COMERCIAL</p>
                            </li>
                            <ul className='list-unstyled row g-0'>
                              <li className='col-sm-3'>
                                <p
                                  className='fw-semibold'
                                  style={{
                                    color: roles.includes(ROLES.ADMIN)
                                      ? 'green'
                                      : '#c4c4c4',
                                    cursor: roles.includes(ROLES.ADMIN)
                                      ? 'auto'
                                      : 'not-allowed'
                                  }}
                                  title={
                                    roles.includes(ROLES.ADMIN)
                                      ? 'Es administrador'
                                      : ''
                                  }
                                >
                                  Administrador
                                </p>
                                <ul className='list-unstyled second-list pt-0'>
                                  <li>
                                    <div>
                                      {company ? (
                                        <div>{company}</div>
                                      ) : (
                                        <div style={{ color: 'red' }}>
                                          No aplica
                                        </div>
                                      )}
                                    </div>
                                  </li>
                                </ul>
                              </li>
                              <li className='col-sm-2'>
                                <p
                                  className='fw-semibold'
                                  style={{
                                    color: roles.includes(ROLES.JEFE_ZONA)
                                      ? 'green'
                                      : '#c4c4c4',
                                    cursor: roles.includes(ROLES.JEFE_ZONA)
                                      ? 'auto'
                                      : 'not-allowed'
                                  }}
                                  title={
                                    roles.includes(ROLES.JEFE_ZONA)
                                      ? 'Es Jefe de zona'
                                      : ''
                                  }
                                >
                                  Jefe de zona
                                </p>
                                <ul className='list-unstyled second-list pt-0'>
                                  <li>
                                    <div>
                                      {roles.includes(ROLES.JEFE_ZONA) &&
                                        zones.map((zone, idx) => (
                                          <div key={zone}>
                                            {idx + 1}. {zone}
                                          </div>
                                        ))}
                                      {!roles.includes(ROLES.JEFE_ZONA) && (
                                        <div style={{ color: 'red' }}>
                                          No aplica
                                        </div>
                                      )}
                                    </div>
                                  </li>
                                </ul>
                              </li>
                              <li className='col-sm-2'>
                                <p
                                  className='fw-semibold'
                                  style={{
                                    color: roles.includes(ROLES.ASESOR)
                                      ? 'green'
                                      : '#c4c4c4',
                                    cursor: roles.includes(ROLES.ASESOR)
                                      ? 'auto'
                                      : 'not-allowed'
                                  }}
                                  title={
                                    roles.includes(ROLES.ASESOR)
                                      ? 'Es Asesor'
                                      : ''
                                  }
                                >
                                  Asesor
                                </p>
                                <ul className='list-unstyled second-list pt-0'>
                                  <li>
                                    <div>
                                      {agencies.map((agency, idx) => (
                                        <div key={agency} className='mt-2'>
                                          {idx + 1}. {agency}
                                        </div>
                                      ))}
                                      {agencies.length === 0 && (
                                        <div style={{ color: 'red' }}>
                                          No aplica
                                        </div>
                                      )}
                                    </div>
                                  </li>
                                </ul>
                              </li>
                              <li className='col-sm-2'>
                                <p
                                  className='fw-semibold'
                                  style={{
                                    color: roles.includes(ROLES.FACTURADOR)
                                      ? 'green'
                                      : '#c4c4c4',
                                    cursor: roles.includes(ROLES.FACTURADOR)
                                      ? 'auto'
                                      : 'not-allowed'
                                  }}
                                  title={
                                    roles.includes(ROLES.FACTURADOR)
                                      ? 'Es facturador'
                                      : ''
                                  }
                                >
                                  Facturador
                                </p>
                                <ul className='list-unstyled second-list pt-0'>
                                  <li>
                                    <div>
                                      {roles.includes(ROLES.FACTURADOR) &&
                                        zones.map((zone, idx) => (
                                          <div key={zone}>
                                            {idx + 1}. {zone}
                                          </div>
                                        ))}
                                      {!roles.includes(ROLES.FACTURADOR) && (
                                        <div style={{ color: 'red' }}>
                                          No aplica
                                        </div>
                                      )}
                                    </div>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </ul>
                        </div>
                      </div>
                      <div className=''>
                        <div className='hori-sitemap'>
                          <ul className='list-unstyled mb-0'>
                            <li className='p-0 parent-title'>
                              <p className='fw-semibold fs-14'>FINANCIERO</p>
                            </li>
                            <ul className='list-unstyled row g-0'>
                              <li className='col-sm-3'>
                                <p
                                  className='fw-semibold'
                                  style={{
                                    color: roles.includes(ROLES.GERENTE)
                                      ? 'green'
                                      : '#c4c4c4',
                                    cursor: roles.includes(ROLES.GERENTE)
                                      ? 'auto'
                                      : 'not-allowed'
                                  }}
                                  title={
                                    roles.includes(ROLES.GERENTE)
                                      ? 'Es Gerente'
                                      : ''
                                  }
                                >
                                  Gerente
                                </p>
                              </li>
                              <li className='col-sm-3'>
                                <p
                                  className='fw-semibold'
                                  style={{
                                    color: roles.includes(ROLES.ANALISTA)
                                      ? 'green'
                                      : '#c4c4c4',
                                    cursor: roles.includes(ROLES.ANALISTA)
                                      ? 'auto'
                                      : 'not-allowed'
                                  }}
                                  title={
                                    roles.includes(ROLES.ANALISTA)
                                      ? 'Es Analista'
                                      : ''
                                  }
                                >
                                  Analista
                                </p>
                              </li>
                              <li className='col-sm-2'>
                                <p
                                  className='fw-semibold'
                                  style={{
                                    color: roles.includes(ROLES.CARTERA)
                                      ? 'green'
                                      : '#c4c4c4',
                                    cursor: roles.includes(ROLES.CARTERA)
                                      ? 'auto'
                                      : 'not-allowed'
                                  }}
                                  title={
                                    roles.includes(ROLES.CARTERA)
                                      ? 'Es Carterista'
                                      : ''
                                  }
                                >
                                  Cartera
                                </p>
                              </li>
                              <li className='col-sm-2'>
                                <p
                                  className='fw-semibold'
                                  style={{
                                    color: roles.includes(ROLES.CONTABILIDAD)
                                      ? 'green'
                                      : '#c4c4c4',
                                    cursor: roles.includes(ROLES.CONTABILIDAD)
                                      ? 'auto'
                                      : 'not-allowed'
                                  }}
                                  title={
                                    roles.includes(ROLES.CONTABILIDAD)
                                      ? 'Es Contabilidad'
                                      : ''
                                  }
                                >
                                  Contabilidad
                                </p>
                              </li>
                              <li className='col-sm-2'>
                                <p
                                  className='fw-semibold'
                                  style={{
                                    color: roles.includes(ROLES.JEFE_CREDITO)
                                      ? 'green'
                                      : '#c4c4c4',
                                    cursor: roles.includes(ROLES.JEFE_CREDITO)
                                      ? 'auto'
                                      : 'not-allowed'
                                  }}
                                  title={
                                    roles.includes(ROLES.JEFE_CREDITO)
                                      ? 'Es Jefe de crédito'
                                      : ''
                                  }
                                >
                                  Jefe de crédito
                                </p>
                              </li>
                            </ul>
                          </ul>
                        </div>
                      </div>
                    </TabPane>

                    {/* <TabPaneBudget
                      tabId='3'
                      userInfo={profileuser}
                      fullName={`${firstName} ${lastName}`}
                      refetch={refetch}
                      userId={id}
                    /> */}

                    {/* <TabPane tabId='3'>
                      <form>
                        <div id='newlink'>
                          <div id='1'>
                            <Row>
                              <Col lg={12}>
                                <div className='mb-3'>
                                  <Label
                                    htmlFor='jobTitle'
                                    className='form-label'
                                  >
                                    Job Title
                                  </Label>
                                  <Input
                                    type='text'
                                    className='form-control'
                                    id='jobTitle'
                                    placeholder='Job title'
                                    defaultValue='Lead Designer / Developer'
                                  />
                                </div>
                              </Col>

                              <Col lg={6}>
                                <div className='mb-3'>
                                  <Label
                                    htmlFor='companyName'
                                    className='form-label'
                                  >
                                    Company Name
                                  </Label>
                                  <Input
                                    type='text'
                                    className='form-control'
                                    id='companyName'
                                    placeholder='Company name'
                                    defaultValue='Themesbrand'
                                  />
                                </div>
                              </Col>

                              <Col lg={6}>
                                <div className='mb-3'>
                                  <label
                                    htmlFor='experienceYear'
                                    className='form-label'
                                  >
                                    Experience Years
                                  </label>
                                  <Row>
                                    <Col lg={5}>
                                      <select
                                        className='form-control'
                                        data-choices
                                        data-choices-search-false
                                        name='experienceYear'
                                        id='experienceYear'
                                      >
                                        <option defaultValue=''>
                                          Select years
                                        </option>
                                        <option value='Choice 1'>2001</option>
                                        <option value='Choice 2'>2002</option>
                                        <option value='Choice 3'>2003</option>
                                        <option value='Choice 4'>2004</option>
                                        <option value='Choice 5'>2005</option>
                                        <option value='Choice 6'>2006</option>
                                        <option value='Choice 7'>2007</option>
                                        <option value='Choice 8'>2008</option>
                                        <option value='Choice 9'>2009</option>
                                        <option value='Choice 10'>2010</option>
                                        <option value='Choice 11'>2011</option>
                                        <option value='Choice 12'>2012</option>
                                        <option value='Choice 13'>2013</option>
                                        <option value='Choice 14'>2014</option>
                                        <option value='Choice 15'>2015</option>
                                        <option value='Choice 16'>2016</option>
                                        <option value='Choice 17'>2017</option>
                                        <option value='Choice 18'>2018</option>
                                        <option value='Choice 19'>2019</option>
                                        <option value='Choice 20'>2020</option>
                                        <option value='Choice 21'>2021</option>
                                        <option value='Choice 22'>2022</option>
                                      </select>
                                    </Col>

                                    <div className='col-auto align-self-center'>
                                      to
                                    </div>

                                    <Col lg={5}>
                                      <select
                                        className='form-control'
                                        data-choices
                                        data-choices-search-false
                                        name='choices-single-default2'
                                      >
                                        <option defaultValue=''>
                                          Select years
                                        </option>
                                        <option value='Choice 1'>2001</option>
                                        <option value='Choice 2'>2002</option>
                                        <option value='Choice 3'>2003</option>
                                        <option value='Choice 4'>2004</option>
                                        <option value='Choice 5'>2005</option>
                                        <option value='Choice 6'>2006</option>
                                        <option value='Choice 7'>2007</option>
                                        <option value='Choice 8'>2008</option>
                                        <option value='Choice 9'>2009</option>
                                        <option value='Choice 10'>2010</option>
                                        <option value='Choice 11'>2011</option>
                                        <option value='Choice 12'>2012</option>
                                        <option value='Choice 13'>2013</option>
                                        <option value='Choice 14'>2014</option>
                                        <option value='Choice 15'>2015</option>
                                        <option value='Choice 16'>2016</option>
                                        <option value='Choice 17'>2017</option>
                                        <option value='Choice 18'>2018</option>
                                        <option value='Choice 19'>2019</option>
                                        <option value='Choice 20'>2020</option>
                                        <option value='Choice 21'>2021</option>
                                        <option value='Choice 22'>2022</option>
                                      </select>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={12}>
                                <div className='mb-3'>
                                  <Label
                                    htmlFor='jobDescription'
                                    className='form-label'
                                  >
                                    Job Description
                                  </Label>
                                  <Input
                                    type='teaxtarea'
                                    className='form-control'
                                    id='jobDescription'
                                    rows='3'
                                    placeholder='Enter description'
                                    defaultValue="You always want to make sure that your fonts work well together and try to limit the number of fonts you use to three or less. Experiment and play around with the fonts that you already have in the software you're working with reputable font websites."
                                  />
                                </div>
                              </Col>

                              <div className='hstack gap-2 justify-content-end'>
                                <Link className='btn btn-success' to='#'>
                                  Delete
                                </Link>
                              </div>
                            </Row>
                          </div>
                        </div>
                        <div id='newForm' style={{ display: 'none' }} />

                        <Col lg={12}>
                          <div className='hstack gap-2'>
                            <button type='submit' className='btn btn-success'>
                              Update
                            </button>
                            <Link to='#' className='btn btn-primary'>
                              Add New
                            </Link>
                          </div>
                        </Col>
                      </form>
                    </TabPane>

                    <TabPane tabId='4'>
                      <div className='mb-4 pb-2'>
                        <h5 className='card-title text-decoration-underline mb-3'>
                          Security:
                        </h5>
                        <div className='d-flex flex-column flex-sm-row mb-4 mb-sm-0'>
                          <div className='flex-grow-1'>
                            <h6 className='fs-14 mb-1'>
                              Two-factor Authentication
                            </h6>
                            <p className='text-muted'>
                              Two-factor authentication is an enhanced security
                              meansur. Once enabled, you'll be required to give
                              two types of identification when you log into
                              Google Authentication and SMS are Supported.
                            </p>
                          </div>
                          <div className='flex-shrink-0 ms-sm-3'>
                            <Link to='#' className='btn btn-sm btn-primary'>
                              Enable Two-facor Authentication
                            </Link>
                          </div>
                        </div>
                        <div className='d-flex flex-column flex-sm-row mb-4 mb-sm-0 mt-2'>
                          <div className='flex-grow-1'>
                            <h6 className='fs-14 mb-1'>
                              Secondary Verification
                            </h6>
                            <p className='text-muted'>
                              The first factor is a password and the second
                              commonly includes a text with a code sent to your
                              smartphone, or biometrics using your fingerprint,
                              face, or retina.
                            </p>
                          </div>
                          <div className='flex-shrink-0 ms-sm-3'>
                            <Link to='#' className='btn btn-sm btn-primary'>
                              Set up secondary method
                            </Link>
                          </div>
                        </div>
                        <div className='d-flex flex-column flex-sm-row mb-4 mb-sm-0 mt-2'>
                          <div className='flex-grow-1'>
                            <h6 className='fs-14 mb-1'>Backup Codes</h6>
                            <p className='text-muted mb-sm-0'>
                              A backup code is automatically generated for you
                              when you turn on two-factor authentication through
                              your iOS or Android Twitter app. You can also
                              generate a backup code on twitter.com.
                            </p>
                          </div>
                          <div className='flex-shrink-0 ms-sm-3'>
                            <Link to='#' className='btn btn-sm btn-primary'>
                              Generate backup codes
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className='mb-3'>
                        <h5 className='card-title text-decoration-underline mb-3'>
                          Application Notifications:
                        </h5>
                        <ul className='list-unstyled mb-0'>
                          <li className='d-flex'>
                            <div className='flex-grow-1'>
                              <label
                                htmlFor='directMessage'
                                className='form-check-label fs-14'
                              >
                                Direct messages
                              </label>
                              <p className='text-muted'>
                                Messages from people you follow
                              </p>
                            </div>
                            <div className='flex-shrink-0'>
                              <div className='form-check form-switch'>
                                <Input
                                  className='form-check-input'
                                  type='checkbox'
                                  role='switch'
                                  id='directMessage'
                                  defaultChecked
                                />
                              </div>
                            </div>
                          </li>
                          <li className='d-flex mt-2'>
                            <div className='flex-grow-1'>
                              <Label
                                className='form-check-label fs-14'
                                htmlFor='desktopNotification'
                              >
                                Show desktop notifications
                              </Label>
                              <p className='text-muted'>
                                Choose the option you want as your default
                                setting. Block a site: Next to "Not allowed to
                                send notifications," click Add.
                              </p>
                            </div>
                            <div className='flex-shrink-0'>
                              <div className='form-check form-switch'>
                                <Input
                                  className='form-check-input'
                                  type='checkbox'
                                  role='switch'
                                  id='desktopNotification'
                                  defaultChecked
                                />
                              </div>
                            </div>
                          </li>
                          <li className='d-flex mt-2'>
                            <div className='flex-grow-1'>
                              <Label
                                className='form-check-label fs-14'
                                htmlFor='emailNotification'
                              >
                                Show email notifications
                              </Label>
                              <p className='text-muted'>
                                {' '}
                                Under Settings, choose Notifications. Under
                                Select an account, choose the account to enable
                                notifications for.{' '}
                              </p>
                            </div>
                            <div className='flex-shrink-0'>
                              <div className='form-check form-switch'>
                                <Input
                                  className='form-check-input'
                                  type='checkbox'
                                  role='switch'
                                  id='emailNotification'
                                />
                              </div>
                            </div>
                          </li>
                          <li className='d-flex mt-2'>
                            <div className='flex-grow-1'>
                              <Label
                                className='form-check-label fs-14'
                                htmlFor='chatNotification'
                              >
                                Show chat notifications
                              </Label>
                              <p className='text-muted'>
                                To prevent duplicate mobile notifications from
                                the Gmail and Chat apps, in settings, turn off
                                Chat notifications.
                              </p>
                            </div>
                            <div className='flex-shrink-0'>
                              <div className='form-check form-switch'>
                                <Input
                                  className='form-check-input'
                                  type='checkbox'
                                  role='switch'
                                  id='chatNotification'
                                />
                              </div>
                            </div>
                          </li>
                          <li className='d-flex mt-2'>
                            <div className='flex-grow-1'>
                              <Label
                                className='form-check-label fs-14'
                                htmlFor='purchaesNotification'
                              >
                                Show purchase notifications
                              </Label>
                              <p className='text-muted'>
                                Get real-time purchase alerts to protect
                                yourself from fraudulent charges.
                              </p>
                            </div>
                            <div className='flex-shrink-0'>
                              <div className='form-check form-switch'>
                                <Input
                                  className='form-check-input'
                                  type='checkbox'
                                  role='switch'
                                  id='purchaesNotification'
                                />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <h5 className='card-title text-decoration-underline mb-3'>
                          Delete This Account:
                        </h5>
                        <p className='text-muted'>
                          Go to the Data & Privacy section of your profile
                          Account. Scroll to "Your data & privacy options."
                          Delete your Profile Account. Follow the instructions
                          to delete your account :
                        </p>
                        <div>
                          <Input
                            type='password'
                            className='form-control'
                            id='passwordInput'
                            placeholder='Enter your password'
                            defaultValue='make@321654987'
                            style={{ maxWidth: '265px' }}
                          />
                        </div>
                        <div className='hstack gap-2 mt-3'>
                          <Link to='#' className='btn btn-soft-danger'>
                            Close & Delete This Account
                          </Link>
                          <Link to='#' className='btn btn-light'>
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </TabPane> */}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default OneUser
