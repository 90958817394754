import { gql } from '@apollo/client'

export const GET_ALL_BILLINGS = gql`
  query listBilling(
    $id: Float
    $status: String
    $documentNumber: String
    $agencyId: Float
    $createdDateGte: DateTime
    $createdDateLte: DateTime
    $name: String
    $offset: Int
  ) {
    listBilling(
      id: $id
      status_Iexact: $status
      documentNumber: $documentNumber
      quotation_Agency_Id: $agencyId
      created_Date_Gte: $createdDateGte
      created_Date_Lte: $createdDateLte
      name: $name
      offset: $offset
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          cityResidence
          firstName
          firstSurname
          status
          documentNumber
          created
          pendingDiscount
          pendingResidue
          productauxbillingSet {
            edges {
              node {
                id
                basePrice
                name
              }
            }
          }
          biller {
            firstName
            lastName
          }
          quotation {
            agency {
              name
            }
            credit {
              id
            }
            quotationType
          }
        }
      }
    }
  }
`

export const GET_CUOTATION_BILLING = gql`
  query listQuotations(
    $id: Float
    $status: String
    $documentNumber: String
    $agencyId: Float
    $createdDateGte: DateTime
    $createdDateLte: DateTime
    $name: String
  ) {
    listQuotations(
      id: $id
      status_Iexact: $status
      documentNumber: $documentNumber
      agency_Id: $agencyId
      created_Date_Gte: $createdDateGte
      created_Date_Lte: $createdDateLte
      name: $name
    ) {
      edges {
        node {
          user {
            firstName
            lastName
            id
            email
          }
          agency {
            name
            id
          }
          created
          documentNumber
          email
          firstName
          firstSurname
          id
          phoneNumber
          secondName
          secondSurname
          status
          quotationType
          quotationproductthroughSet {
            edges {
              node {
                basePrice
                id
                product {
                  id
                  name
                  category {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export const GET_SUPPORTS = gql`
  query billingSupportList {
    billingSupports
  }
`
export const GET_ONE_BILLING = gql`
  query getBilling($billingId: Int!) {
    getBilling(billingId: $billingId) {
      addressResidence
      billingDate
      cityResidence
      created
      documentNumber
      email
      firstName
      firstSurname
      id
      invoice
      phoneNumber
      secondName
      secondSurname
      status
      pendingDiscount
      biller {
        firstName
        lastName
      }
      billingcommentSet {
        edges {
          node {
            comment
            id
            created
            user {
              firstName
              lastName
            }
          }
        }
      }
      supportproductbillingSet {
        edges {
          node {
            id
            name
            file
          }
        }
      }
      productauxbillingSet {
        edges {
          node {
            basePrice
            name
            id
            documents
            residueSet {
              edges {
                node {
                  id
                  value
                  locked
                  comment
                }
              }
            }
            fieldformSet {
              edges {
                node {
                  id
                  name
                  value
                }
              }
            }
            supportproductbillingSet {
              edges {
                node {
                  name
                  file
                }
              }
            }
            priceauxbillingSet {
              edges {
                node {
                  id
                  name
                  value
                  priceType
                }
              }
            }
            discountauxbillingSet {
              edges {
                node {
                  id
                  name
                  discountType
                  value
                  pending
                }
              }
            }
          }
        }
      }
      quotation {
        id
        quotationType
        agency {
          id
          name
          zone {
            commercialCompany {
              image
            }
          }
        }
        credit {
          id
          firstName
          firstSurname
          dateBirth
          documentType
          statusSolucredit
          descriptionSolucredit
          initialFee
          productValue
        }
      }
    }
  }
`
