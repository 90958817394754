import { useMutation } from '@apollo/client'
import React, { useEffect } from 'react'
import { Button, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import { INTERCHANGE_COSIGNER } from '../../../graphql/mutations/creditMutations'
import { toast } from 'react-toastify'
import { parseId } from '../../../helpers'

/**
 *
 * @param {{isOpen: boolean, onClose: ()=>void, info: {titularName: string, cosingerName: string, cosignerId: string, creditId: string}}} param0
 * @returns
 */

export const ModalInterchangeCosigner = ({
  isOpen,
  onClose,
  info,
  refetchCredit,
  refetchCosigners
}) => {
  const [gqlInterchange, { data, loading, error }] =
    useMutation(INTERCHANGE_COSIGNER)

  useEffect(() => {
    if (!data) return
    if (data.interchangeDebtor.success) {
      toast.success('Intercambio realizado exitosamente')
      refetchCredit()
      refetchCosigners()
      onClose()
    } else if (data.interchangeDebtor?.errors) {
      toast.error(
        `Error: ${data.interchangeDebtor.errors?.message || 'Algo ocurrió'}`
      )
    }
  }, [data])

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalHeader toggle={onClose}>Intercambiar con el deudor</ModalHeader>
      <ModalBody>
        <h4>
          ¿Está seguro que quiere intercambiar el codeudor{' '}
          <span className='text-info fw-bold text-uppercase'>
            {info.cosingerName}
          </span>{' '}
          con el titular{' '}
          <span className='text-primary fw-bold text-uppercase'>
            {info.titularName}
          </span>
          ?
        </h4>
      </ModalBody>
      <div className='p-2 m-2 d-flex gap-1'>
        <Button
          color='light'
          block
          onClick={onClose}
          /* onClick={() => {
            setInfoDelete({ id: null, isDelete: false, name: '' })
          }}
          disabled={loadingDeleteEntity} */
        >
          No
        </Button>
        <Button
          color='danger'
          block
          disabled={loading}
          onClick={() => {
            if (!info.creditId || !info.cosignerId) return

            const input = {
              creditId: parseId(info.creditId),
              cosignerId: parseId(info.cosignerId)
            }
            gqlInterchange({
              variables: { input }
            })
          }}
        >
          <span className='d-flex align-items-center gap-1 justify-content-center'>
            {loading ? (
              <Spinner size='sm' color='light' />
            ) : (
              <i className='fs-4 bx bx-transfer-alt' />
            )}
            Si
          </span>
        </Button>
      </div>
    </Modal>
  )
}
