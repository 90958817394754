import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

// Import Images

import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  ModalBody,
  Label,
  Input,
  Modal,
  ModalHeader,
  Form,
  ModalFooter,
  Button,
  FormFeedback
} from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import DeleteModal from '../../../Components/Common/DeleteModal'

import TableContainer from '../../../Components/Common/TableContainer'

// Formik
import * as Yup from 'yup'
import { useFormik } from 'formik'

// Export Modal

import Loader from '../../../Components/Common/Loader'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useMutation, useQuery } from '@apollo/client'
import {
  GET_AGENCIAS,
  GET_INFO_ZONAS
} from '../../../graphql/queries/getPuntos'
import { CREATE_AGENCIA } from '../../../graphql/mutations/empresaComercial'
import { hasPermission, parseId } from '../../../helpers'
import { useGetUser } from '../../../hooks/user'
import { ALL_PERMISSIONS, ERROR_MESSAGES } from '../../../helpers/consts'

const RESET = {
  name: '',
  active: false,
  address: '',
  zone: { id: '', name: '' },
  phoneNumber: ''
}
const regexPhone = /^\d+$/

const AgenciasCustom = () => {
  const [
    gqlCreateAgencia,
    { data: dataCreate, loading: loadingCreate, error: errorCreate }
  ] = useMutation(CREATE_AGENCIA)

  const { data, loading, error } = useQuery(GET_AGENCIAS, {
    fetchPolicy: 'network-only'
  })
  const { data: dataEmpsCom } = useQuery(GET_INFO_ZONAS, {
    fetchPolicy: 'no-cache'
  })

  const { logout, permissions } = useGetUser()

  const [company, setCompany] = useState([])
  const [oneCompany, setOneCompany] = useState({})
  const [isEdit, setIsEdit] = useState(false)

  const [infoEmpComer, setInfoEmpComer] = useState([])

  // delete Company
  const [deleteModal, setDeleteModal] = useState(false)
  // const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [modal, setModal] = useState(false)

  // const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  // const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
  // Export Modal
  // const [isExportCSV, setIsExportCSV] = useState(false);

  const [isViewCompany, setIsViewCompany] = useState(false)

  /* useEffect(() => {
    if (companies && !companies.length) {
      dispatch(onGetCompanies());
    }
  }, [dispatch, companies]); */

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (company && company.img) || '',
      name: oneCompany?.name || '',
      phoneNumber: oneCompany?.phoneNumber || '',
      zone: oneCompany?.zone?.id || '',
      active: oneCompany?.active || false,
      address: oneCompany?.address || '',
      city: oneCompany?.city || ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Ingrese nombre de la agencia'),
      phoneNumber: Yup.string()
        .matches(regexPhone, 'Número no válido')
        .min(10, 'Debe tener 10 dígitos')
        .max(10, 'Debe tener 10 dígitos')
        .required('Ingrese número de teléfono'),
      zone: Yup.string().required('Seleccione empresa comercial'),
      city: Yup.string().required('Ciudad es obligatoria'),
      active: Yup.string().required('Seleccione estado de la zona'),
      address: Yup.string().required('Ingrese dirección de la agencia')
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateCompany = { ...values }
        const { zone } = updateCompany
        const idZone = parseId(zone)
        const id = parseId(oneCompany.id)

        gqlCreateAgencia({
          variables: {
            input: { ...updateCompany, zone: idZone, id }
          },
          refetchQueries: [
            {
              query: GET_AGENCIAS
            }
          ]
        })
      } else {
        const newAgencia = { ...values }
        const { zone } = newAgencia
        const idZone = parseId(zone)
        delete newAgencia.active

        gqlCreateAgencia({
          variables: {
            input: { ...newAgencia, zone: idZone, active: true }
          },
          refetchQueries: [
            {
              query: GET_AGENCIAS
            }
          ]
        })
      }
    }
  })

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error.message}`)
      if (error.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [error])

  useEffect(() => {
    if (dataCreate) {
      if (dataCreate.agencyCreateUpdate.agency) {
        toast.success(
          isEdit
            ? 'Agencia actualizada correctamente'
            : 'Agencia creada correctamente'
        )
        toggle()
        validation.resetForm()
        return
      }
      if (dataCreate.agencyCreateUpdate.errors?.length > 0) {
        toast.error(
          `ERROR! ${dataCreate.agencyCreateUpdate?.errors[0]?.messages[0]}`
        )
      }
    }
  }, [dataCreate])
  useEffect(() => {
    if (errorCreate) {
      toast.error(`ERROR! ${errorCreate.message}`)
    }
  }, [errorCreate])

  useEffect(() => {
    if (data) {
      const newArr = data.listAgency.edges.map(({ node }) => ({
        ...node
      }))
      setCompany(newArr)
    }
  }, [data])

  useEffect(() => {
    if (dataEmpsCom) {
      /** @type {{ "cursor": string, node: {id: string, name: string}}[]} arr */
      const arr = dataEmpsCom.listZones.edges
      setInfoEmpComer(arr.map(({ node: { id, name } }) => ({ id, name })))
    }
  }, [dataEmpsCom])

  /* useEffect(() => {
    if (!isEmpty(companies)) {
      setCompany(companies);
      setIsEdit(false);
    }
  }, [companies]); */

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false)
      // setCompany(null);
    } else {
      setModal(true)
    }
  }, [modal])

  // Delete Data
  const handleDeleteCompany = () => {
    if (company) {
      // dispatch(onDeleteCompanies(company._id));
      setDeleteModal(false)
    }
  }
  // Add Data
  const handleCompanyClicks = () => {
    setCompany('')
    setIsEdit(false)
    toggle()
  }

  // validation

  // Update Data
  const handleCompanyClick = useCallback(
    (arg) => {
      const company = arg
      setOneCompany({
        ...company
      })

      setIsEdit(true)
      toggle()
    },
    [toggle]
  )

  // Node API
  // useEffect(() => {
  //   if (isCompaniesCreated) {
  //     setCompany(null);
  //     dispatch(onGetCompanies());
  //   }
  // }, [
  //   dispatch,
  //   isCompaniesCreated,
  // ]);

  // Checked All
  /* const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".companyCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []); */

  // Delete Multiple

  /* const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".companyCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  }; */

  const toggleView = () => {
    setIsViewCompany((prev) => !prev)
  }

  // Column
  const columns = useMemo(
    () => [
      /* {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="companyCheckBox form-check-input"
              value={cellProps.row.original._id}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
      }, */
      {
        Header: 'Nombre de agencia',
        accessor: 'name',
        filterable: true,
        Cell: (company) => (
          <>
            <div className='d-flex align-items-center'>
              <div className='flex-shrink-0'>
                {company.row.original.image_src ? (
                  <img
                    src={
                      process.env.REACT_APP_API_URL +
                      '/images/' +
                      company.row.original.image_src
                    }
                    alt=''
                    className='avatar-xxs rounded-circle'
                  />
                ) : (
                  <div className='flex-shrink-0 avatar-xs me-2'>
                    <div className='avatar-title bg-soft-success text-success rounded-circle fs-13'>
                      {company.row.original.name.charAt(0)}
                    </div>
                  </div>
                )}
              </div>
              <div className='flex-grow-1 ms-2 name'>
                {company.row.original.name}
              </div>
            </div>
          </>
        )
      },
      {
        Header: 'Zona',
        accessor: 'zone.name',
        filterable: false
      },
      {
        Header: 'Ciudad',
        accessor: 'city',
        filterable: false
      },
      /* {
        Header: "Industry Type",
        accessor: "industry_type",
        filterable: false,
      },
      {
        Header: "Rating",
        accessor: "star_value",
        filterable: false,
        Cell: (cellProps) => (
          <>
            {cellProps.value}{" "}
            <i className="ri-star-fill text-warning align-bottom"></i>
          </>
        ),
      },
      {
        Header: "Location",
        accessor: "location",
        filterable: false,
      }, */
      {
        Header: 'Acciones',
        Cell: (cellProps) => {
          return (
            <ul className='list-inline hstack gap-2 mb-0'>
              <li className='list-inline-item' title='Ver'>
                <Link
                  to='#'
                  onClick={() => {
                    const companyData = cellProps.row.original
                    setInfo(companyData)
                    setIsViewCompany(true)
                  }}
                >
                  <i className='ri-eye-fill align-bottom text-muted' />
                </Link>
              </li>
              {hasPermission(
                permissions,
                ALL_PERMISSIONS.PUEDE_ANADIR_AGENCIA
              ) && (
                <li className='list-inline-item' title='Editar'>
                  <Link
                    className='edit-item-btn'
                    to='#'
                    onClick={() => {
                      const companyData = cellProps.row.original
                      handleCompanyClick(companyData)
                    }}
                  >
                    <i className='ri-pencil-fill align-bottom text-muted' />
                  </Link>
                </li>
              )}
              {/* <li className="list-inline-item" title="Eliminar">
                <Link
                  className="remove-item-btn"
                  onClick={() => {
                    const companyData = cellProps.row.original;
                    onClickDelete(companyData);
                  }}
                  to="#"
                >
                  <i className="ri-delete-bin-fill align-bottom text-muted"></i>
                </Link>
              </li> */}
            </ul>
          )
        }
      }
    ],
    [handleCompanyClick]
  )

  /**
   * @typedef {Object} InfoItem
   * @property {string} name
   * @property {boolean} active
   * @property {string} phoneNumber
   * @property {Object} address
   * @property {string} zone.id
   * @property {string} zone.name
   */

  /** @type {[InfoItem, React.Dispatch<React.SetStateAction<InfoItem>>]} */
  const [info, setInfo] = useState(null)

  document.title = 'Agencias | Total Sales'
  return (
    <>
      <div className='page-content'>
        {/*         <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={company}
        /> */}
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteCompany}
          onCloseClick={() => setDeleteModal(false)}
        />
        {/* <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        /> */}

        <Container fluid>
          <BreadCrumb title='Agencias' pageTitle='CRM' />

          <Row>
            <Col lg={12}>
              <Card>
                {hasPermission(
                  permissions,
                  ALL_PERMISSIONS.PUEDE_ANADIR_AGENCIA
                ) && (
                  <CardHeader>
                    <div className='d-flex align-items-center flex-wrap gap-2'>
                      <div className='flex-grow-1'>
                        <button
                          className='btn btn-info add-btn'
                          onClick={() => {
                            setIsEdit(false)
                            setOneCompany(RESET)
                            toggle()
                          }}
                        >
                          <i className='ri-add-fill me-1 align-bottom' />{' '}
                          Agregar agencia
                        </button>
                      </div>
                      {/* <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2">
                        {isMultiDeleteButton && (
                          <button
                            className="btn btn-soft-danger"
                            onClick={() => setDeleteModalMulti(true)}
                          >
                            <i className="ri-delete-bin-2-line"></i>
                          </button>
                        )}
                        <button className="btn btn-danger">
                          <i className="ri-filter-2-line me-1 align-bottom"></i>{" "}
                          Filters
                        </button>
                        <button
                          className="btn btn-soft-success"
                          onClick={() => setIsExportCSV(true)}
                        >
                          Export
                        </button>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            href="#"
                            className="btn-soft-info btn-icon"
                            tag="button"
                          >
                            <i className="ri-more-2-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className="dropdown-item" href="#">
                              All
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Week
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Month
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Year
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div> */}
                    </div>
                  </CardHeader>
                )}
              </Card>
            </Col>
            <Col>
              <Card id='companyList'>
                <CardBody className='pt-0'>
                  <div>
                    {company && (
                      <TableContainer
                        columns={columns}
                        data={company}
                        isGlobalFilter
                        isAddUserList={false}
                        customPageSize={7}
                        className='custom-header-css'
                        divClass='table-responsive table-card mb-2'
                        tableClass='align-middle table-nowrap'
                        theadClass='table-light'
                        handleCompanyClick={handleCompanyClicks}
                        isCompaniesFilter={false}
                        SearchPlaceholder='Buscar agencia...'
                      />
                    )}
                    {loading && (
                      <Loader
                        error={{
                          error: 'esperando'
                        }}
                      />
                    )}
                  </div>
                  <Modal
                    id='showModal'
                    isOpen={modal}
                    toggle={toggle}
                    onClosed={() => {
                      setInfo(RESET)
                      setOneCompany(RESET)
                    }}
                    centered
                    size='lg'
                  >
                    <ModalHeader className='bg-soft-info p-3' toggle={toggle}>
                      {isEdit ? 'Editar agencia' : 'Añadir agencia'}
                    </ModalHeader>
                    <Form
                      autoComplete='off'
                      className='tablelist-form'
                      onSubmit={(e) => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <ModalBody>
                        <input type='hidden' id='id-field' />
                        <Row className='g-3'>
                          {/* <Col lg={12}>
                            <div className="text-center">
                              <div className="position-relative d-inline-block">
                                <div className="position-absolute bottom-0 end-0">
                                  <Label
                                    htmlFor="company-logo-input"
                                    className="mb-0"
                                  >
                                    <div className="avatar-xs cursor-pointer">
                                      <div className="avatar-title bg-light border rounded-circle text-muted">
                                        <i className="ri-image-fill"></i>
                                      </div>
                                    </div>
                                  </Label>
                                  <Input
                                    name="img"
                                    className="form-control d-none"
                                    id="company-logo-input"
                                    type="file"
                                    accept="image/png, image/gif, image/jpeg"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.img || ""}
                                    invalid={
                                      validation.touched.img &&
                                      validation.errors.img
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                                <div className="avatar-lg p-1">
                                  <div className="avatar-title bg-light rounded-circle">
                                    <img
                                      src={multiUser}
                                      alt="multiUser"
                                      id="companylogo-img"
                                      className="avatar-md rounded-circle object-cover"
                                    />
                                  </div>
                                </div>
                              </div>
                              <h5 className="fs-13 mt-3">Logo de zona</h5>
                            </div>
                          </Col> */}

                          <Col lg={12}>
                            <div>
                              <Label
                                htmlFor='name-field'
                                className='form-label'
                              >
                                Nombre de la agencia
                              </Label>

                              <Input
                                name='name'
                                id='customername-field'
                                className='form-control'
                                placeholder='Nombre de agencia'
                                type='text'
                                validate={{
                                  required: { value: true }
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ''}
                                invalid={Boolean(
                                  validation.touched.name &&
                                    validation.errors.name
                                )}
                              />
                              {validation.touched.name &&
                              validation.errors.name ? (
                                <FormFeedback type='invalid'>
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={isEdit ? 9 : 12}>
                            <div>
                              <Label
                                htmlFor='zone-field'
                                className='form-label'
                              >
                                Zona
                              </Label>

                              <Input
                                name='zone'
                                type='select'
                                className='form-select'
                                id='zone-field'
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.zone || ''}
                              >
                                <option value=''>Seleccione zona</option>
                                {infoEmpComer.map(({ name, id }) => (
                                  <option value={id} key={id}>
                                    {name}
                                  </option>
                                ))}
                              </Input>
                              {validation.touched.zone &&
                              validation.errors.zone ? (
                                <FormFeedback type='invalid'>
                                  {validation.errors.zone}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          {isEdit && (
                            <Col lg={3}>
                              <div>
                                <Label
                                  htmlFor='active-field'
                                  className='form-label'
                                >
                                  Agencia activa
                                </Label>
                                <div className='form-check form-switch form-switch-lg form-switch-success'>
                                  <Input
                                    type='checkbox'
                                    className='form-check-input'
                                    name='active'
                                    id='active-field'
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.active}
                                    checked={validation.values.active}
                                  />
                                </div>
                                {validation.touched.active &&
                                validation.errors.active ? (
                                  <FormFeedback type='invalid'>
                                    {validation.errors.active}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          )}
                          <Col lg={12}>
                            <div>
                              <Label
                                htmlFor='city-field'
                                className='form-label'
                              >
                                Ciudad
                              </Label>

                              <Input
                                name='city'
                                id='city-field'
                                className='form-control'
                                placeholder='Nombre de la cuidad'
                                type='text'
                                validate={{
                                  required: { value: true }
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.city || ''}
                                invalid={Boolean(
                                  validation.touched.city &&
                                    validation.errors.city
                                )}
                              />
                              {validation.touched.city &&
                              validation.errors.city ? (
                                <FormFeedback type='invalid'>
                                  {validation.errors.city}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor='phone-field'
                                className='form-label'
                              >
                                Teléfono
                              </Label>
                              <Input
                                name='phoneNumber'
                                id='phone-field'
                                className='form-control'
                                placeholder='Ingrese teléfono'
                                type='tel'
                                validate={{
                                  required: { value: true }
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phoneNumber}
                                invalid={Boolean(
                                  validation.touched.phoneNumber &&
                                    validation.errors.phoneNumber
                                )}
                              />
                              {validation.touched.phoneNumber &&
                              validation.errors.phoneNumber ? (
                                <FormFeedback type='invalid'>
                                  {validation.errors.phoneNumber}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor='address-field'
                                className='form-label'
                              >
                                Dirección
                              </Label>
                              <Input
                                name='address'
                                id='address-field'
                                className='form-control'
                                placeholder='Ingrese dirección'
                                type='text'
                                validate={{
                                  required: { value: true }
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address || ''}
                                invalid={Boolean(
                                  validation.touched.address &&
                                    validation.errors.address
                                )}
                              />
                              {validation.touched.address &&
                              validation.errors.address ? (
                                <FormFeedback type='invalid'>
                                  {validation.errors.address}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <div className='hstack gap-2 justify-content-end'>
                          <Button
                            color='light'
                            onClick={() => {
                              setModal(false)
                            }}
                          >
                            Cancelar{' '}
                          </Button>
                          <Button
                            type='submit'
                            color='success'
                            id='add-btn'
                            disabled={loadingCreate}
                          >
                            {loadingCreate || !!isEdit
                              ? 'Actualizar'
                              : 'Añadir agencia'}
                            {loadingCreate && 'cargando...'}
                          </Button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
            <Modal
              id='showModalView'
              isOpen={isViewCompany}
              toggle={toggleView}
              centered
              size='lg'
            >
              <ModalHeader className='bg-soft-info p-3' toggle={toggleView}>
                Información de agencia
              </ModalHeader>
              <ModalBody>
                {info && (
                  <Col>
                    <Card id='company-view-detail'>
                      <CardBody className='text-center'>
                        {/* <div className="position-relative d-inline-block">
                    <div className="avatar-md">
                      <div className="avatar-title bg-light rounded-circle">
                        <img
                          src={
                            process.env.REACT_APP_API_URL +
                            "/images/" +
                            (info.image_src || "brands/mail_chimp.png")
                          }
                          alt=""
                          className="avatar-sm rounded-circle object-cover"
                        />
                      </div>
                    </div>
                  </div> */}
                        <h5 className='mt-3 mb-1'>{info.name || 'NAME'}</h5>
                        <p className='text-muted'>
                          {info?.zone?.name || 'descripción'}
                        </p>
                        {/* <ul className="list-inline mb-0">
                    <li className="list-inline-item avatar-xs">
                      <Link
                        to="#"
                        className="avatar-title bg-soft-success text-success fs-15 rounded"
                      >
                        <i className="ri-global-line"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item avatar-xs">
                      <Link
                        to="#"
                        className="avatar-title bg-soft-danger text-danger fs-15 rounded"
                      >
                        <i className="ri-mail-line"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item avatar-xs">
                      <Link
                        to="#"
                        className="avatar-title bg-soft-warning text-warning fs-15 rounded"
                      >
                        <i className="ri-question-answer-line"></i>
                      </Link>
                    </li>
                  </ul> */}
                      </CardBody>
                      <div className='card-body'>
                        <h6 className='text-muted text-uppercase fw-semibold'>
                          Información
                        </h6>
                        {/* <p className="text-muted mb-4">
                    A company incurs fixed and variable costs such as the
                    purchase of raw materials, salaries and overhead, as
                    explained by AccountingTools, Inc. Business owners have the
                    discretion to determine the actions.
                  </p> */}
                        <div className='table-responsive table-card'>
                          <table className='table table-borderless mb-0'>
                            <tbody>
                              <tr>
                                <td className='fw-medium'>Teléfono</td>
                                <td>{info.phoneNumber || '---'}</td>
                              </tr>
                              <tr>
                                <td className='fw-medium'>Estado</td>
                                <td>{info.active ? 'Activa' : 'No activa'}</td>
                              </tr>
                              <tr>
                                <td className='fw-medium'>dirección</td>
                                <td>{info.address || 'dirección'}</td>
                              </tr>
                              <tr>
                                <td className='fw-medium'>Zona</td>
                                <td>{info?.zone?.name || 'dirección'}</td>
                              </tr>
                              <tr>
                                <td className='fw-medium'>Ciudad</td>
                                <td>{info?.city || 'N/A'}</td>
                              </tr>
                              {/* <tr>
                            <td className="fw-medium">Employee</td>
                            <td>{info.employee || "10-50"}</td>
                          </tr>
                          <tr>
                            <td className="fw-medium">Rating</td>
                            <td>
                              {info.star_value || "4.0"}{" "}
                              <i className="ri-star-fill text-warning align-bottom"></i>
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-medium">Website</td>
                            <td>
                              <Link
                                to="#"
                                className="link-primary text-decoration-underline"
                              >
                                {info.website || "www.syntycesolution.com"}
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-medium">Contact Email</td>
                            <td>
                              {info.contact_email || "info@syntycesolution.com"}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-medium">Since</td>
                            <td>{info.since || "1995"}</td>
                          </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Card>
                  </Col>
                )}
              </ModalBody>
            </Modal>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default AgenciasCustom
