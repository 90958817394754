import React from 'react'
import moment from 'moment'

export const TbodyInfoBasic = ({
  firstName,
  secondName,
  firstSurname,
  secondSurname,
  documentType,
  documentNumber,
  expeditionDate,
  dateBirth,
  phoneNumber,
  email
}) => {
  return (
    <tbody>
      <tr>
        <th>Nombre(s)</th>
        <td>
          {firstName} {secondName}
        </td>
      </tr>
      <tr>
        <th>Apellido(s)</th>
        <td>
          {firstSurname} {secondSurname}
        </td>
      </tr>
      <tr>
        <th>Doc. de identificación</th>
        <td>
          {documentType} {documentNumber}
        </td>
      </tr>
      <tr>
        <th>Fecha de expedición</th>
        <td>{moment(expeditionDate).format('DD MMM [de] YYYY')}</td>
      </tr>
      <tr>
        <th>Fecha de nacimiento</th>
        <td>{moment(dateBirth).format('DD MMM [de] YYYY')}</td>
      </tr>
      <tr>
        <th>Teléfono</th>
        <td>{phoneNumber}</td>
      </tr>
      <tr>
        <th>Correo</th>
        <td>{email}</td>
      </tr>
    </tbody>
  )
}
