import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { VERSION } from '../helpers'

const Footer = () => {
  return (
    <>
      <footer className='footer'>
        <Container fluid>
          <Row>
            {/* <Col sm={6}>
              {new Date().getFullYear()} © Motocicletas SAS. {VERSION}
            </Col> */}
            <Col sm={6}>
              {new Date().getFullYear()} © {VERSION}
            </Col>
            <Col sm={6}>
              {/* <div className='text-sm-end d-none d-sm-block'>
                Hecho con <i className='mdi mdi-heart text-danger' /> por
                Soluciones Integrales OB SAS - Motocicletas SAS.
              </div> */}
              <div className='text-sm-end d-none d-sm-block'>
                Hecho con <i className='mdi mdi-heart text-danger' /> por
                Soluciones Integrales OB SAS.
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  )
}

export default Footer
