import { useEffect, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table
} from 'reactstrap'
import {
  GET_FORM_CREDIT,
  GET_SPOUSE_CREDIT
} from '../../../graphql/queries/getCredits'
import { canEditOrAdd, getFormParse, parseId } from '../../../helpers'
import { useFormik } from 'formik'
import {
  ADD_SPOUSE_CREDIT,
  DELETE_ENTITY,
  EDIT_SPOUSE_CREDIT
} from '../../../graphql/mutations/creditMutations'
import * as Yup from 'yup'
import moment from 'moment'
import { FormDinamyc } from './FormDinamyc'
import { FormDataView } from './FormDataView'
import { ALL_PERMISSIONS, ENTITIES_TO_DELETE } from '../../../helpers/consts'
import { useGetUser } from '../../../hooks/user'

const validationSchema = Yup.object().shape({
  documentNumber: Yup.string()
    .required('Número de documento es requerido')
    .matches(/^[0-9]+$/, 'El número de documento debe contener solo números'),
  documentType: Yup.string()
    .oneOf(['CE', 'CC'])
    .required('Tipo de documento es requerido'),
  expeditionDate: Yup.date()
    .max(new Date(), 'Fecha no válida')
    .when('dateBirth', (dateBirth, schema) => {
      if (!dateBirth) return schema
      const minDate = new Date(dateBirth)
      minDate.setFullYear(minDate.getFullYear() + 18)
      return schema.min(minDate, 'La fecha de expedición no es posible.')
    })
    .required('La fecha de expedición es obligatoria'),
  firstName: Yup.string().required('Primer nombre es requerido'),
  secondName: Yup.string(),
  firstSurname: Yup.string().required('Primer apellido es requerido'),
  secondSurname: Yup.string(),
  dateBirth: Yup.date()
    .required('Fecha de nacimiento es requerida')
    .test('is-valid-age', 'Debe ser mayor de edad', function (value) {
      const currentDate = new Date()
      const eighteenYearsAgo = new Date(currentDate)
      eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18)
      return value <= eighteenYearsAgo
    }),
  email: Yup.string()
    .email('Correo electrónico inválido')
    .required('Correo electrónico es requerido'),
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, 'El número de documento debe contener solo números')
    .max('10', 'Debe contener 10 números')
    .min('10', 'Debe contener 10 números')
    .required('Número de teléfono es requerido')
})

export const TabSpouse = ({ idCredit, refetchCredit, statusCredit }) => {
  const [
    gqlAddSpouse,
    { data: dataAddSpouse, loading: loadingAddSpouse, error: errorAddSpouse }
  ] = useMutation(ADD_SPOUSE_CREDIT)
  const [
    gqlEditSpouse,
    { data: dataEditSpouse, loading: loadingEditSpouse, error: errorEditSpouse }
  ] = useMutation(EDIT_SPOUSE_CREDIT)
  const [
    gqlDeleteEntity,
    {
      data: dataDeleteEntity,
      loading: loadingDeleteEntity,
      error: errorDeleteEntity
    }
  ] = useMutation(DELETE_ENTITY)

  const { data, loading, error, refetch } = useQuery(GET_SPOUSE_CREDIT, {
    variables: {
      creditId: parseId(idCredit)
    },
    fetchPolicy: 'no-cache'
  })
  const [
    gqlGetForm,
    { data: dataForm, loading: loadingForm, error: errorForm }
  ] = useLazyQuery(GET_FORM_CREDIT, { fetchPolicy: 'no-cache' })

  const { permissions } = useGetUser()

  const [showAddSpouse, setShowAddSpouse] = useState(false)
  const [namtrikForm, setNamtrikForm] = useState(null)
  const [formDynamicValues, setFormDynamicValues] = useState(null)

  const [infoEdit, setInfoEdit] = useState({
    isEdit: false,
    id: null
  })

  const [infoDelete, setInfoDelete] = useState({
    isDelete: false,
    id: null,
    name: ''
  })

  const formik = useFormik({
    initialValues: {
      documentNumber: '',
      documentType: '',
      expeditionDate: new Date().toISOString().split('T')[0],
      firstName: '',
      secondName: '',
      firstSurname: '',
      secondSurname: '',
      dateBirth: new Date(new Date().setFullYear(new Date().getFullYear() - 18))
        .toISOString()
        .split('T')[0],
      email: '',
      phoneNumber: ''
    },
    validationSchema,
    onSubmit: (values) => {
      // Aquí puedes manejar la lógica de envío del formulario
      const input = { ...values, creditId: parseId(idCredit) }
      if (formDynamicValues) {
        input.form = { forms: formDynamicValues }
      }
      console.log('input', input)
      if (infoEdit.isEdit) {
        input.id = parseId(infoEdit.id)
        gqlEditSpouse({
          variables: {
            input
          }
        })
        return
      }
      gqlAddSpouse({
        variables: {
          input
        }
      })
    }
  })

  useEffect(() => {
    if (error) {
      toast.error('Error: No se pudo cargar cónyuge')
    }
  }, [error])

  useEffect(() => {
    if (!dataForm) return
    if (dataForm.getEntityForms && dataForm.getEntityForms.length > 0) {
      // const parse = JSON.parse(dataForm.getEntiyForm)
      setNamtrikForm(dataForm.getEntityForms.map((str) => JSON.parse(str)))
    }
  }, [dataForm])

  useEffect(() => {
    if (errorForm) {
      toast.error('Error: ' + errorForm.message)
    }
  }, [errorForm])

  useEffect(() => {
    if (errorAddSpouse) {
      toast.error('Error: ' + errorAddSpouse.message)
    }
  }, [errorAddSpouse])

  useEffect(() => {
    if (!dataAddSpouse) return
    if (dataAddSpouse.creditAddSpouse?.success) {
      toast.success('Cónyuge agregad@ correctamente')
      formik.resetForm()
      setShowAddSpouse(false)
      refetchCredit()
      refetch()
    } else if (dataAddSpouse.creditAddSpouse.errors) {
      toast.error('ERROR: ' + dataAddSpouse.creditAddSpouse.errors.message)
    }
  }, [dataAddSpouse])

  useEffect(() => {
    if (!dataDeleteEntity) return
    if (dataDeleteEntity.creditDeleteInformation?.success) {
      toast.success('Cónyuge eliminado correctamente')
      setInfoDelete({
        id: null,
        isDelete: false,
        name: ''
      })
      refetch()
    } else if (dataDeleteEntity.creditDeleteInformation?.errors?.message) {
      toast.error(
        `Error: ${dataDeleteEntity.creditDeleteInformation?.errors?.message}`
      )
    }
  }, [dataDeleteEntity])

  useEffect(() => {
    if (errorDeleteEntity) {
      toast.error('Error: ' + errorDeleteEntity.message)
    }
  }, [errorDeleteEntity])

  useEffect(() => {
    if (errorEditSpouse) {
      toast.error('Error: ' + errorEditSpouse.message)
    }
  }, [errorEditSpouse])

  useEffect(() => {
    if (!dataEditSpouse) return
    if (dataEditSpouse.creditSpouseUpdate?.success) {
      toast.success('Cónyuge agregad@ correctamente')
      formik.resetForm()
      setShowAddSpouse(false)
      refetchCredit()
      setInfoEdit({
        id: null,
        isEdit: false
      })
      refetch()
    } else if (dataEditSpouse.creditSpouseUpdate.errors) {
      toast.error(
        'ERROR: ' + dataEditSpouse.creditSpouseUpdate.errors.message ||
          'No se pudo actualizar'
      )
    }
  }, [dataEditSpouse])

  const toggleShowAddSpouse = () => {
    setShowAddSpouse((p) => !p)
  }

  const toggleDelete = () => {
    infoDelete.isDelete &&
      setInfoDelete({
        id: null,
        isDelete: false,
        name: ''
      })
  }

  if (loading || data == null) {
    return (
      <Card>
        <CardBody
          className='d-flex align-items-center justify-content-center'
          style={{ height: '30vh' }}
        >
          <Spinner style={{ width: '3em', height: '3em' }} />
        </CardBody>
      </Card>
    )
  }
  /** @type {null | {id: string, firstName: string, secondName: string, firstSurname: string, secondSurname: string, documentType: string, documentNumber: string, dateBirth: string, expeditionDate: string, email: string, phoneNumber: string, formData: string}} */
  const spouse = data.getSpouse

  return (
    <>
      <Card>
        <CardHeader className='d-flex align-items-center justify-content-between'>
          <CardTitle>Cónyuge</CardTitle>
          {spouse == null ? (
            <>
              {canEditOrAdd({
                isAdd: true,
                permissionAdmin: ALL_PERMISSIONS.PUEDE_EDITAR_TODO_CREDITO,
                permissions,
                permissionToAdd: ALL_PERMISSIONS.PUEDE_AGREGAR_CONYUGE,
                statusCredit
              }) && (
                <Button
                  onClick={() => {
                    setShowAddSpouse(true)
                    gqlGetForm({
                      variables: {
                        codename: 'Spouse'
                      }
                    })
                  }}
                >
                  Agregar cónyuge
                </Button>
              )}
            </>
          ) : (
            <>
              {canEditOrAdd({
                isAdd: false,
                permissionAdmin: ALL_PERMISSIONS.PUEDE_EDITAR_TODO_CREDITO,
                permissions,
                permissionToEdit: ALL_PERMISSIONS.PUEDE_CAMBIAR_CONYUGE,
                statusCredit
              }) && (
                <Button
                  color='soft-danger'
                  onClick={() => {
                    setShowAddSpouse(true)
                    formik.setValues({
                      dateBirth: spouse.dateBirth,
                      documentNumber: spouse.documentNumber,
                      documentType: spouse.documentType,
                      email: spouse.email,
                      expeditionDate: spouse.expeditionDate,
                      firstName: spouse.firstName,
                      firstSurname: spouse.firstSurname,
                      phoneNumber: spouse.phoneNumber,
                      secondName: spouse.secondName,
                      secondSurname: spouse.secondSurname
                    })
                    setInfoEdit({
                      id: spouse.id,
                      isEdit: true
                    })
                    setNamtrikForm(JSON.parse(spouse.formData))
                  }}
                >
                  <i className='mdi mdi-pencil' /> Editar
                </Button>
              )}
            </>
          )}
        </CardHeader>
        {spouse == null ? (
          <CardBody
            className='d-flex align-items-center justify-content-center'
            style={{ height: '30vh' }}
          >
            <div>No hay cónyuge registrad@</div>
          </CardBody>
        ) : (
          <>
            <CardBody>
              <div className='table-responsive table-card'>
                <Table className='table table-borderless mb-0'>
                  <tbody>
                    <tr>
                      <td className='fw-medium'>Nombre(s)</td>
                      <td>
                        {spouse.firstName} {spouse.secondName}
                      </td>
                    </tr>
                    <tr>
                      <td className='fw-medium'>Apellido(s)</td>
                      <td>
                        {spouse.firstSurname} {spouse.secondSurname}
                      </td>
                    </tr>
                    <tr>
                      <td className='fw-medium'>Documento</td>
                      <td>
                        {spouse.documentType} {spouse.documentNumber}
                      </td>
                    </tr>
                    <tr>
                      <td className='fw-medium'>Fecha de nacimiento</td>
                      <td>
                        {moment(spouse.dateBirth).format('DD MMM [del] YYYY')}
                      </td>
                    </tr>
                    <tr>
                      <td className='fw-medium'>Fecha de expedición</td>
                      <td>
                        {moment(spouse.expeditionDate).format(
                          'DD MMM [del] YYYY'
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className='fw-medium'>Teléfono</td>
                      <td>{spouse.phoneNumber}</td>
                    </tr>
                    <tr>
                      <td className='fw-medium'>Correo</td>
                      <td>{spouse.email}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              {spouse.formData && <FormDataView data={spouse.formData} />}
            </CardBody>
            {canEditOrAdd({
              isAdd: false,
              permissionAdmin: ALL_PERMISSIONS.PUEDE_ELIMINAR_ENTIDAD_ADMIN,
              permissions,
              permissionToEdit: ALL_PERMISSIONS.PUEDE_ELIMINAR_ENTIDAD,
              statusCredit
            }) && (
              <CardFooter className='d-flex justify-content-end'>
                <Button
                  color='danger'
                  onClick={() => {
                    setInfoDelete({
                      isDelete: true,
                      name: `${spouse.firstName} ${spouse.firstSurname}`,
                      id: spouse.id
                    })
                  }}
                >
                  Eliminar
                </Button>
              </CardFooter>
            )}
          </>
        )}
      </Card>
      <Modal isOpen={infoDelete.isDelete} toggle={toggleDelete} centered>
        <ModalBody>
          <h4>
            ¿Seguro desea eliminar a{' '}
            <span className='text-info text-uppercase'>{infoDelete.name}</span>{' '}
            como cónyuge?
          </h4>
        </ModalBody>
        <div className='p-2 m-2 d-flex gap-1'>
          <Button
            color='light'
            block
            onClick={() => {
              setInfoDelete({ id: null, isDelete: false, name: '' })
            }}
            disabled={loadingDeleteEntity}
          >
            No
          </Button>
          <Button
            color='danger'
            block
            disabled={loadingDeleteEntity}
            onClick={() => {
              if (!infoDelete.id) return
              const idDelete = parseId(infoDelete.id)
              const input = {
                entity: ENTITIES_TO_DELETE.CONYUGE,
                id: idDelete
              }
              gqlDeleteEntity({
                variables: { input }
              })
            }}
          >
            <span className='d-flex align-items-center gap-1 justify-content-center'>
              {loadingDeleteEntity ? (
                <Spinner size='sm' color='light' />
              ) : (
                <i className='fs-4 mdi mdi-trash-can-outline' />
              )}
              Si
            </span>
          </Button>
        </div>
      </Modal>

      <Modal isOpen={showAddSpouse} size='lg'>
        <ModalHeader toggle={toggleShowAddSpouse} className='bg-soft-info p-4'>
          {infoEdit.isEdit ? 'Editar' : 'Agregar'} cónyuge
          {loadingForm && <Spinner size='sm' className='ms-2' />}
        </ModalHeader>
        <form
          autoComplete='off'
          onSubmit={(e) => {
            e.preventDefault()
            const values = Object.fromEntries(new FormData(e.target))
            console.log('values', values)
            const arrayFinal = getFormParse(values)
            if (arrayFinal.length > 0) {
              setFormDynamicValues(arrayFinal)
            }
            formik.submitForm()
          }}
        >
          <ModalBody>
            <Row>
              <Col lg={6}>
                <FormGroup>
                  <Label htmlFor='firstName-Spouse'>
                    Primer nombre
                    <span
                      style={{ color: 'red', fontSize: '0.75em' }}
                      title='Requerido'
                    >
                      *
                    </span>
                  </Label>
                  <Input
                    id='firstName-Spouse'
                    name='firstName'
                    placeholder='Ingrese primer nombre'
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.firstName && formik.errors.firstName
                    )}
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <FormFeedback>{formik.errors.firstName}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Label htmlFor='secondName-Spouse'>Segundo nombre</Label>
                  <Input
                    id='secondName-Spouse'
                    name='secondName'
                    placeholder='Ingrese segundo nombre'
                    value={formik.values.secondName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.secondName && formik.errors.secondName
                    )}
                  />
                  {formik.touched.secondName && formik.errors.secondName && (
                    <FormFeedback>{formik.errors.secondName}</FormFeedback>
                  )}
                </FormGroup>
              </Col>

              <Col lg={6}>
                <FormGroup>
                  <Label htmlFor='firstSurname-Spouse'>
                    Apellido
                    <span
                      style={{ color: 'red', fontSize: '0.75em' }}
                      title='Requerido'
                    >
                      *
                    </span>
                  </Label>
                  <Input
                    id='firstSurname-Spouse'
                    name='firstSurname'
                    placeholder='Ingrese apellido'
                    value={formik.values.firstSurname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.firstSurname && formik.errors.firstSurname
                    )}
                  />
                  {formik.touched.firstSurname &&
                    formik.errors.firstSurname && (
                      <FormFeedback>{formik.errors.firstSurname}</FormFeedback>
                    )}
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Label htmlFor='secondSurname-Spouse'>Segundo apellido</Label>
                  <Input
                    id='secondSurname-Spouse'
                    name='secondSurname'
                    placeholder='Ingrese segundo apellido'
                    value={formik.values.secondSurname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.secondSurname &&
                        formik.errors.secondSurname
                    )}
                  />
                  {formik.touched.secondSurname &&
                    formik.errors.secondSurname && (
                      <FormFeedback>{formik.errors.secondSurname}</FormFeedback>
                    )}
                </FormGroup>
              </Col>

              <Col lg={5}>
                <FormGroup>
                  <Label htmlFor='documentType-Spouse'>
                    Tipo de documento
                    <span
                      style={{ color: 'red', fontSize: '0.75em' }}
                      title='Requerido'
                    >
                      *
                    </span>
                  </Label>
                  <Input
                    id='documentType-Spouse'
                    name='documentType'
                    type='select'
                    placeholder='Ingrese apellido'
                    value={formik.values.documentType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.documentType && formik.errors.documentType
                    )}
                  >
                    <option value=''>Seleccione un tipo</option>

                    <option value='CC'>Cédula ciudadanía</option>
                    <option value='CE'>Cédula extranjería</option>
                  </Input>
                  {formik.touched.documentType &&
                    formik.errors.documentType && (
                      <FormFeedback>{formik.errors.documentType}</FormFeedback>
                    )}
                </FormGroup>
              </Col>
              <Col lg={7}>
                <FormGroup>
                  <Label htmlFor='documentNumber-Spouse'>
                    No. documento
                    <span
                      style={{ color: 'red', fontSize: '0.75em' }}
                      title='Requerido'
                    >
                      *
                    </span>
                  </Label>
                  <Input
                    id='documentNumber-Spouse'
                    name='documentNumber'
                    placeholder='Ingrese número de documento'
                    value={formik.values.documentNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.documentNumber &&
                        formik.errors.documentNumber
                    )}
                  />
                  {formik.touched.documentNumber &&
                    formik.errors.documentNumber && (
                      <FormFeedback>
                        {formik.errors.documentNumber}
                      </FormFeedback>
                    )}
                </FormGroup>
              </Col>

              <Col lg={6}>
                <FormGroup>
                  <Label htmlFor='dateBirth-Spouse'>Fecha de nacimiento</Label>
                  <Input
                    type='date'
                    id='dateBirth-Spouse'
                    name='dateBirth'
                    placeholder='Ingrese fecha de nacimiento'
                    value={formik.values.dateBirth}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.dateBirth && formik.errors.dateBirth
                    )}
                  />
                  {formik.touched.dateBirth && formik.errors.dateBirth && (
                    <FormFeedback>{formik.errors.dateBirth}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Label htmlFor='expeditionDate-Spouse'>
                    Fecha de expedición
                  </Label>
                  <Input
                    type='date'
                    id='expeditionDate-Spouse'
                    name='expeditionDate'
                    placeholder='Ingrese fecha de expedición'
                    value={formik.values.expeditionDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.expeditionDate &&
                        formik.errors.expeditionDate
                    )}
                  />
                  {formik.touched.expeditionDate &&
                    formik.errors.expeditionDate && (
                      <FormFeedback>
                        {formik.errors.expeditionDate}
                      </FormFeedback>
                    )}
                </FormGroup>
              </Col>

              <Col lg={6}>
                <FormGroup>
                  <Label htmlFor='documentNumber-Spouse'>
                    Correo electrónico
                    <span
                      style={{ color: 'red', fontSize: '0.75em' }}
                      title='Requerido'
                    >
                      *
                    </span>
                  </Label>
                  <Input
                    id='email-Spouse'
                    name='email'
                    placeholder='Ingrese correo electrónico'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.email && formik.errors.email
                    )}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <FormFeedback>{formik.errors.email}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Label htmlFor='documentNumber-Spouse'>
                    Teléfono
                    <span
                      style={{ color: 'red', fontSize: '0.75em' }}
                      title='Requerido'
                    >
                      *
                    </span>
                  </Label>
                  <Input
                    id='phoneNumber-Spouse'
                    name='phoneNumber'
                    placeholder='Ingrese número de teléfono'
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    )}
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                    <FormFeedback>{formik.errors.phoneNumber}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
              {/* <Col lg={12}>
                  <FormGroup>
                    <Input
                      type='text'
                      onInput={(e) => {
                        console.log('e.target', e.target)
                        console.log('e.target.value', e.target.value)
                        const newValue = e.target.value.replace(/\D/g, '')
                        if (newValue === '') return (e.target.value = '')
                        e.target.value = Number(newValue).toLocaleString()
                      }}
                    />
                  </FormGroup>
                </Col> */}
              {namtrikForm && <FormDinamyc namtrikForm={namtrikForm} />}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color='light'
              type='button'
              onClick={() => {
                setShowAddSpouse(false)
                formik.resetForm()
                setInfoEdit({
                  id: null,
                  isEdit: false
                })
              }}
            >
              Cancelar
            </Button>
            <Button
              type='submit'
              disabled={loadingAddSpouse || loadingEditSpouse}
              className='d-flex align-items-center gap-1'
            >
              {loadingEditSpouse && <Spinner size='sm' color='light' />}
              {loadingAddSpouse && <Spinner size='sm' color='light' />}
              {infoEdit.isEdit ? 'Editar' : 'Agregar'}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  )
}
