/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap'

import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { hasPermission } from '../../../helpers'
import { useNavigate } from 'react-router-dom'
import {
  ALL_PERMISSIONS,
  STORAGE_NAMES,
  TYPES_COTIZ_CREATE
} from '../../../helpers/consts'
import { useGetUser } from '../../../hooks/user'
import { CreateCotizLibre } from '../../../Components/Own/addQuotatios/CreateCotizLibre'
import { CreateCotizMotos } from '../../../Components/Own/addQuotatios/CreateCotizMotos'

export default function AddCotizaciones() {
  const navigate = useNavigate()

  const { permissions } = useGetUser()

  const [isOpenType, setIsOpenType] = useState(true)
  const [typesCotiz, setTypesCotiz] = useState([])
  const [selectQuoType, setSelectQuoType] = useState('')

  const [selectType, setSelectType] = useState(null)

  useEffect(() => {
    if (!hasPermission(permissions, ALL_PERMISSIONS.PUEDE_ANADIR_COTIZACION)) {
      navigate('/')
    }
  }, [permissions])

  useEffect(() => {
    const strArr = window.localStorage.getItem(STORAGE_NAMES.TYPE_COTIZ)
    if (!strArr) return
    const arr = JSON.parse(strArr)
    setTypesCotiz(arr)
  }, [])

  const onBackSelect = () => {
    setIsOpenType(true)
    setSelectType(null)
  }

  document.title = 'Crear Cotización | TOTAL SALES'

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb
          title='Crear cotización'
          pageTitle='Cotizaciones'
          back='/cotizaciones'
        />
        {isOpenType || !selectType ? (
          <div>
            <Spinner />
          </div>
        ) : selectType?.toUpperCase() === TYPES_COTIZ_CREATE.LIBRE_INVERSION ? (
          <CreateCotizLibre
            onBackSelect={onBackSelect}
            creditLine={
              selectType?.toUpperCase() || TYPES_COTIZ_CREATE.LIBRE_INVERSION
            }
          />
        ) : selectType?.toUpperCase() === TYPES_COTIZ_CREATE.MOTOCICLETAS ? (
          <CreateCotizMotos
            onBackSelect={onBackSelect}
            creditLine={
              selectType?.toUpperCase() || TYPES_COTIZ_CREATE.MOTOCICLETAS
            }
            selectQuoType={selectQuoType}
          />
        ) : null}
      </Container>

      <Modal isOpen={isOpenType} centered size='lg'>
        <ModalHeader className='bg-soft-info p-3'>
          Tipo de cotización
        </ModalHeader>
        <ModalBody>
          <div>
            <FormGroup>
              <Label for='exampleSelect'>
                Tipo de cotización
                <span style={{ color: 'red' }} title='Obligatorio'>
                  *
                </span>
              </Label>
              <Input
                id='exampleSelect'
                name='select'
                type='select'
                value={selectQuoType}
                onChange={(e) => {
                  setSelectQuoType(e.target.value)
                }}
              >
                <option value=''>Seleccione una opción</option>
                <option value='CONTADO'>Contado</option>
                <option value='CREDITO'>Crédito</option>
                <option value='TERCEROS'>Terceros</option>
              </Input>
            </FormGroup>
          </div>
          {selectQuoType === 'CREDITO' && (
            <>
              <h4>Linea de crédito</h4>
              <Row>
                {typesCotiz.map(({ node }) => (
                  <Col lg={4} key={node.id}>
                    <Card className='ribbon-box border shadow-none mb-lg-0'>
                      <CardBody className='text-muted'>
                        {selectType === `${node.name}` && (
                          <div className='ribbon-two ribbon-two-success'>
                            <span>ELEGIDO</span>
                          </div>
                        )}
                        <h4 className='d-flex align-items-center justify-content-end'>
                          {node.legibleName}
                        </h4>
                        <p className='mb-0'>{node.description}</p>
                        <Button
                          block
                          color='info'
                          className='mt-3'
                          onClick={() => {
                            setSelectType(node.name)
                          }}
                        >
                          Seleccionar
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={
              !selectQuoType ||
              (selectQuoType === 'CREDITO' && selectType == null)
            }
            color='danger'
            onClick={() => {
              if (!selectQuoType) return
              setIsOpenType(false)
              if (selectQuoType !== 'CREDITO') {
                setSelectType(TYPES_COTIZ_CREATE.MOTOCICLETAS)
              }
            }}
          >
            Aceptar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
