/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Input,
  Spinner,
  Table,
  UncontrolledTooltip,
  Badge
} from 'reactstrap'
// import TableContainer from '../../../Components/Common/TableContainer'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
/*
import {
    useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table' */
import { useLazyQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { hasPermission, parseId } from '../../../helpers'
import { GET_ALL_USERS } from '../../../graphql/queries/getUsuarios'
import { useGetUser } from '../../../hooks/user'
import { ALL_PERMISSIONS, ERROR_MESSAGES } from '../../../helpers/consts'
import { ModalActiveUser } from '../../../Components/Own/Users/ModalActiveUser'
// import { DefaultColumnFilter } from '../../../Components/Common/filters'

// import TableContainer from '../../../Components/Common/TableContainer'
// import { Type, Quantity, OrderValue, AvgPrice, Price, Status } from './OrderCol'

const ROLES = [
  'Administrador',
  'Asesor',
  'Jefe de Zona',
  'Facturador',
  'Analista',
  'Gerente',
  'Jefe de crédito',
  'Cartera'
]

export default function Usuarios() {
  const [gqlGetUsers, { data, loading, error, refetch }] = useLazyQuery(
    GET_ALL_USERS,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const navigate = useNavigate()

  const { logout, permissions } = useGetUser()

  const [dataTable, setDataTable] = useState([])
  const [pagination, setPagination] = useState({
    offset: 0,
    page: 1
  })
  const [isPagination, setIsPagination] = useState(false)
  const [userInfo, setUserInfo] = useState({
    creditId: null,
    status: null
  })
  const [searchInput, setSearchInput] = useState('')
  const [isSearch, setIsSearch] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const [showActiveUser, setShowActiveUser] = useState(false)

  const [rolSelect, setRolSelect] = useState('')

  useEffect(() => {
    if (!hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_USUARIO)) {
      navigate('/')
    }
  }, [permissions])

  /*   useEffect(() => {
    gqlGetUsers()
  }, []) */

  useEffect(() => {
    const variables = {
      offset: pagination.offset
    }
    if (searchValue) {
      variables.name = searchValue
    }
    if (rolSelect) {
      variables.rol = rolSelect
    }
    gqlGetUsers({ variables })
  }, [pagination.offset, searchValue, rolSelect])

  useEffect(() => {
    // console.log('data', data)
    // console.log('data.listUsers', data?.listUsers)
    if (data && data.listUsers) {
      if (isPagination) {
        setDataTable((prev) => [...prev, ...data.listUsers.edges])
        setPagination((prev) => ({ ...prev, page: prev.page + 1 }))
        setIsPagination(false)
      } else {
        setDataTable(data.listUsers.edges)
      }
    }
  }, [data])

  /*   useTable({
    columns,
    data: dataTable.slice(10 * pagination.page - 10, 10 * pagination.page)
  }) */

  useEffect(() => {
    if (error) {
      toast.error(`Error! \n ${error.message}`)
      if (error.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [error])

  const toggleActiveUser = () => {
    setShowActiveUser(false)
  }
  /** @param { React.FormEvent<HTMLFormElement>} e */
  const hanldeSubmit = (e) => {
    e.preventDefault()
    if (searchInput.length >= 3) {
      setIsSearch(true)
      setPagination({ page: 1, offset: 0 })
      setSearchValue(searchInput)
    }
  }

  // console.log('data OUTSIDE', data)

  document.title = 'Usuarios | TOTAL SALES'

  if (loading && !isSearch && !isPagination) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Ver Usuarios' pageTitle='Usuarios' />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between border-0 gap-3 flex-md-column flex-lg-row flex-sm-column '>
                  <div className='w-100'>
                    <h5 className='card-title mb-0'>Usuarios</h5>
                  </div>
                  <div className='w-100'>
                    <p
                      className='placeholder-glow w-100 text-end'
                      style={{ height: '30px' }}
                    >
                      <span
                        className='placeholder col-4'
                        style={{ height: '100%' }}
                      />
                    </p>
                  </div>
                </CardHeader>
                <CardBody style={{ height: '30vh' }}>
                  <div className='d-flex justify-content-center align-items-center h-100'>
                    <Spinner style={{ width: '3em', height: '3em' }} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Ver Usuarios' pageTitle='Usuarios' />
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className='d-flex align-items-center justify-content-between border-0 gap-3 flex-md-column flex-lg-row flex-sm-column '>
                <h5 className='card-title mb-0 d-block'>Usuarios</h5>
                <div className='d-flex align-items-center gap-2'>
                  <Link
                    className='btn btn-outline-danger d-flex align-items-center gap-1'
                    to='/asesores'
                  >
                    <i className='mdi mdi-cash' />
                    Ver presupuestos
                  </Link>
                  <Link className='btn btn-primary' to='/agregar-usuario'>
                    Crear usuario
                  </Link>
                </div>
              </CardHeader>
              <CardBody>
                <Row className='mb-3'>
                  <CardBody className='border border-dashed border-end-0 border-start-0'>
                    <Row className='g-4'>
                      <Col lg={loading && isSearch ? 5 : 6}>
                        <form onSubmit={hanldeSubmit}>
                          <div className='search-box me-2 mb-2 d-inline-block w-100'>
                            <input
                              id='search-bar-0'
                              type='search'
                              value={searchInput}
                              onChange={(e) => {
                                setSearchInput(e.target.value)
                                if (e.target.value === '') {
                                  setPagination({
                                    page: 1,
                                    offset: 0
                                  })
                                  setSearchValue('')
                                }
                              }}
                              name='searchInput'
                              className='form-control search'
                              placeholder='Ingresa nombre'
                            />
                            <i className='bx bx-search-alt search-icon' />
                          </div>
                        </form>
                      </Col>
                      {loading && isSearch && (
                        <Col lg={1}>
                          <div
                            className='d-flex flex-column gap-2'
                            style={{ marginTop: '10px' }}
                          >
                            <Spinner size='sm' />
                          </div>
                        </Col>
                      )}
                      <Col lg={6}>
                        <select
                          className='form-select'
                          name='rol'
                          value={rolSelect}
                          onChange={(e) => {
                            setRolSelect(e.target.value)
                            setPagination({ page: 1, offset: 0 })
                          }}
                        >
                          <option value=''>Seleccione un rol</option>
                          {ROLES.map((rolStr) => (
                            <option key={rolStr} value={rolStr}>
                              {rolStr}
                            </option>
                          ))}
                        </select>
                      </Col>
                    </Row>
                  </CardBody>
                </Row>

                {dataTable.length > 0 && (
                  <>
                    <div className='table-responsive table-card mb-1'>
                      <Table hover className='align-middle table-nowrap'>
                        <thead className='table-light text-muted'>
                          <tr>
                            <th>Nombre</th>
                            <th>Email</th>
                            <th>Teléfono</th>
                            <th>Activo</th>
                            <th>Roles</th>
                            <th />
                          </tr>
                        </thead>

                        <tbody>
                          {dataTable
                            .slice(
                              10 * pagination.page - 10,
                              10 * pagination.page
                            )
                            .map(({ node }) => (
                              <tr key={node.id}>
                                <td>
                                  <span className='text-uppercase'>
                                    {node.firstName} {node.lastName}
                                  </span>
                                </td>
                                <td>{node.email}</td>
                                <td>
                                  {node?.profileuser?.phoneNumber || 'N/A'}
                                </td>
                                <td>
                                  {node?.isActive ? (
                                    <Badge color='success'>SI</Badge>
                                  ) : (
                                    <Badge color='danger'>NO</Badge>
                                  )}
                                </td>
                                <td>
                                  <ul className='px-0'>
                                    {node.groups.edges.map(
                                      ({ node: nodeG }) => (
                                        <li key={nodeG.name}>{nodeG.name}</li>
                                      )
                                    )}
                                  </ul>
                                </td>
                                <td>
                                  <ul className='list-inline hstack gap-2 mb-0'>
                                    <li className='list-inline-item'>
                                      <Link
                                        to={`/usuario/${parseId(node.id)}`}
                                        className='text-primary d-inline-block'
                                        id={
                                          'view' + parseId(node.id).toString()
                                        }
                                      >
                                        <i className='ri-eye-fill fs-16' />
                                      </Link>
                                      <UncontrolledTooltip
                                        placement='top'
                                        target={
                                          'view' + parseId(node.id).toString()
                                        }
                                        trigger='hover'
                                      >
                                        Ver
                                      </UncontrolledTooltip>
                                    </li>
                                    <li className='list-inline-item'>
                                      <Link
                                        to={`/editar-usuario/${parseId(
                                          node.id
                                        )}`}
                                        className='text-primary d-inline-block'
                                        id={
                                          'edit' + parseId(node.id).toString()
                                        }
                                      >
                                        <i className='ri-edit-fill fs-16' />
                                      </Link>
                                      <UncontrolledTooltip
                                        placement='top'
                                        target={
                                          'edit' + parseId(node.id).toString()
                                        }
                                        trigger='hover'
                                      >
                                        Editar
                                      </UncontrolledTooltip>
                                    </li>
                                    {hasPermission(
                                      permissions,
                                      ALL_PERMISSIONS.PUEDE_CAMBIAR_USUARIO
                                    ) && (
                                      <li className='list-inline-item'>
                                        <Button
                                          className={`d-inline-block border-0 bg-transparent p-0 m-0 ${
                                            node.isActive
                                              ? 'text-danger'
                                              : 'text-info'
                                          }`}
                                          id={
                                            'activeinactive' +
                                            parseId(node.id).toString()
                                          }
                                          color='danger'
                                          onClick={() => {
                                            setUserInfo({
                                              creditId: node.id,
                                              status: node.isActive,
                                              name: `${node.firstName} ${node.lastName}`
                                            })
                                            setShowActiveUser(true)
                                          }}
                                        >
                                          {node.isActive ? (
                                            <i className='mdi mdi-pause-circle fs-16' />
                                          ) : (
                                            <i className='mdi mdi-play-circle fs-16' />
                                          )}
                                        </Button>
                                        <UncontrolledTooltip
                                          placement='top'
                                          target={
                                            'activeinactive' +
                                            parseId(node.id).toString()
                                          }
                                          trigger='hover'
                                        >
                                          {node.isActive
                                            ? 'Desactivar'
                                            : 'Activar'}
                                        </UncontrolledTooltip>
                                      </li>
                                    )}
                                  </ul>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                    <Row className='justify-content-md-end justify-content-center align-items-center p-2'>
                      <Col className='col-md-auto'>
                        <div className='d-flex gap-1'>
                          <Button
                            color='primary'
                            disabled={pagination.page <= 1}
                            onClick={() => {
                              setPagination((prev) => ({
                                ...prev,
                                page: prev.page - 1
                              }))
                            }}
                            // onClick={previousPage}
                            // disabled={!canPreviousPage}
                          >
                            {'<'}
                          </Button>
                        </div>
                      </Col>
                      <Col className='col-md-auto d-none d-md-block'>
                        Pág{' '}
                        <strong>
                          {pagination.page} de{' '}
                          {Math.ceil(dataTable.length / 10)}
                        </strong>
                      </Col>
                      <Col className='col-md-auto'>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault()
                            if (!Number(pagination.page)) {
                              setPagination((prev) => ({
                                ...prev,
                                page: 1
                              }))
                            }
                          }}
                        >
                          <Input
                            type='number'
                            min={1}
                            style={{ width: 70 }}
                            // max={pageOptions.length}
                            value={pagination.page}
                            onChange={(e) => {
                              const { value } = e.target
                              if (value === '') {
                                setPagination((prev) => ({
                                  ...prev,
                                  page: ''
                                }))
                                return
                              }
                              if (!Number(value)) {
                                setPagination((prev) => ({
                                  ...prev,
                                  page: 1
                                }))
                                return
                              }
                              if (
                                Number(value) <=
                                Math.ceil(dataTable.length / 10)
                              ) {
                                setPagination((prev) => ({
                                  ...prev,
                                  page: Number(value)
                                }))
                              }
                            }}
                          />
                        </form>
                      </Col>

                      <Col className='col-md-auto'>
                        <div className='d-flex gap-1'>
                          <Button
                            color='primary'
                            disabled={
                              !data?.listUsers?.pageInfo?.hasNextPage &&
                              pagination.page ===
                                Math.ceil(dataTable.length / 10)
                            }
                            onClick={() => {
                              if (
                                pagination.page <
                                Math.ceil(dataTable.length / 10)
                              ) {
                                return setPagination((prev) => ({
                                  ...prev,
                                  page: prev.page + 1
                                }))
                              }
                              const start = parseId(
                                data?.listUsers?.pageInfo?.startCursor
                              )
                              setIsPagination(true)
                              setPagination((prev) => ({
                                ...prev,
                                offset: start + 10
                              }))
                            }}
                            // disabled={!canNextPage}
                          >
                            {loading ? (
                              <Spinner size='sm' color='light' />
                            ) : (
                              '>'
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                {dataTable.length === 0 && (
                  <div
                    className='d-flex justify-content-center align-items-center'
                    style={{ height: '20vh' }}
                  >
                    No hay usuarios
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {userInfo && (
        <ModalActiveUser
          isOpen={showActiveUser}
          toggle={toggleActiveUser}
          userInfo={userInfo}
          refetch={refetch}
        />
      )}
    </div>
  )
}
