import { gql } from '@apollo/client'

export const CREATE_COMMENT_CREDIT_WALLET_STR = `
  mutation walletCommentCreate($input: WalletCommentCreateInput!) {
    walletCommentCreate(input: $input) {
      errors {
        field
        message
      }
      success
    }
  }
`
export const CREATE_PAYMENT_COUTA = gql`
  mutation paymentObligation($input: PaymentObligationInput!) {
    paymentObligation(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`
export const CREATE_PAYMENTE_PARTIAL = gql`
  mutation paymentPartial($input: PartialPaymentInput!) {
    paymentPartial(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`

export const ADD_COUTA_WALLET_GESTION = gql`
  mutation addPaymentManagement($input: PaymentManagementInput!) {
    addPaymentManagement(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`

export const CREATE_ABONO_CAPITAL = gql`
  mutation paymentCapital($input: PaymentCapitalInput!) {
    paymentCapital(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`

export const CREATE_REFINACIACION_TOTAL = gql`
  mutation refinanceObligation($input: RefinanceObligationInput!) {
    refinanceObligation(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`

export const CHANGE_TASA_WALLET = gql`
  mutation changeCreditMonthlyFee($input: ChangeCreditMonthlyFeeInput!) {
    changeCreditMonthlyFee(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`

export const CREATE_MAKE_DISCOUNT = gql`
  mutation paymentAgreement($input: PaymentAgreementInput!) {
    paymentAgreement(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`
