/* eslint-disable react/jsx-indent */
import { useEffect, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table
} from 'reactstrap'
import {
  GET_FORM_CREDIT,
  GET_PRODUCT_INFO_CREDIT
} from '../../../graphql/queries/getCredits'
import {
  canEditOrAdd,
  getFormParse,
  hasPermission,
  parseId
} from '../../../helpers'
import { useFormik } from 'formik'
import {
  ADD_PRODUCT_INFO_CREDIT,
  EDIT_PRODUCT_INFO_CREDIT
} from '../../../graphql/mutations/creditMutations'
import * as Yup from 'yup'
import moment from 'moment'
import { FormDinamyc } from './FormDinamyc'
import { FormDataView } from './FormDataView'
// import { InputCurrency } from '../InputCurrency'
import { ALL_PERMISSIONS } from '../../../helpers/consts'
import { useGetUser } from '../../../hooks/user'

const validationSchema = Yup.object().shape({
  // value: Yup.string().required('Este campo es requerido'),
  name: Yup.string().required('Primer nombre es requerido'),
  description: Yup.string().required('Campo es requerido')
})

export const TabProductInfo = ({
  idCredit,
  refetchCredit,
  statusCredit = '',
  closedDate
}) => {
  const [
    gqlAddProductInfo,
    {
      data: dataAddProductInfo,
      loading: loadingAddProductInfo,
      error: errorAddProductInfo
    }
  ] = useMutation(ADD_PRODUCT_INFO_CREDIT)
  const [
    gqlEditProductInfo,
    {
      data: dataEditProductInfo,
      loading: loadingEditProductInfo,
      error: errorEditProductInfo
    }
  ] = useMutation(EDIT_PRODUCT_INFO_CREDIT)

  const { data, loading, error, refetch } = useQuery(GET_PRODUCT_INFO_CREDIT, {
    variables: {
      creditId: parseId(idCredit)
    },
    fetchPolicy: 'no-cache'
  })
  const [
    gqlGetForm,
    { data: dataForm, loading: loadingForm, error: errorForm }
  ] = useLazyQuery(GET_FORM_CREDIT)

  const { permissions } = useGetUser()

  const [showAddProductInfo, setShowAddProductInfo] = useState(false)
  const [namtrikForm, setNamtrikForm] = useState(null)
  const [formDynamicValues, setFormDynamicValues] = useState(null)

  const [showForm, setShowForm] = useState(false)

  const [infoEdit, setInfoEdit] = useState({
    isEdit: false,
    id: null
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      description: ''
      // value: ''
    },
    validationSchema,
    onSubmit: (values) => {
      // Aquí puedes manejar la lógica de envío del formulario
      const input = { ...values, creditId: parseId(idCredit) }
      if (formDynamicValues) {
        input.form = { forms: formDynamicValues }
      }
      console.log('input', input)
      if (infoEdit.isEdit) {
        input.id = parseId(infoEdit.id)
        gqlEditProductInfo({
          variables: { input }
        })
        return
      }
      gqlAddProductInfo({
        variables: {
          input
        }
      })
    }
  })

  useEffect(() => {
    if (error) {
      toast.error('Error: No se pudo cargar cónyuge')
    }
  }, [error])

  useEffect(() => {
    if (!dataForm) return
    if (dataForm.getEntityForms && dataForm.getEntityForms.length > 0) {
      // const parse = JSON.parse(dataForm.getEntiyForm)
      setNamtrikForm(dataForm.getEntityForms.map((str) => JSON.parse(str)))
    }
  }, [dataForm])

  useEffect(() => {
    if (errorForm) {
      toast.error('Error: ' + errorForm.message)
    }
  }, [errorForm])

  useEffect(() => {
    if (errorAddProductInfo) {
      toast.error('Error: ' + errorAddProductInfo.message)
    }
  }, [errorAddProductInfo])

  useEffect(() => {
    if (!dataAddProductInfo) return
    if (dataAddProductInfo.creditAddInformationProduct?.success) {
      toast.success('Información agregada correctamente')
      formik.resetForm()
      setShowAddProductInfo(false)
      refetchCredit()
      refetch()
    }
  }, [dataAddProductInfo])
  useEffect(() => {
    if (errorEditProductInfo) {
      toast.error('Error: ' + errorEditProductInfo.message)
    }
  }, [errorEditProductInfo])

  useEffect(() => {
    if (!dataEditProductInfo) return
    if (dataEditProductInfo.creditInformationProductUpdate?.success) {
      toast.success('Información actualizada correctamente')
      formik.resetForm()
      setShowAddProductInfo(false)
      refetchCredit()
      refetch()
      setInfoEdit({
        id: null,
        isEdit: false
      })
    } else if (dataEditProductInfo.creditInformationProductUpdate?.errors) {
      toast.error(
        'Error: ' +
          dataEditProductInfo.creditInformationProductUpdate.errors.message ||
          'No se pudo actualizar'
      )
    }
  }, [dataEditProductInfo])

  const toggleShowAddProductInfo = () => {
    setShowAddProductInfo((p) => !p)
  }

  if (loading || data == null) {
    return (
      <Card>
        <CardBody
          className='d-flex align-items-center justify-content-center'
          style={{ height: '30vh' }}
        >
          <Spinner style={{ width: '3em', height: '3em' }} />
        </CardBody>
      </Card>
    )
  }
  /** @type {null | {id: string, description: string, name: string, value: string, created:string, formData: string | null}} */
  const productInfo = data.getProductInformation

  return (
    <>
      <Card>
        <CardHeader className='d-flex align-items-center justify-content-between'>
          <CardTitle>Información general</CardTitle>
          {productInfo == null ? (
            <>
              {canEditOrAdd({
                isAdd: true,
                permissionAdmin: ALL_PERMISSIONS.PUEDE_EDITAR_TODO_CREDITO,
                permissions,
                permissionToAdd:
                  ALL_PERMISSIONS.PUEDE_AGREGAR_INFORMACION_PRODUCTO,
                statusCredit
              }) && (
                <Button
                  onClick={() => {
                    setShowAddProductInfo(true)
                  }}
                >
                  Agregar información
                </Button>
              )}
            </>
          ) : (
            <>
              {(closedDate == null ||
                hasPermission(
                  permissions,
                  ALL_PERMISSIONS.PUEDE_CAMBIAR_INFORMACION_PRODUCTO
                )) && (
                <Button
                  color='soft-danger'
                  onClick={() => {
                    setInfoEdit({
                      id: productInfo.id,
                      isEdit: true
                    })
                    formik.setValues({
                      description: productInfo.description,
                      name: productInfo.name,
                      value: productInfo.value
                    })
                    if (productInfo.formData) {
                      setNamtrikForm(JSON.parse(productInfo.formData))
                      setShowForm(true)
                    } else {
                      gqlGetForm({
                        variables: {
                          codename: 'ProductInformation'
                        }
                      })
                    }
                    setShowAddProductInfo(true)
                  }}
                >
                  <i className='mdi mdi-pencil' /> Editar
                </Button>
              )}
            </>
          )}
        </CardHeader>
        {productInfo == null ? (
          <CardBody
            className='d-flex align-items-center justify-content-center'
            style={{ height: '30vh' }}
          >
            <div>No hay información resgtrada</div>
          </CardBody>
        ) : (
          <CardBody>
            <div className='table-responsive table-card'>
              <Table className='table table-borderless mb-0'>
                <tbody>
                  <tr>
                    <td className='fw-medium'>Nombre</td>
                    <td>{productInfo.name}</td>
                  </tr>
                  {/* <tr>
                    <td className='fw-medium'>Valor</td>
                    <td>{parsePrice(productInfo.value)}</td>
                  </tr> */}
                  <tr>
                    <td className='fw-medium'>Descripción</td>
                    <td>{productInfo.description}</td>
                  </tr>
                  <tr>
                    <td className='fw-medium'>Creado</td>
                    <td>
                      {moment(productInfo.created).format('DD MMM [del] YYYY')}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            {productInfo.formData && (
              <FormDataView data={productInfo.formData} />
            )}
          </CardBody>
        )}
      </Card>

      <Modal isOpen={showAddProductInfo} size='lg'>
        <ModalHeader
          toggle={toggleShowAddProductInfo}
          className='bg-soft-info p-4'
        >
          Agregar información de producto{' '}
          {loadingForm && <Spinner size='sm' className='ms-2' />}
        </ModalHeader>
        <form
          autoComplete='off'
          onSubmit={(e) => {
            e.preventDefault()
            const values = Object.fromEntries(new FormData(e.target))
            console.log('values', values)
            const arrayFinal = getFormParse(values)
            if (arrayFinal.length > 0) {
              setFormDynamicValues(arrayFinal)
            }
            formik.submitForm()
          }}
        >
          <ModalBody>
            <Row>
              <Col lg={12}>
                <FormGroup>
                  <Label htmlFor='name-ProductInfo'>
                    Nombre
                    <span
                      style={{ color: 'red', fontSize: '0.75em' }}
                      title='Requerido'
                    >
                      *
                    </span>
                  </Label>
                  <Input
                    id='name-ProductInfo'
                    name='name'
                    placeholder='Ingrese la marca, línea y modelo del producto'
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(formik.touched.name && formik.errors.name)}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <FormFeedback>{formik.errors.name}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
              {/* <Col lg={6}>
                <FormGroup>
                  <Label htmlFor='value-ProductInfo'>Valor</Label>
                  <InputCurrency
                    id='value-ProductInfo'
                    name='value'
                    placeholder='Ingrese valor'
                    value={formik.values.value}
                    onValueChange={(value, name) => {
                      formik.setFieldValue(name, value)
                    }}
                    onBlur={formik.handleBlur}
                    className={`form-control ${
                      formik.touched.value && formik.errors.value
                        ? 'is-invalid'
                        : ''
                    } `}
                  />
                  {/* <Input
                    id='value-ProductInfo'
                    name='value'
                    placeholder='Ingrese valor'
                    value={formik.values.value}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.value && formik.errors.value
                    )}
                  /> *  /}
                  {formik.touched.value && formik.errors.value && (
                    <FormFeedback>{formik.errors.value}</FormFeedback>
                  )}
                </FormGroup>
              </Col> */}

              <Col lg={12}>
                <FormGroup>
                  <Label htmlFor='description-ProductInfo'>
                    Descripción
                    <span
                      style={{ color: 'red', fontSize: '0.75em' }}
                      title='Requerido'
                    >
                      *
                    </span>
                  </Label>
                  <Input
                    type='textarea'
                    id='description-ProductInfo'
                    name='description'
                    placeholder='Ingrese descripción'
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.description && formik.errors.description
                    )}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <FormFeedback>{formik.errors.description}</FormFeedback>
                  )}
                </FormGroup>
              </Col>

              <Col lg={12}>
                <FormGroup switch>
                  <Input
                    type='switch'
                    checked={showForm}
                    onChange={(e) => {
                      const { checked } = e.target
                      setShowForm(checked)
                    }}
                  />
                  <Label check>
                    {showForm ? '(Si)' : '(No)'} Agregar información extra
                  </Label>
                </FormGroup>
              </Col>
              {namtrikForm && showForm && (
                <FormDinamyc namtrikForm={namtrikForm} />
              )}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color='light'
              type='button'
              onClick={() => {
                setShowAddProductInfo(false)
                formik.resetForm()
                setInfoEdit({
                  id: null,
                  isEdit: false
                })
              }}
            >
              Cancelar
            </Button>
            <Button
              type='submit'
              disabled={loadingAddProductInfo || loadingEditProductInfo}
              className='d-flex align-items-center gap-1'
            >
              {loadingEditProductInfo && <Spinner size='sm' color='light' />}
              {loadingAddProductInfo && <Spinner size='sm' color='light' />}
              {infoEdit.isEdit ? 'Editar' : 'Agregar'}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  )
}
