import { gql } from '@apollo/client'

export const MARK_NOTIFICATION = gql`
  mutation markReadNotification($notificationId: Int!) {
    markReadNotification(notificationId: $notificationId) {
      success
    }
  }
`
export const MARK_ALL_NOTIFIACTIONS = gql`
  mutation markReadAllNotifications {
    markReadAllNotification {
      success
    }
  }
`
