import { gql } from '@apollo/client'

export const GET_ALL_EVENTS = gql`
  query listEventSchedule($after: String) {
    eventSchedules(after: $after) {
      edges {
        node {
          eventDate
          description
          url
          id
          created
          user {
            firstName
          }
        }
      }
    }
  }
`

export const GET_INACTIVE_USER = gql`
  query listUsersInactive(
    $offset: Int
    $firstName: String
    $lastName: String
    $email: String
    $currentRol: ID
    $inactivationDate: DateTime
    $id: Float
    $name: String
    $rol: String
  ) {
    listUsersInactive(
      firstName: $firstName
      offset: $offset
      lastName: $lastName
      email: $email
      currentRol: $currentRol
      inactivationDate: $inactivationDate
      id: $id
      name: $name
      rol: $rol
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          firstName
          lastName
          email
          inactivationDate
          lastLogin
        }
      }
    }
  }
`
