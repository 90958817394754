/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react'
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  /* Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, */
  Row,
  Spinner,
  Table,
  UncontrolledAccordion
} from 'reactstrap'
import Select from 'react-select'
// import TableContainer from '../../../Components/Common/TableContainer'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { useLazyQuery, useQuery } from '@apollo/client'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { hasPermission, parseId, parsePrice } from '../../../helpers'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { ALL_PERMISSIONS, QUOTATION_TYPES } from '../../../helpers/consts'
import { useGetUser } from '../../../hooks/user'
// import CurrencyInput from 'react-currency-input-field'
import {
  GET_CUOTATION_BILLING,
  GET_SUPPORTS
} from '../../../graphql/queries/getBillings'
import {
  CREATE_BILLING,
  CREATE_BILLING_STR
} from '../../../graphql/mutations/billing'
import { useMutationFetch } from '../../../hooks/useMutationFetch'
import { ModalAddDiscount } from '../../../Components/Own/oneBilling/ModalAddDiscount'
import { useDisclosure } from '../../../hooks/useDisclosure'
import { ModalAddResidue } from '../../../Components/Own/oneBilling/ModalAddResidue'
// import { DefaultColumnFilter } from '../../../Components/Common/filters'

// import TableContainer from '../../../Components/Common/TableContainer'
// import { Type, Quantity, OrderValue, AvgPrice, Price, Status } from './OrderCol'

const initialValues = {
  firstName: '',
  secondName: '',
  firstSurname: '',
  secondSurname: '',
  phoneNumber: '',
  email: '',
  cityResidence: '',
  addressResidence: '',
  products: null,
  documentNumber: '',
  supports: []
}

const validationSchema = Yup.object({
  firstName: Yup.string().required('El primer nombre es requerido'),
  secondName: Yup.string(),
  firstSurname: Yup.string().required('El primer apellido es requerido'),
  secondSurname: Yup.string(),
  phoneNumber: Yup.string().required('El número de teléfono es requerido'),
  email: Yup.string()
    .email('Correo electrónico inválido')
    .required('El correo electrónico es requerido'),
  documentNumber: Yup.string().required('El número de documento es requerido'),
  addressResidence: Yup.string().required('Dirección es requerida'),
  cityResidence: Yup.string().required('Cuidad es requerida')
  /* products: Yup.array().of(
    Yup.object()
      .shape({
        value: Yup.string().required('obligatorio')
      })
      .required('Debes seleccionar un producto por lo menos')
  ) */
})

export default function AddBilling() {
  const [
    gqlGetInfoQuotation,
    {
      data: dataInfoQuotation,
      loading: loadingQuotation,
      error: errorQuotation
    }
  ] = useLazyQuery(GET_CUOTATION_BILLING, { fetchPolicy: 'network-only' })

  const {
    data: dataSupports,
    loading: loadingSupports,
    error: errorSupports
  } = useQuery(GET_SUPPORTS)

  const [
    gqlCreateBilling,
    { data: dataCreate, loading: loadingCreate, error: errorCreate }
  ] = useMutationFetch(CREATE_BILLING)

  const { id } = useParams()
  const navigate = useNavigate()

  const { permissions } = useGetUser()

  const [modalProduct, setModalProduct] = useState(false)
  const [products, setProducts] = useState([])
  // const [open, setOpen] = useState(false)
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [optionsData, setOptionsData] = useState([])
  const [infoAddResidue, setInfoAddResidue] = useState(null)
  // const [selectProducts] = useState([])
  const [infoAddDiscount, setInfoAddDiscount] = useState(null)
  const { isOpen, onClose, onOpen } = useDisclosure()
  const {
    isOpen: isOpenResidue,
    onClose: onCloseResidue,
    onOpen: onOpenResidue
  } = useDisclosure()

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (products.length === 0) return
      const billing = { ...values }
      if (billing.supports.length > 0) {
        billing.supports = values.supports.map((support) => ({
          name: support.key,
          file: support.file
        }))
      }
      const newProducts = products.map((prod) => {
        const arrValues = Object.keys(prod.values)
        const supporstArr = arrValues.filter((str) => str.includes('-support'))
        const formArr = arrValues.filter((str) => str.includes('-form'))
        const obj = {
          productId: prod.value,
          supports: supporstArr.map((str) => ({
            name: str.split('-')[0],
            file: prod.values[str]
          })),
          form: formArr.map((str) => ({
            name: str.split('-')[0],
            value: prod.values[str]
          })),
          discounts: prod.discounts,
          documents:
            prod.documents == null ? undefined : Boolean(prod.documents),
          residue: prod.residue
        }
        return obj
      })
      billing.products = newProducts
      billing.quotationId = id
      const billingStr = {
        ...billing,
        supports: billing.supports.map(({ name }) => ({ name, file: null })),
        products: newProducts.map((pro) => ({
          ...pro,
          supports: pro.supports.map((supp) => ({ ...supp, file: null }))
        }))
      }
      const ope = {
        operationName: 'billingCreate',
        query: CREATE_BILLING_STR,
        variables: { billing: billingStr }
      }
      const objStr = JSON.stringify(ope)
      const formData = new FormData()
      const map = {}
      // console.log('objStr', objStr)
      billing.supports.forEach(({ file }, i) => {
        map[`${i}`] = [`variables.billing.supports.${i}.file`]
        formData.append(`${i}`, file, file.name)
      })
      const lngth = billing.supports.length
      let index = 0
      newProducts.forEach(({ supports }, idx) => {
        supports.forEach(({ file }, jdx) => {
          map[`${lngth + index}`] = [
            `variables.billing.products.${idx}.supports.${jdx}.file`
          ]
          formData.append(`${lngth + index}`, file, file.name)
          index++
        })
      })
      formData.append('map', JSON.stringify(map))
      /* for (const pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1])
      } */
      formData.append('operations', objStr)
      // if (values) return
      gqlCreateBilling(formData)
    }
  })

  useEffect(() => {
    if (!hasPermission(permissions, ALL_PERMISSIONS.PUEDE_ANADIR_COTIZACION)) {
      navigate('/')
    }
  }, [permissions])

  useEffect(() => {
    gqlGetInfoQuotation({
      variables: {
        id
      }
    })
  }, [id])

  useEffect(() => {
    if (!dataInfoQuotation) return
    /** @type {{node: {firstName: string,secondName: string,id: string,email:string, firstSurname: string, phoneNumber: string, secondSurname: string, documentNumber: string, products: {edges: {node: {id: string, name:string, quotationproductthroughSet: {edges: [{node: {basePrice:string}}]}}}[]}}}[]} */
    const quotation = dataInfoQuotation.listQuotations.edges

    const { node } = quotation[0]

    formik.setValues({
      documentNumber: node.documentNumber,
      email: node.email,
      firstName: node.firstName,
      firstSurname: node.firstSurname,
      phoneNumber: node.phoneNumber,
      secondName: node.secondName,
      secondSurname: node.secondSurname,
      addressResidence: '',
      cityResidence: '',
      products: null,
      supports: []
    })
  }, [dataInfoQuotation])

  useEffect(() => {
    if (!errorQuotation) return
    toast.error(`Error ${errorQuotation.message}`)
  }, [errorQuotation])

  useEffect(() => {
    if (dataInfoQuotation == null) return
    const newArr =
      dataInfoQuotation.listQuotations.edges[0].node.quotationproductthroughSet.edges.map(
        ({ node }) => ({
          label: node.product.name,
          value: parseId(node.product.id),
          cat: node.product.category.name
        })
      )
    setOptionsData(newArr)
  }, [dataInfoQuotation])

  useEffect(() => {
    if (dataCreate) {
      if (dataCreate.billingCreate?.success) {
        toast.success('Facturación creada con éxito')
        const idBilling = parseId(dataCreate.billingCreate.billing.id)
        navigate(`/facturacion/${idBilling}`)
      } else if (dataCreate.billingCreate?.errors?.message) {
        toast.error(`Error ${dataCreate.billingCreate?.errors?.message}`)
      }
    }
  }, [dataCreate])

  useEffect(() => {
    if (errorCreate) {
      toast.error(`ERROR: ${errorCreate.message}`)
    }
  }, [errorCreate])

  useEffect(() => {
    if (errorSupports) {
      toast.error(`ERROR: ${errorSupports.message}`)
    }
  }, [errorSupports])

  const toggleProduct = () => {
    setModalProduct((prev) => !prev)
  }

  const updateProductDiscount = ({ name, value, id }) => {
    setProducts((pre) => {
      const newArr = [...pre]
      const find = newArr.find(({ value: valueArr }) => id === valueArr)
      if (!find) return newArr
      find.discounts = [{ name, value }]
      return newArr
    })
  }
  const updateProductResidue = ({ value, id }) => {
    setProducts((pre) => {
      const newArr = [...pre]
      const find = newArr.find(({ value: valueArr }) => id === valueArr)
      if (!find) return newArr
      find.residue = value
      return newArr
    })
  }

  const updateDocsProduct = (id, value) => {
    if (id == null) return
    if (value == null) return
    setProducts((prev) => {
      const newArr = [...prev]
      const find = newArr.find((prod) => prod.value === id)
      if (find) find.documents = value
      return newArr
    })
  }

  document.title = 'Crear Facturación | TOTAL SALES'

  if (loadingQuotation || loadingSupports) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='Crear facturación'
            pageTitle='Facturación'
            back='/facturaciones'
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between border-0 gap-3 flex-md-column flex-lg-row flex-sm-column '>
                  <div className='w-100'>
                    <h5 className='card-title mb-0'>Crear cotización</h5>
                  </div>
                </CardHeader>
                <CardBody style={{ height: '30vh' }}>
                  <div className='d-flex justify-content-center align-items-center h-100'>
                    <Spinner style={{ width: '3em', height: '3em' }} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
  if (!dataSupports || !dataSupports.billingSupports) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='Crear facturación'
            pageTitle='Facturación'
            back='/facturaciones'
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between border-0 gap-3 flex-md-column flex-lg-row flex-sm-column '>
                  <div className='w-100'>
                    <h5 className='card-title mb-0'>Crear facturación</h5>
                  </div>
                </CardHeader>
                <CardBody style={{ height: '30vh' }}>
                  <div className='d-flex justify-content-center align-items-center h-100'>
                    <h2>No es posible crear facturaciones por el momento</h2>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  if (!dataInfoQuotation) return null

  if (dataInfoQuotation.listQuotations.edges.length === 0) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='Crear facturación'
            pageTitle='Facturación'
            back='/facturaciones'
          />
          <Row>
            <Col lg={12}>
              <Card>
                <div
                  style={{ height: '30vh' }}
                  className='d-flex align-items-center justify-content-center'
                >
                  <h6 className='text-center'>No se puede crear facturación</h6>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
  /** @type {{node: {firstName: string,secondName: string,id: string,email:string, firstSurname: string, phoneNumber: string, secondSurname: string, quotationproductthroughSet: {edges: {node: {id: string, basePrice:string, product: {id: string, name: string}}}[]}}}[]} */
  // const quotation = dataInfoQuotation.listQuotations.edges
  // console.log('optionsData', opti8onsData)
  /* const optionsData = quotation[0].node.quotationproductthroughSet.edges.map(
    ({ node }) => ({
      label: node.product.name,
      value: parseId(node.product.id)
    })
  ) */
  const infoSupport = JSON.parse(dataSupports.billingSupports)
  const { quotationType } = dataInfoQuotation.listQuotations.edges[0]?.node
  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb
          returnPage={{ to: `/cotizacion/${id}` }}
          title='Crear facturación'
          pageTitle='Facturación'
          back='/facturaciones'
        />
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className='d-flex align-items-center justify-content-between border-0 gap-3 flex-md-column flex-lg-row flex-sm-column '>
                <h5 className='card-title mb-0 d-block'>
                  Información de cliente
                </h5>
              </CardHeader>
              <Form
                autoComplete='off'
                onSubmit={(e) => {
                  e.preventDefault()
                  setIsSubmiting(true)
                  formik.handleSubmit()
                }}
              >
                <CardBody>
                  <input type='hidden' id='id-field' />
                  <Row className='g-3'>
                    <Col lg={6}>
                      <div>
                        <Label htmlFor='firstName-field' className='form-label'>
                          Nombre
                          <span style={{ color: 'red' }} title='Obligatorio'>
                            *
                          </span>
                        </Label>
                        <Input
                          type='text'
                          name='firstName'
                          id='firstName-field'
                          placeholder='Ingrese su primer nombre'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstName}
                          invalid={Boolean(
                            formik.touched.firstName && formik.errors.firstName
                          )}
                        />
                        {formik.touched.firstName && formik.errors.firstName ? (
                          <FormFeedback>{formik.errors.firstName}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div>
                        <Label
                          htmlFor='secondName-field'
                          className='form-label'
                        >
                          Segundo nombre
                        </Label>
                        <Input
                          type='text'
                          name='secondName'
                          id='secondName-field'
                          placeholder='Ingrese su segundo nombre'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.secondName}
                          invalid={Boolean(
                            formik.touched.secondName &&
                              formik.errors.secondName
                          )}
                        />
                        {formik.touched.secondName &&
                        formik.errors.secondName ? (
                          <FormFeedback>
                            {formik.errors.secondName}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col lg={6}>
                      <div>
                        <Label
                          htmlFor='firstSurname-field'
                          className='form-label'
                        >
                          Apellido
                          <span style={{ color: 'red' }} title='Obligatorio'>
                            *
                          </span>
                        </Label>
                        <Input
                          type='text'
                          name='firstSurname'
                          id='firstSurname-field'
                          placeholder='Ingrese su primer apellido'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstSurname}
                          invalid={Boolean(
                            formik.touched.firstSurname &&
                              formik.errors.firstSurname
                          )}
                        />
                        {formik.touched.firstSurname &&
                        formik.errors.firstSurname ? (
                          <FormFeedback>
                            {formik.errors.firstSurname}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div>
                        <Label
                          htmlFor='secondSurname-field'
                          className='form-label'
                        >
                          Segundo apellido
                        </Label>
                        <Input
                          type='text'
                          name='secondSurname'
                          id='secondSurnname-field'
                          placeholder='Ingrese su segundo apellido'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.secondSurname}
                          invalid={Boolean(
                            formik.touched.secondSurname &&
                              formik.errors.secondSurname
                          )}
                        />
                        {formik.touched.secondSurname &&
                        formik.errors.secondSurname ? (
                          <FormFeedback>
                            {formik.errors.secondSurname}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col lg={6}>
                      <div>
                        <Label
                          htmlFor='phoneNumber-field'
                          className='form-label'
                        >
                          Número de teléfono
                          <span style={{ color: 'red' }} title='Obligatorio'>
                            *
                          </span>
                        </Label>
                        <Input
                          type='tel'
                          name='phoneNumber'
                          id='phoneNumber-field'
                          placeholder='Ingrese su número de teléfono'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phoneNumber}
                          invalid={Boolean(
                            formik.touched.phoneNumber &&
                              formik.errors.phoneNumber
                          )}
                        />
                        {formik.touched.phoneNumber &&
                        formik.errors.phoneNumber ? (
                          <FormFeedback>
                            {formik.errors.phoneNumber}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div>
                        <Label htmlFor='email-field' className='form-label'>
                          Correo electrónico
                          <span style={{ color: 'red' }} title='Obligatorio'>
                            *
                          </span>
                        </Label>
                        <Input
                          type='email'
                          name='email'
                          id='email-field'
                          placeholder='Ingrese su correo electrónico'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          invalid={Boolean(
                            formik.touched.email && formik.errors.email
                          )}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <FormFeedback>{formik.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col lg={6}>
                      <div>
                        <Label
                          htmlFor='documentNumber-field'
                          className='form-label'
                        >
                          Número de documento
                          <span style={{ color: 'red' }} title='Obligatorio'>
                            *
                          </span>
                        </Label>
                        <Input
                          type='text'
                          name='documentNumber'
                          id='documentNumber-field'
                          placeholder='Ingrese su número de documento'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.documentNumber}
                          invalid={Boolean(
                            formik.touched.documentNumber &&
                              formik.errors.documentNumber
                          )}
                        />
                        {formik.touched.documentNumber &&
                        formik.errors.documentNumber ? (
                          <FormFeedback>
                            {formik.errors.documentNumber}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div>
                        <Label
                          htmlFor='cityResidence-field'
                          className='form-label'
                        >
                          Ciudad de residencia
                          <span style={{ color: 'red' }} title='Obligatorio'>
                            *
                          </span>
                        </Label>
                        <Input
                          type='text'
                          name='cityResidence'
                          id='cityResidence-field'
                          placeholder='Ingrese la ciudad de recidencia'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.cityResidence}
                          invalid={Boolean(
                            formik.touched.cityResidence &&
                              formik.errors.cityResidence
                          )}
                        />
                        {formik.touched.cityResidence &&
                        formik.errors.cityResidence ? (
                          <FormFeedback>
                            {formik.errors.cityResidence}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div>
                        <Label
                          htmlFor='addressResidence-field'
                          className='form-label'
                        >
                          Dirección
                          <span style={{ color: 'red' }} title='Obligatorio'>
                            *
                          </span>
                        </Label>
                        <Input
                          type='text'
                          name='addressResidence'
                          id='addressResidence-field'
                          placeholder='Ingrese dirección'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.addressResidence}
                          invalid={Boolean(
                            formik.touched.addressResidence &&
                              formik.errors.addressResidence
                          )}
                        />
                        {formik.touched.addressResidence &&
                        formik.errors.addressResidence ? (
                          <FormFeedback>
                            {formik.errors.addressResidence}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    {infoSupport.global.files.map((info, idx) => (
                      <Col lg={12} key={info.name} className='mt-3'>
                        <h5 className='mt-3 mb-4 card-title'>Soportes</h5>

                        <Label>
                          {info.name}
                          {info.required && (
                            <span
                              style={{ color: 'red' }}
                              title={`${info.name} es requerida`}
                            >
                              *
                            </span>
                          )}
                        </Label>
                        <Input
                          type='file'
                          name={info.name}
                          required={info.required}
                          onChange={(e) => {
                            const file = e.target.files[0]
                            const newArr = [...formik.values.supports]
                            newArr[idx] = {
                              key: info.name,
                              file
                            }
                            formik.setFieldValue('supports', newArr)
                          }}
                        />
                      </Col>
                    ))}
                    {infoSupport.global.form.map((form) => (
                      <Col lg={12} key={form.name} className='my-2'>
                        <Label>
                          {form.name}
                          {form.required && (
                            <span style={{ color: 'red' }}>*</span>
                          )}
                        </Label>
                        <Input name={form.name} required={form.required} />
                      </Col>
                    ))}

                    <Col lg={12}>
                      <h5 className='card-title my-4'>
                        Información de productos
                        {isSubmiting && products.length === 0 && (
                          <span
                            className='ms-2 text-danger fs-10 fw-normal'
                            style={{
                              display: 'inline-flex',
                              alignItems: 'center'
                            }}
                          >
                            <i className='ri-information-line me-1' /> Debe
                            seleccionar al menos un producto
                          </span>
                        )}
                      </h5>
                    </Col>
                    <Col lg={12}>
                      <Button onClick={() => setModalProduct(true)}>
                        Agregar producto
                      </Button>
                    </Col>

                    <Col lg={12}>
                      <UncontrolledAccordion defaultOpen='1'>
                        {products.map((prod, i) => (
                          <AccordionItem key={prod.value}>
                            <AccordionHeader targetId={i + 1 + ''}>
                              {prod.label}
                            </AccordionHeader>
                            <AccordionBody accordionId={i + 1 + ''}>
                              <Table>
                                <tbody>
                                  {Object.keys(prod.values).map((key) => (
                                    <tr key={key}>
                                      <td>{key.split('-')[0]}</td>
                                      <td>
                                        {typeof prod.values[key] === 'string'
                                          ? prod.values[key] || '-'
                                          : prod.values[key] instanceof
                                            window.File
                                          ? prod.values[key].name
                                          : prod.values[key].toString()}
                                      </td>
                                    </tr>
                                  ))}
                                  {prod.discounts && (
                                    <>
                                      <tr className='bg-soft-warning'>
                                        <td className='fw-bold' colSpan={2}>
                                          DESCUENTOS
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{prod.discounts?.[0]?.name}</td>
                                        <td>
                                          {parsePrice(
                                            prod.discounts?.[0]?.value
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                  )}

                                  {prod.residue && (
                                    <>
                                      <tr className='bg-soft-info'>
                                        <td className='fw-bold' colSpan={2}>
                                          CONTRAENTREGA
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Saldo contraentrega</td>
                                        <td>{parsePrice(prod.residue)}</td>
                                      </tr>
                                    </>
                                  )}

                                  {prod.documents != null && (
                                    <tr>
                                      <th>¿Incluye documentos?</th>
                                      <td>
                                        <select
                                          className='form-select d-inline'
                                          value={prod.documents}
                                          onChange={(e) => {
                                            const { value } = e.target
                                            updateDocsProduct(prod.value, value)
                                          }}
                                        >
                                          <option value='SI'>Si</option>
                                          <option value=''>No</option>
                                        </select>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td colSpan={2}>
                                      <div className='d-flex align-items-center justify-content-end'>
                                        <div className='d-flex align-items-center gap-4'>
                                          {quotationType ===
                                            QUOTATION_TYPES.CONTADO && (
                                            <>
                                              {prod.residue ? (
                                                <Button
                                                  color='danger'
                                                  type='button'
                                                  onClick={() => {
                                                    setProducts((pre) => {
                                                      const newArr = [...pre]
                                                      const find = newArr.find(
                                                        ({ value }) =>
                                                          prod.value === value
                                                      )
                                                      if (!find) return newArr
                                                      find.residue = null
                                                      return newArr
                                                    })
                                                  }}
                                                >
                                                  Eliminar contraentrega
                                                </Button>
                                              ) : (
                                                <Button
                                                  color='warning'
                                                  outline
                                                  type='button'
                                                  onClick={() => {
                                                    onOpenResidue()
                                                    setInfoAddResidue({
                                                      productId: prod.value,
                                                      productName: prod.label
                                                    })
                                                  }}
                                                >
                                                  Agregar contraentrega
                                                </Button>
                                              )}
                                            </>
                                          )}
                                          {prod.discounts ? (
                                            <Button
                                              color='danger'
                                              outline
                                              type='button'
                                              onClick={() => {
                                                setProducts((pre) => {
                                                  const newArr = [...pre]
                                                  const find = newArr.find(
                                                    ({ value }) =>
                                                      prod.value === value
                                                  )
                                                  if (!find) return newArr
                                                  find.discounts = undefined
                                                  return newArr
                                                })
                                              }}
                                            >
                                              Quitar descuento
                                            </Button>
                                          ) : (
                                            <Button
                                              className='d-inline-flex align-items-center gap-2'
                                              color='primary'
                                              title='Agregar Descuento'
                                              type='button'
                                              onClick={() => {
                                                onOpen()
                                                setInfoAddDiscount({
                                                  productId: prod.value,
                                                  productName: prod.label
                                                })
                                              }}
                                            >
                                              <i className='mdi mdi-tag-arrow-down' />
                                              <span className='d-none d-lg-block'>
                                                Agregar Descuento
                                              </span>
                                            </Button>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tfoot>
                              </Table>
                              <div className='d-flex align-items-center gap-2'>
                                <Button
                                  color='danger'
                                  onClick={() => {
                                    setProducts((prev) => {
                                      const newArr = [...prev]
                                      const filter = newArr.filter(
                                        (p) => p.label !== prod.label
                                      )
                                      return filter
                                    })
                                    setOptionsData((prevOption) => {
                                      const arr = [...prevOption]
                                      const find = arr.find(
                                        (op) => op.label === prod.label
                                      )
                                      if (find) {
                                        delete find.isDisabled
                                      }
                                      return arr
                                    })
                                  }}
                                >
                                  Eliminar
                                </Button>
                                {/* <Button color='info'>Editar</Button> */}
                              </div>
                            </AccordionBody>
                          </AccordionItem>
                        ))}
                      </UncontrolledAccordion>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <div className='hstack gap-2 justify-content-end'>
                    {/* <Button
                      color='light'
                      onClick={() => {
                        setOpen(false)
                      }}
                    >
                      Cancelar{' '}
                    </Button> */}
                    <Button
                      type='submit'
                      color='success'
                      id='add-btn'
                      disabled={loadingCreate}
                    >
                      {loadingCreate && <Spinner size='sm' />}
                      Crear facturación
                    </Button>
                  </div>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal isOpen={modalProduct} toggle={toggleProduct}>
        <ModalHeader toggle={toggleProduct}>Agregar producto</ModalHeader>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            const formData = new FormData(e.target)
            const values = Object.fromEntries(formData)
            // console.log('values', values)
            setProducts((prev) => {
              const obj = { values, ...formik.values.products }
              if (formik.values.products.cat === 'Motocicletas') {
                obj.documents = 'SI'
              }
              return [...prev, obj]
            })
            setOptionsData((prev) => {
              const newArr = [...prev]
              const find = newArr.find(
                (obj) => obj.label === formik.values.products.label
              )
              if (find) {
                find.isDisabled = true
              }
              return newArr
            })
            formik.setFieldValue('products', null)
            setModalProduct(false)
          }}
        >
          <ModalBody>
            <Row>
              <FormGroup>
                <Label className='form-label'>Productos</Label>
                <Select
                  // name='products'
                  className='basic-multi-select'
                  // isMulti
                  classNamePrefix='select'
                  placeholder='Seleccione un producto'
                  value={formik.values.products}
                  // defaultValue={0}
                  isClearable
                  isSearchable
                  onChange={(e) => {
                    formik.setFieldValue('products', e)
                  }}
                  // id='products'
                  options={optionsData}
                  noOptionsMessage={() => 'No se encontró producto'}
                />
              </FormGroup>
              {formik.values.products ? (
                infoSupport[formik.values.products.cat] ? (
                  <>
                    {infoSupport[formik.values.products.cat].files.length >
                      0 && <h6 className='mt-3 mb-4'>Soportes</h6>}
                    {infoSupport[formik.values.products.cat].files.map(
                      (form) => (
                        <Col lg={12} key={form.name} className='my-2'>
                          <Label>
                            {form.name}
                            {form.required && (
                              <span style={{ color: 'red' }}>*</span>
                            )}
                          </Label>
                          <Input
                            name={`${form.name}-support`}
                            required={form.required}
                            type='file'
                          />
                        </Col>
                      )
                    )}
                    {infoSupport[formik.values.products.cat].form.length >
                      0 && (
                      // eslint-disable-next-line react/jsx-indent
                      <h6 className='mt-3 mb-4'>Información específica</h6>
                    )}
                    {infoSupport[formik.values.products.cat].form.map(
                      (form) => (
                        <Col lg={12} key={form.name} className='my-2'>
                          <Label>
                            {form.name}
                            {form.required && (
                              <span style={{ color: 'red' }}>*</span>
                            )}
                          </Label>
                          <Input
                            name={`${form.name}-form`}
                            required={form.required}
                          />
                        </Col>
                      )
                    )}
                  </>
                ) : (
                  <Col lg={12}>
                    <Alert
                      color='danger'
                      className='d-flex align-items-center gap-2'
                    >
                      <i className='bx bx-info-circle fs-4' /> Este producto no
                      se puede agregar a facturación{' '}
                    </Alert>
                  </Col>
                )
              ) : null}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color='dark'
              type='button'
              outline
              onClick={() => setModalProduct(false)}
            >
              Cancelar
            </Button>
            <Button
              type='submit'
              disabled={
                formik.values.products == null ||
                infoSupport[formik.values.products.cat] == null
              }
            >
              Agregar producto
            </Button>
          </ModalFooter>
        </form>
      </Modal>

      {infoAddDiscount && (
        <ModalAddDiscount
          info={infoAddDiscount}
          isOpen={isOpen}
          onClose={onClose}
          update={updateProductDiscount}
        />
      )}

      {infoAddResidue && (
        <ModalAddResidue
          info={infoAddResidue}
          isOpen={isOpenResidue}
          onClose={onCloseResidue}
          update={updateProductResidue}
        />
      )}
    </div>
  )
}
