import { useCallback, useState } from 'react'
import { ERROR_MESSAGES } from '../helpers/consts'
import { API } from '../graphql/client'

/**
 * @returns {[(body: FormData)=>void, {data: {} | null, loading: boolean, error: {} | null}]}
 */
export const useMutationFetch = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const getFetch = useCallback((body) => {
    const tokenStr = window.localStorage.getItem('token')
    if (!tokenStr) return setError(ERROR_MESSAGES.SIGNATURE_EXPIRED)
    const token = JSON.parse(tokenStr)
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    myHeaders.append(
      'Cookie',
      'csrftoken=le5GvRfK7iZkorIAyYzckwQ7m3ux3RztbFZqjr3Rvv4KAcVBn52gQGazZPqaWm5u'
    )
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body,
      redirect: 'follow'
    }
    setLoading(true)
    fetch(`${API}/api`, requestOptions)
      .then((response) => {
        if (!response.ok) throw new Error('Algo ocurrió')
        return response.json()
      })
      .then((result) => {
        setLoading(false)
        setData(result.data)
        if (result?.data.errors?.length > 0) {
          throw new Error(result.data?.errors?.message || 'Algo ocurrio')
        }
        if (result?.errors?.length > 0) {
          throw new Error(result?.errors?.[0]?.message || 'Algo ocurrio')
        }
      })
      .catch((error) => {
        setLoading(false)
        setError(error)
      })
  }, [])

  return [getFetch, { data, loading, error }]
}
