import { gql } from '@apollo/client'

export const LOGIN = gql`
  mutation TokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      payload
      settings
      refreshToken
      refreshExpiresIn
      crm
      rol
      name
      permissions
      current {
        data {
          id
          name
        }
        entity
      }
    }
  }
`

export const REFRES_TOKEN = gql`
  mutation RefreshToken($refreshToken: String) {
    refreshToken(refreshToken: $refreshToken) {
      payload
      refreshExpiresIn
      token
      refreshToken
    }
  }
`

export const VERIFY_TOKEN = gql`
  mutation ($token: String) {
    verifyToken(token: $token) {
      payload
    }
  }
`
