import React from 'react'
import moment from 'moment'
import 'moment/locale/es'

// import Scss
import './assets/scss/themes.scss'

// imoprt Route
import Route from './Routes'

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// Fake Backend
import fakeBackend from './helpers/AuthType/fakeBackend'
import UserProvider from './contexts/user'

import 'react-toastify/dist/ReactToastify.css'

moment.locale('es')
// Activating fake backend
fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// // init firebase backend
// initFirebaseBackend(firebaseConfig);

function App() {
  return (
    <UserProvider>
      <Route />
    </UserProvider>
  )
}

export default App
