import { useLazyQuery } from '@apollo/client'
import React, { useEffect } from 'react'
import { GET_REPORTS_CREDITS } from '../../../graphql/queries/getCredits'
import { toast } from 'react-toastify'
import { Card, CardBody, Spinner } from 'reactstrap'
import CountUp from 'react-countup'
import { ICONS_STATUS } from '../../../helpers/consts'
import SimpleBar from 'simplebar-react'
import { parseId } from '../../../helpers'

const ARR_LOADING = [
  { status: 'APROBADO' },
  { status: 'DESEMBOLSADO' },
  { status: 'DESISTIDO' },
  { status: 'FINALIZADO' },
  { status: 'INCOMPLETO' }
]

export const InfoGeneralCredits = ({
  datesValue,
  statusSelect,
  agencySelect,
  analystSelect,
  typeSelect,
  preAprovedSelect
}) => {
  const [gqlGetReports, { data, loading, error }] = useLazyQuery(
    GET_REPORTS_CREDITS,
    {
      fetchPolicy: 'no-cache'
    }
  )

  useEffect(() => {
    const variables = {}
    if (datesValue?.createdGte && datesValue?.createdLte) {
      variables.createdGte = datesValue.createdGte
      variables.createdLte = datesValue.createdLte
    }
    if (analystSelect) {
      variables.analystId = parseId(analystSelect)
    }
    if (statusSelect) {
      variables.status = statusSelect?.replace(' ', '_')
    }
    if (agencySelect) {
      variables.agencyId = agencySelect.split('***')[0]
    }
    if (typeSelect) {
      variables.creditType = typeSelect
    }
    if (preAprovedSelect) {
      variables.preapproved = preAprovedSelect === 'SI'
    }
    // console.log('variables', variables)
    gqlGetReports({
      variables
    })
  }, [
    datesValue,
    statusSelect,
    agencySelect,
    analystSelect,
    typeSelect,
    preAprovedSelect
  ])

  useEffect(() => {
    if (error) toast.error(`Error: ${error.message}`)
  }, [error])

  if (loading || !data) {
    return (
      <div style={{ maxWidth: '100%', overflowX: 'hidden' }}>
        <div className='d-flex gap-3'>
          {ARR_LOADING.map((obj) => (
            <Card
              style={{ flex: 1, minWidth: '160px' }}
              key={obj.status}
              className='card-animate'
            >
              <CardBody>
                <div className='d-flex justify-content-between'>
                  <div>
                    <p className='fw-medium text-muted mb-0'>{obj.status}</p>
                    <h2 className='mt-4 ff-secondary fw-semibold'>
                      <span className='counter-value d-flex align-items-center gap-3'>
                        <Spinner size='sm' />

                        <div className='avatar-sm '>
                          <span
                            className={`avatar-title bg-soft-${
                              ICONS_STATUS[obj.status]?.[1] || 'dark'
                            } rounded-circle fs-2`}
                          >
                            <i
                              className={`text-${
                                ICONS_STATUS[obj.status]?.[1] || 'dark'
                              } mdi ${
                                ICONS_STATUS[obj.status]?.[0] ||
                                'mdi-cube-outline'
                              }`}
                            />
                          </span>
                        </div>
                      </span>
                    </h2>
                  </div>
                </div>
              </CardBody>
            </Card>
          ))}
        </div>
      </div>
    )
  }

  return (
    <SimpleBar
      style={{ maxWidth: '100%', margin: '0 0 10px 0' }}
      // autoHide={false}
    >
      <div className='d-flex gap-3 mb-3'>
        {data.statusCreditCount.map((obj) => (
          <Card
            style={{ flex: 1, minWidth: '160px' }}
            key={obj.status}
            className='card-animate mb-0'
          >
            <CardBody>
              <div className='d-flex justify-content-between'>
                <div>
                  <p className='fw-medium text-muted mb-0'>{obj.status}</p>
                  <h2 className='mt-4 ff-secondary fw-semibold'>
                    <span className='counter-value d-flex align-items-center gap-3'>
                      <CountUp
                        start={0}
                        end={obj.count}
                        decimals={0}
                        duration={1.8}
                      />

                      <div className='avatar-sm '>
                        <span
                          className={`avatar-title bg-soft-${
                            ICONS_STATUS[obj.status]?.[1] || 'dark'
                          } rounded-circle fs-2`}
                        >
                          <i
                            className={`text-${
                              ICONS_STATUS[obj.status]?.[1] || 'dark'
                            } mdi ${
                              ICONS_STATUS[obj.status]?.[0] ||
                              'mdi-cube-outline'
                            }`}
                          />
                        </span>
                      </div>
                    </span>
                  </h2>
                </div>
              </div>
            </CardBody>
          </Card>
        ))}
      </div>
    </SimpleBar>
  )
}
