import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Spinner,
  TabPane
} from 'reactstrap'
import { CREATE_PAYMENT_COUTA } from '../../../../graphql/mutations/creditWalletMutations'
import { useMutation } from '@apollo/client'
import { QOUTA_STATUS } from '../../../../helpers/consts'
import { parseId, parsePrice } from '../../../../helpers'
import { useDisclosure } from '../../../../hooks/useDisclosure'
import moment from 'moment'

export const SubTabPayCoutas = ({
  refetch,
  backToResume,
  pagos,
  coutaTotal,
  creditId,
  obligationId,
  tabId
}) => {
  const [
    gqlCreatePyment,
    { data: dataCreate, loading: loadingCreate, error: errorCreate }
  ] = useMutation(CREATE_PAYMENT_COUTA)

  const { isOpen, onClose, onOpen } = useDisclosure()

  const [coutasToPay, setCoutasToPay] = useState([])
  const [errorPays, setErrorPays] = useState(false)

  useEffect(() => {
    setCoutasToPay([])
    setErrorPays(false)
  }, [tabId])

  useEffect(() => {
    if (!errorCreate) return
    toast.error('Error: ' + errorCreate.message)
  }, [errorCreate])
  useEffect(() => {
    if (!dataCreate) return
    if (dataCreate.paymentObligation.success) {
      toast.success('Pago creado correctamente')
      refetch()
      backToResume()
      onClose()
      setCoutasToPay([])
    } else if (dataCreate.paymentObligation.errors?.message) {
      toast.error('Error: ' + dataCreate.paymentObligation.errors.message)
    }
  }, [dataCreate])
  const getTotalToPay = useCallback(() => {
    let tot = 0
    coutasToPay.forEach(({ value }) => {
      tot += Number(value)
    })
    return tot
  }, [coutasToPay])

  const addCoutaToPay = (couta) => {
    setCoutasToPay((pre) => [...pre, couta])
  }

  const deleteCoutaToPay = (idDelete) => {
    setCoutasToPay((pre) => {
      const newArr = [...pre]
      return newArr.filter(({ id }) => id !== idDelete)
    })
  }

  const canPay = (st) =>
    QOUTA_STATUS[st] !== QOUTA_STATUS.PAGADA &&
    QOUTA_STATUS[st] !== QOUTA_STATUS.PENDIENTE

  const handleClickPayModal = () => {
    if (coutasToPay.length === 0) return
    const quotaId = coutasToPay
      .map(({ id }) => parseId(id))
      .sort((a, b) => a - b)
    const lastIdx = pagos.find(({ node }) => canPay(node.status))
    if (!lastIdx) return
    const idLast = parseId(lastIdx.node.id)
    let isValid = true
    quotaId.forEach((num, i) => {
      if (i === 0) {
        if (num !== idLast) isValid = false
      } else {
        if (quotaId[i] - quotaId[i - 1] !== 1) {
          isValid = false
        }
      }
    })
    if (!isValid) {
      return setErrorPays(true)
    }
    setErrorPays(false)
    onOpen()
    /* gqlCreatePyment({
      variables: {
        input: {
          quotaId,
          value
        }
      }
    }) */
  }
  const createPayCouta = () => {
    const value = getTotalToPay()
    const quotaId = coutasToPay
      .map(({ id }) => parseId(id))
      .sort((a, b) => a - b)

    gqlCreatePyment({
      variables: {
        input: {
          quotaId,
          value,
          creditId: parseId(creditId),
          obligationId: parseId(obligationId)
        }
      }
    })
  }
  return (
    <>
      <TabPane tabId='1' className='p-4'>
        <Row>
          <Col lg={8}>
            <Card className='shadow-lg'>
              <CardHeader className='border-bottom-dashed'>
                <h5 className='card-title mb-0'>Pago de cuotas</h5>
              </CardHeader>
              <CardBody>
                <div className='table-responsive'>
                  <table className='table table-borderless mb-0'>
                    <thead>
                      <tr>
                        <th className='text-center'>
                          <FormGroup check inline>
                            <Input
                              type='checkbox'
                              onChange={(e) => {
                                const { checked } = e.target
                                if (checked) {
                                  setCoutasToPay(
                                    pagos
                                      .map(({ node }, i) => ({
                                        node: {
                                          status: node.status,
                                          id: node.id,
                                          totalQuota: node.totalQuota,
                                          endDate: node.endDate,
                                          index: i + 1
                                        }
                                      }))
                                      .filter(({ node }) => canPay(node.status))
                                      .map(({ node }) => ({
                                        id: node.id,
                                        value: node.totalQuota,
                                        index: node.index
                                      }))
                                  )
                                } else {
                                  setCoutasToPay([])
                                }
                              }}
                              checked={
                                coutasToPay.length ===
                                  pagos.filter(({ node }) =>
                                    canPay(node.status)
                                  ).length && coutasToPay.length > 0
                              }
                            />
                          </FormGroup>
                        </th>
                        <th className='text-center'>Cuota</th>
                        <th className='text-center'>Fecha de vencimiento</th>
                        <th className='text-center'>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pagos.map(({ node }, idx) => (
                        <tr
                          key={node.id}
                          style={{
                            backgroundColor: !canPay(node.status)
                              ? '#f4f4f4'
                              : undefined,
                            color: !canPay(node.status) ? '#c4c4c4' : undefined
                          }}
                        >
                          <td className='text-center'>
                            <FormGroup check inline>
                              <Input
                                disabled={!canPay(node.status)}
                                checked={Boolean(
                                  coutasToPay.find(({ id }) => id === node.id)
                                )}
                                type='checkbox'
                                onChange={(e) => {
                                  const { checked } = e.target
                                  if (checked) {
                                    addCoutaToPay({
                                      id: node.id,
                                      value: node.totalQuota,
                                      index: idx + 1
                                    })
                                  } else {
                                    deleteCoutaToPay(node.id)
                                  }
                                }}
                              />
                            </FormGroup>
                          </td>
                          <td className='text-center'>{idx + 1}</td>
                          <td className='text-center'>
                            {moment(node.endDate).format('DD MMM [de] YYYY')}
                          </td>
                          <td className='text-center'>
                            {parsePrice(node.totalQuota)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <div className='sticky-side-div'>
              <Card className='shadow-lg'>
                <CardHeader className='border-bottom-dashed'>
                  <h5 className='card-title mb-0'>Resumen</h5>
                </CardHeader>
                <CardBody>
                  <div className='table-responsive'>
                    <table className='table table-borderless mb-0'>
                      <tbody>
                        <tr>
                          <td>Cuotas:</td>
                          <td className='text-end' id='cart-subtotal'>
                            {coutasToPay.length}
                          </td>
                        </tr>
                        <tr className='table-active'>
                          <th>Total:</th>
                          <td className='text-end'>
                            <span className='fw-semibold' id='cart-total'>
                              {parsePrice(getTotalToPay())}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
              <div className='w-100 d-flex justify-content-end my-4'>
                <Button
                  disabled={loadingCreate || coutasToPay.length === 0}
                  className='d-flex align-items-center gap-1'
                  onClick={handleClickPayModal}
                >
                  Pagar
                </Button>
              </div>
              <div>
                <Alert
                  color='danger'
                  isOpen={errorPays}
                  toggle={() => setErrorPays(false)}
                  className='d-flex align-items-center gap-2'
                >
                  <span className='fs-4'>
                    <i className='mdi mdi-alert-outline' />
                  </span>
                  <span> Debe verificar el orden de los pagos</span>
                </Alert>
              </div>
            </div>
          </Col>
        </Row>
      </TabPane>
      <Modal isOpen={isOpen} toggle={onClose} centered>
        <ModalBody>
          <p className='fs-2'>
            Esta acción realizará un pago total de{' '}
            <span className='text-danger fw-bold'>
              {parsePrice(getTotalToPay())}
            </span>{' '}
            por <b> {coutasToPay.length}</b> cuota
            {coutasToPay.length > 1 ? 's' : ''}
          </p>
          <div>
            <h6 className='fs-4'>Las cuotas a pagar son:</h6>
            {coutasToPay.map(({ id, index }) => (
              <div key={id} className='fs-5'>
                Cuota # {index}
              </div>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='light' onClick={onClose}>
            Cancelar
          </Button>
          <Button
            color='primary'
            onClick={createPayCouta}
            disabled={loadingCreate}
            className='d-flex align-items-center gap-1'
          >
            {loadingCreate && <Spinner color='light' size='sm' />}
            Pagar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
