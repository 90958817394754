import moment from 'moment'
import React from 'react'
import {
  Button,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap'
import { BadgeStatusCredit } from './BadgeStatusCredit'

/**
 *
 * @param {{isOpen: boolean, onClose: ()=>void, info: {id: string, created: string, status: string}[]}} param0
 * @returns {JSX.Element}
 */
export const ModalInfoPreCredit = ({ isOpen, onClose, info }) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader className='bg-soft-info p-4' toggle={onClose}>
        Información de créditos
      </ModalHeader>

      <ModalBody>
        <p>El número de documento aparece como titular o codeudor de:</p>
        <ListGroup>
          {info.map((credit, i) => (
            <ListGroupItem key={credit.id}>
              <div className='d-flex align-items-center gap-2'>
                <div
                  className='d-flex align-items-center justify-content-center'
                  style={{
                    borderRadius: '100%',
                    backgroundColor: '#c4c4c4',
                    color: 'white',
                    padding: '2px 8px',
                    fontWeight: 'bolder'
                  }}
                >
                  {i + 1}
                </div>
                <div>
                  <div>
                    <span style={{ fontWeight: 'bold' }}> Creado:</span>{' '}
                    {moment(credit.created).format('DD MMMM [de] YYYY')}
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>Estado: </span>
                    <BadgeStatusCredit status={credit.status} />
                  </div>
                </div>
              </div>
            </ListGroupItem>
          ))}
        </ListGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          className='d-flex align-items-center gap-1'
          onClick={() => {
            onClose()
          }}
        >
          Aceptar
        </Button>
      </ModalFooter>
    </Modal>
  )
}
