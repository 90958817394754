import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from 'reactstrap'
import { CREATE_COMMENT_CREDIT_STR } from '../../../graphql/mutations/creditMutations'
import { parseId } from '../../../helpers'
import { toast } from 'react-toastify'
import { useMutationFetch } from '../../../hooks/useMutationFetch'
import {
  MAX_SIZE,
  MAX_SIZE_ALL_FILES,
  TableCommentCredit
} from '../TableCommentCredit'

export const ModalAddComment = ({
  showComment,
  toggleShowComment,
  creditId,
  refetchDataCredit
}) => {
  const [
    createComment,
    {
      data: dataCreateComment,
      loading: loadingCreateComment,
      error: errorCreateComment
    }
  ] = useMutationFetch()

  /** @type {[File[], React.Dispatch<React.SetStateAction<File[]>>]} */
  const [filesComment, setFilesComment] = useState([])

  useEffect(() => {
    if (!dataCreateComment) return
    if (dataCreateComment?.creditComment?.success) {
      toast.success('Comentario creado con éxito')
      refetchDataCredit()
      setFilesComment([])
      const form = document.getElementById('form-comment-add-credit')
      form?.reset()
      toggleShowComment()
    } else {
      toast.error('No se pudo crear comentario')
    }
  }, [dataCreateComment])

  useEffect(() => {
    if (!errorCreateComment) return
    if (errorCreateComment) {
      toast.error(
        `ERROR: No se pudo crear comentario ${errorCreateComment.toString()}`
      )
    }
  }, [errorCreateComment])

  const deleteFileComment = (nameFile) => {
    setFilesComment((p) => {
      return p.filter(({ name }) => name !== nameFile)
    })
  }

  const isOneBigger = () => {
    const find = filesComment.find(({ size }) => size > MAX_SIZE)
    if (find) {
      return true
    }
    return false
  }

  let totalFiles = 0

  filesComment.forEach((f) => {
    totalFiles += f.size
  })

  const isBiggerAll = totalFiles > MAX_SIZE_ALL_FILES

  return (
    <Modal isOpen={showComment} toggle={toggleShowComment}>
      <ModalHeader toggle={toggleShowComment}>Agregar comentario</ModalHeader>
      <Form
        id='form-comment-add-credit'
        onSubmit={(e) => {
          e.preventDefault()
          const { comment } = Object.fromEntries(new FormData(e.target))
          /* console.log('comment', comment)
              console.log('filesComment', filesComment) */
          const formdata = new FormData()
          const map = {}
          filesComment.forEach((file, idx) => {
            formdata.append(`${idx}`, file, file.name)
            map[`${idx}`] = [`variables.input.files.${idx}`]
          })
          const obj = {
            query: CREATE_COMMENT_CREDIT_STR,
            variables: {
              input: {
                files: filesComment.map(() => null),
                creditId: parseId(creditId),
                comment
              }
            }
          }
          formdata.append('map', JSON.stringify(map))
          formdata.append('operations', JSON.stringify(obj))
          /* if (comment) {
                for (const pair of formdata.entries()) {
                  console.log(pair[0] + ', ' + pair[1])
                }
                return
              } */
          createComment(formdata)
        }}
      >
        <ModalBody>
          <FormGroup>
            <Label htmlFor='textarea45'>Comentario</Label>
            <Input type='textarea' name='comment' id='textarea45' required />
          </FormGroup>
          <FormGroup>
            <Label>Archivo(s)</Label>
            <Input
              type='file'
              onChange={(e) => {
                const { target } = e
                const fileAdd = target.files[0]
                setFilesComment((p) => {
                  const files = [...p]
                  const find = files.find((file) => file.name === fileAdd.name)
                  if (find) {
                    return files
                  }
                  files.unshift(fileAdd)
                  return files
                })
                target.value = null
              }}
            />
          </FormGroup>
          {filesComment.length > 0 ? (
            <TableCommentCredit
              files={filesComment}
              setDeleteFile={deleteFileComment}
            />
          ) : (
            <Alert>No hay soportes adjuntados</Alert>
          )}

          {isBiggerAll && (
            <Alert color='warning'>
              No puedes subir mas de <span className='fw-bold'>20MB</span> de
              archivos
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color='light'
            type='button'
            onClick={() => {
              toggleShowComment()
              setFilesComment([])
            }}
          >
            Cancelar
          </Button>
          <Button
            type='submit'
            disabled={loadingCreateComment || isOneBigger() || isBiggerAll}
            className='d-flex align-items-center gap-1'
          >
            {loadingCreateComment && <Spinner color='light' size='sm' />}
            Comentar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
