import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import {
  FormGroup,
  Label,
  Input,
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback
} from 'reactstrap'
import * as Yup from 'yup'
import { useMutation } from '@apollo/client'
import { CREATE_PAZ_Y_SALVO } from '../../../graphql/mutations/certificationMutations'
import { toast } from 'react-toastify'

const validationSchema = Yup.object().shape({
  names: Yup.string().required('Campo requerido'),
  surnames: Yup.string().required('Campo requerido'),
  documentNumber: Yup.string().required('Campo requerido'),
  brand: Yup.string().required('Campo requerido'),
  line: Yup.string().required('Campo requerido'),
  model: Yup.string().required('Campo requerido'),
  color: Yup.string().required('Campo requerido'),
  engineNumber: Yup.string().required('Campo requerido'),
  chassisNumber: Yup.string().required('Campo requerido'),
  plaque: Yup.string().required('Campo requerido')
})

const initialValues = {
  names: '',
  surnames: '',
  documentNumber: '',
  brand: '',
  line: '',
  model: '',
  color: '',
  engineNumber: '',
  chassisNumber: '',
  plaque: ''
}

export const ModalAddCertificates = ({ isOpen, toggle, refetch }) => {
  const [
    gqlCreateCertificate,
    { data: dataCreate, loading: loadingCreate, error: errorCreate }
  ] = useMutation(CREATE_PAZ_Y_SALVO)
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const input = { ...values }
      gqlCreateCertificate({
        variables: {
          input
        }
      })
    }
  })

  useEffect(() => {
    if (!errorCreate) return
    toast.error('Error: ' + errorCreate.message)
  }, [errorCreate])
  useEffect(() => {
    if (!dataCreate) return
    if (dataCreate.complianceCertificateCreate.success) {
      toast.success('Solicitud completada correctamente')
      formik.resetForm()
      toggle()
      refetch()
    } else if (dataCreate.complianceCertificateCreate.errors) {
      toast.error(
        'Error: ' + dataCreate.complianceCertificateCreate.errors.message ||
          'No se pudo procesar solicitud'
      )
    }
  }, [dataCreate])

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} className='p-4 bg-soft-info'>
        Solicitar paz y salvo
      </ModalHeader>
      <Form onSubmit={formik.handleSubmit}>
        <ModalBody>
          <FormGroup>
            <Label>Nombres</Label>
            <Input
              placeholder='Ingrese nombres'
              invalid={Boolean(formik.errors.names && formik.touched.names)}
              onBlur={formik.handleBlur}
              type='text'
              name='names'
              value={formik.values.names}
              onChange={formik.handleChange}
            />
            {formik.errors.names && formik.touched.names && (
              <FormFeedback>{formik.errors.names}</FormFeedback>
            )}
          </FormGroup>

          <FormGroup>
            <Label>Apellidos</Label>
            <Input
              placeholder='Ingrese apellidos'
              invalid={Boolean(
                formik.errors.surnames && formik.touched.surnames
              )}
              onBlur={formik.handleBlur}
              type='text'
              name='surnames'
              value={formik.values.surnames}
              onChange={formik.handleChange}
            />
            {formik.errors.surnames && formik.touched.surnames && (
              <FormFeedback>{formik.errors.surnames}</FormFeedback>
            )}
          </FormGroup>

          <FormGroup>
            <Label>Número de documento</Label>
            <Input
              placeholder='Ingrese Numero de documento'
              invalid={Boolean(
                formik.errors.documentNumber && formik.touched.documentNumber
              )}
              onBlur={formik.handleBlur}
              type='text'
              name='documentNumber'
              value={formik.values.documentNumber}
              onChange={formik.handleChange}
            />
            {formik.errors.documentNumber && formik.touched.documentNumber && (
              <FormFeedback>{formik.errors.documentNumber}</FormFeedback>
            )}
          </FormGroup>

          <FormGroup>
            <Label>Marca</Label>
            <Input
              placeholder='Ingrese marca'
              invalid={Boolean(formik.errors.brand && formik.touched.brand)}
              onBlur={formik.handleBlur}
              type='text'
              name='brand'
              value={formik.values.brand}
              onChange={formik.handleChange}
            />
            {formik.errors.brand && formik.touched.brand && (
              <FormFeedback>{formik.errors.brand}</FormFeedback>
            )}
          </FormGroup>

          <FormGroup>
            <Label>Línea</Label>
            <Input
              placeholder='Ingrese linea'
              invalid={Boolean(formik.errors.line && formik.touched.line)}
              onBlur={formik.handleBlur}
              type='text'
              name='line'
              value={formik.values.line}
              onChange={formik.handleChange}
            />
            {formik.errors.line && formik.touched.line && (
              <FormFeedback>{formik.errors.line}</FormFeedback>
            )}
          </FormGroup>

          <FormGroup>
            <Label>Modelo</Label>
            <Input
              placeholder='Ingrese modelo'
              invalid={Boolean(formik.errors.model && formik.touched.model)}
              onBlur={formik.handleBlur}
              type='text'
              name='model'
              value={formik.values.model}
              onChange={formik.handleChange}
            />
            {formik.errors.model && formik.touched.model && (
              <FormFeedback>{formik.errors.model}</FormFeedback>
            )}
          </FormGroup>

          <FormGroup>
            <Label>Color</Label>
            <Input
              placeholder='Ingrese color'
              invalid={Boolean(formik.errors.color && formik.touched.color)}
              onBlur={formik.handleBlur}
              type='text'
              name='color'
              value={formik.values.color}
              onChange={formik.handleChange}
            />
            {formik.errors.color && formik.touched.color && (
              <FormFeedback>{formik.errors.color}</FormFeedback>
            )}
          </FormGroup>

          <FormGroup>
            <Label>Número de motor</Label>
            <Input
              placeholder='Ingrese número de motor'
              invalid={Boolean(
                formik.errors.engineNumber && formik.touched.engineNumber
              )}
              onBlur={formik.handleBlur}
              type='text'
              name='engineNumber'
              value={formik.values.engineNumber}
              onChange={formik.handleChange}
            />
            {formik.errors.engineNumber && formik.touched.engineNumber && (
              <FormFeedback>{formik.errors.engineNumber}</FormFeedback>
            )}
          </FormGroup>

          <FormGroup>
            <Label>Número de chasis</Label>
            <Input
              placeholder='Ingrese chasis'
              invalid={Boolean(
                formik.errors.chassisNumber && formik.touched.chassisNumber
              )}
              onBlur={formik.handleBlur}
              type='text'
              name='chassisNumber'
              value={formik.values.chassisNumber}
              onChange={formik.handleChange}
            />
            {formik.errors.chassisNumber && formik.touched.chassisNumber && (
              <FormFeedback>{formik.errors.chassisNumber}</FormFeedback>
            )}
          </FormGroup>

          <FormGroup>
            <Label>Placa</Label>
            <Input
              placeholder='Ingrese placa'
              invalid={Boolean(formik.errors.plaque && formik.touched.plaque)}
              onBlur={formik.handleBlur}
              type='text'
              name='plaque'
              value={formik.values.plaque}
              onChange={formik.handleChange}
            />
            {formik.errors.plaque && formik.touched.plaque && (
              <FormFeedback>{formik.errors.plaque}</FormFeedback>
            )}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color='light'
            type='button'
            onClick={() => {
              formik.resetForm()
              toggle()
            }}
          >
            Cancelar
          </Button>
          <Button type='submit' disabled={loadingCreate}>
            Solicitar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
