import { useContext } from 'react'
import { userContext } from '../contexts/user'

export const useGetUser = () => {
  /**
   * @typedef {{message: string, url: string, title: string, isReaded: boolean, id: number, createDate: string}} INotification
   * @typedef {INotification[]} INotifications
   */

  /**
   * @typedef {Object} IData
   * @property {{token: string, name: string, settings: string,payload: {email: string, exp: number, origIat: string},refreshToken: string, refreshExpiresIn: number, rol: string, crm: boolean,permissions: string[] } tokenAuth}
   */

  /** @type {{user: null | IData, checking: "loading" | "success" | "no-user", rol: string | null, permissions: string[], infoZoneOrAgencie: {showModal: boolean, zoneOrAgency: string | null}, notifications: INotifications, totalNotifications: number,logout: ()=>void, login: (data: {})=>void, changeRolAndPermissions: (rol: string, permissions: string[]) => void, openModalChangeZoneOrAgencie: (isReset?: boolean)=>void, closeModalChangeZoneOrAgencie: (isForce?: boolean)=>void, updateOptionAgencieOrZone: (info: string | null)=>void, updateNotifications: (notification: INotification) => void, loadAllNotifications: (allNotifications: INotifications)=>void, updateOneNotificationReaded: (id: number)=>void, incDecTotalNotifications: (str: 'plus' | 'subtract') => void, setTotalNotifications: (num: number) => void, updateMonthlyFeeSetting: (monthlyFee: string)=>void}} */

  const user = useContext(userContext)
  return { ...user }
}
