import { gql } from '@apollo/client'

export const GET_BUDGET_REPORT = gql`
  query budgetReport($initialDate: Date!, $endDate: Date!) {
    budgetReport(initialDate: $initialDate, endDate: $endDate) {
      budget
      totalCharged
    }
  }
`
