import { useFormik } from 'formik'
import React from 'react'
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  Label,
  ModalFooter,
  Button
} from 'reactstrap'
import * as Yup from 'yup'
import { InputCurrency } from '../InputCurrency'

/**
 *
 * @param {{isOpen: boolean, onClose: ()=>void, update: ({name: string, value: string, id: string})=>void,info: { productId: string, productName: string}}} param0
 * @returns
 */

export const ModalAddResidue = ({ isOpen, onClose, info, update }) => {
  const formik = useFormik({
    initialValues: {
      value: ''
    },
    validationSchema: Yup.object({
      value: Yup.string().required('Precio requerido')
    }),
    onSubmit: (values) => {
      update({
        value: values.value,
        id: info.productId
      })
      formik.resetForm()
      onClose()
    }
  })

  return (
    <Modal id='showModal' isOpen={isOpen} toggle={onClose} centered size='lg'>
      <ModalHeader className='bg-soft-info p-3' toggle={onClose}>
        Agregar saldo contraentrega a "{info.productName?.toLocaleUpperCase()}"
      </ModalHeader>
      <Form className='tablelist-form' onSubmit={formik.handleSubmit}>
        <ModalBody>
          <Row className='g-3'>
            <Col lg={12}>
              <div>
                <Label htmlFor='value' className='form-label'>
                  Precio
                </Label>
                <InputCurrency
                  id='value'
                  name='value'
                  placeholder='Saldo contraentrega'
                  className={`form-control ${
                    formik.touched.value && formik.errors.value
                      ? 'is-invalid'
                      : ''
                  } `}
                  prefix='$ '
                  value={formik.values.value}
                  // decimalsLimit={0}
                  // decimalSeparator=','
                  // groupSeparator='.'
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value || '')
                  }}
                  // allowNegativeValue={false}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.value && formik.errors.value && (
                  <div
                    style={{
                      width: '100%',
                      marginTop: '0.25rem',
                      fontSize: '0.875em',
                      color: '#f06548'
                    }}
                  >
                    {formik.errors.value}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div className='hstack gap-2 justify-content-end'>
            <Button
              color='light'
              onClick={() => {
                onClose()
                formik.resetForm()
              }}
            >
              Cancelar{' '}
            </Button>
            <Button
              type='submit'
              color='success'
              id='add-btn'
              className='d-flex align-items-center gap-1'
            >
              Agregar
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
