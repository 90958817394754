/* eslint-disable react/jsx-indent */
import { useFormik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap'
import * as Yup from 'yup'
import { useGetUser } from '../../../hooks/user'
// import { CREDIT_TYPES } from '../../../helpers/consts'
// import Select from 'react-select'
import { FormDinamyc } from './FormDinamyc'
import { InputCurrency } from '../InputCurrency'
import { getFormParse, parseId } from '../../../helpers'
import {
  GET_FORM_CREDIT,
  GET_FORM_DATA
} from '../../../graphql/queries/getCredits'
import { useLazyQuery, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { EDIT_CREDIT } from '../../../graphql/mutations/creditMutations'
import { CREDIT_TYPES } from '../../../helpers/consts'

export const ModalEditInfo = ({ isOpen, toggle, credit, refetchCredit }) => {
  const [
    gqlEditCredit,
    { data: dataEdit, loading: loadingEdit, error: errorEdit }
  ] = useMutation(EDIT_CREDIT)

  const [
    gqlGetFormEdit,
    { data: dataFormEdit, loading: loadingForm, error: errorForm }
  ] = useLazyQuery(GET_FORM_DATA, { fetchPolicy: 'no-cache' })
  const [
    gqlGetFormCreate,
    { data: dataFormCreate, loading: loadingFormCreate, error: errorFormCreate }
  ] = useLazyQuery(GET_FORM_CREDIT, { fetchPolicy: 'no-cache' })

  const { user } = useGetUser()
  const maxTerm = JSON.parse(user.tokenAuth.settings)?.max_quotation_fee || 24
  const minimumfractiondigits =
    JSON.parse(user.tokenAuth.settings)?.minimumfractiondigits || 0

  const [moreInfo, setMoreInfo] = useState(false)

  const [formDynamicValues, setFormDynamicValues] = useState(null)
  const [namtrikFormParse, setNamtrikFormParse] = useState(null)

  const formik = useFormik({
    initialValues: {
      documentNumber: credit.documentNumber ?? '',
      documentType: credit.documentType ?? '',
      expeditionDate: credit.expeditionDate ?? moment().format('YYYY-MM-DD'),
      firstName: credit.firstName ?? '',
      secondName: credit.secondName ?? '',
      firstSurname: credit.firstSurname ?? '',
      secondSurname: credit.secondSurname ?? '',
      dateBirth:
        credit.dateBirth ?? moment().subtract(18, 'years').format('YYYY-MM-DD'),
      email: credit.email ?? '',
      phoneNumber: credit.phoneNumber ?? '',
      creditType: credit.creditType ?? '',

      initialFee:
        Number(credit.initialFee).toFixed(minimumfractiondigits) ?? '',
      term: credit.term ?? 1,
      productValue:
        Number(credit.productValue).toFixed(minimumfractiondigits) ?? ''
    },
    validationSchema: Yup.object({
      documentNumber: Yup.string()
        .required('El número de documento es obligatorio')
        .matches(/^\d+$/, 'El número de documento debe contener solo números'),
      documentType: Yup.string()
        .oneOf(['CC', 'CE'], 'Tipo de documento inválido')
        .required('El tipo de documento es obligatorio'),
      expeditionDate: Yup.date()
        .max(new Date(), 'Fecha no válida')
        .when('dateBirth', (dateBirth, schema) => {
          if (!dateBirth) return schema
          const minDate = new Date(dateBirth)
          minDate.setFullYear(minDate.getFullYear() + 18)
          return schema.min(minDate, 'La fecha de expedición no es posible.')
        })
        .required('La fecha de expedición es obligatoria'),
      firstName: Yup.string().required('El primer nombre es obligatorio'),
      secondName: Yup.string(),
      firstSurname: Yup.string().required('El primer apellido es obligatorio'),
      secondSurname: Yup.string(),
      dateBirth: Yup.date()
        .max(
          new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
          'Debes ser mayor de edad'
        )
        .required('La fecha de nacimiento es obligatoria'),
      email: Yup.string()
        .email('El correo electrónico no es válido')
        .required('El correo electrónico es obligatorio'),
      phoneNumber: Yup.string().required(
        'El número de teléfono es obligatorio'
      ),
      creditType: Yup.string()
        .oneOf(['INMEDIATO', 'TRADICIONAL'], 'Tipo de crédito inválido')
        .required('El tipo de crédito es obligatorio'),
      productValue: Yup.string()
        .matches(/^[0-9.,]+$/, 'Debe ser un número válido')
        .test(
          'not-zero',
          'Debe ser mayor que 0',
          (value) => parseFloat(value) !== 0
        )
        .when('creditType', {
          is: 'TRADICIONAL',
          then: Yup.string().required('Campo obligatorio')
        }),
      initialFee: Yup.string()
        .matches(/^[0-9.,]+$/, 'Debe ser un número válido')
        .when('creditType', {
          is: 'TRADICIONAL',
          then: Yup.string().required('Campo obligatorio')
        }),
      term: Yup.number()
        .min(1, 'Debe ser mayor a cero')
        .max(maxTerm, `Las cuotas no pueden ser mayor a ${maxTerm}`)
        .when('creditType', {
          is: 'TRADICIONAL',
          then: Yup.number().required('Campo obligatorio')
        })
    }),
    onSubmit: (values) => {
      const input = {
        id: parseId(credit.id),
        ...values
      }
      if (formDynamicValues) {
        input.form = { forms: formDynamicValues }
      } else {
        delete input.form
      }

      console.log('input', input)
      gqlEditCredit({
        variables: {
          input
        }
      })
    }
  })

  useEffect(() => {
    if (!credit) return
    // if (credit.creditType === CREDIT_TYPES.TRADICIONAL) {
    gqlGetFormEdit({
      variables: {
        codename: 'Credit',
        id: parseId(credit.id)
      }
    })
    // }
  }, [credit])

  useEffect(() => {
    if (errorForm) {
      toast.error(`ERROR: ${errorForm.message}`)
    }
  }, [errorForm])
  useEffect(() => {
    if (errorFormCreate) {
      toast.error(`ERROR: ${errorFormCreate.message}`)
    }
  }, [errorFormCreate])

  useEffect(() => {
    if (dataEdit) {
      if (dataEdit.creditUpdate.success) {
        toast.success('Crédito editado con éxito')
        refetchCredit()
        toggle()
        setMoreInfo(false)
      } else {
        toast.error(`Error ${dataEdit.creditUpdate.errors.message}`)
      }
    }
  }, [dataEdit])

  useEffect(() => {
    if (errorEdit) {
      toast.error(`ERROR: ${errorEdit.message}`)
    }
  }, [errorEdit])

  useEffect(() => {
    if (!dataFormEdit) return
    if (dataFormEdit.getEntityResponse) {
      if (dataFormEdit.getEntityResponse.length > 0) {
        setNamtrikFormParse(
          dataFormEdit.getEntityResponse.map((objStr) => JSON.parse(objStr))
        )
      }
    }
  }, [dataFormEdit])

  useEffect(() => {
    if (!dataFormCreate) return
    if (dataFormCreate.getEntityForms) {
      if (dataFormCreate.getEntityForms.length > 0) {
        setNamtrikFormParse(
          dataFormCreate.getEntityForms.map((objStr) => JSON.parse(objStr))
        )
      }
    }
  }, [dataFormCreate])

  useEffect(() => {
    if (moreInfo) {
      gqlGetFormCreate({
        variables: {
          codename: 'Credit'
        }
      })
    }
  }, [moreInfo])
  return (
    <Modal
      isOpen={isOpen}
      size='lg'
      onClosed={() => {
        setMoreInfo(false)
        formik.resetForm()
      }}
    >
      <ModalHeader className='bg-soft-info p-4' toggle={toggle}>
        Editar información de crédito
      </ModalHeader>
      <Form
        autoComplete='off'
        onSubmit={(e) => {
          e.preventDefault()
          const values = Object.fromEntries(new FormData(e.target))
          const arrayFinal = getFormParse(values)
          if (arrayFinal.length > 0) {
            setFormDynamicValues(arrayFinal)
          }
          if (
            formik.errors.initialFee ||
            formik.errors.productValue ||
            formik.errors.term
          ) {
            formik.setFieldTouched('initialFee', true)
            formik.setFieldTouched('productValue', true)
            formik.setFieldTouched('term', true)
            return
          }
          formik.handleSubmit(e)
        }}
      >
        <ModalBody>
          <Row className='g-3'>
            <Col lg={6}>
              <div>
                <Label htmlFor='firstName-field' className='form-label'>
                  Nombre
                  <span style={{ color: 'red' }} title='Obligatorio'>
                    *
                  </span>
                </Label>
                <Input
                  type='text'
                  name='firstName'
                  id='firstName-field'
                  placeholder='Ingrese su primer nombre'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  invalid={Boolean(
                    formik.touched.firstName && formik.errors.firstName
                  )}
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <FormFeedback>{formik.errors.firstName}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <Label htmlFor='secondName-field' className='form-label'>
                  Segundo nombre
                </Label>
                <Input
                  type='text'
                  name='secondName'
                  id='secondName-field'
                  placeholder='Ingrese su segundo nombre'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.secondName}
                  invalid={Boolean(
                    formik.touched.secondName && formik.errors.secondName
                  )}
                />
                {formik.touched.secondName && formik.errors.secondName ? (
                  <FormFeedback>{formik.errors.secondName}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <Label htmlFor='firstSurname-field' className='form-label'>
                  Apellido
                  <span style={{ color: 'red' }} title='Obligatorio'>
                    *
                  </span>
                </Label>
                <Input
                  type='text'
                  name='firstSurname'
                  id='firstSurname-field'
                  placeholder='Ingrese su primer apellido'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstSurname}
                  invalid={Boolean(
                    formik.touched.firstSurname && formik.errors.firstSurname
                  )}
                />
                {formik.touched.firstSurname && formik.errors.firstSurname ? (
                  <FormFeedback>{formik.errors.firstSurname}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <Label htmlFor='secondSurname-field' className='form-label'>
                  Segundo apellido
                </Label>
                <Input
                  type='text'
                  name='secondSurname'
                  id='secondSurnname-field'
                  placeholder='Ingrese su segundo apellido'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.secondSurname}
                  invalid={Boolean(
                    formik.touched.secondSurname && formik.errors.secondSurname
                  )}
                />
                {formik.touched.secondSurname && formik.errors.secondSurname ? (
                  <FormFeedback>{formik.errors.secondSurname}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6}>
              <FormGroup>
                <Label htmlFor='documentType-field' className='form-label'>
                  Tipo de documento
                  <span style={{ color: 'red' }} title='Obligatorio'>
                    *
                  </span>
                </Label>
                <Input
                  type='select'
                  name='documentType'
                  id='documentType-field'
                  placeholder='Seleccione tipo de documento'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.documentType}
                  invalid={Boolean(
                    formik.touched.documentType && formik.errors.documentType
                  )}
                >
                  <option value=''>Seleccione tipo de documento</option>
                  <option value='CC'>Cédula de ciudadanía</option>
                  <option value='CE'>Cédula extranjera</option>
                </Input>
                {formik.touched.documentType && formik.errors.documentType && (
                  <div
                    style={{
                      width: '100%',
                      marginTop: '0.25rem',
                      fontSize: '0.875em',
                      color: '#f06548'
                    }}
                  >
                    {formik.errors.documentType}
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col lg={6}>
              <div>
                <Label htmlFor='documentNumber-field' className='form-label'>
                  Número de documento
                  <span style={{ color: 'red' }} title='Obligatorio'>
                    *
                  </span>
                </Label>
                <Input
                  type='text'
                  name='documentNumber'
                  id='documentNumber-field'
                  placeholder='Ingrese su número de documento'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.documentNumber}
                  invalid={Boolean(
                    formik.touched.documentNumber &&
                      formik.errors.documentNumber
                  )}
                />
                {formik.touched.documentNumber &&
                formik.errors.documentNumber ? (
                  <FormFeedback>{formik.errors.documentNumber}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <Label htmlFor='phoneNumber-field' className='form-label'>
                  Número de teléfono
                  <span style={{ color: 'red' }} title='Obligatorio'>
                    *
                  </span>
                </Label>
                <Input
                  type='tel'
                  name='phoneNumber'
                  id='phoneNumber-field'
                  placeholder='Ingrese su número de teléfono'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                  invalid={Boolean(
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  )}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                  <FormFeedback>{formik.errors.phoneNumber}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <Label htmlFor='email-field' className='form-label'>
                  Correo electrónico
                  <span style={{ color: 'red' }} title='Obligatorio'>
                    *
                  </span>
                </Label>
                <Input
                  type='email'
                  name='email'
                  id='email-field'
                  placeholder='Ingrese su correo electrónico'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  invalid={Boolean(formik.touched.email && formik.errors.email)}
                />
                {formik.touched.email && formik.errors.email ? (
                  <FormFeedback>{formik.errors.email}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <Label htmlFor='dateBirth-field' className='form-label'>
                  Fecha de nacimiento
                  <span style={{ color: 'red' }} title='Obligatorio'>
                    *
                  </span>
                </Label>
                <Input
                  type='date'
                  name='dateBirth'
                  id='dateBirth-field'
                  placeholder='Ingrese fecha de nacimiento'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.dateBirth}
                  invalid={Boolean(
                    formik.touched.dateBirth && formik.errors.dateBirth
                  )}
                />
                {formik.touched.dateBirth && formik.errors.dateBirth ? (
                  <FormFeedback>{formik.errors.dateBirth}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <Label htmlFor='expeditionDate-field' className='form-label'>
                  Fecha de expedición
                  <span style={{ color: 'red' }} title='Obligatorio'>
                    *
                  </span>
                </Label>
                <Input
                  type='date'
                  name='expeditionDate'
                  id='expeditionDate-field'
                  placeholder='Ingrese fecha de expedición'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.expeditionDate}
                  invalid={Boolean(
                    formik.touched.expeditionDate &&
                      formik.errors.expeditionDate
                  )}
                />
                {formik.touched.expeditionDate &&
                formik.errors.expeditionDate ? (
                  <FormFeedback>{formik.errors.expeditionDate}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <Label htmlFor='creditType-field' className='form-label'>
                  Tipo de crédito
                  <span style={{ color: 'red' }} title='Obligatorio'>
                    *
                  </span>
                </Label>
                <Input
                  type='select'
                  name='creditType'
                  id='creditType-field'
                  placeholder='Seleccione tipo de documento'
                  disabled
                  // onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  defaultValue={formik.values.creditType}
                  invalid={Boolean(
                    formik.touched.creditType && formik.errors.creditType
                  )}
                  style={{ cursor: 'not-allowed' }}
                >
                  <option value=''>Seleccione tipo de documento</option>
                  <option value='INMEDIATO'>Inmediato</option>
                  <option value='TRADICIONAL'>Tradicional</option>
                </Input>
                {formik.touched.creditType && formik.errors.creditType ? (
                  <div
                    style={{
                      width: '100%',
                      marginTop: '0.25rem',
                      fontSize: '0.875em',
                      color: '#f06548'
                    }}
                  >
                    {formik.errors.creditType}
                  </div>
                ) : null}
              </div>
            </Col>

            <Col lg={6}>
              <div>
                <Label htmlFor='productValue-field' className='form-label'>
                  Valor del producto
                </Label>
                <InputCurrency
                  id='productValue-field'
                  name='productValue'
                  placeholder='Ingrese valor del producto'
                  value={formik.values.productValue}
                  className={`form-control ${
                    formik.touched.productValue && formik.errors.productValue
                      ? 'is-invalid'
                      : ''
                  } `}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value)
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.productValue && formik.errors.productValue && (
                  <div
                    style={{
                      width: '100%',
                      marginTop: '0.25rem',
                      fontSize: '0.875em',
                      color: '#f06548'
                    }}
                  >
                    {formik.errors.productValue}
                  </div>
                )}
              </div>
            </Col>
            {/* {credit.creditType !== CREDIT_TYPES.INMEDIATO && ( */}
            <Col lg={6}>
              <div>
                <Label htmlFor='initialFee-field' className='form-label'>
                  Cuota inicial
                </Label>
                <InputCurrency
                  id='initialFee-field'
                  name='initialFee'
                  placeholder='Ingrese cuota incial'
                  className={`form-control ${
                    formik.touched.initialFee && formik.errors.initialFee
                      ? 'is-invalid'
                      : ''
                  } `}
                  value={formik.values.initialFee}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value)
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.initialFee && formik.errors.initialFee && (
                  <div
                    style={{
                      width: '100%',
                      marginTop: '0.25rem',
                      fontSize: '0.875em',
                      color: '#f06548'
                    }}
                  >
                    {formik.errors.initialFee}
                  </div>
                )}
              </div>
            </Col>
            {/* )} */}
            <Col lg={6}>
              <div>
                <Label htmlFor='term-field' className='form-label'>
                  Plazo
                </Label>
                <Input
                  id='term-field'
                  name='term'
                  type='number'
                  placeholder='Ingrese el plazo'
                  value={formik.values.term}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`form-control ${
                    formik.touched.term && formik.errors.term
                      ? 'is-invalid'
                      : ''
                  } `}
                />
                {formik.touched.term && formik.errors.term && (
                  <div
                    style={{
                      width: '100%',
                      marginTop: '0.25rem',
                      fontSize: '0.875em',
                      color: '#f06548'
                    }}
                  >
                    {formik.errors.term}
                  </div>
                )}
              </div>
            </Col>
            {credit.creditType === CREDIT_TYPES.TRADICIONAL && (
              <>
                {loadingForm ||
                !dataFormEdit ||
                !dataFormEdit.getEntityResponse ||
                loadingFormCreate ? (
                  <Col lg={12} style={{ height: '50px' }}>
                    <Spinner color='dark' />
                  </Col>
                ) : dataFormEdit.getEntityResponse.length === 0 ? (
                  <>
                    <Col lg={12}>
                      <FormGroup switch>
                        <Label htmlFor='switch-field' className='form-label'>
                          ¿Completar más información?
                        </Label>
                        <Input
                          id='switch-field'
                          type='checkbox'
                          checked={moreInfo}
                          onChange={(e) => setMoreInfo(e.target.checked)}
                        />
                      </FormGroup>
                    </Col>
                    {moreInfo && namtrikFormParse && (
                      <Col lg={12} className='mt-4'>
                        <FormDinamyc namtrikForm={namtrikFormParse} />
                      </Col>
                    )}
                  </>
                ) : (
                  <Col lg={12} className='mt-4'>
                    <FormDinamyc namtrikForm={namtrikFormParse} />
                  </Col>
                )}
              </>
            )}
            {credit.creditType === CREDIT_TYPES.INMEDIATO && (
              <>
                <Col lg={12}>
                  <Alert color='warning'>
                    <h4 className='alert-heading text-warning'>¡Precaución!</h4>
                    <p>
                      Si ajusta los valores de cupo/producto y plazo, debe ser
                      dentro de los rangos aprobados por el sistema
                    </p>
                  </Alert>
                </Col>
                {loadingForm ||
                !dataFormEdit ||
                !dataFormEdit.getEntityResponse ||
                loadingFormCreate ? (
                  <Col lg={12} style={{ height: '50px' }}>
                    <Spinner color='dark' />
                  </Col>
                ) : dataFormEdit.getEntityResponse.length === 0 ? (
                  <>
                    <Col lg={12}>
                      <FormGroup switch>
                        <Label htmlFor='switch-field' className='form-label'>
                          ¿Completar más información?
                        </Label>
                        <Input
                          id='switch-field'
                          type='checkbox'
                          checked={moreInfo}
                          onChange={(e) => setMoreInfo(e.target.checked)}
                        />
                      </FormGroup>
                    </Col>
                    {moreInfo && namtrikFormParse && (
                      <Col lg={12} className='mt-4'>
                        <FormDinamyc namtrikForm={namtrikFormParse} />
                      </Col>
                    )}
                  </>
                ) : (
                  <Col lg={12} className='mt-4'>
                    <FormDinamyc namtrikForm={namtrikFormParse} />
                  </Col>
                )}
              </>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              formik.resetForm()
              toggle()
              setMoreInfo(false)
            }}
            type='button'
            color='light'
          >
            Cancelar
          </Button>
          <Button
            type='submit'
            disabled={loadingEdit}
            className='d-flex align-items-center gap-1'
          >
            {loadingEdit && <Spinner size='sm' color='light' />}
            Editar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
