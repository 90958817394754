import React, { useEffect } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'

// Layouts
import NonAuthLayout from '../Layouts/NonAuthLayout'
import VerticalLayout from '../Layouts/index'

// routes
import { authProtectedRoutes } from './allRoutes'
// import { AuthProtected } from './AuthProtected'
import { useGetUser } from '../hooks/user'
import ParticlesAuth from '../pages/AuthenticationInner/ParticlesAuth'
import maintenanceImg from '../assets/images/maintenance.png'
import { Alert, Col, Container, Row } from 'reactstrap'
import Login from '../pages/Authentication/Login'
import { ToastContainer, toast } from 'react-toastify'
import { getUrlWs } from '../graphql/client'
import { MsgToastNotification } from '../Components/Own/MsgToastNotification'
import { useNetworkStatus } from '../hooks/useNetworkStatus'
import { AllowedCrm } from './allowedCrm'
// import { socket } from '../socket'

const Index = () => {
  const { checking, user, updateNotifications, incDecTotalNotifications } =
    useGetUser()
  const isOnline = useNetworkStatus()
  const location = useLocation()

  useEffect(() => {
    if (!user || !updateNotifications) return
    const socket = new window.WebSocket(getUrlWs(user.tokenAuth.token))
    socket.onopen = (_evt) => {
      console.log('Conexión OK!')
    }
    socket.onmessage = (evt) => {
      let parseData = {}
      try {
        const obj = JSON.parse(evt.data)
        parseData = { ...obj }
      } catch (_e) {
        parseData = {}
      }
      console.log('parseData', parseData)
      if (parseData && parseData.type && parseData.title) {
        toast(
          <MsgToastNotification
            title={parseData.title}
            msg={parseData.message}
            id={Number(parseData.id) || 0}
            url={parseData.url}
          />,
          {
            position: 'bottom-left',
            hideProgressBar: true,
            autoClose: 5000,
            closeOnClick: false
          }
        )
        const notiPush = {
          id: Number(parseData.id) || 0,
          msg: parseData.message,
          title: parseData.title,
          url: parseData.url,
          isReaded: parseData.isRead || false,
          createDate: parseData.create || new Date().toISOString()
        }
        updateNotifications(notiPush)
        incDecTotalNotifications('plus')
      }
    }
    return () => {
      socket.close()
    }
  }, [user])

  if (checking === 'loading') {
    return (
      <ParticlesAuth>
        <div className='auth-page-content'>
          <Container>
            <Row>
              <Col lg={12}>
                <div className='text-center mt-sm-5 pt-4'>
                  <div className='mb-5 text-white-50'>
                    <h1 className='display-5 coming-soon-text'>Cargando...</h1>
                    <p className='fs-14'>Validando información</p>
                  </div>
                  <Row className='justify-content-center mb-5'>
                    <Col xl={4} lg={8}>
                      <div>
                        <img
                          src={maintenanceImg}
                          alt=''
                          className='img-fluid'
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    )
  }

  if (!user) {
    return (
      <Routes>
        <Route>
          {/* {publicRoutes.map((route, idx) => ( */}
          <Route
            path='/login'
            element={
              <NonAuthLayout>
                <Login />
              </NonAuthLayout>
            }
          />
          <Route
            path='*'
            element={
              <Navigate
                to='/login'
                replace
                state={{ prevUrl: location.pathname }}
              />
            }
          />
          {/* ))} */}
        </Route>
      </Routes>
    )
  }

  return (
    <>
      <ToastContainer
        closeButton={({ closeToast }) => (
          <i onClick={closeToast} className='ri ri-close-circle-fill fs-3' />
        )}
      />
      {!isOnline && (
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
          <Alert
            style={{ zIndex: 1005, maxWidth: '275px', margin: '0 auto' }}
            className='d-flex align-items-center gap-2 justify-content-center'
            color='danger'
          >
            <i className='mdi mdi-wifi-strength-off-outline fs-2' />
            Revisa tu conexión a internet
          </Alert>
        </div>
      )}
      <Routes>
        <Route>
          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                //  <AuthProtected>
                <AllowedCrm route={route.path}>
                  <VerticalLayout>{route.component}</VerticalLayout>
                </AllowedCrm>
                //  </AuthProtected>
              }
              key={idx}
              exact
            />
          ))}
          {/* <Route path='login' element={<Navigate to='/dashboard' />} /> */}
        </Route>
      </Routes>
    </>
  )
}

export default Index
