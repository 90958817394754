import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Link, useNavigate } from 'react-router-dom'

import Flatpickr from 'react-flatpickr'
import { Spanish } from 'flatpickr/dist/l10n/es'

// Import Images

import {
  Col,
  Container,
  Row,
  Card,
  // CardHeader,
  CardBody,
  UncontrolledTooltip,
  // UncontrolledPopover,
  Badge,
  Button,
  Table,
  Input,
  Spinner,
  CardHeader
} from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'

// Import actions

import { toast } from 'react-toastify'
import { useGetUser } from '../../../hooks/user'
import 'react-toastify/dist/ReactToastify.css'
// import { GET_INFO_EMP_COMER } from '../../../graphql/queries/getPuntos'
import { hasPermission, parseId } from '../../../helpers'
import {
  ALL_PERMISSIONS,
  ERROR_MESSAGES,
  STATUS_BILLINGS
} from '../../../helpers/consts'
import { GET_ALL_BILLINGS } from '../../../graphql/queries/getBillings'
import moment from 'moment'
import { API } from '../../../graphql/client'

const Billings = () => {
  const [gqlGetBillings, { data, loading, error }] = useLazyQuery(
    GET_ALL_BILLINGS,
    {
      fetchPolicy: 'network-only'
    }
  )

  const navigate = useNavigate()
  const { logout, permissions, user } = useGetUser()

  const [dataTable, setDataTable] = useState([])
  const [isSearch, setIsSearch] = useState(false)

  const [searchInput, setSearchInput] = useState('')
  const [searchValue, setSearchValue] = useState({
    key: '',
    value: ''
  })
  const [datesValue, setDatesValue] = useState({
    createdDateGte: undefined,
    createdDateLte: undefined
  })
  const [statusSelect, setStatusSelect] = useState('')
  const [pagination, setPagination] = useState({
    offset: 0,
    page: 1
  })
  const [isPagination, setIsPagination] = useState(false)

  const [loadingExport, setLoadingExport] = useState(false)

  useEffect(() => {
    const variables = {
      offset: pagination.offset
    }
    if (searchValue.key) {
      variables[searchValue.key] = searchValue.value
    }
    if (datesValue.createdDateGte && datesValue.createdDateLte) {
      variables.createdDateGte = datesValue.createdDateGte
      variables.createdDateLte = datesValue.createdDateLte
    }
    if (statusSelect) {
      variables.status = statusSelect
    }
    // console.log('variables', variables)
    gqlGetBillings({
      variables
    })
  }, [
    searchValue.value,
    datesValue.createdDateGte,
    datesValue.createdDateLte,
    statusSelect,
    pagination.offset
  ])

  useEffect(() => {
    if (!hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_FATURACION)) {
      navigate('/')
    }
  }, [permissions])

  useEffect(() => {
    if (data) {
      if (isPagination) {
        setDataTable((prev) => [...prev, ...data.listBilling.edges])
        setPagination((prev) => ({ ...prev, page: prev.page + 1 }))
        setIsPagination(false)
      } else {
        setDataTable(data.listBilling.edges)
      }
    }
  }, [data])

  useEffect(() => {
    if (error) {
      toast.error(`ERROR! ${error.message}`)
      if (error.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [error])

  const searchForDates = (e) => {
    if (e.length === 1) return

    setPagination({ page: 1, offset: 0 })

    if (e && e.length === 2) {
      setIsSearch(true)
      const createdDateGte = new Date(e[0]).toISOString()
      const createdDateLte = new Date(e[1]).toISOString()

      return setDatesValue({
        createdDateGte,
        createdDateLte
      })
    }
    setDatesValue({
      createdDateGte: undefined,
      createdDateLte: undefined
    })
  }

  const hanldeSubmit = (e) => {
    e.preventDefault()
    if (searchInput.length >= 3) {
      const key = `${Number(searchInput) ? 'documentNumber' : 'name'}`
      setIsSearch(true)
      setPagination({ page: 1, offset: 0 })
      setSearchValue({
        key,
        value: searchInput
      })
    }
  }

  const handleDownload = async () => {
    if (!datesValue.createdDateGte || !datesValue.createdDateLte) return
    const { token } = user.tokenAuth
    if (!token) return
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    const requestOptions = {
      method: 'GET',
      headers: myHeaders
    }

    setLoadingExport(true)
    const init = new Date(datesValue.createdDateGte).toISOString().split('T')[0]
    const end = new Date(datesValue.createdDateLte).toISOString().split('T')[0]
    try {
      const res = await fetch(
        `${API}/export/billings/${init}/${end}`,
        requestOptions
      )
      if (!res.ok) throw new Error('Error al exportar')
      const blob = await res.blob()
      // console.log('blob', blob)
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `facturaciones-${moment(datesValue.createdDateGte).format(
        'DD-MM-YY'
      )}-${moment(datesValue.createdDateLte).format('DD-MM-YY')}.xlsx`
      document.body.appendChild(link)
      setLoadingExport(false)
      link.click()
    } catch (err) {
      setLoadingExport(false)
      toast.error(`ERROR: ${err.message}`)
    }
  }

  if (loading && !isSearch && !isPagination) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Ver facturaciones' pageTitle='Facturaciones' />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody style={{ height: '30vh' }}>
                  <div className='d-flex justify-content-center align-items-center h-100'>
                    <Spinner style={{ width: '3em', height: '3em' }} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  document.title = 'Facturación | Total Sales'
  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Facturación' pageTitle='Facturaciones' />

          <Row>
            <Col>
              <Card id='companyList'>
                <CardHeader className='d-flex align-items-center justify-content-between border-0 gap-3 flex-column flex-md-row'>
                  <h5 className='card-title mb-0 d-flex align-items-center gap-1'>
                    Solicitudes{' '}
                    {loading && isSearch && (
                      <Spinner style={{ width: '14px', height: '14px' }} />
                    )}
                  </h5>
                  <div className='d-flex align-items-center gap-2 flex-column flex-md-row'>
                    <Button
                      disabled={
                        (!datesValue.createdDateGte &&
                          !datesValue.createdDateLte) ||
                        loadingExport
                      }
                      color='success'
                      className='d-flex align-items-center gap-2'
                      onClick={handleDownload}
                    >
                      {loadingExport ? (
                        <Spinner size='sm' />
                      ) : (
                        <i className='mdi mdi-microsoft-excel' />
                      )}
                      Descargar Excel
                    </Button>
                  </div>
                </CardHeader>
                <CardBody className='pt-0'>
                  <Row className='mb-3'>
                    <CardBody className='border border-dashed border-end-0 border-start-0'>
                      <Row className='g-4'>
                        <Col lg={4}>
                          <form onSubmit={hanldeSubmit}>
                            <div className='search-box me-2 mb-2 d-inline-block w-100'>
                              <input
                                id='search-bar-0'
                                type='search'
                                value={searchInput}
                                onChange={(e) => {
                                  setSearchInput(e.target.value)
                                  if (e.target.value === '') {
                                    setPagination({
                                      page: 1,
                                      offset: 0
                                    })
                                    setSearchValue({
                                      key: '',
                                      value: ''
                                    })
                                  }
                                }}
                                name='searchInput'
                                className='form-control search /'
                                placeholder='Ingresa cédula o nombre'
                              />
                              <i className='bx bx-search-alt search-icon' />
                            </div>
                          </form>
                        </Col>
                        <Col lg={4}>
                          <div className='input-group'>
                            <span
                              className='input-group-text'
                              id='basic-addon1'
                            >
                              <i className='ri-calendar-2-line' />
                            </span>
                            <Flatpickr
                              placeholder='Seleccione fecha(s)'
                              className='form-control'
                              onChange={(e) => {
                                searchForDates(e)
                                console.log('e', e)
                              }}
                              options={{
                                mode: 'range',
                                dateFormat: 'd F y',
                                showMonths: 2,
                                locale: Spanish,
                                position: 'auto center'
                              }}
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <select
                            className='form-select'
                            data-choices
                            data-choices-search-false
                            name='choices-single-default2'
                            id='choices-single-default2'
                            value={statusSelect}
                            onChange={(e) => {
                              setStatusSelect(e.target.value)
                              setPagination({ page: 1, offset: 0 })
                            }}
                          >
                            <option value=''>Escoja estado</option>
                            {Object.keys(STATUS_BILLINGS).map((status) => (
                              <option
                                key={status}
                                value={status.replace(/_/g, ' ')}
                              >
                                {STATUS_BILLINGS[status]}
                              </option>
                            ))}
                          </select>
                        </Col>
                      </Row>
                    </CardBody>
                  </Row>
                  {dataTable.length === 0 && !loading && (
                    <div
                      className='d-flex justify-content-center align-items-center'
                      style={{ height: '40vh' }}
                    >
                      <h6>No hay facturaciones</h6>
                    </div>
                  )}
                  <div>
                    {dataTable.length > 0 && (
                      <>
                        <Table
                          responsive
                          hover
                          className='align-middle table-nowrap'
                        >
                          <thead className='table-light text-muted'>
                            <tr>
                              <th>ID</th>
                              <th>Facturador</th>
                              <th>Agencia</th>
                              <th>Nombre</th>
                              <th>Documento</th>
                              <th className='text-center'>Productos</th>
                              <th>¿Pendiente descuento?</th>
                              <th>Ciudad</th>
                              <th>Tipo</th>
                              <th>Estado</th>
                              <th>¿Contraentrega pendiente?</th>
                              <th>Creado</th>
                              <th />
                            </tr>
                          </thead>

                          <tbody>
                            {dataTable
                              .slice(
                                10 * pagination.page - 10,
                                10 * pagination.page
                              )
                              .map(({ node }) => (
                                <tr key={node.id}>
                                  <td>{parseId(node.id)}</td>
                                  <td>
                                    {node.biller?.firstName ? (
                                      <span className='text-uppercase'>
                                        {node.biller?.firstName}{' '}
                                        {node.biller?.lastName}
                                      </span>
                                    ) : (
                                      <span style={{ color: 'red' }}>
                                        Sin asignar
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <span>
                                      {node.quotation?.agency?.name || 'N/A'}
                                    </span>
                                  </td>
                                  <td>
                                    <span className='text-uppercase'>
                                      {node.firstName} {node.firstSurname}
                                    </span>
                                  </td>
                                  <td>{node.documentNumber}</td>

                                  <td>
                                    <ul className=''>
                                      {node.productauxbillingSet.edges.map(
                                        ({ node }) => (
                                          <li className='' key={node.id}>
                                            {node.name}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </td>

                                  <td className='text-center'>
                                    <Badge
                                      color={
                                        node.pendingDiscount
                                          ? 'danger'
                                          : 'success'
                                      }
                                    >
                                      {node.pendingDiscount ? 'SI' : 'No'}
                                    </Badge>
                                  </td>
                                  <td>{node.cityResidence}</td>
                                  <td>
                                    {node.quotation?.quotationType || 'N/A'}
                                  </td>
                                  <td>
                                    <span>
                                      <Badge
                                        color={
                                          STATUS_BILLINGS[node.status] ===
                                          STATUS_BILLINGS.FACTURADO
                                            ? 'success'
                                            : STATUS_BILLINGS[node.status] ===
                                              STATUS_BILLINGS.EN_FACTURACION
                                            ? 'info'
                                            : 'dark'
                                        }
                                      >
                                        {STATUS_BILLINGS[node.status] ||
                                          node.status.replace(/_/g, ' ')}
                                      </Badge>
                                    </span>
                                  </td>
                                  <td className='text-center'>
                                    <Badge
                                      color={
                                        node.pendingResidue
                                          ? 'danger'
                                          : 'success'
                                      }
                                    >
                                      {node.pendingResidue ? 'SI' : 'No'}
                                    </Badge>
                                  </td>
                                  <td>{moment(node.created).fromNow()}</td>

                                  <td>
                                    <ul className='list-inline hstack gap-2 mb-0'>
                                      <li className='list-inline-item'>
                                        <Link
                                          to={`/facturacion/${parseId(
                                            node.id
                                          )}`}
                                          className='text-primary d-inline-block'
                                          id={
                                            'view' + parseId(node.id).toString()
                                          }
                                          target='_blank'
                                          rel='noopener noreferrer'
                                        >
                                          <i className='ri-eye-fill fs-16' />
                                        </Link>
                                        <UncontrolledTooltip
                                          placement='top'
                                          target={
                                            'view' + parseId(node.id).toString()
                                          }
                                          trigger='hover'
                                        >
                                          Ver
                                        </UncontrolledTooltip>
                                      </li>
                                      {node.quotation?.credit && (
                                        <li className='list-inline-item'>
                                          <Link
                                            to={`/credito/${parseId(
                                              node.quotation.credit?.id
                                            )}`}
                                            className='text-primary d-inline-block'
                                            id={
                                              'view' +
                                              parseId(
                                                node.quotation.credit?.id
                                              ).toString()
                                            }
                                            target='_blank'
                                            rel='noopener noreferrer'
                                          >
                                            <i className='mdi mdi-credit-card-clock-outline fs-16' />
                                          </Link>
                                          <UncontrolledTooltip
                                            placement='top'
                                            target={
                                              'view' +
                                              parseId(
                                                node.quotation.credit?.id
                                              ).toString()
                                            }
                                            trigger='hover'
                                          >
                                            Ver crédito
                                          </UncontrolledTooltip>
                                        </li>
                                      )}
                                    </ul>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                        <Row className='justify-content-md-end justify-content-center align-items-center p-2'>
                          <Col className='col-md-auto'>
                            <div className='d-flex gap-1'>
                              <Button
                                color='primary'
                                disabled={pagination.page <= 1}
                                onClick={() => {
                                  setPagination((prev) => ({
                                    ...prev,
                                    page: prev.page - 1
                                  }))
                                }}
                                // onClick={previousPage}
                                // disabled={!canPreviousPage}
                              >
                                {'<'}
                              </Button>
                            </div>
                          </Col>
                          <Col className='col-md-auto d-none d-md-block'>
                            Pág{' '}
                            <strong>
                              {pagination.page} de{' '}
                              {Math.ceil(dataTable.length / 10)}
                            </strong>
                          </Col>
                          <Col className='col-md-auto'>
                            <form
                              onSubmit={(e) => {
                                e.preventDefault()
                                if (!Number(pagination.page)) {
                                  setPagination((prev) => ({
                                    ...prev,
                                    page: 1
                                  }))
                                }
                                // console.log('pagination', pagination)
                              }}
                            >
                              <Input
                                type='number'
                                min={1}
                                style={{ width: 70 }}
                                // max={pageOptions.length}
                                value={pagination.page}
                                onChange={(e) => {
                                  const { value } = e.target
                                  if (value === '') {
                                    setPagination((prev) => ({
                                      ...prev,
                                      page: ''
                                    }))
                                    return
                                  }
                                  if (!Number(value)) {
                                    setPagination((prev) => ({
                                      ...prev,
                                      page: 1
                                    }))
                                    return
                                  }
                                  if (
                                    Number(value) <=
                                    Math.ceil(dataTable.length / 10)
                                  ) {
                                    setPagination((prev) => ({
                                      ...prev,
                                      page: Number(value)
                                    }))
                                  }
                                }}
                              />
                            </form>
                          </Col>

                          <Col className='col-md-auto'>
                            <div className='d-flex gap-1'>
                              <Button
                                color='primary'
                                disabled={
                                  !data?.listBilling?.pageInfo?.hasNextPage &&
                                  pagination.page ===
                                    Math.ceil(dataTable.length / 10)
                                }
                                onClick={() => {
                                  if (
                                    pagination.page <
                                    Math.ceil(dataTable.length / 10)
                                  ) {
                                    return setPagination((prev) => ({
                                      ...prev,
                                      page: prev.page + 1
                                    }))
                                  }
                                  const start = parseId(
                                    data?.listBilling?.pageInfo?.startCursor
                                  )
                                  setIsPagination(true)
                                  setPagination((prev) => ({
                                    ...prev,
                                    offset: start + 10
                                  }))
                                }}
                                // disabled={!canNextPage}
                              >
                                {loading ? (
                                  <Spinner size='sm' color='light' />
                                ) : (
                                  '>'
                                )}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Billings
