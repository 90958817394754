import { gql } from '@apollo/client'

export const DISBURSEMENT_FINISH = gql`
  mutation disbursementFinish($input: DisbursementFinishInput!) {
    disbursementFinish(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`
export const DISBURSEMENT_FINISH_STR = `
  mutation disbursementFinish($input: DisbursementFinishInput!) {
    disbursementFinish(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`
