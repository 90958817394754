import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
  TabPane
} from 'reactstrap'
import { parseId, parsePrice } from '../../../../helpers'
import { useMutation } from '@apollo/client'
import { CREATE_PAYMENTE_PARTIAL } from '../../../../graphql/mutations/creditWalletMutations'
import { toast } from 'react-toastify'
import { useDisclosure } from '../../../../hooks/useDisclosure'
import { InputCurrency } from '../../InputCurrency'

import { loadAnimation } from 'lottie-web'
import { defineElement } from 'lord-icon-element'

// register lottie and define custom element
defineElement(loadAnimation)

export const SubTabPayAbono = ({
  refetch,
  backToResume,
  creditId,
  obligationId,
  tabId
}) => {
  const [
    gqlCretePartial,
    { data: dataCreate, loading: loadingCreate, error: errorCreate }
  ] = useMutation(CREATE_PAYMENTE_PARTIAL)

  const { isOpen, onClose, onOpen } = useDisclosure()
  const [totalPay, setTotalPay] = useState('')

  useEffect(() => {
    setTotalPay('')
  }, [tabId])

  useEffect(() => {
    if (!errorCreate) return
    toast.error('Error: ' + errorCreate.message)
  }, [errorCreate])
  useEffect(() => {
    if (!dataCreate) return
    if (dataCreate.paymentPartial.success) {
      toast.success('Pago creado correctamente')
      refetch()
      backToResume()
      onClose()
      setTotalPay(0)
    } else if (dataCreate.paymentPartial.errors?.message) {
      toast.error('Error: ' + dataCreate.paymentPartial.errors.message)
    }
  }, [dataCreate])

  const openModal = () => {
    if (!Number(totalPay)) return
    onOpen()
  }
  const createPayPartial = () => {
    gqlCretePartial({
      variables: {
        input: {
          creditId: parseId(creditId),
          obligationId: parseId(obligationId),
          value: Number(totalPay)
        }
      }
    })
  }

  return (
    <>
      <TabPane tabId='2' className='p-4'>
        <div
          className='w-100 d-flex align-items-center justify-content-center'
          // style={{ height: '45vh' }}
        >
          <Card className='shadow-lg' style={{ minWidth: '240px' }}>
            <CardHeader>
              <CardTitle>Realiza pagos parciales o abonos</CardTitle>
            </CardHeader>
            <CardBody>
              <div className='d-flex align-items-center justify-content-center'>
                <lord-icon
                  src='/lotties/test-lottie.json'
                  trigger='loop'
                  // colors='primary:#0ab39c,secondary:#405189'
                  style={{ width: '120px', height: '120px' }}
                />
              </div>
              <div>
                <FormGroup>
                  <Label>Valor del abono</Label>
                  <InputCurrency
                    onValueChange={(v) => {
                      setTotalPay(v)
                    }}
                    value={totalPay}
                    className='form-control fs-4'
                  />
                </FormGroup>
              </div>
            </CardBody>
            <CardFooter className='d-flex justify-content-end'>
              <Button onClick={openModal} disabled={!Number(totalPay)}>
                Realizar abono
              </Button>
            </CardFooter>
          </Card>
        </div>
      </TabPane>

      <Modal isOpen={isOpen} toggle={onClose} centered>
        <ModalBody>
          <p className='fs-2'>
            {' '}
            Esta acción realizará un abono de{' '}
            <span className='text-danger fw-bold'>
              {parsePrice(totalPay)}
            </span>{' '}
          </p>{' '}
        </ModalBody>
        <ModalFooter>
          <Button
            color='light'
            onClick={() => {
              onClose()
              setTotalPay('')
            }}
          >
            Cancelar
          </Button>
          <Button
            color='primary'
            onClick={createPayPartial}
            disabled={loadingCreate}
            className='d-flex align-items-center gap-1'
          >
            {loadingCreate && <Spinner color='light' size='sm' />}
            Pagar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
