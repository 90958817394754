/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useParams, useNavigate, Link } from 'react-router-dom'
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  CloseButton,
  Col,
  Container,
  Fade,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  Table,
  UncontrolledAccordion
} from 'reactstrap'
import Select from 'react-select'
import { toast } from 'react-toastify'

import {
  GET_PRODUCTS_COTIZACION,
  GET_PRODUCT_INFO_COTIZACION
} from '../../../graphql/queries/getProducts'
import { GET_ONE_COTIZACION_TO_ADD_PRODUCT } from '../../../graphql/queries/getCotizaciones'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { InputCurrency } from '../../../Components/Own/InputCurrency'
import {
  getFullTotal,
  getTotal,
  hasPermission,
  inversePrice,
  parseId,
  parsePrice,
  parsePriceToNum
} from '../../../helpers'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useGetUser } from '../../../hooks/user'
import { ALL_PERMISSIONS, PRICE_TO_DELETE } from '../../../helpers/consts'
import imgNotFound from '../../../assets/images/not-found-prod.png'
import { ADD_PRODUCT_TO_QUOTATION } from '../../../graphql/mutations/quotations'
import { BadgeQuotation } from '../../../Components/Own/quotations/BadgeQuotation'

const INIT_OTHER_PRICES = {
  values: {
    name: '',
    price: ''
  },
  errors: {
    name: null,
    price: null
  },
  touched: {
    name: false,
    price: false
  }
}

export const AddProductToQuotationPage = () => {
  const [
    gqlAddProduct,
    { data: dataAddProduct, loading: loadingAddProduct, error: errorAddProduct }
  ] = useMutation(ADD_PRODUCT_TO_QUOTATION)

  const [gqlGetCotizacion, { data, loading, error }] = useLazyQuery(
    GET_ONE_COTIZACION_TO_ADD_PRODUCT,
    { fetchPolicy: 'network-only' }
  )
  const [
    gqlGetProducts,
    { data: dataProducts, loading: loadingProducts, error: errorProducts }
  ] = useLazyQuery(GET_PRODUCTS_COTIZACION, {
    fetchPolicy: 'network-only'
  })
  const [
    gqlGetInfoProduct,
    { data: dataInfoProduct, loading: loadingInfoProduct }
  ] = useLazyQuery(GET_PRODUCT_INFO_COTIZACION, { fetchPolicy: 'network-only' })
  const { id } = useParams()
  const navigate = useNavigate()

  const { infoZoneOrAgencie, permissions } = useGetUser()

  const [optionsData, setOptionsData] = useState([])
  const [selectOneProduct, setSelectOneProduct] = useState(null)
  const [productsSelected, setProductsSelected] = useState([])

  const [initialFee, setInitialFee] = useState('0')

  const [formOtherPrices, setFormOtherPrices] = useState(INIT_OTHER_PRICES)
  const [formThirdCredit, setFormThirdCredit] = useState(INIT_OTHER_PRICES)

  const [detail, setDetail] = useState([])

  const [canUpdateOptions, setCanUpdateOptions] = useState(false)

  const formik = useFormik({
    initialValues: {
      otherPrices: [],
      quotationType: 'CONTADO'
    },
    validationSchema: Yup.object({
      otherPrices: Yup.array().of(
        Yup.object({
          name: Yup.string().required('El nombre es obligatorio'),
          price: Yup.string().required('Number es obligatorio')
        })
      )
    }),
    onSubmit: (values) => {
      const quotation = {
        ...values
      }
      delete quotation.otherPrices
      const arrToAddNewPrices = productsSelected.map((prod) => {
        const obj = {
          productId: prod.id,
          otherPrices: prod.prices.map((info) => ({
            name: info.name,
            value: info.price
          }))
        }

        if (quotation.quotationType === 'CREDITO') {
          obj.initialFee = prod.initialFee
        }

        if (quotation.quotationType === 'TERCEROS') {
          obj.detail = prod.detail.map((obj) => ({
            name: obj.name,
            value: obj.price
          }))
        }

        return obj
      })
      // quotation.products = arrToAddNewPrices

      gqlAddProduct({
        variables: {
          input: {
            quotationId: id,
            products: arrToAddNewPrices
          }
        }
      })
    }
  })

  useEffect(() => {
    if (!id || !Number(id)) {
      navigate('/')
      return
    }
    if (!hasPermission(permissions, ALL_PERMISSIONS.PUEDE_CAMBIAR_COTIZACION)) {
      navigate('/')
      return
    }
    gqlGetCotizacion({
      variables: {
        id
      }
    })
    gqlGetProducts({
      variables: {
        active: true
      }
    })
  }, [id])

  useEffect(() => {
    if (dataProducts && dataProducts.listProducts?.edges) {
      const dataParse = dataProducts.listProducts.edges.map(({ node }) => ({
        value: parseId(node.id),
        label: node.name
      }))
      setOptionsData(dataParse)
      setCanUpdateOptions(true)
    }
  }, [dataProducts])

  useEffect(() => {
    if (!data) return
    if (data.listQuotations.edges.length === 0) return
    formik.setFieldValue(
      'quotationType',
      data.listQuotations.edges[0].node.quotationType
    )
  }, [data])

  useEffect(() => {
    if (error) {
      toast.error(`ERROR: ${error.message}`)
    }
  }, [error])
  useEffect(() => {
    if (errorProducts) {
      toast.error(`ERROR: ${errorProducts.message}`)
    }
  }, [errorProducts])

  useEffect(() => {
    if (dataInfoProduct) {
      if (
        dataInfoProduct.listProducts?.edges &&
        dataInfoProduct.listProducts?.edges.length > 0
      ) {
        const dataRes = dataInfoProduct.listProducts?.edges[0].node
        setProductsSelected((prev) => {
          const newArr = [...prev]
          const findIndex = newArr.findIndex(
            (prod) => prod.value === parseId(dataRes.id)
          )
          // console.log('findIndex', findIndex)
          newArr[findIndex] = {
            ...newArr[findIndex],
            basePrice: dataRes.basePrice,
            priceSet: dataRes.priceSet.edges,
            discountSet: dataRes.discountSet.edges
          }
          return newArr
        })
        setOptionsData((prev) => {
          const newArr = [...prev]
          const finIdx = newArr.findIndex(
            (prod) => parseId(dataRes.id) === prod.value
          )
          newArr[finIdx] = {
            ...newArr[finIdx],
            basePrice: dataRes.basePrice,
            priceSet: dataRes.priceSet.edges,
            discountSet: dataRes.discountSet.edges
          }
          return newArr
        })
      }
    }
  }, [dataInfoProduct])

  useEffect(() => {
    if (!data) return
    if (!dataProducts) return
    if (data.listQuotations.edges.length === 0) return
    if (dataProducts.listProducts?.edges.length === 0) return
    if (!canUpdateOptions) return

    /** @type {{node: {id: string, basePrice: string, product: {id: string, name: string, sku: string}}}[]} */

    const productsQuotation =
      data.listQuotations.edges[0]?.node?.quotationproductthroughSet?.edges

    if (!productsQuotation || productsQuotation.length === 0) return

    const newArr = optionsData.map((opt) => {
      const newObj = { ...opt }
      const findPro = productsQuotation.find(
        ({ node }) => parseId(node.product.id) === opt.value
      )
      if (findPro) newObj.isDisabled = true
      return newObj
    })
    setOptionsData(newArr)
  }, [dataProducts, data, canUpdateOptions])

  useEffect(() => {
    if (!dataAddProduct) return
    if (dataAddProduct.quotationAddProduct?.success) {
      toast.success('Pruducto(s) agregado(s) con éxito')
      navigate(`/cotizacion/${id}`)
    } else if (dataAddProduct.quotationAddProduct?.errors) {
      toast.error('Error ' + dataAddProduct.quotationAddProduct?.errors.message)
    }
  }, [dataAddProduct])

  useEffect(() => {
    if (!errorAddProduct) return
    toast.error('Error: ' + errorAddProduct.message)
  }, [errorAddProduct])

  const getTotalPrice = (pricesArr) => {
    let tot = 0
    // console.log('pricesArr', pricesArr)
    pricesArr.forEach(({ price }) => {
      tot += Number(price)
    })

    return tot
  }

  if (loadingProducts || loading) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Agregar productos' pageTitle='Cotizaciónes' />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between border-0 gap-3 flex-md-column flex-lg-row flex-sm-column '>
                  <div className='w-100'>
                    <h5 className='card-title mb-0'>Agregar productos</h5>
                  </div>
                </CardHeader>
                <CardBody style={{ height: '40vh' }}>
                  <div className='d-flex justify-content-center align-items-center h-100'>
                    <Spinner style={{ width: '3em', height: '3em' }} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  if (!data || !dataProducts) return null

  const {
    quotationType,
    firstName,
    firstSurname,
    quotationproductthroughSet,
    status
  } = data.listQuotations.edges[0].node

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Agregar productos' pageTitle='Cotizaciónes' />
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader>
                <CardTitle className='d-flex align-items-center gap-2'>
                  <h2 className='p-0 m-0'>
                    Cotización de{' '}
                    <span className='text-uppercase'>
                      {firstName} {firstSurname}
                    </span>
                  </h2>
                  <BadgeQuotation status={status} />
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div>
                  <p className='pb-4'>
                    {' '}
                    Esta cotización tiene{' '}
                    {quotationproductthroughSet.edges.length} productos
                  </p>
                  <div className='table-responsive table-card'>
                    <Table>
                      <thead className='bg-light'>
                        <tr>
                          <th />
                          <th>Nombre</th>
                          <th>SKU</th>
                          <th>Precio base</th>
                        </tr>
                      </thead>
                      <tbody>
                        {quotationproductthroughSet.edges.map(({ node }) => (
                          <tr key={node.id} style={{ verticalAlign: 'middle' }}>
                            <td>
                              <img
                                src={node.product.image || imgNotFound}
                                width={40}
                                alt={node.product.name}
                                style={{ borderRadius: '100%' }}
                              />
                            </td>
                            <td>{node.product.name}</td>
                            <td>{node.product.sku}</td>
                            <td>{parsePrice(node.basePrice)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={12}>
            <Card>
              <Form onSubmit={formik.handleSubmit}>
                <CardHeader className='d-flex align-items-center justify-content-between border-0 gap-3 flex-md-column flex-lg-row flex-sm-column '>
                  <div className='w-100'>
                    <h5 className='card-title mb-0'>Agregar productos</h5>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row className='g-3'>
                    <Col lg={12}>
                      <FormGroup>
                        <Label className='form-label'>Productos</Label>
                        <Select
                          className='basic-single'
                          placeholder='Seleccione un producto'
                          value={selectOneProduct}
                          // defaultValue={0}
                          isClearable
                          isSearchable
                          onChange={(e) => {
                            if (e) {
                              setSelectOneProduct({
                                id: e.value,
                                name: e.label,
                                ...e
                              })
                            } else {
                              setSelectOneProduct(null)
                            }
                          }}
                          id='products'
                          name='product'
                          options={optionsData}
                          noOptionsMessage={() => 'No se encontró producto'}
                        />

                        {/* {formik.touched.products && formik.errors.products ? (
                    <FormFeedback>{formik.errors.products}</FormFeedback>
                  ) : null} */}
                      </FormGroup>
                    </Col>

                    {selectOneProduct != null &&
                      quotationType === 'CREDITO' && (
                        <Col lg={12}>
                          <h5>Cuota incial</h5>
                          <FormGroup>
                            <Label>Valor cuota inicial</Label>
                            <InputCurrency
                              placeholder='Precio'
                              // decimalsLimit={0}
                              // decimalSeparator=','
                              // groupSeparator='.'
                              className='form-control'
                              // prefix='$ '
                              value={initialFee}
                              onValueChange={(value) => setInitialFee(value)}
                            />
                          </FormGroup>
                        </Col>
                      )}

                    {selectOneProduct != null &&
                      quotationType === 'TERCEROS' && (
                        <>
                          <Col lg={12}>
                            <h5 className='my-2 text-info'>
                              Información de crédito de terceros
                            </h5>
                            <Row className='align-items-center'>
                              <Col lg={5}>
                                <FormGroup style={{ color: '#003153' }}>
                                  <Label htmlFor='nameThirdPrice'>Nombre</Label>
                                  <Input
                                    id='nameThirdPrice'
                                    name='nameThirdPrice'
                                    value={formThirdCredit.values.name}
                                    placeholder='Ingrese nombre del precio'
                                    onBlur={() => {
                                      setFormThirdCredit((prev) => ({
                                        ...prev,
                                        touched: { ...prev.touched, name: true }
                                      }))
                                    }}
                                    onChange={(e) => {
                                      setFormThirdCredit((prev) => ({
                                        ...prev,
                                        values: {
                                          ...prev.values,
                                          name: e.target.value
                                        }
                                      }))
                                    }}
                                    invalid={Boolean(
                                      formThirdCredit.touched.name &&
                                        formThirdCredit.errors.name
                                    )}
                                  />
                                  {formThirdCredit.touched.name &&
                                    formThirdCredit.errors.name && (
                                      <FormFeedback type='invalid'>
                                        {formThirdCredit.errors.name}
                                      </FormFeedback>
                                    )}
                                </FormGroup>
                              </Col>
                              <Col lg={5}>
                                <FormGroup style={{ color: '#003153' }}>
                                  <Label>Valor</Label>
                                  <InputCurrency
                                    placeholder='Precio'
                                    // decimalsLimit={0}
                                    // decimalSeparator=','
                                    // groupSeparator='.'
                                    className={`form-control ${
                                      formThirdCredit.touched.price &&
                                      formThirdCredit.errors.price
                                        ? 'is-invalid'
                                        : ''
                                    } `}
                                    // prefix='$ '
                                    value={formThirdCredit.values.price}
                                    onValueChange={(value) => {
                                      setFormThirdCredit((prev) => ({
                                        ...prev,
                                        values: { ...prev.values, price: value }
                                      }))
                                    }}
                                    onBlur={() => {
                                      setFormThirdCredit((prev) => ({
                                        ...prev,
                                        touched: {
                                          ...prev.touched,
                                          price: true
                                        }
                                      }))
                                    }}
                                  />
                                  {formThirdCredit.touched.price &&
                                    formThirdCredit.errors.price && (
                                      <FormFeedback type='invalid'>
                                        {formThirdCredit.errors.price}
                                      </FormFeedback>
                                    )}
                                </FormGroup>
                              </Col>
                              <Col lg={2}>
                                <div className='form-label' />
                                <button
                                  type='button'
                                  className='btn btn-outline-info'
                                  disabled={
                                    formThirdCredit.errors.name ||
                                    formThirdCredit.errors.price ||
                                    formThirdCredit.values.name === ''
                                  }
                                  onClick={() => {
                                    if (
                                      formThirdCredit.errors.name &&
                                      formThirdCredit.errors.price
                                    ) {
                                      return
                                    }
                                    const { name, price } =
                                      formThirdCredit.values
                                    const idx = detail.findIndex(
                                      (el) => el.name === name
                                    )
                                    if (idx > -1) {
                                      setFormThirdCredit((prev) => ({
                                        ...prev,
                                        errors: {
                                          ...prev.errors,
                                          name: 'Nombre ya existe'
                                        }
                                      }))
                                      return
                                    }
                                    const otherPrices = { name, price }
                                    setDetail((pre) => [...pre, otherPrices])
                                    setFormThirdCredit(INIT_OTHER_PRICES)
                                  }}
                                >
                                  <i className='bx bx-plus' />
                                </button>
                              </Col>
                            </Row>
                          </Col>
                          {detail.length > 0 ? (
                            <Row style={{ borderBottom: '1px solid #e9e9e9' }}>
                              <Table responsive cellSpacing={4}>
                                <thead>
                                  <tr>
                                    <th
                                      scope='col'
                                      style={{ color: '#003153' }}
                                    >
                                      Nombre
                                    </th>
                                    <th
                                      scope='col'
                                      style={{ color: '#003153' }}
                                    >
                                      Valor
                                    </th>
                                    <th />
                                  </tr>
                                </thead>
                                <tbody>
                                  {detail.map((info, i) => (
                                    <tr key={info.name + i}>
                                      <td>{info.name}</td>
                                      <td>{parsePrice(info.price)}</td>
                                      <td>
                                        <div className='d-flex align-items-center gap-1'>
                                          <button
                                            className='btn btn-outline'
                                            title='Editar'
                                            onClick={() => {
                                              setDetail((pre) =>
                                                pre.filter(
                                                  (infoPrev) =>
                                                    infoPrev.name !== info.name
                                                )
                                              )
                                              setFormThirdCredit({
                                                ...INIT_OTHER_PRICES,
                                                values: {
                                                  name: info.name,
                                                  price: info.price
                                                }
                                              })
                                              const input =
                                                document.getElementById(
                                                  'nameThirdPrice'
                                                )
                                              if (input) {
                                                input.focus()
                                              }
                                            }}
                                          >
                                            <i className='bx bx-pencil' />
                                          </button>
                                          <CloseButton
                                            title='Eliminar'
                                            onClick={() => {
                                              setDetail((pre) =>
                                                pre.filter(
                                                  (infoPrev) =>
                                                    infoPrev.name !== info.name
                                                )
                                              )
                                            }}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </Row>
                          ) : (
                            <Row style={{ borderBottom: '1px solid #e9e9e9' }}>
                              <Col lg={12}>
                                <Alert
                                  color='warning'
                                  className='d-flex align-items-center gap-1'
                                >
                                  <i className='bx bx-info-circle fs-4' /> No
                                  hay precios agregados!
                                </Alert>
                              </Col>
                            </Row>
                          )}
                        </>
                      )}

                    {selectOneProduct != null && (
                      <>
                        <Col lg={12}>
                          <h5 className='my-2'>Agregar otros precios</h5>
                          <Row className='align-items-center'>
                            <Col lg={5}>
                              <FormGroup>
                                <Label>Nombre</Label>
                                <Input
                                  id='nameOtherPrice'
                                  name='nameOtherPrice'
                                  value={formOtherPrices.values.name}
                                  placeholder='Ingrese nombre del precio'
                                  onBlur={() => {
                                    setFormOtherPrices((prev) => ({
                                      ...prev,
                                      touched: { ...prev.touched, name: true }
                                    }))
                                  }}
                                  onChange={(e) => {
                                    setFormOtherPrices((prev) => ({
                                      ...prev,
                                      values: {
                                        ...prev.values,
                                        name: e.target.value
                                      }
                                    }))
                                  }}
                                  invalid={Boolean(
                                    formOtherPrices.touched.name &&
                                      formOtherPrices.errors.name
                                  )}
                                />
                                {formOtherPrices.touched.name &&
                                  formOtherPrices.errors.name && (
                                    <FormFeedback type='invalid'>
                                      {formOtherPrices.errors.name}
                                    </FormFeedback>
                                  )}
                              </FormGroup>
                            </Col>
                            <Col lg={5}>
                              <FormGroup>
                                <Label>Valor</Label>
                                <InputCurrency
                                  placeholder='Precio'
                                  // decimalsLimit={0}
                                  // decimalSeparator=','
                                  // groupSeparator='.'
                                  className={`form-control ${
                                    formOtherPrices.touched.price &&
                                    formOtherPrices.errors.price
                                      ? 'is-invalid'
                                      : ''
                                  } `}
                                  // prefix='$ '
                                  value={formOtherPrices.values.price}
                                  onValueChange={(value) => {
                                    setFormOtherPrices((prev) => ({
                                      ...prev,
                                      values: { ...prev.values, price: value }
                                    }))
                                  }}
                                  onBlur={() => {
                                    setFormOtherPrices((prev) => ({
                                      ...prev,
                                      touched: { ...prev.touched, price: true }
                                    }))
                                  }}
                                />

                                {formOtherPrices.touched.price &&
                                  formOtherPrices.errors.price && (
                                    <FormFeedback type='invalid'>
                                      {formOtherPrices.errors.price}
                                    </FormFeedback>
                                  )}
                              </FormGroup>
                            </Col>
                            <Col lg={2}>
                              <div className='form-label' />
                              <button
                                type='button'
                                className='btn btn-outline-success'
                                disabled={
                                  formOtherPrices.errors.name ||
                                  formOtherPrices.errors.price ||
                                  formOtherPrices.values.name === ''
                                }
                                onClick={() => {
                                  if (
                                    formOtherPrices.errors.name &&
                                    formOtherPrices.errors.price
                                  ) {
                                    return
                                  }
                                  const { name, price } = formOtherPrices.values
                                  const idx =
                                    formik.values.otherPrices.findIndex(
                                      (el) => el.name === name
                                    )
                                  if (idx > -1) {
                                    setFormOtherPrices((prev) => ({
                                      ...prev,
                                      errors: {
                                        ...prev.errors,
                                        name: 'Nombre ya existe'
                                      }
                                    }))
                                    return
                                  }
                                  const otherPrices = { name, price }
                                  formik.setFieldValue('otherPrices', [
                                    ...formik.values.otherPrices,
                                    otherPrices
                                  ])
                                  setFormOtherPrices(INIT_OTHER_PRICES)
                                }}
                              >
                                <i className='bx bx-plus' />
                              </button>
                            </Col>
                          </Row>
                        </Col>
                        {formik.values.otherPrices.length > 0 ? (
                          <Row>
                            <Table responsive cellSpacing={4}>
                              <thead>
                                <tr>
                                  <th scope='col'>Nombre</th>
                                  <th scope='col'>Valor</th>
                                  <th />
                                </tr>
                              </thead>
                              <tbody>
                                {formik.values.otherPrices.map((info, i) => (
                                  <tr key={info.name + i}>
                                    <td>{info.name}</td>
                                    <td>
                                      {parsePrice(parsePriceToNum(info.price))}
                                    </td>
                                    <td>
                                      <div className='d-flex align-items-center gap-1'>
                                        <button
                                          className='btn btn-outline'
                                          title='Editar'
                                          onClick={() => {
                                            const newArr = [
                                              ...formik.values.otherPrices
                                            ]

                                            formik.setFieldValue(
                                              'otherPrices',
                                              newArr.filter(
                                                (infoPrev) =>
                                                  infoPrev.name !== info.name
                                              )
                                            )
                                            setFormOtherPrices({
                                              ...INIT_OTHER_PRICES,
                                              values: {
                                                name: info.name,
                                                price: info.price
                                              }
                                            })
                                            const input =
                                              document.getElementById(
                                                'nameOtherPrice'
                                              )
                                            if (input) {
                                              input.focus()
                                            }
                                          }}
                                        >
                                          <i className='bx bx-pencil' />
                                        </button>
                                        <CloseButton
                                          title='Eliminar'
                                          onClick={() => {
                                            const newArr = [
                                              ...formik.values.otherPrices
                                            ]

                                            formik.setFieldValue(
                                              'otherPrices',
                                              newArr.filter(
                                                (infoPrev) =>
                                                  infoPrev.name !== info.name
                                              )
                                            )
                                          }}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </Row>
                        ) : (
                          <Row>
                            <Col lg={12}>
                              <Alert
                                color='primary'
                                className='d-flex align-items-center gap-1'
                              >
                                <i className='bx bx-info-circle fs-4' /> No hay
                                precios agregados!
                              </Alert>
                            </Col>
                          </Row>
                        )}
                      </>
                    )}

                    <div className='hstack gap-2 justify-content-end'>
                      <Button
                        color='info'
                        type='button'
                        id='add-btn-product'
                        onClick={() => {
                          if (!selectOneProduct) return

                          setOptionsData((prev) => {
                            const arr = [...prev]
                            const find = arr.find(
                              (option) => option.value === selectOneProduct.id
                            )
                            if (find) {
                              find.isDisabled = true
                            }
                            return arr
                          })
                          setProductsSelected((prev) => {
                            const newArr = [...prev]
                            // console.log('newArr', newArr)
                            // console.log('selectOneProduct', selectOneProduct)
                            const find = newArr.find(
                              (prod) => prod.id === selectOneProduct.value
                            )
                            // console.log('find', find)
                            if (find) {
                              find.prices = formik.values.otherPrices
                              find.initialFee =
                                quotationType === 'CREDITO'
                                  ? parsePriceToNum(initialFee)
                                  : undefined
                              find.detail =
                                quotationType === 'TERCEROS'
                                  ? detail
                                  : undefined
                              return newArr
                            }
                            const obj = {
                              ...selectOneProduct,
                              prices: formik.values.otherPrices,
                              initialFee:
                                quotationType === 'CREDITO'
                                  ? parsePriceToNum(initialFee)
                                  : undefined,
                              detail:
                                quotationType === 'TERCEROS'
                                  ? detail
                                  : undefined
                            }
                            return [...newArr, { ...obj }]
                          })
                          if (!infoZoneOrAgencie.zoneOrAgency) {
                            toast.error('Error: No tiene agencia asignada')
                          }
                          gqlGetInfoProduct({
                            variables: {
                              id: selectOneProduct.id,
                              agencyId:
                                infoZoneOrAgencie.zoneOrAgency?.split('***')[1]
                            }
                          }).then(() => {
                            setSelectOneProduct(null)
                            formik.setFieldValue('otherPrices', [])
                            setFormOtherPrices(INIT_OTHER_PRICES)
                            setFormThirdCredit(INIT_OTHER_PRICES)
                            setInitialFee('0')
                            setDetail([])
                          })
                        }}
                        disabled={
                          !selectOneProduct ||
                          loadingInfoProduct ||
                          formOtherPrices.values.name.length > 0 ||
                          formThirdCredit.values.name.length > 0
                        }
                      >
                        {/* {loadingCreate && (
                  <div
                    className='spinner-border me-2'
                    style={{ width: '16px', height: '16px' }}
                    role='status'
                  >
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                )} */}
                        {loadingInfoProduct && (
                          <Spinner size='sm' className='me-1' />
                        )}
                        Añadir
                      </Button>
                    </div>

                    <Col lg={12}>
                      <p className='text-muted'>
                        Productos: ({productsSelected.length})
                      </p>
                      <Fade>
                        {productsSelected.length > 0 && (
                          <UncontrolledAccordion defaultOpen='1'>
                            {productsSelected.map(
                              (
                                {
                                  id: idInfoProduct,
                                  name,
                                  basePrice,
                                  discountSet,
                                  prices,
                                  priceSet,
                                  initialFee: initialFeeProd,
                                  detail: detailProd
                                },
                                idx
                              ) => (
                                <AccordionItem key={idInfoProduct}>
                                  <AccordionHeader targetId={`${idx + 1}`}>
                                    {name}
                                  </AccordionHeader>
                                  {discountSet ? (
                                    <AccordionBody accordionId={`${idx + 1}`}>
                                      <div className='d-flex flex-column'>
                                        <table className='table table-borderless mb-0 table-responsive'>
                                          <tbody>
                                            {quotationType === 'CREDITO' && (
                                              <tr>
                                                <td className='fw-bold'>
                                                  Cuota inicial
                                                </td>
                                                <td className='text-end'>
                                                  {parsePrice(initialFeeProd)}
                                                </td>
                                              </tr>
                                            )}
                                            <tr>
                                              <td>Precio base:</td>
                                              <td
                                                className='text-end'
                                                id='cart-subtotal'
                                              >
                                                {parsePrice(basePrice)}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Descuentos:</td>
                                              <td
                                                className='text-end text-danger'
                                                id='cart-discount'
                                              >
                                                - {getTotal(discountSet)}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Otros conceptos:</td>
                                              <td
                                                className='text-end'
                                                id='cart-shipping'
                                              >
                                                {getTotal(
                                                  priceSet,
                                                  quotationType !== 'CREDITO'
                                                    ? PRICE_TO_DELETE.PRENDA
                                                    : null
                                                )}
                                              </td>
                                            </tr>
                                            <tr
                                              style={
                                                prices.length > 0
                                                  ? {
                                                      backgroundColor:
                                                        '#f6ffeb',
                                                      fontWeight: 500
                                                    }
                                                  : undefined
                                              }
                                            >
                                              <td>Otros precios</td>
                                              {prices.length > 0 ? (
                                                <td />
                                              ) : (
                                                <td className='text-end'>
                                                  $0.00
                                                </td>
                                              )}
                                              {/* {formik.values.otherPrices} */}
                                            </tr>
                                            {prices.map((infoPrices) => (
                                              <tr key={infoPrices.name}>
                                                <td className='ps-4'>
                                                  {infoPrices.name}:
                                                </td>
                                                <td className='text-end'>
                                                  {parsePrice(infoPrices.price)}
                                                </td>
                                              </tr>
                                            ))}
                                            <tr className='table-active'>
                                              <th>Total:</th>
                                              <td className='text-end'>
                                                {prices.length > 0 ? (
                                                  <span
                                                    className='fw-semibold'
                                                    id='cart-total'
                                                  >
                                                    {parsePrice(
                                                      inversePrice(
                                                        getFullTotal(
                                                          basePrice,
                                                          discountSet,
                                                          priceSet,
                                                          quotationType !==
                                                            'CREDITO'
                                                            ? PRICE_TO_DELETE.PRENDA
                                                            : null
                                                        )
                                                      ) + getTotalPrice(prices)
                                                    )}
                                                  </span>
                                                ) : (
                                                  <span
                                                    className='fw-semibold'
                                                    id='cart-total'
                                                  >
                                                    {getFullTotal(
                                                      basePrice,
                                                      discountSet,
                                                      priceSet,
                                                      quotationType !==
                                                        'CREDITO'
                                                        ? PRICE_TO_DELETE.PRENDA
                                                        : null
                                                    )}
                                                  </span>
                                                )}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        {quotationType === 'TERCEROS' && (
                                          <div className='mt-4'>
                                            <h5>
                                              Información de crédito de terceros
                                            </h5>
                                            {detailProd.length > 0 ? (
                                              <table className='table table-borderless mb-0 table-responsive'>
                                                <tbody>
                                                  {detailProd.map((det) => (
                                                    <tr key={det.name}>
                                                      <td>{det.name}</td>
                                                      <td className='text-end'>
                                                        {parsePrice(det.price)}
                                                      </td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              </table>
                                            ) : (
                                              <div>No hay valores</div>
                                            )}
                                          </div>
                                        )}
                                        <div className='d-flex gap-2 justify-content-end align-items-center'>
                                          <Button
                                            color='info'
                                            className='d-flex align-items-center align-self-end mt-4'
                                            outline
                                            onClick={() => {
                                              const arr = [...optionsData]
                                              const find = arr.find(
                                                (option) =>
                                                  option.label === name
                                              )
                                              if (find) {
                                                setOptionsData((prev) => {
                                                  const arr = [...prev]
                                                  const find = arr.find(
                                                    (option) =>
                                                      option.value ===
                                                      idInfoProduct
                                                  )
                                                  if (find) {
                                                    delete find.isDisabled
                                                  }
                                                  return arr
                                                })
                                                setSelectOneProduct(find)
                                                formik.setFieldValue(
                                                  'otherPrices',
                                                  [...prices]
                                                )
                                                if (
                                                  quotationType === 'CREDITO'
                                                ) {
                                                  setInitialFee(
                                                    initialFeeProd.toString()
                                                  )
                                                }
                                                if (
                                                  quotationType === 'TERCEROS'
                                                ) {
                                                  setDetail([...detailProd])
                                                }
                                                setProductsSelected((pre) => {
                                                  const newArr = [...pre]
                                                  return newArr.filter(
                                                    (prod) =>
                                                      prod.id !== idInfoProduct
                                                  )
                                                })
                                              }
                                            }}
                                          >
                                            <i className='bx bx-pencil d-block me-2' />
                                            <span className='d-block'>
                                              Editar
                                            </span>
                                          </Button>
                                          <Button
                                            color='danger'
                                            className='d-flex align-items-center align-self-end mt-4'
                                            outline
                                            onClick={() => {
                                              setProductsSelected((prev) => {
                                                const newArr = [...prev]
                                                return newArr.filter(
                                                  (prod) =>
                                                    prod.id !== idInfoProduct
                                                )
                                              })
                                              setOptionsData((prev) => {
                                                const arr = [...prev]
                                                const find = arr.find(
                                                  (option) =>
                                                    option.value ===
                                                    idInfoProduct
                                                )
                                                if (find) {
                                                  delete find.isDisabled
                                                }
                                                return arr
                                              })
                                            }}
                                          >
                                            <i className='bx bx-trash d-block me-2' />
                                            <span className='d-block'>
                                              Eliminar
                                            </span>
                                          </Button>
                                        </div>
                                      </div>
                                    </AccordionBody>
                                  ) : (
                                    <AccordionBody accordionId={id}>
                                      Cargando...
                                    </AccordionBody>
                                  )}
                                </AccordionItem>
                              )
                            )}
                          </UncontrolledAccordion>
                        )}
                      </Fade>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className='d-flex align-items-center justify-content-end gap-2'>
                  <Link className='btn btn-light' to={`/cotizacion/${id}`}>
                    Cancelar
                  </Link>
                  <Button
                    color='success'
                    type='submit'
                    disabled={loadingAddProduct}
                    className='d-flex align-items-center gap-1'
                  >
                    {loadingAddProduct && <Spinner size='sm' color='light' />}
                    Agregar a cotización
                  </Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
