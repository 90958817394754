import { gql } from '@apollo/client'

export const GET_ALL_COTIZACIONES = gql`
  query listQuotations(
    $id: Float
    $status: String
    $documentNumber: String
    $agencyId: Float
    $createdDateGte: DateTime
    $createdDateLte: DateTime
    $name: String
    $offset: Int
  ) {
    listQuotations(
      id: $id
      status_Iexact: $status
      documentNumber: $documentNumber
      agency_Id: $agencyId
      created_Date_Gte: $createdDateGte
      created_Date_Lte: $createdDateLte
      name: $name
      offset: $offset
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }

      edges {
        node {
          agency {
            name
          }
          id
          created
          quotationType
          phoneNumber
          firstName
          firstSurname
          secondName
          secondSurname
          status
          user {
            id
            firstName
            lastName
          }
          products {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`
export const GET_ONE_COTIZACION = gql`
  query listQuotations(
    $id: Float
    $status: String
    $documentNumber: String
    $agencyId: Float
    $createdDateGte: DateTime
    $createdDateLte: DateTime
    $name: String
  ) {
    listQuotations(
      id: $id
      status_Iexact: $status
      documentNumber: $documentNumber
      agency_Id: $agencyId
      created_Date_Gte: $createdDateGte
      created_Date_Lte: $createdDateLte
      name: $name
    ) {
      edges {
        node {
          id
          created
          documentNumber
          email
          firstName
          firstSurname
          secondName
          secondSurname
          phoneNumber
          status
          quotationType
          billing {
            id
          }
          agency {
            name
            address
            phoneNumber
            city
            zone {
              name
              commercialCompany {
                image
              }
            }
          }
          credit {
            id
          }
          user {
            firstName
            lastName
            profileuser {
              phoneNumber
            }
          }
          quotationproductthroughSet {
            edges {
              node {
                id
                basePrice
                priceauxquotationSet {
                  edges {
                    node {
                      name
                      value
                    }
                  }
                }
                discountauxquotationSet {
                  edges {
                    node {
                      name
                      value
                    }
                  }
                }
                product {
                  id
                  name
                  sku
                  image
                }
                auxquotationdetailSet {
                  edges {
                    node {
                      id
                      name
                      value
                    }
                  }
                }
              }
            }
          }
          quotationcommentSet {
            edges {
              node {
                id
                comment
                created
                user {
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    }
  }
`
export const GET_ONE_COTIZACION_TO_ADD_PRODUCT = gql`
  query listQuotations(
    $id: Float
    $status: String
    $documentNumber: String
    $agencyId: Float
    $createdDateGte: DateTime
    $createdDateLte: DateTime
    $name: String
  ) {
    listQuotations(
      id: $id
      status_Iexact: $status
      documentNumber: $documentNumber
      agency_Id: $agencyId
      created_Date_Gte: $createdDateGte
      created_Date_Lte: $createdDateLte
      name: $name
    ) {
      edges {
        node {
          id
          documentNumber
          firstName
          firstSurname
          secondName
          secondSurname
          status
          quotationType
          quotationproductthroughSet {
            edges {
              node {
                id
                basePrice
                product {
                  id
                  name
                  sku
                  image
                }
              }
            }
          }
        }
      }
    }
  }
`

export const UPDATE_PRICE_QUOTATION = gql`
  mutation changePricesQuotation($input: ChangePriceQuotationInput!) {
    changePricesQuotation(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`

export const GET_ALL_TYPE_COTIZ = gql`
  query listCreditLine(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    listCreditLine(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          name
          legibleName
          description
        }
      }
    }
  }
`
