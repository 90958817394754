import React from 'react'
import { STATUS_CREDITS_OBJ } from '../../../helpers/consts'

export const BadgeStatusCredit = ({ status }) => {
  switch (STATUS_CREDITS_OBJ[status]) {
    case STATUS_CREDITS_OBJ.SIN_REVISAR:
      return (
        <span className='badge badge-soft-dark text-uppercase'>
          {status.replace(/_/g, ' ')}
        </span>
      )
    case STATUS_CREDITS_OBJ.PREAPROBADO:
      return (
        <span className='badge badge-soft-success text-uppercase'>
          {status.replace(/_/g, ' ')}
        </span>
      )
    case STATUS_CREDITS_OBJ.RECHAZADO:
      return (
        <span className='badge badge-soft-danger text-uppercase'>
          {status.replace(/_/g, ' ')}
        </span>
      )
    case STATUS_CREDITS_OBJ.POR_DESEMBOLSAR:
      return (
        <span
          className='badge text-uppercase'
          style={{ backgroundColor: '#3498db' }}
        >
          {status.replace(/_/g, ' ')}
        </span>
      )
    case STATUS_CREDITS_OBJ.DESEMBOLSADO:
      return (
        <span
          className='badge text-uppercase'
          style={{ backgroundColor: 'purple' }}
        >
          {status.replace(/_/g, ' ')}
        </span>
      )
    case STATUS_CREDITS_OBJ.FORMALIZADO:
      return (
        <span className='badge badge-soft-secondary text-uppercase'>
          {status.replace(/_/g, ' ')}
        </span>
      )
    case STATUS_CREDITS_OBJ.APROBADO:
      return (
        <span
          className='badge text-uppercase'
          style={{ backgroundColor: '#27ae60' }}
        >
          {status.replace(/_/g, ' ')}
        </span>
      )
    case STATUS_CREDITS_OBJ.ESTUDIO_FORMAL:
      return (
        <span className='badge badge-soft-primary text-uppercase'>
          {status.replace(/_/g, ' ')}
        </span>
      )
    case STATUS_CREDITS_OBJ.PENDIENTE:
      return (
        <span className='badge badge-soft-warning text-uppercase'>
          {status.replace(/_/g, ' ')}
        </span>
      )
    case STATUS_CREDITS_OBJ.INCOMPLETO:
      return (
        <span
          className='badge text-uppercase'
          style={{ backgroundColor: '#f39c12' }}
        >
          {status.replace(/_/g, ' ')}
        </span>
      )
    case STATUS_CREDITS_OBJ.NO_VIABLE:
      return (
        <span
          className='badge text-uppercase'
          style={{ backgroundColor: '#ff8c3a' }}
        >
          {status.replace(/_/g, ' ')}
        </span>
      )
    case STATUS_CREDITS_OBJ.DESISTIDO:
      return (
        <span
          className='badge text-bg-dark text-uppercase'
          style={{ backgroundColor: '#e74c3c' }}
        >
          {status.replace(/_/g, ' ')}
        </span>
      )
    default:
      return (
        <span className='badge badge-soft-dark text-uppercase'>
          {status.replace(/_/g, ' ')}
        </span>
      )
  }
}
