import React, { useEffect, useState } from 'react'
import {
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner
  // UncontrolledAlert
} from 'reactstrap'
// import ParticlesAuth from '../AuthenticationInner/ParticlesAuth'

// redux
// import { useSelector, useDispatch } from 'react-redux'

import { useLocation, useNavigate } from 'react-router-dom'
import withRouter from '../../Components/Common/withRouter'
// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'

// Social Media Imports
/* import { GoogleLogin } from 'react-google-login'
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
// actions
import { loginUser, socialLogin, resetLoginFlag } from '../../slices/thunks' */

// import logoLight from '../../assets/images/logo-light.png'
// Import config
// import { facebook, google } from '../../config'
import { useMutation, useQuery } from '@apollo/client'
import { LOGIN } from '../../graphql/mutations/login'
import { useGetUser } from '../../hooks/user'
import AuthSlider from '../AuthenticationInner/authCarousel'
import { GET_ROLES_TO_USER } from '../../graphql/queries/getUsuarios'
import { VERSION } from '../../helpers'
// import images

const Login = () => {
  useQuery(GET_ROLES_TO_USER, { fetchPolicy: 'no-cache' })
  const navigate = useNavigate()
  const location = useLocation()
  const { login } = useGetUser()
  const [
    gqlLogin,
    { data: dataLogin, error: errorLogin, loading: loadingLogin }
  ] = useMutation(LOGIN)

  // const dispatch = useDispatch();

  const [passwordShow, setPasswordShow] = useState(false)

  /* useEffect(() => {
        if (user && user) {
            setUserLogin({
                email: user.user.email,
                password: user.user.confirm_password
            });
        }
    }, [user]); */

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Ingresa un correo electrónico'),
      password: Yup.string().required('Ingresa una contraseña')
    }),
    onSubmit: (values) => {
      // console.log(values);
      gqlLogin({
        variables: {
          email: values.email,
          password: values.password
        }
      })
      // dispatch(loginUser(values, props.router.navigate));
    }
  })

  /* const signIn = (res, type) => {
        if (type === "google" && res) {
            const postData = {
                name: res.profileObj.name,
                email: res.profileObj.email,
                token: res.tokenObj.access_token,
                idToken: res.tokenId,
            };
            dispatch(socialLogin(postData, props.router.navigate, type));
        } else if (type === "facebook" && res) {
            const postData = {
                name: res.name,
                email: res.email,
                token: res.accessToken,
                idToken: res.tokenId,
            };
            dispatch(socialLogin(postData, props.router.navigate, type));
        }
    }; */

  // handleGoogleLoginResponse
  /*     const googleResponse = response => {
        signIn(response, "google");
    }; */

  // handleTwitterLoginResponse
  // const twitterResponse = e => {}

  // handleFacebookLoginResponse
  /* const facebookResponse = response => {
        signIn(response, "facebook");
    }; */

  /*     useEffect(() => {
        if (errorMsg) {
            setTimeout(() => {
                dispatch(resetLoginFlag());
            }, 3000);
        }
    }, [dispatch, errorMsg]); */

  useEffect(() => {
    if (dataLogin) {
      login(dataLogin)
      console.log('window.history', window.history)
      const urlBack = location?.state?.prevUrl || '/dashboard'
      navigate(urlBack)
      // return
    }
  }, [dataLogin])

  document.title = 'Inicio de sesión | Total Sales'
  return (
    <>
      <div className='auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100'>
        <div className='bg-overlay' />
        <div className='auth-page-content overflow-hidden pt-lg-5'>
          <Container>
            <Row>
              <Col lg={12}>
                <Card className='overflow-hidden'>
                  <Row className='g-0'>
                    <AuthSlider />

                    <Col lg={6}>
                      <div className='p-lg-5 p-4'>
                        <div>
                          <h5 className='text-primary'>Bienvenido !</h5>
                          <p className='text-muted'>
                            Ingresa a continuación a Total Sales.
                          </p>
                        </div>

                        {errorLogin && (
                          <Alert color='danger'> {errorLogin.message} </Alert>
                        )}

                        <div className='mt-4'>
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault()
                              validation.handleSubmit()
                              return false
                            }}
                            action='#'
                          >
                            <div className='mb-3'>
                              <Label htmlFor='email' className='form-label'>
                                Correo
                              </Label>
                              <Input
                                name='email'
                                className='form-control'
                                placeholder='Ingresa correo electrónico'
                                type='email'
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ''}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                }
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type='invalid'>
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className='mb-3'>
                              <Label
                                className='form-label'
                                htmlFor='password-input'
                              >
                                Contraseña
                              </Label>
                              <div className='position-relative auth-pass-inputgroup mb-3'>
                                <Input
                                  name='password'
                                  value={validation.values.password || ''}
                                  type={passwordShow ? 'text' : 'password'}
                                  className='form-control pe-5'
                                  placeholder='Ingresa contraseña'
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.password &&
                                    validation.errors.password
                                  }
                                />
                                {validation.touched.password &&
                                validation.errors.password ? (
                                  <FormFeedback type='invalid'>
                                    {validation.errors.password}
                                  </FormFeedback>
                                ) : null}
                                <button
                                  className='btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted'
                                  type='button'
                                  id='password-addon'
                                  onClick={() => setPasswordShow(!passwordShow)}
                                >
                                  <i className='ri-eye-fill align-middle' />
                                </button>
                              </div>
                            </div>

                            <div className='form-check'>
                              <Input
                                className='form-check-input'
                                type='checkbox'
                                value=''
                                id='auth-remember-check'
                              />
                              <Label
                                className='form-check-label'
                                htmlFor='auth-remember-check'
                              >
                                Recordarme
                              </Label>
                            </div>

                            <div className='mt-4'>
                              <Button
                                color='success'
                                disabled={errorLogin ? null : !!loadingLogin}
                                className='btn btn-success w-100'
                                type='submit'
                              >
                                {loadingLogin && (
                                  <Spinner size='sm' className='me-2'>
                                    Cargando...
                                  </Spinner>
                                )}
                                Iniciar sesión
                              </Button>
                            </div>

                            {/*                             <div className="mt-4 text-center">
                              <div className="signin-other-title">
                                <h5 className="fs-13 mb-4 title">
                                  Sign In with
                                </h5>
                              </div>
                              <div>
                                <FacebookLogin
                                  appId={facebook.APP_ID}
                                  autoLoad={false}
                                  callback={facebookResponse}
                                  render={(renderProps) => (
                                    <Button
                                      color="primary"
                                      className="btn-icon me-1"
                                      onClick={renderProps.onClick}
                                    >
                                      <i className="ri-facebook-fill fs-16" />
                                    </Button>
                                  )}
                                />
                                <GoogleLogin
                                  clientId={
                                    google.CLIENT_ID ? google.CLIENT_ID : ""
                                  }
                                  render={(renderProps) => (
                                    <Button
                                      color="danger"
                                      to="#"
                                      className="btn-icon me-1"
                                      onClick={renderProps.onClick}
                                    >
                                      <i className="ri-google-fill fs-16" />
                                    </Button>
                                  )}
                                  onSuccess={googleResponse}
                                  onFailure={(error) => {
                                    console.log("fail", error);
                                  }}
                                />
                                <Button color="dark" className="btn-icon">
                                  <i className="ri-github-fill fs-16"></i>
                                </Button>{" "}
                                <Button color="info" className="btn-icon">
                                  <i className="ri-twitter-fill fs-16"></i>
                                </Button>
                              </div>
                            </div> */}
                          </Form>
                        </div>

                        {/* <div className="mt-5 text-center">
                          <p className="mb-0">
                            Don't have an account ?{" "}
                            <a
                              href="/auth-signup-cover"
                              className="fw-semibold text-primary text-decoration-underline"
                            >
                              {" "}
                              Signup
                            </a>{" "}
                          </p>
                        </div> */}
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <footer className='footer'>
          <Container>
            <Row>
              <Col lg={12}>
                <div className='text-center'>
                  {/* <p className='mb-0'>
                    Hecho con <i className='mdi mdi-heart text-danger' /> por
                    Soluciones Integrales OB SAS - Motocicletas SAS. {VERSION}
                  </p> */}
                  <p className='mb-0'>
                    Hecho con <i className='mdi mdi-heart text-danger' /> por
                    Soluciones Integrales OB SAS. {VERSION}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </>
  )
}

export default withRouter(Login)

/*
    <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Inicio de sesión!</h5>
                      <p className="text-muted">
                        Ingresa correo y contraseña para acceder a tu cuenta.
                      </p>
                    </div>
                    {errorLogin && errorLogin ? (
                      <Alert color="danger"> {errorLogin.message} </Alert>
                    ) : null}
                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            Correo
                          </Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <div className="float-end">
                            <Link to="/forgot-password" className="text-muted">
                              Forgot password?
                            </Link>
                          </div>
                          <Label
                            className="form-label"
                            htmlFor="password-input"
                          >
                            Contraseña
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={passwordShow ? "text" : "password"}
                              className="form-control pe-5"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon"
                              onClick={() => setPasswordShow(!passwordShow)}
                            >
                              <i className="ri-eye-fill align-middle"></i>
                            </button>
                          </div>
                        </div>

                        <div className="form-check">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="auth-remember-check"
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="auth-remember-check"
                          >
                            Remember me
                          </Label>
                        </div>

                        <div className="mt-4">
                          <Button
                            color="success"
                            disabled={
                              errorLogin ? null : loadingLogin ? true : false
                            }
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            {loadingLogin ? (
                              <Spinner size="sm" className="me-2">
                                {" "}
                                Cargando...{" "}
                              </Spinner>
                            ) : null}
                            Iniciar sesión
                          </Button>
                        </div>

                         <div className="mt-4 text-center">
                                                    <div className="signin-other-title">
                                                        <h5 className="fs-13 mb-4 title">Sign In with</h5>
                                                    </div>
                                                    <div>
                                                        <FacebookLogin
                                                            appId={facebook.APP_ID}
                                                            autoLoad={false}
                                                            callback={facebookResponse}
                                                            render={renderProps => (
                                                                <Button color="primary"
                                                                    className="btn-icon me-1"
                                                                    onClick={renderProps.onClick}
                                                                >
                                                                    <i className="ri-facebook-fill fs-16" />
                                                                </Button>
                                                            )}
                                                        />
                                                        <GoogleLogin
                                                            clientId={
                                                                google.CLIENT_ID ? google.CLIENT_ID : ""
                                                            }
                                                            render={renderProps => (
                                                                <Button color="danger"
                                                                    to="#"
                                                                    className="btn-icon me-1"
                                                                    onClick={renderProps.onClick}
                                                                >
                                                                    <i className="ri-google-fill fs-16" />
                                                                </Button>
                                                            )}
                                                            onSuccess={googleResponse}
                                                            onFailure={(error) => {
                                                                console.log("fail", error);
                                                            }}
                                                        />
                                                        <Button color="dark" className="btn-icon"><i className="ri-github-fill fs-16"></i></Button>{" "}
                                                        <Button color="info" className="btn-icon"><i className="ri-twitter-fill fs-16"></i></Button>
                                                    </div>
                                                </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
*/
