/** @constant */
export const ROLES = {
  ADMIN: 'Administrador',
  ASESOR: 'Asesor',
  JEFE_ZONA: 'Jefe de Zona',
  FACTURADOR: 'Facturador',
  ANALISTA: 'Analista',
  GERENTE: 'Gerente',
  JEFE_CREDITO: 'Jefe de cr\u00e9dito',
  CARTERA: 'Cartera',
  CONTABILIDAD: 'Contabilidad'
}
export const LANG_SELECT_DUAL_LIST = {
  availableFilterHeader: 'Filtrar agencias',
  availableHeader: 'Agencias',
  filterPlaceholder: 'Buscar...',
  moveAllLeft: 'Mover todo a agencias',
  moveAllRight: 'Mover todo a seleccionados',
  moveLeft: 'Mover a agencias',
  moveRight: 'Mover a seleccionados',
  moveBottom: 'Reorganizar hacia abajo',
  moveDown: 'Mover hacia abajo',
  moveUp: 'Mover hacia arriba',
  moveTop: 'Reorganizar hacia arriba',
  noAvailableOptions: 'No hay opciones agencias',
  noSelectedOptions: 'No hay opciones seleccionadas',
  requiredError: 'Por favor, selecciona al menos una opción.',
  selectedFilterHeader: 'Filtrar seleccionados',
  selectedHeader: 'Seleccionados'
}

export const ENTITIES = {
  ROL_ASESOR: 'agencia',
  ROL_JEFE: 'zones'
}

export const ERROR_MESSAGES = {
  SIGNATURE_EXPIRED: 'Signature has expired'
}

export const STATUS_USED_MOTOS = {
  EN_INVENTARIO: 'EN INVENTARIO',
  NOTIFICADA: 'NOTIFICADA',
  VENDIDA: 'VENDIDA',
  ENTREGADA_AL_CLIENTE: 'ENTREGADA AL CLIENTE'
}

export const INIT_INFO_ROLES = {
  ADMIN: {
    name: ROLES.ADMIN,
    company: ''
  },
  JEFE_ZONA: {
    name: ROLES.JEFE_ZONA,
    zones: []
  },
  ASESOR: {
    name: ROLES.ASESOR,
    position: []
  },
  FACTURADOR: {
    name: ROLES.FACTURADOR,
    zones: []
  },
  ANALISTA: {
    name: ROLES.ANALISTA,
    zones: []
  },
  GERENTE: {
    name: ROLES.GERENTE,
    select: false
  },
  CARTERA: {
    name: ROLES.CARTERA,
    select: false
  },
  JEFE_CREDITO: {
    name: ROLES.JEFE_CREDITO,
    select: false
  },
  CONTABILIDAD: {
    name: ROLES.CONTABILIDAD,
    select: false
  }
}

export const ALL_PERMISSIONS = {
  PUEDE_PASAR_COTIZACION_ALIADO_CREDITO: 'change_allyquote',
  PUEDE_CREAR_COTIZACIONES_ALIADOS: 'add_allyquote',
  PUEDE_VER_COTIZACION_ALIADOS_ADMIN: 'view_allyquote_admin',
  PUEDE_VER_COTIZACION_ALIADOS: 'view_allyquote',
  PUEDE_ACTUALIZAR_PRECIO_QUOT: 'change_pricequotation',
  DESEMBOLSO_PASAR_A_PENDIENTE: 'can_walletadd',
  DESEMBOLSO_PASAR_A_POR_DESEMBOLSAR: 'can_walletapprove',
  DESEMBOLSO_PASAR_A_DESEMBOLSADO: 'can_walletdisburse',
  PUEDE_INTERCAMBIAR_CODEUDOR: 'interchange_debtor',
  PUEDE_VER_REPORTES: 'view_budgetreport',
  PUEDE_CAMBIAR_SETTINGS: 'change_settings',
  PUEDE_CAMBIAR_TASA: 'change_monthlyfee',
  PUEDE_VER_CONFIGURACIONES: 'view_settings',
  PUEDE_VER_USUARIOS_INACTIVOS: 'view_user_inactive',
  PUEDE_CAMBIAR_RESIDUE: 'change_residue',
  PUEDE_CAMBIAR_PERCIO_BASE: 'change_basepriceproducts',
  PUEDE_APROBAR_DESCUENTO_FATURACION: 'change_discountauxbilling',
  PUEDE_VER_CENTRALES: 'view_centralrisk',
  PUEDE_CAMBIAR_ESTADO_PAZ_Y_SALVO: 'change_compliancecertificate',
  PUEDE_CREAR_PAZ_Y_SALVO: 'add_compliancecertificate',
  PUEDE_VER_PAZ_Y_SALVOS: 'view_compliancecertificate',
  PUEDE_VER_CARTERA_CREDITO: 'view_walletcredit',
  PUEDE_CAMBIAR_ESTADO_CREDITO: 'change_creditstatus',
  PUEDE_VER_DESEMBOLSOS: 'view_disbursementrequest',
  PUEDE_ELIMINAR_ENTIDAD_ADMIN: 'delete_adminentity',
  PUEDE_EDITAR_TODO_CREDITO: 'change_adminentity',
  PUEDE_AGREGAR_REFERENCIA: 'add_reference',
  PUEDE_AGREGAR_CONYUGE: 'add_spouse',
  PUEDE_AGREGAR_CODEUDOR: 'add_cosigner',
  PUEDE_AGREGAR_INFORMACION_PRODUCTO: 'add_productinformation',
  PUEDE_CAMBIAR_REFERENCIA: 'change_reference',
  PUEDE_CAMBIAR_CONYUGE: 'change_spouse',
  PUEDE_CAMBIAR_CODEUDOR: 'change_cosigner',
  PUEDE_CAMBIAR_INFORMACION_PRODUCTO: 'change_productinformation',
  PUPEDE_CAMBIAR_CREDITO: 'change_credit',
  PUEDE_VER_LOG_CREDITO: 'view_logcredit',
  PUEDE_DESBLOQUEAR_ENTIDADES: 'change_auxcredit',
  PUEDE_ELIMINAR_ENTIDAD: 'delete_entity',
  PUEDE_VER_CREDITOS: 'view_credit',
  ANADIR_CREDITO: 'add_credit',
  CAMBIAR_CONTRASENA: 'set_password',
  ANADIR_ENTRADA_DE_REGISTRO: 'add_logentry',
  PUEDE_CAMBIAR_LA_ENTRADA_DE_REGISTRO: 'change_logentry',
  PUEDE_ELIMINAR_LA_ENTRADA_DE_REGISTRO: 'delete_logentry',
  VER_ENTRADA_DE_REGISTRO: 'view_logentry',
  ANADIR_GRUPO: 'add_group',
  CAMBIAR_DE_GRUPO: 'change_group',
  ELIMINAR_GRUPO: 'delete_group',
  VER_GRUPO: 'view_group',
  ANADIR_PERMISO: 'add_permission',
  MODIFICAR_PERMISO: 'change_permission',
  ELIMINAR_PERMISO: 'delete_permission',
  PUEDE_VER_EL_PERMISO: 'view_permission',
  ANADIR_TIPO_DE_CONTENIDO: 'add_contenttype',
  PUEDE_CAMBIAR_EL_TIPO_DE_CONTENIDO: 'change_contenttype',
  ELIMINAR_TIPO_DE_CONTENIDO: 'delete_contenttype',
  SE_PUEDE_VISUALIZAR_EL_CONTENIDO: 'view_contenttype',
  PUEDE_ANADIR_AGENCIA: 'add_agency',
  PUEDE_CAMBIAR_AGENCIA: 'change_agency',
  PUEDE_ELIMINAR_LA_AGENCIA: 'delete_agency',
  PUEDE_VER_AGENCIA: 'view_agency',
  PUEDE_ANADIR_FATURACION: 'add_billing',
  PUEDE_CAMBIAR_FATURACION: 'change_billing',
  PUEDE_ELIMINAR_FATURACION: 'delete_billing',
  PUEDE_VER_FATURACION: 'view_billing',
  PUEDE_ANADIR_PRESUPUESTO: 'add_budget',
  PUEDE_CAMBIAR_PRESUPUESTO: 'change_budget',
  PUEDE_ELIMINAR_PRESUPUESTO: 'delete_budget',
  PUEDE_VER_PRESUPUESTO: 'view_budget',
  PUEDE_ANADIR_CATEGORIA: 'add_category',
  PUEDE_CAMBIAR_CATEGORIA: 'change_category',
  PUEDE_ELIMINAR_CATEGORIA: 'delete_category',
  PUEDE_VER_CATEGORIA: 'view_category',
  PUEDE_ANADIR_EMPRESA_COMERCIAL: 'add_commercialcompany',
  PUEDE_CAMBIAR_EMPRESA_COMERCIAL: 'change_commercialcompany',
  PUEDE_ELIMINAR_EMPRESA_COMERCIAL: 'delete_commercialcompany',
  PUEDE_VER_EMPRESA_COMERCIAL: 'view_commercialcompany',
  PUEDE_ANADIR_DESCUENTO: 'add_discount',
  PUEDE_CAMBIAR_DESCUENTO: 'change_discount',
  PUEDE_ELIMINAR_DESCUENTO: 'delete_discount',
  PUEDE_VER_DESCUENTO: 'view_discount',
  PUEDE_ANADIR_DESCUENTO_AUXILIAR_DE_FACTURACION: 'add_discountauxquotation',
  PUEDE_CAMBIAR_DESCUENTO_AUXILIAR_DE_FACTURACION:
    'change_discountauxquotation',
  PUEDE_ELIMINAR_DESCUENTO_AUXILIAR_DE_FACTURACION:
    'delete_discountauxquotation',
  PUEDE_VER_DESCUENTO_AUXILIAR_DE_FACTURACION: 'view_discountauxquotation',
  PUEDE_ANADIR_FORMULARIO_DE_FACTURACION: 'add_fieldform',
  PUEDE_CAMBIAR_EL_FORMULARIO_DE_FACTURACION: 'change_fieldform',
  PUEDE_ELIMINAR_FORMULARIO_DE_FACTURACION: 'delete_fieldform',
  PUEDE_VER_FORMULARIO_DE_FACTURACION: 'view_fieldform',
  PUEDE_ANADIR_PRECIO: 'add_price',
  PUEDE_CAMBIAR_EL_PRECIO: 'change_price',
  PUEDE_ELIMINAR_EL_PRECIO: 'delete_price',
  PUEDE_VER_EL_PRECIO: 'view_price',
  PUEDE_ANADIR_PRECIO_AUXILIAR_DE_FACTURACION: 'add_priceauxbilling',
  PUEDE_CAMBIAR_EL_PRECIO_AUXILIAR_DE_FACTURACION: 'change_priceauxbilling',
  PUEDE_ELIMINAR_PRECIO_AUXILIAR_DE_FACTURACION: 'delete_priceauxbilling',
  PUEDE_VER_PRECIO_AUXILIAR_DE_FACTURACION: 'view_priceauxbilling',
  PUEDE_ANADIR_PRECIO_AUXILIAR_DE_COTIZACION: 'add_priceauxquotation',
  PUEDE_CAMBIAR_EL_PRECIO_AUXILIAR_DE_COTIZACION: 'change_priceauxquotation',
  PUEDE_ELIMINAR_PRECIO_AUXILIAR_DE_COTIZACION: 'delete_priceauxquotation',
  PUEDE_VER_PRECIO_AUXILIAR_DE_COTIZACION: 'view_priceauxquotation',
  PUEDE_ANADIR_PRODUCTO: 'add_product',
  PUEDE_CAMBIAR_EL_PRODUCTO: 'change_product',
  PUEDE_ELIMINAR_EL_PRODUCTO: 'delete_product',
  PUEDE_VER_EL_PRODUCTO: 'view_product',
  PUEDE_ANADIR_PRODUCTO_AUXILIAR_DE_FACTURACION: 'add_productauxbilling',
  PUEDE_CAMBIAR_EL_PRODUCTO_AUXILIAR_DE_FACTURACION: 'change_productauxbilling',
  PUEDE_ELIMINAR_EL_PRODUCTO_AUXILIAR_DE_FACTURACION:
    'delete_productauxbilling',
  PUEDE_VER_EL_PRODUCTO_AUXILIAR_DE_FACTURACION: 'view_productauxbilling',
  PUEDE_ANADIR_PERFIL_DE_USUARIO: 'add_profileuser',
  PUEDE_CAMBIAR_EL_PERFIL_DE_USUARIO: 'change_profileuser',
  PUEDE_ELIMINAR_PERFIL_DE_USUARIO: 'delete_profileuser',
  PUEDE_VER_PERFIL_DE_USUARIO: 'view_profileuser',
  PUEDE_ANADIR_COTIZACION: 'add_quotation',
  PUEDE_CAMBIAR_COTIZACION: 'change_quotation',
  PUEDE_ELIMINAR_COTIZACION: 'delete_quotation',
  PUEDE_VER_COTIZACION: 'view_quotation',
  PUEDE_ANADIR_COMENTARIO_DE_COTIZACION: 'add_quotationcomment',
  PUEDE_CAMBIAR_COMENTARIO_DE_COTIZACION: 'change_quotationcomment',
  PUEDE_ELIMINAR_COMENTARIO_DE_COTIZACION: 'delete_quotationcomment',
  PUEDE_VER_COMENTARIO_DE_COTIZACION: 'view_quotationcomment',
  PUEDE_ANADIR_REGISTRO_DE_ESTADO_DE_COTIZACION: 'add_quotationlogstatus',
  PUEDE_CAMBIAR_EL_REGISTRO_DE_ESTADO_DE_COTIZACION:
    'change_quotationlogstatus',
  PUEDE_ELIMINAR_EL_REGISTRO_DE_ESTADO_DE_COTIZACION:
    'delete_quotationlogstatus',
  PUEDE_VER_REGISTRO_DE_ESTADO_DE_COTIZACION: 'view_quotationlogstatus',
  PUEDE_ANADIR_PRODUCTO_DE_COTIZACION: 'add_quotationproductthrough',
  PUEDE_CAMBIAR_EL_PRODUCTO_DE_COTIZACION: 'change_quotationproductthrough',
  PUEDE_ELIMINAR_EL_PRODUCTO_DE_COTIZACION: 'delete_quotationproductthrough',
  PUEDE_VER_EL_PRODUCTO_DE_COTIZACION: 'view_quotationproductthrough',
  PUEDE_ANADIR_ESPECIFICACION: 'add_specification',
  PUEDE_CAMBIAR_ESPECIFICACION: 'change_specification',
  PUEDE_ELIMINAR_ESPECIFICACION: 'delete_specification',
  PUEDE_VER_ESPECIFICACION: 'view_specification',
  PUEDE_ANADIR_SOPORTE: 'add_supportproductbilling',
  PUEDE_CAMBIAR_SOPORTE: 'change_supportproductbilling',
  PUEDE_ELIMINAR_SOPORTE: 'delete_supportproductbilling',
  PUEDE_VER_SOPORTE: 'view_supportproductbilling',
  PUEDE_ANADIR_MOTOCICLETA_USUADA: 'add_usedmotorcycle',
  PUEDE_CAMBIAR_MOTOCICLETA_USUADA: 'change_usedmotorcycle',
  PUEDE_BORRAR_MOTOCICLETA_USUADA: 'delete_usedmotorcycle',
  PUEDE_VER_MOTOCICLETA_USUADA: 'view_usedmotorcycle',
  PUEDE_ANADIR_COMENTARIO_DE_MOTOTA_USA: 'add_usedmotorcyclecomment',
  CAN_CHANGE_COMENTARIO_DE_MOTOTA_USA: 'change_usedmotorcyclecomment',
  PUEDE_ELIMINAR_COMENTARIO_DE_MOTOTA_USA: 'delete_usedmotorcyclecomment',
  PUEDE_VER_COMENTARIO_DE_MOTOTA_USA: 'view_usedmotorcyclecomment',
  PUEDE_ANADIR_REGISTRO_DE_ESTADOS_DE_MOTOCICLETAS_USADAS:
    'add_usedmotorcyclelogstatus',
  PUEDE_CAMBIAR_EL_REGISTRO_DE_ESTADOS_DE_MOTOCICLETAS_USADAS:
    'change_usedmotorcyclelogstatus',
  PUEDE_ELIMINAR_EL_REGISTRO_DE_ESTADOS_DE_MOTOCICLETAS_USADAS:
    'delete_usedmotorcyclelogstatus',
  PUEDE_VER_EL_REGISTRO_DE_ESTADOS_DE_MOTOCICLETAS_USADAS:
    'view_usedmotorcyclelogstatus',
  PUEDE_ANADIR_UNA_AGENCIA_DE_USUARIO_A_TRAVES_DE: 'add_useragencythrough',
  PUEDE_CAMBIAR_LA_AGENCIA_DE_USUARIO_A_TRAVES_DE: 'change_useragencythrough',
  PUEDE_ELIMINAR_LA_AGENCIA_DE_USUARIO_A_TRAVES_DE: 'delete_useragencythrough',
  PUEDE_VER_LA_AGENCIA_DE_USUARIO_A_TRAVES_DE: 'view_useragencythrough',
  PUEDE_ANADIR_ZONA_DE_USUARIO_A_TRAVES_DE: 'add_userzonethrough',
  PUEDE_CAMBIAR_LA_ZONA_DE_USUARIO_A_TRAVES_DE: 'change_userzonethrough',
  PUEDE_ELIMINAR_LA_ZONA_DE_USUARIO_A_TRAVES_DE: 'delete_userzonethrough',
  PUEDE_VER_LA_ZONA_DE_USUARIO_A_TRAVES_DE: 'view_userzonethrough',
  PUEDE_ANADIR_ZONA: 'add_zone',
  PUEDE_CAMBIAR_DE_ZONA: 'change_zone',
  PUEDE_ELIMINAR_ZONA: 'delete_zone',
  PUEDE_VER_LA_ZONA: 'view_zone',
  PUEDE_ANADIR_USUARIO: 'add_customuser',
  PUEDE_CAMBIAR_USUARIO: 'change_customuser',
  PUEDE_ELIMINAR_USUARIO: 'delete_customuser',
  PUEDE_VER_USUARIO: 'view_customuser',
  PUEDE_ANADIR_TOKEN_DE_ACTUALIZACION: 'add_refreshtoken',
  PUEDE_CAMBIAR_EL_TOKEN_DE_ACTUALIZACION: 'change_refreshtoken',
  PUEDE_ELIMINAR_EL_TOKEN_DE_ACTUALIZACION: 'delete_refreshtoken',
  PUEDE_VER_EL_TOKEN_DE_ACTUALIZACION: 'view_refreshtoken',
  ANADIR_SESION: 'add_session',
  PUEDE_CAMBIAR_LA_SESION: 'change_session',
  ELIMINAR_LA_SESION: 'delete_session',
  VER_SESION: 'view_session',
  PUEDE_AGREGAR_UN_CLIENTE: 'add_client',
  CAMBIAR_DE_CLIENTE: 'change_client',
  PUEDE_ELIMINAR_UN_CLIENTE: 'delete_client',
  VER_CLIENTE: 'view_client',
  ANADIR_DOMINIO: 'add_domain',
  CAMBIO_DE_DOMINIO: 'change_domain',
  ELIMINAR_DOMINIO: 'delete_domain',
  PUEDE_VER_EL_DOMINIO: 'view_domain'
}

export const STATUS_QUOATATIONS = [
  ['SIN_INTERES', 'SIN INTERÉS'],
  ['ANULADO', 'ANULADO'],
  ['CONTINUA_INTERESADO', 'CONTINUA INTERESADO'],
  ['EN_FACTURACION', 'EN FACTURACIÓN'],
  ['SIN_ESTADO', 'SIN ESTADO'],
  ['FACTURADO', 'FACTURADO'],
  ['EN_SOLICITUD_DE_CREDITO', 'EN SOLICITUD DE CRÉDITO'],
  ['VENCIDO', 'VENCIDO']
]
export const STATUS_QUOATATIONS_ALIADOS = [
  ['CREADO', 'CREADO'],
  // ['EN_CREDITO', 'EN CRÉDITO'],
  ['FINALIZADO', 'FINALIZADO'],
  ['EN_SOLICITUD_DE_CREDITO', 'EN SOLICITUD DE CRÉDITO']
]

export const STATUS_QUOATATIONS_OBJ = {
  SIN_INTERES: 'SIN INTERÉS',
  CONTINUA_INTERESADO: 'CONTINUA INTERESADO',
  ANULADO: 'ANULADO',
  EN_FACTURACION: 'EN FACTURACIÓN',
  SIN_ESTADO: 'SIN ESTADO',
  FACTURADO: 'FACTURADO',
  EN_SOLICITUD_DE_CREDITO: 'EN SOLICITUD DE CRÉDITO',
  VENCIDO: 'VENCIDO'
}
export const STATUS_BILLINGS = {
  EN_FACTURACION: 'EN FACTURACIÓN',
  FACTURADO: 'FACTURADO'
}

export const QUOTATION_TYPES = {
  CONTADO: 'CONTADO',
  CREDITO: 'CRÉDITO',
  TERCEROS: 'TERCEROS'
}

export const EVENT_SERVICE_WORKER_NAME = 'swUpdated'
export const EVENT_SERVICE_WORKER_RELOAD = 'UpdatedSWReload'
export const MSG_SKIP_WAITING = 'SKIP_WAITING'

export const STATUS_CREDITS_OBJ = {
  APROBADO: 'APROBADO',
  RECHAZADO: 'RECHAZADO',
  PENDIENTE: 'PENDIENTE',
  DESEMBOLSADO: 'DESEMBOLSADO',
  SIN_REVISAR: 'SIN REVISAR',
  ESTUDIO_FORMAL: 'ESTUDIO FORMAL',
  FORMALIZADO: 'FORMALIZADO',
  POR_DESEMBOLSAR: 'POR DESEMBOLSAR',
  PREAPROBADO: 'PREAPROBADO',
  INCOMPLETO: 'INCOMPLETO',
  NO_VIABLE: 'NO VIABLE',
  DESISTIDO: 'DESISTIDO',
  FINALIZADO: 'FINALIZADO'
}

export const ENTITIES_TABS_OBJ = {
  ProductInformation: 'Información de producto',
  Reference: 'Referencias',
  CoSigner: 'Codeudor',
  Spouse: 'Cónyuge'
}

export const STATUS_CREDITS_ARR = [
  { value: 'APROBADO', name: 'Aprobado' },
  { value: 'DESISTIDO', name: 'Desistido' },
  { value: 'INCOMPLETO', name: 'Incompleto' },
  { value: 'PENDIENTE', name: 'Pendiente' },
  { value: 'PREAPROBADO', name: 'Preaprobado' },
  { value: 'NO_VIABLE', name: 'No viable' },
  { value: 'SIN_REVISAR', name: 'Sin revisar' },

  { value: 'RECHAZADO', name: 'Rechazado' },
  { value: 'DESEMBOLSADO', name: 'Desembolsado' },
  { value: 'ESTUDIO_FORMAL', name: 'Estudio formal' },
  { value: 'FORMALIZADO', name: 'Formalizado' },
  { value: 'POR_DESEMBOLSAR', name: 'Por desembolsar' }
]

export const INFO_CURRENCY_OBJ = {
  TYPE: 'currency',
  LOCALE: 'locale',
  FRACTIONS: 'minimumfractiondigits'
}

export const CREDIT_TYPES = {
  INMEDIATO: 'INMEDIATO',
  TRADICIONAL: 'TRADICIONAL'
}
export const ENTITIES_TO_DELETE = {
  CONYUGE: 'CONYUGE',
  CODEUDOR: 'CODEUDOR',
  REFERENCIA: 'REFERENCIA'
}

export const STATUS_FORBBIDEN_CHANGE_ENITIES = [
  STATUS_CREDITS_OBJ.APROBADO,
  STATUS_CREDITS_OBJ.RECHAZADO,
  STATUS_CREDITS_OBJ.PREAPROBADO,
  STATUS_CREDITS_OBJ.DESEMBOLSADO
]
export const STATUS_DISBURSEMENT = {
  CREACION_DE_CARTERA: 'CREACIÓN DE CARTERA',
  PENDIENTE_DE_APROBACION: 'PENDIENTE DE APROBACIÓN',
  POR_DESEMBOLSAR: 'POR DESEMBOLSAR',
  DESEMBOLSADO: 'DESEMBOLSADO'
}
export const STATUS_CERTIFICATES = {
  SIN_REVISAR: 'SIN REVISAR',
  AUTORIZADO: 'AUTORIZADO',
  RECHAZADO: 'RECHAZADO'
}

export const commaDecimalPointThousands = {
  'fr-FR': 'Francia',
  'de-DE': 'Alemania',
  'it-IT': 'Italia',
  'es-ES': 'España',
  'nl-BE': 'Bélgica',
  'lb-LU': 'Luxemburgo',
  'nl-NL': 'Países Bajos',
  'de-AT': 'Austria',
  'de-CH': 'Suiza',
  'pt-PT': 'Portugal',
  'el-GR': 'Grecia',
  'tr-TR': 'Turquía',
  'pt-BR': 'Brasil',
  'es-CO': 'Colombia'
}
export const pointDecimalCommaThousands = {
  'en-US': 'Estados Unidos',
  'en-CA': 'Canadá',
  'en-GB': 'Reino Unido',
  'en-AU': 'Australia',
  'en-NZ': 'Nueva Zelanda',
  'en-IE': 'Irlanda',
  'en-ZA': 'Sudáfrica',
  'en-IN': 'India',
  'zh-CN': 'China',
  'ja-JP': 'Japón',
  'ru-RU': 'Rusia',
  'es-MX': 'México',
  'es-AR': 'Argentina',
  'es-US': 'Ecuador'
}
export const PRICE_TO_DELETE = {
  PRENDA: 'PRENDA'
}

export const QOUTA_STATUS = {
  PAGADA: 'PAGADA',
  PARCIALMENTE_PAGADA: 'PARCIALMENTE PAGADA',
  'EN MORA': 'EN MORA',
  PENDIENTE: 'PENDIENTE',
  ACTIVA: 'ACTIVA',
  REFINANCIADA: 'REFINANCIADA',
  PAGADA_CON_DESCUENTO: 'PAGADA CON DESCUENTO'
}

export const ICONS_STATUS = {
  [STATUS_CREDITS_OBJ.APROBADO]: ['mdi-check-circle-outline', 'success'],
  [STATUS_CREDITS_OBJ.DESEMBOLSADO]: ['mdi-cash-check', 'info'],
  [STATUS_CREDITS_OBJ.ESTUDIO_FORMAL]: ['mdi-format-list-checks', 'info'],
  [STATUS_CREDITS_OBJ.FORMALIZADO]: ['mdi-folder-information-outline', 'info'],
  [STATUS_CREDITS_OBJ.PENDIENTE]: ['mdi-timer-sand-complete', 'warning'],
  [STATUS_CREDITS_OBJ.POR_DESEMBOLSAR]: ['mdi-clock-alert-outline', 'warning'],
  [STATUS_CREDITS_OBJ.PREAPROBADO]: ['mdi-progress-check', 'info'],
  [STATUS_CREDITS_OBJ.RECHAZADO]: ['mdi-cancel', 'danger'],
  [STATUS_CREDITS_OBJ.SIN_REVISAR]: ['mdi-email-alert-outline', 'dark'],
  [STATUS_CREDITS_OBJ.INCOMPLETO]: ['mdi-puzzle-outline', 'warning'],
  [STATUS_CREDITS_OBJ.NO_VIABLE]: ['mdi-alert-remove', 'danger'],
  [STATUS_CREDITS_OBJ.DESISTIDO]: ['mdi-hand-back-right-off', 'danger'],
  [STATUS_CREDITS_OBJ.FINALIZADO]: ['mdi-calendar-check', 'warning']
}

export const INFO_ARR_KEYS_WALLET_RESUME = [
  {
    key: 'capital',
    description: 'Lo que se adeuda de capital actualmente',
    icon: 'ri-money-dollar-circle-line',
    color: 'info',
    name: 'Capital'
  },
  {
    key: 'endorsement',
    description: 'Lo que se adeuda de Aval',
    icon: 'mdi mdi-handshake-outline',
    color: 'warning',
    name: 'Aval'
  },
  {
    key: 'interest',
    description: 'Lo que se adeuda de intereses',
    icon: 'mdi mdi-hand-coin-outline',
    color: 'danger',
    name: 'Intereses'
  },
  {
    key: 'paymentManagement',
    description: 'Lo que se adeuda de gestión de cobro',
    icon: 'ri-coins-fill', // Agrega aquí el icono correspondiente si es necesario
    color: 'info', // Agrega aquí el color correspondiente si es necesario
    name: 'Gestión cobro'
  },
  {
    key: 'penaltyInterest',
    description: 'Lo que se adeuda de intereses de mora',
    icon: 'ri-hand-coin-line', // Agrega aquí el icono correspondiente si es necesario
    color: 'danger', // Agrega aquí el color correspondiente si es necesario,
    name: 'Mora'
  },
  {
    key: 'total',
    description: 'Valor total de lo que se adeuda',
    icon: 'ri-currency-line', // Agrega aquí el icono correspondiente si es necesario
    color: 'success', // Agrega aquí el color correspondiente si es necesario,
    name: 'Total'
  }
]

export const STORAGE_NAMES = { TYPE_COTIZ: 'type-cotiz' }

export const TYPES_COTIZ_CREATE = {
  MOTOCICLETAS: 'MOTOCICLETAS',
  LIBRE_INVERSION: 'LIBRE_INVERSION'
}

export const COLORS_CREDIT_LINE = {
  MOTOCICLETAS: 'success',
  LIBRE_INVERSION: 'info'
}
