/* eslint-disable react/jsx-indent */
import React, { useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  Row,
  TabPane,
  UncontrolledCollapse
} from 'reactstrap'
import { parseId } from '../../../helpers'
import moment from 'moment'
import { ModalAddComment } from './ModalAddComment'

export const TabComments = ({
  tabId,
  comments,
  refetchDataCredit,
  creditId
}) => {
  const [showComment, setShowComment] = useState(false)

  const toggleShowComment = () => {
    setShowComment(false)
  }

  /** @param {string} url */
  const getIcon = (url) => {
    if (!url) return 'mdi-file-outline'
    const imgs = [
      'bmp',
      'gif',
      'jpg',
      'jpge',
      'tif',
      'png',
      'svg',
      'jpeg',
      'tiff'
    ]
    const excel = ['xlsx', 'xlsm', 'xlsb', 'xltx']
    try {
      const { pathname: pathFull } = new URL(url)
      if (!pathFull) return 'mdi-file-outline'
      const pathname = pathFull.split('.')[1]
      // console.log('pathname', pathname)
      if (imgs.includes(pathname.toLowerCase())) return 'mdi-image-outline'
      if (pathname.toLowerCase().includes('pdf')) {
        return 'mdi-file-document-outline'
      }
      if (excel.includes(pathname.toLowerCase())) return 'mdi-microsoft-excel'
      if (pathname.toLowerCase().includes('docx')) return 'mdi-microsoft-word'
      return 'mdi-file-outline'
    } catch (error) {
      return 'mdi-file-outline'
    }
  }

  /** @param {string} url */
  const getColorBg = (url) => {
    if (!url) return 'info'
    const imgs = [
      'bmp',
      'gif',
      'jpg',
      'jpge',
      'tif',
      'png',
      'svg',
      'jpeg',
      'tiff'
    ]
    const excel = ['xlsx', 'xlsm', 'xlsb', 'xltx']
    try {
      const { pathname: pathFull } = new URL(url)
      if (!pathFull) return 'info'
      const pathname = pathFull.split('.')[1]
      if (imgs.includes(pathname.toLowerCase())) return 'dark'
      if (pathname.toLowerCase().includes('pdf')) {
        return 'danger'
      }
      if (excel.includes(pathname.toLowerCase())) return 'success'
      if (pathname.toLowerCase().includes('docx')) return 'info'
      return 'info'
    } catch (error) {
      return 'info'
    }
  }

  const handleClickDown = ({ name, file }) => {
    const link = document.createElement('a')
    link.href = file
    link.download = name
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <>
      <TabPane tabId={tabId}>
        <Card>
          <CardBody>
            <div className='d-flex justify-content-between align-items-center'>
              <h5 className='card-title mb-3'>Comentarios</h5>
              <Button
                className='d-flex align-items-center gap-1'
                onClick={() => setShowComment(true)}
              >
                <i className='mdi mdi-comment-plus-outline' />
                Añadir comentario
              </Button>
            </div>
            <div className='profile-timeline'>
              <div />
              <div className='accordion accordion-flush' id='todayExample'>
                {comments.map(({ node: nodeComment }) => (
                  <div className='accordion-item border-0' key={nodeComment.id}>
                    <div className='accordion-header' id='headingTwo'>
                      <button
                        className='accordion-button p-2 shadow-none'
                        id={`collapseTwo-${parseId(nodeComment.id)}`}
                      >
                        <div className='d-flex'>
                          <div className='flex-shrink-0 avatar-xs'>
                            <div className='avatar-title bg-light text-success rounded-circle'>
                              {nodeComment.user.firstName.charAt(0)}
                            </div>
                          </div>
                          <div className='flex-grow-1 ms-3'>
                            <h6 className='fs-14 mb-1'>
                              {nodeComment.user.firstName}{' '}
                              {nodeComment.user.lastName}
                            </h6>
                            <small
                              className='text-muted'
                              title={`Este comentario se realizó el ${moment(
                                nodeComment.created
                              ).format('DD MMMM [del] YYYY')} a las ${moment(
                                nodeComment.created
                              ).format('HH:mm A')}`}
                            >
                              {moment(nodeComment.created).format('DD MMMM')} -{' '}
                              {moment(nodeComment.created).format('HH:mm A')}
                            </small>
                          </div>
                        </div>
                      </button>
                    </div>
                    <UncontrolledCollapse
                      toggler={`collapseTwo-${parseId(nodeComment.id)}`}
                      defaultOpen
                    >
                      <div className='accordion-body ms-2 ps-5'>
                        <div className='mb-2 fs-6' style={{ color: '#111' }}>
                          {nodeComment.comment}
                        </div>
                        {nodeComment.creditsupportwalletSet.edges.length >
                          0 && (
                          <Row className='g-2'>
                            {nodeComment.creditsupportwalletSet.edges.map(
                              ({ node: { file, id, name } }) => (
                                <div key={id} className='col-6'>
                                  <div className='d-flex border border-dashed p-2 rounded position-relative'>
                                    <div
                                      className='flex-shrink-0'
                                      style={{ margin: 'auto' }}
                                    >
                                      <i
                                        className={`mdi ${getIcon(
                                          file
                                        )} fs-4 text-${getColorBg(file)}`}
                                      />
                                    </div>
                                    <div className='flex-grow-1 ms-2'>
                                      <h6>
                                        <div className='stretched-link'>
                                          {name}
                                        </div>
                                      </h6>
                                      <button
                                        onClick={() => {
                                          handleClickDown({
                                            file,
                                            name
                                          })
                                        }}
                                        style={{
                                          zIndex: 3
                                        }}
                                        className='bg-transparent border-0 btn-hover-credit'
                                      >
                                        Descargar
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </Row>
                        )}
                      </div>
                    </UncontrolledCollapse>
                  </div>
                ))}
                {comments.length === 0 && (
                  <div
                    className='text-center d-flex align-items-center justify-content-center'
                    style={{ height: '30vh' }}
                  >
                    <p>No hay comentarios para este crédito</p>
                  </div>
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      </TabPane>

      <ModalAddComment
        refetchDataCredit={refetchDataCredit}
        toggle={toggleShowComment}
        showComment={showComment}
        creditId={creditId}
      />
    </>
  )
}
