import { STATUS_QUOATATIONS_OBJ } from '../../../helpers/consts'

export const BadgeQuotation = ({ status }) => {
  switch (STATUS_QUOATATIONS_OBJ[status]) {
    case STATUS_QUOATATIONS_OBJ.SIN_ESTADO:
      return (
        <span className='badge badge-soft-dark text-uppercase'>
          {status.replace(/_/g, ' ')}
        </span>
      )
    case STATUS_QUOATATIONS_OBJ.FACTURADO:
      return (
        <span className='badge badge-soft-success text-uppercase'>
          {status.replace(/_/g, ' ')}
        </span>
      )
    case STATUS_QUOATATIONS_OBJ.ANULADO:
      return (
        <span className='badge badge-soft-danger text-uppercase'>
          {status.replace(/_/g, ' ')}
        </span>
      )
    case STATUS_QUOATATIONS_OBJ.SIN_INTERES:
      return (
        <span className='badge badge-soft-warning text-uppercase'>
          {status.replace(/_/g, ' ')}
        </span>
      )
    case STATUS_QUOATATIONS_OBJ.EN_FACTURACION:
      return (
        <span className='badge badge-soft-info text-uppercase'>
          {status.replace(/_/g, ' ')}
        </span>
      )
    case STATUS_QUOATATIONS_OBJ.EN_SOLICITUD_DE_CREDITO:
      return (
        <span className='badge badge-soft-info text-uppercase'>
          {status.replace(/_/g, ' ')}
        </span>
      )
    case STATUS_QUOATATIONS_OBJ.VENCIDO:
      return (
        <span className='badge badge-soft-danger text-uppercase'>
          {status.replace(/_/g, ' ')}
        </span>
      )
    case STATUS_QUOATATIONS_OBJ.CONTINUA_INTERESADO:
      return (
        <span className='badge badge-soft-info text-uppercase'>
          {status.replace(/_/g, ' ')}
        </span>
      )
    default:
      return (
        <span className='badge badge-soft-dark text-uppercase'>
          {status.replace(/_/g, ' ')}
        </span>
      )
  }
}
