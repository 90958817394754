import { useMutation, useQuery } from '@apollo/client'
import React, { createContext, useEffect, useRef, useState } from 'react'
import { VERIFY_TOKEN } from '../graphql/mutations/login'
import { GET_ALL_TYPE_COTIZ } from '../graphql/queries/getCotizaciones'
import { STORAGE_NAMES } from '../helpers/consts'

export const userContext = createContext()

const INIT_USER = {
  user: null,
  checking: 'loading',
  rol: null,
  permissions: [],
  infoZoneOrAgencie: {
    showModal: false,
    zoneOrAgency: null
  },
  notifications: [],
  totalNotifications: 0
}

const UserProvider = ({ children }) => {
  const [gqlVerify, { data, loading, error }] = useMutation(VERIFY_TOKEN)
  const { data: dataTypeCotiz } = useQuery(GET_ALL_TYPE_COTIZ)
  const render = useRef(0)

  /**
   * @typedef {Object} IData
   * @property {{token: string, name: string, settings: string,payload: {email: string, exp: number, origIat: string},refreshToken: string, refreshExpiresIn: number, rol: string, permissions: string[] } tokenAuth}
   */
  /**
   * @typedef {{message: string, url: string, title: string, isReaded: boolean, id: number}} INotification
   * @typedef {INotification[]} INotifications
   */

  /**
   * @type {[{user: null | IData, checking: "loading" | "success" | "no-user", rol: null | string, permissions: string[], infoZoneOrAgencie: {showModal: boolean, zoneOrAgency: string | null}, notifications: INotifications, totalNotifications: number}, React.Dispatch<React.SetStateAction<{user: null | IData, checking: "loading" | "success" | "no-user", rol: null | string, permissions: string[], infoZoneOrAgencie: {showModal: boolean, zoneOrAgency: string | null}, notifications: INotifications, totalNotifications: number}>>]}
   */
  const [state, setState] = useState(INIT_USER)
  /**
   * @param {IData} data
   */
  const login = (data) => {
    if (!data || !data.tokenAuth) return
    setState((prev) => ({
      ...prev,
      user: data,
      checking: 'success',
      rol: data.tokenAuth.rol,
      permissions: data.tokenAuth.permissions,
      infoZoneOrAgencie: {
        showModal: false,
        zoneOrAgency: data.tokenAuth.current?.data?.name
          ? `${data.tokenAuth.current?.data?.name}***${data.tokenAuth.current?.data?.id}`
          : undefined
      }
    }))
    window.localStorage.setItem('rol', JSON.stringify(data.tokenAuth.rol))
    window.localStorage.setItem(
      'permissions',
      JSON.stringify(data.tokenAuth.permissions)
    )
    window.localStorage.setItem('token', JSON.stringify(data.tokenAuth.token))
    window.localStorage.setItem('user', JSON.stringify(data.tokenAuth))
    window.localStorage.setItem(
      'infoZoneOrAgency',
      JSON.stringify(
        data.tokenAuth.current?.data?.name
          ? `${data.tokenAuth.current?.data?.name}***${data.tokenAuth.current?.data?.id}`
          : ''
      )
    )
    window.localStorage.setItem('money', data.tokenAuth.settings)
  }
  const logout = () => {
    setState({ ...INIT_USER, checking: 'no-user' })
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('user')
    window.localStorage.removeItem('rol')
    window.localStorage.removeItem('permissions')
    window.localStorage.removeItem('infoZoneOrAgency')
    window.localStorage.removeItem('money')
  }
  /**
   *  @param {string} rol
   * @param {string[]} permissions
   */
  const changeRolAndPermissions = (rol, permissions) => {
    window.localStorage.setItem('rol', JSON.stringify(rol))
    window.localStorage.setItem('permissions', JSON.stringify(permissions))
    // console.log('state', state)
    setState((prev) => ({ ...prev, rol, permissions }))
  }

  const openModalChangeZoneOrAgencie = (isReset = false) => {
    if (isReset) {
      setState((prev) => ({
        ...prev,
        infoZoneOrAgencie: {
          showModal: true,
          zoneOrAgency: null
        }
      }))
    } else {
      setState((prev) => ({
        ...prev,
        infoZoneOrAgencie: {
          ...prev.infoZoneOrAgencie,
          showModal: true
        }
      }))
    }
  }

  const closeModalChangeZoneOrAgencie = (isForce = false) => {
    if (isForce) {
      setState((prev) => ({
        ...prev,
        infoZoneOrAgencie: {
          ...prev.infoZoneOrAgencie,
          showModal: false
        }
      }))
      return
    }
    if (state.infoZoneOrAgencie.zoneOrAgency) {
      setState((prev) => ({
        ...prev,
        infoZoneOrAgencie: {
          ...prev.infoZoneOrAgencie,
          showModal: false
        }
      }))
    }
  }

  const updateOptionAgencieOrZone = (info) => {
    const toStorage = info ?? ''
    window.localStorage.setItem('infoZoneOrAgency', JSON.stringify(toStorage))
    setState((prev) => ({
      ...prev,
      infoZoneOrAgencie: {
        ...prev.infoZoneOrAgencie,
        zoneOrAgency: info
      }
    }))
  }

  const loadAllNotifications = (allNotifications) => {
    setState((prev) => ({
      ...prev,
      notifications: allNotifications
    }))
  }
  const updateNotifications = (notification) => {
    setState((prev) => ({
      ...prev,
      notifications: [notification, ...prev.notifications]
    }))
  }
  const setTotalNotifications = (num) => {
    setState((prev) => ({ ...prev, totalNotifications: num }))
  }
  const incDecTotalNotifications = (str) => {
    const num = str === 'plus' ? 1 : str === 'subtract' ? -1 : 0
    console.log(
      'num + state.totalNotifications, state.totalNotifications',
      num + state.totalNotifications,
      state.totalNotifications
    )
    console.log('num', num)
    setState((prev) => ({
      ...prev,
      totalNotifications: prev.totalNotifications + num
    }))
  }

  const updateOneNotificationReaded = (id) => {
    setState((prev) => {
      const arrNoti = [...prev.notifications]
      const oneNoti = arrNoti.find((oneN) => Number(oneN.id) === Number(id))
      oneNoti.isReaded = true
      return {
        ...prev,
        notifications: arrNoti
      }
    })
  }

  const updateMonthlyFeeSetting = (monthlyFee) => {
    setState((p) => {
      const newUser = { ...p }
      const prevUser = newUser.user
      const { settings } = prevUser.tokenAuth
      const objSetting = JSON.parse(settings)
      objSetting.monthly_fee = monthlyFee?.toString()
      prevUser.tokenAuth.settings = JSON.stringify(objSetting)
      window.localStorage.setItem(
        'user',
        JSON.stringify(newUser.user.tokenAuth)
      )
      return newUser
    })
  }

  useEffect(() => {
    const tokenParse = window.localStorage.getItem('token')
    if (!tokenParse) {
      return setState({ ...INIT_USER, checking: 'no-user' })
    }
    const token = JSON.parse(tokenParse)

    if (render.current === 0) {
      render.current = 1
      gqlVerify({
        variables: {
          token
        }
      })
    }
  }, [])

  useEffect(() => {
    if (loading) {
      setState({
        ...INIT_USER,
        checking: 'loading'
      })
    }
  }, [loading])

  useEffect(() => {
    if (data) {
      if (data.verifyToken && data.verifyToken.payload) {
        const date = data.verifyToken.payload.exp

        const fechaActual = Date.now()

        // Obtiene la fecha de vencimiento del token en milisegundos (suponiendo que el valor proporcionado es en segundos)
        const fechaVencimientoToken = date * 1000

        // Calcula la diferencia en milisegundos entre la fecha de vencimiento y la fecha actual
        const diferencia = fechaVencimientoToken - fechaActual

        // Convierte la diferencia en minutos
        const minutosRestantes = Math.floor(diferencia / (1000 * 60))

        if (minutosRestantes <= 30) {
          return logout()
        }

        const rol = window.localStorage.getItem('rol')
        const permissions = window.localStorage.getItem('permissions')
        const user = window.localStorage.getItem('user')
        const infoZoneOrAgencie =
          window.localStorage.getItem('infoZoneOrAgency')
        setState({
          checking: 'success',
          user: { tokenAuth: JSON.parse(user) },
          permissions: JSON.parse(permissions),
          rol: JSON.parse(rol),
          infoZoneOrAgencie: {
            showModal: false,
            zoneOrAgency: JSON.parse(infoZoneOrAgencie)
          },
          totalNotifications: 0
        })
      } else {
        logout()
      }
    }
  }, [data])

  useEffect(() => {
    if (error) {
      console.log('error', error)
      setState({
        user: null,
        checking: 'no-user',
        permissions: null,
        rol: null
      })

      window.localStorage.removeItem('token')
      window.localStorage.removeItem('refreshToken')
    }
  }, [error])

  useEffect(() => {
    if (!dataTypeCotiz) return

    const arr = dataTypeCotiz?.listCreditLine?.edges || []

    const strArr = JSON.stringify(arr)

    window.localStorage.setItem(STORAGE_NAMES.TYPE_COTIZ, strArr)
  }, [dataTypeCotiz])

  return (
    <userContext.Provider
      value={{
        ...state,
        login,
        logout,
        changeRolAndPermissions,
        openModalChangeZoneOrAgencie,
        closeModalChangeZoneOrAgencie,
        updateOptionAgencieOrZone,
        updateNotifications,
        loadAllNotifications,
        updateOneNotificationReaded,
        setTotalNotifications,
        incDecTotalNotifications,
        updateMonthlyFeeSetting
      }}
    >
      {children}
    </userContext.Provider>
  )
}

export default UserProvider
