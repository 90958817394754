import React, { useState } from 'react'
import moment from 'moment'
import classnames from 'classnames'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap'
import imgEmpty from '../../../assets/images/totalsales/empty.svg'
import { OneObligationInactive } from './OneObligationInactive'
import { TabViewPayments } from './TabViewPayments'
import { TabViewAbonosCapitalInactive } from './TabViewAbonosCapitalInactive'
import { parsePrice } from '../../../helpers'

/**
 *
 * @param {{obligations: {node: {id: string; value: string; term: string; montlyFee: string; endorsement: string; tea: string; initialDate: string; endDate: string; status: string; obligationdiscountSet: {edges: {node:{id: string, description: string, value: string}}[]}}}[]}} param0
 * @returns {JSX.Element}
 */
export const TabInactiveOblg = ({ obligations }) => {
  const [tab, setTab] = useState(-1)
  const [customActiveTab, setCustomActiveTab] = useState('1')

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab)
    }
  }

  if (obligations.length === 0) return null

  return (
    <Card>
      <CardHeader>
        <div className='d-flex align-items-center gap-1 justify-content-between flex-column flex-md-row w-100'>
          <CardTitle>Obligaciones inactivas</CardTitle>
          <div>
            <Input
              id='exampleSelect'
              name='select'
              type='select'
              value={tab}
              onChange={(e) => {
                setTab(Number(e.target.value))
                setCustomActiveTab('1')
              }}
            >
              <option value={-1}>Seleccione una obligacion</option>
              {obligations.map(({ node }, idx) => (
                <option key={node.id} value={idx}>
                  {moment(node.initialDate).format('DD MMMM YYYY')} -{' '}
                  {moment(node.endDate).format('DD MMMM YYYY')}
                </option>
              ))}
            </Input>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        {obligations[tab] == null ? (
          <div className='w-100 d-flex flex-column justify-content-center align-items-center gap-1'>
            <p className='text-center'>
              Seleccione una obligación para ver su información
            </p>
            <div>
              <img
                src={imgEmpty}
                style={{ width: '100%', maxWidth: '400px' }}
              />
            </div>
          </div>
        ) : (
          <>
            <Nav
              tabs
              className='nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3'
            >
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: customActiveTab === '1'
                  })}
                  onClick={() => {
                    toggleCustom('1')
                  }}
                >
                  Resumen
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: customActiveTab === '4'
                  })}
                  onClick={() => {
                    toggleCustom('4')
                  }}
                >
                  Ver pagos
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: customActiveTab === '2'
                  })}
                  onClick={() => {
                    toggleCustom('2')
                  }}
                >
                  Descuentos
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({
                    active: customActiveTab === '3'
                  })}
                  onClick={() => {
                    toggleCustom('3')
                  }}
                >
                  Ver abonos a capital
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={customActiveTab} className='text-muted'>
              <TabPane tabId='1' id='home1'>
                <div className='p-4'>
                  <OneObligationInactive obligation={obligations[tab]} />
                </div>
              </TabPane>
              {customActiveTab === '4' && (
                <TabPane tabId='4'>
                  <TabViewPayments
                    activeTab={customActiveTab}
                    obligationId={obligations[tab].node.id}
                  />
                </TabPane>
              )}
              {customActiveTab === '3' && (
                <TabPane tabId='3'>
                  <TabViewAbonosCapitalInactive
                    obligationId={obligations[tab].node.id}
                  />
                </TabPane>
              )}
              {customActiveTab === '2' &&
                (obligations[tab].node.obligationdiscountSet.edges.length >
                0 ? (
                  <Row>
                    {obligations[tab].node.obligationdiscountSet.edges.map(
                      ({ node }) => (
                        <Col key={node.id}>
                          <Card className='card-animate'>
                            <CardBody>
                              <div className='d-flex justify-content-between'>
                                <div>
                                  <p className='fw-medium text-muted mb-0'>
                                    {moment(node.created).format(
                                      'DD MMM [de] YYYY [a las] hh:mm A'
                                    )}
                                  </p>
                                  <h2 className='mt-4 ff-secondary fw-semibold'>
                                    <span className='counter-value'>
                                      {parsePrice(node.value)}
                                    </span>
                                  </h2>
                                  <p className='mb-0 text-muted'>
                                    {node.description}
                                  </p>
                                </div>
                                <div>
                                  <div className='avatar-sm flex-shrink-0'>
                                    <span className='avatar-title bg-soft-info rounded-circle fs-2'>
                                      <i
                                        className='mdi mdi-clock-edit-outline'
                                        style={{ color: 'inherit' }}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      )
                    )}
                  </Row>
                ) : (
                  <Row>
                    <Col>
                      <Card className='card-animate'>
                        <CardBody>
                          <div className='d-flex justify-content-between'>
                            <div>
                              <p className='fw-medium text-muted mb-0'>
                                Sin descuentos
                              </p>
                              <h2 className='mt-4 ff-secondary fw-semibold'>
                                <span className='counter-value'>
                                  Esta obligación no tiene descuentos
                                </span>
                              </h2>
                            </div>
                            <div>
                              <div className='avatar-sm flex-shrink-0'>
                                <span className='avatar-title bg-soft-danger rounded-circle fs-2'>
                                  <i
                                    className='mdi mdi-alert-circle-outline'
                                    style={{ color: 'inherit' }}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                ))}
            </TabContent>
          </>
        )}
      </CardBody>
    </Card>
  )
}
