import React, { useState } from 'react'
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Spinner
} from 'reactstrap'
import illustarator1 from '../../assets/images/user-illustarator-1.png'

export const OffCanvaSettings = () => {
  const [openOffcanva, setOpenOffcanva] = useState(false)
  const [loading, setLoading] = useState(false)
  const toggleOffcanvaSetting = () => setOpenOffcanva((p) => !p)
  const handleClick = () => {
    /* const NAMES_LOCALSTORAGE = [
      'permissions',
      'infoZoneOrAgency',
      'user',
      'money',
      'token',
      'rol'
    ] */
    if ('serviceWorker' in window.navigator) {
      setLoading(true)
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (const registration of registrations) {
          registration.unregister()
        }
        // eslint-disable-next-line no-undef
        caches.keys().then(function (kl) {
          kl.forEach(function (key) {
            // eslint-disable-next-line no-undef
            caches.delete(key)
          })
          /* NAMES_LOCALSTORAGE.forEach((name) =>
          window.localStorage.removeItem(name)
        ) */
          setLoading(false)
          window.location.reload()
        })
      })
    }
  }
  return (
    <>
      <Button
        onClick={toggleOffcanvaSetting}
        type='button'
        className='btn btn-icon btn-topbar btn-ghost-secondary rounded-circle'
        title='Mantenimiento'
      >
        <i className='mdi mdi-cog-outline fs-22' />
      </Button>
      <Offcanvas
        direction='end'
        toggle={toggleOffcanvaSetting}
        isOpen={openOffcanva}
        className='bg-ligth'
      >
        <OffcanvasHeader toggle={toggleOffcanvaSetting}>
          Mantenimiento
        </OffcanvasHeader>
        <OffcanvasBody>
          <Card>
            <CardBody className='p-0'>
              <Alert
                color='danger'
                className='rounded-top alert-solid alert-label-icon border-0 rounded-0 m-0 d-flex align-items-center'
              >
                <i className='ri-error-warning-line label-icon' />
                <div className='flex-grow-1 text-truncate'>
                  Forzar la actualización
                </div>
              </Alert>

              <Row className='align-items-end'>
                <Col sm={8}>
                  <div className='p-3'>
                    <p className='fs-16 lh-base '>
                      Esta acción descargará la última versión de
                      <span className='fw-semibold ms-2'>Total Sales</span>{' '}
                      disponible.
                      <i className='mdi mdi-arrow-right' />
                    </p>
                    <div className='mt-3'>
                      <Button
                        to='/pages-pricing'
                        className='btn btn-info d-flex align-items-center gap-1'
                        onClick={handleClick}
                        disabled={loading}
                      >
                        {loading && <Spinner size='sm' color='light' />}
                        Actualizar
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className='px-3'>
                    {loading ? (
                      <Spinner
                        color='dark'
                        style={{ width: '3em', height: '3em', margin: '1em 0' }}
                      />
                    ) : (
                      <img src={illustarator1} className='img-fluid' alt='' />
                    )}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </OffcanvasBody>
      </Offcanvas>
    </>
  )
}
