import { gql } from '@apollo/client'

/**
 * @param {{name: string, nit: string, address: string, phoneNumber: string, id?: string, crm: boolean,image: File | null}}
 * @returns {string}
 */
export const CREATE_EMPRESA_COMERCIAL = ({
  name,
  nit,
  address,
  phoneNumber,
  id,
  image,
  crm
}) => `
  {
    "query": "mutation commercialCompanyCreateUpdate($input: CommercialCompanyInput!){createUpdateCommercialCompany(input:$input){commercialCompany{address created image}errors{field message}}}",
    "variables": {
      "input": {
        ${id ? `"id": ${id},` : ''}
        "nit": "${nit}",
        "name": "${name}",
        "address": "${address}",
        "phoneNumber": "${phoneNumber}"
        "crm": ${crm}
        ${image ? ',"image": null' : ''}
      }
    }
  }
`
export const CREATE_UPDATE_EMP_COMERCIAL_STR = `
  mutation commercialCompanyCreateUpdate($input: CommercialCompanyInput!) {
    createUpdateCommercialCompany(input: $input) {
      commercialCompany {
        id
      }
      errors {
        field
        message
      }
    }
  }
`

export const CREATE_ZONA = gql`
  mutation zoneCreateUpdate($input: ZoneCreateUpdateMutationInput!) {
    zoneCreateUpdate(input: $input) {
      clientMutationId
      errors {
        field
        messages
      }
      zone {
        commercialCompany {
          name
        }
        created
        description
        id
        name
        updated
      }
    }
  }
`

export const CREATE_AGENCIA = gql`
  mutation agencyCreateUpdate($input: AgencyCreateUpdateMutationInput!) {
    agencyCreateUpdate(input: $input) {
      agency {
        id
        name
      }
      clientMutationId
      errors {
        field
        messages
      }
    }
  }
`
