import { useMutation } from '@apollo/client'
import React, { useEffect } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner
} from 'reactstrap'
import { CHANGE_CREDIT_TRADICIONAL } from '../../../graphql/mutations/creditMutations'
import { toast } from 'react-toastify'
import { parseId } from '../../../helpers'
export const ModalChangeTrad = ({ isOpen, creditId, refetch, onClose }) => {
  const [
    gqlChangeTrad,
    { data: dataChange, loading: loadingChange, error: errorChange }
  ] = useMutation(CHANGE_CREDIT_TRADICIONAL)

  useEffect(() => {
    if (!dataChange) return
    if (dataChange?.changeCreditType?.success) {
      toast.success('Crédito actualizado con éxito')
      refetch()
      onClose()
    } else if (dataChange?.changeCreditType?.errors) {
      toast.error(
        'ERROR: ' + dataChange?.changeCreditType?.errors.message ||
          'No se pudo actualizar crédito'
      )
    }
  }, [dataChange])

  useEffect(() => {
    if (!errorChange) return
    if (errorChange) {
      toast.error(`ERROR: ${errorChange.message}`)
    }
  }, [errorChange])

  const handleChangoTrad = () => {
    if (!creditId) return
    gqlChangeTrad({
      variables: {
        input: {
          creditId: parseId(creditId)
        }
      }
    })
  }
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalHeader className='bg-soft-info p-4' toggle={onClose}>
        Cerrar crédito
      </ModalHeader>
      <ModalBody>
        <p className='fw-semibold fs-4'>
          ¿Seguro que desea cambiar el crédito a tradicional?
        </p>
      </ModalBody>
      <ModalFooter className='d-flex align-items-center justify-content-center gap-2'>
        <Button color='light' className='flex-1 text-center' onClick={onClose}>
          No
        </Button>
        <Button
          color='danger'
          className='flex-1 text-center d-flex gap-1 align-items-center justify-content-center'
          onClick={handleChangoTrad}
        >
          {loadingChange && <Spinner size='sm' color='light' />}
          SI
        </Button>
      </ModalFooter>
    </Modal>
  )
}
