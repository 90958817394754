import { gql } from '@apollo/client'

export const GET_ALL_CREDITS = gql`
  query creditList(
    $first: Int
    $after: String
    $id: Float
    $status: String
    $documentNumber: String
    $agencyId: Float
    $createdGte: DateTime
    $createdLte: DateTime
    $uuid: UUID
    $name: String
    $offset: Int
    $analyst_Id: Float
    $creditType: String
    $preapproved: Boolean
    $closed: Boolean
    $creditLine_Id: Float
  ) {
    creditList(
      first: $first
      offset: $offset
      after: $after
      id: $id
      status_Iexact: $status
      documentNumber: $documentNumber
      agency_Id: $agencyId
      created_Gte: $createdGte
      created_Lte: $createdLte
      uuid: $uuid
      name: $name
      analyst_Id: $analyst_Id
      creditType: $creditType
      preapproved: $preapproved
      closed: $closed
      creditLine_Id: $creditLine_Id
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          id
          creditType
          status
          firstName
          firstSurname
          secondName
          secondSurname
          uuid
          documentNumber
          created
          updated
          closedDate

          quotaValue
          initialFee
          term
          productValue
          preapproved

          creditLine {
            id
            name
            legibleName
          }

          agency {
            id
            name
          }
          analyst {
            firstName
            lastName
          }
          user {
            firstName
            lastName
          }
        }
      }
    }
  }
`
export const GET_AGENCIAS_TO_CREDITS = gql`
  query listAgency($id: Float) {
    listAgency(id: $id) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`
export const GET_ONE_CREDIT = gql`
  query getCredit($creditId: Int, $uuid: UUID) {
    getCredit(creditId: $creditId, uuid: $uuid) {
      agency {
        name
        zone {
          commercialCompany {
            image
            name
          }
        }
      }
      dateBirth
      documentNumber
      documentType
      email
      expeditionDate
      firstName
      firstSurname
      id
      phoneNumber
      secondName
      secondSurname
      status
      uuid
      firmaAutorizacion
      creditType

      monthlyFee

      quotaValue
      initialFee
      term
      productValue

      closedDate
      descriptionSolucredit
      statusSolucredit
      preapproved
      notified

      centrales
      centralesJson

      oldData

      guarantorRequired

      creditLine {
        id
        name
        legibleName
      }

      logcreditSet {
        edges {
          node {
            description
            title
            created
            id

            user {
              firstName
              lastName
            }
          }
        }
      }

      quotation {
        id
        status
      }
      disbursementrequest {
        id
        status
      }
      supportSet {
        edges {
          node {
            id
            name
            file
            created
            deleted
          }
        }
      }
      creditcommentSet {
        edges {
          node {
            id
            comment
            created

            oldStatus
            newStatus

            user {
              firstName
              lastName
              email
            }
            supportSet {
              edges {
                node {
                  file
                  id
                  name
                  deleted
                }
              }
            }
          }
        }
      }
      cupoSeleccionado {
        cuota
        term
        value
      }
      cupoPreaprobado {
        value
        terms
        valorCuota
      }
      user {
        firstName
        id
        lastName
      }
      analyst {
        firstName
        lastName
      }
    }
  }
`

export const GET_LIST_TABS = gql`
  query entityList($creditId: Int!) {
    entityListLocked(creditId: $creditId) {
      entity
      locked
    }
  }
`

export const GET_COSINGER_CREDIT = gql`
  query getCosigners($after: String, $id: Float, $creditId: Float) {
    getCosigners(after: $after, id: $id, credit_Id: $creditId) {
      edges {
        node {
          id
          firstName
          secondName
          firstSurname
          secondSurname
          documentType
          documentNumber
          dateBirth
          expeditionDate
          email
          phoneNumber
          created

          formData
        }
      }
    }
  }
`
export const GET_PRODUCT_INFO_CREDIT = gql`
  query getProductInformation($creditId: Int, $productInformationId: Int) {
    getProductInformation(
      creditId: $creditId
      productInformationId: $productInformationId
    ) {
      id
      description
      name
      value
      created
      formData
    }
  }
`
export const GET_REFERENCES_CREDIT = gql`
  query getReferences($after: String, $id: Float, $creditId: Float) {
    getReferences(after: $after, id: $id, credit_Id: $creditId) {
      edges {
        node {
          fullName
          formData
          address
          id
          phoneNumber
          referenceType
          cosigner {
            id
            firstName
            secondName
            firstSurname
            secondSurname
          }
        }
      }
    }
  }
`
export const GET_SPOUSE_CREDIT = gql`
  query getSpouse($creditId: Int, $spouseId: Int) {
    getSpouse(creditId: $creditId, spouseId: $spouseId) {
      id
      firstName
      secondName
      firstSurname
      secondSurname
      documentType
      documentNumber
      dateBirth
      expeditionDate
      email
      phoneNumber

      formData
    }
  }
`

export const GET_FORM_CREDIT = gql`
  query getEntityForms($codename: String!) {
    getEntityForms(codename: $codename)
  }
`
export const GET_FORM_CREDIT_ENTITY = gql`
  query getEntityForms($entity: EntityEnum!) {
    getEntiyForm(entity: $entity)
  }
`
export const GET_QUOTATION_TO_CREDIT = gql`
  query listQuotations(
    $id: Float
    $status: String
    $documentNumber: String
    $agencyId: Float
    $createdDateGte: DateTime
    $createdDateLte: DateTime
    $name: String
  ) {
    listQuotations(
      id: $id
      status_Iexact: $status
      documentNumber: $documentNumber
      agency_Id: $agencyId
      created_Date_Gte: $createdDateGte
      created_Date_Lte: $createdDateLte
      name: $name
    ) {
      edges {
        node {
          user {
            firstName
            lastName
          }
          agency {
            name
          }
          created
          documentNumber
          email
          firstName
          firstSurname
          id
          phoneNumber
          secondName
          secondSurname
          status
          quotationproductthroughSet {
            edges {
              node {
                id
                basePrice
                product {
                  id
                  name
                  image
                  sku
                }
                priceauxquotationSet {
                  edges {
                    node {
                      name
                      value
                    }
                  }
                }
                discountauxquotationSet {
                  edges {
                    node {
                      name
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export const GET_FORM_DATA = gql`
  query getEntityResponse($codename: String!, $id: Int!) {
    getEntityResponse(codename: $codename, id: $id)
  }
`
export const GET_DOCS_CREDIT = gql`
  query listDocuments($after: String, $creditId: ID!) {
    listDocuments(after: $after, creditId: $creditId) {
      edges {
        node {
          id
          name
          legibleName
        }
      }
    }
  }
`

export const GET_ANALYST_CREDITS = gql`
  query listUsers($id: Float, $after: String, $rol: String) {
    listUsers(id: $id, after: $after, rol: $rol) {
      edges {
        node {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const GET_COSINGER_REFERENCE = gql`
  query getCosigners($credit_Id: Float) {
    getCosigners(credit_Id: $credit_Id) {
      edges {
        node {
          id
          firstName
          secondName
          firstSurname
          secondSurname
        }
      }
    }
  }
`

export const CHECK_VIEW_CREDIT = gql`
  query canViewCredit($creditId: Int!) {
    canViewCredit(creditId: $creditId)
  }
`

export const GET_REPORTS_CREDITS = gql`
  query statusCreditCount(
    $creditType: CreditTypeEnum
    $status: CreditStatusEnum
    $createdGte: DateTime
    $createdLte: DateTime
    $preapproved: Boolean
    $agencyId: Int
    $analystId: Int
  ) {
    statusCreditCount(
      creditType: $creditType
      status: $status
      createdGte: $createdGte
      createdLte: $createdLte
      preapproved: $preapproved
      agencyId: $agencyId
      analyst_Id: $analystId
    ) {
      status
      count
    }
  }
`

export const GET_PRE_CREDITS = gql`
  query oldCreditByCustomer(
    $documentType: DocumentTypeEnum
    $documentNumber: String
  ) {
    oldCreditByCustomer(
      documentType: $documentType
      documentNumber: $documentNumber
    ) {
      id
      created
      status
    }
  }
`
