import React, { Fragment } from 'react'
import { Container } from 'reactstrap'
import BreadCrumb from '../../Common/BreadCrumb'
import imgLogoPrecision from '../../../assets/images/totalsales/precision.JPG'
import moment from 'moment'

export const PrecisionPage = () => {
  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb
          title='Crear crédito'
          pageTitle='Créditos'
          back='/creditos'
        />
        <div className='bg-white p-4'>
          <div className='w-100 d-flex align-items-center justify-content-center mb-5'>
            <div className='d-flex align-items-end gap-4 flex-column flex-md-row'>
              <img src={imgLogoPrecision} alt='logo precision' width={200} />
              <div>
                <h2 className='m-0 p-0 fs-4'>SOLUCIONES INTEGRALES OB SAS</h2>
                <h6 className='m-0 p-0 fs-6'>
                  {moment().format('DD/MM/YY')} {moment().format('hh:mm:ss A')}
                </h6>
              </div>
            </div>
          </div>
          <div style={{ overflowX: 'auto', width: '100%' }}>
            <table
              className='w-100'
              style={{ borderCollapse: 'separate' }}
              cellSpacing='4px'
            >
              <thead>
                <tr style={{ backgroundColor: '#006685' }}>
                  <th colSpan={6} className='text-center py-1 text-white fs-5'>
                    Resultado de la Consulta
                  </th>
                </tr>
              </thead>
              <tbody style={{ paddingInline: '3px' }}>
                <tr>
                  <th
                    style={{
                      backgroundColor: '#666666',
                      color: 'white',
                      paddingInline: '7px'
                    }}
                  >
                    TIPO IDENTIFICACIÓN
                  </th>
                  <td
                    style={{ backgroundColor: '#ededed', paddingInline: '7px' }}
                  >
                    C.C.
                  </td>
                  <th
                    style={{
                      backgroundColor: '#666666',
                      color: 'white',
                      paddingInline: '7px'
                    }}
                  >
                    EST DOCUMENTO
                  </th>
                  <td
                    style={{ backgroundColor: '#ededed', paddingInline: '7px' }}
                  >
                    VIGENTE
                  </td>
                  <th
                    style={{
                      backgroundColor: '#666666',
                      color: 'white',
                      paddingInline: '7px'
                    }}
                  >
                    FECHA
                  </th>
                  <td
                    style={{ backgroundColor: '#ededed', paddingInline: '7px' }}
                  >
                    31/01/2024
                  </td>
                </tr>
                <tr>
                  <th
                    style={{
                      backgroundColor: '#666666',
                      color: 'white',
                      paddingInline: '7px'
                    }}
                  >
                    No. IDENTIFICACIÓN
                  </th>
                  <td
                    style={{ backgroundColor: '#ededed', paddingInline: '7px' }}
                  >
                    26,870,439
                  </td>
                  <th
                    style={{
                      backgroundColor: '#666666',
                      color: 'white',
                      paddingInline: '7px'
                    }}
                  >
                    FECHA EXPEDICIÓN
                  </th>
                  <td
                    style={{ backgroundColor: '#ededed', paddingInline: '7px' }}
                  >
                    24/04/1981
                  </td>
                  <th
                    style={{
                      backgroundColor: '#666666',
                      color: 'white',
                      paddingInline: '7px'
                    }}
                  >
                    HORA
                  </th>
                  <td
                    style={{ backgroundColor: '#ededed', paddingInline: '7px' }}
                  >
                    14:36:44
                  </td>
                </tr>
                <tr>
                  <th
                    style={{
                      backgroundColor: '#666666',
                      color: 'white',
                      paddingInline: '7px'
                    }}
                  >
                    NOMBRES APELLIDOS - RAZÓN SOCIAL
                  </th>
                  <td
                    style={{ backgroundColor: '#ededed', paddingInline: '7px' }}
                  >
                    ACOSTA ROMERO SULINA MERCEDES
                  </td>
                  <th
                    style={{
                      backgroundColor: '#666666',
                      color: 'white',
                      paddingInline: '7px'
                    }}
                  >
                    LUGAR DE EXPEDICIÓN
                  </th>
                  <td
                    style={{ backgroundColor: '#ededed', paddingInline: '7px' }}
                  >
                    LA PAZ
                  </td>
                  <th
                    style={{
                      backgroundColor: '#666666',
                      color: 'white',
                      paddingInline: '7px'
                    }}
                  >
                    USUARIO
                  </th>
                  <td
                    style={{ backgroundColor: '#ededed', paddingInline: '7px' }}
                  >
                    APCT SOLUCIONES INTEGRALES OB SAS
                  </td>
                </tr>
                <tr>
                  <th
                    style={{
                      backgroundColor: '#666666',
                      color: 'white',
                      paddingInline: '7px'
                    }}
                  >
                    ACTIVIDAD ECONÓMICA - CIIU
                  </th>
                  <td
                    style={{ backgroundColor: '#ededed', paddingInline: '7px' }}
                  >
                    -
                  </td>
                  <th
                    style={{
                      backgroundColor: '#666666',
                      color: 'white',
                      paddingInline: '7px'
                    }}
                  >
                    RANGO EDAD PROBABLE
                  </th>
                  <td
                    style={{ backgroundColor: '#ededed', paddingInline: '7px' }}
                  >
                    61-65
                  </td>
                  <th
                    style={{
                      backgroundColor: '#666666',
                      color: 'white',
                      paddingInline: '7px'
                    }}
                  >
                    No INFORME
                  </th>
                  <td
                    style={{ backgroundColor: '#ededed', paddingInline: '7px' }}
                  >
                    01363519210262710173
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className='w-100 d-flex gap-2 text-muted justify-content-between'
            style={{ fontSize: '11px' }}
          >
            <p className='mt-1'>
              * Todos los valores de la consulta están expresados en miles de
              pesos
            </p>
            <div className='d-flex flex-column align-items-end'>
              <p className='m-0 p-0'>
                Se presenta reporte negativo cuando la(s) persona(s) naturales y
                jurídicas efectivamente se encuentran en mora en sus cuotas u
                obligaciones
              </p>
              <p className='m-0 p-0'>
                Se presenta reporte positivo cuando la(s) persona(s) naturales y
                jurídicas están al día en sus obligaciones.
              </p>
            </div>
          </div>

          <div className='pt-5'>
            <h4 className='text-center' style={{ color: '#27486a' }}>
              Información Score
            </h4>
            <div style={{ overflowX: 'auto', width: '100%' }}>
              <table
                className='w-100'
                style={{ borderCollapse: 'separate' }}
                cellSpacing='4px'
              >
                <thead>
                  <tr style={{ backgroundColor: '#006685' }}>
                    <th colSpan={6} className='text-center text-white fs-5'>
                      Información Score
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      SCORE
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      INDICADOR BUENO / MALO
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CV LINK GROUP
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      NEGATIVE REASON CODE
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      POSITIVE REASON CODE
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      SUBPOBLACION
                    </th>
                  </tr>
                  <tr>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      843
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      -
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className='pt-5'>
            <h4 className='text-center' style={{ color: '#27486a' }}>
              Información Ubica Plus
            </h4>
            <div style={{ overflowX: 'auto', width: '100%' }}>
              <table
                className='w-100'
                style={{ borderCollapse: 'separate' }}
                cellSpacing='4px'
              >
                <thead>
                  <tr style={{ backgroundColor: '#006685' }}>
                    <th colSpan={9} className='text-center text-white fs-5'>
                      Datos Históricos de Direcciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center',
                        maxWidth: '60px'
                      }}
                    >
                      NO.
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      TIPO
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      DIRECCIÓN
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CIUDAD
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      PRIMER REPORTE
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      ÚLTIMO REPORTE
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      GRUPO
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      P. ACTIVO
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      NO. DE REPORTES
                    </th>
                  </tr>
                  {[1, 2, 3, 4].map((n) => (
                    <tr key={n}>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        RES
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        CL 1 # 6 - 05 ESQ
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        RIOHACHA (LA GUAJIRA)
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        31/10/2019
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        31/10/2023
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        1
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        SI
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        2
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className='pt-5'>
            <div style={{ overflowX: 'auto', width: '100%' }}>
              <table
                className='w-100'
                style={{ borderCollapse: 'separate' }}
                cellSpacing='4px'
              >
                <thead>
                  <tr style={{ backgroundColor: '#006685' }}>
                    <th colSpan={10} className='text-center text-white fs-5'>
                      Datos Históricos de Números Telefónicos
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center',
                        maxWidth: '60px'
                      }}
                    >
                      NO.
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      TIPO
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      PREFIJO
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      TELÉFONO
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CIUDAD
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      PRIMER REPORTE
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      ÚLTIMO REPORTE
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      GRUPO
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      P. ACTIVO
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      NO. DE REPORTES
                    </th>
                  </tr>

                  {[1, 2, 3, 4, 5].map((n) => (
                    <tr key={n}>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        LAB
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        605
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        7284080
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        RIOHACHA (LA GUAJIRA)
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        31/10/2023
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        22/01/2024
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        1
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        SI
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        2
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className='pt-5 px-0 px-md-4'>
            <div style={{ overflowX: 'auto', width: '100%' }}>
              <table
                className='w-100'
                style={{ borderCollapse: 'separate' }}
                cellSpacing='4px'
              >
                <thead>
                  <tr style={{ backgroundColor: '#006685' }}>
                    <th colSpan={7} className='text-center text-white fs-5'>
                      Datos Históricos de Números Celulares
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center',
                        maxWidth: '60px'
                      }}
                    >
                      NO.
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CELULAR
                    </th>

                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      PRIMER REPORTE
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      ÚLTIMO REPORTE
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      GRUPO
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      P. ACTIVO
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      NO. DE REPORTES
                    </th>
                  </tr>

                  {[1, 2, 3, 4, 5].map((n) => (
                    <tr key={n}>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {n}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        3043928413
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        31/10/2023
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        22/01/2024
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        1
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        SI
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        2
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className='pt-5 px-0 px-md-4'>
            <div style={{ overflowX: 'auto', width: '100%' }}>
              <table
                className='w-100'
                style={{ borderCollapse: 'separate' }}
                cellSpacing='4px'
              >
                <thead>
                  <tr style={{ backgroundColor: '#006685' }}>
                    <th colSpan={4} className='text-center text-white fs-5'>
                      Datos Históricos de Correos Electrónicos
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center',
                        maxWidth: '60px'
                      }}
                    >
                      CORREO
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      NO. DE REPORTES
                    </th>

                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      PRIMER REPORTE
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      ÚLTIMO REPORTE
                    </th>
                  </tr>

                  {[1, 2, 3, 4].map((n) => (
                    <tr key={n}>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        SUMEACORO1@HOTMAIL.COM
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        11
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        31/10/2023
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        22/01/2024
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className='pt-5'>
            <h4 className='text-center' style={{ color: '#27486a' }}>
              Resumen Endeudamiento
            </h4>
            <div style={{ overflowX: 'auto', width: '100%' }}>
              <table
                className='w-100'
                style={{ borderCollapse: 'separate' }}
                cellSpacing='4px'
              >
                <thead>
                  <tr style={{ backgroundColor: '#006685' }}>
                    <th colSpan={11} className='text-center text-white fs-5'>
                      Resumen de Obligaciones (Como Principal)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      rowSpan={2}
                    >
                      OBLIGACIONES
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      colSpan={3}
                    >
                      TOTALES
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      colSpan={3}
                    >
                      OBLIGACIONES AL DÍA
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      colSpan={4}
                    >
                      OBLIGACIONES EN MORA
                    </th>
                  </tr>
                  <tr>
                    {/* th */}
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CANT
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      SALDO TOTAL
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      PADE
                    </th>

                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CANT
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      SALDO TOTAL
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CUOTA
                    </th>

                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CANT
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      SALDO TOTAL
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CUOTA
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      VALOR EN MORA
                    </th>
                  </tr>

                  <tr>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'left'
                      }}
                    >
                      Tarjetas De Credito:{' '}
                    </td>

                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      1
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      6,189
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      2
                    </td>

                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      1
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      6,189
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      227
                    </td>

                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      -
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'left'
                      }}
                    >
                      Sector Financiero:
                    </td>

                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      1
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      6,189
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      2
                    </td>

                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      1
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      6,189
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      227
                    </td>

                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      -
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'left'
                      }}
                    >
                      Sector Solidario:
                    </td>

                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      1
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      6,189
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      2
                    </td>

                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      1
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      6,189
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      227
                    </td>

                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      -
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'left'
                      }}
                    >
                      Sector Real:
                    </td>

                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      2
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      -
                    </td>

                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      2
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      -
                    </td>

                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#ededed',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      -
                    </td>
                  </tr>

                  <tr style={{ color: 'white' }}>
                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'left'
                      }}
                    >
                      SUBTOTAL PRINCIPAL
                    </td>

                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      10
                    </td>
                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      348,230
                    </td>
                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      100
                    </td>

                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      10
                    </td>
                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      348,230
                    </td>
                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      6,513
                    </td>

                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      -
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className='pt-3'>
            <div style={{ overflowX: 'auto', width: '100%' }}>
              <table
                className='w-100'
                style={{ borderCollapse: 'separate' }}
                cellSpacing='4px'
              >
                <thead>
                  <tr style={{ backgroundColor: '#006685' }}>
                    <th colSpan={11} className='text-center text-white fs-5'>
                      Resumen Total de Obligaciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ color: 'white' }}>
                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'left'
                      }}
                    >
                      TOTAL
                    </td>

                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      10
                    </td>
                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      348,230
                    </td>
                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      100
                    </td>

                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      10
                    </td>
                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      348,230
                    </td>
                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      6,513
                    </td>

                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'center'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        backgroundColor: '#666666',
                        padding: '2px 5px',
                        textAlign: 'right'
                      }}
                    >
                      -
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className='pt-5'>
            <h4 className='text-center' style={{ color: '#27486a' }}>
              Informe Detallado
            </h4>
            <div style={{ overflowX: 'auto', width: '100%' }}>
              <table
                className='w-100'
                style={{ borderCollapse: 'separate' }}
                cellSpacing='4px'
              >
                <thead>
                  <tr style={{ backgroundColor: '#006685' }}>
                    <th colSpan={13} className='text-center text-white fs-5'>
                      Información de Cuentas
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      FECHA CORTE
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      TIPO CONTRATO
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      No. CUENTA
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      ESTADO
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      TIPO ENT
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      ENTIDAD
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CIUDAD
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      SUCURSAL
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      FECHA APERTURA
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CUPO SOBREGIRO
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      DIAS AUTOR
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      FECHA PERMANENCIA
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CHEQ DEVUELTOS ÚLTIMO MES
                    </th>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: '#ededed' }} colSpan={13}>
                      <div className='pt-4 d-flex algin-items-start justify-content-start fw-bold'>
                        ESTADO: VIGENTES
                      </div>
                    </td>
                  </tr>
                  {[1, 2, 3].map((n) => (
                    <tr key={n}>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        31/12/2023
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        CTEINDIVIDUAL
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        012005
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        INACT
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        BCO
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        DAVIVIENDA S.A.
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        BOGOTA
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        ALHAMBRA
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        28/05/2019
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        0
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        0
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        -
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        0
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <td style={{ backgroundColor: '#ededed' }} colSpan={13}>
                      <div className='pt-4 d-flex algin-items-start justify-content-start fw-bold'>
                        ESTADO: NO VIGENTES
                      </div>
                    </td>
                  </tr>
                  {[1, 2, 3].map((n) => (
                    <tr key={n}>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        31/12/2023
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        CTEINDIVIDUAL
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        012005
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        INACT
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        BCO
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        DAVIVIENDA S.A.
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        BOGOTA
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        ALHAMBRA
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        28/05/2019
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        0
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        0
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        -
                      </td>
                      <td
                        style={{
                          backgroundColor: '#ededed',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        0
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className='pt-5'>
            <div style={{ overflowX: 'auto', width: '100%', fontSize: '10px' }}>
              <table
                className='w-100'
                style={{ borderCollapse: 'separate' }}
                cellSpacing='4px'
              >
                <thead>
                  <tr style={{ backgroundColor: '#006685' }}>
                    <th colSpan={21} className='text-center text-white fs-5'>
                      Información Endeudamiento en Sectores Financiero,
                      Asegurador y Solidario
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th
                      rowSpan={2}
                      colSpan={2}
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      FECHA CORTE
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      MODA
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      No. OBLIG
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      TIPO ENT
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      NOMBRE ENTIDAD
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CIUDAD
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CAL
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      MRC
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      TIPO GAR
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      F INICIO
                    </th>
                    <th
                      rowSpan={1}
                      colSpan={3}
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      No. CUOTAS
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CUPO APROBVLR INIC
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      PAGO MINIMVLR CUOTA
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      SIT OBLIG
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      NATU REES
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      No. REE
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      TIP PAG
                    </th>
                    <th
                      rowSpan={2}
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      F PAGO F EXTIN
                    </th>
                  </tr>
                  <tr>
                    {/* others <td> */}
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      PAC
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      PAG
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      MOR
                    </th>
                    {/* others <td> */}
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      TIPO CONT
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      PADE
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      LCRE
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      EST. CONTR
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CLF
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      ORIGEN CARTERA
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      SUCURSAL
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      EST TITU
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CLS
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      COB GAR
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      F TERM
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      PER
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      colSpan={2}
                    />
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CUPO UTILISALDO CORT
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    />

                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      VALOR MORA
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      REES
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      MOR MAX
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      MOD EXT
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      F PERMAN
                    </th>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: '#ededed' }} colSpan={21}>
                      <div className='pt-4 d-flex algin-items-start justify-content-start fw-bold'>
                        OBLIGACIONES VIGENTES Y AL DÍA
                      </div>
                    </td>
                  </tr>
                  {[1, 2, 3, 4].map((n) => (
                    <Fragment key={n}>
                      <tr>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                          colSpan={2}
                        >
                          28/12/2023
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          CONS
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          5454
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          BCO
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          BBVA COLOMBIA
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          VALLEDUPAR
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          PRIN
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          CRE
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          14/11/2001
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          0
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          6,425
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          277
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          VIGE
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          CRE
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          2
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          TCR
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          VIGE
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          CENTRO COMERCIAL
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          OTRO
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          COR
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                          colSpan={2}
                        />
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'right'
                          }}
                        >
                          6,189
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        />
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          0
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          NO
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                      </tr>

                      <tr>
                        <td
                          colSpan={12}
                          style={{
                            backgroundColor: '#ededed',
                            padding: '4px 5px',
                            textAlign: 'center'
                          }}
                        >
                          <div className='d-flex align-items-end w-100'>
                            <div className='d-flex justify-content-end gap-1 w-100'>
                              <div className='d-flex algin-items-center text-muted fw-bold'>
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                                  (n) => (
                                    <span
                                      key={n}
                                      style={{
                                        display: 'inline-block',
                                        border: '1px solid lightblue',
                                        padding: '0 5px'
                                      }}
                                    >
                                      N
                                    </span>
                                  )
                                )}
                              </div>
                              <div className='d-flex algin-items-center text-muted fw-bold'>
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                                  (n) => (
                                    <span
                                      key={n}
                                      style={{
                                        display: 'inline-block',
                                        border: '1px solid lightblue',
                                        padding: '0 5px'
                                      }}
                                    >
                                      N
                                    </span>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td
                          colSpan={4}
                          style={{
                            backgroundColor: '#ededed',
                            padding: '0',
                            textAlign: 'center'
                          }}
                        >
                          <div
                            className='w-100 h-100 fw-bold'
                            style={{
                              backgroundColor: '#00a6ca',
                              color: 'white',
                              clipPath:
                                'polygon(100% 0, 100% 50%, 100% 100%, 7% 99%, 0 54%, 7% 0)',
                              padding: '8px'
                            }}
                          >
                            COMPORTAMIENTOS
                          </div>
                        </td>
                        <td
                          colSpan={5}
                          style={{
                            backgroundColor: '#ededed',
                            padding: '4px 5px',
                            textAlign: 'center'
                          }}
                        />
                      </tr>
                    </Fragment>
                  ))}

                  <tr>
                    <td style={{ backgroundColor: '#ededed' }} colSpan={21}>
                      <div className='pt-4 d-flex algin-items-start justify-content-start fw-bold'>
                        OBLIGACIONES EXTINGUIDAS
                      </div>
                    </td>
                  </tr>
                  {[1, 2, 3, 4, 5, 6, 7].map((n) => (
                    <Fragment key={n}>
                      <tr>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                          colSpan={2}
                        >
                          28/12/2023
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          CONS
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          5454
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          BCO
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          BBVA COLOMBIA
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          VALLEDUPAR
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          PRIN
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          CRE
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          14/11/2001
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          0
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          6,425
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          277
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          VIGE
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          CRE
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          2
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          TCR
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          VIGE
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          CENTRO COMERCIAL
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          OTRO
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          COR
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                          colSpan={2}
                        />
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'right'
                          }}
                        >
                          6,189
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        />
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          0
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          NO
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                      </tr>

                      <tr>
                        <td
                          colSpan={12}
                          style={{
                            backgroundColor: '#ededed',
                            padding: '4px 5px',
                            textAlign: 'center'
                          }}
                        >
                          <div className='d-flex align-items-end w-100'>
                            <div className='d-flex justify-content-end gap-1 w-100'>
                              <div className='d-flex algin-items-center text-muted fw-bold'>
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                                  (n) => (
                                    <span
                                      key={n}
                                      style={{
                                        display: 'inline-block',
                                        border: '1px solid lightblue',
                                        padding: '0 5px'
                                      }}
                                    >
                                      N
                                    </span>
                                  )
                                )}
                              </div>
                              <div className='d-flex algin-items-center text-muted fw-bold'>
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                                  (n) => (
                                    <span
                                      key={n}
                                      style={{
                                        display: 'inline-block',
                                        border: '1px solid lightblue',
                                        padding: '0 5px'
                                      }}
                                    >
                                      N
                                    </span>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td
                          colSpan={4}
                          style={{
                            backgroundColor: '#ededed',
                            padding: '0',
                            textAlign: 'center'
                          }}
                        >
                          <div
                            className='w-100 h-100 fw-bold'
                            style={{
                              backgroundColor: '#00a6ca',
                              color: 'white',
                              clipPath:
                                'polygon(100% 0, 100% 50%, 100% 100%, 7% 99%, 0 54%, 7% 0)',
                              padding: '8px'
                            }}
                          >
                            COMPORTAMIENTOS
                          </div>
                        </td>
                        <td
                          colSpan={5}
                          style={{
                            backgroundColor: '#ededed',
                            padding: '4px 5px',
                            textAlign: 'center'
                          }}
                        />
                      </tr>
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className='pt-5'>
            <div style={{ overflowX: 'auto', width: '100%', fontSize: '10px' }}>
              <table
                className='w-100'
                style={{ borderCollapse: 'separate' }}
                cellSpacing='4px'
              >
                <thead>
                  <tr style={{ backgroundColor: '#006685' }}>
                    <th colSpan={18} className='text-center text-white fs-5'>
                      Información Endeudamiento en Sector Real
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      rowSpan={3}
                    >
                      FECHA CORTE
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      rowSpan={2}
                    >
                      TIPO CONT
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      rowSpan={2}
                    >
                      No. OBLIG
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      rowSpan={2}
                    >
                      NOMBRE ENTIDAD
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      rowSpan={2}
                    >
                      CIUDAD
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      rowSpan={2}
                    >
                      CALD
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      rowSpan={2}
                    >
                      VIG
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      rowSpan={3}
                    >
                      CLA PER
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      rowSpan={2}
                    >
                      F INICIO
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      rowSpan={1}
                      colSpan={3}
                    >
                      No. CUOTAS
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      rowSpan={2}
                    >
                      CUPO APROBVLR INIC
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      rowSpan={2}
                    >
                      PAGO MINIM-VLR CUOTA
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      rowSpan={2}
                    >
                      SIT OBLIG
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      rowSpan={2}
                    >
                      TIP PAG
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      rowSpan={2}
                    >
                      REF
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      rowSpan={2}
                    >
                      F PAGO-F EXTIN
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      rowSpan={1}
                    >
                      PAC
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      rowSpan={1}
                    >
                      PAG
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      rowSpan={1}
                    >
                      MOR
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CATELCRE
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      EST. CONTR
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      TIPO EMPR
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      SUCURSAL
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      EST TITU
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      MES
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      F TERM
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      PER
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                      colSpan={2}
                    />

                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      CUPO UTILISALDO CORT
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      VALOR CARGO FIJO
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      VALOR MORA
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      MOD EXT
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      MOR MAX
                    </th>
                    <th
                      style={{
                        backgroundColor: '#00a6ca',
                        padding: '4px 6px',
                        color: 'white',
                        textAlign: 'center'
                      }}
                    >
                      F PERMAN
                    </th>
                  </tr>

                  <tr>
                    <td style={{ backgroundColor: '#ededed' }} colSpan={18}>
                      <div className='pt-4 d-flex algin-items-start justify-content-start fw-bold'>
                        OBLIGACIONES VIGENTES Y AL DÍA
                      </div>
                    </td>
                  </tr>
                  {[1, 2, 3, 4].map((n) => (
                    <Fragment key={n}>
                      <tr>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          28/12/2023
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          CRE
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          J28872
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          CREDIJAMAR S. A
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          BARRANQUILLA
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          PRIN
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          IND
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          0
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          15/12/2017
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          0
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          0
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          0
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          0
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          0
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          VIGE
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          NO
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        />
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          NORM
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          VIGE
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          CPMM
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          PRINCIPAL
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        />
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          31/12/2026
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          MEN
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                          colSpan={2}
                        />
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          0
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          0
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'right'
                          }}
                        >
                          0
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            backgroundColor: '#ededed',
                            padding: '2px 5px',
                            textAlign: 'center'
                          }}
                        >
                          -
                        </td>
                      </tr>

                      <tr>
                        <td
                          colSpan={10}
                          style={{
                            backgroundColor: '#ededed',
                            padding: '4px 5px',
                            textAlign: 'center'
                          }}
                        >
                          <div className='d-flex align-items-end w-100'>
                            <div className='d-flex justify-content-end gap-1 w-100'>
                              <div className='d-flex algin-items-center text-muted fw-bold'>
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                                  (n) => (
                                    <span
                                      key={n}
                                      style={{
                                        display: 'inline-block',
                                        border: '1px solid lightblue',
                                        padding: '0 5px'
                                      }}
                                    >
                                      N
                                    </span>
                                  )
                                )}
                              </div>
                              <div className='d-flex algin-items-center text-muted fw-bold'>
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                                  (n) => (
                                    <span
                                      key={n}
                                      style={{
                                        display: 'inline-block',
                                        border: '1px solid lightblue',
                                        padding: '0 5px'
                                      }}
                                    >
                                      N
                                    </span>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td
                          colSpan={4}
                          style={{
                            backgroundColor: '#ededed',
                            padding: '0',
                            textAlign: 'center'
                          }}
                        >
                          <div
                            className='w-100 h-100 fw-bold'
                            style={{
                              backgroundColor: '#00a6ca',
                              color: 'white',
                              clipPath:
                                'polygon(100% 0, 100% 50%, 100% 100%, 7% 99%, 0 54%, 7% 0)',
                              padding: '8px'
                            }}
                          >
                            COMPORTAMIENTOS
                          </div>
                        </td>
                        <td
                          colSpan={4}
                          style={{
                            backgroundColor: '#ededed',
                            padding: '4px 5px',
                            textAlign: 'center'
                          }}
                        />
                      </tr>
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className='d-flex align-items-center justify-content-center fw-bold fs-3 pt-5'>
            <span> * * * * * * * * * * *</span>
            <span> F I N </span>
            <span style={{ marginInline: '10px' }}> D E </span>{' '}
            <span>C O N S U L T A</span>
            <span> * * * * * * * * * * *</span>
          </div>

          <div
            className='py-5 d-flex align-items-center justify-content-end mt-5'
            style={{ backgroundColor: '#006685' }}
          >
            <div className='d-flex align-items-center gap-3 fs-4 pe-4'>
              <button
                style={{
                  border: 0,
                  padding: 0,
                  backgroundColor: 'transparent',
                  color: 'white'
                }}
              >
                DESCARGAR
              </button>
              <button
                style={{
                  border: 0,
                  padding: 0,
                  backgroundColor: 'transparent',
                  color: 'white'
                }}
                // onClick={onClose}
              >
                CERRAR
              </button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
