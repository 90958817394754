import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Row, Spinner } from 'reactstrap'
import { MARK_NOTIFICATION } from '../../graphql/mutations/notificationsMut'
import { useMutation } from '@apollo/client'
import { useGetUser } from '../../hooks/user'
import { toast } from 'react-toastify'

export const MsgToastNotification = ({
  closeToast,
  toastProps,
  title,
  msg,
  url,
  id
}) => {
  const [
    gqlMarkNotification,
    {
      data: dataNotification,
      loading: loadingNotification,
      error: errorNotification
    }
  ] = useMutation(MARK_NOTIFICATION)

  const { updateOneNotificationReaded, incDecTotalNotifications } = useGetUser()
  const navigate = useNavigate()
  const [urlNavigate, setUrlNavigate] = useState('/')
  const [updateNotiId, setUpdateNotiId] = useState(0)

  useEffect(() => {
    if (!dataNotification) return
    if (dataNotification.markReadNotification.success) {
      incDecTotalNotifications('subtract')
      closeToast()
      navigate(urlNavigate)
      updateOneNotificationReaded(updateNotiId)
    }
  }, [dataNotification])
  useEffect(() => {
    if (errorNotification) {
      toast.error('Error: No se pudo ver notificación')
    }
  }, [errorNotification])

  return (
    <Row
      style={{ cursor: 'pointer' }}
      onClick={() => {
        if (loadingNotification) return
        const urlData = new URL(url)
        setUrlNavigate(urlData.pathname)
        setUpdateNotiId(id)
        gqlMarkNotification({
          variables: {
            notificationId: id
          }
        })
      }}
    >
      <Col lg={12} className='d-flex justify-content-between'>
        <div className='d-flex gap-1 align-items-center'>
          {loadingNotification ? (
            <Spinner size='sm' />
          ) : (
            <img
              src='/favicon-png.png'
              width={30}
              height={30}
              style={{ borderRadius: '0.25em', display: 'inline-block' }}
            />
          )}
          <p className='fs-6'>
            <strong>{title}</strong>
          </p>
        </div>
      </Col>
      <Col lg={12} style={{ borderTop: '1px solid #f1f1f1' }}>
        <p className='mt-2'>{msg}</p>
      </Col>
    </Row>
  )
}
