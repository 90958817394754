import React, { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom'

// Import Images

import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  ModalBody,
  Label,
  Input,
  Modal,
  ModalHeader,
  Form,
  ModalFooter,
  Button,
  FormFeedback,
  Spinner,
  Table
} from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'

// Formik
import * as Yup from 'yup'
import { useFormik } from 'formik'

// Export Modal
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useMutation, useQuery } from '@apollo/client'
// import { CREATE_AGENCIA } from '../../../graphql/mutations/empresaComercial'
import { hasPermission, parseId } from '../../../helpers'
import { GET_ALL_CATEGORIES } from '../../../graphql/queries/getProducts'
import { ADD_CATEGORIES } from '../../../graphql/mutations/products'
import moment from 'moment'
import { useGetUser } from '../../../hooks/user'
import { ALL_PERMISSIONS, ERROR_MESSAGES } from '../../../helpers/consts'
import { useNavigate } from 'react-router-dom'

const CategoriasPage = () => {
  const { data, loading, error } = useQuery(GET_ALL_CATEGORIES, {
    fetchPolicy: 'no-cache'
  })

  const [
    gqlCreateCategory,
    { data: dataCreate, loading: loadingCreate, error: errorCreate }
  ] = useMutation(ADD_CATEGORIES, {
    refetchQueries: [
      {
        query: GET_ALL_CATEGORIES
      }
    ]
  })
  const navigate = useNavigate()

  const { logout, permissions } = useGetUser()

  // const [company, setCompany] = useState([])
  // const [oneCompany, setOneCompany] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [idEdit, setIdEdit] = useState(null)

  // delete Company
  // const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [modal, setModal] = useState(false)

  // const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  // const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
  // Export Modal
  // const [isExportCSV, setIsExportCSV] = useState(false);

  /* useEffect(() => {
    if (companies && !companies.length) {
      dispatch(onGetCompanies());
    }
  }, [dispatch, companies]); */

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (company && company.img) || '',
      name: '',
      description: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Ingrese nombre de la categoría'),
      description: Yup.string().required('Ingrese descripción')
    }),
    onSubmit: (values) => {
      const input = { ...values }
      if (isEdit) {
        input.id = idEdit
      }
      // console.log('input', input)
      gqlCreateCategory({
        variables: {
          input
        },

        refetchQueries: [
          {
            query: GET_ALL_CATEGORIES
          }
        ]
      })
    }
  })

  useEffect(() => {
    if (!hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_CATEGORIA)) {
      navigate('/dashboard')
    }
  }, [permissions])

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error.message}`)
      if (error.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [error])

  useEffect(() => {
    if (dataCreate) {
      if (dataCreate?.categoryCreateUpdate?.category?.id) {
        toast.success(
          isEdit
            ? 'Categoría acutualizada correctamente'
            : 'Categoría creada correctamente'
        )
        validation.resetForm()
        setIdEdit(null)
        setModal(false)
        return
      }
      if (dataCreate.agencyCreateUpdate.errors?.length > 0) {
        toast.error(
          `ERROR! ${dataCreate.agencyCreateUpdate?.errors[0]?.messages[0]}`
        )
      }
    }
  }, [dataCreate])
  useEffect(() => {
    if (errorCreate) {
      toast.error(`ERROR! ${errorCreate.message}`)
      if (errorCreate.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorCreate])

  /* useEffect(() => {
    if (dataEmpsCom) {
       @type {{ "cursor": string, node: {id: string, name: string}}[]} arr
      const arr = dataEmpsCom.listZones.edges
      setInfoEmpComer(arr.map(({ node: { id, name } }) => ({ id, name })))
    }
  }, [dataEmpsCom]) */

  const toggle = () => {
    setModal((prev) => !prev)
  }

  /**
   * @typedef {Object} InfoItem
   * @property {string} name
   * @property {boolean} active
   * @property {string} phoneNumber
   * @property {Object} address
   * @property {string} zone.id
   * @property {string} zone.name
   */

  document.title = 'Categrías | Total Sales'

  if (loading) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Categorías' pageTitle='CRM' />

          <Row>
            <Col lg={12}>
              <Card style={{ height: '40vh' }}>
                <Spinner
                  style={{ height: '3em', width: '3em', margin: 'auto' }}
                />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  /** @type {{node: {created:string,description:string | null,id:string,name: string, productSet: {edges: {node: {id:string}}[]}}}[]} */
  const categories = data?.categoryList.edges || []

  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Categorías' pageTitle='CRM' />

          <Row>
            <Col lg={12}>
              <Card>
                {hasPermission(
                  permissions,
                  ALL_PERMISSIONS.PUEDE_ANADIR_CATEGORIA
                ) && (
                  <CardHeader>
                    <div className='d-flex align-items-center flex-wrap gap-2'>
                      <div className='flex-grow-1'>
                        <button
                          className='btn btn-info add-btn'
                          onClick={() => {
                            setIdEdit(null)
                            setIsEdit(false)
                            setModal(true)
                          }}
                        >
                          <i className='ri-add-fill me-1 align-bottom' />{' '}
                          Agregar categoría
                        </button>
                      </div>
                    </div>
                  </CardHeader>
                )}
                <CardBody className='pt-0'>
                  {categories.length > 0 ? (
                    <div className='mt-4'>
                      <Table responsive>
                        {/* create a table head whit next items: name, description, products, created */}
                        <thead>
                          <tr>
                            <th>Nombre</th>
                            <th>Descripción</th>
                            <th>Productos</th>
                            <th>Creado</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {categories.map(({ node }) => (
                            <tr key={node.id}>
                              <td>{node.name}</td>
                              <td
                                className='text-break'
                                style={{ maxWidth: '200px' }}
                              >
                                {node.description || 'N/A'}
                              </td>
                              <td className='ms-2'>
                                {node.productSet.edges.length}
                              </td>
                              <td>
                                {moment(node.created).format(
                                  'DD MMM [del] YYYY'
                                )}
                              </td>
                              <td>
                                {hasPermission(
                                  permissions,
                                  ALL_PERMISSIONS.PUEDE_CAMBIAR_CATEGORIA
                                ) && (
                                  <button
                                    style={{ border: 0, background: 'none' }}
                                    title='Editar'
                                    onClick={() => {
                                      setIsEdit(true)
                                      setIdEdit(parseId(node.id))
                                      validation.setValues({
                                        description: node.description || '',
                                        name: node.name
                                      })
                                      setModal(true)
                                    }}
                                  >
                                    <i className='bx bx-pencil' />
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <div style={{ height: '40vh' }}>
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <h6>No hay categorías creadas</h6>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          id='showModal'
          isOpen={modal}
          toggle={toggle}
          onClosed={() => {}}
          centered
          size='lg'
        >
          <ModalHeader className='bg-soft-info p-3' toggle={toggle}>
            {isEdit ? 'Editar categoría' : 'Añadir categoría'}
          </ModalHeader>
          <Form
            className='tablelist-form'
            onSubmit={(e) => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <ModalBody>
              <input type='hidden' id='id-field' />
              <Row className='g-3'>
                <Col lg={12}>
                  <div>
                    <Label htmlFor='name-field' className='form-label'>
                      Nombre de la categoría
                    </Label>

                    <Input
                      name='name'
                      id='customername-field'
                      className='form-control'
                      placeholder='Nombre de categoría'
                      type='text'
                      validate={{
                        required: { value: true }
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.name || ''}
                      invalid={Boolean(
                        validation.touched.name && validation.errors.name
                      )}
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <FormFeedback type='invalid'>
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={12}>
                  <div>
                    <Label htmlFor='description-field' className='form-label'>
                      Descripción
                    </Label>
                    <Input
                      name='description'
                      id='description-field'
                      className='form-control'
                      placeholder='Ingrese descripción'
                      type='textarea'
                      validate={{
                        required: { value: true }
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.description}
                      invalid={Boolean(
                        validation.touched.description &&
                          validation.errors.description
                      )}
                    />
                    {validation.touched.description &&
                    validation.errors.description ? (
                      <FormFeedback type='invalid'>
                        {validation.errors.description}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <div className='hstack gap-2 justify-content-end'>
                <Button
                  color='light'
                  onClick={() => {
                    validation.resetForm()
                    setModal(false)
                  }}
                >
                  Cancelar{' '}
                </Button>
                <Button
                  type='submit'
                  color='success'
                  id='add-btn'
                  disabled={loadingCreate}
                >
                  {loadingCreate || !!isEdit
                    ? 'Actualizar'
                    : 'Añadir categoría'}
                  {loadingCreate && 'cargando...'}
                </Button>
              </div>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default CategoriasPage
