import React, { useState } from 'react'
// import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap'

// import images
import avatar1 from '../../assets/images/user-profile.png'
import { useGetUser } from '../../hooks/user'
import { OffCanvaRol } from '../Own/OffCanvaRol'
import { toast } from 'react-toastify'
import { ROLES } from '../../helpers/consts'
// import SimpleBar from 'simplebar-react'

const ProfileDropdown = () => {
  /* const { user } = useSelector((state) => ({
    user: state.Profile.user
  })) */

  const {
    logout,
    user: userOwn,
    rol,
    infoZoneOrAgencie,
    openModalChangeZoneOrAgencie
  } = useGetUser()

  const [showOffCanva, setShowOffCanva] = useState(false)

  // const [userName, setUserName] = useState('Admin')

  // Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false)
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown)
  }

  const toggleCanvas = () => {
    setShowOffCanva((prev) => !prev)
  }

  const closeOffCanvas = () => {
    setShowOffCanva(false)
  }
  /**
   * @param {string} message
   * @param {boolean} isSuccess
   */
  const showMessage = (message, isSuccess) => {
    if (isSuccess) {
      toast.success(message)
    } else {
      toast.error(message)
    }
  }

  const getTextZoneOrAgencyInfo = (currentRol) => {
    return currentRol === ROLES.ASESOR
      ? 'agencia'
      : currentRol === ROLES.JEFE_ZONA ||
        currentRol === ROLES.FACTURADOR ||
        currentRol === ROLES.ANALISTA
      ? 'zona'
      : 'zona/agencia'
  }

  return (
    <>
      {/* <ToastContainer theme='light' /> */}
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className='ms-sm-3 header-item topbar-user'
      >
        <DropdownToggle tag='button' type='button' className='btn'>
          <span className='d-flex align-items-center'>
            <img
              className='rounded-circle header-profile-user'
              src={avatar1}
              alt='Header Avatar'
            />
            <span className='text-start ms-xl-2'>
              <span className='d-none d-xl-inline-block ms-1 fw-medium user-name-text'>
                {userOwn?.tokenAuth?.name || 'User'}
              </span>
              <span className='d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text'>
                {rol || 'Rol'}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu-end'>
          <h6 className='dropdown-header'>Bienvenido! </h6>
          {infoZoneOrAgencie.zoneOrAgency && (
            <div style={{ padding: '0 1.2rem' }}>
              <small
                className='text-success text-center'
                style={{ textShadow: '0 0 40px #0f0' }}
              >
                <i className='mdi mdi-map-marker-account' />{' '}
                {infoZoneOrAgencie.zoneOrAgency.split('***')[0]}
              </small>
            </div>
          )}
          <div className='dropdown-divider' />

          <DropdownItem className='p-0'>
            <Link
              to={process.env.PUBLIC_URL + '/profile'}
              className='dropdown-item'
            >
              <i className='mdi mdi-account-circle text-muted fs-16 align-middle me-1' />
              <span className='align-middle'>Perfil</span>
            </Link>
          </DropdownItem>
          <DropdownItem className='p-0'>
            <div
              className='dropdown-item'
              onClick={() => setShowOffCanva(true)}
            >
              <i className='bx bx-transfer text-muted fs-16 align-middle me-1' />{' '}
              <span className='align-middle'>Cambiar rol</span>
            </div>
          </DropdownItem>

          {rol !== ROLES.ADMIN &&
            rol !== ROLES.GERENTE &&
            rol !== ROLES.CARTERA &&
            rol !== ROLES.CONTABILIDAD &&
            rol !== ROLES.JEFE_CREDITO && (
              <DropdownItem className='p-0'>
                <div
                  className='dropdown-item'
                  onClick={() => openModalChangeZoneOrAgencie()}
                >
                  <i className='mdi mdi-account-convert text-muted fs-16 align-middle me-1' />{' '}
                  <span className='align-middle'>
                    Cambiar de {getTextZoneOrAgencyInfo(rol)}
                  </span>
                </div>
              </DropdownItem>
            )}

          <div className='dropdown-divider' />
          <DropdownItem className='p-0'>
            <div
              className='dropdown-item'
              style={{ cursor: 'pointer' }}
              onClick={logout}
            >
              <i className='mdi mdi-logout text-muted fs-16 align-middle me-1' />{' '}
              <span className='align-middle'>Salir</span>
            </div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      <OffCanvaRol
        showOffCanva={showOffCanva}
        toggleCanvas={toggleCanvas}
        closeOffCanvas={closeOffCanvas}
        showMessage={showMessage}
      />
    </>
  )
}

export default ProfileDropdown
