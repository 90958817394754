import React, { useEffect, useMemo, useState } from 'react'
import { Card, CardBody, Col, Row, Spinner } from 'reactstrap'
import Flatpickr from 'react-flatpickr'
import { useGetUser } from '../../../hooks/user'
import { hasPermission, parsePrice } from '../../../helpers'
import { ALL_PERMISSIONS } from '../../../helpers/consts'
import CountUp from 'react-countup'
import moment from 'moment'
import { useLazyQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { GET_BUDGET_REPORT } from '../../../graphql/queries/getReports'
import { Spanish } from 'flatpickr/dist/l10n/es'
import { LoadingBudgets } from './LoadingBudgets'

export const BudgetReport = () => {
  const [gqlGetReport, { data, loading, error }] = useLazyQuery(
    GET_BUDGET_REPORT,
    { fetchPolicy: 'no-cache' }
  )

  const { user, permissions } = useGetUser()

  const [datesValue, setDatesValue] = useState({
    createdGte: new Date(new Date().setDate(1)).toISOString(),
    createdLte: new Date().toISOString()
  })

  useEffect(() => {
    gqlGetReport({
      variables: {
        initialDate: datesValue.createdGte?.split('T')[0],
        endDate: datesValue.createdLte?.split('T')[0]
      }
    })
  }, [datesValue])

  useEffect(() => {
    if (error) toast.error('Error: ' + error.message)
  }, [error])

  const getGreeting = () => {
    const horaActual = new Date().getHours()
    if (horaActual >= 6 && horaActual < 12) {
      return '¡Buenos días'
    } else if (horaActual >= 12 && horaActual < 18) {
      return '¡Buenas tardes'
    } else {
      return '¡Buenas noches'
    }
  }

  const getPercentaje = (report, total) => {
    if (!Number(report)) return 0.0
    return (Number(total) * 100) / Number(report)
  }

  const searchForDates = (e) => {
    if (e.length < 2) return

    if (e && e.length === 2) {
      const createdGte = new Date(e[0]).toISOString()
      const createdLte = new Date(e[1]).toISOString()

      return setDatesValue({
        createdGte,
        createdLte
      })
    }
    setDatesValue({
      createdGte: undefined,
      createdLte: undefined
    })
  }

  const canViewReports = useMemo(
    () => hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_REPORTES),
    [permissions.includes(ALL_PERMISSIONS.PUEDE_VER_REPORTES)]
  )

  return (
    <div>
      <Row className='mb-3 pb-1'>
        <Col xs={12}>
          <div className='d-flex align-items-lg-center justify-content-between flex-md-row flex-column'>
            <div className=''>
              <h4 className='fs-16 mb-1'>
                {getGreeting()}, {user.tokenAuth.name}!
                {loading && <Spinner size='sm' className='ms-2' />}
              </h4>
              <p className='text-muted mb-0'>
                Esto es lo que está sucediendo hoy.
              </p>
            </div>
            {canViewReports && (
              <div
                className='mt-3 mt-lg-0'
                style={{ flex: 1, maxWidth: '256px' }}
              >
                <div className='input-group'>
                  <span className='input-group-text' id='basic-addon1'>
                    <i className='ri-calendar-2-line' />
                  </span>
                  <Flatpickr
                    placeholder='Seleccione fecha(s)'
                    className='form-control'
                    value={[datesValue.createdGte, datesValue.createdLte]}
                    onChange={(e) => searchForDates(e)}
                    options={{
                      mode: 'range',
                      dateFormat: 'd F y',
                      showMonths: 2,
                      locale: Spanish,
                      position: 'auto center'
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>

      {canViewReports && (
        <Row className='mt-4'>
          {loading || !data ? (
            <LoadingBudgets
              end={datesValue.createdLte}
              init={datesValue.createdGte}
            />
          ) : (
            data.budgetReport?.budget && (
              <>
                <Col lg={4} md={6}>
                  <Card className='card-animate'>
                    <CardBody>
                      <div className='d-flex align-items-center'>
                        <div className='flex-grow-1 overflow-hidden'>
                          <p className='text-uppercase fw-medium text-muted text-truncate mb-0'>
                            Presupuesto
                          </p>
                        </div>
                        {/* <div className='flex-shrink-0'>
                  <h5 className={'fs-14 mb-0 text-' + item.badgeClass}>
                    {item.badge ? (
                      <i className={'fs-13 align-middle ' + item.badge}></i>
                    ) : null}{' '}
                    {item.percentage} %
                  </h5>
                </div> */}
                      </div>
                      <div className='d-flex align-items-end justify-content-between mt-4'>
                        <div>
                          <h4 className='fs-22 fw-semibold ff-secondary mb-4'>
                            <span
                              className='counter-value'
                              data-target='559.25'
                            >
                              {parsePrice(data.budgetReport.budget)}
                            </span>
                          </h4>
                          <span
                            className='text-decoration-underline'
                            style={{ fontSize: '11px' }}
                          >
                            Meta de ventas
                          </span>
                        </div>
                        <div className='avatar-sm flex-shrink-0'>
                          <span className='avatar-title rounded fs-3 bg-soft-info'>
                            <i className='text-info mdi mdi-clipboard-clock-outline' />
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={4} md={6}>
                  <Card className='card-animate'>
                    <CardBody>
                      <div className='d-flex align-items-center'>
                        <div className='flex-grow-1 overflow-hidden'>
                          <p className='text-uppercase fw-medium text-muted text-truncate mb-0'>
                            Total facturado
                          </p>
                        </div>
                        {/* <div className='flex-shrink-0'>
                  <h5 className={'fs-14 mb-0 text-' + item.badgeClass}>
                    {item.badge ? (
                      <i className={'fs-13 align-middle ' + item.badge}></i>
                    ) : null}{' '}
                    {item.percentage} %
                  </h5>
                </div> */}
                      </div>
                      <div className='d-flex align-items-end justify-content-between mt-4'>
                        <div>
                          <h4 className='fs-22 fw-semibold ff-secondary mb-4'>
                            <span
                              className='counter-value'
                              data-target='559.25'
                            >
                              {parsePrice(data.budgetReport.totalCharged)}
                            </span>
                          </h4>
                          <span
                            className='text-decoration-underline'
                            style={{ fontSize: '11px' }}
                          >
                            Entre{' '}
                            {moment(datesValue.createdGte).format('DD MMM')} y{' '}
                            {moment(datesValue.createdLte).format('DD MMM')}
                          </span>
                        </div>
                        <div className='avatar-sm flex-shrink-0'>
                          <span className='avatar-title rounded fs-3 bg-soft-success'>
                            <i className='text-success mdi mdi-cash-check' />
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={4} md={6}>
                  <Card className='card-animate'>
                    <CardBody>
                      <div className='d-flex align-items-center'>
                        <div className='flex-grow-1 overflow-hidden'>
                          <p className='text-uppercase fw-medium text-muted text-truncate mb-0'>
                            Cumplimiento
                          </p>
                        </div>
                        {/* <div className='flex-shrink-0'>
                  <h5 className={'fs-14 mb-0 text-' + item.badgeClass}>
                    {item.badge ? (
                      <i className={'fs-13 align-middle ' + item.badge}></i>
                    ) : null}{' '}
                    {item.percentage} %
                  </h5>
                </div> */}
                      </div>
                      <div className='d-flex align-items-end justify-content-between mt-4'>
                        <div>
                          <h4 className='fs-22 fw-semibold ff-secondary mb-4'>
                            <span
                              className='counter-value'
                              data-target='559.25'
                            >
                              <CountUp
                                start={0}
                                end={getPercentaje(
                                  data.budgetReport.budget,
                                  data.budgetReport.totalCharged
                                )}
                                suffix='%'
                                decimals={2}
                                duration={2}
                              />
                            </span>
                          </h4>
                          <span
                            className='text-decoration-underline'
                            style={{ fontSize: '11px' }}
                          >
                            Porcentaje de cumplimiento
                          </span>
                        </div>
                        <div className='avatar-sm flex-shrink-0'>
                          <span className='avatar-title rounded fs-3 bg-soft-warning'>
                            <i className='text-warning mdi mdi-percent-outline' />
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </>
            )
          )}
        </Row>
      )}
    </div>
  )
}
