import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Table
} from 'reactstrap'
import { useMutationFetch } from '../../../hooks/useMutationFetch'
import { parseId, parsePrice } from '../../../helpers'
import { CHANGE_STATUS_CREDIT_STR } from '../../../graphql/mutations/creditMutations'
import { MAX_SIZE, TableCommentCredit } from '../TableCommentCredit'
import { toast } from 'react-toastify'
import { STATUS_CREDITS_ARR } from '../../../helpers/consts'

export const ModalChangeStatus = ({
  refetchDataCredit,
  closeModal,
  showChangeStatus,
  toggleShowChangeStatus,
  creditId,
  infoExtra
}) => {
  const [
    changeStatus,
    { data: dataChange, loading: loadingChange, error: errorChange }
  ] = useMutationFetch()

  /** @type {[File[], React.Dispatch<React.SetStateAction<File[]>>]} */
  const [filesChange, setFilesChange] = useState([])

  useEffect(() => {
    if (!dataChange) return
    if (dataChange?.creditChangeStatus?.success) {
      toast.success('Cambio de extado realizado con éxito')
      refetchDataCredit()
      setFilesChange([])
      const form = document.getElementById('form-change-status-credit')
      form?.reset()
      // setShowComment(false)
      closeModal()
    } else {
      toast.error('No se pudo cambiar estado')
    }
  }, [dataChange])

  useEffect(() => {
    if (!errorChange) return
    if (errorChange) {
      toast.error(`ERROR: No se pudo cambiar estado ${errorChange.toString()}`)
    }
  }, [errorChange])

  const isOneBigger = () => {
    const find = filesChange.find(({ size }) => size > MAX_SIZE)
    if (find) {
      return true
    }
    return false
  }
  const deleteFileChange = (nameFile) => {
    setFilesChange((p) => {
      return p.filter(({ name }) => name !== nameFile)
    })
  }
  return (
    <Modal isOpen={showChangeStatus} toggle={toggleShowChangeStatus}>
      <ModalHeader toggle={toggleShowChangeStatus}>
        Cambiar estado de crédito
      </ModalHeader>
      <Form
        id='form-change-status-credit'
        onSubmit={(e) => {
          e.preventDefault()
          const { comment, status } = Object.fromEntries(new FormData(e.target))
          const formdata = new FormData()
          const map = {}
          filesChange.forEach((file, idx) => {
            formdata.append(`${idx}`, file, file.name)
            map[`${idx}`] = [`variables.input.files.${idx}`]
          })
          const obj = {
            query: CHANGE_STATUS_CREDIT_STR,
            variables: {
              input: {
                files: filesChange.map(() => null),
                creditId: parseId(creditId),
                comment,
                status
              }
            }
          }
          formdata.append('map', JSON.stringify(map))
          formdata.append('operations', JSON.stringify(obj))
          /* if (comment) {
                for (const pair of formdata.entries()) {
                  console.log(pair[0] + ', ' + pair[1])
                }
                return
              } */
          /* if (obj) {
            console.log('obj', obj)
            return
          } */
          changeStatus(formdata)
        }}
      >
        <ModalBody>
          {infoExtra && (
            <div>
              <Table hover>
                <tbody>
                  <tr>
                    <th scope='row'>Titular</th>
                    <td className='text-uppercase'>{infoExtra.name}</td>
                  </tr>
                  <tr>
                    <th scope='row'>Documento de identificación</th>
                    <td>{infoExtra.document}</td>
                  </tr>
                  <tr>
                    <th scope='row'>Valor</th>
                    <td>
                      {infoExtra.value ? parsePrice(infoExtra.value) : 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <th scope='row'>Cuota inicial</th>
                    <td>
                      {infoExtra.intialQuota
                        ? parsePrice(infoExtra.intialQuota)
                        : 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <th scope='row'>Plazo</th>
                    <td>{infoExtra?.plazo || 'N/A'}</td>
                  </tr>
                  <tr>
                    <th scope='row'>Valor cuota</th>
                    <td>
                      {infoExtra.quotaValue
                        ? parsePrice(infoExtra.quotaValue)
                        : 'N/A'}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
          <FormGroup>
            <Label>Seleccione el estado</Label>
            <Input name='status' required type='select'>
              <option value=''>Escoga un estado</option>
              {STATUS_CREDITS_ARR.slice(0, 7).map(({ name, value }) => (
                <option key={value} value={value}>
                  {name}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label htmlFor='textarea45'>Comentario</Label>
            <Input type='textarea' name='comment' id='textarea45' required />
          </FormGroup>
          <FormGroup>
            <Label>Archivo(s)</Label>
            <Input
              type='file'
              onChange={(e) => {
                const { target } = e
                const fileAdd = target.files[0]
                setFilesChange((p) => {
                  const files = [...p]
                  const find = files.find((file) => file.name === fileAdd.name)
                  if (find) {
                    return files
                  }
                  files.unshift(fileAdd)
                  return files
                })
                target.value = null
              }}
            />
          </FormGroup>
          {filesChange.length > 0 ? (
            <TableCommentCredit
              files={filesChange}
              setDeleteFile={deleteFileChange}
            />
          ) : (
            <Alert>No hay soportes adjuntados</Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color='light'
            type='button'
            onClick={() => {
              // setShowComment(false)
              closeModal()
              setFilesChange([])
              const form = document.getElementById('form-change-status-credit')
              form?.reset()
            }}
          >
            Cancelar
          </Button>
          <Button
            type='submit'
            disabled={loadingChange || isOneBigger()}
            className='d-flex align-items-center gap-1'
          >
            {loadingChange && <Spinner color='light' size='sm' />}
            Cambiar estado
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
