import React from 'react'

const Type = (cell) => {
  return (
    <>
      {cell.value === 'Buy' ? (
        <span className='type text-success'>{cell.value}</span>
      ) : (
        <span className='type text-danger'>{cell.value}</span>
      )}
    </>
  )
}

const Quantity = (cell) => {
  return <>{cell.value}</>
}

const OrderValue = (cell) => {
  return <>{cell.value}</>
}

const AvgPrice = (cell) => {
  return <>{cell.value}</>
}

const Price = (cell) => {
  return <>{cell.value}</>
}

const Status = (cell) => {
  return (
    <>
      {cell.value === 'Pending' ? (
        <span className='badge badge-soft-warning text-uppercase'>
          {cell.value}
        </span>
      ) : cell.value === 'Successful' ? (
        <span className='badge badge-soft-success text-uppercase'>
          {cell.value}
        </span>
      ) : cell.value === 'Cancelled' ? (
        <span className='badge badge-soft-danger text-uppercase'>
          {cell.value}
        </span>
      ) : null}
    </>
  )
}

export { Type, Quantity, OrderValue, AvgPrice, Price, Status }
