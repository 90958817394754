/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react'
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CloseButton,
  Col,
  Fade,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
  UncontrolledAccordion
} from 'reactstrap'
import Select from 'react-select'
// import TableContainer from '../../../Components/Common/TableContainer'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  GET_PRODUCTS_COTIZACION,
  GET_PRODUCT_INFO_COTIZACION
} from '../../../graphql/queries/getProducts'
import {
  getFullTotal,
  getTotal,
  hasPermission,
  inversePrice,
  parseId,
  parsePrice,
  parsePriceToNum
} from '../../../helpers'
import { CREATE_QUOTATION } from '../../../graphql/mutations/quotations'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { ALL_PERMISSIONS, PRICE_TO_DELETE } from '../../../helpers/consts'
import { useGetUser } from '../../../hooks/user'
// import CurrencyInput from 'react-currency-input-field'
import { InputCurrency } from '../../../Components/Own/InputCurrency'

const initialValues = {
  firstName: '',
  secondName: '',
  firstSurname: '',
  secondSurname: '',
  phoneNumber: '',
  email: '',
  documentNumber: '',
  quotationType: 'CONTADO',
  products: [],
  otherPrices: []
}

const validationSchema = Yup.object({
  firstName: Yup.string().required('El primer nombre es requerido'),
  secondName: Yup.string(),
  firstSurname: Yup.string().required('El primer apellido es requerido'),
  secondSurname: Yup.string(),
  phoneNumber: Yup.string()
    .matches(/^\d+$/, 'Solo se permiten números')
    .max(15, 'El número de teléfono debe tener como máximo 15 caracteres')
    .required('El número de teléfono es requerido'),
  email: Yup.string().email('Correo electrónico inválido'),
  documentNumber: Yup.string()
    .matches(/^[0-9]+$/, 'Debe contener solo números')
    .max(10, 'Número demasiado largo')
    .min(6, 'Número demasiado corto'),
  quotationType: Yup.string().required('El tipo de cotización es requerido'),
  products: Yup.array()
    .of(Yup.number().required('El producto es requerido'))
    .min(1, 'Debe seleccionar al menos un producto'),
  otherPrices: Yup.array().of(
    Yup.object({
      name: Yup.string().required('El nombre es obligatorio'),
      price: Yup.string().required('Number es obligatorio')
    })
  )
})

const INIT_OTHER_PRICES = {
  values: {
    name: '',
    price: ''
  },
  errors: {
    name: null,
    price: null
  },
  touched: {
    name: false,
    price: false
  }
}

export const CreateCotizMotos = ({
  onBackSelect,
  creditLine,
  selectQuoType
}) => {
  const { data: dataProducts, loading: loadingProducts } = useQuery(
    GET_PRODUCTS_COTIZACION,
    {
      fetchPolicy: 'no-cache',
      variables: {
        active: true
      }
    }
  )
  const [
    gqlGetInfoProduct,
    { data: dataInfoProduct, loading: loadingInfoProduct }
  ] = useLazyQuery(GET_PRODUCT_INFO_COTIZACION, { fetchPolicy: 'network-only' })

  const [
    gqlCreateQuotation,
    { data: dataCreate, loading: loadingCreate, error: errorCreate }
  ] = useMutation(CREATE_QUOTATION)

  const navigate = useNavigate()

  const { permissions, infoZoneOrAgencie, user } = useGetUser()

  const [open, setOpen] = useState(false)
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [productsSelected, setProductsSelected] = useState([])
  const [optionsData, setOptionsData] = useState([])
  const [selectOneProduct, setSelectOneProduct] = useState(null)

  const [formOtherPrices, setFormOtherPrices] = useState(INIT_OTHER_PRICES)
  const [formThirdCredit, setFormThirdCredit] = useState(INIT_OTHER_PRICES)

  const [detail, setDetail] = useState([])
  const [initialFee, setInitialFee] = useState('0')

  const [hasLead, setHasLead] = useState(false)
  const [leadOptions, setLeadOptions] = useState([])
  const [selectLead, setSelectLead] = useState('')

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const quotation = {
        ...values
      }
      if (hasLead) {
        if (!selectLead) {
          toast.warning('Debe seleccionar un lead')
          return
        }
        quotation.leadSource = selectLead
      }

      delete quotation.otherPrices
      const arrToAddNewPrices = productsSelected.map((prod) => {
        const obj = {
          productId: prod.id,
          otherPrices: prod.prices.map((info) => ({
            name: info.name,
            value: info.price
          }))
        }

        if (selectQuoType === 'CREDITO') {
          obj.creditLine = creditLine
        }

        if (quotation.quotationType === 'CREDITO') {
          obj.initialFee = prod.initialFee
        }

        if (quotation.quotationType === 'TERCEROS') {
          obj.detail = prod.detail.map((obj) => ({
            name: obj.name,
            value: obj.price
          }))
        }

        return obj
      })
      quotation.products = arrToAddNewPrices
      // console.log('quotation', quotation)
      gqlCreateQuotation({
        variables: {
          quotation
        }
      })
    }
  })

  useEffect(() => {
    formik.setFieldValue('quotationType', selectQuoType || '')
  }, [selectQuoType])

  useEffect(() => {
    if (!hasPermission(permissions, ALL_PERMISSIONS.PUEDE_ANADIR_COTIZACION)) {
      navigate('/')
    }
  }, [permissions])

  useEffect(() => {
    if (dataProducts && dataProducts.listProducts?.edges) {
      const dataParse = dataProducts.listProducts.edges.map(({ node }) => ({
        value: parseId(node.id),
        label: node.name
      }))
      setOptionsData(dataParse)
    }
  }, [dataProducts])

  useEffect(() => {
    if (dataInfoProduct) {
      if (
        dataInfoProduct.listProducts?.edges &&
        dataInfoProduct.listProducts?.edges.length > 0
      ) {
        const dataRes = dataInfoProduct.listProducts?.edges[0].node
        setProductsSelected((prev) => {
          const newArr = [...prev]
          const findIndex = newArr.findIndex(
            (prod) => prod.value === parseId(dataRes.id)
          )
          // console.log('findIndex', findIndex)
          newArr[findIndex] = {
            ...newArr[findIndex],
            basePrice: dataRes.basePrice,
            priceSet: dataRes.priceSet.edges,
            discountSet: dataRes.discountSet.edges
          }
          return newArr
        })
        setOptionsData((prev) => {
          const newArr = [...prev]
          const finIdx = newArr.findIndex(
            (prod) => parseId(dataRes.id) === prod.value
          )
          newArr[finIdx] = {
            ...newArr[finIdx],
            basePrice: dataRes.basePrice,
            priceSet: dataRes.priceSet.edges,
            discountSet: dataRes.discountSet.edges
          }
          return newArr
        })
      }
    }
  }, [dataInfoProduct])

  useEffect(() => {
    const prods = productsSelected.map(({ id }) => id)
    formik.setFieldValue('products', prods)
  }, [productsSelected])

  useEffect(() => {
    if (dataCreate) {
      if (dataCreate.quotationCreate.success) {
        toast.success('Cotización creada con éxito')
        const id = parseId(dataCreate.quotationCreate.quotation.id)
        navigate(`/cotizacion/${id}`)
      } else {
        toast.error(`Error ${dataCreate.quotationCreate.errors.message}`)
      }
    }
  }, [dataCreate])

  useEffect(() => {
    if (errorCreate) {
      toast.error(`ERROR: ${errorCreate.message}`)
    }
  }, [errorCreate])

  useEffect(() => {
    if (!formOtherPrices.values.name) {
      setFormOtherPrices((pre) => ({
        ...pre,
        errors: { ...pre.errors, name: 'Nombre requerido' }
      }))
    } else {
      setFormOtherPrices((pre) => ({
        ...pre,
        errors: { ...pre.errors, name: null }
      }))
    }

    if (!formOtherPrices.values.price) {
      setFormOtherPrices((pre) => ({
        ...pre,
        errors: { ...pre.errors, price: 'Precio requerido' }
      }))
    } else if (Number(formOtherPrices.values.price) < 1000) {
      setFormOtherPrices((pre) => ({
        ...pre,
        errors: { ...pre.errors, price: 'Debe ser mayor a $1.000' }
      }))
    } else {
      setFormOtherPrices((pre) => ({
        ...pre,
        errors: { ...pre.errors, price: null }
      }))
    }
  }, [formOtherPrices.values.name, formOtherPrices.values.price])

  useEffect(() => {
    if (!formThirdCredit.values.name) {
      setFormThirdCredit((pre) => ({
        ...pre,
        errors: { ...pre.errors, name: 'Nombre requerido' }
      }))
    } else {
      setFormThirdCredit((pre) => ({
        ...pre,
        errors: { ...pre.errors, name: null }
      }))
    }

    if (!formThirdCredit.values.price) {
      setFormThirdCredit((pre) => ({
        ...pre,
        errors: { ...pre.errors, price: 'Precio requerido' }
      }))
    } else if (Number(formThirdCredit.values.price) < 1000) {
      setFormThirdCredit((pre) => ({
        ...pre,
        errors: { ...pre.errors, price: 'Debe ser mayor a $1.000' }
      }))
    } else {
      setFormThirdCredit((pre) => ({
        ...pre,
        errors: { ...pre.errors, price: null }
      }))
    }
  }, [formThirdCredit.values.name, formThirdCredit.values.price])

  useEffect(() => {
    if (!user) return
    if (user.tokenAuth) {
      /** @type {{lead_sources: string}} */
      const obj = JSON.parse(user.tokenAuth.settings)
      if (!obj) return
      if (obj.lead_sources) {
        try {
          const arr = JSON.parse(obj.lead_sources)
          if (!arr) return
          if (arr.length > 0) {
            setLeadOptions(arr)
            setHasLead(true)
          }
        } catch (er) {
          console.log('er', er)
        }
      }
    }
  }, [user])

  const toggle = () => {
    setOpen((prev) => !prev)
  }
  /**
   * @param {{name: string, price: string}[]} pricesArr
   * @returns {number}
   */
  const getTotalPrice = (pricesArr) => {
    let tot = 0
    // console.log('pricesArr', pricesArr)
    pricesArr.forEach(({ price }) => {
      tot += Number(price)
    })

    return tot
  }

  if (loadingProducts) {
    return (
      <Card>
        <CardBody>
          <div
            className='d-flex align-items-center justify-content-center'
            style={{ height: '40vh' }}
          >
            <Spinner />
          </div>
        </CardBody>
      </Card>
    )
  }

  return (
    <>
      <Card>
        <CardHeader className='d-flex align-items-center border-0 gap-1'>
          <button
            style={{
              border: 0,
              backgroundColor: 'transparent',
              padding: '5px 8px'
            }}
            title='Seleccionar tipo de cotización'
            onClick={onBackSelect}
          >
            <i className='mdi mdi-arrow-left fs-4' />
          </button>
          <h5 className='card-title mb-0 d-block'>Información de cliente</h5>
        </CardHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            setIsSubmiting(true)
            formik.handleSubmit()
          }}
          autoComplete='off'
        >
          <CardBody>
            <input type='hidden' id='id-field' />
            <Row className='g-3'>
              <Col lg={6}>
                <div>
                  <Label htmlFor='firstName-field' className='form-label'>
                    Nombre
                    <span style={{ color: 'red' }} title='Obligatorio'>
                      *
                    </span>
                  </Label>
                  <Input
                    type='text'
                    name='firstName'
                    id='firstName-field'
                    placeholder='Ingrese su primer nombre'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    invalid={Boolean(
                      formik.touched.firstName && formik.errors.firstName
                    )}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <FormFeedback>{formik.errors.firstName}</FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <Label htmlFor='secondName-field' className='form-label'>
                    Segundo nombre
                  </Label>
                  <Input
                    type='text'
                    name='secondName'
                    id='secondName-field'
                    placeholder='Ingrese su segundo nombre'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondName}
                    invalid={Boolean(
                      formik.touched.secondName && formik.errors.secondName
                    )}
                  />
                  {formik.touched.secondName && formik.errors.secondName ? (
                    <FormFeedback>{formik.errors.secondName}</FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col lg={6}>
                <div>
                  <Label htmlFor='firstSurname-field' className='form-label'>
                    Apellido
                    <span style={{ color: 'red' }} title='Obligatorio'>
                      *
                    </span>
                  </Label>
                  <Input
                    type='text'
                    name='firstSurname'
                    id='firstSurname-field'
                    placeholder='Ingrese su primer apellido'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstSurname}
                    invalid={Boolean(
                      formik.touched.firstSurname && formik.errors.firstSurname
                    )}
                  />
                  {formik.touched.firstSurname && formik.errors.firstSurname ? (
                    <FormFeedback>{formik.errors.firstSurname}</FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <Label htmlFor='secondSurname-field' className='form-label'>
                    Segundo apellido
                  </Label>
                  <Input
                    type='text'
                    name='secondSurname'
                    id='secondSurnname-field'
                    placeholder='Ingrese su segundo apellido'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.secondSurname}
                    invalid={Boolean(
                      formik.touched.secondSurname &&
                        formik.errors.secondSurname
                    )}
                  />
                  {formik.touched.secondSurname &&
                  formik.errors.secondSurname ? (
                    <FormFeedback>{formik.errors.secondSurname}</FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col lg={6}>
                <div>
                  <Label htmlFor='phoneNumber-field' className='form-label'>
                    Número de teléfono
                    <span style={{ color: 'red' }} title='Obligatorio'>
                      *
                    </span>
                  </Label>
                  <Input
                    type='tel'
                    name='phoneNumber'
                    id='phoneNumber-field'
                    placeholder='Ingrese su número de teléfono'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phoneNumber}
                    invalid={Boolean(
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    )}
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                    <FormFeedback>{formik.errors.phoneNumber}</FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <Label htmlFor='email-field' className='form-label'>
                    Correo electrónico
                  </Label>
                  <Input
                    type='email'
                    name='email'
                    id='email-field'
                    placeholder='Ingrese su correo electrónico'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    invalid={Boolean(
                      formik.touched.email && formik.errors.email
                    )}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <FormFeedback>{formik.errors.email}</FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col lg={6}>
                <div>
                  <Label htmlFor='documentNumber-field' className='form-label'>
                    Número de documento
                  </Label>
                  <Input
                    type='text'
                    name='documentNumber'
                    id='documentNumber-field'
                    placeholder='Ingrese su número de documento'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.documentNumber}
                    invalid={Boolean(
                      formik.touched.documentNumber &&
                        formik.errors.documentNumber
                    )}
                  />
                  {formik.touched.documentNumber &&
                  formik.errors.documentNumber ? (
                    <FormFeedback>{formik.errors.documentNumber}</FormFeedback>
                  ) : null}
                </div>
              </Col>

              {leadOptions.length > 0 && (
                <Col lg={6}>
                  <div>
                    <FormGroup>
                      <Label for='leadSource'>¿Cómo supiste de nosotros?</Label>
                      <Input
                        id='leadSource'
                        name='leadSource'
                        type='select'
                        value={selectLead}
                        onChange={(e) => setSelectLead(e.target.value)}
                        required
                      >
                        <option value=''>Seleccione una opción</option>
                        {leadOptions.map((str) => (
                          <option key={str} value={str}>
                            {str}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </div>
                </Col>
              )}

              <Col lg={12}>
                <h5 className='card-title my-4'>
                  Información de cotización
                  {isSubmiting && formik.errors.products && (
                    <span
                      className='ms-2 text-danger fs-10 fw-normal'
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center'
                      }}
                    >
                      <i className='ri-information-line me-1' /> Debe
                      seleccionar al menos un producto
                    </span>
                  )}
                </h5>
              </Col>

              <Col lg={6}>
                <div>
                  <Label htmlFor='quotationType-field' className='form-label'>
                    Tipo de cotización
                    <span style={{ color: 'red' }} title='Obligatorio'>
                      *
                    </span>
                  </Label>
                  <Input
                    type='select'
                    name='quotationType'
                    id='quotationType-field'
                    disabled
                    onChange={(e) => {
                      formik.handleChange(e)
                      setProductsSelected([])
                      setOptionsData((prev) => {
                        const arr = [...prev]
                        return arr.map((obj) => {
                          const newObj = { ...obj }
                          delete newObj.isDisabled
                          return newObj
                        })
                      })
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.quotationType}
                    invalid={Boolean(
                      formik.touched.quotationType &&
                        formik.errors.quotationType
                    )}
                  >
                    <option value='CONTADO'>Contado</option>
                    <option value='CREDITO'>Crédito</option>
                    <option value='TERCEROS'>Terceros</option>
                  </Input>
                  {formik.touched.quotationType &&
                  formik.errors.quotationType ? (
                    <FormFeedback>{formik.errors.quotationType}</FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className='d-flex justify-content-end'>
                  <Button
                    color='success'
                    className='mt-4 d-flex align-items-center'
                    onClick={() => {
                      setOpen(true)
                      setSelectOneProduct(null)
                    }}
                  >
                    <i className='d-inline-block bx bx-add-to-queue me-1' />
                    <span className='d-inline-block'>Agregar producto</span>
                  </Button>
                </div>
              </Col>

              <Col lg={12}>
                <p className='text-muted'>
                  Productos: ({productsSelected.length})
                </p>
                <Fade>
                  {productsSelected.length > 0 && (
                    <UncontrolledAccordion>
                      {productsSelected.map(
                        ({
                          id,
                          name,
                          basePrice,
                          discountSet,
                          prices,
                          priceSet,
                          initialFee: initialFeeProd,
                          detail: detailProd
                        }) => (
                          <AccordionItem key={id}>
                            <AccordionHeader targetId={id}>
                              {name}
                            </AccordionHeader>
                            {discountSet ? (
                              <AccordionBody accordionId={id}>
                                <div className='d-flex flex-column'>
                                  <table className='table table-borderless mb-0 table-responsive'>
                                    <tbody>
                                      {formik.values.quotationType ===
                                        'CREDITO' && (
                                        <tr>
                                          <td className='fw-bold'>
                                            Cuota inicial
                                          </td>
                                          <td className='text-end'>
                                            {parsePrice(initialFeeProd)}
                                          </td>
                                        </tr>
                                      )}
                                      <tr>
                                        <td>Precio base:</td>
                                        <td
                                          className='text-end'
                                          id='cart-subtotal'
                                        >
                                          {parsePrice(basePrice)}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Descuentos:</td>
                                        <td
                                          className='text-end text-danger'
                                          id='cart-discount'
                                        >
                                          - {getTotal(discountSet)}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Otros conceptos:</td>
                                        <td
                                          className='text-end'
                                          id='cart-shipping'
                                        >
                                          {getTotal(
                                            priceSet,
                                            formik.values.quotationType !==
                                              'CREDITO'
                                              ? PRICE_TO_DELETE.PRENDA
                                              : null
                                          )}
                                        </td>
                                      </tr>
                                      <tr
                                        style={
                                          prices.length > 0
                                            ? {
                                                backgroundColor: '#f6ffeb',
                                                fontWeight: 500
                                              }
                                            : undefined
                                        }
                                      >
                                        <td>Otros precios</td>
                                        {prices.length > 0 ? (
                                          <td />
                                        ) : (
                                          <td className='text-end'>$0.00</td>
                                        )}
                                        {/* {formik.values.otherPrices} */}
                                      </tr>
                                      {prices.map((infoPrices) => (
                                        <tr key={infoPrices.name}>
                                          <td className='ps-4'>
                                            {infoPrices.name}:
                                          </td>
                                          <td className='text-end'>
                                            {parsePrice(infoPrices.price)}
                                          </td>
                                        </tr>
                                      ))}
                                      <tr className='table-active'>
                                        <th>Total:</th>
                                        <td className='text-end'>
                                          {prices.length > 0 ? (
                                            <span
                                              className='fw-semibold'
                                              id='cart-total'
                                            >
                                              {parsePrice(
                                                inversePrice(
                                                  getFullTotal(
                                                    basePrice,
                                                    discountSet,
                                                    priceSet,
                                                    formik.values
                                                      .quotationType !==
                                                      'CREDITO'
                                                      ? PRICE_TO_DELETE.PRENDA
                                                      : null
                                                  )
                                                ) + getTotalPrice(prices)
                                              )}
                                            </span>
                                          ) : (
                                            <span
                                              className='fw-semibold'
                                              id='cart-total'
                                            >
                                              {getFullTotal(
                                                basePrice,
                                                discountSet,
                                                priceSet,
                                                formik.values.quotationType !==
                                                  'CREDITO'
                                                  ? PRICE_TO_DELETE.PRENDA
                                                  : null
                                              )}
                                            </span>
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  {formik.values.quotationType ===
                                    'TERCEROS' && (
                                    <div className='mt-4'>
                                      <h5>
                                        Información de crédito de terceros
                                      </h5>
                                      {detailProd.length > 0 ? (
                                        <table className='table table-borderless mb-0 table-responsive'>
                                          <tbody>
                                            {detailProd.map((det) => (
                                              <tr key={det.name}>
                                                <td>{det.name}</td>
                                                <td className='text-end'>
                                                  {parsePrice(det.price)}
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      ) : (
                                        <div>No hay valores</div>
                                      )}
                                    </div>
                                  )}
                                  <div className='d-flex gap-2 justify-content-end align-items-center'>
                                    <Button
                                      color='info'
                                      className='d-flex align-items-center align-self-end mt-4'
                                      outline
                                      onClick={() => {
                                        const arr = [...optionsData]
                                        const find = arr.find(
                                          (option) => option.label === name
                                        )
                                        if (find) {
                                          setSelectOneProduct(find)
                                          formik.setFieldValue('otherPrices', [
                                            ...prices
                                          ])
                                          if (
                                            formik.values.quotationType ===
                                            'CREDITO'
                                          ) {
                                            setInitialFee(
                                              initialFeeProd.toString()
                                            )
                                          }
                                          if (
                                            formik.values.quotationType ===
                                            'TERCEROS'
                                          ) {
                                            setDetail([...detailProd])
                                          }
                                          setOpen(true)
                                        }
                                      }}
                                    >
                                      <i className='bx bx-pencil d-block me-2' />
                                      <span className='d-block'>Editar</span>
                                    </Button>
                                    <Button
                                      color='danger'
                                      className='d-flex align-items-center align-self-end mt-4'
                                      outline
                                      onClick={() => {
                                        setProductsSelected((prev) => {
                                          const newArr = [...prev]
                                          return newArr.filter(
                                            (prod) => prod.id !== id
                                          )
                                        })
                                        setOptionsData((prev) => {
                                          const arr = [...prev]
                                          const find = arr.find(
                                            (option) => option.value === id
                                          )
                                          if (find) {
                                            delete find.isDisabled
                                          }
                                          return arr
                                        })
                                      }}
                                    >
                                      <i className='bx bx-trash d-block me-2' />
                                      <span className='d-block'>Eliminar</span>
                                    </Button>
                                  </div>
                                </div>
                              </AccordionBody>
                            ) : (
                              <AccordionBody accordionId={id}>
                                Cargando...
                              </AccordionBody>
                            )}
                          </AccordionItem>
                        )
                      )}
                    </UncontrolledAccordion>
                  )}
                </Fade>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <div className='hstack gap-2 justify-content-end'>
              {/* <Button
                      color='light'
                      onClick={() => {
                        setOpen(false)
                      }}
                    >
                      Cancelar{' '}
                    </Button> */}
              <Button
                type='submit'
                color='success'
                id='add-btn'
                disabled={loadingCreate}
              >
                {loadingCreate && <Spinner size='sm' />}
                Crear cotización
              </Button>
            </div>
          </CardFooter>
        </Form>
      </Card>
      <Modal isOpen={open} toggle={toggle} centered size='lg'>
        <ModalHeader className='bg-soft-info p-3' toggle={toggle}>
          Agregar producto
        </ModalHeader>
        <Form onSubmit={formik.handleSubmit}>
          <ModalBody>
            <input type='hidden' id='id-field' />
            <Row className='g-3'>
              <Col lg={12}>
                <FormGroup>
                  <Label className='form-label'>Productos</Label>
                  <Select
                    className='basic-single'
                    /* styles={{
                      control: (provided, state) => ({
                        ...provided,
                        borderColor: formik.errors.products
                          ? 'red'
                          : provided.borderColor,
                        ':hover': {
                          borderColor: state.isFocused
                            ? 'yellow'
                            : provided.borderColor
                        }
                      })
                    }} */
                    classNamePrefix='select'
                    placeholder='Seleccione un producto'
                    value={selectOneProduct}
                    // defaultValue={0}
                    isClearable
                    isSearchable
                    onChange={(e) => {
                      if (e) {
                        setSelectOneProduct({
                          id: e.value,
                          name: e.label,
                          ...e
                        })
                      } else {
                        setSelectOneProduct(null)
                      }
                    }}
                    id='products'
                    name='product'
                    options={optionsData}
                    noOptionsMessage={() => 'No se encontró producto'}
                  />

                  {/* {formik.touched.products && formik.errors.products ? (
                    <FormFeedback>{formik.errors.products}</FormFeedback>
                  ) : null} */}
                </FormGroup>
              </Col>

              {selectOneProduct != null &&
                formik.values.quotationType === 'CREDITO' && (
                  <Col lg={12}>
                    <h5>Cuota incial</h5>
                    <FormGroup>
                      <Label>Valor cuota inicial</Label>
                      <InputCurrency
                        placeholder='Precio'
                        // decimalsLimit={0}
                        // decimalSeparator=','
                        // groupSeparator='.'
                        className='form-control'
                        // prefix='$ '
                        value={initialFee}
                        onValueChange={(value) => setInitialFee(value)}
                      />
                    </FormGroup>
                  </Col>
                )}

              {selectOneProduct != null &&
                formik.values.quotationType === 'TERCEROS' && (
                  <>
                    <Col lg={12}>
                      <h5 className='my-2 text-info'>
                        Información de crédito de terceros
                      </h5>
                      <Row className='align-items-center'>
                        <Col lg={5}>
                          <FormGroup style={{ color: '#003153' }}>
                            <Label htmlFor='nameThirdPrice'>Nombre</Label>
                            <Input
                              id='nameThirdPrice'
                              name='nameThirdPrice'
                              value={formThirdCredit.values.name}
                              placeholder='Ingrese nombre del precio'
                              onBlur={() => {
                                setFormThirdCredit((prev) => ({
                                  ...prev,
                                  touched: { ...prev.touched, name: true }
                                }))
                              }}
                              onChange={(e) =>
                                setFormThirdCredit((prev) => ({
                                  ...prev,
                                  values: {
                                    ...prev.values,
                                    name: e.target.value
                                  }
                                }))
                              }
                              invalid={Boolean(
                                formThirdCredit.touched.name &&
                                  formThirdCredit.errors.name
                              )}
                            />
                            {formThirdCredit.touched.name &&
                              formThirdCredit.errors.name && (
                                <FormFeedback type='invalid'>
                                  {formThirdCredit.errors.name}
                                </FormFeedback>
                              )}
                          </FormGroup>
                        </Col>
                        <Col lg={5}>
                          <FormGroup style={{ color: '#003153' }}>
                            <Label>Valor</Label>
                            <InputCurrency
                              placeholder='Precio'
                              // decimalsLimit={0}
                              // decimalSeparator=','
                              // groupSeparator='.'
                              className={`form-control ${
                                formThirdCredit.touched.price &&
                                formThirdCredit.errors.price
                                  ? 'is-invalid'
                                  : ''
                              } `}
                              // prefix='$ '
                              value={formThirdCredit.values.price}
                              onValueChange={(value) =>
                                setFormThirdCredit((prev) => ({
                                  ...prev,
                                  values: { ...prev.values, price: value }
                                }))
                              }
                              onBlur={() => {
                                setFormThirdCredit((prev) => ({
                                  ...prev,
                                  touched: { ...prev.touched, price: true }
                                }))
                              }}
                            />
                            {formThirdCredit.touched.price &&
                              formThirdCredit.errors.price && (
                                <FormFeedback type='invalid'>
                                  {formThirdCredit.errors.price}
                                </FormFeedback>
                              )}
                          </FormGroup>
                        </Col>
                        <Col lg={2}>
                          <div className='form-label' />
                          <button
                            type='button'
                            className='btn btn-outline-info'
                            disabled={
                              formThirdCredit.errors.name ||
                              formThirdCredit.errors.price ||
                              formThirdCredit.values.name === ''
                            }
                            onClick={() => {
                              if (
                                formThirdCredit.errors.name &&
                                formThirdCredit.errors.price
                              ) {
                                return
                              }
                              const { name, price } = formThirdCredit.values
                              const idx = detail.findIndex(
                                (el) => el.name === name
                              )
                              if (idx > -1) {
                                setFormThirdCredit((prev) => ({
                                  ...prev,
                                  errors: {
                                    ...prev.errors,
                                    name: 'Nombre ya existe'
                                  }
                                }))
                                return
                              }
                              const otherPrices = { name, price }
                              setDetail((pre) => [...pre, otherPrices])
                              setFormThirdCredit(INIT_OTHER_PRICES)
                            }}
                          >
                            <i className='bx bx-plus' />
                          </button>
                        </Col>
                      </Row>
                    </Col>
                    {detail.length > 0 ? (
                      <Row style={{ borderBottom: '1px solid #e9e9e9' }}>
                        <Table responsive cellSpacing={4}>
                          <thead>
                            <tr>
                              <th scope='col' style={{ color: '#003153' }}>
                                Nombre
                              </th>
                              <th scope='col' style={{ color: '#003153' }}>
                                Valor
                              </th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {detail.map((info, i) => (
                              <tr key={info.name + i}>
                                <td>{info.name}</td>
                                <td>{parsePrice(info.price)}</td>
                                <td>
                                  <div className='d-flex align-items-center gap-1'>
                                    <button
                                      className='btn btn-outline'
                                      title='Editar'
                                      onClick={() => {
                                        setDetail((pre) =>
                                          pre.filter(
                                            (infoPrev) =>
                                              infoPrev.name !== info.name
                                          )
                                        )
                                        setFormThirdCredit({
                                          ...INIT_OTHER_PRICES,
                                          values: {
                                            name: info.name,
                                            price: info.price
                                          }
                                        })
                                        const input =
                                          document.getElementById(
                                            'nameThirdPrice'
                                          )
                                        if (input) {
                                          input.focus()
                                        }
                                      }}
                                    >
                                      <i className='bx bx-pencil' />
                                    </button>
                                    <CloseButton
                                      title='Eliminar'
                                      onClick={() => {
                                        setDetail((pre) =>
                                          pre.filter(
                                            (infoPrev) =>
                                              infoPrev.name !== info.name
                                          )
                                        )
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Row>
                    ) : (
                      <Row style={{ borderBottom: '1px solid #e9e9e9' }}>
                        <Col lg={12}>
                          <Alert
                            color='warning'
                            className='d-flex align-items-center gap-1'
                          >
                            <i className='bx bx-info-circle fs-4' /> No hay
                            precios agregados!
                          </Alert>
                        </Col>
                      </Row>
                    )}
                  </>
                )}

              {selectOneProduct != null && (
                <>
                  <Col lg={12}>
                    <h5 className='my-2'>Agregar otros precios</h5>
                    <Row className='align-items-center'>
                      <Col lg={5}>
                        <FormGroup>
                          <Label>Nombre</Label>
                          <Input
                            id='nameOtherPrice'
                            name='nameOtherPrice'
                            value={formOtherPrices.values.name}
                            placeholder='Ingrese nombre del precio'
                            onBlur={() => {
                              setFormOtherPrices((prev) => ({
                                ...prev,
                                touched: { ...prev.touched, name: true }
                              }))
                            }}
                            onChange={(e) =>
                              setFormOtherPrices((prev) => ({
                                ...prev,
                                values: { ...prev.values, name: e.target.value }
                              }))
                            }
                            invalid={Boolean(
                              formOtherPrices.touched.name &&
                                formOtherPrices.errors.name
                            )}
                          />
                          {formOtherPrices.touched.name &&
                            formOtherPrices.errors.name && (
                              <FormFeedback type='invalid'>
                                {formOtherPrices.errors.name}
                              </FormFeedback>
                            )}
                        </FormGroup>
                      </Col>
                      <Col lg={5}>
                        <FormGroup>
                          <Label>Valor</Label>
                          <InputCurrency
                            placeholder='Precio'
                            // decimalsLimit={0}
                            // decimalSeparator=','
                            // groupSeparator='.'
                            className={`form-control ${
                              formOtherPrices.touched.price &&
                              formOtherPrices.errors.price
                                ? 'is-invalid'
                                : ''
                            } `}
                            // prefix='$ '
                            value={formOtherPrices.values.price}
                            onValueChange={(value) =>
                              setFormOtherPrices((prev) => ({
                                ...prev,
                                values: { ...prev.values, price: value }
                              }))
                            }
                            onBlur={() => {
                              setFormOtherPrices((prev) => ({
                                ...prev,
                                touched: { ...prev.touched, price: true }
                              }))
                            }}
                          />

                          {formOtherPrices.touched.price &&
                            formOtherPrices.errors.price && (
                              <FormFeedback type='invalid'>
                                {formOtherPrices.errors.price}
                              </FormFeedback>
                            )}
                        </FormGroup>
                      </Col>
                      <Col lg={2}>
                        <div className='form-label' />
                        <button
                          type='button'
                          className='btn btn-outline-success'
                          disabled={
                            formOtherPrices.errors.name ||
                            formOtherPrices.errors.price ||
                            formOtherPrices.values.name === ''
                          }
                          onClick={() => {
                            if (
                              formOtherPrices.errors.name &&
                              formOtherPrices.errors.price
                            ) {
                              return
                            }
                            const { name, price } = formOtherPrices.values
                            const idx = formik.values.otherPrices.findIndex(
                              (el) => el.name === name
                            )
                            if (idx > -1) {
                              setFormOtherPrices((prev) => ({
                                ...prev,
                                errors: {
                                  ...prev.errors,
                                  name: 'Nombre ya existe'
                                }
                              }))
                              return
                            }
                            const otherPrices = { name, price }
                            formik.setFieldValue('otherPrices', [
                              ...formik.values.otherPrices,
                              otherPrices
                            ])
                            setFormOtherPrices(INIT_OTHER_PRICES)
                          }}
                        >
                          <i className='bx bx-plus' />
                        </button>
                      </Col>
                    </Row>
                  </Col>
                  {formik.values.otherPrices.length > 0 ? (
                    <Row>
                      <Table responsive cellSpacing={4}>
                        <thead>
                          <tr>
                            <th scope='col'>Nombre</th>
                            <th scope='col'>Valor</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {formik.values.otherPrices.map((info, i) => (
                            <tr key={info.name + i}>
                              <td>{info.name}</td>
                              <td>{parsePrice(parsePriceToNum(info.price))}</td>
                              <td>
                                <div className='d-flex align-items-center gap-1'>
                                  <button
                                    className='btn btn-outline'
                                    title='Editar'
                                    onClick={() => {
                                      const newArr = [
                                        ...formik.values.otherPrices
                                      ]

                                      formik.setFieldValue(
                                        'otherPrices',
                                        newArr.filter(
                                          (infoPrev) =>
                                            infoPrev.name !== info.name
                                        )
                                      )
                                      setFormOtherPrices({
                                        ...INIT_OTHER_PRICES,
                                        values: {
                                          name: info.name,
                                          price: info.price
                                        }
                                      })
                                      const input =
                                        document.getElementById(
                                          'nameOtherPrice'
                                        )
                                      if (input) {
                                        input.focus()
                                      }
                                    }}
                                  >
                                    <i className='bx bx-pencil' />
                                  </button>
                                  <CloseButton
                                    title='Eliminar'
                                    onClick={() => {
                                      const newArr = [
                                        ...formik.values.otherPrices
                                      ]

                                      formik.setFieldValue(
                                        'otherPrices',
                                        newArr.filter(
                                          (infoPrev) =>
                                            infoPrev.name !== info.name
                                        )
                                      )
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Row>
                  ) : (
                    <Row>
                      <Col lg={12}>
                        <Alert
                          color='primary'
                          className='d-flex align-items-center gap-1'
                        >
                          <i className='bx bx-info-circle fs-4' /> No hay
                          precios agregados!
                        </Alert>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className='hstack gap-2 justify-content-end'>
              <Button
                color='light'
                onClick={() => {
                  setOpen(false)
                }}
              >
                Cancelar
              </Button>
              <Button
                color='info'
                id='add-btn-product'
                onClick={() => {
                  if (!selectOneProduct) return

                  setOptionsData((prev) => {
                    const arr = [...prev]
                    const find = arr.find(
                      (option) => option.value === selectOneProduct.id
                    )
                    if (find) {
                      find.isDisabled = true
                    }
                    return arr
                  })
                  setProductsSelected((prev) => {
                    const newArr = [...prev]
                    // console.log('newArr', newArr)
                    // console.log('selectOneProduct', selectOneProduct)
                    const find = newArr.find(
                      (prod) => prod.id === selectOneProduct.value
                    )
                    // console.log('find', find)
                    if (find) {
                      find.prices = formik.values.otherPrices
                      find.initialFee =
                        formik.values.quotationType === 'CREDITO'
                          ? parsePriceToNum(initialFee)
                          : undefined
                      find.detail =
                        formik.values.quotationType === 'TERCEROS'
                          ? detail
                          : undefined
                      return newArr
                    }
                    const obj = {
                      ...selectOneProduct,
                      prices: formik.values.otherPrices,
                      initialFee:
                        formik.values.quotationType === 'CREDITO'
                          ? parsePriceToNum(initialFee)
                          : undefined,
                      detail:
                        formik.values.quotationType === 'TERCEROS'
                          ? detail
                          : undefined
                    }
                    return [...newArr, { ...obj }]
                  })
                  if (!infoZoneOrAgencie.zoneOrAgency) {
                    toast.error('Error: No tiene agencia asignada')
                  }
                  gqlGetInfoProduct({
                    variables: {
                      id: selectOneProduct.id,
                      agencyId: infoZoneOrAgencie.zoneOrAgency?.split('***')[1]
                    }
                  }).then(() => {
                    setOpen(false)
                    setSelectOneProduct(null)
                    formik.setFieldValue('otherPrices', [])
                    setFormOtherPrices(INIT_OTHER_PRICES)
                    setFormThirdCredit(INIT_OTHER_PRICES)
                    setInitialFee('0')
                    setDetail([])
                  })
                }}
                disabled={
                  !selectOneProduct ||
                  loadingInfoProduct ||
                  formOtherPrices.values.name.length > 0 ||
                  formThirdCredit.values.name.length > 0
                }
              >
                {/* {loadingCreate && (
                  <div
                    className='spinner-border me-2'
                    style={{ width: '16px', height: '16px' }}
                    role='status'
                  >
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                )} */}
                {loadingInfoProduct && <Spinner size='sm' className='me-1' />}
                Añadir
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  )
}
