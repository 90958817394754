import React from 'react'
import { Navigate } from 'react-router-dom'
import { useGetUser } from '../hooks/user'

const ROUTES_CRM = ['/producto', '/categoria', '/cotizacion', '/facturacion']

export const AllowedCrm = ({ route, ...props }) => {
  const { user } = useGetUser()
  const { crm } = user.tokenAuth
  console.log('crm', crm)
  console.log('route', route)
  if (!crm && ROUTES_CRM.some((rout) => route.startsWith(rout))) {
    return <Navigate to='/dashboard' />
  }
  return <>{props.children}</>
}
