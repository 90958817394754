import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// import CurrencyInput from 'react-currency-input-field'

// Import Images

import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  Input,
  Button,
  Spinner,
  Badge,
  Table,
  UncontrolledTooltip
} from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'

// import TableContainer from '../../../Components/Common/TableContainer'

// Formik

// Export Modal

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useLazyQuery } from '@apollo/client'
// import { GET_ZONAS } from '../../../graphql/queries/getPuntos'
import { hasPermission, parseId } from '../../../helpers'
import { GET_ALL_MOTOS_USADAS } from '../../../graphql/queries/getMotosUsadas'

import {
  ALL_PERMISSIONS,
  ERROR_MESSAGES,
  STATUS_USED_MOTOS
} from '../../../helpers/consts'
import { useGetUser } from '../../../hooks/user'
import { useDisclosure } from '../../../hooks/useDisclosure'
import { ModalAddUsedMoto } from '../../../Components/Own/MotoUsada/ModalAddUsedMoto'
import { API } from '../../../graphql/client'

const MotosUsadasPage = () => {
  const [gqlGetMotos, { data, loading, error }] = useLazyQuery(
    GET_ALL_MOTOS_USADAS,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const navigate = useNavigate()

  const { logout, permissions } = useGetUser()

  const [dataTable, setDataTable] = useState([])

  const [isSearch, setIsSearch] = useState(false)

  const [searchInput, setSearchInput] = useState('')
  const [searchValue, setSearchValue] = useState({
    key: '',
    value: ''
  })

  const [statusSelect, setStatusSelect] = useState('')
  // const [oneCompany, setOneCompany] = useState({})
  // delete Company
  // const [deleteModalMulti, setDeleteModalMulti] = useState(false)
  const { isOpen, onClose, onOpen } = useDisclosure()

  const [pagination, setPagination] = useState({
    offset: 0,
    page: 1
  })
  const [isPagination, setIsPagination] = useState(false)

  const [loadingExport, setLoadingExport] = useState(false)

  // const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([])
  // const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false)
  // Export Modal
  // const [isExportCSV, setIsExportCSV] = useState(false)

  /* useEffect(() => {
    if (companies && !companies.length) {
      dispatch(onGetCompanies());
    }
  }, [dispatch, companies]); */

  useEffect(() => {
    const variables = {
      offset: pagination.offset
    }
    if (searchValue.key) {
      variables[searchValue.key] = searchValue.value
    }
    if (statusSelect) {
      variables.status_Iexact = statusSelect
    }
    gqlGetMotos({
      variables
    })
  }, [pagination.offset, searchValue, statusSelect])

  useEffect(() => {
    if (
      !hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_MOTOCICLETA_USUADA)
    ) {
      navigate('/dashboard')
    }
  }, [permissions])

  useEffect(() => {
    if (error) {
      toast.error(`ERROR! ${error.message}`)
      if (error.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [error])
  useEffect(() => {
    if (data && data.listUsedMotorcycle) {
      if (isPagination) {
        setDataTable((prev) => [...prev, ...data.listUsedMotorcycle.edges])
        setPagination((prev) => ({ ...prev, page: prev.page + 1 }))
        setIsPagination(false)
      } else {
        setDataTable(data.listUsedMotorcycle.edges)
      }
    }
  }, [data])

  const hanldeSubmit = (e) => {
    e.preventDefault()
    if (searchInput.length >= 3) {
      const key = `${Number(searchInput) ? 'ownerDocument' : 'name'}`
      setIsSearch(true)
      setPagination({ page: 1, offset: 0 })
      setSearchValue({
        key,
        value: searchInput
      })
    }
  }

  const handleClickDown = async () => {
    const token = JSON.parse(window.localStorage.getItem('token'))
    if (!token) return
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)
    myHeaders.append(
      'Cookie',
      'csrftoken=Od45hbfeAuJX7uEQBccJfgkXhva8KgvocUGB9FUlIlL5zavQTjre5fyld4D7CPt3'
    )

    const requestOptions = {
      method: 'GET',
      headers: myHeaders
    }

    setLoadingExport(true)

    try {
      const res = await fetch(`${API}/export/used-motorcycle`, requestOptions)
      if (!res.ok) throw new Error('Error al exportar')
      const blob = await res.blob()
      // console.log('blob', blob)
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `Motos-usadas-${
        new Date().toISOString().split('T')[0]
      }.xlsx`
      document.body.appendChild(link)
      setLoadingExport(false)
      link.click()
    } catch (err) {
      setLoadingExport(false)
      toast.error(`ERROR: ${err?.message || 'Algo ocurrió'}`)
    }
  }

  document.title = 'Motocicletas usadas | Total Sales'
  if (loading && !isSearch && !isPagination) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Motocicleta Usadas' pageTitle='CRM' />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className='d-flex justify-content-end align-items-center flex-wrap gap-2'>
                    <div className=''>
                      <button className='btn btn-light text-light' disabled>
                        <i className='ri-add-fill me-1 align-bottom' /> Agregar
                        motocicleta usada
                      </button>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
            <Col lg={12}>
              <Card
                className='d-flex align-items-center justify-content-center'
                style={{ height: '30vh' }}
              >
                <Spinner color='dark' style={{ width: '3em', height: '3em' }} />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Motocicletas Usadas' pageTitle='CRM' />

          <Row>
            <Col lg={12}>
              <Card>
                {hasPermission(
                  permissions,
                  ALL_PERMISSIONS.PUEDE_ANADIR_MOTOCICLETA_USUADA
                ) && (
                  <CardHeader>
                    <div className='d-flex align-items-center justify-content-end flex-wrap gap-2'>
                      <div className=''>
                        <button
                          className='btn btn-info add-btn'
                          onClick={onOpen}
                        >
                          <i className='ri-add-fill me-1 align-bottom' />{' '}
                          Agregar motocicleta usada
                        </button>
                      </div>
                      <Button
                        className='d-flex align-items-center gap-1'
                        color='success'
                        onClick={handleClickDown}
                        disabled={loadingExport}
                      >
                        {loadingExport ? (
                          <Spinner size='sm' />
                        ) : (
                          <i className='mdi mdi-microsoft-excel' />
                        )}
                        Descargar
                      </Button>
                    </div>
                  </CardHeader>
                )}
              </Card>
            </Col>
            <Col>
              <Card id='companyList'>
                <CardHeader className='mb-2'>
                  <Row>
                    <Col lg={6}>
                      <form onSubmit={hanldeSubmit}>
                        <div className='search-box me-2 mb-2 d-inline-block w-100'>
                          <input
                            id='search-bar-0'
                            type='search'
                            value={searchInput}
                            onChange={(e) => {
                              setSearchInput(e.target.value)
                              if (e.target.value === '') {
                                setPagination({
                                  page: 1,
                                  offset: 0
                                })
                                setSearchValue({
                                  key: '',
                                  value: ''
                                })
                              }
                            }}
                            name='searchInput'
                            className='form-control search /'
                            placeholder='Ingresa cédula o nombre'
                          />
                          <i className='bx bx-search-alt search-icon' />
                        </div>
                      </form>
                    </Col>

                    <Col>
                      <select
                        className='form-select'
                        data-choices
                        data-choices-search-false
                        name='choices-single-default2'
                        id='choices-single-default2'
                        value={statusSelect}
                        onChange={(e) => {
                          setStatusSelect(e.target.value)
                          setPagination({ page: 1, offset: 0 })
                        }}
                      >
                        <option value=''>Seleccione estado</option>
                        {Object.keys(STATUS_USED_MOTOS).map((statusStr) => (
                          <option
                            key={statusStr}
                            value={STATUS_USED_MOTOS[statusStr]}
                          >
                            {STATUS_USED_MOTOS[statusStr]}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className='pt-0'>
                  {dataTable.length === 0 && !loading ? (
                    <div
                      className='d-flex justify-content-center align-items-center'
                      style={{ height: '40vh' }}
                    >
                      <h6>No hay Motocicletas usadas</h6>
                    </div>
                  ) : loading && isSearch ? (
                    <div
                      className='d-flex justify-content-center align-items-center flex-column gap-2'
                      style={{ height: '20vh' }}
                    >
                      <Spinner style={{ width: '3em', height: '3em' }} />
                      <span className='d-block'>cargando...</span>
                    </div>
                  ) : (
                    <div>
                      <div className='table-responsive table-card mb-1'>
                        <Table hover className='align-middle table-nowrap'>
                          <thead className='table-light text-muted'>
                            <tr>
                              <th>Propietario</th>
                              <th>No. Documento</th>
                              <th>Marca</th>
                              <th>Linea</th>
                              <th>Modelo</th>
                              <th>Placa</th>
                              <th>En Venta</th>
                              <th>Estado</th>
                              <th>Bodega</th>
                              <th />
                            </tr>
                          </thead>

                          <tbody>
                            {dataTable
                              .slice(
                                10 * pagination.page - 10,
                                10 * pagination.page
                              )
                              .map(({ node }) => (
                                <tr key={node.id}>
                                  <td>{node.ownerName}</td>
                                  <td>{node.ownerDocument}</td>
                                  <td>{node.trademark}</td>
                                  <td>{node.line}</td>
                                  <td>{node.model}</td>
                                  <td>{node.plaque}</td>
                                  <td>
                                    <Badge
                                      color={node.forSale ? 'danger' : 'dark'}
                                    >
                                      {node.forSale ? 'SI' : 'NO'}
                                    </Badge>
                                  </td>
                                  <td>{STATUS_USED_MOTOS[node.status]}</td>
                                  <td>
                                    {node.cellar.name} - {node.cellar.city}
                                  </td>
                                  {/* <td>{renderStatus(node.status)}</td> */}
                                  <td>
                                    <ul className='list-inline hstack gap-2 mb-0'>
                                      <li className='list-inline-item'>
                                        <Link
                                          to={`/motocicleta-usada/${parseId(
                                            node.id
                                          )}`}
                                          className='text-primary d-inline-block'
                                          id={
                                            'view' + parseId(node.id).toString()
                                          }
                                          target='_blank'
                                          rel='noopener noreferrer'
                                        >
                                          <i className='ri-eye-fill fs-16' />
                                        </Link>
                                        <UncontrolledTooltip
                                          placement='top'
                                          target={
                                            'view' + parseId(node.id).toString()
                                          }
                                          trigger='hover'
                                        >
                                          Ver
                                        </UncontrolledTooltip>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </div>
                      <Row className='justify-content-md-end justify-content-center align-items-center p-2'>
                        <Col className='col-md-auto'>
                          <div className='d-flex gap-1'>
                            <Button
                              color='primary'
                              disabled={pagination.page <= 1}
                              onClick={() => {
                                setPagination((prev) => ({
                                  ...prev,
                                  page: prev.page - 1
                                }))
                              }}
                              // onClick={previousPage}
                              // disabled={!canPreviousPage}
                            >
                              {'<'}
                            </Button>
                          </div>
                        </Col>
                        <Col className='col-md-auto d-none d-md-block'>
                          Pág{' '}
                          <strong>
                            {pagination.page} de{' '}
                            {Math.ceil(dataTable.length / 10)}
                          </strong>
                        </Col>
                        <Col className='col-md-auto'>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault()
                              if (!Number(pagination.page)) {
                                setPagination((prev) => ({
                                  ...prev,
                                  page: 1
                                }))
                              }
                              // console.log('pagination', pagination)
                            }}
                          >
                            <Input
                              type='number'
                              min={1}
                              style={{ width: 70 }}
                              // max={pageOptions.length}
                              value={pagination.page}
                              onChange={(e) => {
                                const { value } = e.target
                                if (value === '') {
                                  setPagination((prev) => ({
                                    ...prev,
                                    page: ''
                                  }))
                                  return
                                }
                                if (!Number(value)) {
                                  setPagination((prev) => ({
                                    ...prev,
                                    page: 1
                                  }))
                                  return
                                }
                                if (
                                  Number(value) <=
                                  Math.ceil(dataTable.length / 10)
                                ) {
                                  setPagination((prev) => ({
                                    ...prev,
                                    page: Number(value)
                                  }))
                                }
                              }}
                            />
                          </form>
                        </Col>

                        <Col className='col-md-auto'>
                          <div className='d-flex gap-1'>
                            <Button
                              color='primary'
                              disabled={
                                !data?.listUsedMotorcycle?.pageInfo
                                  ?.hasNextPage &&
                                pagination.page ===
                                  Math.ceil(dataTable.length / 10)
                              }
                              onClick={() => {
                                if (
                                  pagination.page <
                                  Math.ceil(dataTable.length / 10)
                                ) {
                                  return setPagination((prev) => ({
                                    ...prev,
                                    page: prev.page + 1
                                  }))
                                }
                                const start = parseId(
                                  data?.listUsedMotorcycle?.pageInfo
                                    ?.startCursor
                                )
                                setIsPagination(true)
                                setPagination((prev) => ({
                                  ...prev,
                                  offset: start + 10
                                }))
                              }}
                              // disabled={!canNextPage}
                            >
                              {loading ? (
                                <Spinner size='sm' color='light' />
                              ) : (
                                '>'
                              )}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {isOpen && <ModalAddUsedMoto isOpen={isOpen} onClose={onClose} />}
    </>
  )
}

export default MotosUsadasPage
