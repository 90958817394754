import { gql } from '@apollo/client'

// export const GET_PUNTOS = gql``;

export const GET_PUNTOS_EMP_COMER = gql`
  query listCommercialCompany($id: Float) {
    listCommercialCompany(id: $id) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          address
          created
          id
          name
          nit
          phoneNumber
          updated
          image
          crm
        }
      }
    }
  }
`

export const GET_ZONAS = gql`
  query listZones($id: Float) {
    listZones(id: $id) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          created
          name
          description
          commercialCompany {
            id
            name
          }
          active
          agencySet {
            edges {
              cursor
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

export const GET_INFO_EMP_COMER = gql`
  query listOneCommercialCompany {
    listCommercialCompany {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`

export const GET_AGENCIAS = gql`
  query listAgency($id: Float) {
    listAgency(id: $id) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          name
          phoneNumber
          address
          active
          city
          zone {
            name
            id
          }
        }
      }
    }
  }
`

export const GET_INFO_ZONAS = gql`
  query listOneZone($id: Float) {
    listZones(id: $id) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const GET_COMPANY_INFO = gql`
  query getOrganization {
    getOrganization {
      id
      name
      image
    }
  }
`
