import moment from 'moment'
import React from 'react'
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table
} from 'reactstrap'
import { parsePrice } from '../../../helpers'

export const ModalInfoOnePay = ({ isOpen, onClose, infoOnePay }) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalHeader toggle={onClose} className='text-uppercase'>
        Cuota {infoOnePay.qouta}
      </ModalHeader>
      <ModalBody>
        <h6 className='mb-4'>
          Información de pago de la cuota {infoOnePay.qouta} con vencimiento{' '}
          {moment(infoOnePay.endDate).format('DD [de] MMM YYYY')}
        </h6>
        {infoOnePay.info.map(({ node }, i) => (
          <div key={node.id}>
            {i !== 0 && <hr />}
            <h6 className='text-center my-4'>Detalles del pago</h6>
            <div className='my-4'>
              <p className='ms-2 text-primary'>
                Pago por valor de{' '}
                <span className='fw-bold'>
                  {parsePrice(
                    node.quotapaymentdetailSet.edges.filter(
                      ({ node }) => node.quota.id === infoOnePay.qoutaId
                    )?.[0].node.payment.value
                  )}
                </span>{' '}
                el día{' '}
                {moment(
                  node.quotapaymentdetailSet.edges.filter(
                    ({ node }) => node.quota.id === infoOnePay.qoutaId
                  )[0].node.payment.created
                ).format('DD MMM YYYY [a las] hh:mm A')}
              </p>
              {node.quotapaymentdetailSet.edges.filter(
                ({ node }) => node.quota.id !== infoOnePay.qoutaId
              ).length > 0 && (
                <Alert
                  color='warning'
                  className='d-flex align-items-center gap-1'
                >
                  <i className='mdi mdi-alert-circle-outline fs-2' />
                  Este pago afectó a más de una cuota
                </Alert>
              )}
            </div>
            <div className='table-responsive'>
              <Table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Capital</th>
                    <th>Interés</th>
                    <th>Interés Mora</th>
                    <th>Gestión de cobro</th>
                  </tr>
                  {node.quotapaymentdetailSet.edges
                    .filter(({ node }) => node.quota.id === infoOnePay.qoutaId)
                    .map(({ node: nodeQuota }, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{parsePrice(nodeQuota.capital)}</td>
                        <td>{parsePrice(nodeQuota.interest)}</td>
                        <td>{parsePrice(nodeQuota.penaltyInterest)}</td>
                        <td>{parsePrice(nodeQuota.paymentManagement)}</td>
                      </tr>
                    ))}
                </thead>
              </Table>
            </div>
            <h6>Detalles del AVAL</h6>
            <div className='table-responsive'>
              <Table borderless>
                <thead>
                  {node.endorsementpaymentdetailSet.edges
                    .filter(
                      ({ node }) =>
                        node.endorsement.quota.id === infoOnePay.qoutaId
                    )
                    .map(({ node: nodeAval }, id) => (
                      <tr key={id}>
                        <th>Valor</th>
                        <td>{parsePrice(nodeAval.endorsement.endorsement)}</td>
                      </tr>
                    ))}
                  {node.endorsementpaymentdetailSet.edges.filter(
                    ({ node }) =>
                      node.endorsement.quota.id === infoOnePay.qoutaId
                  ).length === 0 && (
                    <tr>
                      <td colSpan={3} className='text-center'>
                        No hay información de pago de Aval
                      </td>
                    </tr>
                  )}
                </thead>
              </Table>
            </div>
          </div>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Aceptar</Button>
      </ModalFooter>
    </Modal>
  )
}
