import { useLazyQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Spinner,
  TabPane,
  Table,
  UncontrolledAccordion
} from 'reactstrap'
import SimpleBar from 'simplebar-react'
import { GET_PAYMENTS_OBLIGATION_WALLET } from '../../../graphql/queries/getCreditsWallet'
import { toast } from 'react-toastify'
import { parseId, parsePrice } from '../../../helpers'

import imgEmpty from '../../../assets/images/totalsales/empty.svg'
import imgSelectPayment from '../../../assets/images/totalsales/selectPayment.svg'
import moment from 'moment'

const TAB_INDEX = '4'

export const TabViewPayments = ({ activeTab, obligationId }) => {
  const [gqlGetPayments, { data, loading, error }] = useLazyQuery(
    GET_PAYMENTS_OBLIGATION_WALLET,
    { fetchPolicy: 'no-cache' }
  )
  /** @typedef {{id: number, detallePagoAval: {value: string}[], detallePagoCuota: {penaltyInterest: string, interest: string, capital: string}[]}} ICouta */
  /** @type {[infoPay: {cuotas: ICouta[], index: number, total: string}, setInfoPay: React.Dispatch<React.SetStateAction<{}>>]} */
  const [infoPay, setInfoPay] = useState(null)

  useEffect(() => {
    if (activeTab === TAB_INDEX) {
      gqlGetPayments({
        variables: {
          obligationId: parseId(obligationId)
        }
      })
    }
  }, [activeTab])

  useEffect(() => {
    if (!error) return
    toast.error('Error: ' + error.message)
  }, [error])

  if (loading || data == null) {
    return (
      <TabPane tabId={TAB_INDEX} className='p-4'>
        <Row>
          <Col lg={4}>
            <Card
              className='d-flex align-items-center justify-content-center'
              style={{ height: '40vh' }}
            >
              <Spinner />
            </Card>
          </Col>
          <Col lg={8}>
            <Card
              className='d-flex align-items-center justify-content-center'
              style={{ height: '40vh' }}
            >
              <Spinner />
            </Card>
          </Col>
        </Row>
      </TabPane>
    )
  }

  /** @type {{fechaPago: string, valorPago: string, cuotas: ICouta[]}[]} */
  const payments = data.listObligationPayment
  return (
    <TabPane tabId={TAB_INDEX} className='p-4'>
      <Row>
        <Col lg={4}>
          <Card>
            <CardHeader>
              <CardTitle>Información de pagos</CardTitle>
            </CardHeader>
            <CardBody>
              {payments.length > 0 ? (
                <SimpleBar style={{ maxHeight: '45vh' }}>
                  <div className='table-responsive'>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Fecha de pago</th>
                          <th>Valor pagado</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {payments.map(
                          ({ fechaPago, valorPago, cuotas }, idx) => (
                            <tr
                              key={idx}
                              className={
                                idx === infoPay?.index ? 'bg-soft-info' : ''
                              }
                            >
                              <td>
                                {moment(fechaPago).format('DD MMM [de] YYYY')}
                              </td>
                              <td>{parsePrice(valorPago)}</td>
                              <td>
                                <button
                                  className='border-0 p-0 m-0 bg-transparent'
                                  onClick={() => {
                                    setInfoPay({
                                      index: idx,
                                      cuotas,
                                      total: valorPago
                                    })
                                  }}
                                >
                                  <i className='mdi mdi-eye' />
                                </button>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </div>
                </SimpleBar>
              ) : (
                <div>
                  <p className='text-center'>
                    No hay pagos para esta obligación
                  </p>
                  <div>
                    <img src={imgEmpty} style={{ width: '100%' }} />
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col lg={8}>
          {infoPay != null ? (
            <Card>
              <CardHeader className='justify-content-between d-flex'>
                <CardTitle>
                  Detalle del pago por <b>{parsePrice(infoPay.total)}</b>
                </CardTitle>
                <div>
                  <button
                    className='p-0 m-0 bg-transparent border-0'
                    onClick={() => setInfoPay(null)}
                  >
                    <i className='mdi mdi-window-close fs-4' />
                  </button>
                </div>
              </CardHeader>
              <CardBody>
                <UncontrolledAccordion defaultOpen='1'>
                  {infoPay.cuotas.map(
                    (
                      { detallePagoAval, detallePagoCuota, id, endDate },
                      index
                    ) => (
                      <AccordionItem key={id}>
                        <AccordionHeader targetId={`${index + 1}`}>
                          Pago: {id}
                        </AccordionHeader>
                        <AccordionBody accordionId={`${index + 1}`}>
                          <h6 className='text-info'>
                            Cuota con vencimiento el{' '}
                            {moment(endDate).format('DD MMMM [del] YYYY')}
                          </h6>
                          <div className='table-responsive'>
                            <Table>
                              <thead>
                                <tr>
                                  <th>Capital</th>
                                  <th>Interés</th>
                                  <th>Interés Mora</th>
                                </tr>
                              </thead>
                              <tbody>
                                {detallePagoCuota.map(
                                  (
                                    { capital, interest, penaltyInterest },
                                    i
                                  ) => (
                                    <tr key={i}>
                                      <td>{parsePrice(capital)}</td>
                                      <td>{parsePrice(interest)}</td>
                                      <td>{parsePrice(penaltyInterest)}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </div>
                          <h6>Detalles aval</h6>
                          {detallePagoAval.length > 0 ? (
                            <div className='table-responsive'>
                              <Table>
                                <thead>
                                  <tr>
                                    <th>Valor</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {detallePagoAval.map(({ value }, i) => (
                                    <tr key={i}>
                                      <td>{parsePrice(value)}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          ) : (
                            <div className='text-center p-4'>
                              No hay pagos para aval
                            </div>
                          )}
                        </AccordionBody>
                      </AccordionItem>
                    )
                  )}
                </UncontrolledAccordion>
              </CardBody>
            </Card>
          ) : (
            <Card>
              <CardBody>
                <p className='text-center'>
                  {payments.length > 0
                    ? 'Seleccione un pago para ver la Información'
                    : 'No hay pagos registrados'}
                </p>
                <div
                  className='d-flex align-items-center justify-content-center'
                  style={{ height: '50vh' }}
                >
                  <img src={imgSelectPayment} style={{ width: '55%' }} />
                </div>
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </TabPane>
  )
}
