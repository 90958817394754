import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { Link } from 'react-router-dom'

import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  ModalBody,
  Label,
  Input,
  Modal,
  ModalHeader,
  Form,
  ModalFooter,
  Button,
  FormFeedback,
  UncontrolledTooltip,
  Spinner,
  UncontrolledPopover,
  Badge,
  FormGroup
} from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import DeleteModal from '../../../Components/Common/DeleteModal'

import TableContainer from '../../../Components/Common/TableContainer'

// Formik
import * as Yup from 'yup'
import { useFormik } from 'formik'

// Export Modal

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useQuery } from '@apollo/client'
import { GET_PUNTOS_EMP_COMER } from '../../../graphql/queries/getPuntos'
import { CREATE_UPDATE_EMP_COMERCIAL_STR } from '../../../graphql/mutations/empresaComercial'
import { hasPermission, parseId } from '../../../helpers'
import { ALL_PERMISSIONS, ERROR_MESSAGES } from '../../../helpers/consts'
import { useGetUser } from '../../../hooks/user'
import { useMutationFetch } from '../../../hooks/useMutationFetch'

const regexPhone = /^\d+$/

const EmpresaComercialCustom = () => {
  /*  const dispatch = useDispatch();
 const { companies, isCompaniesSuccess, error } = useSelector((state) => ({
   companies: state.Crm.companies,
   isCompaniesSuccess: state.Crm.isCompaniesSuccess,
   error: state.Crm.error,
  })); */

  /* const [
    gqlCreateEmpresaComercial,
    { data: dataCreate, loading: loadingCreate, error: errorCreate }
  ] = useMutation(CREATE_EMPRESA_COMERCIAL) */

  const [
    creatUpdateEmpCo,
    { data: dataCreate, loading: loadingCreate, error: errorCreate }
  ] = useMutationFetch()
  const { data, loading, error, refetch } = useQuery(GET_PUNTOS_EMP_COMER, {
    fetchPolicy: 'network-only'
  })

  const { logout, permissions } = useGetUser()

  const inputRef = useRef()

  const [company, setCompany] = useState([])
  const [idEdit, setIdEdit] = useState(null)
  const [isEdit, setIsEdit] = useState(false)

  // delete Company
  const [deleteModal, setDeleteModal] = useState(false)
  const [modal, setModal] = useState(false)

  // Export Modal

  const [isViewCompany, setIsViewCompany] = useState(false)

  /* useEffect(() => {
    if (companies && !companies.length) {
      dispatch(onGetCompanies());
    }
  }, [dispatch, companies]); */

  /* useEffect(() => {
    if (!isEmpty(companies)) {
      setCompany(companies);
      setIsEdit(false);
    }
  }, [companies]); */

  const toggle = useCallback(() => {
    setModal((p) => !p)
  }, [modal])

  // Delete Data
  const handleDeleteCompany = () => {
    if (company) {
      // dispatch(onDeleteCompanies(company._id));
      setDeleteModal(false)
    }
  }
  // Add Data
  const handleCompanyClicks = () => {
    setCompany('')
    setIsEdit(false)
    toggle()
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (company && company.img) || '',
      name: '',
      phoneNumber: '',
      address: '',
      nit: '',
      image: null,
      crm: false
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Ingrese nombre de la empresa'),
      phoneNumber: Yup.string()
        .matches(regexPhone, 'Número no válido')
        .min(10, 'Debe tener 10 dígitos')
        .max(10, 'Debe tener 10 dígitos')
        .required('Ingrese número de teléfono'),
      address: Yup.string().required('Ingrese dirección de la empresa'),
      nit: Yup.string().required('Ingrese NIT de la empresa'),
      crm: Yup.bool()
    }),
    onSubmit: async (values) => {
      const companyInfo = { ...values }
      // console.log('companyInfo', companyInfo)
      if (isEdit) {
        companyInfo.id = parseId(idEdit)
      }

      const formdata = new FormData()
      if (companyInfo.image && typeof companyInfo.image === 'object') {
        formdata.append('0', companyInfo.image, companyInfo.image.name)
        formdata.append('map', '{"0": ["variables.input.image"]}')
        companyInfo.image = null
      } else {
        delete companyInfo.image
      }

      const obj = {
        query: CREATE_UPDATE_EMP_COMERCIAL_STR,
        variables: {
          input: {
            ...companyInfo
          }
        }
      }
      formdata.append('operations', JSON.stringify(obj))
      creatUpdateEmpCo(formdata)
    }
  })

  useEffect(() => {
    console.log('error', error)
    if (error) {
      toast.error(`Error: ${error.message}`)
      if (error.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [error])

  useEffect(() => {
    // console.log('dataCreate', dataCreate)
    if (dataCreate) {
      if (
        dataCreate.createUpdateCommercialCompany &&
        dataCreate.createUpdateCommercialCompany?.commercialCompany
      ) {
        // console.log("dataCreate", dataCreate);
        toast.success(
          isEdit
            ? 'Empresa Comercial actualizada correctamente'
            : 'Empresa Comercial creada correctamente'
        )
        refetch()
        validation.resetForm()
        toggle()
        return
      }

      if (dataCreate?.errors) {
        toast.error(
          `ERROR! ${dataCreate.createUpdateCommercialCompany?.errors[0].messages[0]}`
        )
      }
    }
  }, [dataCreate])
  useEffect(() => {
    if (errorCreate) {
      toast.error(`ERROR! ${errorCreate.message}`)
      if (errorCreate.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorCreate])

  useEffect(() => {
    if (data) {
      const newArr = data.listCommercialCompany.edges.map(({ node }) => ({
        ...node
      }))
      setCompany(newArr)
    }
  }, [data])

  // Node API
  // useEffect(() => {
  //   if (isCompaniesCreated) {
  //     setCompany(null);
  //     dispatch(onGetCompanies());
  //   }
  // }, [
  //   dispatch,
  //   isCompaniesCreated,
  // ]);

  const toggleView = () => {
    setIsViewCompany((prev) => !prev)
  }

  // Column
  const columns = useMemo(
    () => [
      /* {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="companyCheckBox form-check-input"
              value={cellProps.row.original._id}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
      }, */
      {
        Header: 'Nombre de empresa',
        accessor: 'name',
        filterable: true,
        Cell: (company) => (
          <>
            <div className='d-flex align-items-center'>
              <div className='flex-shrink-0'>
                {company.row.original.image ? (
                  <>
                    <img
                      src={company.row.original.image}
                      alt='imagen de EC'
                      className='avatar-xxs rounded-circle'
                      id={`popover-${parseId(company.row.original.id)}`}
                      // srcSet='https://static.thenounproject.com/png/4381137-200.png'
                    />
                    <UncontrolledPopover
                      placement='right-end'
                      target={`popover-${parseId(company.row.original.id)}`}
                      trigger='hover'
                      style={{ backgroundColor: 'white' }}
                    >
                      <img
                        src={company.row.original.image}
                        alt='Imagen Grande'
                        className='img-thumbnail'
                        loading='lazy'
                      />
                    </UncontrolledPopover>
                  </>
                ) : (
                  <div className='flex-shrink-0 avatar-xs me-2'>
                    <div className='avatar-title bg-soft-success text-success rounded-circle fs-13'>
                      {company.row.original.name.charAt(0)}
                    </div>
                  </div>
                )}
              </div>
              <div className='flex-grow-1 ms-2 name'>
                {company.row.original.name}
              </div>
            </div>
          </>
        )
      },
      {
        Header: 'CRM',
        accessor: 'node.crm',
        filterable: false,
        Cell: (company) => (
          <>
            {company.row.original.crm ? (
              <Badge color='success'>SI</Badge>
            ) : (
              <Badge color='danger'>NO</Badge>
            )}
          </>
        )
      },
      /* {
        Header: "Owner",
        accessor: "owner",
        filterable: false,
      },
      {
        Header: "Industry Type",
        accessor: "industry_type",
        filterable: false,
      },
      {
        Header: "Rating",
        accessor: "star_value",
        filterable: false,
        Cell: (cellProps) => (
          <>
            {cellProps.value}{" "}
            <i className="ri-star-fill text-warning align-bottom"></i>
          </>
        ),
      },
      {
        Header: "Location",
        accessor: "location",
        filterable: false,
      }, */
      {
        Header: 'Acciones',
        Cell: (cellProps) => {
          return (
            <ul className='list-inline hstack gap-2 mb-0'>
              <li className='list-inline-item'>
                <Link
                  to='#'
                  onClick={() => {
                    const companyData = cellProps.row.original
                    setInfo(companyData)
                    setIsViewCompany(true)
                  }}
                  id={`${cellProps.row.original.id.replace('=', '')}-ver`}
                >
                  <i className='ri-eye-fill align-bottom text-muted' />
                </Link>
                <UncontrolledTooltip
                  target={`${cellProps.row.original.id.replace('=', '')}-ver`}
                  placement='top'
                  trigger='hover'
                  title='hola'
                >
                  Ver
                </UncontrolledTooltip>
              </li>
              {hasPermission(
                permissions,
                ALL_PERMISSIONS.PUEDE_CAMBIAR_EMPRESA_COMERCIAL
              ) && (
                <li className='list-inline-item'>
                  <Button
                    className='edit-item-btn bg-transparent border-0 p-0 m-0'
                    onClick={() => {
                      const companyData = cellProps.row.original
                      setIsEdit(true)
                      validation.setValues({
                        address: companyData.address,
                        crm: companyData.crm,
                        name: companyData.name,
                        nit: companyData.nit,
                        phoneNumber: companyData.phoneNumber,
                        image: companyData.image
                      })
                      setModal(true)
                      setIdEdit(companyData.id)
                    }}
                    id={cellProps.row.original.id.replace('=', '')}
                  >
                    <i className='ri-pencil-fill align-bottom text-muted' />
                  </Button>
                  <UncontrolledTooltip
                    target={cellProps.row.original.id.replace('=', '')}
                    placement='top'
                    trigger='hover'
                    title='hola'
                  >
                    Editar
                  </UncontrolledTooltip>
                </li>
              )}
              {/* <li className="list-inline-item" title="Eliminar">
                <Link
                  className="remove-item-btn"
                  onClick={() => {
                    const companyData = cellProps.row.original;
                    onClickDelete(companyData);
                  }}
                  to="#"
                >
                  <i className="ri-delete-bin-fill align-bottom text-muted"></i>
                </Link>
              </li> */}
            </ul>
          )
        }
      }
    ],
    []
  )

  // SideBar Company Deatail
  /** @type {[{name: string,  phoneNumber: string,  address: string,  nit: string, crm: boolean}]} info */
  const [info, setInfo] = useState(null)

  // console.log("company", company);
  document.title = 'Empresas comerciales | Total Sales'
  if (loading) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Empresa comercial' pageTitle='CRM' />

          <Row>
            <Col lg={12} style={{ height: '40vh' }}>
              <Card className='d-flex align-items-center justify-content-center h-100'>
                <Spinner style={{ height: '3em', width: '3em' }} />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
  console.log('validation.values.crm', validation.values.crm)
  return (
    <>
      <div className='page-content'>
        {/*         <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={company}
        /> */}
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteCompany}
          onCloseClick={() => setDeleteModal(false)}
        />
        {/* <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        /> */}

        <Container fluid>
          <BreadCrumb title='Empresa comercial' pageTitle='CRM' />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className='d-flex align-items-center flex-wrap gap-2'>
                    <div className='flex-grow-1'>
                      {hasPermission(
                        permissions,
                        ALL_PERMISSIONS.PUEDE_ANADIR_EMPRESA_COMERCIAL
                      ) && (
                        <button
                          className='btn btn-info add-btn'
                          onClick={() => {
                            setIsEdit(false)
                            validation.resetForm()
                            toggle()
                            setIdEdit(null)
                          }}
                        >
                          <i className='ri-add-fill me-1 align-bottom' />{' '}
                          Agregar empresa comercial
                        </button>
                      )}
                    </div>
                    {/* <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2">
                        {isMultiDeleteButton && (
                          <button
                            className="btn btn-soft-danger"
                            onClick={() => setDeleteModalMulti(true)}
                          >
                            <i className="ri-delete-bin-2-line"></i>
                          </button>
                        )}
                        <button className="btn btn-danger">
                          <i className="ri-filter-2-line me-1 align-bottom"></i>{" "}
                          Filters
                        </button>
                        <button
                          className="btn btn-soft-success"
                          onClick={() => setIsExportCSV(true)}
                        >
                          Export
                        </button>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            href="#"
                            className="btn-soft-info btn-icon"
                            tag="button"
                          >
                            <i className="ri-more-2-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className="dropdown-item" href="#">
                              All
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Week
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Month
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Year
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div> */}
                  </div>
                </CardHeader>
              </Card>
            </Col>
            <Col>
              <Card id='companyList'>
                <CardBody className='pt-0'>
                  <div>
                    {company.length > 0 ? (
                      <TableContainer
                        columns={columns}
                        data={company}
                        isGlobalFilter
                        isAddUserList={false}
                        customPageSize={7}
                        className='custom-header-css'
                        divClass='table-responsive table-card mb-2'
                        tableClass='align-middle table-nowrap'
                        theadClass='table-light'
                        handleCompanyClick={handleCompanyClicks}
                        isCompaniesFilter={false}
                        SearchPlaceholder='Buscar empresa...'
                      />
                    ) : (
                      <div style={{ height: '40vh' }}>
                        <div className='d-flex justify-content-center align-items-center h-100'>
                          <p>No hay empresas comercial</p>
                        </div>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Modal
              id='showModal'
              isOpen={modal}
              toggle={toggle}
              centered
              size='lg'
            >
              <ModalHeader className='bg-soft-info p-3' toggle={toggle}>
                {isEdit ? 'Editar empresa' : 'Añadir empresa comercial'}
              </ModalHeader>
              <Form
                className='tablelist-form'
                onSubmit={(e) => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <ModalBody>
                  <input type='hidden' id='id-field' />
                  <Row className='g-3'>
                    <Col lg={12}>
                      <div className='text-center'>
                        {validation.values.image &&
                        typeof validation.values.image === 'object' ? (
                          <div className='position-relative d-inline-block'>
                            <img
                              src={URL.createObjectURL(validation.values.image)}
                              alt='Previsualización'
                              width={200}
                              height='auto'
                            />
                            <div
                              style={{
                                zIndex: 10,
                                backgroundColor: 'black',
                                borderRadius: '100%'
                              }}
                              className='position-absolute top-0 start-0'
                              onClick={() => {
                                validation.setFieldValue('image', null)
                                if (inputRef.current) {
                                  inputRef.current.value = null
                                }
                              }}
                            >
                              <div className='mb-0'>
                                <div className='avatar-xs cursor-pointer'>
                                  <div className='avatar-title bg-transparent text-light'>
                                    {/* <i className='ri-image-fill' /> */}
                                    <i
                                      className='las la-times-circle'
                                      style={{ fontSize: '24px' }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className='position-relative d-inline-block'>
                            <div className='position-absolute bottom-0 end-0'>
                              <Label
                                htmlFor='company-logo-input'
                                className='mb-0'
                              >
                                <div className='avatar-xs cursor-pointer'>
                                  <div className='avatar-title bg-light border rounded-circle text-muted'>
                                    <i className='ri-image-fill' />
                                  </div>
                                </div>
                              </Label>
                              <Input
                                ref={inputRef}
                                name='img'
                                className='form-control d-none'
                                id='company-logo-input'
                                type='file'
                                accept='image/png, image/gif, image/jpeg'
                                onChange={(e) => {
                                  validation.setFieldValue(
                                    'image',
                                    e.target.files[0]
                                  )
                                }}
                                onBlur={validation.handleBlur}
                                // value={validation.values.image}
                                invalid={
                                  validation.touched.image &&
                                  validation.errors.image
                                }
                              />
                            </div>
                            <div className='avatar-lg p-1'>
                              <div className='avatar-title bg-light rounded-circle'>
                                <img
                                  src={
                                    validation.values.image ||
                                    'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/681px-Placeholder_view_vector.svg.png'
                                  }
                                  alt='imagen de empresa comercial'
                                  // srcSet='https://static.thenounproject.com/png/4381137-200.png'
                                  id='companylogo-img'
                                  className='avatar-md rounded-circle'
                                  style={{ objectFit: 'contain' }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <h5 className='fs-13 mt-3'>
                          Logo de empresa comercial
                        </h5>
                      </div>
                    </Col>

                    <Col lg={12}>
                      <FormGroup>
                        <Label htmlFor='name-field' className='form-label'>
                          Nombre de empresa comercial
                        </Label>

                        <Input
                          name='name'
                          id='customername-field'
                          className='form-control'
                          placeholder='Nombre de la empresa comercial'
                          type='text'
                          validate={{
                            required: { value: true }
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ''}
                          invalid={
                            !!(
                              validation.touched.name && validation.errors.name
                            )
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label htmlFor='nit-field' className='form-label'>
                          NIT
                        </Label>
                        <Input
                          name='nit'
                          id='nit-field'
                          className='form-control'
                          placeholder='Ingrese NIT de la empresa comercial'
                          type='text'
                          validate={{
                            required: { value: true }
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.nit}
                          invalid={
                            !!(validation.touched.nit && validation.errors.nit)
                          }
                        />
                        {validation.touched.nit && validation.errors.nit ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.nit}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label htmlFor='phone-field' className='form-label'>
                          Teléfono
                        </Label>
                        <Input
                          name='phoneNumber'
                          id='phone-field'
                          className='form-control'
                          placeholder='Ingrese teléfono'
                          type='tel'
                          validate={{
                            required: { value: true }
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.phoneNumber}
                          invalid={
                            !!(
                              validation.touched.phoneNumber &&
                              validation.errors.phoneNumber
                            )
                          }
                        />
                        {validation.touched.phoneNumber &&
                        validation.errors.phoneNumber ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.phoneNumber}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col lg={9}>
                      <div>
                        <Label htmlFor='address-field' className='form-label'>
                          Dirección
                        </Label>
                        <Input
                          name='address'
                          id='address-field'
                          className='form-control'
                          placeholder='Ingrese dirección'
                          type='text'
                          validate={{
                            required: { value: true }
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.address || ''}
                          invalid={
                            !!(
                              validation.touched.address &&
                              validation.errors.address
                            )
                          }
                        />
                        {validation.touched.address &&
                        validation.errors.address ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.address}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col
                      lg={3}
                      className='d-flex align-items-center justify-content-center'
                    >
                      <FormGroup switch>
                        <div>
                          <Label>¿CRM?</Label>
                        </div>
                        <Label check htmlFor='crm-field'>
                          {validation.values.crm ? '(SI)' : '(NO)'}
                        </Label>
                        <Input
                          name='crm'
                          id='crm-field'
                          className='form-control'
                          placeholder='Ingrese teléfono'
                          type='switch'
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          checked={validation.values.crm}
                          // defaultChecked={validation.values.crm}
                          // value={validation.values.crm}
                          invalid={
                            !!(validation.touched.crm && validation.errors.crm)
                          }
                        />
                        {validation.touched.crm && validation.errors.crm && (
                          <FormFeedback type='invalid'>
                            {validation.errors.crm}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <div className='hstack gap-2 justify-content-end'>
                    <Button
                      color='light'
                      onClick={() => {
                        setModal(false)
                        validation.resetForm()
                        setIdEdit(null)
                      }}
                    >
                      Cancelar{' '}
                    </Button>
                    <Button
                      type='submit'
                      color='success'
                      id='add-btn'
                      disabled={loadingCreate}
                    >
                      {loadingCreate || !!isEdit
                        ? 'Actualizar'
                        : 'Añadir empresa'}
                      {loadingCreate && 'cargando...'}
                    </Button>
                  </div>
                </ModalFooter>
              </Form>
            </Modal>

            <Modal
              id='showModalView'
              isOpen={isViewCompany}
              toggle={toggleView}
              centered
              size='lg'
            >
              <ModalHeader className='bg-soft-info p-3' toggle={toggleView}>
                Información de la empresa
              </ModalHeader>
              <ModalBody>
                {info && (
                  <Col>
                    <Card id='company-view-detail'>
                      <CardBody className='text-center'>
                        <div className='position-relative d-inline-block'>
                          <div className='avatar-lg'>
                            <div className='avatar-title bg-light'>
                              <img
                                src={
                                  info.image ||
                                  'https://static.thenounproject.com/png/4381137-200.png'
                                }
                                alt='logo empresa comercial'
                                className='avatar-lg object-fill'
                              />
                            </div>
                          </div>
                        </div>
                        <h5 className='mt-3 mb-1'>{info.name || 'name'}</h5>
                        <p className='text-muted'>{info.nit || 'NIT'}</p>

                        {/* <ul className="list-inline mb-0">
                    <li className="list-inline-item avatar-xs">
                      <Link
                        to="#"
                        className="avatar-title bg-soft-success text-success fs-15 rounded"
                      >
                        <i className="ri-global-line"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item avatar-xs">
                      <Link
                        to="#"
                        className="avatar-title bg-soft-danger text-danger fs-15 rounded"
                      >
                        <i className="ri-mail-line"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item avatar-xs">
                      <Link
                        to="#"
                        className="avatar-title bg-soft-warning text-warning fs-15 rounded"
                      >
                        <i className="ri-question-answer-line"></i>
                      </Link>
                    </li>
                  </ul> */}
                      </CardBody>
                      <div className='card-body'>
                        <h6 className='text-muted text-uppercase fw-semibold'>
                          Información
                        </h6>
                        {/* <p className="text-muted mb-4">
                    A company incurs fixed and variable costs such as the
                    purchase of raw materials, salaries and overhead, as
                    explained by AccountingTools, Inc. Business owners have the
                    discretion to determine the actions.
                  </p> */}
                        <div className='table-responsive table-card'>
                          <table className='table table-borderless mb-0'>
                            <tbody>
                              <tr>
                                <td className='fw-medium'>Número telefónico</td>
                                <td>{info.phoneNumber || '000-000-0000'}</td>
                              </tr>
                              <tr>
                                <td className='fw-medium'>Dirección</td>
                                <td>{info.address || 'Dirección'}</td>
                              </tr>
                              <tr>
                                <td className='fw-medium'>NIT</td>
                                <td>{info.nit || 'NIT'}</td>
                              </tr>
                              <tr>
                                <td className='fw-medium'>CRM activo</td>
                                <td>
                                  {info.crm ? (
                                    <Badge color='success'>SI</Badge>
                                  ) : (
                                    <Badge color='danger'>NO</Badge>
                                  )}
                                </td>
                              </tr>
                              {/* <tr>
                            <td className="fw-medium">Employee</td>
                            <td>{info.employee || "10-50"}</td>
                          </tr>
                          <tr>
                            <td className="fw-medium">Rating</td>
                            <td>
                              {info.star_value || "4.0"}{" "}
                              <i className="ri-star-fill text-warning align-bottom"></i>
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-medium">Website</td>
                            <td>
                              <Link
                                to="#"
                                className="link-primary text-decoration-underline"
                              >
                                {info.website || "www.syntycesolution.com"}
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-medium">Contact Email</td>
                            <td>
                              {info.contact_email || "info@syntycesolution.com"}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-medium">Since</td>
                            <td>{info.since || "1995"}</td>
                          </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Card>
                  </Col>
                )}
              </ModalBody>
            </Modal>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default EmpresaComercialCustom
