import {
  Col,
  Row,
  ModalBody,
  Label,
  Input,
  Modal,
  ModalHeader,
  Form,
  ModalFooter,
  Button,
  FormFeedback,
  Spinner,
  FormGroup
} from 'reactstrap'

import { InputCurrency } from '../InputCurrency'
import { useMutation, useQuery } from '@apollo/client'
import { CREATE_MOTO_USADA } from '../../../graphql/mutations/motosUsadas'
import { GET_CELLARS_MOTO_USADAS } from '../../../graphql/queries/getMotosUsadas'
import { useEffect, useState } from 'react'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useGetUser } from '../../../hooks/user'
import { ERROR_MESSAGES } from '../../../helpers/consts'
import { toast } from 'react-toastify'
import { parseId } from '../../../helpers'

export const ModalAddUsedMoto = ({ isOpen, onClose }) => {
  const [
    gqlCreateUsedMoto,
    { data: dataCreate, loading: loadingCreate, error: errorCreate }
  ] = useMutation(CREATE_MOTO_USADA)

  const {
    data: dataCellar,
    loading: loadingCellar,
    error: errorCellar
  } = useQuery(GET_CELLARS_MOTO_USADAS)

  const { logout } = useGetUser()

  const navigate = useNavigate()

  const [cellars, setCellars] = useState([])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (company && company.img) || '',
      trademark: '',
      line: '',
      model: '',
      color: '',
      plaque: '',
      chassis: '',
      engine: '',
      cellar: '',
      basePrice: '',
      forSale: false,
      salePrice: '',
      hasKey: false,
      haveOwnershipCard: true,
      hasSoat: false,
      hasTechnomechanics: false,
      soatExpirationDate: '',
      technomechanicsExpirationDate: '',
      vehicleDelivery: false,
      vehicleDeliveryDate: '',
      finesViolations: false,
      returnDate: '',
      ownerDocument: '',
      ownerName: '',
      taxDebt: '',
      additionalDescription: '',
      status: ''
    },
    validationSchema: Yup.object({
      cellar: Yup.string().required('La bodega es obligatoria'),
      trademark: Yup.string().required('La marca es obligatoria'),
      line: Yup.string().required('La línea es obligatoria'),
      model: Yup.string().required('El modelo es obligatorio'),
      chassis: Yup.string().required('El chassis es obligatorio'),
      plaque: Yup.string().required('La placa es obligatoria'),
      color: Yup.string().required('El color es obligatorio'),
      engine: Yup.string().required('El motor es obligatorio'),
      additionalDescription: Yup.string().required('Este campo es obligatorio'),
      status: Yup.string().required('Obligatorio')
    }),
    onSubmit: (values) => {
      const newMotoUsed = { ...values }
      newMotoUsed.basePrice = values.basePrice.replace(/,/, '.')
      if (newMotoUsed.salePrice) {
        newMotoUsed.salePrice = values.salePrice.replace(/,/, '.')
      }
      if (newMotoUsed.taxDebt) {
        newMotoUsed.taxDebt = values.taxDebt.replace(/,/, '.')
      } else {
        newMotoUsed.taxDebt = 0
      }

      // console.log('newMotoUsed', newMotoUsed)

      Object.keys(newMotoUsed).forEach((key) => {
        if (key !== 'additionalDescription') {
          newMotoUsed[key] =
            newMotoUsed[key] === '' ? undefined : newMotoUsed[key]
        }
      })

      // console.log('newMotoUsed 2', newMotoUsed)
      gqlCreateUsedMoto({
        variables: {
          input: newMotoUsed
        }
      })
    }
  })

  useEffect(() => {
    if (!dataCellar || !dataCellar.listCellar) return
    if (dataCellar.listCellar.length > 0) {
      setCellars(dataCellar.listCellar)
    }
  }, [dataCellar])

  useEffect(() => {
    if (errorCellar) {
      toast.error(`ERROR! ${errorCellar.message}`)
      if (errorCellar.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorCellar])

  useEffect(() => {
    if (dataCreate) {
      if (dataCreate.usedMotorcycleCreateUpdate?.usedMotorcycle?.id) {
        toast.success('Motocicleta usada creada correctamente')
        navigate(
          `/motocicleta-usada/${parseId(
            dataCreate.usedMotorcycleCreateUpdate.usedMotorcycle.id
          )}`
        )
        onClose()
        validation.resetForm()
        return
      }
      if (dataCreate.usedMotorcycleCreateUpdate.errors.length > 0) {
        toast.error(
          `ERROR! ${dataCreate.usedMotorcycleCreateUpdate?.errors[0]?.messages[0]}`
        )
      }
    }
  }, [dataCreate])
  useEffect(() => {
    if (errorCreate) {
      toast.error(`ERROR! ${errorCreate.message}`)
      if (errorCreate.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorCreate])

  const selectAllToggle = (e) => {
    validation.setFieldValue('forSale', e.target.checked)
    validation.setFieldValue('hasKey', e.target.checked)
    validation.setFieldValue('haveOwnershipCard', e.target.checked)
    validation.setFieldValue('hasSoat', e.target.checked)
    validation.setFieldValue('hasTechnomechanics', e.target.checked)
    validation.setFieldValue('vehicleDelivery', e.target.checked)
    validation.setFieldValue('finesViolations', e.target.checked)
  }

  return (
    <Modal id='showModal' isOpen={isOpen} toggle={onClose} centered size='lg'>
      <ModalHeader className='bg-soft-info p-3' toggle={onClose}>
        Añadir motocicleta
      </ModalHeader>
      <Form
        autoComplete='off'
        className='tablelist-form'
        onSubmit={(e) => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <ModalBody>
          <input type='hidden' id='id-field' />
          <Row className='g-3'>
            {/* <Col lg={12}>
                            <div className="text-center">
                              <div className="position-relative d-inline-block">
                                <div className="position-absolute bottom-0 end-0">
                                  <Label
                                    htmlFor="company-logo-input"
                                    className="mb-0"
                                  >
                                    <div className="avatar-xs cursor-pointer">
                                      <div className="avatar-title bg-light border rounded-circle text-muted">
                                        <i className="ri-image-fill"></i>
                                      </div>
                                    </div>
                                  </Label>
                                  <Input
                                    name="img"
                                    className="form-control d-none"
                                    id="company-logo-input"
                                    type="file"
                                    accept="image/png, image/gif, image/jpeg"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.img || ""}
                                    invalid={
                                      validation.touched.img &&
                                      validation.errors.img
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                                <div className="avatar-lg p-1">
                                  <div className="avatar-title bg-light rounded-circle">
                                    <img
                                      src={multiUser}
                                      alt="multiUser"
                                      id="companylogo-img"
                                      className="avatar-md rounded-circle object-cover"
                                    />
                                  </div>
                                </div>
                              </div>
                              <h5 className="fs-13 mt-3">Logo de zona</h5>
                            </div>
                          </Col> */}

            <Col lg={6}>
              <div>
                <Label htmlFor='name-field' className='form-label'>
                  Marca
                </Label>

                <Input
                  name='trademark'
                  id='customername-field'
                  className='form-control'
                  placeholder='Marca...'
                  type='text'
                  validate={{
                    required: { value: true }
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.trademark}
                  invalid={
                    !!(
                      validation.touched.trademark &&
                      validation.errors.trademark
                    )
                  }
                />
                {validation.touched.trademark &&
                  validation.errors.trademark && (
                    <FormFeedback type='invalid'>
                      {validation.errors.trademark}
                    </FormFeedback>
                  )}
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <Label htmlFor='line' className='form-label'>
                  Linea
                </Label>

                <Input
                  name='line'
                  id='line'
                  className='form-control'
                  placeholder='Ingrese linea'
                  type='text'
                  validate={{
                    required: { value: true }
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.line}
                  invalid={
                    !!(validation.touched.line && validation.errors.line)
                  }
                />
                {validation.touched.line && validation.errors.line && (
                  <FormFeedback type='invalid'>
                    {validation.errors.line}
                  </FormFeedback>
                )}
              </div>
            </Col>
            <Col lg={4}>
              <div>
                <Label htmlFor='Modelomotocicleta' className='form-label'>
                  Modelo de motocicleta
                </Label>

                <Input
                  name='model'
                  id='Modelomotocicleta'
                  className='form-control'
                  placeholder='Modelo...'
                  type='text'
                  validate={{
                    required: { value: true }
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.model}
                  invalid={
                    !!(validation.touched.model && validation.errors.model)
                  }
                />
                {validation.touched.model && validation.errors.model && (
                  <FormFeedback type='invalid'>
                    {validation.errors.model}
                  </FormFeedback>
                )}
              </div>
            </Col>
            <Col lg={4}>
              <div>
                <Label htmlFor='color' className='form-label'>
                  Color
                </Label>

                <Input
                  name='color'
                  id='color'
                  className='form-control'
                  placeholder='color...'
                  type='text'
                  validate={{
                    required: { value: true }
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.color}
                  invalid={
                    !!(validation.touched.color && validation.errors.color)
                  }
                />
                {validation.touched.color && validation.errors.color && (
                  <FormFeedback type='invalid'>
                    {validation.errors.color}
                  </FormFeedback>
                )}
              </div>
            </Col>
            <Col lg={4}>
              <div>
                <Label htmlFor='plaque' className='form-label'>
                  Placa
                </Label>

                <Input
                  name='plaque'
                  id='plaque'
                  className='form-control text-uppercase'
                  placeholder='Placa...'
                  type='text'
                  validate={{
                    required: { value: true }
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.plaque}
                  invalid={
                    !!(validation.touched.plaque && validation.errors.plaque)
                  }
                />
                {validation.touched.plaque && validation.errors.plaque && (
                  <FormFeedback type='invalid'>
                    {validation.errors.plaque}
                  </FormFeedback>
                )}
              </div>
            </Col>

            <Col lg={6}>
              <div>
                <Label htmlFor='engine' className='form-label'>
                  Motor
                </Label>

                <Input
                  name='engine'
                  id='engine'
                  className='form-control'
                  placeholder='Motor'
                  type='text'
                  validate={{
                    required: { value: true }
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.engine}
                  invalid={
                    !!(validation.touched.engine && validation.errors.engine)
                  }
                />
                {validation.touched.engine && validation.errors.engine && (
                  <FormFeedback type='invalid'>
                    {validation.errors.engine}
                  </FormFeedback>
                )}
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <Label htmlFor='chassis' className='form-label'>
                  Chasis
                </Label>

                <Input
                  name='chassis'
                  id='chassis'
                  className='form-control'
                  placeholder='Chasis...'
                  type='text'
                  validate={{
                    required: { value: true }
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.chassis}
                  invalid={
                    !!(validation.touched.chassis && validation.errors.chassis)
                  }
                />
                {validation.touched.chassis && validation.errors.chassis && (
                  <FormFeedback type='invalid'>
                    {validation.errors.chassis}
                  </FormFeedback>
                )}
              </div>
            </Col>

            <Col lg={6}>
              <div>
                <Label htmlFor='cellar' className='form-label'>
                  Ubicación
                </Label>

                {loadingCellar ? (
                  <Input disabled />
                ) : (
                  <Input
                    name='cellar'
                    id='cellar'
                    className='form-control'
                    placeholder='Ingrese ubicación'
                    type='select'
                    validate={{
                      required: { value: true }
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.cellar}
                    invalid={
                      !!(validation.touched.cellar && validation.errors.cellar)
                    }
                  >
                    <option value=''>Seleccione bodega</option>
                    {cellars.map(({ id, city, name }) => (
                      <option key={id} value={parseId(id)}>
                        {name} - {city}
                      </option>
                    ))}
                  </Input>
                )}
                {validation.touched.cellar && validation.errors.cellar && (
                  <FormFeedback type='invalid'>
                    {validation.errors.cellar}
                  </FormFeedback>
                )}
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <FormGroup>
                  <Label htmlFor='statusForm' className='form-label'>
                    Estado
                  </Label>

                  <Input
                    name='status'
                    type='select'
                    className='form-select'
                    id='statusForm'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.status}
                    invalid={Boolean(
                      validation.touched.status && validation.errors.status
                    )}
                  >
                    <option value=''>Seleccione el estado</option>
                    <option value='EN INVENTARIO'>EN INVENTARIO</option>
                    <option value='NOTIFICADA'>NOTIFICADA</option>
                    <option value='VENDIDA'>VENDIDA</option>
                    <option value='ENTREGADA AL CLIENTE'>
                      ENTREGADA AL CLIENTE
                    </option>
                  </Input>
                  {validation.touched.status && validation.errors.status ? (
                    <FormFeedback type='invalid'>
                      {validation.errors.status}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </div>
            </Col>

            <Col lg={6}>
              <div>
                <Label htmlFor='basePrice' className='form-label'>
                  Precio base
                </Label>
                {/* <Input
                                name='basePrice'
                                id='basePrice'
                                className='form-control'
                                placeholder='Ingrese precio base'
                                type='number'
                                validate={{
                                  required: { value: true }
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.basePrice}
                                invalid={
                                  !!(
                                    validation.touched.basePrice &&
                                    validation.errors.basePrice
                                  )
                                }
                              />
                              {validation.touched.basePrice &&
                                validation.errors.basePrice && (
                                  <FormFeedback type='invalid'>
                                    {validation.errors.basePrice}
                                  </FormFeedback>
                                )} */}
                <InputCurrency
                  id='basePrice'
                  name='basePrice'
                  placeholder='Precio base'
                  className='form-control'
                  // prefix='$ '
                  value={validation.values.basePrice}
                  // defaultValue={1000}
                  // decimalsLimit={0}
                  // decimalSeparator=','
                  // groupSeparator='.'
                  onValueChange={(value, name) => {
                    validation.setFieldValue(name, value)
                  }}
                  // allowNegativeValue={false}
                />
                {validation.touched.basePrice &&
                  validation.errors.basePrice && (
                    <FormFeedback type='invalid'>
                      {validation.errors.basePrice}
                    </FormFeedback>
                  )}
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <Label htmlFor='salePrice' className='form-label'>
                  Precio de venta
                </Label>

                <InputCurrency
                  id='salePrice'
                  name='salePrice'
                  placeholder='Precio de venta'
                  className='form-control'
                  // prefix='$ '
                  value={validation.values.salePrice}
                  // decimalsLimit={0}
                  // decimalSeparator=','
                  // groupSeparator='.'
                  onValueChange={(value, name) => {
                    validation.setFieldValue(name, value)
                  }}
                  onBlur={validation.handleBlur}
                  // allowNegativeValue={false}
                />
                {/* <Input
                                name='salePrice'
                                id='salePrice'
                                className='form-control'
                                placeholder='Ingrese precio de venta'
                                type='number'
                                validate={{
                                  required: { value: true }
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.salePrice}
                                invalid={
                                  !!(
                                    validation.touched.salePrice &&
                                    validation.errors.salePrice
                                  )
                                }
                              /> */}
                {validation.touched.salePrice &&
                  validation.errors.salePrice && (
                    <FormFeedback type='invalid'>
                      {validation.errors.salePrice}
                    </FormFeedback>
                  )}
              </div>
            </Col>

            <Col lg={12}>
              <div>
                <label className='form-label label'>
                  Atributos de la motocicleta
                </label>
              </div>
              <div className='d-flex justify-content-between flex-sm-column flex-md-row'>
                <span className='block text-muted flex-fill'>
                  Selecciona con un clic los atributos que tiene la motocicleta
                </span>
                <div className='flex-fill'>
                  <Input
                    name='selectAll'
                    id='selectAll'
                    className=''
                    type='checkbox'
                    onChange={selectAllToggle}
                  />
                  <Label htmlFor='selectAll' className='ms-2'>
                    Seleccionar todo
                  </Label>
                </div>
              </div>
            </Col>

            <Col lg={4}>
              <div>
                <Input
                  name='forSale'
                  id='forSale'
                  className=''
                  type='checkbox'
                  onChange={validation.handleChange}
                  value={validation.values.forSale}
                  checked={validation.values.forSale}
                />
                <Label htmlFor='forSale' className='ms-2'>
                  Disponible para la venta ?{' '}
                  {validation.values.forSale ? (
                    <span className='text-muted'>(SI)</span>
                  ) : (
                    <span className='text-muted'>(NO)</span>
                  )}
                </Label>
              </div>
            </Col>
            <Col lg={4}>
              <div>
                <Input
                  name='hasKey'
                  id='hasKey'
                  className=''
                  type='checkbox'
                  onChange={validation.handleChange}
                  value={validation.values.hasKey}
                  checked={validation.values.hasKey}
                />
                <Label htmlFor='hasKey' className='ms-2'>
                  Tiene llaves ?{' '}
                  {validation.values.hasKey ? (
                    <span className='text-muted'>(SI)</span>
                  ) : (
                    <span className='text-muted'>(NO)</span>
                  )}
                </Label>
              </div>
            </Col>
            <Col lg={4}>
              <div>
                <Input
                  name='haveOwnershipCard'
                  id='haveOwnershipCard'
                  className=''
                  type='checkbox'
                  onChange={validation.handleChange}
                  value={validation.values.haveOwnershipCard}
                  checked={validation.values.haveOwnershipCard}
                />
                <Label htmlFor='haveOwnershipCard' className='ms-2'>
                  Tiene tarjeta de propiedad ?{' '}
                  {validation.values.haveOwnershipCard ? (
                    <span className='text-muted'>(SI)</span>
                  ) : (
                    <span className='text-muted'>(NO)</span>
                  )}
                </Label>
              </div>
            </Col>

            <Col lg={4}>
              <div>
                <Input
                  name='hasSoat'
                  id='hasSoat'
                  className=''
                  type='checkbox'
                  onChange={validation.handleChange}
                  value={validation.values.hasSoat}
                  checked={validation.values.hasSoat}
                />
                <Label htmlFor='hasSoat' className='ms-2'>
                  Tiene SOAT ?{' '}
                  {validation.values.hasSoat ? (
                    <span className='text-muted'>(SI)</span>
                  ) : (
                    <span className='text-muted'>(NO)</span>
                  )}
                </Label>
              </div>
            </Col>
            <Col lg={4}>
              <div>
                <Input
                  name='hasTechnomechanics'
                  id='hasTechnomechanics'
                  className=''
                  type='checkbox'
                  onChange={validation.handleChange}
                  value={validation.values.hasTechnomechanics}
                  checked={validation.values.hasTechnomechanics}
                />
                <Label htmlFor='hasTechnomechanics' className='ms-2'>
                  Tiene tecnomecánica ?{' '}
                  {validation.values.hasTechnomechanics ? (
                    <span className='text-muted'>(SI)</span>
                  ) : (
                    <span className='text-muted'>(NO)</span>
                  )}
                </Label>
              </div>
            </Col>
            <Col lg={4}>
              <div>
                <Input
                  name='finesViolations'
                  id='finesViolations'
                  className=''
                  type='checkbox'
                  onChange={validation.handleChange}
                  value={validation.values.finesViolations}
                  checked={validation.values.finesViolations}
                />
                <Label htmlFor='finesViolations' className='ms-2'>
                  Multas o infracciones ?{' '}
                  {validation.values.finesViolations ? (
                    <span className='text-muted'>(SI)</span>
                  ) : (
                    <span className='text-muted'>(NO)</span>
                  )}
                </Label>
              </div>
            </Col>

            <Col lg={4}>
              <div>
                <Input
                  name='vehicleDelivery'
                  id='vehicleDelivery'
                  className=''
                  type='checkbox'
                  onChange={validation.handleChange}
                  value={validation.values.vehicleDelivery}
                  checked={validation.values.vehicleDelivery}
                />
                <Label htmlFor='vehicleDelivery' className='ms-2'>
                  Vehiculo entregado ?{' '}
                  {validation.values.vehicleDelivery ? (
                    <span className='text-muted'>(SI)</span>
                  ) : (
                    <span className='text-muted'>(NO)</span>
                  )}
                </Label>
              </div>
            </Col>
            <Col lg={4} />
            <Col lg={4} />

            <Col lg={6}>
              {validation.values.hasSoat && (
                <div>
                  <Label htmlFor='soatExpirationDate' className='form-label'>
                    Fecha de expiración de soat
                  </Label>

                  <Input
                    name='soatExpirationDate'
                    id='soatExpirationDate'
                    className='form-control'
                    placeholder='Fecha de expiración de soat'
                    type='date'
                    validate={{
                      required: { value: true }
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.soatExpirationDate}
                    invalid={
                      !!(
                        validation.touched.soatExpirationDate &&
                        validation.errors.soatExpirationDate
                      )
                    }
                  />
                  {validation.touched.soatExpirationDate &&
                    validation.errors.soatExpirationDate && (
                      <FormFeedback type='invalid'>
                        {validation.errors.soatExpirationDate}
                      </FormFeedback>
                    )}
                </div>
              )}
            </Col>
            <Col lg={6}>
              {validation.values.hasTechnomechanics && (
                <div>
                  <Label
                    htmlFor='technomechanicsExpirationDate'
                    className='form-label'
                  >
                    Fecha de expiración de tecnomecánica
                  </Label>

                  <Input
                    name='technomechanicsExpirationDate'
                    id='technomechanicsExpirationDate'
                    className='form-control'
                    placeholder='Fecha de expiración de tecnomecánica'
                    type='date'
                    validate={{
                      required: { value: true }
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.technomechanicsExpirationDate}
                    invalid={
                      !!(
                        validation.touched.technomechanicsExpirationDate &&
                        validation.errors.technomechanicsExpirationDate
                      )
                    }
                  />
                  {validation.touched.technomechanicsExpirationDate &&
                    validation.errors.technomechanicsExpirationDate && (
                      // eslint-disable-next-line react/jsx-indent
                      <FormFeedback type='invalid'>
                        {validation.errors.technomechanicsExpirationDate}
                      </FormFeedback>
                    )}
                </div>
              )}
            </Col>

            <Col lg={6}>
              <div>
                <Label htmlFor='returnDate' className='form-label'>
                  Fecha de restitución
                </Label>

                <Input
                  name='returnDate'
                  id='returnDate'
                  className='form-control'
                  placeholder='Fecha de restitución'
                  type='date'
                  validate={{
                    required: { value: true }
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.returnDate}
                  invalid={
                    !!(
                      validation.touched.returnDate &&
                      validation.errors.returnDate
                    )
                  }
                />
                {validation.touched.returnDate &&
                  validation.errors.returnDate && (
                    // eslint-disable-next-line react/jsx-indent
                    <FormFeedback type='invalid'>
                      {validation.errors.returnDate}
                    </FormFeedback>
                  )}
              </div>
            </Col>
            <Col lg={6}>
              {validation.values.vehicleDelivery && (
                <div>
                  <Label htmlFor='vehicleDeliveryDate' className='form-label'>
                    Fecha de entrega de vehículo
                  </Label>

                  <Input
                    name='vehicleDeliveryDate'
                    id='vehicleDeliveryDate'
                    className='form-control'
                    placeholder='Fecha de entrega de vehículo'
                    type='date'
                    validate={{
                      required: { value: true }
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.vehicleDeliveryDate}
                    invalid={
                      !!(
                        validation.touched.vehicleDeliveryDate &&
                        validation.errors.vehicleDeliveryDate
                      )
                    }
                  />
                  {validation.touched.vehicleDeliveryDate &&
                    validation.errors.vehicleDeliveryDate && (
                      // eslint-disable-next-line react/jsx-indent
                      <FormFeedback type='invalid'>
                        {validation.errors.vehicleDeliveryDate}
                      </FormFeedback>
                    )}
                </div>
              )}
            </Col>

            {validation.values.haveOwnershipCard && (
              <>
                <Col lg={6}>
                  <div>
                    <Label htmlFor='ownerDocument' className='form-label'>
                      Documento del propietario
                    </Label>

                    <Input
                      name='ownerDocument'
                      id='ownerDocument'
                      className='form-control'
                      placeholder='Ingrese documento'
                      type='number'
                      validate={{
                        required: { value: true }
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.ownerDocument}
                      invalid={
                        !!(
                          validation.touched.ownerDocument &&
                          validation.errors.ownerDocument
                        )
                      }
                    />
                    {validation.touched.ownerDocument &&
                      validation.errors.ownerDocument && (
                        <FormFeedback type='invalid'>
                          {validation.errors.ownerDocument}
                        </FormFeedback>
                      )}
                  </div>
                </Col>
                <Col lg={6}>
                  <div>
                    <Label htmlFor='ownerName' className='form-label'>
                      Nombre del propietario
                    </Label>

                    <Input
                      name='ownerName'
                      id='ownerName'
                      className='form-control'
                      placeholder='Ingrese nombre del propietario'
                      type='text'
                      validate={{
                        required: { value: true }
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.ownerName}
                      invalid={
                        !!(
                          validation.touched.ownerName &&
                          validation.errors.ownerName
                        )
                      }
                    />
                    {validation.touched.ownerName &&
                      validation.errors.ownerName && (
                        <FormFeedback type='invalid'>
                          {validation.errors.ownerName}
                        </FormFeedback>
                      )}
                  </div>
                </Col>
              </>
            )}

            <Col lg={6}>
              {validation.values.finesViolations && (
                <div>
                  <Label htmlFor='taxDebt' className='form-label'>
                    Deuda en impuestos
                  </Label>

                  <InputCurrency
                    // decimalsLimit={0}
                    // decimalSeparator=','
                    // groupSeparator='.'
                    name='taxDebt'
                    // prefix='$ '
                    id='taxDebt'
                    className={`form-control ${
                      validation.touched.taxDebt && validation.errors.taxDebt
                        ? 'is-invalid'
                        : ''
                    } `}
                    placeholder='Ingrese de la deuda'
                    // type='number'
                    /* validate={{
                                    required: { value: true }
                                  }} */
                    // onChange={validation.handleChange}
                    onValueChange={(e) => {
                      validation.setFieldValue('taxDebt', e || '')
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.taxDebt}
                    // value={validation.values.taxDebt}
                    /* invalid={
                                    !!(
                                      validation.touched.taxDebt &&
                                      validation.errors.taxDebt
                                    )
                                  } */
                  />
                  {validation.touched.taxDebt && validation.errors.taxDebt && (
                    <FormFeedback type='invalid'>
                      {validation.errors.taxDebt}
                    </FormFeedback>
                  )}
                </div>
              )}
            </Col>
            <Col lg={6} />

            <Col lg={12}>
              <div>
                <Label htmlFor='additionalDescription' className='form-label'>
                  Descripción
                </Label>
                <textarea
                  rows='3'
                  required
                  name='additionalDescription'
                  id='additionalDescription'
                  className='form-control'
                  placeholder='Ingrese una descripción'
                  type='text'
                  validate={{
                    required: { value: true }
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.additionalDescription}
                  invalid={
                    validation.touched.additionalDescription &&
                    validation.errors.additionalDescription
                      ? 'true'
                      : 'false'
                  }
                />
                {validation.errors.additionalDescription ? (
                  <FormFeedback type='invalid'>
                    {validation.errors.additionalDescription}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div className='hstack gap-2 justify-content-end'>
            <Button color='light' onClick={onClose}>
              Cancelar{' '}
            </Button>
            <Button
              type='submit'
              color='success'
              id='add-btn'
              disabled={loadingCreate}
            >
              {loadingCreate && <Spinner size='sm' color='light' />}
              Añadir motocicleta
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
