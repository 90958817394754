import React from 'react'
import { Table, UncontrolledTooltip } from 'reactstrap'

export const MAX_SIZE = 5269095

export const MAX_SIZE_ALL_FILES = 21076380

/** @param {{files: File[], setDeleteFile: (fileName: string)=>void}} */
export const TableCommentCredit = ({ files, setDeleteFile }) => {
  const formatFileSize = (bytes) => {
    if (bytes < 1024) {
      return bytes + ' B'
    } else if (bytes < 1024 * 1024) {
      return (bytes / 1024).toFixed(2) + ' KB'
    } else if (bytes < 1024 * 1024 * 1024) {
      return (bytes / (1024 * 1024)).toFixed(2) + ' MB'
    } else {
      return (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB'
    }
  }
  return (
    <div className='table-responsive w-100'>
      <Table className='table-striped table-nowrap align-middle mb-0'>
        <thead>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>Nombre</th>
            <th scope='col'>Tamaño</th>
            <th scope='col'>tipo</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {files.map(({ name, size, type }, idx) => (
            <tr key={name}>
              <td className='fw-medium'>
                {idx < 10 ? `0${idx + 1}` : idx + 1}
              </td>
              <td style={{ maxWidth: '130px' }} className='truncate-ts'>
                {name}
              </td>
              <td style={{ color: size > MAX_SIZE ? 'red' : 'green' }}>
                {size > MAX_SIZE && (
                  <>
                    <i
                      className='mdi mdi-information-outline me-1'
                      id={`info-sizes-comment-${idx}`}
                    />
                    <UncontrolledTooltip target={`#info-sizes-comment-${idx}`}>
                      Este archivo sobre pasa el tamaño permitido
                    </UncontrolledTooltip>
                  </>
                )}
                {formatFileSize(size)}
              </td>
              <td style={{ maxWidth: '100px' }} className='truncate-ts-type'>
                {type}
              </td>
              <td>
                <button
                  className='border-0 bg-transparent p-1 m-0'
                  title='Eliminar'
                  onClick={() => setDeleteFile(name)}
                >
                  <i className='mdi mdi-trash-can-outline text-danger' />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
