import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useQuery, useLazyQuery } from '@apollo/client'
import { Link, useNavigate } from 'react-router-dom'
import Cropper from 'react-easy-crop'

// Import Images

import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  ModalBody,
  Label,
  Input,
  Modal,
  ModalHeader,
  Form,
  ModalFooter,
  Button,
  FormFeedback,
  UncontrolledTooltip,
  FormGroup,
  UncontrolledPopover,
  Badge,
  PopoverHeader,
  PopoverBody,
  Spinner,
  Table
} from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'

// Formik
import * as Yup from 'yup'
import { useFormik } from 'formik'

// Export Modal
// import ExportCSVModal from '../../../Components/Common/ExportCSVModal'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import { GET_INFO_EMP_COMER } from '../../../graphql/queries/getPuntos'
import { hasPermission, parseId, parsePrice } from '../../../helpers'
import {
  GET_CATEGORIES_TO_PRODUCT,
  GET_PRODUCTS
} from '../../../graphql/queries/getProducts'
// import CurrencyInput from 'react-currency-input-field'
import getCroppedImg from '../../../helpers/toImgFile'
import { useGetUser } from '../../../hooks/user'
import { API } from '../../../graphql/client'
import { ModalExportProducts } from '../../../Components/Own/ModalExportProducts'
import { ALL_PERMISSIONS, ERROR_MESSAGES } from '../../../helpers/consts'
import ModalUpploadExcel from '../../../Components/Own/ModalUpploadExcel'
import { CREATE_MASIVE_PRODUCTS } from '../../../graphql/mutations/products'
import { useMutationFetch } from '../../../hooks/useMutationFetch'
import { InputCurrency } from '../../../Components/Own/InputCurrency'
// import { CREATE_PRODUCT } from '../../../graphql/mutations/products'

/* const RESET = {
  name: '',
  sku: '',
  basePrice: '',
  image: '',
  commercialCompany: '',
  active: false
} */

/* const getCreateMutation = (input) => {
  const obj = {
    query:
      'mutation($input: ProductCreateUpdateInput!){productCreateUpdate(input: $input){ \n product{ \n id \n name \n } \n success \n errors { \n field \n message } \n } \n }',
    variables: {
      input: {
        ...input,
        image: null
      }
    }
  }
  return JSON.stringify(obj)
} */

const ProductosCustom = () => {
  /*  const dispatch = useDispatch();
 const { companies, isCompaniesSuccess, error } = useSelector((state) => ({
   companies: state.Crm.companies,
   isCompaniesSuccess: state.Crm.isCompaniesSuccess,
   error: state.Crm.error,
  })); */

  /* const [
    gqlCreateProduct,
    { data: dataCreate, loading: loadingCreate, error: errorCreate }
  ] = useMutation(CREATE_PRODUCT) */

  const [gqlGetProducts, { data, loading, refetch, error }] = useLazyQuery(
    GET_PRODUCTS,
    {
      fetchPolicy: 'no-cache'
    }
  )

  /* const {
    data: dataEmpsCom
    loading: loadingEmpsCom,
    error: errorEmpsCom
  } = useQuery(GET_INFO_EMP_COMER, {
    fetchPolicy: 'no-cache'
  }) */
  const {
    data: dataCategories
    /*     loading: loadingEmpsCom,
    error: errorEmpsCom */
  } = useQuery(GET_CATEGORIES_TO_PRODUCT, {
    fetchPolicy: 'network-only'
  })

  const [
    createAllProducts,
    {
      data: dataCreateAllProds,
      loading: loadingCreateAllProds,
      error: errorCreateAllProds
    }
  ] = useMutationFetch()

  const navigate = useNavigate()
  const { logout, permissions, user } = useGetUser()

  const [loadingCreate, setLoadingCreate] = useState(false)

  const inputRef = useRef()
  /**
   * @type {[products: {node:{id: string, sku: string, name: string, basePrice: string}}[], ()=>void]}
   */
  const [products, setProducts] = useState([])
  // const [infoEmpComer, setInfoEmpComer] = useState([])
  const [infoCategories, setInfoCategories] = useState([])

  const [imgToEdit, setImgToEdit] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedArea, setCroppedArea] = React.useState(null)

  // delete Company
  const [modal, setModal] = useState(false)
  const [modalUpload, setModalUpload] = useState(false)

  const [modalExport, setModalExport] = useState(false)

  const [loadingDownload, setLoadingDownload] = useState(false)
  const [modalUploadAllProducts, setModalUploadAllProducts] = useState(false)

  const [offset, setOffset] = useState(0)
  const [isPag, setIsPag] = useState(false)
  const [isActive, setIsActive] = useState('1')

  const [nameFilter, setNameFilter] = useState('')

  // const [isExportCSV, setIsExportCSV] = useState(false)

  const toggle = useCallback(() => {
    setModal((prev) => !prev)
  }, [modal])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: '',
      sku: '',
      basePrice: '',
      image: null,
      category: '',
      active: true
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Ingrese nombre del producto'),
      basePrice: Yup.string()
        // .matches(/^[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]+)?$/, 'Formato inválido')
        .required('Ingrese precio'),
      sku: Yup.string().required('Ingrese SKU'),
      category: Yup.string().required('Seleccione una categoría')
    }),
    onSubmit: async (values) => {
      const newCompany = { ...values }
      const id = parseId(newCompany.category)

      const input = {
        ...newCompany,
        category: id
        // image: newFile
      }

      const formData = new FormData()

      if (imgToEdit) {
        const res = await getCroppedImg(imgToEdit, croppedArea)
        const resBuff = await fetch(res)
        const buff = await resBuff.arrayBuffer()
        const newFile = new window.File([buff], `${Date.now()}-moto.png`, {
          type: 'image/png'
        })
        // console.log('newFile', newFile)
        input.image = newFile
        formData.append('map', '{"0": ["variables.input.image"]}')
        formData.append('0', newFile, newFile.name)
      } else {
        delete input.image
      }

      const myHeaders = new Headers()
      const token = JSON.parse(window.localStorage.getItem('token'))

      if (!token) {
        return logout()
      }

      myHeaders.append('Authorization', `Bearer ${token}`)
      /*       myHeaders.append(
        'Cookie',
        'csrftoken=le5GvRfK7iZkorIAyYzckwQ7m3ux3RztbFZqjr3Rvv4KAcVBn52gQGazZPqaWm5u'
      )
 */
      // const formdata = new FormData()
      formData.append(
        'operations',
        `{"variables": {"input": {"name": "${input.name}","sku": "${
          input.sku
        }","basePrice": ${input.basePrice.replace(/,/, '.')},"category": ${
          input.category
        }${
          imgToEdit ? ',"image": null' : ''
        }}}, "query": "mutation ($input: ProductCreateUpdateInput!){productCreateUpdate(input: $input){ errors{field message} product{id} success } }"}`
      )
      // console.log('input', input)

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        redirect: 'follow'
      }
      setLoadingCreate(true)
      fetch(API + '/api', requestOptions)
        .then((response) => response.json())
        .then((dataCreate) => {
          setLoadingCreate(false)
          // console.log('dataCreate', dataCreate)
          if (dataCreate?.data?.productCreateUpdate?.success) {
            // console.log('dataCreate', dataCreate);
            toast.success('Producto creado correctamente')
            validation.resetForm()
            setImgToEdit(null)
            refetch()
            toggle()
          } else {
            console.log('dataCreate.errors', dataCreate.errors)
            toast.error(`ERROR! ${dataCreate?.errors?.message}`)
          }
        })
        .catch((errorCreate) => {
          console.log('errorCreate', errorCreate)
          setLoadingCreate(false)
          toast.error(`ERROR! ${errorCreate.message}`)
        })

      /* gqlCreateProduct({
        variables: {
          input
        },
        refetchQueries: [
          {
            query: GET_PRODUCTS
          }
        ]
      }) */

      /* gqlCreateEmpresaComercial({
          variables: {
            input: newCompany,
          },
          refetchQueries: [
            {
              query: GET_PUNTOS_EMP_COMER,
            },
          ],
        }); */
      // validation.resetForm();
    }
  })

  useEffect(() => {
    if (!hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_EL_PRODUCTO)) {
      navigate('/')
    }
  }, [permissions])

  useEffect(() => {
    const variables = {
      offset
    }

    if (isActive) {
      variables.active = Boolean(Number(isActive))
    }

    if (nameFilter) {
      variables.name = nameFilter
    }

    gqlGetProducts({
      variables
    })
  }, [offset, isActive, nameFilter])

  /* useEffect(() => {
    if (dataCreate) {
      if (dataCreate.productCreateUpdate.product) {
        // console.log('dataCreate', dataCreate);
        toast.success('Producto creado correctamente')
        validation.resetForm()
        toggle()
        return
      }

      if (
        dataCreate.productCreateUpdate?.errors &&
        dataCreate.productCreateUpdate?.errors.length > 0
      ) {
        toast.error(
          `ERROR! ${dataCreate.productCreateUpdate?.errors[0]?.messages[0]}`
        )
      }
    }
  }, [dataCreate])
  useEffect(() => {
    if (errorCreate) {
      toast.error(`ERROR! ${errorCreate.message}`)
    }
  }, [errorCreate]) */

  useEffect(() => {
    if (!data) return

    const newArr = data.listProducts.edges

    if (isPag) {
      setProducts((p) => [...p, ...newArr])
    } else {
      setProducts(newArr)
    }
  }, [data])

  useEffect(() => {
    if (error) {
      toast.error(`ERROR! ${error.message}`)
      if (error.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [error])

  /* useEffect(() => {
    if (dataEmpsCom) {
     @type {{ 'cursor': string, node: {id: string, name: string}}[]} arr
      const arr = dataEmpsCom.listCommercialCompany.edges
      setInfoEmpComer(arr.map(({ node: { id, name } }) => ({ id, name })))
    }
  }, [dataEmpsCom]) */
  useEffect(() => {
    if (dataCategories) {
      /** @type {{ 'cursor': string, node: {id: string, name: string}}[]} arr */
      const arr = dataCategories.categoryList.edges
      setInfoCategories(arr.map(({ node: { id, name } }) => ({ id, name })))
    }
  }, [dataCategories])

  useEffect(() => {
    if (dataCreateAllProds) {
      if (dataCreateAllProds.masiveProductCreate.success) {
        toast.success('Productos creados correctamente')
        refetch()
        setModalUploadAllProducts(false)
        document.getElementById('formCreateAllProducts')?.reset()
      } else {
        toast.error(
          `Error ${dataCreateAllProds.masiveProductCreate.errors.message}`
        )
      }
    }
  }, [dataCreateAllProds])

  useEffect(() => {
    if (errorCreateAllProds) {
      toast.error(`ERROR: ${errorCreateAllProds}`)
    }
  }, [errorCreateAllProds])

  const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels)
  }, [])

  const openModalExport = () => {
    setModalExport(true)
  }
  const closeModalExport = () => {
    setModalExport(false)
  }

  const toggleModalExport = () => {
    setModalExport((prev) => !prev)
  }
  const toggleUpload = () => {
    setModalUpload((p) => !p)
  }

  const toggleUploadAllProducts = () => {
    setModalUploadAllProducts((p) => !p)
  }

  // SideBar Company Deatail
  /** @type {[{name: string, sku: string, basePrice: string, image: string, commercialCompany: string, active: boolean, }]} info
  const [info] = useState(null)
  */

  const closeModalUppload = () => {
    setModalUpload(false)
  }

  const formSubmitAllProducts = (e) => {
    e.preventDefault()
    const values = Object.fromEntries(new FormData(e.target))
    if (values.file) {
      const formdata = new FormData()
      formdata.append('0', values.file, values.file.name)
      formdata.append('map', '{"0": ["variables.input.file"]}')
      const obj = {
        query: CREATE_MASIVE_PRODUCTS,
        variables: {
          input: {
            file: null
          }
        }
      }
      formdata.append('operations', JSON.stringify(obj))
      createAllProducts(formdata)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const { name } = Object.fromEntries(new FormData(e.target))
    if (!name) return
    setOffset(0)
    setProducts([])
    setNameFilter(name)
  }

  document.title = 'Productos | Total Sales'
  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Productos' pageTitle='CRM' />

          <Row>
            <Col lg={12}>
              <Card>
                {hasPermission(
                  permissions,
                  ALL_PERMISSIONS.PUEDE_ANADIR_PRODUCTO
                ) && (
                  <CardHeader>
                    <div className='d-flex align-items-center flex-wrap gap-2'>
                      <div className='flex-grow-1'>
                        <button
                          className='btn btn-info add-btn'
                          onClick={() => {
                            toggle()
                          }}
                        >
                          <i className='ri-add-fill me-1 align-bottom' />{' '}
                          Agregar producto
                        </button>
                      </div>

                      <div className='flex-shrink-0 d-flex gap-2'>
                        <button
                          className='btn btn-success d-flex align-items-center gap-1'
                          onClick={() => openModalExport()}
                        >
                          <i className='ri-download-2-line' />
                          Exportar
                        </button>
                        <button
                          className='btn btn-info d-flex align-items-center gap-1'
                          onClick={() => setModalUpload(true)}
                        >
                          <i className='mdi mdi-file-upload-outline' />
                          Subir archivo
                        </button>
                        <button
                          className='btn btn-soft-dark d-flex align-items-center gap-1 p-0 px-2'
                          onClick={() => {}}
                          id='PopoverLegacy22'
                        >
                          <i className='mdi mdi-table-plus fs-4' />
                        </button>
                        <UncontrolledPopover
                          placement='left'
                          target='PopoverLegacy22'
                          trigger='legacy'
                        >
                          <PopoverHeader className='bg-soft-dark p-2'>
                            Seleccione acción
                          </PopoverHeader>
                          <PopoverBody>
                            A continuación podrá elegir si desea descargar la
                            plantilla a editar para subir los productos de forma
                            masiva.
                            <div className='d-flex justify-content-between gap-2 mt-2 flex-column'>
                              <Button
                                disabled={loadingDownload}
                                className='d-flex align-items-center justify-content-center gap-1'
                                onClick={() => {
                                  setLoadingDownload(true)
                                  fetch(`${API}/export/create_products`, {
                                    headers: {
                                      Authorization: `Bearer ${user.tokenAuth.token}`
                                    }
                                  })
                                    .then((response) => response.blob())
                                    .then((blob) => {
                                      const url =
                                        window.URL.createObjectURL(blob)
                                      const link = document.createElement('a')
                                      link.href = url
                                      link.download = `productos-${
                                        new Date().toISOString().split('T')[0]
                                      }.xlsx`
                                      document.body.appendChild(link)
                                      setLoadingDownload(false)
                                      link.click()
                                    })
                                    .catch((error) => {
                                      toast.error(
                                        `Error al descargar el producto ${
                                          error?.toString() || ''
                                        }`
                                      )
                                    })
                                }}
                              >
                                {loadingDownload ? (
                                  <Spinner size='sm' />
                                ) : (
                                  <i className='mdi mdi-arrow-collapse-down' />
                                )}
                                Descargar
                              </Button>
                              <Button
                                outline
                                color='danger'
                                className='d-flex align-items-center gap-1 justify-content-center'
                                onClick={() => {
                                  document
                                    .getElementById('PopoverLegacy22')
                                    ?.click()
                                  setModalUploadAllProducts(true)
                                }}
                              >
                                <i className='mdi mdi-upload-outline' />
                                Subir productos
                              </Button>
                            </div>
                          </PopoverBody>
                        </UncontrolledPopover>
                      </div>
                      {/* <div className='flex-shrink-0'>
                      <div className='hstack text-nowrap gap-2'>
                        {isMultiDeleteButton && (
                          <button
                            className='btn btn-soft-danger'
                            onClick={() => setDeleteModalMulti(true)}
                          >
                            <i className='ri-delete-bin-2-line'></i>
                          </button>
                        )}
                        <button className='btn btn-danger'>
                          <i className='ri-filter-2-line me-1 align-bottom'></i>{' '}
                          Filters
                        </button>
                        <button
                          className='btn btn-soft-success'
                          onClick={() => setIsExportCSV(true)}
                        >
                          Export
                        </button>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            href='#'
                            className='btn-soft-info btn-icon'
                            tag='button'
                          >
                            <i className='ri-more-2-fill'></i>
                          </DropdownToggle>
                          <DropdownMenu className='dropdown-menu-end'>
                            <DropdownItem className='dropdown-item' href='#'>
                              All
                            </DropdownItem>
                            <DropdownItem className='dropdown-item' href='#'>
                              Last Week
                            </DropdownItem>
                            <DropdownItem className='dropdown-item' href='#'>
                              Last Month
                            </DropdownItem>
                            <DropdownItem className='dropdown-item' href='#'>
                              Last Year
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div> */}
                    </div>
                  </CardHeader>
                )}
              </Card>
            </Col>
            <Col>
              <Card id='companyList'>
                <CardBody>
                  <div className='mt-6 d-flex gap-4 align-items-center justify-content-between w-100 flex-column flex-md-row'>
                    <form
                      onSubmit={handleSubmit}
                      style={{ position: 'relative' }}
                      className='w-100'
                    >
                      <input
                        placeholder='Buscar producto'
                        className='form-control ps-4'
                        type='search'
                        name='name'
                        onInput={(e) => {
                          if (e.target.value === '') {
                            setNameFilter('')
                          }
                        }}
                      />
                      <i
                        className='bx bx-search-alt-2'
                        style={{
                          position: 'absolute',
                          top: '12px',
                          left: '10px'
                        }}
                      />
                    </form>
                    <div className='w-100'>
                      <select
                        className='form-select w-100'
                        value={isActive}
                        onChange={(e) => setIsActive(e.target.value)}
                      >
                        <option value=''>Seleccione estado</option>
                        <option value='1'>Activo</option>
                        <option value='0'>No activo</option>
                      </select>
                    </div>
                  </div>
                  {loading && !isPag && (
                    <div
                      className='d-flex justify-content-center align-items-center'
                      style={{ height: '40vh' }}
                    >
                      <Spinner />
                    </div>
                  )}

                  {products.length === 0 && !loading && (
                    <div
                      className='d-flex justify-content-center align-items-center'
                      style={{ height: '40vh' }}
                    >
                      <h6>No hay productos</h6>
                    </div>
                  )}
                  {products.length > 0 && !loading && (
                    <div className='pt-4'>
                      <div className='table-responsive'>
                        <Table className='table-hover align-middle table-nowrap mb-0'>
                          <thead>
                            <tr>
                              <th scope='col'>Nombre de producto</th>
                              <th scope='col'>Categoría</th>
                              <th scope='col'>SKU</th>
                              <th scope='col'>Precio</th>
                              <th scope='col'>Estado</th>
                              <th scope='col' />
                            </tr>
                          </thead>
                          <tbody>
                            {products.map(({ node }) => (
                              <tr key={node.id}>
                                <td>
                                  <>
                                    <div className='d-flex align-items-center'>
                                      <div className='flex-shrink-0'>
                                        {node.image ? (
                                          <>
                                            <img
                                              src={node.image}
                                              alt=''
                                              className='avatar-xxs rounded-circle'
                                              loading='lazy'
                                              id={`popover-${parseId(node.id)}`}
                                            />

                                            <UncontrolledPopover
                                              placement='right-end'
                                              target={`popover-${parseId(
                                                node.id
                                              )}`}
                                              trigger='hover'
                                            >
                                              <img
                                                src={node.image}
                                                alt='Imagen Grande'
                                                className='img-thumbnail'
                                                loading='lazy'
                                              />
                                            </UncontrolledPopover>
                                          </>
                                        ) : (
                                          <div className='flex-shrink-0 avatar-xs me-2'>
                                            <div className='avatar-title bg-soft-success text-success rounded-circle fs-13'>
                                              {node.name.charAt(0)}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <div className='flex-grow-1 ms-2 name'>
                                        {node.name}
                                      </div>
                                    </div>
                                  </>
                                </td>
                                <td>{node.category.name}</td>
                                <td>{node.sku}</td>
                                <td>{parsePrice(node.basePrice)}</td>
                                <td>
                                  <Badge
                                    color={node.active ? 'success' : 'danger'}
                                  >
                                    {node.active ? 'Activo' : 'No activo'}
                                  </Badge>
                                </td>
                                <td>
                                  <Link
                                    to={`/producto/${parseId(node.id)}`}
                                    id={`tolpit${parseId(node.id)}`}
                                  >
                                    <i className='mdi mdi-eye' />
                                  </Link>
                                  <UncontrolledTooltip
                                    target={`tolpit${parseId(node.id)}`}
                                  >
                                    Ver
                                  </UncontrolledTooltip>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          {data?.listProducts?.pageInfo?.hasNextPage && (
                            <tfoot>
                              <tr>
                                <td colSpan={6}>
                                  <div className='d-flex align-items-center justify-content-center w-100'>
                                    <Button
                                      disabled={loading}
                                      className='d-flex align-items-center gap-2'
                                      onClick={() => {
                                        setOffset((p) => p + 1)
                                        setIsPag(true)
                                      }}
                                    >
                                      {loading && <Spinner size='sm' />}
                                      Cargar más
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            </tfoot>
                          )}
                        </Table>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            id='showModal'
            isOpen={modal}
            toggle={toggle}
            centered
            size='lg'
          >
            <ModalHeader className='bg-soft-info p-3' toggle={toggle}>
              Añadir producto
            </ModalHeader>
            <Form
              autoComplete='off'
              className='tablelist-form'
              onSubmit={(e) => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <ModalBody>
                <input type='hidden' id='id-field' />
                <Row className='g-3'>
                  {imgToEdit ? (
                    <Col
                      lg={12}
                      style={{ height: '250px', backgroundColor: '#878787' }}
                      className='relative'
                    >
                      <div
                        style={{ zIndex: 10 }}
                        className='position-absolute top-0 start-0'
                        onClick={() => {
                          // console.log('clik')
                          setImgToEdit(null)

                          validation.setFieldValue('image', null)
                          if (inputRef.current) {
                            inputRef.current.value = null
                          }
                        }}
                      >
                        <div className='mb-0'>
                          <div className='avatar-xs cursor-pointer'>
                            <div className='avatar-title bg-transparent text-light'>
                              {/* <i className='ri-image-fill' /> */}
                              <i
                                className='las la-times-circle'
                                style={{ fontSize: '24px' }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <Cropper
                        image={imgToEdit}
                        crop={crop}
                        zoom={zoom}
                        aspect={1 / 1}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                      />
                    </Col>
                  ) : (
                    <Col lg={12}>
                      <div className='text-center'>
                        <div className='position-relative d-inline-block'>
                          <div className='position-absolute bottom-0 end-0'>
                            <Label htmlFor='image' className='mb-0'>
                              <div className='avatar-xs cursor-pointer'>
                                <div className='avatar-title bg-light border rounded-circle text-muted'>
                                  <i className='ri-image-fill' />
                                </div>
                              </div>
                            </Label>
                            <input
                              ref={inputRef}
                              name='image'
                              className='form-control d-none'
                              id='image'
                              type='file'
                              accept='image/png, image/gif, image/jpeg'
                              onChange={(e) => {
                                validation.setFieldValue(
                                  'image',
                                  e.currentTarget.files[0]
                                )
                                // eslint-disable-next-line no-undef
                                const reader = new FileReader()
                                reader.readAsDataURL(e.currentTarget.files[0])
                                reader.addEventListener('load', () => {
                                  setImgToEdit(reader.result)
                                })
                              }}
                              // value={validation.values.image?.name || ''}
                            />
                          </div>
                          <div className='avatar-lg p-1'>
                            <div className='avatar-title bg-light rounded-circle'>
                              <img
                                src='https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/681px-Placeholder_view_vector.svg.png'
                                alt='multiUser'
                                id='productlogo-img'
                                className='avatar-md rounded-circle object-cover'
                              />
                            </div>
                          </div>
                        </div>
                        <h5 className='fs-13 mt-3'>Imagen del producto</h5>
                      </div>
                    </Col>
                  )}

                  <Col lg={12}>
                    <div>
                      <FormGroup>
                        <Label htmlFor='category-field' className='form-label'>
                          Categoría
                        </Label>

                        <Input
                          name='category'
                          type='select'
                          className='form-select'
                          id='category-field'
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.category}
                          invalid={Boolean(
                            validation.touched.category &&
                              validation.errors.category
                          )}
                        >
                          <option value=''>Seleccione categoria</option>
                          {infoCategories.map(({ name, id }) => (
                            <option value={id} key={id}>
                              {name}
                            </option>
                          ))}
                        </Input>
                        {validation.touched.category &&
                        validation.errors.category ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.category}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </div>
                  </Col>

                  {/* <Col lg={6}>
                    {isEdit && (
                      <div>
                        <Label htmlFor='active-field' className='form-label'>
                          Producto activo
                        </Label>
                        <div className='form-check form-switch form-switch-lg form-switch-success'>
                          <Input
                            type='checkbox'
                            className='form-check-input'
                            name='active'
                            id='active-field'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.active}
                            checked={validation.values.active}
                          />
                        </div>
                        {validation.touched.active &&
                        validation.errors.active ? (
                          <FormFeedback type='invalid'>
                            {validation.errors.active}
                          </FormFeedback>
                        ) : null}
                      </div>
                    )}
                  </Col> */}

                  <Col lg={12}>
                    <div>
                      <Label htmlFor='name-field' className='form-label'>
                        Nombre de producto
                      </Label>

                      <Input
                        name='name'
                        id='customername-field'
                        className='form-control'
                        placeholder='Nombre del producto'
                        type='text'
                        validate={{
                          required: { value: true }
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ''}
                        invalid={
                          !!(validation.touched.name && validation.errors.name)
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div>
                      <Label htmlFor='sku-field' className='form-label'>
                        SKU
                      </Label>
                      <Input
                        name='sku'
                        id='sku-field'
                        className='form-control'
                        placeholder='Ingrese SKU de producto'
                        type='text'
                        validate={{
                          required: { value: true }
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.sku}
                        invalid={
                          !!(validation.touched.sku && validation.errors.sku)
                        }
                      />
                      {validation.touched.sku && validation.errors.sku ? (
                        <FormFeedback type='invalid'>
                          {validation.errors.sku}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div>
                      <FormGroup>
                        <Label htmlFor='basePrice' className='form-label'>
                          Precio
                        </Label>

                        <InputCurrency
                          id='basePrice'
                          name='basePrice'
                          // locale={locale}
                          placeholder='Precio base'
                          className={`form-control ${
                            validation.touched.basePrice &&
                            validation.errors.basePrice
                              ? 'is-invalid'
                              : ''
                          } `}
                          // currency={currency}
                          // prefix='$ '
                          value={validation.values.basePrice}
                          // decimalsLimit={fractions}
                          // decimalSeparator=','
                          // groupSeparator='.'
                          onValueChange={(value, name) => {
                            validation.setFieldValue(name, value)
                          }}
                          // allowNegativeValue={false}
                          onBlur={validation.handleBlur}
                        />

                        {/* <Input
                        name='basePrice'
                        id='price-field'
                        className='form-control'
                        placeholder='Ingrese precio'
                        type='number'
                        validate={{
                          required: { value: true }
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.basePrice}
                        invalid={
                          !!(
                            validation.touched.basePrice &&
                            validation.errors.basePrice
                          )
                        }
                      /> */}
                        {validation.touched.basePrice &&
                          validation.errors.basePrice && (
                            <div
                              style={{
                                width: '100%',
                                marginTop: '0.25rem',
                                fontSize: '0.875em',
                                color: '#f06548'
                              }}
                            >
                              {validation.errors.basePrice}
                            </div>
                          )}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <div className='hstack gap-2 justify-content-end'>
                  <Button
                    color='light'
                    onClick={() => {
                      setImgToEdit(null)
                      if (inputRef.current) {
                        inputRef.current.value = null
                      }
                      validation.resetForm()
                      setModal(false)
                    }}
                  >
                    Cancelar{' '}
                  </Button>
                  <Button
                    type='submit'
                    color='success'
                    id='add-btn'
                    disabled={loadingCreate}
                  >
                    {loadingCreate ? 'cargando...' : 'Agregar producto'}
                  </Button>
                </div>
              </ModalFooter>
            </Form>
          </Modal>

          <ModalExportProducts
            modalExport={modalExport}
            openModalExport={openModalExport}
            closeModalExport={closeModalExport}
            toggleModalExport={toggleModalExport}
          />

          <ModalUpploadExcel
            modalUpload={modalUpload}
            toggleUpload={toggleUpload}
            closeModalUppload={closeModalUppload}
            refetch={refetch}
          />
        </Container>

        <Modal
          id='showModalUploadProduct'
          isOpen={modalUploadAllProducts}
          toggle={toggleUploadAllProducts}
        >
          <ModalHeader
            className='bg-soft-info p-3'
            toggle={toggleUploadAllProducts}
          >
            Carga masiva de productos
          </ModalHeader>
          <form onSubmit={formSubmitAllProducts} id='formCreateAllProducts'>
            <ModalBody>
              <Row className='g-3'>
                <Col lg={12}>
                  <Label className='d-flex align-items-center gap-1'>
                    <i className='mdi mdi-microsoft-excel fs-4' />
                    Cargar archivo excel
                  </Label>

                  <Input
                    id='excel-upload'
                    placeholder='Seleccione un archivo Excel'
                    type='file'
                    accept='.xlsx, .xls'
                    name='file'
                    required
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <div className='d-flex gap-2 justify-content-end'>
                <Button
                  type='button'
                  color='light'
                  onClick={() => {
                    setModalUploadAllProducts(false)
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  type='submit'
                  className='d-flex align-items-center gap-1'
                  disabled={loadingCreateAllProds}
                >
                  {loadingCreateAllProds && <Spinner size='sm' color='light' />}
                  Crear productos
                </Button>
              </div>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    </>
  )
}

export default ProductosCustom

/*
const myHeaders = new Headers()
      const tokenJSON = window.localStorage.getItem('token')
      if (!tokenJSON) return
      const token = JSON.parse(tokenJSON)
      myHeaders.append('Authorization', `Bearer ${token}`)
      const formdata = new FormData()
      formdata.append('0', newFile, 'C:\\fakepath\\' + newFile.name)
      formdata.append('map', '{"0": ["variables.input.image"]}')
      formdata.append('operations', getCreateMutation(input))

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      }
      try {
        setLoadingCreate(true)
        const res = await fetch(
          'https://totalsales.backend.crediscore.co/api',
          requestOptions
        )
        const json = await res.json()

        console.log('json', json)

        if (json.errors) {
          toast.error(json.errors[0].message)
          setLoadingCreate(false)
          return
        }

        const { data } = json
        setDataCreate(data)
        setLoadingCreate(false)
      } catch (error) {
        setLoadingCreate(false)
        setErrorCreate(error.message)
      }
*/
