import React, { useEffect, useState } from 'react'
import {
  Button,
  CardFooter,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner
} from 'reactstrap'
import { InputCurrency } from './InputCurrency'
import { parsePrice, parsePriceToNum } from '../../helpers'
import { useMutation } from '@apollo/client'
import { CALCULATOR } from '../../graphql/mutations/userMutations'
import { toast } from 'react-toastify'

export const CalculatorDropdown = () => {
  const [gqlCalculator, { data, loading, error }] = useMutation(CALCULATOR)
  const [isWebAppDropdown, setIsWebAppDropdown] = useState(false)
  const [result, setResult] = useState('0')

  useEffect(() => {
    if (!error) return
    toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.calculator.success) {
      setResult(data.calculator.quota)
    } else if (data.calculator?.errors) {
      toast.error(
        'Error: ' + data.calculator.errors?.message ||
          'No se pudo calcular crédito'
      )
    }
  }, [data])
  useEffect(() => {
    if (!isWebAppDropdown) {
      setResult('0')
    }
  }, [isWebAppDropdown])

  const toggleWebAppDropdown = () => {
    setIsWebAppDropdown(!isWebAppDropdown)
  }
  return (
    <>
      <Dropdown
        isOpen={isWebAppDropdown}
        toggle={toggleWebAppDropdown}
        className='topbar-head-dropdown ms-1 header-item'
        title='Calculadora créditos'
      >
        <DropdownToggle
          tag='button'
          type='button'
          className='btn btn-icon btn-topbar btn-ghost-secondary rounded-circle'
        >
          <i className='mdi mdi-calculator-variant-outline fs-22' />
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu-lg p-0 dropdown-menu-end'>
          <div className='p-3 border-top-0 border-start-0 border-end-0 border-dashed border bg-soft-info'>
            <Row className='align-items-center'>
              <Col>
                <h6 className='m-0 fw-semibold fs-15'> Calcular crédito</h6>
              </Col>
              {/* <div className='col-auto'>
                <Link to='#' className='btn btn-sm btn-soft-info'>
                  {' '}
                  View All Apps
                  <i className='ri-arrow-right-s-line align-middle'></i>
                </Link>
              </div> */}
            </Row>
          </div>

          <div className='p-2'>
            <div className='row g-0'>
              <Form
                onSubmit={(e) => {
                  e.preventDefault()
                  const { term, value, creditLine } = Object.fromEntries(
                    new FormData(e.target)
                  )
                  if (!term || !value || !creditLine) return
                  gqlCalculator({
                    variables: {
                      input: {
                        term,
                        value: parsePriceToNum(value),
                        creditLine
                      }
                    }
                  })
                }}
              >
                <Col>
                  <FormGroup>
                    <Label>Valor a financiar</Label>
                    <InputCurrency
                      required
                      name='value'
                      placeholder='Ingrese valor a financiar'
                      autoComplete='off'
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Plazo</Label>
                    <Input
                      type='number'
                      max={48}
                      min={1}
                      name='term'
                      placeholder='Ingrese número de cuotas'
                      defaultValue={0}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for='creditLine'>Línea crédito</Label>
                    <Input
                      id='creditLine'
                      name='creditLine'
                      type='select'
                      defaultValue=''
                      required
                    >
                      <option value=''>Seleccione una línea</option>
                      <option value='MOTOCICLETAS'>Motocicletas</option>
                      <option value='LIBRE_INVERSION'>Libre inversión</option>
                    </Input>
                  </FormGroup>
                </Col>
                <CardFooter className='p-2 d-flex justify-content-between align-items-center'>
                  <div className='d-flex flex-column'>
                    <span className='d-flex align-items-center gap-2'>
                      <small>Valor mensual</small>
                      {loading && <Spinner size='sm' />}
                    </span>
                    <h3 className='m-0 p-0'>
                      {loading ? <Spinner size='sm' /> : parsePrice(result)}
                    </h3>
                  </div>
                  <Button disabled={loading}>Calcular</Button>
                </CardFooter>
              </Form>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}
