import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  Label,
  Input,
  FormFeedback,
  ModalFooter,
  Button,
  Spinner
} from 'reactstrap'
import * as Yup from 'yup'
import { InputCurrency } from '../InputCurrency'
import { useMutation } from '@apollo/client'
import { APPROVE_DISCOUNT } from '../../../graphql/mutations/billing'
import { parseId } from '../../../helpers'
import { toast } from 'react-toastify'
/**
 *
 * @param {{isOpen: boolean, onClose: ()=>void, info: {billingId: string,discountId: string,value: string, productName: string, discountName: string}, refetch: ()=>void, closeParentModal: ()=>void}} param0
 * @returns {JSX.Element}
 */
export const ModalConfirmDiscount = ({
  isOpen,
  onClose,
  info,
  refetch,
  closeParentModal
}) => {
  const [gqlApprove, { data, loading, error }] = useMutation(APPROVE_DISCOUNT)
  const formik = useFormik({
    initialValues: {
      billingId: '',
      discountId: '',
      value: '',
      comment: ''
    },
    validationSchema: Yup.object({
      billingId: Yup.string().required('Facturación requerida'),
      discountId: Yup.string().required('Descuento requerido'),
      comment: Yup.string().required('Comentario requerido'),
      value: Yup.string().required('Precio requerido')
    }),
    onSubmit: (values) => {
      console.log('values', values)
      const input = {
        ...values,
        discountId: parseId(values.discountId)
      }
      gqlApprove({
        variables: {
          input
        }
      })
    }
  })

  useEffect(() => {
    if (!info) return
    formik.setValues({
      billingId: info.billingId,
      discountId: info.discountId,
      value: info.value,
      comment: ''
    })
  }, [info])

  useEffect(() => {
    if (error) {
      toast.error('Error: ' + error.message)
    }
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.approveBillingDiscount.success) {
      toast.success('Descuento aprobado correctamente')
      refetch()
      onClose()
      closeParentModal()
    } else if (data.approveBillingDiscount.errors) {
      toast.error('Error al aprobar descuento')
    }
  }, [data])

  return (
    <Modal id='showModal' isOpen={isOpen} toggle={onClose} centered size='lg'>
      <ModalHeader className='bg-soft-info p-3' toggle={onClose}>
        Confirmar descuento "{info.discountName?.toUpperCase()}" para{' '}
        {info.productName?.toUpperCase()}
      </ModalHeader>
      <Form className='tablelist-form' onSubmit={formik.handleSubmit}>
        <ModalBody>
          <Row className='g-3'>
            <Col lg={12} sm={12}>
              <div>
                <Label htmlFor='value' className='form-label'>
                  Precio
                </Label>
                <InputCurrency
                  id='value'
                  name='value'
                  placeholder='Precio base'
                  className={`form-control ${
                    formik.touched.value && formik.errors.value
                      ? 'is-invalid'
                      : ''
                  } `}
                  prefix='$ '
                  value={formik.values.value}
                  // decimalsLimit={0}
                  // decimalSeparator=','
                  // groupSeparator='.'
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value || '')
                  }}
                  // allowNegativeValue={false}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.value && formik.errors.value && (
                  <div
                    style={{
                      width: '100%',
                      marginTop: '0.25rem',
                      fontSize: '0.875em',
                      color: '#f06548'
                    }}
                  >
                    {formik.errors.value}
                  </div>
                )}
              </div>
            </Col>
            <Col lg={12} sm={12}>
              <div>
                <Label htmlFor='name-field' className='form-label'>
                  Comentario
                </Label>

                <Input
                  name='comment'
                  id='comment-field'
                  className='form-control'
                  placeholder='Ingrese un comentario'
                  type='textarea'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.comment}
                  invalid={!!(formik.touched.comment && formik.errors.comment)}
                />
                {formik.touched.comment && formik.errors.comment ? (
                  <FormFeedback type='invalid'>
                    {formik.errors.comment}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div className='hstack gap-2 justify-content-end'>
            <Button
              color='light'
              type='button'
              onClick={() => {
                onClose()
                formik.resetForm()
              }}
            >
              Cancelar{' '}
            </Button>
            <Button
              type='submit'
              color='success'
              id='add-btn'
              className='d-flex align-items-center gap-1'
            >
              {loading && <Spinner color='light' size='sm' />}
              Aprobar
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
