import React, { useEffect, useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from 'reactstrap'
import Select from 'react-select'
import { useGetUser } from '../../hooks/user'
import { useLazyQuery, useMutation } from '@apollo/client'
import { CHANGE_AGENCIE_OR_ZONE } from '../../graphql/mutations/userMutations'
import { toast } from 'react-toastify'
import { ROLES } from '../../helpers/consts'
import { GET_AGENCIES_OR_ZONES } from '../../graphql/queries/getUsuarios'
import { useNavigate } from 'react-router-dom'

export const ModalChangeZoneOrAgen = () => {
  const [gqlGetInfoAgenOrZone, { data: dataInfo, loading: loadingInfo }] =
    useLazyQuery(GET_AGENCIES_OR_ZONES, { nextFetchPolicy: 'network-only' })
  const [
    gqlChangeZoneOrAgencie,
    { data: dataChange, loading: loadingChange, error: errorChange }
  ] = useMutation(CHANGE_AGENCIE_OR_ZONE)
  const {
    infoZoneOrAgencie,
    closeModalChangeZoneOrAgencie,
    rol,
    openModalChangeZoneOrAgencie,
    updateOptionAgencieOrZone
  } = useGetUser()

  const navigate = useNavigate()

  const [textInfo, setTextInfo] = useState('')
  const [selectOptions, setSelectOptions] = useState([])
  const [selectForm, setSelectForm] = useState(null)

  useEffect(() => {
    let idTime = 0
    if (dataChange) {
      if (dataChange.switchAgencyZone.success) {
        toast.success(`Se cambió exitosamente a: ${selectForm.name}`)
        updateOptionAgencieOrZone(`${selectForm.name}***${selectForm.id}`)
        closeModalChangeZoneOrAgencie(true)
        navigate('/dashboard')
        idTime = setTimeout(() => {
          const el = document.getElementsByTagName('body')[0]
          el.removeAttribute('style')
          // console.log('el', el)
        }, 500)
      } else {
        toast.error(`ERROR: ${dataChange.switchAgencyZone.errors.message}`)
      }
    }
    return () => {
      clearTimeout(idTime)
    }
  }, [dataChange])

  useEffect(() => {
    if (errorChange) {
      toast.error(`ERROR: ${errorChange.message}`)
    }
  }, [errorChange])

  useEffect(() => {
    if (rol) {
      gqlGetInfoAgenOrZone({ fetchPolicy: 'network-only' })
      if (
        !infoZoneOrAgencie.zoneOrAgency &&
        rol.toLocaleLowerCase() !== ROLES.ADMIN.toLocaleLowerCase() &&
        rol.toLocaleLowerCase() !== ROLES.CARTERA.toLocaleLowerCase() &&
        rol.toLocaleLowerCase() !== ROLES.CONTABILIDAD.toLocaleLowerCase() &&
        rol.toLocaleLowerCase() !== ROLES.JEFE_CREDITO.toLocaleLowerCase() &&
        rol.toLocaleLowerCase() !== ROLES.GERENTE.toLocaleLowerCase()
      ) {
        openModalChangeZoneOrAgencie()
      }
    }
  }, [rol])

  useEffect(() => {
    if (dataInfo) {
      const newData = dataInfo.listZonesAgenciesUser.data.map(
        ({ id, name }) => ({
          label: name,
          value: id
        })
      )
      setTextInfo(dataInfo.listZonesAgenciesUser.entity)
      setSelectOptions(newData)
    }
  }, [dataInfo])

  return (
    <Modal
      isOpen={infoZoneOrAgencie.showModal}
      backdrop='static'
      keyboard={false}
    >
      <ModalHeader>Cambiar de {textInfo || 'zona / agencia'}</ModalHeader>
      {loadingInfo ? (
        <>
          <ModalBody>
            <Spinner size='sm' color='info' />
          </ModalBody>
          <ModalFooter>
            <Spinner size='sm' color='light' />
          </ModalFooter>
        </>
      ) : (
        <>
          <ModalBody>
            <Select
              className='basic-single'
              classNamePrefix='select'
              isClearable
              isSearchable
              name='color'
              onChange={(e) => {
                if (e) {
                  setSelectForm({
                    id: e.value,
                    name: e.label
                  })
                } else {
                  setSelectForm(null)
                }
              }}
              options={selectOptions}
              noOptionsMessage={() => {
                return 'No se encontró rol'
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color='light'
              onClick={() => {
                if (infoZoneOrAgencie.zoneOrAgency) {
                  closeModalChangeZoneOrAgencie()
                }
              }}
            >
              Cancelar
            </Button>
            <Button
              className='d-flex align-items-center'
              color='info'
              disabled={!selectForm || loadingChange}
              onClick={() => {
                if (!selectForm) return
                gqlChangeZoneOrAgencie({
                  variables: {
                    input: {
                      id: selectForm.id
                    }
                  }
                })
                // console.log('selectForm.id', selectForm.id)
              }}
            >
              {loadingChange && <Spinner size='sm' color='light' />}
              Cambiar {textInfo || 'zona / agencia'}
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  )
}
