import { useQuery } from '@apollo/client'
import React, { Fragment, useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
  Table
} from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { GET_ASSESORS } from '../../../graphql/queries/getUsuarios'
import { toast } from 'react-toastify'
import { parsePrice } from '../../../helpers'
import { useDisclosure } from '../../../hooks/useDisclosure'
import { ModalAddBudget } from '../../../Components/Own/Users/ModalAddBudget'

export const AsesoresPage = () => {
  const { data, loading, error, refetch } = useQuery(GET_ASSESORS, {
    fetchPolicy: 'no-cache'
  })

  const { isOpen, onClose, onOpen } = useDisclosure()

  const [selectAgency, setSelectAgency] = useState('')

  const [infoBudget, setInfoBudget] = useState(null)

  useEffect(() => {
    if (error) {
      toast.error('Error: ' + error.message)
    }
  }, [error])

  if (loading || !data) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Ver asesores' pageTitle='Asesores' />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between border-0 gap-3 flex-md-column flex-lg-row flex-sm-column '>
                  <div className='w-100'>
                    <h5 className='card-title mb-0'>Asesores</h5>
                  </div>
                  <div className='w-100'>
                    <p
                      className='placeholder-glow w-100 text-end'
                      style={{ height: '30px' }}
                    >
                      <span
                        className='placeholder col-4'
                        style={{ height: '100%' }}
                      />
                    </p>
                  </div>
                </CardHeader>
                <CardBody style={{ height: '30vh' }}>
                  <div className='d-flex justify-content-center align-items-center h-100'>
                    <Spinner style={{ width: '3em', height: '3em' }} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
  /**
   * @type {{id: number, name: string, agencies: {id: number, name: string, users: {userId: number, name: string, budgetValue: null | string, budgetId: string}[]}[]}[]}
   */
  const budgets = data.getAsesors

  /** @type {{id: number, name: string, users: {userId: number, name: string, budgetValue: null | string, budgetId: string}[]}[]} */
  let allAgencies = []

  budgets.forEach(({ agencies }) => {
    allAgencies = [...allAgencies, ...agencies]
  })

  const agenciesFilter = allAgencies.filter(({ name }) => {
    if (!selectAgency) return true
    return selectAgency === name
  })

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Ver asesores' pageTitle='Asesores' />
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className='d-flex align-items-center justify-content-between border-0 gap-3 flex-md-column flex-lg-row flex-sm-column '>
                <div className='w-100'>
                  <h5 className='card-title mb-0'>Asesores</h5>
                </div>
                <div className='w-100'>
                  <select
                    className='form-select'
                    value={selectAgency}
                    onChange={(e) => setSelectAgency(e.target.value)}
                  >
                    <option value=''>Seleccione una agencia</option>
                    {allAgencies.map(({ id, name }) => (
                      <option key={id} value={name}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </CardHeader>
              <CardBody>
                <Table>
                  <thead className='bg-soft-info'>
                    <tr>
                      <th>Nombre</th>
                      {/* <th>Zona</th> */}
                      <th>Agencia</th>
                      <th>Presupuesto</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {agenciesFilter.map(({ id: idAgency, name, users }) => (
                      <Fragment key={idAgency}>
                        {users.map(
                          ({
                            budgetValue,
                            name: nameUser,
                            userId,
                            budgetId
                          }) => (
                            <tr key={userId}>
                              <td>{nameUser}</td>
                              {/* <td>Zona</td> */}
                              <td>{name}</td>
                              <td>
                                {budgetValue
                                  ? parsePrice(budgetValue)
                                  : 'No aplica'}
                              </td>
                              <td>
                                <button
                                  className='border-0 bg-transparent m-0 p-1'
                                  onClick={() => {
                                    setInfoBudget({
                                      name,
                                      nameUser,
                                      idAgency,
                                      userId,
                                      budgetValue,
                                      budgetId
                                    })
                                    onOpen()
                                  }}
                                >
                                  <i className='mdi mdi-pencil' />
                                </button>
                              </td>
                            </tr>
                          )
                        )}
                        {selectAgency !== '' && users.length === 0 && (
                          <tr>
                            <td colSpan={4}>
                              <div className='d-flex align-items-center justify-content-center py-4'>
                                No hay usuario para "{name}"
                              </div>
                            </td>
                          </tr>
                        )}
                      </Fragment>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      {infoBudget && (
        <ModalAddBudget
          infoBudget={infoBudget}
          isOpen={isOpen}
          onClose={onClose}
          refetch={refetch}
        />
      )}
    </div>
  )
}
