/* eslint-disable react/jsx-indent */
import React, { useEffect, useRef, useState } from 'react'
import {
  Container,
  Col,
  Row,
  Spinner,
  Card,
  CardBody,
  CardHeader,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Table,
  Badge,
  Form,
  FormGroup,
  Label,
  Alert
} from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  GET_CHECKS_DISBURSEMENT,
  GET_ONE_DISBURSEMENT
} from '../../../graphql/queries/getDisbursements'
import { hasPermission, parseId, parsePrice } from '../../../helpers'
import { useGetUser } from '../../../hooks/user'
import { useNavigate, useParams } from 'react-router-dom'
import {
  ALL_PERMISSIONS,
  ERROR_MESSAGES,
  STATUS_DISBURSEMENT
} from '../../../helpers/consts'
import { toast } from 'react-toastify'
import moment from 'moment'
import {
  DISBURSEMENT_FINISH,
  DISBURSEMENT_FINISH_STR
} from '../../../graphql/mutations/disbursementMutations'
import { useMutationFetch } from '../../../hooks/useMutationFetch'
import { useDisclosure } from '../../../hooks/useDisclosure'
import { ModalViewCreditInfo } from '../../../Components/Own/Disbursement/ModalViewCreditInfo'

export const OneDisbursement = () => {
  const [
    gqlFinish,
    { data: dataFinish, loading: loadingFinish, error: errorFinish }
  ] = useMutation(DISBURSEMENT_FINISH)

  const [
    finishDibursement,
    { data: dataEnd, loading: loadingEnd, error: errorEnd }
  ] = useMutationFetch()

  const [gqlGetDisbur, { data, loading, error, refetch }] = useLazyQuery(
    GET_ONE_DISBURSEMENT,
    { fetchPolicy: 'no-cache' }
  )
  const [
    gqlGetChecks,
    { data: dataChecks, loading: loadingChecks, error: errorChecks }
  ] = useLazyQuery(GET_CHECKS_DISBURSEMENT, { fetchPolicy: 'no-cache' })

  const { id } = useParams()
  const navigate = useNavigate()
  const formRef = useRef(null)

  const { permissions, logout } = useGetUser()

  const [showConfirmationEnd, setShowConfirmationEnd] = useState(false)
  const [showFinishDisbur, setShowFinishDisbur] = useState(false)

  const { isOpen, onClose, onOpen } = useDisclosure()

  /** @type {[{node: {id: string, isChecked: boolean, name: string}}[], React.Dispatch<React.SetStateAction<{node: {}}[]>>]} */
  const [checksInfo, setChecksInfo] = useState([])

  useEffect(() => {
    if (!hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_DESEMBOLSOS)) {
      navigate('/dashboard')
    }
  }, [permissions])

  useEffect(() => {
    if (!id || !Number(id)) {
      navigate('/cotizaciones')
    }
    gqlGetDisbur({
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    })
  }, [id])

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error.message}`)
      if (error.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [error])

  useEffect(() => {
    if (!data) return
    if (
      data.listDisbursementRequest?.edges &&
      data.listDisbursementRequest?.edges.length > 0
    ) {
      const { status, id: idCredit } =
        data.listDisbursementRequest?.edges[0].node
      if (
        STATUS_DISBURSEMENT[status] !== STATUS_DISBURSEMENT.CREACION_DE_CARTERA
      ) {
        gqlGetChecks({
          variables: {
            disbursementId: parseId(idCredit)
          }
        })
      }
    }
  }, [data])

  useEffect(() => {
    if (errorChecks) {
      toast.error(`Error: ${errorChecks.message}`)
      if (errorChecks.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorChecks])

  useEffect(() => {
    if (!dataChecks) return
    if (dataChecks.listCheckListItems) {
      setChecksInfo(dataChecks.listCheckListItems.edges)
    }
  }, [dataChecks])

  useEffect(() => {
    if (errorFinish) {
      toast.error(`Error: ${errorFinish.message}`)
      if (errorFinish.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorFinish])

  useEffect(() => {
    if (!dataFinish) return
    if (dataFinish.disbursementFinish?.success) {
      toast.success('Desembolso actualizado correctamente')
      refetch()
      setShowConfirmationEnd(false)
    } else if (dataFinish.disbursementFinish?.errors) {
      toast.error(
        'Error:' + dataFinish.disbursementFinish?.errors?.message ||
          'No se pudo realizar esta acción'
      )
    }
  }, [dataFinish])

  useEffect(() => {
    if (!dataEnd) return
    if (dataEnd.disbursementFinish?.success) {
      toast.success('Desembolso finalizado correctamente')
      refetch()
      setShowFinishDisbur(false)
    } else if (dataEnd.disbursementFinish?.errors) {
      toast.error(
        'Error:' + dataEnd.disbursementFinish?.errors?.message ||
          'No se pudo realizar esta acción'
      )
    }
  }, [dataEnd])

  useEffect(() => {
    if (!errorEnd) return
    toast.error('ERROR: ' + errorEnd.toString())
  }, [errorEnd])

  const toggleConfirmationEnd = () => {
    setShowConfirmationEnd(false)
  }

  const toggleshowFinishDisbur = () => {
    setShowFinishDisbur(false)
  }

  const renderStatus = (status) => {
    switch (STATUS_DISBURSEMENT[status]) {
      case STATUS_DISBURSEMENT.POR_DESEMBOLSAR:
        return (
          <span className='badge badge-soft-danger text-uppercase'>
            {status.replace(/_/g, ' ')}
          </span>
        )
      case STATUS_DISBURSEMENT.DESEMBOLSADO:
        return (
          <span className='badge badge-soft-success text-uppercase'>
            {status.replace(/_/g, ' ')}
          </span>
        )
      case STATUS_DISBURSEMENT.CREACION_DE_CARTERA:
        return (
          <span className='badge badge-soft-info text-uppercase'>
            {status.replace(/_/g, ' ')}
          </span>
        )
      case STATUS_DISBURSEMENT.PENDIENTE_DE_APROBACION:
        return (
          <span className='badge badge-soft-warning text-uppercase'>
            {status.replace(/_/g, ' ')}
          </span>
        )
      default:
        return (
          <span className='badge badge-soft-dark text-uppercase'>
            {status.replace(/_/g, ' ')}
          </span>
        )
    }
  }
  /** @param {string} status */
  const renderText = (status) => {
    switch (STATUS_DISBURSEMENT[status]) {
      case STATUS_DISBURSEMENT.CREACION_DE_CARTERA:
        return 'Si continuas, este desembolso pasará a PENDIENTE DE APROBACIÓN'
      case STATUS_DISBURSEMENT.PENDIENTE_DE_APROBACION:
        return 'Al continuar confirmarás que revisaste bien la documentación'
      case STATUS_DISBURSEMENT.POR_DESEMBOLSAR:
        return 'Si continuas, deberás adjuntar la imagen de desembolso para finalizar el proceso'
      default:
        return 'Dale en continuar'
    }
  }

  /**
   * @param {string} status
   * @param {string} id
   */
  const handleContinue = (status, id) => {
    const st = STATUS_DISBURSEMENT[status]
    if (st === STATUS_DISBURSEMENT.CREACION_DE_CARTERA) {
      gqlFinish({
        variables: {
          input: {
            disbursementId: parseId(id)
          }
        }
      })
    } else if (st === STATUS_DISBURSEMENT.PENDIENTE_DE_APROBACION) {
      if (formRef && formRef.current) {
        formRef.current?.dispatchEvent(
          new Event('submit', { cancelable: true, bubbles: true })
        )
      }
    } else if (st === STATUS_DISBURSEMENT.POR_DESEMBOLSAR) {
      setShowConfirmationEnd(false)
      setShowFinishDisbur(true)
    }
  }

  const canContinueWithPermission = (status) => {
    const st = STATUS_DISBURSEMENT[status]

    if (st === STATUS_DISBURSEMENT.CREACION_DE_CARTERA) {
      return hasPermission(
        permissions,
        ALL_PERMISSIONS.DESEMBOLSO_PASAR_A_PENDIENTE
      )
    }
    if (st === STATUS_DISBURSEMENT.PENDIENTE_DE_APROBACION) {
      return hasPermission(
        permissions,
        ALL_PERMISSIONS.DESEMBOLSO_PASAR_A_POR_DESEMBOLSAR
      )
    }
    if (st === STATUS_DISBURSEMENT.POR_DESEMBOLSAR) {
      return hasPermission(
        permissions,
        ALL_PERMISSIONS.DESEMBOLSO_PASAR_A_DESEMBOLSADO
      )
    }
    return false
  }

  document.title = 'Desembolso | TOTAL SALES'

  if (loading) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            back='/facturaciones'
            title='Resumen facturación'
            pageTitle='Facturaciones'
          />
          <Card>
            <CardHeader>
              <Row>
                <Col lg={2} md={4} sm={12} xs={12}>
                  <div>
                    <h5 className='card-title placeholder-glow'>
                      <span className='placeholder col-6' />
                    </h5>
                    <p>Cierre de crédito</p>
                  </div>
                </Col>
                <Col lg={2} md={4} sm={12} xs={12}>
                  <div>
                    <h5 className='card-title placeholder-glow'>
                      <span className='placeholder col-6' />
                    </h5>
                    <p>Primera cuota</p>
                  </div>
                </Col>
                <Col lg={2} md={4} sm={12} xs={12}>
                  <div>
                    <h5 className='card-title placeholder-glow'>
                      <span className='placeholder col-6' />
                    </h5>
                    <p>Valor a financiar</p>
                  </div>
                </Col>
                <Col lg={2} md={4} sm={12} xs={12}>
                  <div>
                    <h5 className='card-title placeholder-glow'>
                      <span className='placeholder col-6' />
                    </h5>
                    <p>Plazo</p>
                  </div>
                </Col>
                <Col lg={2} md={4} sm={12} xs={12}>
                  <div>
                    <h5 className='card-title placeholder-glow'>
                      <span className='placeholder col-6' />
                    </h5>
                    <p>Valor cuota</p>
                  </div>
                </Col>
                <Col lg={2} md={4} sm={12} xs={12}>
                  <div>
                    <h5 className='card-title placeholder-glow'>
                      <span className='placeholder col-6' />
                    </h5>
                    <p>Provisión</p>
                  </div>
                </Col>
              </Row>
            </CardHeader>
          </Card>
          <Row>
            <Col lg={3}>
              <Card
                className='d-flex align-items-center justify-content-center'
                style={{ height: '40vh' }}
              >
                <Spinner
                  style={{
                    width: '1.5em',
                    height: '1.5em'
                  }}
                  color='dark'
                />
              </Card>
            </Col>
            <Col lg={9}>
              <Card
                className='d-flex align-items-center justify-content-center'
                style={{ height: '40vh' }}
              >
                <Spinner
                  style={{
                    width: '2.5em',
                    height: '2.5em'
                  }}
                  color='dark'
                />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  if (!data) return null

  /** @type {{id: string, status: string, disbursementDate: string | null, disbursementNote: string | null, created: string, credit: {id: string, firstName: string, secondName: string, firstSurname: string, secondSurname: string, documentType: string, documentNumber: string, dateBirth: string, expeditionDate: string ,closedDate: string | null, paymentDate: string |null ,user: {firstName: string, lastName: string}, agency: {id: string, name: string, city: string, zone: {name: string, commercialCompany: {name:string}}}, closedDate: string | null, productValue: string | null, term: number | null, quotaValue: null | number, initialFee:  string | null}}} */
  const disbursement = data.listDisbursementRequest.edges[0]?.node

  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='Solicitud de desembolso'
            pageTitle='Desembolso'
            back='/desembolsos'
          />
          <Card>
            <CardHeader>
              <Row>
                <Col lg={2} md={4} sm={12} xs={12}>
                  <div>
                    <h6>
                      {disbursement.credit.closedDate
                        ? moment(disbursement.credit.closedDate).format(
                            'DD MMM [de] YY'
                          )
                        : 'N/A'}
                    </h6>
                    <p>Cierre de crédito</p>
                  </div>
                </Col>
                <Col lg={2} md={4} sm={12} xs={12}>
                  <div>
                    <h6>
                      {disbursement.credit.paymentDate
                        ? moment(disbursement.credit.paymentDate).format(
                            'DD MMM [de] YY'
                          )
                        : 'N/A'}
                    </h6>
                    <p>Primera cuota</p>
                  </div>
                </Col>
                <Col lg={2} md={4} sm={12} xs={12}>
                  <div>
                    <h6>
                      {disbursement.credit.productValue
                        ? parsePrice(
                            Number(disbursement.credit.productValue) -
                              Number(disbursement.credit.initialFee || 0)
                          )
                        : 'N/A'}
                    </h6>
                    <p>Valor a financiar</p>
                  </div>
                </Col>
                <Col lg={2} md={4} sm={12} xs={12}>
                  <div>
                    <h6>
                      {disbursement.credit.term
                        ? disbursement.credit.term + ' meses'
                        : 'N/A'}
                    </h6>
                    <p>Plazo</p>
                  </div>
                </Col>
                <Col lg={2} md={4} sm={12} xs={12}>
                  <div>
                    <h6>
                      {disbursement.credit.quotaValue
                        ? parsePrice(disbursement.credit.quotaValue)
                        : 'N/A'}
                    </h6>
                    <p>Valor cuota</p>
                  </div>
                </Col>
                <Col lg={2} md={4} sm={12} xs={12}>
                  <div>
                    <h6>
                      {disbursement.credit.quotaValue &&
                      disbursement.credit.term
                        ? parsePrice(
                            Number(disbursement.credit.quotaValue) *
                              Number(disbursement.credit.term) -
                              (Number(disbursement.credit.productValue) -
                                Number(disbursement.credit.initialFee || 0))
                          )
                        : 'N/A'}
                    </h6>
                    <p>Provisión</p>
                  </div>
                </Col>
              </Row>
            </CardHeader>
          </Card>
          <Row>
            <Col lg={3}>
              {STATUS_DISBURSEMENT[disbursement.status] ===
                STATUS_DISBURSEMENT.DESEMBOLSADO &&
                disbursement.disbursementDate &&
                disbursement.disbursementNote && (
                  <Card className='mb-3'>
                    <CardHeader className='fw-semibold bg-soft-info'>
                      Información de desembolso
                    </CardHeader>
                    <CardBody>
                      <div className='table-card table-responsive'>
                        <table className='table mb-0'>
                          <tbody>
                            <tr>
                              <td className='fw-medium'>Fecha de desembolso</td>
                              <td>
                                {moment(disbursement.disbursementDate).format(
                                  'DD MMM [del] YYYY - hh:mm A'
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td className='fw-medium'>Soporte</td>
                              <td>
                                <a
                                  href={disbursement.disbursementNote}
                                  rel='noreferrer'
                                  target='_blank'
                                  className='btn btn-soft-info'
                                >
                                  Descargar
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                )}
              <Card className='mb-3'>
                <CardHeader className='fw-semibold'>
                  Información del crédito
                </CardHeader>
                <CardBody>
                  <div className='table-card table-responsive'>
                    <table className='table mb-0'>
                      <tbody>
                        <tr>
                          <td className='fw-medium'>Cuidad</td>
                          <td>{disbursement.credit?.agency?.city || 'N/A'}</td>
                        </tr>
                        <tr>
                          <td className='fw-medium'>Zona</td>
                          <td>
                            {disbursement.credit?.agency?.zone?.name || 'N/A'}
                          </td>
                        </tr>
                        <tr>
                          <td className='fw-medium'>Agencia</td>
                          <td>{disbursement.credit?.agency?.name || 'N/A'}</td>
                        </tr>
                        <tr>
                          <td className='fw-medium'>Empresa comercial</td>
                          <td>
                            {disbursement.credit?.agency?.zone
                              ?.commercialCompany?.name || 'N/A'}
                          </td>
                        </tr>
                        <tr>
                          <td className='fw-medium'>Asesor(a)</td>
                          <td>
                            {disbursement.credit?.user?.firstName || 'N/A'}{' '}
                            {disbursement.credit?.user?.lastName || 'N/A'}
                          </td>
                        </tr>
                        {/* <tr>
                          <td className='fw-medium'>Estado</td>
                          <td>{renderStatus(disbursement.status)}</td>
                        </tr> */}
                        {disbursement.credit && (
                          <tr>
                            <td className='fw-medium text-center' colSpan={2}>
                              <Button onClick={onOpen}>
                                <i className='mdi mdi-eye' /> Ver crédito
                              </Button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={9}>
              <Card>
                <CardBody>
                  <div className='text-muted'>
                    <h6 className='mb-3 fw-semibold text-uppercase'>
                      Información personal - Deudor
                    </h6>
                    <div className='table-card mb-4 p-2'>
                      <table className='table mb-0'>
                        <tbody>
                          <tr>
                            <td className='fw-medium'>Nombre(s)</td>
                            <td>
                              {disbursement.credit.firstName}{' '}
                              {disbursement.credit.secondName}
                            </td>
                          </tr>
                          <tr>
                            <td className='fw-medium'>Apellido(s)</td>
                            <td>
                              {disbursement.credit.firstSurname}{' '}
                              {disbursement.credit.secondSurname}
                            </td>
                          </tr>
                          <tr>
                            <td className='fw-medium'>Identificación</td>
                            <td>
                              {disbursement.credit.documentType}{' '}
                              {disbursement.credit.documentNumber}
                            </td>
                          </tr>
                          <tr>
                            <td className='fw-medium'>Fecha de nacimiento</td>
                            <td>
                              {moment(disbursement.credit.dateBirth).format(
                                'DD MMM [de] YYYY'
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className='fw-medium'>Fecha de expedición</td>
                            <td>
                              {moment(
                                disbursement.credit.expeditionDate
                              ).format('DD MMM [de] YYYY')}
                            </td>
                          </tr>
                          <tr>
                            <td className='fw-medium'>Teléfono</td>
                            <td>{disbursement.credit.phoneNumber}</td>
                          </tr>
                          <tr>
                            <td className='fw-medium'>Correo electrónico</td>
                            <td>{disbursement.credit.email}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='pt-3 mb-4 d-flex align-items-center justify-content-between'>
                      <div>
                        <h6 className='fw-semibold text-uppercase mb-0'>
                          Estado
                        </h6>
                        {renderStatus(disbursement.status)}
                      </div>
                      {STATUS_DISBURSEMENT[disbursement.status] !==
                        STATUS_DISBURSEMENT.DESEMBOLSADO && (
                        <div>
                          <Button
                            color='danger'
                            className='d-flex align-items-center gap-1'
                            onClick={() => {
                              setShowConfirmationEnd(true)
                            }}
                          >
                            <i className='mdi mdi-cash-lock fs-4' />
                            Terminar
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>

              {STATUS_DISBURSEMENT[disbursement.status] ===
                STATUS_DISBURSEMENT.PENDIENTE_DE_APROBACION && (
                <Card>
                  {loadingChecks ? (
                    <CardBody
                      className='d-flex justify-content-center align-items-center'
                      style={{ height: '20vh' }}
                    >
                      <Spinner
                        color='dark'
                        style={{ height: '2.5em', width: '2.5em' }}
                      />
                    </CardBody>
                  ) : (
                    <>
                      <CardHeader className='bg-soft-info'>
                        <Row>
                          <Col lg={6} className='text-center fw-bold'>
                            Ítem
                          </Col>
                          <Col lg={6} className='text-center fw-bold'>
                            ¿Es correcto?
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <form
                          ref={formRef}
                          onSubmit={(e) => {
                            e.preventDefault()
                            const values = Object.fromEntries(
                              new FormData(e.target)
                            )
                            console.log('values -->', values)
                            if (!values) return
                            const arr = Object.keys(values)
                            const checkList = []
                            arr.forEach((key) => {
                              checkList.push({
                                id: Number(key),
                                isChecked: Boolean(values[key])
                              })
                            })
                            console.log('checkList', checkList)
                            gqlFinish({
                              variables: {
                                input: {
                                  disbursementId: parseId(disbursement.id),
                                  checkList
                                }
                              }
                            })
                          }}
                        >
                          <Table responsive striped>
                            <tbody className='fs-12'>
                              {checksInfo.map(({ node: nodeChecks }) => (
                                <tr key={nodeChecks.id}>
                                  <th>{nodeChecks.name}</th>
                                  <td>
                                    <Input
                                      name={parseId(nodeChecks.id) + ''}
                                      type='select'
                                      required
                                      defaultValue={
                                        nodeChecks.isChecked ? 'SI' : ''
                                      }
                                      /* valid={Boolean(nodeChecks.isChecked)}
                                      invalid={Boolean(!nodeChecks.isChecked)} */
                                    >
                                      <option value='SI'>Si</option>
                                      <option value=''>NO</option>
                                    </Input>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </form>
                      </CardBody>
                    </>
                  )}
                </Card>
              )}

              {(STATUS_DISBURSEMENT[disbursement.status] ===
                STATUS_DISBURSEMENT.POR_DESEMBOLSAR ||
                STATUS_DISBURSEMENT[disbursement.status] ===
                  STATUS_DISBURSEMENT.DESEMBOLSADO) && (
                <Card>
                  {loadingChecks ? (
                    <CardBody
                      className='d-flex justify-content-center align-items-center'
                      style={{ height: '20vh' }}
                    >
                      <Spinner
                        color='dark'
                        style={{ height: '2.5em', width: '2.5em' }}
                      />
                    </CardBody>
                  ) : (
                    <>
                      <CardHeader className='bg-soft-info'>
                        <Row>
                          <Col lg={6} className='text-center fw-bold'>
                            Ítem
                          </Col>
                          <Col lg={6} className='text-center fw-bold'>
                            ¿Es correcto?
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Table responsive striped>
                          <tbody className='fs-12'>
                            {checksInfo.map(({ node: nodeChecks }) => (
                              <tr key={nodeChecks.id}>
                                <th>{nodeChecks.name}</th>
                                <td>
                                  {nodeChecks.isChecked ? (
                                    <Badge color='success' className='fs-6'>
                                      <i className='mdi mdi-check-circle-outline me-1' />
                                      SI
                                    </Badge>
                                  ) : (
                                    <Badge color='danger' className='fs-6'>
                                      <i className='mdi mdi-close-circle-outline me-1' />
                                      NO
                                    </Badge>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </CardBody>
                    </>
                  )}
                </Card>
              )}
            </Col>
          </Row>
        </Container>
        <Modal isOpen={showConfirmationEnd} toggle={toggleConfirmationEnd}>
          <ModalHeader
            className='p-4 bg-soft-info'
            toggle={toggleConfirmationEnd}
          >
            ¿Seguro quiere terminar este desembolso?
          </ModalHeader>
          <ModalBody>
            <p>{renderText(disbursement.status)}</p>
            {!canContinueWithPermission(disbursement.status) && (
              <Alert color='warning'>
                <i className='mdi mdi-alert' /> No tienes permiso para realizar
                esta acción
              </Alert>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color='light' onClick={() => toggleConfirmationEnd()}>
              Cancelar
            </Button>
            {canContinueWithPermission(disbursement.status) ? (
              <Button
                color='success'
                onClick={() => {
                  handleContinue(disbursement.status, disbursement.id)
                }}
                disabled={loadingFinish}
                className='d-flex align-items-center gap-1'
              >
                {loadingFinish && <Spinner size='sm' color='light' />}
                Continuar
              </Button>
            ) : (
              <Button disabled color='success' title='No puedes continuar'>
                Continuar
              </Button>
            )}
          </ModalFooter>
        </Modal>

        <Modal isOpen={showFinishDisbur} toggle={toggleshowFinishDisbur}>
          <ModalHeader
            toggle={toggleshowFinishDisbur}
            className='p-4 bg-soft-danger'
          >
            Finalizar desembolso
          </ModalHeader>
          <Form
            onSubmit={(e) => {
              e.preventDefault()
              const { file } = Object.fromEntries(new FormData(e.target))
              console.log('values', file)
              if (!file) return

              const formdata = new FormData()
              const map = {}
              formdata.append('0', file, file.name)
              map['0'] = ['variables.input.file']
              const obj = {
                query: DISBURSEMENT_FINISH_STR,
                variables: {
                  input: {
                    file: null,
                    disbursementId: parseId(disbursement.id)
                  }
                }
              }
              formdata.append('map', JSON.stringify(map))
              formdata.append('operations', JSON.stringify(obj))

              finishDibursement(formdata)
            }}
          >
            <ModalBody>
              <FormGroup>
                <Label>Sube un archivo</Label>
                <Input
                  type='file'
                  required
                  name='file'
                  placeholder='Sube un archivo'
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                color='light'
                type='button'
                onClick={() => toggleshowFinishDisbur()}
              >
                Cancelar
              </Button>
              <Button
                color='danger'
                type='submit'
                disabled={loadingEnd}
                className='d-flex align-items-center gap-1'
              >
                {loadingEnd && <Spinner size='sm' color='light' />}
                Finalizar
              </Button>
            </ModalFooter>
          </Form>
        </Modal>

        <ModalViewCreditInfo
          isOpen={isOpen}
          onClose={onClose}
          idCredit={disbursement.credit.id}
        />
      </div>
    </>
  )
}
