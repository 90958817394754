import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'

const BreadCrumb = ({
  title,
  pageTitle,
  back = '#',
  className = '',
  returnPage
}) => {
  return (
    <>
      <Row className={className}>
        <Col xs={12}>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center gap-2'>
              {returnPage && (
                <>
                  <Link
                    className='d-inline-block'
                    to={returnPage.to}
                    id='id-back'
                  >
                    <i className='mdi mdi-arrow-left-thin fs-2' />
                  </Link>
                  <UncontrolledTooltip
                    placement='top'
                    target='id-back'
                    trigger='hover'
                  >
                    Volver
                  </UncontrolledTooltip>
                </>
              )}
              <h4 className='mb-sm-0'>{title}</h4>
            </div>

            <div className='page-title-right'>
              <ol className='breadcrumb m-0'>
                <li className='breadcrumb-item'>
                  <Link to={back}>{pageTitle}</Link>
                </li>
                <li className='breadcrumb-item active'>{title}</li>
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default BreadCrumb
