import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import {
  FormGroup,
  Label,
  Input,
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Collapse,
  Spinner
} from 'reactstrap'
import * as Yup from 'yup'
import { useMutation } from '@apollo/client'
import { CHANGE_STATUS_CERTIFICATE } from '../../../graphql/mutations/certificationMutations'
import { toast } from 'react-toastify'
import { parseId } from '../../../helpers'

const STATUS = {
  SIN_REVISAR: 'SIN_REVISAR',
  AUTORIZADO: 'AUTORIZADO',
  RECHAZADO: 'RECHAZADO'
}

const validationSchema = Yup.object().shape({
  status: Yup.string()
    .oneOf(
      ['SIN_REVISAR', 'AUTORIZADO', 'RECHAZADO'],
      'Debe ser uno de estos estados'
    )
    .required('Estado requerido'),
  comment: Yup.string().when('status', {
    is: STATUS.RECHAZADO,
    then: (schema) => schema.required('campo requerido')
  }),
  obligationNumber: Yup.string().when('status', {
    is: STATUS.AUTORIZADO,
    then: (schema) => schema.required('campo requerido')
  }),
  organizationName: Yup.string().when('status', {
    is: STATUS.AUTORIZADO,
    then: (schema) => schema.required('campo requerido')
  }),
  organizationNit: Yup.string().when('status', {
    is: STATUS.AUTORIZADO,
    then: (schema) => schema.required('campo requerido')
  })
})

const initialValues = {
  status: '',
  comment: '',
  obligationNumber: '',
  organizationName: '',
  organizationNit: ''
}

export const ModalStatusCertificate = ({
  isOpen,
  toggle,
  refetch,
  creditId
}) => {
  const [
    gqlChangeStatus,
    { data: dataCreate, loading: loadingCreate, error: errorCreate }
  ] = useMutation(CHANGE_STATUS_CERTIFICATE)

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const input = { ...values }
      if (values.status === STATUS.RECHAZADO) {
        delete input.obligationNumber
        delete input.organizationName
        delete input.organizationNit
      }
      if (values.status === STATUS.AUTORIZADO) {
        delete input.comment
      }
      input.id = parseId(creditId)
      gqlChangeStatus({
        variables: {
          input
        }
      })
    }
  })

  useEffect(() => {
    if (!errorCreate) return
    toast.error('Error: ' + errorCreate.message)
  }, [errorCreate])
  useEffect(() => {
    if (!dataCreate) return
    if (dataCreate.complianceCertificateChangeStatus.success) {
      toast.success('Cambio de estado completado correctamente')
      formik.resetForm()
      toggle()
      refetch()
    } else if (dataCreate.complianceCertificateChangeStatus.errors) {
      toast.error(
        'Error: ' +
          dataCreate.complianceCertificateChangeStatus.errors.message ||
          'No se pudo procesar solicitud'
      )
    }
  }, [dataCreate])

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} className='p-4 bg-soft-info'>
        Camnbiar estado
      </ModalHeader>
      <Form onSubmit={formik.handleSubmit}>
        <ModalBody>
          <FormGroup>
            <Label>Estado</Label>
            <Input
              placeholder='Estado'
              invalid={Boolean(formik.errors.status && formik.touched.status)}
              onBlur={formik.handleBlur}
              type='select'
              name='status'
              value={formik.values.status}
              onChange={formik.handleChange}
            >
              <option value=''>Seleccione el estado</option>
              <option value={STATUS.RECHAZADO}>Rechazado</option>
              <option value={STATUS.AUTORIZADO}>Autorizado</option>
            </Input>
            {formik.errors.status && formik.touched.status && (
              <FormFeedback>{formik.errors.status}</FormFeedback>
            )}
          </FormGroup>
          <Collapse isOpen={formik.values.status === STATUS.RECHAZADO}>
            <FormGroup>
              <Label>Comentario</Label>
              <Input
                placeholder='Escriba razón del rechazo'
                invalid={Boolean(
                  formik.errors.comment && formik.touched.comment
                )}
                onBlur={formik.handleBlur}
                type='textarea'
                name='comment'
                value={formik.values.comment}
                onChange={formik.handleChange}
              />
              {formik.errors.comment && formik.touched.comment && (
                <FormFeedback>{formik.errors.comment}</FormFeedback>
              )}
            </FormGroup>
          </Collapse>
          <Collapse isOpen={formik.values.status === STATUS.AUTORIZADO}>
            <FormGroup>
              <Label>No. de obligación</Label>
              <Input
                placeholder='Número de obligación'
                invalid={Boolean(
                  formik.errors.obligationNumber &&
                    formik.touched.obligationNumber
                )}
                onBlur={formik.handleBlur}
                type='text'
                name='obligationNumber'
                value={formik.values.obligationNumber}
                onChange={formik.handleChange}
              />
              {formik.errors.obligationNumber &&
                formik.touched.obligationNumber && (
                  <FormFeedback>{formik.errors.obligationNumber}</FormFeedback>
                )}
            </FormGroup>
            <FormGroup>
              <Label>Nombre de la organización</Label>
              <Input
                placeholder='Nombre de organización'
                invalid={Boolean(
                  formik.errors.organizationName &&
                    formik.touched.organizationName
                )}
                onBlur={formik.handleBlur}
                type='text'
                name='organizationName'
                value={formik.values.organizationName}
                onChange={formik.handleChange}
              />
              {formik.errors.organizationName &&
                formik.touched.organizationName && (
                  <FormFeedback>{formik.errors.organizationName}</FormFeedback>
                )}
            </FormGroup>
            <FormGroup>
              <Label>NIT</Label>
              <Input
                placeholder='Escriba el NIT'
                invalid={Boolean(
                  formik.errors.organizationNit &&
                    formik.touched.organizationNit
                )}
                onBlur={formik.handleBlur}
                type='text'
                name='organizationNit'
                value={formik.values.organizationNit}
                onChange={formik.handleChange}
              />
              {formik.errors.organizationNit &&
                formik.touched.organizationNit && (
                  <FormFeedback>{formik.errors.organizationNit}</FormFeedback>
                )}
            </FormGroup>
          </Collapse>
        </ModalBody>
        <ModalFooter>
          <Button
            color='light'
            type='button'
            onClick={() => {
              formik.resetForm()
              toggle()
            }}
          >
            Cancelar
          </Button>
          <Button
            type='submit'
            disabled={loadingCreate}
            className='d-flex align-items-center gap-1'
          >
            {loadingCreate && <Spinner color='light' size='sm' />}
            Cambiar estado
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
