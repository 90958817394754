import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Collapse,
  /* DropdownItem,
  DropdownMenu,
  DropdownToggle, */
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from 'reactstrap'
import { useMutationFetch } from '../../../hooks/useMutationFetch'
import { toast } from 'react-toastify'
import { MAX_SIZE, TableCommentCredit } from '../TableCommentCredit'
import { CREATE_COMMENT_CREDIT_WALLET_STR } from '../../../graphql/mutations/creditWalletMutations'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
const BASE_SUB_URL = '/cartera_credito/'
export const ModalAddComment = ({
  refetchDataCredit,
  toggle,
  showComment,
  creditId
}) => {
  const [
    createComment,
    {
      data: dataCreateComment,
      loading: loadingCreateComment,
      error: errorCreateComment
    }
  ] = useMutationFetch()
  /** @type {[File[], React.Dispatch<React.SetStateAction<File[]>>]} */
  const [filesComment, setFilesComment] = useState([])

  const formik = useFormik({
    initialValues: {
      comment: '',
      description: '',
      eventDate: moment().format('YYYY-MM-DDTHH:mm'),
      isChecked: false
    },
    validationSchema: Yup.object({
      comment: Yup.string().required('Comentario requerido'),
      description: Yup.string().when('isChecked', {
        is: true,
        then: Yup.string().required('Comentario requerido')
      }),
      isChecked: Yup.boolean(),
      eventDate: Yup.date().when('isChecked', {
        is: true,
        then: Yup.date()
          .min(new Date(), 'No puedes seleccionar fechas pasadas')
          .required('Fecha requerida')
      })
    }),
    onSubmit: (values) => {
      const { comment, description, eventDate, isChecked } = values
      const formdata = new FormData()
      const map = {}
      filesComment.forEach((file, idx) => {
        formdata.append(`${idx}`, file, file.name)
        map[`${idx}`] = [`variables.input.files.${idx}`]
      })

      const eventSchedule = {
        eventDate: moment(eventDate).toISOString(),
        description,
        url: BASE_SUB_URL + creditId
      }
      console.log('eventSchedule', eventSchedule)
      const input = {
        files: filesComment.map(() => null),
        creditId,
        comment,
        eventSchedule
      }
      if (!isChecked) {
        delete input.eventSchedule
      }
      const obj = {
        query: CREATE_COMMENT_CREDIT_WALLET_STR,
        variables: {
          input
        }
      }
      formdata.append('map', JSON.stringify(map))
      formdata.append('operations', JSON.stringify(obj))
      // if (values) return
      createComment(formdata)
    }
  })

  useEffect(() => {
    if (!dataCreateComment) return
    if (dataCreateComment?.walletCommentCreate?.success) {
      toast.success('Comentario creado con éxito')
      refetchDataCredit()
      setFilesComment([])
      formik.resetForm()
      toggle()
    } else if (dataCreateComment?.walletCommentCreate?.errors) {
      toast.error(
        'Error: ' + dataCreateComment?.walletCommentCreate?.errors.message ||
          'No se pudo crear comentario'
      )
    }
  }, [dataCreateComment])

  useEffect(() => {
    if (!errorCreateComment) return
    if (errorCreateComment) {
      toast.error(`${errorCreateComment.toString()}`)
    }
  }, [errorCreateComment])

  useEffect(() => {
    if (!formik.values.isChecked) {
      formik.setFieldValue('description', '')
      formik.setFieldValue('eventDate', moment().format('YYYY-MM-DDTHH:mm'))
    }
  }, [formik.values.isChecked])

  const deleteFileComment = (nameFile) => {
    setFilesComment((p) => {
      return p.filter(({ name }) => name !== nameFile)
    })
  }
  const isOneBigger = () => {
    const find = filesComment.find(({ size }) => size > MAX_SIZE)
    if (find) {
      return true
    }
    return false
  }
  return (
    <Modal isOpen={showComment} toggle={toggle}>
      <ModalHeader toggle={toggle} className='p-4 bg-soft-info'>
        Agregar comentario
      </ModalHeader>
      <Form id='form-comment-add-credit' onSubmit={formik.handleSubmit}>
        <ModalBody>
          <FormGroup>
            <Label htmlFor='textarea45'>Comentario</Label>
            <Input
              type='textarea'
              name='comment'
              id='textarea45'
              value={formik.values.comment}
              onChange={formik.handleChange}
              invalid={Boolean(formik.errors.comment && formik.touched.comment)}
              onBlur={formik.handleBlur}
            />
            {Boolean(formik.errors.comment && formik.touched.comment) && (
              <FormFeedback>{formik.errors.comment}</FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <Label>Archivo(s)</Label>
            <Input
              type='file'
              onChange={(e) => {
                const { target } = e
                const fileAdd = target.files[0]
                setFilesComment((p) => {
                  const files = [...p]
                  const find = files.find((file) => file.name === fileAdd.name)
                  if (find) {
                    return files
                  }
                  files.unshift(fileAdd)
                  return files
                })
                target.value = null
              }}
            />
          </FormGroup>
          <div className='form-check form-switch form-switch-lg my-3'>
            <Input
              type='checkbox'
              className='form-check-input'
              id='field-check'
              name='isChecked'
              onChange={(e) => {
                formik.setFieldValue('isChecked', e.target.checked)
              }}
              checked={formik.values.isChecked}
            />
            <Label className='form-check-label' htmlFor='field-check'>
              ¿Agendar? -{' '}
              <small className='text-muted'>
                {formik.values.isChecked ? '(SI)' : '(NO)'}
              </small>
            </Label>
          </div>
          <Collapse isOpen={formik.values.isChecked}>
            <FormGroup>
              <Label>Descripción</Label>
              <Input
                type='text'
                name='description'
                value={formik.values.description}
                onChange={formik.handleChange}
                invalid={Boolean(
                  formik.errors.description && formik.touched.description
                )}
                onBlur={formik.handleBlur}
              />
              {Boolean(
                formik.errors.description && formik.touched.description
              ) && <FormFeedback>{formik.errors.description}</FormFeedback>}
            </FormGroup>
            <FormGroup>
              <Label>Fecha de recordatorio</Label>
              <Input
                type='datetime-local'
                name='eventDate'
                value={formik.values.eventDate}
                onChange={(e) => {
                  console.log('e.target.value', e.target.value)
                  formik.handleChange(e)
                }}
                invalid={Boolean(
                  formik.errors.eventDate && formik.touched.eventDate
                )}
                onBlur={formik.handleBlur}
              />
              {Boolean(formik.errors.eventDate && formik.touched.eventDate) && (
                <FormFeedback>{formik.errors.eventDate}</FormFeedback>
              )}
            </FormGroup>
          </Collapse>
          {filesComment.length > 0 ? (
            <TableCommentCredit
              files={filesComment}
              setDeleteFile={deleteFileComment}
            />
          ) : (
            <Alert color='info'>
              <i className='mdi mdi-alert-circle-outline' /> No hay soportes
              adjuntados
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color='light'
            type='button'
            onClick={() => {
              toggle()
              setFilesComment([])
              formik.resetForm()
            }}
          >
            Cancelar
          </Button>
          <Button
            type='submit'
            disabled={loadingCreateComment || isOneBigger()}
            className='d-flex align-items-center gap-1'
          >
            {loadingCreateComment && <Spinner color='light' size='sm' />}
            Comentar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
