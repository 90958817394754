import { gql } from '@apollo/client'

export const ALL_CREDITS_WALLET = gql`
  query walletCredits(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id: Float
    $agency_Id: Float
    $created_Gte: DateTime
    $created_Lte: DateTime
    $uuid: UUID
    $name: String
    $documentNumber: String
  ) {
    walletCredits(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      id: $id
      agency_Id: $agency_Id
      created_Gte: $created_Gte
      created_Lte: $created_Lte
      uuid: $uuid
      name: $name
      documentNumber: $documentNumber
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          firstName
          firstSurname
          created
          agency {
            id
            name
          }
          productinformationSet {
            edges {
              node {
                id
                name
              }
            }
          }
          lastComment {
            id
            comment
            created
            creditsupportwalletSet {
              edges {
                node {
                  id
                }
              }
            }
            user {
              id
              firstName
              lastName
            }
          }
          cosignerSet {
            edges {
              node {
                id
                firstName
              }
            }
          }
        }
      }
    }
  }
`
export const GET_USER_CREDIT_WALLET = gql`
  query walletCredits($id: Float) {
    walletCredits(id: $id) {
      edges {
        node {
          id
          firstName
          firstSurname
          secondName
          secondSurname
          documentNumber
          documentType
          expeditionDate
          dateBirth
          email
          phoneNumber
          firmaAutorizacion
          spouse {
            id
          }
          cosignerSet {
            edges {
              node {
                id
              }
            }
          }
          referenceSet {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`
export const GET_COSINGERS_CREDIT_WALLET = gql`
  query walletCredits($id: Float) {
    walletCredits(id: $id) {
      edges {
        node {
          firstName
          firstSurname
          cosignerSet {
            edges {
              node {
                id
                documentNumber
                documentType
                expeditionDate
                firstName
                secondName
                secondSurname
                dateBirth
                email
                phoneNumber
                formData
              }
            }
          }
        }
      }
    }
  }
`
export const GET_ONE_CREDIT_WALLET = gql`
  query walletCredits($id: Float) {
    walletCredits(id: $id) {
      edges {
        node {
          id
          firstName
          firstSurname
          secondName
          secondSurname
          documentNumber
          documentType
          expeditionDate
          dateBirth
          email
          phoneNumber
          closedDate
          creditType
          initialFee
          term
          quotaValue
          productValue
          firmaAutorizacion
          status
          user {
            id
            firstName
            lastName
          }
          analyst {
            id
            firstName
            lastName
          }
          cupoSeleccionado {
            value
            term
            cuota
          }
          quotation {
            id
          }
          agency {
            id
            name
            zone {
              commercialCompany {
                image
                name
              }
            }
          }
          productinformationSet {
            edges {
              node {
                id
                name
              }
            }
          }
          lastComment {
            id
            comment
            created
            user {
              id
              firstName
              lastName
            }
          }
          cosignerSet {
            edges {
              node {
                id
                firstName
              }
            }
          }
          creditcommentwalletSet {
            edges {
              node {
                id
                comment
                created
                creditsupportwalletSet {
                  edges {
                    node {
                      id
                      name
                      file
                    }
                  }
                }
                user {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
          obligationSet {
            edges {
              node {
                obligationdiscountSet {
                  edges {
                    node {
                      created
                      id
                      value
                      description
                    }
                  }
                }
                id
                value
                term
                monthlyFee
                endorsement
                tea
                initialDate
                endDate
                status
                actve
                quotaSet {
                  edges {
                    node {
                      id
                      totalQuota
                      capital
                      interest
                      penaltyInterest
                      status
                      initialDate
                      endDate
                      effective
                      paymentDate
                      paymentManagement
                      endorsement {
                        id
                        endorsement
                      }
                      payments {
                        edges {
                          node {
                            id
                            quotapaymentdetailSet {
                              edges {
                                node {
                                  id
                                  capital
                                  interest
                                  penaltyInterest
                                  paymentManagement
                                  payment {
                                    created
                                    value
                                  }
                                  quota {
                                    id
                                  }
                                }
                              }
                            }
                            endorsementpaymentdetailSet {
                              edges {
                                node {
                                  value
                                  endorsement {
                                    endorsement
                                    quota {
                                      id
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_PAYMENTS_OBLIGATION_WALLET = gql`
  query listObligationPayment($obligationId: Int!) {
    listObligationPayment(obligationId: $obligationId) {
      fechaPago
      valorPago
      cuotas {
        id
        endDate
        detallePagoCuota {
          capital
          interest
          penaltyInterest
        }
        detallePagoAval {
          value
        }
      }
    }
  }
`

export const GET_RESUME_OBLIGATION = gql`
  query obligationResume($obligationId: Int!) {
    obligationResume(obligationId: $obligationId) {
      capital
      endorsement
      interest
      penaltyInterest
      paymentManagement
      total
    }
  }
`

export const GET_PAYMENTS_CAPITAL_OBL = gql`
  query listPaymentCapital(
    $obligationId: Int!
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    listPaymentCapital(
      obligationId: $obligationId
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      edges {
        node {
          id
          created
          value
          obligation {
            id
          }
        }
      }
    }
  }
`
