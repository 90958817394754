/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  Alert,
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  // Progress,
  Row,
  Spinner,
  TabContent,
  Table,
  TabPane,
  UncontrolledTooltip
} from 'reactstrap'
import classnames from 'classnames'

// Images
import profileBg from '../../../assets/images/bg-one-user.png'
// import profileBg from '../../../assets/images/profile-bg.jpg'
// import avatar1 from '../../../assets/images/users/avatar-1.jpg'
// import avatar8 from '../../../assets/images/users/avatar-8.jpg'

// import { document as documentData } from '../../../common/data'
import { useLazyQuery } from '@apollo/client'
import { GET_ONE_CREDIT_WALLET } from '../../../graphql/queries/getCreditsWallet'
import { useGetUser } from '../../../hooks/user'
import {
  hasPermission,
  isValidUUID,
  parseId,
  parsePrice
} from '../../../helpers'
import {
  ALL_PERMISSIONS,
  CREDIT_TYPES,
  ERROR_MESSAGES,
  QOUTA_STATUS
} from '../../../helpers/consts'
import { toast } from 'react-toastify'
import moment from 'moment'
import { StatusOneCredit } from '../../../Components/Own/StatusOneCredit'
import { LoadingPage } from '../../../Components/Own/creditPage/LoadingPage'
// import imgNoCupo from '../../../assets/images/no-data-cupo.png'
// import { ModalAddComment } from '../../../Components/Own/CreditWalletPage/ModalAddComment'
import { TabPagos } from '../../../Components/Own/CreditWalletPage/TabPagos'
import { TabViewPayments } from '../../../Components/Own/CreditWalletPage/TabViewPayments'
import { useDisclosure } from '../../../hooks/useDisclosure'
import { ModalAddGestionWallet } from '../../../Components/Own/CreditWalletPage/ModalAddGestionWallet'
import { ResumeInfoDebt } from '../../../Components/Own/CreditWalletPage/ResumeInfoDebt'
import { TabAbonoCapital } from '../../../Components/Own/CreditWalletPage/TabAbonoCapital'
import { NoInfoObligations } from '../../../Components/Own/CreditWalletPage/NoInfoObligations'
import { TabRefinanciacion } from '../../../Components/Own/CreditWalletPage/TabRefinanciacion'
import { TabInactiveOblg } from '../../../Components/Own/CreditWalletPage/TabInactiveOblg'
import { ModalInfoOnePay } from '../../../Components/Own/CreditWalletPage/ModalInfoOnePay'
import { TabDiscounts } from '../../../Components/Own/CreditWalletPage/TabDiscounts'
import { TabComments } from '../../../Components/Own/CreditWalletPage/TabComments'

export default function OneCreditWalletPage() {
  const [gqlGetCredit, { data, loading, error, refetch: refetchDataCredit }] =
    useLazyQuery(GET_ONE_CREDIT_WALLET, { fetchPolicy: 'no-cache' })

  const { id } = useParams()
  // console.log('rest', rest)
  const navigate = useNavigate()
  const { permissions, logout } = useGetUser()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const {
    isOpen: isOpenGestion,
    onClose: onCloseGestion,
    onOpen: onOpenGestion
  } = useDisclosure()
  // console.log('id param', param)
  // const [isCopy, setIsCopy] = useState(false)
  const [activeTab, setActiveTab] = useState('1')

  /** @type {[info: {qouta: number, qoutaId: string, endDate: string,info: {node:{id: string, quotapaymentdetailSet: {edges: {node:{capital: string,interest: string,penaltyInterest: string, payment: {created: string, value: string}, quota: {id: string}}}[]}, endorsementpaymentdetailSet: {edges: {node:{value: string, endorsement: {endorsement: string}}}[]}}}[]}, set: ()=>void]} */
  const [infoOnePay, setInfoOnePay] = useState(null)

  const [infoOneGestion, setInfoOneGestion] = useState(null)

  const [capitalValue, setCapitalValue] = useState(null)
  const [isAbono, setIsAbono] = useState(null)
  const [infoObligation, setInfoObligation] = useState(null)
  // const [isOneBigger, setIsOneBigger] = useState(false)

  useEffect(() => {
    if (!id) {
      return navigate('/')
    }
    if (Number(id)) {
      gqlGetCredit({
        variables: {
          id
        }
      })
      return
    }

    if (isValidUUID(id)) {
      gqlGetCredit({
        variables: {
          uuid: id
        }
      })
    } else {
      return navigate('/')
    }
  }, [id])

  useEffect(() => {
    if (!hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_CREDITOS)) {
      navigate('/')
    }
  }, [permissions])

  useEffect(() => {
    if (error) {
      toast.error(`ERROR: ${error.message}`)
      if (error.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [error])

  /* useEffect(() => {
    console.log('me ejecuto')
    filesComment.forEach(({ size }) => {
      if (size > MAX_SIZE) {
        setIsOneBigger(true)
      }
    })
  }, [filesComment]) */

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  const backToResume = () => setActiveTab('1')

  const getBadge = (status) => {
    const COLORS = {
      [QOUTA_STATUS['EN MORA']]: 'danger',
      [QOUTA_STATUS.PAGADA]: 'success',
      [QOUTA_STATUS.PARCIALMENTE_PAGADA]: 'info',
      [QOUTA_STATUS.PENDIENTE]: 'dark',
      [QOUTA_STATUS.ACTIVA]: 'primary',
      [QOUTA_STATUS.REFINANCIADA]: 'warning'
    }
    return (
      <Badge color={COLORS[QOUTA_STATUS[status]] || 'dark'}>
        {QOUTA_STATUS[status] || status}
      </Badge>
    )
  }

  document.title = 'Crédito | TOTAL SALES'

  if (loading) {
    return <LoadingPage />
  }

  if (!data) return null

  if (data.walletCredits.edges.length === 0) {
    return (
      <div className='page-content'>
        <Container fluid>
          <Row
            className='justify-content-center align-items-center'
            style={{ height: '30vh' }}
          >
            <Col
              lg={12}
              className='d-flex align-items-center h-100 justify-content-center bg-white'
            >
              <div>
                <h4>Ups! No se encontró crédito 😞</h4>
                <div className='d-flex justify-content-center'>
                  <Link to='/creditos' className='btn btn-info'>
                    Ir a créditos
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  /** @type {{}} */
  const credit = data.walletCredits.edges[0].node

  const comments = credit?.creditcommentwalletSet?.edges || []

  /** @type {{node: {id: string, value: string,term: string,montlyFee: string,endorsement: string,tea: string,initialDate: string,endDate: string,status: string}}[]} */
  const obligations = credit?.obligationSet?.edges || []

  const findInfo = obligations.find(({ node }) => node.actve)

  const noActiveObligations = obligations.filter(({ node }) => !node.actve)

  const info = findInfo?.node

  if (!info) {
    return (
      <NoInfoObligations
        credit={credit}
        refetchDataCredit={refetchDataCredit}
      />
    )
  }

  /** @type {{node: {id: string, value: string,term: string,effective: boolean, paymentManagement: string, montlyFee: string,endorsement: {endorsement:string},tea: string,initialDate: string,endDate: string,status: string, totalQuota: string, paymentDate: string, payments: {edges: {node: {quotapaymentdetailSet: {edges: {node: {paymentManagement: string, penaltyInterest: string, interest: string, capital: string}}[]}}}[]}}}[]} */
  const cuotas = findInfo?.node?.quotaSet?.edges || []

  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <div className='profile-foreground position-relative mx-n4 mt-n4'>
            <div className='profile-wid-bg' style={{ height: '490px' }}>
              <img
                src={profileBg}
                alt=''
                className='profile-wid-img bg-img-h'
              />
            </div>
          </div>
          <div className='pt-4 mb-4 mb-lg-3 pb-lg-4'>
            <Row className='g-4'>
              <div className='col-auto'>
                <div className='avatar-lg'>
                  <img
                    src={
                      credit.agency.zone.commercialCompany.image ||
                      '/TotalSales-Positivo.png'
                    }
                    alt='user-img'
                    className='img-thumbnail rounded-circle'
                    style={{ height: '92px' }}
                  />
                </div>
              </div>

              <Col>
                <div className='p-2'>
                  <h3 className='text-white mb-1 d-flex align-items-center gap-2 flex-wrap'>
                    Crédito en cartera
                    <Badge
                      className='fs-4'
                      color={
                        credit.creditType === CREDIT_TYPES.INMEDIATO
                          ? 'dark'
                          : credit.creditType === CREDIT_TYPES.TRADICIONAL
                          ? 'info'
                          : 'primary'
                      }
                    >
                      {credit.creditType}
                    </Badge>
                  </h3>
                  {/* <div className='d-flex align-items-center gap-1'>
                    <p className='text-white-75 m-0'>{credit.uuid}</p>
                    <button
                      className='border-0 p-0 bg-transparent m-0 text-white'
                      title={isCopy ? 'Copiado' : 'Copiar'}
                      onClick={() => {
                        window.navigator.clipboard.writeText(credit.uuid)
                        setIsCopy(true)
                        setTimeout(() => {
                          setIsCopy(false)
                        }, 2000)
                      }}
                    >
                      <i
                        className={`mdi ${
                          isCopy
                            ? 'mdi-text-box-check-outline'
                            : 'mdi-text-box-multiple-outline'
                        }`}
                        style={{
                          color: isCopy ? '#3fff3f' : undefined,
                          fontSize: '1em'
                        }}
                      />
                    </button>
                  </div> */}
                  <div className='hstack text-white-50 gap-2'>
                    <div className='me-2'>
                      <i className='ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle' />
                      {credit.agency.name}
                    </div>
                    <div>
                      <i className='ri-building-line me-1 text-white-75 fs-16 align-middle' />
                      {credit.agency.zone.commercialCompany.name}
                    </div>

                    <div className='ms-2'>
                      <Link
                        to={`/credito/${parseId(credit.id)}`}
                        className='d-flex align-items-center text-reset text-decoration-underline'
                        style={{
                          color: 'white',
                          textDecoration: 'underline !important'
                        }}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <i className='mdi mdi-eye-outline me-1 text-white-75 fs-16 align-middle' />
                        Ver crédito
                      </Link>
                    </div>
                  </div>
                  {credit.closedDate && (
                    <div className='d-flex mt-2'>
                      <Alert className='flex-0' color='warning'>
                        Cerrado el{' '}
                        <b>
                          {moment(credit.closedDate).format(
                            'DD MMM [del] YY - hh:mm A'
                          )}
                        </b>
                      </Alert>
                    </div>
                  )}
                </div>
              </Col>

              <Col xs={12} className='col-lg-auto order-last'>
                <Row className='text text-white-50 text-center me-1'>
                  <Col lg={3} sm={3} xs={6}>
                    <div className='p-2'>
                      {credit.creditType === CREDIT_TYPES.TRADICIONAL ? (
                        <>
                          <h4 className='text-white mb-1'>
                            {credit.initialFee
                              ? parsePrice(credit.initialFee)
                              : 'N/A'}
                          </h4>
                          <p className='fs-14 mb-0'>Inicial</p>
                        </>
                      ) : (
                        <>
                          <h4 className='text-white mb-1'>
                            {credit.cupoSeleccionado?.value
                              ? parsePrice(credit.cupoSeleccionado.value)
                              : 'N/A'}
                          </h4>
                          <p className='fs-14 mb-0'>Cupo</p>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col lg={3} sm={3} xs={6}>
                    <div className='p-2'>
                      {credit.creditType === CREDIT_TYPES.TRADICIONAL ? (
                        <h4 className='text-white mb-1'>
                          {credit.term ? credit.term : 'N/A'}
                        </h4>
                      ) : (
                        <h4 className='text-white mb-1'>
                          {credit.cupoSeleccionado?.term
                            ? credit.cupoSeleccionado.term
                            : 'N/A'}
                        </h4>
                      )}
                      <p className='fs-14 mb-0'>Plazo</p>
                    </div>
                  </Col>
                  <Col lg={3} sm={3} xs={6}>
                    <div className='p-2'>
                      {credit.creditType === CREDIT_TYPES.TRADICIONAL ? (
                        <h4 className='text-white mb-1'>
                          {credit.quotaValue
                            ? parsePrice(credit.quotaValue)
                            : 'N/A'}
                        </h4>
                      ) : (
                        <h4 className='text-white mb-1'>
                          {credit.cupoSeleccionado?.cuota
                            ? parsePrice(credit.cupoSeleccionado.cuota)
                            : 'N/A'}
                        </h4>
                      )}
                      <p className='fs-14 mb-0'>Cuota</p>
                    </div>
                  </Col>
                  <Col lg={3} sm={3} xs={6}>
                    <div className='p-2'>
                      {/* {credit.creditType === CREDIT_TYPES.TRADICIONAL ? (
                        <h4 className='text-white mb-1'>
                          {credit.productValue
                            ? parsePrice(credit.productValue)
                            : 'N/A'}
                        </h4>
                      ) : ( */}
                      <h4 className='text-white mb-1'>
                        {credit.productValue
                          ? parsePrice(credit.productValue)
                          : 'N/A'}
                      </h4>
                      {/* )} */}
                      <p className='fs-14 mb-0'>Valor Producto</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <Row>
            <Col lg={12}>
              <div>
                <div className='d-flex'>
                  <Nav
                    pills
                    className='animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1'
                    role='tablist'
                  >
                    <NavItem>
                      <NavLink
                        href='#overview-tab'
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => {
                          toggleTab('1')
                        }}
                      >
                        <i className='ri-airplay-fill d-inline-block d-md-none' />{' '}
                        <span className='d-none d-md-inline-block'>
                          Resumen
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href='#comments'
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => {
                          toggleTab('2')
                        }}
                      >
                        <i className='ri-list-unordered d-inline-block d-md-none' />{' '}
                        <span className='d-none d-md-inline-block'>
                          Comentarios
                        </span>
                      </NavLink>
                    </NavItem>
                    {cuotas.length > 0 && (
                      <NavItem>
                        <NavLink
                          href='#pagos'
                          className={classnames({ active: activeTab === '3' })}
                          onClick={() => {
                            toggleTab('3')
                          }}
                        >
                          <i className='ri-price-tag-line d-inline-block d-md-none' />{' '}
                          <span className='d-none d-md-inline-block'>
                            Pagos
                          </span>
                        </NavLink>
                      </NavItem>
                    )}
                    {cuotas.length > 0 && (
                      <NavItem>
                        <NavLink
                          href='#ver_pagos'
                          className={classnames({ active: activeTab === '4' })}
                          onClick={() => {
                            toggleTab('4')
                          }}
                        >
                          <i className='mdi mdi-credit-card-check-outline d-inline-block' />{' '}
                          <span className='d-none d-md-inline-block'>
                            Ver pagos
                          </span>
                        </NavLink>
                      </NavItem>
                    )}
                    <NavItem>
                      {capitalValue == null || isAbono == null ? (
                        <div className='d-flex align-items-center justify-content-center h-100'>
                          <Spinner size='sm' color='light' />
                        </div>
                      ) : isAbono ? (
                        <NavLink
                          href='#Abonos'
                          className={classnames({
                            active: activeTab === '5'
                          })}
                          onClick={() => {
                            toggleTab('5')
                          }}
                        >
                          <i className='mdi mdi-hand-coin-outline' />{' '}
                          <span className='d-none d-md-inline-block'>
                            Abono a capital
                          </span>
                        </NavLink>
                      ) : (
                        <NavLink
                          href='#Refinanciacion'
                          className={classnames({
                            active: activeTab === '6'
                          })}
                          onClick={() => {
                            toggleTab('6')
                          }}
                        >
                          <span>
                            <i className='mdi mdi-cash-refund' />
                          </span>{' '}
                          <span className='d-none d-md-inline-block'>
                            Refinanciación
                          </span>
                        </NavLink>
                      )}
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href='#hacer-descuento'
                        className={classnames({
                          active: activeTab === '8'
                        })}
                        onClick={() => {
                          toggleTab('8')
                        }}
                      >
                        <span>
                          <i className='mdi mdi-chevron-triple-down' />
                        </span>{' '}
                        <span className='d-none d-md-inline-block'>
                          Hacer descuento
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      {noActiveObligations.length > 0 && (
                        <NavLink
                          href='#obligaciones-inactivas'
                          className={classnames({ active: activeTab === '7' })}
                          onClick={() => {
                            toggleTab('7')
                          }}
                        >
                          <i className='ri-list-unordered d-inline-block d-md-none' />{' '}
                          <span className='d-none d-md-inline-block'>
                            Obligaciones inactivas
                          </span>
                        </NavLink>
                      )}
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        href='#documents'
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => {
                          toggleTab('3')
                        }}
                      >
                        <i className='ri-folder-4-line d-inline-block d-md-none' />{' '}
                        <span className='d-none d-md-inline-block'>
                          Soportes
                        </span>
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                </div>

                <TabContent activeTab={activeTab} className='pt-4'>
                  {activeTab === '1' && (
                    <TabPane tabId='1'>
                      <Row>
                        <Col lg={3} md={12}>
                          <Card>
                            <CardBody className='d-flex justify-content-center'>
                              {/* <h5 className='card-title mb-0'>Estado</h5> */}
                              <StatusOneCredit status={credit.status} />
                            </CardBody>
                          </Card>

                          <Card>
                            <CardBody>
                              <h5 className='card-title mb-3'>
                                Información cliente
                              </h5>
                              <div className='table-responsive'>
                                <Table className='table-borderless mb-0'>
                                  <tbody>
                                    <tr>
                                      <th className='ps-0' scope='row'>
                                        Nombre(s):
                                      </th>
                                      <td className='text-muted'>
                                        {credit.firstName} {credit.secondName}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className='ps-0' scope='row'>
                                        Apellido(s):
                                      </th>
                                      <td className='text-muted'>
                                        {credit.firstSurname}{' '}
                                        {credit.secondSurname}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className='ps-0' scope='row'>
                                        Documento:
                                      </th>
                                      <td className='text-muted'>
                                        {credit.documentType.toLowerCase()}{' '}
                                        {credit.documentNumber}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className='ps-0' scope='row'>
                                        Teléfono:
                                      </th>
                                      <td className='text-muted'>
                                        {credit.phoneNumber}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className='ps-0' scope='row'>
                                        E-mail:
                                      </th>
                                      <td className='text-muted'>
                                        {credit.email}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className='ps-0' scope='row'>
                                        Fecha de nacimiento:
                                      </th>
                                      <td className='text-muted'>
                                        {moment(credit.dateBirth).format(
                                          'DD MMMM [de] YYYY'
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className='ps-0' scope='row'>
                                        Fecha de expedición:
                                      </th>
                                      <td className='text-muted'>
                                        {moment(credit.expeditionDate).format(
                                          'DD MMMM [de] YYYY'
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className='ps-0' scope='row'>
                                        Autorización
                                      </th>
                                      <td>
                                        {credit.firmaAutorizacion ? (
                                          <Badge color='success'>Firmada</Badge>
                                        ) : (
                                          <Badge color='danger'>
                                            No firmado
                                          </Badge>
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </CardBody>
                          </Card>
                          <Card>
                            <CardBody>
                              <h5 className='card-title mb-3'>
                                Información adicional
                              </h5>
                              <div className='table-responsive'>
                                <Table className='table-borderless mb-0'>
                                  <tbody>
                                    <tr>
                                      <th className='ps-0' scope='row'>
                                        Asesor:
                                      </th>
                                      <td className='text-muted'>
                                        {credit.user ? (
                                          <>
                                            {credit.user.firstName}{' '}
                                            {credit.user.lastName}
                                          </>
                                        ) : (
                                          <span
                                            className='d-flex align-items-center gap-1'
                                            style={{ color: '#ff8787' }}
                                          >
                                            <i className='mdi mdi-account-cancel' />{' '}
                                            No aplica
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className='ps-0' scope='row'>
                                        Analista:
                                      </th>
                                      <td className='text-muted'>
                                        {credit.analyst ? (
                                          <>
                                            {credit.analyst.firstName}{' '}
                                            {credit.analyst.lastName}
                                          </>
                                        ) : (
                                          <span
                                            className='d-flex align-items-center gap-1'
                                            style={{ color: '#ff8787' }}
                                          >
                                            <i className='mdi mdi-account-cancel' />{' '}
                                            No aplica
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg={9} md={12}>
                          <Card>
                            <CardHeader className='d-flex align-items-center justify-content-between'>
                              <h5 className='card-title m-0 p-0'>
                                Obligaciones financieras
                              </h5>
                            </CardHeader>
                            <CardBody id='table-cupo-preaprobado'>
                              <div className='w-100 d-flex align-items-center justify-content-center'>
                                {obligations.length > 0 ? (
                                  <div className='w-100 d-flex justify-content-center flex-column'>
                                    <div className='table-responsive table-card'>
                                      <table className='table table-nowrap align-middle table-borderless mb-0'>
                                        <thead className='table-light text-muted'>
                                          <tr>
                                            <th className='text-center'>
                                              Valor de la obligacion
                                            </th>
                                            <th className='text-center'>
                                              Valor de aval
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td className='text-center'>
                                              {parsePrice(info.value)}
                                            </td>
                                            <td className='text-center'>
                                              {parsePrice(info.endorsement)}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>

                                    {info?.id && (
                                      <ResumeInfoDebt
                                        setCapitalValue={setCapitalValue}
                                        obligationId={info.id}
                                        setIsAbono={setIsAbono}
                                        setInfoObligation={setInfoObligation}
                                      />
                                    )}

                                    <div>
                                      <h4 className='my-2'>Cuotas</h4>
                                    </div>
                                    <div className='w-100 d-flex align-items-center justify-content-center mt-4'>
                                      <div className='table-responsive table-card'>
                                        <table className='table table-nowrap align-middle table-borderless mb-0'>
                                          <thead className='table-light text-muted'>
                                            <tr>
                                              <th
                                                scope='col'
                                                className='text-center'
                                              >
                                                Cuota
                                              </th>
                                              <th
                                                scope='col'
                                                className='text-center'
                                              >
                                                Estado
                                              </th>
                                              <th />
                                              <th
                                                scope='col'
                                                className='text-center'
                                              >
                                                Fecha de vencimiento
                                              </th>
                                              <th
                                                scope='col'
                                                className='text-center'
                                              >
                                                Capital
                                              </th>
                                              <th
                                                scope='col'
                                                className='text-center'
                                              >
                                                Interés
                                              </th>
                                              <th
                                                scope='col'
                                                className='text-center'
                                              >
                                                Aval
                                              </th>
                                              <th
                                                scope='col'
                                                className='text-center'
                                              >
                                                Valor de cuota{' '}
                                              </th>
                                              <th
                                                scope='col'
                                                className='text-center'
                                              >
                                                Días vencidos
                                              </th>
                                              <th
                                                scope='col'
                                                className='text-center'
                                              >
                                                Intereses de mora
                                              </th>
                                              <th
                                                scope='col'
                                                className='text-center'
                                              >
                                                Gestión de cobro
                                              </th>
                                              <th
                                                scope='col'
                                                className='text-end'
                                              >
                                                Total
                                              </th>
                                              <th scope='col' />
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {cuotas.map(({ node }, idx) => (
                                              <tr key={node.id}>
                                                <th className='text-center'>
                                                  {idx + 1}
                                                </th>
                                                <td className='text-center'>
                                                  {getBadge(node.status)}
                                                </td>
                                                <td className='text-center'>
                                                  <div className='d-flex align-items-center gap-1'>
                                                    {node.payments.edges
                                                      .length > 0 && (
                                                      <button
                                                        title='Ver pago'
                                                        className='border-0 p-0 m-0 bg-transparent'
                                                        onClick={() => {
                                                          onOpen()
                                                          setInfoOnePay({
                                                            info: node.payments
                                                              .edges,
                                                            qouta: idx + 1,
                                                            endDate:
                                                              node.endDate,
                                                            qoutaId: node.id
                                                          })
                                                        }}
                                                      >
                                                        <i className='fs-4 mdi mdi-eye text-secondary' />
                                                      </button>
                                                    )}
                                                    {node.effective &&
                                                      node.status !==
                                                        'PAGADA' &&
                                                      node.status !==
                                                        'PARCIALMENTE_PAGADA' && (
                                                        <>
                                                          <button
                                                            id={`toltipAddCobroGestion${idx}`}
                                                            className='border-0 p-0 m-0 bg-transparent'
                                                            onClick={() => {
                                                              setInfoOneGestion(
                                                                {
                                                                  id: node.id,
                                                                  endDate:
                                                                    node.endDate,
                                                                  value:
                                                                    node.paymentManagement
                                                                }
                                                              )
                                                              onOpenGestion()
                                                            }}
                                                          >
                                                            <i className='fs-4 mdi mdi-cash-register text-danger' />
                                                          </button>
                                                          <UncontrolledTooltip
                                                            placement='top'
                                                            target={`toltipAddCobroGestion${idx}`}
                                                          >
                                                            Agregar gestión de
                                                            cobro
                                                          </UncontrolledTooltip>
                                                        </>
                                                      )}
                                                  </div>
                                                </td>
                                                <td className='text-center'>
                                                  {moment(node.endDate).format(
                                                    'DD MMM YYYY'
                                                  )}
                                                </td>
                                                <td className='text-center'>
                                                  {parsePrice(node.capital)}
                                                </td>
                                                <td className='text-center'>
                                                  {parsePrice(node.interest)}
                                                </td>
                                                <td className='text-center'>
                                                  {parsePrice(
                                                    node.endorsement.endorsement
                                                  )}
                                                </td>
                                                <td className='text-center'>
                                                  {parsePrice(info.montlyFee)}
                                                </td>
                                                <td className='text-center'>
                                                  {moment(
                                                    node.paymentDate ||
                                                      new Date()
                                                  ).diff(node.endDate, 'days') <
                                                  0 ? (
                                                    0
                                                  ) : (
                                                    <span className='text-danger'>
                                                      {moment(
                                                        node.paymentDate ||
                                                          new Date()
                                                      ).diff(
                                                        node.endDate,
                                                        'days'
                                                      )}
                                                    </span>
                                                  )}
                                                </td>
                                                <td className='text-center'>
                                                  {parsePrice(
                                                    node.penaltyInterest
                                                  )}
                                                </td>
                                                <td className='text-center'>
                                                  {parsePrice(
                                                    node.paymentManagement
                                                  )}
                                                </td>
                                                <td className='text-center'>
                                                  {parsePrice(node.totalQuota)}
                                                </td>
                                                <td />
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className='d-flex align-items-center justify-content-center'
                                    style={{ height: '20vh' }}
                                  >
                                    No hay Información registrada
                                  </div>
                                )}
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                  )}
                  <TabComments
                    tabId='2'
                    comments={comments}
                    creditId={parseId(credit.id)}
                    refetchDataCredit={refetchDataCredit}
                  />
                  {cuotas.length > 0 && (
                    <TabPagos
                      tabId='3'
                      pagos={cuotas}
                      coutaTotal={info.montlyFee}
                      refetch={refetchDataCredit}
                      backToResume={backToResume}
                      creditId={credit.id}
                      obligationId={info.id}
                    />
                  )}
                  {cuotas.length > 0 && (
                    <TabViewPayments
                      activeTab={activeTab}
                      obligationId={info.id}
                      creditId={credit.id}
                    />
                  )}
                  {activeTab === '5' && (
                    <TabPane tabId='5' className='p-4'>
                      <TabAbonoCapital
                        capital={capitalValue}
                        obligationId={info.id}
                        refetch={refetchDataCredit}
                        backToResume={backToResume}
                      />
                    </TabPane>
                  )}
                  {activeTab === '6' && infoObligation && (
                    <TabPane tabId='6' className='p-4'>
                      <TabRefinanciacion
                        capital={capitalValue}
                        obligationId={info.id}
                        refetch={refetchDataCredit}
                        backToResume={backToResume}
                        infoObligation={infoObligation}
                      />
                    </TabPane>
                  )}
                  {activeTab === '7' && noActiveObligations.length > 0 && (
                    <TabPane tabId='7' className='p-4'>
                      <TabInactiveOblg obligations={noActiveObligations} />
                    </TabPane>
                  )}
                  {activeTab === '8' && infoObligation && (
                    <TabPane tabId='8' className='p-4'>
                      <TabDiscounts
                        capital={capitalValue}
                        obligationId={info.id}
                        refetch={refetchDataCredit}
                        backToResume={backToResume}
                        infoObligation={infoObligation}
                      />
                    </TabPane>
                  )}
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>

        {infoOnePay && (
          <ModalInfoOnePay
            infoOnePay={infoOnePay}
            isOpen={isOpen}
            onClose={onClose}
          />
        )}
      </div>

      {isOpenGestion && infoOneGestion && (
        <ModalAddGestionWallet
          isOpen={isOpenGestion}
          onClose={onCloseGestion}
          refetch={refetchDataCredit}
          infoOneGestion={infoOneGestion}
        />
      )}
    </>
  )
}
