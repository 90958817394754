import { STATUS_DISBURSEMENT } from '../../../helpers/consts'

export const BadgeDisbursements = ({ status }) => {
  switch (STATUS_DISBURSEMENT[status]) {
    case STATUS_DISBURSEMENT.POR_DESEMBOLSAR:
      return (
        <span className='badge badge-soft-dark text-uppercase'>
          {status.replace(/_/g, ' ')}
        </span>
      )
    case STATUS_DISBURSEMENT.DESEMBOLSADO:
      return (
        <span className='badge badge-soft-success text-uppercase'>
          {status.replace(/_/g, ' ')}
        </span>
      )
    case STATUS_DISBURSEMENT.CREACION_DE_CARTERA:
      return (
        <span className='badge badge-soft-info text-uppercase'>
          {status.replace(/_/g, ' ')}
        </span>
      )
    case STATUS_DISBURSEMENT.PENDIENTE_DE_APROBACION:
      return (
        <span className='badge badge-soft-warning text-uppercase'>
          {status.replace(/_/g, ' ')}
        </span>
      )
    default:
      return (
        <span className='badge badge-soft-dark text-uppercase'>
          {status.replace(/_/g, ' ')}
        </span>
      )
  }
}
