import React from 'react'
import { Navigate } from 'react-router-dom'

// Dashboard
import DashboardAnalytics from '../pages/DashboardAnalytics'
import DashboardCrm from '../pages/DashboardCrm'
import DashboardEcommerce from '../pages/DashboardEcommerce'

import DashboardCrypto from '../pages/DashboardCrypto'
import DashboardProject from '../pages/DashboardProject'
import DashboardNFT from '../pages/DashboardNFT'
import DashboardJob from '../pages/DashboardJob/'

// Calendar
//  Email box
import MailInbox from '../pages/EmailInbox'
import BasicAction from '../pages/Email/EmailTemplates/BasicAction'
import EcommerceAction from '../pages/Email/EmailTemplates/EcommerceAction'

// Chat
import Chat from '../pages/Chat'
import Calendar from '../pages/Calendar'

// Project
import ProjectList from '../pages/Projects/ProjectList'
import ProjectOverview from '../pages/Projects/ProjectOverview'
import CreateProject from '../pages/Projects/CreateProject'

// Task
import TaskDetails from '../pages/Tasks/TaskDetails'
import TaskList from '../pages/Tasks/TaskList'

// Transactions
import Transactions from '../pages/Crypto/Transactions'
import BuySell from '../pages/Crypto/BuySell'
import CryproOrder from '../pages/Crypto/CryptoOrder'
import MyWallet from '../pages/Crypto/MyWallet'
import ICOList from '../pages/Crypto/ICOList'
import KYCVerification from '../pages/Crypto/KYCVerification'

// Crm Pages
import CrmCompanies from '../pages/Crm/CrmCompanies'
import CrmContacts from '../pages/Crm/CrmContacts'
import CrmDeals from '../pages/Crm/CrmDeals/index'
import CrmLeads from '../pages/Crm/CrmLeads/index'

// Invoices
import InvoiceList from '../pages/Invoices/InvoiceList'
import InvoiceCreate from '../pages/Invoices/InvoiceCreate'
import InvoiceDetails from '../pages/Invoices/InvoiceDetails'

//  Support Tickets
import ListView from '../pages/SupportTickets/ListView'
import TicketsDetails from '../pages/SupportTickets/TicketsDetails'

// Ecommerce Pages
import EcommerceProducts from '../pages/Ecommerce/EcommerceProducts/index'
import EcommerceProductDetail from '../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail'
import EcommerceAddProduct from '../pages/Ecommerce/EcommerceProducts/EcommerceAddProduct'
import EcommerceOrders from '../pages/Ecommerce/EcommerceOrders/index'
import EcommerceOrderDetail from '../pages/Ecommerce/EcommerceOrders/EcommerceOrderDetail'
import EcommerceCustomers from '../pages/Ecommerce/EcommerceCustomers/index'
import EcommerceCart from '../pages/Ecommerce/EcommerceCart'
import EcommerceCheckout from '../pages/Ecommerce/EcommerceCheckout'
import EcommerceSellers from '../pages/Ecommerce/EcommerceSellers/index'
import EcommerceSellerDetail from '../pages/Ecommerce/EcommerceSellers/EcommerceSellerDetail'

// NFT Marketplace Pages
import Marketplace from '../pages/NFTMarketplace/Marketplace'
import Collections from '../pages/NFTMarketplace/Collections'
import CreateNFT from '../pages/NFTMarketplace/CreateNFT'
import Creators from '../pages/NFTMarketplace/Creators'
import ExploreNow from '../pages/NFTMarketplace/ExploreNow'
import ItemDetails from '../pages/NFTMarketplace/Itemdetails'
import LiveAuction from '../pages/NFTMarketplace/LiveAuction'
import Ranking from '../pages/NFTMarketplace/Ranking'
import WalletConnect from '../pages/NFTMarketplace/WalletConnect'

// Base Ui
import UiAlerts from '../pages/BaseUi/UiAlerts/UiAlerts'
import UiBadges from '../pages/BaseUi/UiBadges/UiBadges'
import UiButtons from '../pages/BaseUi/UiButtons/UiButtons'
import UiColors from '../pages/BaseUi/UiColors/UiColors'
import UiCards from '../pages/BaseUi/UiCards/UiCards'
import UiCarousel from '../pages/BaseUi/UiCarousel/UiCarousel'
import UiDropdowns from '../pages/BaseUi/UiDropdowns/UiDropdowns'
import UiGrid from '../pages/BaseUi/UiGrid/UiGrid'
import UiImages from '../pages/BaseUi/UiImages/UiImages'
import UiTabs from '../pages/BaseUi/UiTabs/UiTabs'
import UiAccordions from '../pages/BaseUi/UiAccordion&Collapse/UiAccordion&Collapse'
import UiModals from '../pages/BaseUi/UiModals/UiModals'
import UiOffcanvas from '../pages/BaseUi/UiOffcanvas/UiOffcanvas'
import UiPlaceholders from '../pages/BaseUi/UiPlaceholders/UiPlaceholders'
import UiProgress from '../pages/BaseUi/UiProgress/UiProgress'
import UiNotifications from '../pages/BaseUi/UiNotifications/UiNotifications'
import UiMediaobject from '../pages/BaseUi/UiMediaobject/UiMediaobject'
import UiEmbedVideo from '../pages/BaseUi/UiEmbedVideo/UiEmbedVideo'
import UiTypography from '../pages/BaseUi/UiTypography/UiTypography'
import UiList from '../pages/BaseUi/UiLists/UiLists'
import UiGeneral from '../pages/BaseUi/UiGeneral/UiGeneral'
import UiRibbons from '../pages/BaseUi/UiRibbons/UiRibbons'
import UiUtilities from '../pages/BaseUi/UiUtilities/UiUtilities'

// Advance Ui
import UiNestableList from '../pages/AdvanceUi/UiNestableList/UiNestableList'
import UiScrollbar from '../pages/AdvanceUi/UiScrollbar/UiScrollbar'
import UiAnimation from '../pages/AdvanceUi/UiAnimation/UiAnimation'
import UiTour from '../pages/AdvanceUi/UiTour/UiTour'
import UiSwiperSlider from '../pages/AdvanceUi/UiSwiperSlider/UiSwiperSlider'
import UiRatings from '../pages/AdvanceUi/UiRatings/UiRatings'
import UiHighlight from '../pages/AdvanceUi/UiHighlight/UiHighlight'

// Widgets
import Widgets from '../pages/Widgets/Index'

// Forms
import BasicElements from '../pages/Forms/BasicElements/BasicElements'
import FormSelect from '../pages/Forms/FormSelect/FormSelect'
import FormEditor from '../pages/Forms/FormEditor/FormEditor'
import CheckBoxAndRadio from '../pages/Forms/CheckboxAndRadio/CheckBoxAndRadio'
import Masks from '../pages/Forms/Masks/Masks'
import FileUpload from '../pages/Forms/FileUpload/FileUpload'
import FormPickers from '../pages/Forms/FormPickers/FormPickers'
import FormRangeSlider from '../pages/Forms/FormRangeSlider/FormRangeSlider'
import Formlayouts from '../pages/Forms/FormLayouts/Formlayouts'
import FormValidation from '../pages/Forms/FormValidation/FormValidation'
import FormWizard from '../pages/Forms/FormWizard/FormWizard'
import FormAdvanced from '../pages/Forms/FormAdvanced/FormAdvanced'
import Select2 from '../pages/Forms/Select2/Select2'

// Tables
import BasicTables from '../pages/Tables/BasicTables/BasicTables'
import GridTables from '../pages/Tables/GridTables/GridTables'
import ListTables from '../pages/Tables/ListTables/ListTables'
import DataTables from '../pages/Tables/DataTables/DataTables'

// Icon pages
import RemixIcons from '../pages/Icons/RemixIcons/RemixIcons'
import BoxIcons from '../pages/Icons/BoxIcons/BoxIcons'
import MaterialDesign from '../pages/Icons/MaterialDesign/MaterialDesign'
import FeatherIcons from '../pages/Icons/FeatherIcons/FeatherIcons'
import LineAwesomeIcons from '../pages/Icons/LineAwesomeIcons/LineAwesomeIcons'
import CryptoIcons from '../pages/Icons/CryptoIcons/CryptoIcons'

// Maps
import GoogleMaps from '../pages/Maps/GoogleMaps/GoogleMaps'
import VectorMaps from '../pages/Maps/VectorMaps/VectorMaps'
import LeafletMaps from '../pages/Maps/LeafletMaps/LeafletMaps'

// AuthenticationInner pages
import BasicSignIn from '../pages/AuthenticationInner/Login/BasicSignIn'
import CoverSignIn from '../pages/AuthenticationInner/Login/CoverSignIn'
import BasicSignUp from '../pages/AuthenticationInner/Register/BasicSignUp'
import CoverSignUp from '../pages/AuthenticationInner/Register/CoverSignUp'
import BasicPasswReset from '../pages/AuthenticationInner/PasswordReset/BasicPasswReset'
// pages
import Starter from '../pages/Pages/Starter/Starter'
import SimplePage from '../pages/Pages/Profile/SimplePage/SimplePage'
import Settings from '../pages/Pages/Profile/Settings/Settings'
import Team from '../pages/Pages/Team/Team'
import Timeline from '../pages/Pages/Timeline/Timeline'
import Faqs from '../pages/Pages/Faqs/Faqs'
import Pricing from '../pages/Pages/Pricing/Pricing'
import Gallery from '../pages/Pages/Gallery/Gallery'
import Maintenance from '../pages/Pages/Maintenance/Maintenance'
import ComingSoon from '../pages/Pages/ComingSoon/ComingSoon'
import SiteMap from '../pages/Pages/SiteMap/SiteMap'
import SearchResults from '../pages/Pages/SearchResults/SearchResults'

import CoverPasswReset from '../pages/AuthenticationInner/PasswordReset/CoverPasswReset'
import BasicLockScreen from '../pages/AuthenticationInner/LockScreen/BasicLockScr'
import CoverLockScreen from '../pages/AuthenticationInner/LockScreen/CoverLockScr'
import BasicLogout from '../pages/AuthenticationInner/Logout/BasicLogout'
import CoverLogout from '../pages/AuthenticationInner/Logout/CoverLogout'
import BasicSuccessMsg from '../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg'
import CoverSuccessMsg from '../pages/AuthenticationInner/SuccessMessage/CoverSuccessMsg'
import BasicTwosVerify from '../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify'
import CoverTwosVerify from '../pages/AuthenticationInner/TwoStepVerification/CoverTwosVerify'
import Basic404 from '../pages/AuthenticationInner/Errors/Basic404'
import Cover404 from '../pages/AuthenticationInner/Errors/Cover404'
import Alt404 from '../pages/AuthenticationInner/Errors/Alt404'
import Error500 from '../pages/AuthenticationInner/Errors/Error500'

import BasicPasswCreate from '../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate'
import CoverPasswCreate from '../pages/AuthenticationInner/PasswordCreate/CoverPasswCreate'
import Offlinepage from '../pages/AuthenticationInner/Errors/Offlinepage'

// APi Key
import APIKey from '../pages/APIKey/index'

// login
import Login from '../pages/Authentication/Login'
import ForgetPasswordPage from '../pages/Authentication/ForgetPassword'
import Logout from '../pages/Authentication/Logout'
import Register from '../pages/Authentication/Register'

// Charts
import LineCharts from '../pages/Charts/ApexCharts/LineCharts'
import AreaCharts from '../pages/Charts/ApexCharts/AreaCharts'
import ColumnCharts from '../pages/Charts/ApexCharts/ColumnCharts'
import BarCharts from '../pages/Charts/ApexCharts/BarCharts'
import MixedCharts from '../pages/Charts/ApexCharts/MixedCharts'
import TimelineCharts from '../pages/Charts/ApexCharts/TimelineCharts'
import CandlestickChart from '../pages/Charts/ApexCharts/CandlestickChart'
import BoxplotCharts from '../pages/Charts/ApexCharts/BoxplotCharts'
import BubbleChart from '../pages/Charts/ApexCharts/BubbleChart'
import ScatterCharts from '../pages/Charts/ApexCharts/ScatterCharts'
import HeatmapCharts from '../pages/Charts/ApexCharts/HeatmapCharts'
import TreemapCharts from '../pages/Charts/ApexCharts/TreemapCharts'
import PieCharts from '../pages/Charts/ApexCharts/PieCharts'
import RadialbarCharts from '../pages/Charts/ApexCharts/RadialbarCharts'
import RadarCharts from '../pages/Charts/ApexCharts/RadarCharts'
import PolarCharts from '../pages/Charts/ApexCharts/PolarCharts'
import ChartsJs from '../pages/Charts/ChartsJs/index'
import Echarts from '../pages/Charts/ECharts/index'

// Job pages
import Statistics from '../pages/Jobs/Statistics'
import JobList from '../pages/Jobs/JobList/List'
import JobGrid from '../pages/Jobs/JobList/Grid'
import JobOverview from '../pages/Jobs/JobList/Overview'
import CandidateList from '../pages/Jobs/CandidateList/ListView'
import CandidateGrid from '../pages/Jobs/CandidateList/GridView'
import NewJobs from '../pages/Jobs/NewJob'
import JobCategories from '../pages/Jobs/JobCategories'
import Application from '../pages/Jobs/Application'
import CompaniesList from '../pages/Jobs/CompaniesList'

//  Landing Index
import OnePage from '../pages/Landing/OnePage'
import NFTLanding from '../pages/Landing/NFTLanding'

import PrivecyPolicy from '../pages/Pages/PrivacyPolicy'
import TermsCondition from '../pages/Pages/TermsCondition'
import JobLanding from '../pages/Job_Landing/Job'

// User Profile
import UserProfile from '../pages/Authentication/user-profile'

import FileManager from '../pages/FileManager'
import ToDoList from '../pages/ToDo'

// Own pages
import ZonasCustom from '../pages/App/zonas'
import AgenciaCustom from '../pages/App/Agencias'
import EmpresaComercialCustom from '../pages/App/EmpresaComercial'
import ProductosCustom from '../pages/App/Productos'
import MotosUsadasPage from '../pages/App/MotocicletasUsadas'
import Usuarios from '../pages/App/Usuarios'
import OneProduct from '../pages/App/OneProduct'
import OneMotoUsada from '../pages/App/OneMotoUsada'
import Cotizaciones from '../pages/App/Cotizaciones'
import AddCotizaciones from '../pages/App/AddCotizaciones'
import OneCotizacion from '../pages/App/OneCotizacion/OneCotizacion'
import AddUser from '../pages/App/AddUser'
import EditUser from '../pages/App/EditUser'
import OneUser from '../pages/App/oneUser'
import CategoriasPage from '../pages/App/Categorias'
import Billings from '../pages/App/Billings'
import AddBilling from '../pages/App/AddBilling'
import OneBilling from '../pages/App/OneBilling'
import CrmCustom from '../pages/App/Crm'
import Notifications from '../pages/App/Notifications'
import CreditsPage from '../pages/App/Credits'
import AddCreditPage from '../pages/App/AddCredit'
import OneCreditPage from '../pages/App/OneCredit'
import DisbursementPage from '../pages/App/Disbursements'
import { OneDisbursement } from '../pages/App/OneDisbursement'
import CreditsWalletPage from '../pages/App/CreditsWallet'
import OneCreditWalletPage from '../pages/App/OneCreditWallet'
import CertificatesPage from '../pages/App/Certificates'
import { OneCertificatePage } from '../pages/App/OneCertificate'
import FormatsPage from '../pages/App/Formats'
import { AddProductToQuotationPage } from '../pages/App/AddProductToQuotationPage'
import { AsesoresPage } from '../pages/App/Asesores'
import { CotizacionAliados } from '../pages/App/CotizacionAliados'
import OneCotizacionAliado from '../pages/App/OneCotizacionAliado'
import { CotizacionesOtro } from '../pages/App/CotizacionesOtros'
import OneCotizacionOtros from '../pages/App/OneCotizacionOtros'
import { PrecisionPage } from '../Components/Own/precision/PrecisionPage'
// end own pages

const authProtectedRoutes = [
  // OWNS
  { path: '/usuarios', component: <Usuarios /> },
  { path: '/zonas', component: <ZonasCustom /> },
  { path: '/agencias', component: <AgenciaCustom /> },
  { path: '/empresa-comercial', component: <EmpresaComercialCustom /> },
  { path: '/productos', component: <ProductosCustom /> },
  { path: '/categorias', component: <CategoriasPage /> },
  { path: '/motocicletas-usadas', component: <MotosUsadasPage /> },
  { path: '/producto/:id', component: <OneProduct /> },
  { path: '/motocicleta-usada/:id', component: <OneMotoUsada /> },
  { path: '/cotizaciones', component: <Cotizaciones /> },
  { path: '/agregar-cotizacion', component: <AddCotizaciones /> },
  { path: '/cotizacion/:id', component: <OneCotizacion /> },
  { path: '/agregar-usuario', component: <AddUser /> },
  { path: '/editar-usuario/:id', component: <EditUser /> },
  { path: '/usuario/:id', component: <OneUser /> },
  { path: '/facturaciones', component: <Billings /> },
  { path: '/agregar-facturacion/:id', component: <AddBilling /> },
  { path: '/facturacion/:id', component: <OneBilling /> },
  { path: '/dashboard', component: <CrmCustom /> },
  { path: '/notificaciones', component: <Notifications /> },
  { path: '/creditos', component: <CreditsPage /> },
  { path: '/agregar-credito', component: <AddCreditPage /> },
  { path: '/credito/*', component: <OneCreditPage /> },
  { path: '/desembolsos', component: <DisbursementPage /> },
  { path: '/desembolso/:id', component: <OneDisbursement /> },
  { path: '/cartera_creditos', component: <CreditsWalletPage /> },
  { path: '/cartera_credito/:id', component: <OneCreditWalletPage /> },
  { path: '/paz_y_salvos', component: <CertificatesPage /> },
  { path: '/paz_y_salvo/:id', component: <OneCertificatePage /> },
  { path: '/formatos', component: <FormatsPage /> },
  {
    path: '/producto-a-cotizacion/:id',
    component: <AddProductToQuotationPage />
  },
  { path: '/asesores', component: <AsesoresPage /> },
  { path: '/aliados-cotizaciones', component: <CotizacionAliados /> },
  { path: '/aliado-cotizacion/:id', component: <OneCotizacionAliado /> },
  { path: '/cotizaciones-otros', component: <CotizacionesOtro /> },
  { path: '/cotizacion-otros/:id', component: <OneCotizacionOtros /> },
  { path: '/precision', component: <PrecisionPage /> },
  // END
  { path: '/dashboard-analytics', component: <DashboardAnalytics /> },
  { path: '/dashboard-crm', component: <DashboardCrm /> },
  { path: '/index', component: <DashboardEcommerce /> },
  { path: '/dashboard-crypto', component: <DashboardCrypto /> },
  { path: '/dashboard-projects', component: <DashboardProject /> },
  { path: '/dashboard-nft', component: <DashboardNFT /> },
  { path: '/dashboard-job', component: <DashboardJob /> },
  { path: '/apps-calendar', component: <Calendar /> },
  { path: '/apps-ecommerce-products', component: <EcommerceProducts /> },
  {
    path: '/apps-ecommerce-product-details/:_id',
    component: <EcommerceProductDetail />
  },
  {
    path: '/apps-ecommerce-product-details',
    component: <EcommerceProductDetail />
  },
  { path: '/apps-ecommerce-add-product', component: <EcommerceAddProduct /> },
  { path: '/apps-ecommerce-orders', component: <EcommerceOrders /> },
  {
    path: '/apps-ecommerce-order-details',
    component: <EcommerceOrderDetail />
  },
  { path: '/apps-ecommerce-customers', component: <EcommerceCustomers /> },
  { path: '/apps-ecommerce-cart', component: <EcommerceCart /> },
  { path: '/apps-ecommerce-checkout', component: <EcommerceCheckout /> },
  { path: '/apps-ecommerce-sellers', component: <EcommerceSellers /> },
  {
    path: '/apps-ecommerce-seller-details',
    component: <EcommerceSellerDetail />
  },

  { path: '/apps-file-manager', component: <FileManager /> },
  { path: '/apps-todo', component: <ToDoList /> },

  // Chat
  { path: '/apps-chat', component: <Chat /> },

  // EMail
  { path: '/apps-mailbox', component: <MailInbox /> },
  { path: '/apps-email-basic', component: <BasicAction /> },
  { path: '/apps-email-ecommerce', component: <EcommerceAction /> },

  // Projects
  { path: '/apps-projects-list', component: <ProjectList /> },
  { path: '/apps-projects-overview', component: <ProjectOverview /> },
  { path: '/apps-projects-create', component: <CreateProject /> },

  // Task
  { path: '/apps-tasks-list-view', component: <TaskList /> },
  { path: '/apps-tasks-details', component: <TaskDetails /> },

  // Api Key
  { path: '/apps-api-key', component: <APIKey /> },

  // Crm
  { path: '/apps-crm-contacts', component: <CrmContacts /> },
  { path: '/apps-crm-companies', component: <CrmCompanies /> },
  { path: '/apps-crm-deals', component: <CrmDeals /> },
  { path: '/apps-crm-leads', component: <CrmLeads /> },

  // Invoices
  { path: '/apps-invoices-list', component: <InvoiceList /> },
  { path: '/apps-invoices-details', component: <InvoiceDetails /> },
  { path: '/apps-invoices-create', component: <InvoiceCreate /> },

  // Supports Tickets
  { path: '/apps-tickets-list', component: <ListView /> },
  { path: '/apps-tickets-details', component: <TicketsDetails /> },

  // transactions
  { path: '/apps-crypto-transactions', component: <Transactions /> },
  { path: '/apps-crypto-buy-sell', component: <BuySell /> },
  { path: '/apps-crypto-orders', component: <CryproOrder /> },
  { path: '/apps-crypto-wallet', component: <MyWallet /> },
  { path: '/apps-crypto-ico', component: <ICOList /> },
  { path: '/apps-crypto-kyc', component: <KYCVerification /> },

  //  NFT Marketplace
  { path: '/apps-nft-marketplace', component: <Marketplace /> },
  { path: '/apps-nft-collections', component: <Collections /> },
  { path: '/apps-nft-create', component: <CreateNFT /> },
  { path: '/apps-nft-creators', component: <Creators /> },
  { path: '/apps-nft-explore', component: <ExploreNow /> },
  { path: '/apps-nft-item-details', component: <ItemDetails /> },
  { path: '/apps-nft-auction', component: <LiveAuction /> },
  { path: '/apps-nft-ranking', component: <Ranking /> },
  { path: '/apps-nft-wallet', component: <WalletConnect /> },

  // charts
  { path: '/charts-apex-line', component: <LineCharts /> },
  { path: '/charts-apex-area', component: <AreaCharts /> },
  { path: '/charts-apex-column', component: <ColumnCharts /> },
  { path: '/charts-apex-bar', component: <BarCharts /> },
  { path: '/charts-apex-mixed', component: <MixedCharts /> },
  { path: '/charts-apex-timeline', component: <TimelineCharts /> },
  { path: '/charts-apex-candlestick', component: <CandlestickChart /> },
  { path: '/charts-apex-boxplot', component: <BoxplotCharts /> },
  { path: '/charts-apex-bubble', component: <BubbleChart /> },
  { path: '/charts-apex-scatter', component: <ScatterCharts /> },
  { path: '/charts-apex-heatmap', component: <HeatmapCharts /> },
  { path: '/charts-apex-treemap', component: <TreemapCharts /> },
  { path: '/charts-apex-pie', component: <PieCharts /> },
  { path: '/charts-apex-radialbar', component: <RadialbarCharts /> },
  { path: '/charts-apex-radar', component: <RadarCharts /> },
  { path: '/charts-apex-polar', component: <PolarCharts /> },

  { path: '/charts-chartjs', component: <ChartsJs /> },
  { path: '/charts-echarts', component: <Echarts /> },

  // Base Ui
  { path: '/ui-alerts', component: <UiAlerts /> },
  { path: '/ui-badges', component: <UiBadges /> },
  { path: '/ui-buttons', component: <UiButtons /> },
  { path: '/ui-colors', component: <UiColors /> },
  { path: '/ui-cards', component: <UiCards /> },
  { path: '/ui-carousel', component: <UiCarousel /> },
  { path: '/ui-dropdowns', component: <UiDropdowns /> },
  { path: '/ui-grid', component: <UiGrid /> },
  { path: '/ui-images', component: <UiImages /> },
  { path: '/ui-tabs', component: <UiTabs /> },
  { path: '/ui-accordions', component: <UiAccordions /> },
  { path: '/ui-modals', component: <UiModals /> },
  { path: '/ui-offcanvas', component: <UiOffcanvas /> },
  { path: '/ui-placeholders', component: <UiPlaceholders /> },
  { path: '/ui-progress', component: <UiProgress /> },
  { path: '/ui-notifications', component: <UiNotifications /> },
  { path: '/ui-media', component: <UiMediaobject /> },
  { path: '/ui-embed-video', component: <UiEmbedVideo /> },
  { path: '/ui-typography', component: <UiTypography /> },
  { path: '/ui-lists', component: <UiList /> },
  { path: '/ui-general', component: <UiGeneral /> },
  { path: '/ui-ribbons', component: <UiRibbons /> },
  { path: '/ui-utilities', component: <UiUtilities /> },

  // Advance Ui
  { path: '/advance-ui-nestable', component: <UiNestableList /> },
  { path: '/advance-ui-scrollbar', component: <UiScrollbar /> },
  { path: '/advance-ui-animation', component: <UiAnimation /> },
  { path: '/advance-ui-tour', component: <UiTour /> },
  { path: '/advance-ui-swiper', component: <UiSwiperSlider /> },
  { path: '/advance-ui-ratings', component: <UiRatings /> },
  { path: '/advance-ui-highlight', component: <UiHighlight /> },

  // Widgets
  { path: '/widgets', component: <Widgets /> },

  // Forms
  { path: '/forms-elements', component: <BasicElements /> },
  { path: '/forms-select', component: <FormSelect /> },
  { path: '/forms-editors', component: <FormEditor /> },
  { path: '/forms-checkboxes-radios', component: <CheckBoxAndRadio /> },
  { path: '/forms-masks', component: <Masks /> },
  { path: '/forms-file-uploads', component: <FileUpload /> },
  { path: '/forms-pickers', component: <FormPickers /> },
  { path: '/forms-range-sliders', component: <FormRangeSlider /> },
  { path: '/forms-layouts', component: <Formlayouts /> },
  { path: '/forms-validation', component: <FormValidation /> },
  { path: '/forms-wizard', component: <FormWizard /> },
  { path: '/forms-advanced', component: <FormAdvanced /> },
  { path: '/forms-select2', component: <Select2 /> },

  // Tables
  { path: '/tables-basic', component: <BasicTables /> },
  { path: '/tables-gridjs', component: <GridTables /> },
  { path: '/tables-listjs', component: <ListTables /> },
  { path: '/tables-datatables', component: <DataTables /> },

  // Icons
  { path: '/icons-remix', component: <RemixIcons /> },
  { path: '/icons-boxicons', component: <BoxIcons /> },
  { path: '/icons-materialdesign', component: <MaterialDesign /> },
  { path: '/icons-feather', component: <FeatherIcons /> },
  { path: '/icons-lineawesome', component: <LineAwesomeIcons /> },
  { path: '/icons-crypto', component: <CryptoIcons /> },

  // Maps
  { path: '/maps-google', component: <GoogleMaps /> },
  { path: '/maps-vector', component: <VectorMaps /> },
  { path: '/maps-leaflet', component: <LeafletMaps /> },

  // Pages
  { path: '/pages-starter', component: <Starter /> },
  { path: '/pages-profile', component: <SimplePage /> },
  { path: '/pages-profile-settings', component: <Settings /> },
  { path: '/pages-team', component: <Team /> },
  { path: '/pages-timeline', component: <Timeline /> },
  { path: '/pages-faqs', component: <Faqs /> },
  { path: '/pages-gallery', component: <Gallery /> },
  { path: '/pages-pricing', component: <Pricing /> },
  { path: '/pages-sitemap', component: <SiteMap /> },
  { path: '/pages-search-results', component: <SearchResults /> },

  // Job pages
  { path: '/apps-job-statistics', component: <Statistics /> },
  { path: '/apps-job-lists', component: <JobList /> },
  { path: '/apps-job-grid-lists', component: <JobGrid /> },
  { path: '/apps-job-details', component: <JobOverview /> },
  { path: '/apps-job-candidate-lists', component: <CandidateList /> },
  { path: '/apps-job-candidate-grid', component: <CandidateGrid /> },
  { path: '/apps-job-application', component: <Application /> },
  { path: '/apps-job-new', component: <NewJobs /> },
  { path: '/apps-job-companies-lists', component: <CompaniesList /> },
  { path: '/apps-job-categories', component: <JobCategories /> },

  { path: '/pages-privacy-policy', component: <PrivecyPolicy /> },
  { path: '/pages-terms-condition', component: <TermsCondition /> },

  // User Profile
  { path: '/profile', component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: '/',
    exact: true,
    component: <Navigate to='/dashboard' />
  },
  { path: '*', component: <Basic404 /> }
]

const publicRoutes = [
  // Authentication Page
  { path: '/logout', component: <Logout /> },
  { path: '/login', component: <Login /> },
  { path: '/forgot-password', component: <ForgetPasswordPage /> },
  { path: '/register', component: <Register /> },

  // AuthenticationInner pages
  { path: '/auth-signin-basic', component: <BasicSignIn /> },
  { path: '/auth-signin-cover', component: <CoverSignIn /> },
  { path: '/auth-signup-basic', component: <BasicSignUp /> },
  { path: '/auth-signup-cover', component: <CoverSignUp /> },
  { path: '/auth-pass-reset-basic', component: <BasicPasswReset /> },
  { path: '/auth-pass-reset-cover', component: <CoverPasswReset /> },
  { path: '/auth-lockscreen-basic', component: <BasicLockScreen /> },
  { path: '/auth-lockscreen-cover', component: <CoverLockScreen /> },
  { path: '/auth-logout-basic', component: <BasicLogout /> },
  { path: '/auth-logout-cover', component: <CoverLogout /> },
  { path: '/auth-success-msg-basic', component: <BasicSuccessMsg /> },
  { path: '/auth-success-msg-cover', component: <CoverSuccessMsg /> },
  { path: '/auth-twostep-basic', component: <BasicTwosVerify /> },
  { path: '/auth-twostep-cover', component: <CoverTwosVerify /> },
  { path: '/auth-404-basic', component: <Basic404 /> },
  { path: '/auth-404-cover', component: <Cover404 /> },
  { path: '/auth-404-alt', component: <Alt404 /> },
  { path: '/auth-500', component: <Error500 /> },
  { path: '/pages-maintenance', component: <Maintenance /> },
  { path: '/pages-coming-soon', component: <ComingSoon /> },

  { path: '/landing', component: <OnePage /> },
  { path: '/nft-landing', component: <NFTLanding /> },
  { path: '/job-landing', component: <JobLanding /> },

  { path: '/auth-pass-change-basic', component: <BasicPasswCreate /> },
  { path: '/auth-pass-change-cover', component: <CoverPasswCreate /> },
  { path: '/auth-offline', component: <Offlinepage /> }
]

export { authProtectedRoutes, publicRoutes }
