import { useMutation } from '@apollo/client'
import React, { useEffect } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner
} from 'reactstrap'
import { CLOSE_CREDIT } from '../../../graphql/mutations/creditMutations'
import { toast } from 'react-toastify'
import { parseId } from '../../../helpers'

export const ModalCloseCredit = ({
  showClose,
  toggleClose,
  idCredit,
  refetchDataCredit
}) => {
  const [
    gqlCloseCredit,
    { data: dataClose, loading: loadingClose, error: errorClosex }
  ] = useMutation(CLOSE_CREDIT)

  useEffect(() => {
    if (!dataClose) return
    if (dataClose?.creditClose?.success) {
      toast.success('Crédito cerrado con éxito')
      refetchDataCredit()
      toggleClose()
    } else if (dataClose?.creditClose?.errors) {
      toast.error(
        'ERROR: ' + dataClose?.creditClose?.errors.message ||
          'No se pudo cerrar crédito'
      )
    }
  }, [dataClose])

  useEffect(() => {
    if (!errorClosex) return
    if (errorClosex) {
      toast.error(`ERROR: ${errorClosex.message}`)
    }
  }, [errorClosex])

  const handleCloseCredit = () => {
    if (!idCredit) return
    gqlCloseCredit({
      variables: {
        input: {
          id: parseId(idCredit)
        }
      }
    })
  }

  return (
    <Modal isOpen={showClose} toggle={toggleClose} centered>
      <ModalHeader className='bg-soft-info p-4' toggle={toggleClose}>
        Cerrar crédito
      </ModalHeader>
      <ModalBody>
        <p className='fw-semibold fs-4'>
          ¿Seguro que desea cerrar este crédito?
        </p>
      </ModalBody>
      <ModalFooter className='d-flex align-items-center justify-content-center gap-2'>
        <Button
          color='light'
          className='flex-1 text-center'
          onClick={() => toggleClose()}
        >
          No
        </Button>
        <Button
          color='danger'
          className='flex-1 text-center d-flex gap-1 align-items-center justify-content-center'
          onClick={handleCloseCredit}
        >
          {loadingClose && <Spinner size='sm' color='light' />}
          SI
        </Button>
      </ModalFooter>
    </Modal>
  )
}
