import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Select from 'react-select'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  UncontrolledDropdown
} from 'reactstrap'
// import { Link } from 'react-router-dom'
import {
  GET_COSINGER_REFERENCE,
  GET_FORM_CREDIT,
  GET_REFERENCES_CREDIT
} from '../../../graphql/queries/getCredits'
import { canEditOrAdd, getFormParse, parseId } from '../../../helpers'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  ADD_REFERENCE_CREDIT,
  DELETE_ENTITY,
  EDIT_REFERENCE_CREDIT
} from '../../../graphql/mutations/creditMutations'
import { FormDinamyc } from './FormDinamyc'
import { FormDataView } from './FormDataView'
import { ALL_PERMISSIONS, ENTITIES_TO_DELETE } from '../../../helpers/consts'
import { useGetUser } from '../../../hooks/user'

const COLORS_REFERENCES = {
  PERSONAL: 'danger',
  FAMILIAR: 'info',
  LABORAL: 'warning'
}

const validationSchema = Yup.object().shape({
  referenceType: Yup.string()
    .oneOf(['LABORAL', 'PERSONAL', 'FAMILIAR'])
    .required('Este campo es requerido'),
  address: Yup.string().required('Dirección es requerida'),
  fullName: Yup.string().required('Primer nombre es requerido'),
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, 'El número de documento debe contener solo números')
    .max('10')
    .required('Número de teléfono es requerido'),
  referenceOf: Yup.string()
    .oneOf(['titular', 'codeudor'])
    .required('Seleccione a quíen pertenece esta referencia'),
  cosignerId: Yup.string().when('referenceOf', {
    is: 'codeudor',
    then: (schema) => schema.required('Este campo es requerido')
  })
})

export const TabReferences = ({ idCredit, refetchCredit, statusCredit }) => {
  const [
    gqlAddReference,
    {
      data: dataAddReference,
      loading: loadingAddReference,
      error: errorAddReference
    }
  ] = useMutation(ADD_REFERENCE_CREDIT)
  const [
    gqlEditReference,
    {
      data: dataEditReference,
      loading: loadingEditReference,
      error: errorEditReference
    }
  ] = useMutation(EDIT_REFERENCE_CREDIT)

  const [
    gqlDeleteEntity,
    {
      data: dataDeleteEntity,
      loading: loadingDeleteEntity,
      error: errorDeleteEntity
    }
  ] = useMutation(DELETE_ENTITY)
  const { data, loading, error, refetch } = useQuery(GET_REFERENCES_CREDIT, {
    variables: {
      creditId: parseId(idCredit)
    },
    fetchPolicy: 'no-cache'
  })
  const [
    gqlGetForm,
    { data: dataForm, loading: loadingForm, error: errorForm }
  ] = useLazyQuery(GET_FORM_CREDIT, { fetchPolicy: 'no-cache' })

  const [
    gqlGetCosingers,
    { data: dataCosinger, loading: loadingCosinger, error: errorCosinger }
  ] = useLazyQuery(GET_COSINGER_REFERENCE, {
    fetchPolicy: 'no-cache'
  })

  const { permissions } = useGetUser()

  const [showAddReference, setShowAddReference] = useState(false)
  const [namtrikForm, setNamtrikForm] = useState(null)
  const [formDynamicValues, setFormDynamicValues] = useState(null)
  const [infoReferenceForm, setInfoReferenceForm] = useState(null)

  const [infoEdit, setInfoEdit] = useState({
    isEdit: false,
    id: null
  })

  const [infoDelete, setInfoDelete] = useState({
    isDelete: false,
    id: null,
    name: ''
  })

  const [optionsCosinger, setOptionsCosinger] = useState([])

  const formik = useFormik({
    initialValues: {
      referenceType: '',
      fullName: '',
      address: '',
      phoneNumber: '',
      referenceOf: '',
      cosignerId: ''
    },
    validationSchema,
    onSubmit: (values) => {
      // Aquí puedes manejar la lógica de envío del formulario
      const input = { ...values }
      const cosignerId = input.cosignerId
      if (formDynamicValues) {
        input.form = { forms: formDynamicValues }
      }
      const creditId = parseId(idCredit)
      delete input.referenceOf
      delete input.cosignerId

      console.log('input', input)
      if (infoEdit.isEdit) {
        const idEdit = parseId(infoEdit.id)
        gqlEditReference({
          variables: {
            input: {
              creditId,
              id: idEdit,
              cosignerId: cosignerId || undefined,
              ...input
            }
          }
        })
        return
      }
      gqlAddReference({
        variables: {
          input: {
            creditId,
            cosignerId: cosignerId || undefined,
            references: [input]
          }
        }
      })
    }
  })

  useEffect(() => {
    if (!idCredit) return
    if (showAddReference) {
      gqlGetCosingers({
        variables: {
          credit_Id: parseId(idCredit)
        }
      })
    }
  }, [showAddReference, idCredit])

  useEffect(() => {
    if (error) {
      toast.error('Error: No se pudo cargar referencias')
    }
  }, [error])

  useEffect(() => {
    if (!dataForm) return
    if (dataForm.getEntityForms && dataForm.getEntityForms.length > 0) {
      // const parse = JSON.parse(dataForm.getEntiyForm)
      setNamtrikForm(dataForm.getEntityForms.map((str) => JSON.parse(str)))
    }
  }, [dataForm])

  useEffect(() => {
    if (errorForm) {
      toast.error('Error: ' + errorForm.message)
    }
  }, [errorForm])
  useEffect(() => {
    if (errorCosinger) {
      toast.error('Error: ' + errorCosinger.message)
    }
  }, [errorCosinger])

  useEffect(() => {
    if (errorAddReference) {
      toast.error('Error: ' + errorAddReference.message)
    }
  }, [errorAddReference])

  useEffect(() => {
    if (!dataAddReference) return
    if (dataAddReference.creditAddReferences?.success) {
      toast.success('Referencia creada correctamente')
      formik.resetForm()
      setShowAddReference(false)
      refetchCredit()
      refetch()
    }
  }, [dataAddReference])

  useEffect(() => {
    if (!dataDeleteEntity) return
    if (dataDeleteEntity.creditDeleteInformation?.success) {
      toast.success('Referencia eliminada correctamente')
      setInfoDelete({
        id: null,
        isDelete: false,
        name: ''
      })
      refetch()
    } else if (dataDeleteEntity.creditDeleteInformation?.errors?.message) {
      toast.error(
        `Error: ${dataDeleteEntity.creditDeleteInformation?.errors?.message}`
      )
    }
  }, [dataDeleteEntity])

  useEffect(() => {
    if (errorDeleteEntity) {
      toast.error('Error: ' + errorDeleteEntity.message)
    }
  }, [errorDeleteEntity])

  useEffect(() => {
    if (errorEditReference) {
      toast.error('Error: ' + errorEditReference.message)
    }
  }, [errorEditReference])

  useEffect(() => {
    if (!dataEditReference) return
    if (dataEditReference.creditReferenceUpdate?.success) {
      toast.success('Referencia actualizada correctamente')
      formik.resetForm()
      setShowAddReference(false)
      refetchCredit()
      refetch()
      setInfoEdit({
        id: null,
        isEdit: false
      })
    } else if (dataEditReference.creditReferenceUpdate.errors) {
      toast.error(
        'Error: ' + dataEditReference.creditReferenceUpdate?.errors?.message ||
          'No se pudo crear'
      )
    }
  }, [dataEditReference])

  useEffect(() => {
    if (!dataCosinger) return
    const arr = dataCosinger.getCosigners.edges.map(({ node }) => ({
      label: `${node.firstName} ${node.secondName} ${node.firstSurname} ${node.secondSurname}`,
      value: parseId(node.id),
      color: '#5243AA'
    }))
    setOptionsCosinger(arr)
  }, [dataCosinger])

  const toggleShowAddReference = () => {
    setShowAddReference((p) => !p)
  }

  const toggleDelete = () => {
    infoDelete.isDelete &&
      setInfoDelete({
        id: null,
        isDelete: false,
        name: ''
      })
  }

  if (loading || data == null) {
    return (
      <Card>
        <CardBody
          className='d-flex align-items-center justify-content-center'
          style={{ height: '30vh' }}
        >
          <Spinner style={{ width: '3em', height: '3em' }} />
        </CardBody>
      </Card>
    )
  }
  /** @type {{node:{fullName: string, formData: string, address: string, id: string, phoneNumber: string, referenceType: string, cosigner: {id:string,firstName: string,secondName: string,firstSurname: string,secondSurname: string } | null}}[]} */
  const references = data.getReferences?.edges || []

  const referenceTitular = references.filter(
    ({ node }) => node.cosigner == null
  )
  const referenceCosingers = references.filter(({ node }) =>
    Boolean(node.cosigner)
  )

  return (
    <>
      <Card>
        <CardHeader className='d-flex justify-content-between align-items-center'>
          <h5>Referencias</h5>
          {canEditOrAdd({
            isAdd: true,
            permissionAdmin: ALL_PERMISSIONS.PUEDE_EDITAR_TODO_CREDITO,
            permissions,
            permissionToAdd: ALL_PERMISSIONS.PUEDE_AGREGAR_REFERENCIA,
            statusCredit
          }) && (
            <Button
              color='success'
              onClick={() => {
                setShowAddReference(true)
                setNamtrikForm(null)
                gqlGetForm({ variables: { codename: 'Reference' } })
              }}
            >
              Agregar referencia
            </Button>
          )}
        </CardHeader>
        <CardBody>
          <Row>
            {references.length === 0 ? (
              <div
                className='d-flex align-items-center justify-content-center'
                style={{ height: '30vh' }}
              >
                No hay referencias registradas
              </div>
            ) : (
              <>
                {referenceTitular.length > 0 && (
                  <Col lg={12}>
                    <h6 className='text-center my-4'>
                      Referencias del titular
                    </h6>
                  </Col>
                )}
                {referenceTitular.map(({ node }) => (
                  <Col xxl={3} sm={6} key={node.id}>
                    <Card
                      className={`profile-project-card shadow-none profile-project-${
                        COLORS_REFERENCES[node.referenceType]
                      }`}
                    >
                      <CardBody className='p-4'>
                        <div className='d-flex'>
                          <div className='flex-grow-1 text-muted overflow-hidden'>
                            <h5 className='fs-14 text-truncate'>
                              <div className='text-dark'>
                                {node.fullName.toUpperCase()}
                              </div>
                            </h5>
                            <p className='text-muted text-truncate mb-0'>
                              Teléfono:{' '}
                              <span className='fw-semibold text-dark'>
                                {node.phoneNumber}
                              </span>
                            </p>
                            <p className='text-muted text-truncate mb-0'>
                              Dirección:{' '}
                              <span className='fw-semibold text-dark'>
                                {node.address}
                              </span>
                            </p>
                          </div>
                          <div className='flex-shrink-0 ms-2 d-flex flex-column justify-content-between align-items-center'>
                            <div
                              className={`badge badge-soft-${
                                COLORS_REFERENCES[node.referenceType]
                              } fs-10`}
                            >
                              {node.referenceType}
                            </div>
                            {/* {node.formData && (
                            <div>
                              <button
                                className='border-0 p-0 m-0 bg-transparent'
                                onClick={
                                  () =>
                                    setInfoReferenceForm({
                                      name: node.fullName,
                                      type: node.referenceType,
                                      form: node.formData
                                    })
                                  // eslint-disable-next-line react/jsx-curly-newline
                                }
                                title='Ver información extra'
                              >
                                <i className='mdi mdi-eye-plus-outline fs-4' />
                              </button>
                            </div>
                          )} */}
                            <UncontrolledDropdown>
                              <DropdownToggle
                                // href='#'
                                className='btn btn-soft-secondary btn-sm'
                                tag='button'
                              >
                                <i className='ri-more-fill' />
                              </DropdownToggle>
                              <DropdownMenu className='dropdown-menu-end'>
                                {node.formData && (
                                  <DropdownItem
                                    // href='apps-ecommerce-product-details'
                                    onClick={
                                      () =>
                                        setInfoReferenceForm({
                                          name: node.fullName,
                                          type: node.referenceType,
                                          form: node.formData
                                        })
                                      // eslint-disable-next-line react/jsx-curly-newline
                                    }
                                  >
                                    <i className='ri-eye-fill align-bottom me-2 text-muted' />{' '}
                                    Ver más
                                  </DropdownItem>
                                )}

                                {canEditOrAdd({
                                  isAdd: false,
                                  permissionAdmin:
                                    ALL_PERMISSIONS.PUEDE_EDITAR_TODO_CREDITO,
                                  permissions,
                                  permissionToEdit:
                                    ALL_PERMISSIONS.PUEDE_CAMBIAR_REFERENCIA,
                                  statusCredit
                                }) && (
                                  <DropdownItem
                                    onClick={() => {
                                      setShowAddReference(true)
                                      setInfoEdit({
                                        id: node.id,
                                        isEdit: true
                                      })
                                      setNamtrikForm(JSON.parse(node.formData))
                                      formik.setValues({
                                        address: node.address,
                                        fullName: node.fullName,
                                        phoneNumber: node.phoneNumber,
                                        referenceType: node.referenceType,
                                        referenceOf: 'titular',
                                        cosignerId: ''
                                      })
                                    }}
                                  >
                                    <i className='ri-pencil-fill align-bottom me-2 text-muted' />{' '}
                                    Editar
                                  </DropdownItem>
                                )}

                                {canEditOrAdd({
                                  isAdd: false,
                                  permissionAdmin:
                                    ALL_PERMISSIONS.PUEDE_ELIMINAR_ENTIDAD_ADMIN,
                                  permissions,
                                  permissionToEdit:
                                    ALL_PERMISSIONS.PUEDE_ELIMINAR_ENTIDAD,
                                  statusCredit
                                }) && (
                                  <>
                                    <DropdownItem divider />
                                    <DropdownItem
                                      onClick={() => {
                                        setInfoDelete({
                                          isDelete: true,
                                          name: node.fullName,
                                          id: node.id
                                        })
                                      }}
                                    >
                                      <i className='ri-delete-bin-fill align-bottom me-2 text-muted' />{' '}
                                      Eliminar
                                    </DropdownItem>
                                  </>
                                )}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
                {referenceCosingers.length > 0 && (
                  <Col lg={12}>
                    <h6 className='text-center my-4'>
                      Referencias de codeudores
                    </h6>
                  </Col>
                )}
                {referenceCosingers.map(({ node }) => (
                  <Col xxl={3} sm={6} key={node.id}>
                    <Card
                      className={`profile-project-card shadow-none profile-project-${
                        COLORS_REFERENCES[node.referenceType]
                      }`}
                    >
                      <CardHeader>
                        Codeudor:
                        <span className='fw-bold'>
                          {' '}
                          {node.cosigner.firstName} {node.cosigner.secondName}{' '}
                          {node.cosigner.firstSurname}{' '}
                          {node.cosigner.secondSurname}
                        </span>
                      </CardHeader>
                      <CardBody className='p-4'>
                        <div className='d-flex'>
                          <div className='flex-grow-1 text-muted overflow-hidden'>
                            <h5 className='fs-14 text-truncate'>
                              <div className='text-dark'>
                                {node.fullName.toUpperCase()}
                              </div>
                            </h5>
                            <p className='text-muted text-truncate mb-0'>
                              Teléfono:{' '}
                              <span className='fw-semibold text-dark'>
                                {node.phoneNumber}
                              </span>
                            </p>
                            <p className='text-muted text-truncate mb-0'>
                              Dirección:{' '}
                              <span className='fw-semibold text-dark'>
                                {node.address}
                              </span>
                            </p>
                          </div>
                          <div className='flex-shrink-0 ms-2 d-flex flex-column justify-content-between align-items-center'>
                            <div
                              className={`badge badge-soft-${
                                COLORS_REFERENCES[node.referenceType]
                              } fs-10`}
                            >
                              {node.referenceType}
                            </div>
                            {/* {node.formData && (
                            <div>
                              <button
                                className='border-0 p-0 m-0 bg-transparent'
                                onClick={
                                  () =>
                                    setInfoReferenceForm({
                                      name: node.fullName,
                                      type: node.referenceType,
                                      form: node.formData
                                    })
                                  // eslint-disable-next-line react/jsx-curly-newline
                                }
                                title='Ver información extra'
                              >
                                <i className='mdi mdi-eye-plus-outline fs-4' />
                              </button>
                            </div>
                          )} */}
                            <UncontrolledDropdown>
                              <DropdownToggle
                                // href='#'
                                className='btn btn-soft-secondary btn-sm'
                                tag='button'
                              >
                                <i className='ri-more-fill' />
                              </DropdownToggle>
                              <DropdownMenu className='dropdown-menu-end'>
                                {node.formData && (
                                  <DropdownItem
                                    // href='apps-ecommerce-product-details'
                                    onClick={
                                      () =>
                                        setInfoReferenceForm({
                                          name: node.fullName,
                                          type: node.referenceType,
                                          form: node.formData
                                        })
                                      // eslint-disable-next-line react/jsx-curly-newline
                                    }
                                  >
                                    <i className='ri-eye-fill align-bottom me-2 text-muted' />{' '}
                                    Ver más
                                  </DropdownItem>
                                )}

                                {canEditOrAdd({
                                  isAdd: false,
                                  permissionAdmin:
                                    ALL_PERMISSIONS.PUEDE_EDITAR_TODO_CREDITO,
                                  permissions,
                                  permissionToEdit:
                                    ALL_PERMISSIONS.PUEDE_CAMBIAR_REFERENCIA,
                                  statusCredit
                                }) && (
                                  <DropdownItem
                                    onClick={() => {
                                      setShowAddReference(true)
                                      setInfoEdit({
                                        id: node.id,
                                        isEdit: true
                                      })
                                      setNamtrikForm(JSON.parse(node.formData))
                                      formik.setValues({
                                        address: node.address,
                                        fullName: node.fullName,
                                        phoneNumber: node.phoneNumber,
                                        referenceType: node.referenceType,
                                        referenceOf: 'codeudor',
                                        cosignerId: parseId(node.cosigner.id)
                                      })
                                    }}
                                  >
                                    <i className='ri-pencil-fill align-bottom me-2 text-muted' />{' '}
                                    Editar
                                  </DropdownItem>
                                )}

                                {canEditOrAdd({
                                  isAdd: false,
                                  permissionAdmin:
                                    ALL_PERMISSIONS.PUEDE_ELIMINAR_ENTIDAD_ADMIN,
                                  permissions,
                                  permissionToEdit:
                                    ALL_PERMISSIONS.PUEDE_ELIMINAR_ENTIDAD,
                                  statusCredit
                                }) && (
                                  <>
                                    <DropdownItem divider />
                                    <DropdownItem
                                      onClick={() => {
                                        setInfoDelete({
                                          isDelete: true,
                                          name: node.fullName,
                                          id: node.id
                                        })
                                      }}
                                    >
                                      <i className='ri-delete-bin-fill align-bottom me-2 text-muted' />{' '}
                                      Eliminar
                                    </DropdownItem>
                                  </>
                                )}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </>
            )}
          </Row>
        </CardBody>
      </Card>

      <Modal isOpen={showAddReference} size='lg'>
        <ModalHeader
          toggle={toggleShowAddReference}
          className='bg-soft-info p-4'
        >
          Agregar referencia{' '}
          {(loadingForm || loadingCosinger) && (
            <Spinner size='sm' className='ms-2' />
          )}
        </ModalHeader>
        <form
          autoComplete='off'
          onSubmit={(e) => {
            e.preventDefault()
            const values = Object.fromEntries(new FormData(e.target))
            console.log('values', values)
            const arrayFinal = getFormParse(values)
            if (arrayFinal.length > 0) {
              setFormDynamicValues(arrayFinal)
            }
            formik.submitForm()
          }}
        >
          <ModalBody>
            <Row>
              <Col lg={12}>
                <FormGroup>
                  <Label htmlFor='fullName-Reference'>
                    Nombre completo
                    <span
                      style={{ color: 'red', fontSize: '0.75em' }}
                      title='Requerido'
                    >
                      *
                    </span>
                  </Label>
                  <Input
                    id='fullName-Reference'
                    name='fullName'
                    placeholder='Ingrese nombre completo'
                    value={formik.values.fullName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.fullName && formik.errors.fullName
                    )}
                  />
                  {formik.touched.fullName && formik.errors.fullName && (
                    <FormFeedback>{formik.errors.fullName}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col lg={12}>
                <FormGroup>
                  <Label htmlFor='address-Reference'>
                    Dirección
                    <span
                      style={{ color: 'red', fontSize: '0.75em' }}
                      title='Requerido'
                    >
                      *
                    </span>
                  </Label>
                  <Input
                    id='address-Reference'
                    name='address'
                    placeholder='Ingrese dirección'
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.address && formik.errors.address
                    )}
                  />
                  {formik.touched.address && formik.errors.address && (
                    <FormFeedback>{formik.errors.address}</FormFeedback>
                  )}
                </FormGroup>
              </Col>

              <Col lg={6}>
                <FormGroup>
                  <Label htmlFor='referenceType-Reference'>
                    Tipo de referencia
                    <span
                      style={{ color: 'red', fontSize: '0.75em' }}
                      title='Requerido'
                    >
                      *
                    </span>
                  </Label>
                  <Input
                    id='referenceType-Reference'
                    name='referenceType'
                    type='select'
                    placeholder='Ingrese apellido'
                    value={formik.values.referenceType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.referenceType &&
                        formik.errors.referenceType
                    )}
                  >
                    <option value=''>Seleccione un tipo</option>

                    <option value='LABORAL'>Laboral</option>
                    <option value='PERSONAL'>Personal</option>
                    <option value='FAMILIAR'>Familiar</option>
                  </Input>
                  {formik.touched.referenceType &&
                    formik.errors.referenceType && (
                      <FormFeedback>{formik.errors.referenceType}</FormFeedback>
                    )}
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Label htmlFor='documentNumber-Reference'>
                    Teléfono
                    <span
                      style={{ color: 'red', fontSize: '0.75em' }}
                      title='Requerido'
                    >
                      *
                    </span>
                  </Label>
                  <Input
                    id='phoneNumber-Reference'
                    name='phoneNumber'
                    placeholder='Ingrese número de teléfono'
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    )}
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                    <FormFeedback>{formik.errors.phoneNumber}</FormFeedback>
                  )}
                </FormGroup>
              </Col>

              <Col lg={12}>
                <FormGroup>
                  <Label htmlFor='referenceOf-Reference'>
                    ¿A quíen pertenece esta referencia?
                    <span
                      style={{ color: 'red', fontSize: '0.75em' }}
                      title='Requerido'
                    >
                      *
                    </span>
                  </Label>
                  <Input
                    id='referenceOf-Reference'
                    name='referenceOf'
                    type='select'
                    placeholder='Seleccione uno'
                    value={formik.values.referenceOf}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.referenceOf && formik.errors.referenceOf
                    )}
                  >
                    <option value=''>Seleccione un tipo</option>

                    <option value='titular'>Titular</option>
                    <option value='codeudor'>Codeudor</option>
                  </Input>
                  {formik.touched.referenceOf && formik.errors.referenceOf && (
                    <FormFeedback>{formik.errors.referenceOf}</FormFeedback>
                  )}
                </FormGroup>
              </Col>

              {formik.values.referenceOf === 'codeudor' && (
                <Col lg={12}>
                  <FormGroup>
                    <Label htmlFor='cosignerId-field' className='form-label'>
                      Codeudor
                      <span style={{ color: 'red' }} title='Obligatorio'>
                        *
                      </span>
                    </Label>
                    <Select
                      className='basic-single'
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused
                            ? 'grey'
                            : formik.touched.cosignerId &&
                              formik.errors.cosignerId
                            ? 'red'
                            : '#ced4da'
                        })
                      }}
                      placeholder='Seleccione el codeudor'
                      classNamePrefix='select'
                      isClearable
                      isSearchable
                      noOptionsMessage={() => {
                        return 'No hay codeudores para esta referencia'
                      }}
                      name='cosignerId'
                      onChange={(e) => {
                        if (!e) {
                          return formik.setFieldValue('cosignerId', '')
                        }
                        formik.setFieldValue('cosignerId', e.value)
                      }}
                      onBlur={() => {
                        formik.setFieldTouched('cosignerId', true)
                      }}
                      options={optionsCosinger}
                    />
                    {formik.touched.cosignerId && formik.errors.cosignerId && (
                      <div
                        style={{
                          width: '100%',
                          marginTop: '0.25rem',
                          fontSize: '0.875em',
                          color: '#f06548'
                        }}
                      >
                        {formik.errors.cosignerId}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              )}

              {namtrikForm && <FormDinamyc namtrikForm={namtrikForm} />}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color='light'
              type='button'
              onClick={() => {
                setShowAddReference(false)
                formik.resetForm()
                setInfoEdit({
                  id: null,
                  isEdit: false
                })
              }}
            >
              Cancelar
            </Button>
            <Button
              type='submit'
              disabled={loadingAddReference || loadingEditReference}
              className='d-flex align-items-center gap-1'
            >
              {loadingAddReference && <Spinner size='sm' color='light' />}
              {loadingEditReference && <Spinner size='sm' color='light' />}
              {infoEdit.isEdit ? 'Editar' : 'Agregar'}
            </Button>
          </ModalFooter>
        </form>
      </Modal>

      <Modal
        isOpen={Boolean(infoReferenceForm)}
        toggle={() => setInfoReferenceForm(null)}
      >
        <ModalHeader
          toggle={() => setInfoReferenceForm(null)}
          className='bg-soft-info p-4'
        >
          Información extra
        </ModalHeader>
        <ModalBody>
          <h6>
            {infoReferenceForm?.type}: {infoReferenceForm?.name?.split(' ')[0]}
          </h6>
          {infoReferenceForm && (
            <div className='p-2'>
              <FormDataView data={infoReferenceForm.form} />
            </div>
          )}
        </ModalBody>
      </Modal>

      <Modal isOpen={infoDelete.isDelete} toggle={toggleDelete} centered>
        <ModalBody>
          <h4>
            ¿Seguro desea eliminar a{' '}
            <span className='text-info text-uppercase'>{infoDelete.name}</span>{' '}
            como referencia?
          </h4>
        </ModalBody>
        <div className='p-2 m-2 d-flex gap-1'>
          <Button
            color='light'
            block
            onClick={() => {
              setInfoDelete({ id: null, isDelete: false, name: '' })
            }}
            disabled={loadingDeleteEntity}
          >
            No
          </Button>
          <Button
            color='danger'
            block
            disabled={loadingDeleteEntity}
            onClick={() => {
              if (!infoDelete.id) return
              const idDelete = parseId(infoDelete.id)
              const input = {
                entity: ENTITIES_TO_DELETE.REFERENCIA,
                id: idDelete
              }
              gqlDeleteEntity({
                variables: { input }
              })
            }}
          >
            <span className='d-flex align-items-center gap-1 justify-content-center'>
              {loadingDeleteEntity ? (
                <Spinner size='sm' color='light' />
              ) : (
                <i className='fs-4 mdi mdi-trash-can-outline' />
              )}
              Si
            </span>
          </Button>
        </div>
      </Modal>
    </>
  )
}
