/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react'
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom'
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  /* DropdownItem,
  DropdownMenu,
  DropdownToggle, */
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  // Progress,
  Row,
  Spinner,
  TabContent,
  Table,
  TabPane
  // UncontrolledDropdown
} from 'reactstrap'
import classnames from 'classnames'
import SwiperCore, { Autoplay } from 'swiper'

// Images
import profileBg from '../../../assets/images/bg-one-user.png'
import imgNoCupo from '../../../assets/images/no-data-cupo.png'
// import profileBg from '../../../assets/images/profile-bg.jpg'
// import avatar1 from '../../../assets/images/users/avatar-1.jpg'
// import avatar8 from '../../../assets/images/users/avatar-8.jpg'

// import { document as documentData } from '../../../common/data'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  CHECK_VIEW_CREDIT,
  GET_FORM_DATA,
  GET_LIST_TABS,
  GET_ONE_CREDIT
} from '../../../graphql/queries/getCredits'
import { useGetUser } from '../../../hooks/user'
import {
  canEditOrAdd,
  getColorLineCredit,
  hasPermission,
  isValidUUID,
  parseId,
  parsePrice
} from '../../../helpers'
import {
  ALL_PERMISSIONS,
  CREDIT_TYPES,
  ENTITIES_TABS_OBJ,
  ERROR_MESSAGES,
  STATUS_CREDITS_OBJ
} from '../../../helpers/consts'
import { toast } from 'react-toastify'
import moment from 'moment'
import Select from 'react-select'
import {
  CHANGE_CREDIT_TO_REVITION,
  UNLOCK_ENTITY,
  VIEW_REQUEST_LINK_CREDIT
} from '../../../graphql/mutations/creditMutations'
import { StatusOneCredit } from '../../../Components/Own/StatusOneCredit'
/* import {
  MAX_SIZE,
  TableCommentCredit
} from '../../../Components/Own/TableCommentCredit'
import { useMutationFetch } from '../../../hooks/useMutationFetch' */
import { TabCosinger } from '../../../Components/Own/creditPage/TabCosinger'
import { TabProductInfo } from '../../../Components/Own/creditPage/TabProductInfo'
import { TabReferences } from '../../../Components/Own/creditPage/TabReferences'
import { TabSpouse } from '../../../Components/Own/creditPage/TabSpuse'
import { LoadingPage } from '../../../Components/Own/creditPage/LoadingPage'
import { ModalChangeStatus } from '../../../Components/Own/creditPage/ModalChangeStatus'
import { FormDataView } from '../../../Components/Own/creditPage/FormDataView'
import { SwiperSlide, Swiper } from 'swiper/react'
import { ModalCloseCredit } from '../../../Components/Own/creditPage/ModalCloseCredit'
import { TabDocuments } from '../../../Components/Own/creditPage/TabDocuments'
import { ModalReqDisbursement } from '../../../Components/Own/creditPage/ModalReqDisbursement'
import { ModalEditInfo } from '../../../Components/Own/creditPage/ModalEditInfo'
import { API } from '../../../graphql/client'
import { CardCentrales } from '../../../Components/Own/creditPage/CardCentrales'
import { TabSuports } from '../../../Components/Own/creditPage/TabSuports'
import { useDisclosure } from '../../../hooks/useDisclosure'

import { loadAnimation } from 'lottie-web'
import { defineElement } from 'lord-icon-element'
import { TabComments } from '../../../Components/Own/creditPage/TabComments'
import { ModalDesitirCredit } from '../../../Components/Own/creditPage/ModalDesitirCredit'
import { ModalChangeTasa } from '../../../Components/Own/creditPage/ModalChangeTasa'
import { ModalChangeTrad } from '../../../Components/Own/creditPage/ModalChangeTrad'
import { ModalAddComment } from '../../../Components/Own/creditPage/ModalAddComment'
import { BadgeDisbursements } from '../../../Components/Own/Disbursement/BadgeDisbursements'
import { BadgeQuotation } from '../../../Components/Own/quotations/BadgeQuotation'
import { ModalChangeOldData } from '../../../Components/Own/creditPage/ModalChangeOldData'
import { ModalChangePending } from '../../../Components/Own/creditPage/ModalChangePending'

// register lottie and define custom element
defineElement(loadAnimation)

/* const INIT_OPTION_CHANGE_ENTITIES = [
  {
    value: 'INFORMACION_PRODUCTO',
    label: 'Información de producto',
    color: '#0052CC'
  },
  {
    value: 'CODEUDOR',
    label: 'Codeudor',
    color: '#0052CC'
  },
  {
    value: 'REFERENCIA',
    label: 'Referencia',
    color: '#0052CC'
  },
  {
    value: 'CONYUGE',
    label: 'Cónyuge',
    color: '#0052CC'
  }
] */

export default function OneCreditPage() {
  const [
    gqlUnlockEntity,
    { data: dataUnlock, loading: loadingUnlock, error: errorUnlock }
  ] = useMutation(UNLOCK_ENTITY)
  const [
    gqlChangeRevision,
    { data: dataRevition, loading: loadingRevition, error: errorRevition }
  ] = useMutation(CHANGE_CREDIT_TO_REVITION)
  const [
    qglViewRequestLink,
    { data: dataLink, loading: loadingLink, error: errorLink }
  ] = useMutation(VIEW_REQUEST_LINK_CREDIT)

  const [gqlGetCredit, { data, loading, error, refetch: refetchDataCredit }] =
    useLazyQuery(GET_ONE_CREDIT, { fetchPolicy: 'no-cache' })

  const [
    gqlCheck,
    { data: dataCheck, loading: loadingCheck, error: errorCheck }
  ] = useLazyQuery(CHECK_VIEW_CREDIT)

  const [
    gqlGetTabs,
    { data: dataTabs, loading: loadingTabs, error: errorTabs, refetch }
  ] = useLazyQuery(GET_LIST_TABS, { fetchPolicy: 'no-cache' })
  const [
    gqlGetFormData,
    { data: dataFormData, loading: loadingFormData, error: errorFormData }
  ] = useLazyQuery(GET_FORM_DATA, { fetchPolicy: 'no-cache' })

  const param = useParams()
  const [searchParam] = useSearchParams()

  const id = param['*'] ? param['*'] : searchParam.get('uuid')
  // console.log('rest', rest)
  const navigate = useNavigate()
  const { permissions, logout, user } = useGetUser()
  // console.log('id param', param)
  SwiperCore.use([Autoplay])

  const { isOpen, onClose, onOpen } = useDisclosure()

  const [optionsChangeEntities, setOptionsChangeEntities] = useState([])
  // const [isCopy, setIsCopy] = useState(false)
  const [activeTab, setActiveTab] = useState('1')
  const [showEntity, setShowEntity] = useState(false)

  const [isViewMore, setIsViewMore] = useState(false)
  const [selectEntities, setSelectEntities] = useState(null)

  const [showComment, setShowComment] = useState(false)

  const [isOpenEdit, setIsOpenEdit] = useState(false)

  const [showChangeStatus, setShowChangeStatus] = useState(false)
  const [showChangeRevition, setShowChangeRevition] = useState(false)

  const [showClose, setShowClose] = useState(false)
  const [showReqDisbur, setShowReqDisbur] = useState(false)

  const [showEditInfo, setShowEditInfo] = useState(false)

  const [visibleAlertPreap, setVisibleAlertPreap] = useState(true)
  const [visibleAlertIncom, setVisibleAlertIncom] = useState(true)
  const [visibleAlertOldData, setVisibleAlertOldData] = useState(true)
  const [visibleAlertPend, setVisibleAlertPend] = useState(true) // pasa a incompleto
  const [visibleAlertNoCodeu, setVisibleAlertNoCodeu] = useState(true)

  const [visibleAlertChangeTrad, setVisibleAlertChangeTrad] = useState(true)

  const [loadingDown, setLoadingDown] = useState(false)

  const [infoTasa, setInfoTasa] = useState(null)
  // const [isOneBigger, setIsOneBigger] = useState(false)
  const {
    isOpen: isOpenDesistir,
    onClose: onCloseDesistir,
    onOpen: onOpenDesistir
  } = useDisclosure()

  const {
    isOpen: isOpenTasa,
    onClose: onCloseTasa,
    onOpen: onOpenTasa
  } = useDisclosure()

  const {
    isOpen: isOpenChangeTrad,
    onClose: onCloseChangeTrad,
    onOpen: onOpenChangeTrad
  } = useDisclosure()

  const {
    isOpen: isOpenOldData,
    onClose: onCloseOldData,
    onOpen: onOpenOldData
  } = useDisclosure()
  const {
    isOpen: isOpenChangePending,
    onClose: onCloseChangePending,
    onOpen: onOpenChangePending
  } = useDisclosure() // pasa a incompleto

  useEffect(() => {
    if (!id) {
      return navigate('/')
    }
    if (Number(id)) {
      gqlGetCredit({
        variables: {
          creditId: id
        }
      })
      return
    }

    if (isValidUUID(id)) {
      gqlGetCredit({
        variables: {
          uuid: id
        }
      })
    } else {
      return navigate('/')
    }
  }, [id])

  useEffect(() => {
    if (!data) return
    if (data.getCredit?.id) {
      gqlCheck({
        variables: {
          creditId: parseId(data.getCredit?.id)
        }
      })

      gqlGetTabs({
        variables: {
          creditId: parseId(data.getCredit.id)
        }
      })
      // if (data.getCredit.creditType === CREDIT_TYPES.TRADICIONAL) {
      gqlGetFormData({
        variables: {
          codename: 'Credit',
          id: parseId(data.getCredit.id)
        }
      })
      // }
    }
  }, [data])

  useEffect(() => {
    if (!hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_CREDITOS)) {
      navigate('/')
    }
  }, [permissions])

  useEffect(() => {
    if (error) {
      toast.error(`ERROR: ${error.message}`)
      if (error.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [error])

  useEffect(() => {
    if (!dataTabs) return
    if (dataTabs?.entityListLocked) {
      const newArr = dataTabs.entityListLocked.map(({ entity, locked }) => {
        if (
          ENTITIES_TABS_OBJ[entity] === ENTITIES_TABS_OBJ.ProductInformation
        ) {
          return {
            value: 'INFORMACION_PRODUCTO',
            label: 'Información de producto',
            color: '#0052CC',
            isDisabled: !locked
          }
        }
        if (ENTITIES_TABS_OBJ[entity] === ENTITIES_TABS_OBJ.CoSigner) {
          return {
            value: 'CODEUDOR',
            label: 'Codeudor',
            color: '#0052CC',
            isDisabled: !locked
          }
        }
        if (ENTITIES_TABS_OBJ[entity] === ENTITIES_TABS_OBJ.Reference) {
          return {
            value: 'REFERENCIA',
            label: 'Referencia',
            color: '#0052CC',
            isDisabled: !locked
          }
        }
        return {
          value: 'CONYUGE',
          label: 'Cónyuge',
          color: '#0052CC',
          isDisabled: !locked
        }
      })
      setOptionsChangeEntities(newArr)
    }
  }, [dataTabs])

  useEffect(() => {
    if (errorTabs) {
      toast.error(`ERROR: ${errorTabs.message}`)
      if (errorTabs.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorTabs])

  useEffect(() => {
    if (errorUnlock) {
      toast.error(`ERROR: ${errorUnlock.message}`)
      if (errorUnlock.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorUnlock])

  useEffect(() => {
    if (!dataUnlock) return
    if (dataUnlock.creditUnlockEntities.success) {
      toast.success('Entidad(es) desbloqueadas con éxito')
      refetch()
      refetchDataCredit()
      setSelectEntities(null)
      setShowEntity(false)
      document.getElementById('formUnlockEntities')?.reset()
    } else {
      toast.error('No se pudo desbloquear la(s) entidad(es)')
    }
  }, [dataUnlock])

  /* useEffect(() => {
    console.log('me ejecuto')
    filesComment.forEach(({ size }) => {
      if (size > MAX_SIZE) {
        setIsOneBigger(true)
      }
    })
  }, [filesComment]) */

  useEffect(() => {
    if (errorFormData) {
      toast.error(`ERROR: ${errorFormData.message}`)
      if (errorFormData.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorFormData])

  useEffect(() => {
    if (!dataRevition) return
    if (dataRevition?.creditRequestReview?.success) {
      toast.success('Crédito cambiado a revisión con éxito')
      refetchDataCredit()
      const form = document.getElementById('form-change-revition-credit')
      form?.reset()
      setShowChangeRevition(false)
    } else if (dataRevition.creditRequestReview.errors) {
      toast.error(
        'Error: ' + dataRevition.creditRequestReview.errors?.message ||
          'No se pudo pasar a revisión'
      )
    }
  }, [dataRevition])

  useEffect(() => {
    if (errorRevition) {
      toast.error(`ERROR: ${errorRevition.message}`)
    }
  }, [errorRevition])

  useEffect(() => {
    if (errorLink) {
      toast.error(`ERROR: ${errorLink.message}`)
      if (errorLink.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorLink])

  useEffect(() => {
    if (!dataLink) return
    if (dataLink.viewRequestLink && dataLink.viewRequestLink.success) {
      window.location.replace(dataLink.viewRequestLink.url)
    } else if (dataLink?.viewRequestLink?.errors) {
      toast.error(
        'ERROR: ' + dataLink.viewRequestLink.errors?.message ||
          'No se pudo ver solicitud'
      )
    }
  }, [dataLink])

  useEffect(() => {
    if (errorCheck) {
      toast.error(`ERROR: ${errorCheck.message}`)
    }
  }, [errorCheck])

  useEffect(() => {
    if (!dataCheck) return
    if (!dataCheck.canViewCredit) {
      onOpen()
    }
  }, [dataCheck])

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const toggleShowEntity = () => {
    setShowEntity((p) => !p)
  }
  const toggleShowComment = () => {
    setShowComment((p) => !p)
  }

  const toggleIsOpenEdit = () => {
    setIsOpenEdit((p) => !p)
  }
  const toggleClose = () => {
    setShowClose(false)
  }
  const toggleReqDisbur = () => {
    setShowReqDisbur(false)
  }
  const toggleEditInfo = () => {
    setShowEditInfo(false)
  }
  const onDismissPreap = () => setVisibleAlertPreap(false)
  const onDismissIncom = () => setVisibleAlertIncom(false)
  const onDismissChangeTrad = () => setVisibleAlertChangeTrad(false)
  const onDismissOldData = () => setVisibleAlertOldData(false)
  const onDismissPend = () => setVisibleAlertPend(false) // pasa a incompleto
  const onDismissNoCodeu = () => setVisibleAlertNoCodeu(false)

  /** @param {string | null} nameId */
  const renderTab = (nameId, statusCredit, nameTitular) => {
    if (nameId == null) {
      return (
        <Card>
          <CardBody>Ups ocurrió un error</CardBody>
        </Card>
      )
    }

    if (ENTITIES_TABS_OBJ[nameId] === ENTITIES_TABS_OBJ.ProductInformation) {
      return (
        <TabProductInfo
          idCredit={data?.getCredit?.id}
          refetchCredit={refetch}
          statusCredit={statusCredit}
        />
      )
    }
    if (ENTITIES_TABS_OBJ[nameId] === ENTITIES_TABS_OBJ.CoSigner) {
      return (
        <TabCosinger
          idCredit={data?.getCredit?.id}
          refetchCredit={refetchDataCredit}
          statusCredit={statusCredit}
          nameTitular={nameTitular}
        />
      )
    }
    if (ENTITIES_TABS_OBJ[nameId] === ENTITIES_TABS_OBJ.Reference) {
      return (
        <TabReferences
          idCredit={data?.getCredit?.id}
          refetchCredit={refetch}
          statusCredit={statusCredit}
        />
      )
    }
    if (ENTITIES_TABS_OBJ[nameId] === ENTITIES_TABS_OBJ.Spouse) {
      return (
        <TabSpouse
          idCredit={data?.getCredit?.id}
          refetchCredit={refetch}
          statusCredit={statusCredit}
        />
      )
    }
  }

  const handleClickDown = ({ name, file }) => {
    const link = document.createElement('a')
    link.href = file
    link.download = name
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const toggleShowChangeStatus = () => {
    setShowChangeStatus((p) => !p)
  }
  const closeModalChangeStatus = () => {
    setShowChangeStatus(false)
  }
  const getIconNav = (entity) => {
    if (ENTITIES_TABS_OBJ.CoSigner === ENTITIES_TABS_OBJ[entity]) {
      return 'mdi-account-cash-outline'
    }
    if (ENTITIES_TABS_OBJ.ProductInformation === ENTITIES_TABS_OBJ[entity]) {
      return 'mdi-cube-outline'
    }
    if (ENTITIES_TABS_OBJ.Reference === ENTITIES_TABS_OBJ[entity]) {
      return 'mdi-account-group-outline'
    }
    if (ENTITIES_TABS_OBJ.Spouse === ENTITIES_TABS_OBJ[entity]) {
      return 'mdi-account-heart-outline'
    }
    return 'mdi-square-medium-outline'
  }

  /**
   *
   * @param {string} name
   * @param {string} legibleName
   * @returns {void}
   */
  const handleDownloadDocument = async (name, legibleName) => {
    if (!name) return
    const { token } = user.tokenAuth
    if (!token) return
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    const requestOptions = {
      method: 'GET',
      headers: myHeaders
    }

    setLoadingDown(true)

    try {
      const res = await fetch(
        `${API}/export/${name}/${Number(id)}`,
        requestOptions
      )
      if (!res.ok) throw new Error('Error al exportar')
      const blob = await res.blob()
      // console.log('blob', blob)
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `${legibleName}-${Number(id)}.${blob.type.replace(
        'application/',
        ''
      )}`
      document.body.appendChild(link)
      setLoadingDown(false)
      link.click()
    } catch (err) {
      setLoadingDown(false)
      toast.error(`ERROR: ${err.message}`)
    }
  }

  document.title = 'Crédito | TOTAL SALES'

  if (loading) {
    return <LoadingPage />
  }

  if (!data) return null

  if (data.getCredit == null) {
    return (
      <div className='page-content'>
        <Container fluid>
          <Row
            className='justify-content-center align-items-center'
            style={{ height: '30vh' }}
          >
            <Col
              lg={12}
              className='d-flex align-items-center h-100 justify-content-center bg-white'
            >
              <div>
                <h4>Ups! No se encontró crédito 😞</h4>
                <div className='d-flex justify-content-center'>
                  <Link to='/creditos' className='btn btn-info'>
                    Ir a créditos
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  /** @type {{agency: {name: string, zone: {commercialCompany: {image: string, name: string}}},dateBirth: string,documentNumber: string,documentType: "CC" | "CE",email: string, expeditionDate: string, firstName: string, firstSurname: String,id: string, phoneNumber: string, secondName: string, secondSurname: string, status: string, uuid: string, firmaAutorizacion: boolean, creditType: string, centrales: string,initialFee: string, term: string, quotaValue: string, guarantorRequired: boolean, productValue: string, preapproved: boolean, notified: boolean ,closedDate: null | string ,quotation: null | {id: string}, logcreditSet: {edges: {node: {description: string, title: string, created: string, id: string, user:{firstName: string, lastName: string }}}[]},supportSet: {edges:{node:{id:string, name: string, file: string}}[]},creditcommentSet: {edges:{node:{user:{firstName: string, lastName: string, email: string}, oldStatus: string, newStatus: string, created: string,comment: string, id: string, supportSet:{edges:{node:{file:string, name: string, id: string, deleted: boolean}}[]}}}[]} ,user:{firstName: string, lastName: string} | null, analyst: null | {firstName: string, lastName: string}, cupoSeleccionado: {cuota: number, term: number, value: number}, cupoPreaprobado: {value: number, terms:number, valorCuota:number}[], oldData: string, creditLine: {id: string, name: string, legibleName: string}}} */
  const credit = data.getCredit

  /** @type {{entity: string, locked: boolean}[]} */
  const tabs = dataTabs?.entityListLocked || []

  const comments = credit?.creditcommentSet?.edges || []

  const supports = credit?.supportSet?.edges || []

  const timeLine = credit?.logcreditSet?.edges || []

  /** @type {{"user_id": string, "agency_id": string, "ally_name": string, "user_name": string, "zone_name": string, "agency_name": string}} */
  const oldData =
    credit.oldData && credit.oldData !== '{}'
      ? JSON.parse(credit.oldData)
      : null

  const hAlert =
    0 +
    +Boolean(!credit?.notified && credit?.preapproved && visibleAlertPreap) +
    +Boolean(
      (credit?.status === STATUS_CREDITS_OBJ.INCOMPLETO ||
        credit?.status === STATUS_CREDITS_OBJ.PENDIENTE) &&
        visibleAlertIncom
    ) +
    +Boolean(
      credit?.status === STATUS_CREDITS_OBJ.INCOMPLETO &&
        credit?.oldData?.length > 2 &&
        visibleAlertOldData
    ) +
    +Boolean(
      credit?.status === STATUS_CREDITS_OBJ.INCOMPLETO &&
        credit?.creditType === CREDIT_TYPES.INMEDIATO &&
        visibleAlertChangeTrad
    )

  return (
    <>
      {loadingCheck && (
        <div
          className='d-flex align-items-center justify-content-center'
          isOpen
          style={{
            backgroundColor: '#111',
            opacity: '.5',
            position: 'absolute',
            inset: 0,
            zIndex: 9999,
            overflow: 'hidden'
          }}
        >
          <Spinner color='light' size='lg' />
        </div>
      )}
      <div className='page-content'>
        <Container fluid>
          <div className='profile-foreground position-relative mx-n4 mt-n4'>
            <div
              className='profile-wid-bg h-bg-credit'
              style={{ height: `calc(590px + ${hAlert * 75}px)` }}
            >
              <img src={profileBg} alt='' className='profile-wid-img h-100' />
            </div>
          </div>
          {!credit.notified && credit.preapproved && (
            <Alert
              isOpen={visibleAlertPreap}
              toggle={onDismissPreap}
              color='info'
            >
              <h5>IMPORTANTE</h5>
              El crédito ha sido preaprobado, pero no ha sido notificado debido
              a la falta de referenciación. Complete información laboral del
              cliente, las referencias y adjunte cédula y copia de recibo
            </Alert>
          )}
          <div className='pt-4 mb-4 mb-lg-3 pb-lg-4'>
            {credit.guarantorRequired && (
              <Alert
                isOpen={visibleAlertNoCodeu}
                toggle={onDismissNoCodeu}
                color='info'
              >
                <h5>IMPORTANTE</h5>
                <p className='fs-4'>
                  Este crédito requiere codeudor obligatorio para poder ser
                  procesado
                </p>
              </Alert>
            )}
            {(credit.status === STATUS_CREDITS_OBJ.INCOMPLETO ||
              credit.status === STATUS_CREDITS_OBJ.PENDIENTE) && (
              <Alert
                isOpen={visibleAlertIncom}
                toggle={onDismissIncom}
                color='warning'
              >
                <h5>¡ATENCIÓN!</h5>
                <div className='d-flex flex-column flex-md-row justify-content-between'>
                  <p>
                    Los créditos en estado pendiente o incompletos no son
                    revisados por los equipos de analistas, recuerda enviar a
                    revisión para obtener respuesta
                  </p>

                  <div>
                    <Button
                      color='danger'
                      className='fs-5 d-flex align-items-center gap-2'
                      onClick={() => setShowChangeRevition(true)}
                    >
                      <i className='mdi mdi-alert' />
                      Pasar a revisión
                    </Button>
                  </div>
                </div>
              </Alert>
            )}
            {(credit.status === 'SIN_REVISAR' ||
              credit.status === STATUS_CREDITS_OBJ.PENDIENTE) && (
              <Alert
                isOpen={visibleAlertPend}
                toggle={onDismissPend}
                color='warning'
              >
                <h5>!CUIDADO!</h5>
                <div className='d-flex flex-column flex-md-row justify-content-between'>
                  <p>
                    Si quieres corregir o modificar las condiciones del negocio,
                    puede usar esta opción
                  </p>

                  <div>
                    <Button
                      color='warning'
                      className='fs-5 d-flex align-items-center gap-2'
                      onClick={() => onOpenChangePending(true)}
                    >
                      <i className='mdi mdi-puzzle-outline' />
                      Pasar a incompleto
                    </Button>
                  </div>
                </div>
              </Alert>
            )}
            {credit.status === STATUS_CREDITS_OBJ.INCOMPLETO &&
              credit.oldData?.length > 2 && (
                <Alert
                  isOpen={visibleAlertOldData}
                  toggle={onDismissOldData}
                  color='danger'
                >
                  <h5 className='fw-bold text-danger'>IMPORTANTE!</h5>
                  <div className='d-flex flex-column flex-md-row justify-content-between'>
                    <p>
                      Si deseas re asignar este crédito al asesor inicial puedes
                      usar esta función
                    </p>

                    <div>
                      <Button
                        color='danger'
                        className='fs-5 d-flex align-items-center gap-2'
                        onClick={onOpenOldData}
                      >
                        <i className='mdi mdi-cog-counterclockwise' />
                        Reasignar
                      </Button>
                    </div>
                  </div>
                </Alert>
              )}
            {credit.status === STATUS_CREDITS_OBJ.INCOMPLETO &&
              credit.creditType === CREDIT_TYPES.INMEDIATO && (
                <div className='pt-1 mb-1 mb-lg-1 pb-lg-1'>
                  <Alert
                    isOpen={visibleAlertChangeTrad}
                    toggle={onDismissChangeTrad}
                    color='info'
                  >
                    <h6>IMPORTANTE!</h6>
                    <div className='d-flex flex-column flex-md-row justify-content-between align-items-center'>
                      <p style={{ flex: 1 }}>
                        Si la solicitud de cupo no cumple las expectativas del
                        cliente, usa esta función para cambiar el crédito a un
                        estudio tradicional
                      </p>

                      <div>
                        <Button
                          color='info'
                          size='sm'
                          style={{ flex: 1 }}
                          className='d-flex align-items-center gap-2'
                          onClick={onOpenChangeTrad}
                        >
                          <i className='mdi mdi-alert' />
                          Cambiar a tradicional
                        </Button>
                      </div>
                    </div>
                  </Alert>
                </div>
              )}
            <Row className='g-4'>
              <div className='col-auto'>
                <div className='avatar-lg'>
                  <img
                    src={
                      credit.agency.zone.commercialCompany.image ||
                      '/TotalSales-Positivo.png'
                    }
                    alt='user-img'
                    className='img-thumbnail rounded-circle'
                    style={{ height: '92px' }}
                  />
                </div>
              </div>

              <Col>
                <div className='p-2'>
                  <h3 className='text-white mb-1 d-flex align-items-center gap-2 flex-wrap'>
                    Información de Crédito{' '}
                    <Badge
                      className='fs-6'
                      color={
                        credit.creditType === CREDIT_TYPES.INMEDIATO
                          ? 'dark'
                          : credit.creditType === CREDIT_TYPES.TRADICIONAL
                          ? 'info'
                          : 'primary'
                      }
                    >
                      {credit.creditType}
                    </Badge>
                  </h3>
                  {/* <div className='d-flex align-items-center gap-1'>
                    <p className='text-white-75 m-0'>{credit.uuid}</p>
                    <button
                      className='border-0 p-0 bg-transparent m-0 text-white'
                      title={isCopy ? 'Copiado' : 'Copiar'}
                      onClick={() => {
                        window.navigator.clipboard.writeText(credit.uuid)
                        setIsCopy(true)
                        setTimeout(() => {
                          setIsCopy(false)
                        }, 2000)
                      }}
                    >
                      <i
                        className={`mdi ${
                          isCopy
                            ? 'mdi-text-box-check-outline'
                            : 'mdi-text-box-multiple-outline'
                        }`}
                        style={{
                          color: isCopy ? '#3fff3f' : undefined,
                          fontSize: '1em'
                        }}
                      />
                    </button>
                  </div> */}
                  <div className='hstack text-white-50 gap-2'>
                    <div className='me-2'>
                      <i className='ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle' />
                      {credit.agency.name}
                    </div>
                    <div>
                      <i className='ri-building-line me-1 text-white-75 fs-16 align-middle' />
                      {credit.agency.zone.commercialCompany.name}
                    </div>
                    {credit.quotation && (
                      <div className='ms-2'>
                        <Link
                          to={`/cotizacion/${parseId(credit.quotation?.id)}`}
                          className='d-flex align-items-center text-reset text-decoration-underline'
                          style={{
                            color: 'white',
                            textDecoration: 'underline !important'
                          }}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <i className='mdi mdi-eye-outline me-1 text-white-75 fs-16 align-middle' />
                          Ver cotización
                        </Link>
                      </div>
                    )}
                  </div>
                  <div className='d-flex flex-column flex-md-row align-items-start align-items-md-center gap-2'>
                    <div className='d-flex mt-2'>
                      <Alert
                        className='flex-0'
                        color={getColorLineCredit(credit?.creditLine?.name)}
                      >
                        Linea crédito: <b>{credit?.creditLine?.legibleName}</b>
                      </Alert>
                    </div>
                    {credit.closedDate && (
                      <div className='d-flex mt-2'>
                        <Alert className='flex-0' color='warning'>
                          Cerrado el{' '}
                          <b>
                            {moment(credit.closedDate).format(
                              'DD MMM [del] YY - hh:mm A'
                            )}
                          </b>
                        </Alert>
                      </div>
                    )}
                  </div>
                </div>
              </Col>

              <Col xs={12} className='col-lg-auto order-last order-lg-0'>
                <Row className='text text-white-50 text-center me-1'>
                  <Col lg={3} sm={3} xs={6}>
                    <div className='p-2'>
                      {credit.creditType === CREDIT_TYPES.TRADICIONAL ? (
                        <>
                          <h4 className='text-white mb-1'>
                            {credit.initialFee
                              ? parsePrice(credit.initialFee)
                              : 'N/A'}
                          </h4>
                          <p className='fs-14 mb-0'>Inicial</p>
                        </>
                      ) : (
                        <>
                          <h4 className='text-white mb-1'>
                            {credit.initialFee
                              ? parsePrice(credit.initialFee)
                              : 'N/A'}
                          </h4>
                          <p className='fs-14 mb-0'>Inicial</p>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col lg={3} sm={3} xs={6}>
                    <div className='p-2'>
                      {credit.creditType === CREDIT_TYPES.TRADICIONAL ? (
                        <h4 className='text-white mb-1'>
                          {credit.term ? credit.term : 'N/A'}
                        </h4>
                      ) : (
                        <h4 className='text-white mb-1'>
                          {credit.term ? credit.term : 'N/A'}
                        </h4>
                      )}
                      <p className='fs-14 mb-0'>Plazo</p>
                    </div>
                  </Col>
                  <Col lg={3} sm={3} xs={6}>
                    <div className='p-2'>
                      {credit.creditType === CREDIT_TYPES.TRADICIONAL ? (
                        <h4 className='text-white mb-1'>
                          {credit.quotaValue
                            ? parsePrice(credit.quotaValue)
                            : 'N/A'}
                        </h4>
                      ) : (
                        <h4 className='text-white mb-1'>
                          {credit.quotaValue
                            ? parsePrice(credit.quotaValue)
                            : 'N/A'}
                        </h4>
                      )}
                      <p className='fs-14 mb-0'>Cuota</p>
                    </div>
                  </Col>
                  <Col lg={3} sm={3} xs={6}>
                    <div className='p-2'>
                      {/* {credit.creditType === CREDIT_TYPES.TRADICIONAL ? (
                        <h4 className='text-white mb-1'>
                          {credit.productValue
                            ? parsePrice(credit.productValue)
                            : 'N/A'}
                        </h4>
                      ) : ( */}
                      <h4 className='text-white mb-1'>
                        {credit.productValue
                          ? parsePrice(credit.productValue)
                          : 'N/A'}
                      </h4>
                      {/* )} */}
                      <p className='fs-14 mb-0'>Valor Producto</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <Row>
            <Col lg={12}>
              <div>
                <div className='d-flex'>
                  <Nav
                    pills
                    className='animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1'
                    role='tablist'
                  >
                    <NavItem>
                      <NavLink
                        href='#overview-tab'
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => {
                          toggleTab('1')
                        }}
                        title='Resumen'
                      >
                        <i className='ri-airplay-fill d-inline-block d-md-none' />{' '}
                        <span className='d-none d-md-inline-block'>
                          Resumen
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href='#activities'
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => {
                          toggleTab('2')
                        }}
                        title='Comentarios'
                      >
                        <i className='ri-list-unordered d-inline-block d-md-none' />{' '}
                        <span className='d-none d-md-inline-block'>
                          Comentarios
                        </span>
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        href='#projects'
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => {
                          toggleTab('3')
                        }}
                      >
                        <i className='ri-price-tag-line d-inline-block d-md-none' />{' '}
                        <span className='d-none d-md-inline-block'>
                          Referencias
                        </span>
                      </NavLink>
                    </NavItem> */}
                    <NavItem>
                      <NavLink
                        href='#documents'
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => {
                          toggleTab('3')
                        }}
                        title='Soportes'
                      >
                        <i className='ri-folder-4-line d-inline-block d-md-none' />{' '}
                        <span className='d-none d-md-inline-block'>
                          Soportes
                        </span>
                      </NavLink>
                    </NavItem>
                    {loadingTabs && (
                      <Spinner
                        style={{ width: '2em', height: '2em' }}
                        color='light'
                      />
                    )}
                    {tabs.map(({ entity, locked }, idx) => {
                      if (locked) return null
                      return (
                        <NavItem key={entity}>
                          <NavLink
                            href={`#${entity}`}
                            className={classnames({
                              active: activeTab === 4 + idx + ''
                            })}
                            onClick={() => {
                              toggleTab(4 + idx + '')
                            }}
                          >
                            <i
                              className={`mdi ${getIconNav(
                                entity
                              )} d-inline-block d-md-none`}
                            />{' '}
                            <span className='d-none d-md-inline-block'>
                              {ENTITIES_TABS_OBJ[entity]}
                            </span>
                          </NavLink>
                        </NavItem>
                      )
                    })}

                    {credit.closedDate && (
                      <NavItem>
                        <NavLink
                          href='#documentos'
                          className={classnames({ active: activeTab === '8' })}
                          onClick={() => {
                            toggleTab('8')
                          }}
                          title='Documentos de crédito'
                        >
                          <i className='mdi mdi-file-document-multiple-outline d-inline-block d-md-none' />{' '}
                          <span className='d-none d-md-inline-block'>
                            Documentos de crédito
                          </span>
                        </NavLink>
                      </NavItem>
                    )}

                    {!!dataTabs &&
                      hasPermission(
                        permissions,
                        ALL_PERMISSIONS.PUEDE_DESBLOQUEAR_ENTIDADES
                      ) && (
                        <NavItem>
                          <NavLink
                            // href='#projects'
                            className='bg-info'
                            style={{ cursor: 'pointer' }}
                            onClick={() => setShowEntity(true)}
                            title='Solicitar entidad'
                          >
                            <i className='ri-price-tag-line d-inline-block d-md-none' />{' '}
                            <span className='d-none d-md-inline-block'>
                              Solicitar entidad
                            </span>
                          </NavLink>
                        </NavItem>
                      )}
                    {(credit.status === STATUS_CREDITS_OBJ.INCOMPLETO ||
                      credit.status === STATUS_CREDITS_OBJ.PENDIENTE ||
                      credit.status === 'SIN_REVISAR') && (
                      <NavItem>
                        <NavLink
                          // href='#projects'
                          className='bg-warning'
                          style={{ cursor: 'pointer' }}
                          onClick={onOpenDesistir}
                          title='Desistir'
                        >
                          <i className='mdi mdi-handshake-outline d-inline-block d-md-none' />{' '}
                          <span className='d-none d-md-inline-block'>
                            Desistir crédito
                          </span>
                        </NavLink>
                      </NavItem>
                    )}
                  </Nav>
                  <div className='flex-shrink-0'>
                    <Dropdown
                      isOpen={isOpenEdit}
                      toggle={toggleIsOpenEdit}
                      direction='start'
                    >
                      <DropdownToggle>
                        <i className='ri-edit-box-line align-bottom' /> Editar
                        crédito
                      </DropdownToggle>
                      <DropdownMenu container='body' className='me-2'>
                        <DropdownItem header>Acciones</DropdownItem>
                        <DropdownItem divider />
                        {canEditOrAdd({
                          isAdd: false,
                          permissionAdmin:
                            ALL_PERMISSIONS.PUEDE_EDITAR_TODO_CREDITO,
                          permissions,
                          permissionToEdit:
                            ALL_PERMISSIONS.PUPEDE_CAMBIAR_CREDITO,
                          statusCredit: credit.status
                        }) && (
                          <DropdownItem onClick={() => setShowEditInfo(true)}>
                            Editar información
                          </DropdownItem>
                        )}
                        {hasPermission(
                          permissions,
                          ALL_PERMISSIONS.PUEDE_CAMBIAR_ESTADO_CREDITO
                        ) && (
                          <DropdownItem
                            onClick={() => setShowChangeStatus(true)}
                          >
                            Cambiar estado
                          </DropdownItem>
                        )}
                        {(credit.status === STATUS_CREDITS_OBJ.INCOMPLETO ||
                          credit.status === STATUS_CREDITS_OBJ.PENDIENTE) && (
                          <DropdownItem
                            onClick={() => setShowChangeRevition(true)}
                          >
                            Pasar a revisión
                          </DropdownItem>
                        )}
                        {credit.status === STATUS_CREDITS_OBJ.APROBADO &&
                          credit.closedDate == null &&
                          hasPermission(
                            permissions,
                            ALL_PERMISSIONS.PUPEDE_CAMBIAR_CREDITO
                          ) && (
                            <>
                              <DropdownItem divider />
                              <DropdownItem
                                className='d-flex align-items-center gap-1 text-danger'
                                onClick={() => setShowClose(true)}
                              >
                                <i className='mdi mdi-alert-outline' />
                                Cerrar crédito
                              </DropdownItem>
                            </>
                          )}
                        {credit.quotation == null &&
                          credit.closedDate &&
                          !user.tokenAuth.crm && (
                            <DropdownItem
                              onClick={() => {
                                setShowReqDisbur(true)
                              }}
                            >
                              Solicitar desembolso
                            </DropdownItem>
                          )}
                        {hasPermission(
                          permissions,
                          ALL_PERMISSIONS.PUEDE_CAMBIAR_TASA
                        ) && (
                          <DropdownItem
                            onClick={() => {
                              onOpenTasa()
                              setInfoTasa({
                                id: credit.id,
                                monthlyFee: credit.monthlyFee
                              })
                            }}
                          >
                            Cambiar tasa
                          </DropdownItem>
                        )}
                        {/* <DropdownItem text>Dropdown Item Text</DropdownItem>
                        <DropdownItem disabled>Action (disabled)</DropdownItem>
                        <DropdownItem>Foo Action</DropdownItem>
                        <DropdownItem>Bar Action</DropdownItem>
                        <DropdownItem>Quo Action</DropdownItem> */}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>

                <TabContent activeTab={activeTab} className='pt-4'>
                  <TabPane tabId='1'>
                    <Row>
                      <Col lg={4} md={12}>
                        <Card>
                          <CardBody className='d-flex justify-content-center'>
                            {/* <h5 className='card-title mb-0'>Estado</h5> */}
                            <StatusOneCredit status={credit.status} />
                          </CardBody>
                        </Card>

                        {oldData && (
                          <Card>
                            <CardBody>
                              <ListGroup>
                                <ListGroupItem active>
                                  <span className='fw-bold'>
                                    Agencia inicial
                                  </span>
                                </ListGroupItem>
                                <ListGroupItem>
                                  <span className='fw-bold me-2'>Asesor:</span>
                                  {oldData?.user_name
                                    ? oldData.user_name
                                    : 'N/R'}
                                </ListGroupItem>
                                <ListGroupItem>
                                  <span className='fw-bold me-2'>Agencia:</span>
                                  {oldData?.agency_name
                                    ? oldData.agency_name
                                    : 'N/R'}
                                </ListGroupItem>
                                <ListGroupItem>
                                  <span className='fw-bold me-2'>Zona:</span>
                                  {oldData?.zone_name
                                    ? oldData.zone_name
                                    : 'N/R'}
                                </ListGroupItem>
                                <ListGroupItem>
                                  <span className='fw-bold me-2'>Aliado:</span>
                                  {oldData?.ally_name
                                    ? oldData.ally_name
                                    : 'N/R'}
                                </ListGroupItem>
                              </ListGroup>
                            </CardBody>
                          </Card>
                        )}

                        <Card>
                          <CardBody>
                            <h5 className='card-title mb-3'>Dependencias</h5>
                            {credit.quotation || credit.disbursementrequest ? (
                              <div>
                                <p>Este crédito tiene dependencia con:</p>
                                <ul>
                                  {credit.quotation && (
                                    <li>
                                      Cotización en estado{' '}
                                      <BadgeQuotation
                                        status={credit.quotation.status}
                                      />
                                    </li>
                                  )}
                                </ul>
                                <ul>
                                  {credit.disbursementrequest && (
                                    <li>
                                      Solicitud de desembolso en estado{' '}
                                      <BadgeDisbursements
                                        status={
                                          credit.disbursementrequest.status
                                        }
                                      />
                                    </li>
                                  )}
                                </ul>
                              </div>
                            ) : (
                              <p className='fst-italic'>
                                Este credito no tiene dependencias actualmente
                              </p>
                            )}
                          </CardBody>
                        </Card>

                        <Card>
                          <CardBody>
                            <h5 className='card-title mb-3'>
                              Información cliente
                            </h5>
                            <div className='table-responsive'>
                              <Table className='table-borderless mb-0'>
                                <tbody>
                                  <tr>
                                    <th className='ps-0' scope='row'>
                                      Nombre(s):
                                    </th>
                                    <td className='text-muted'>
                                      {credit.firstName} {credit.secondName}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className='ps-0' scope='row'>
                                      Apellido(s):
                                    </th>
                                    <td className='text-muted'>
                                      {credit.firstSurname}{' '}
                                      {credit.secondSurname}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className='ps-0' scope='row'>
                                      Documento:
                                    </th>
                                    <td className='text-muted'>
                                      {credit.documentType.toLowerCase()}{' '}
                                      {credit.documentNumber}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className='ps-0' scope='row'>
                                      Teléfono:
                                    </th>
                                    <td className='text-muted'>
                                      {credit.phoneNumber}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className='ps-0' scope='row'>
                                      E-mail:
                                    </th>
                                    <td className='text-muted'>
                                      {credit.email}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className='ps-0' scope='row'>
                                      Fecha de nacimiento:
                                    </th>
                                    <td className='text-muted'>
                                      {moment(credit.dateBirth).format(
                                        'DD MMMM [de] YYYY'
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className='ps-0' scope='row'>
                                      Fecha de expedición:
                                    </th>
                                    <td className='text-muted'>
                                      {moment(credit.expeditionDate).format(
                                        'DD MMMM [de] YYYY'
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className='ps-0' scope='row'>
                                      Autorización
                                    </th>
                                    <td>
                                      {credit.firmaAutorizacion ? (
                                        <Badge color='success'>Firmada</Badge>
                                      ) : (
                                        <Badge color='danger'>No firmado</Badge>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </CardBody>
                        </Card>

                        {hasPermission(
                          permissions,
                          ALL_PERMISSIONS.PUEDE_VER_CENTRALES
                        ) && (
                          <CardCentrales
                            centrales={credit.centrales}
                            infoCentralesJson={credit.centralesJson}
                            document={credit.documentNumber}
                          />
                        )}

                        <Card>
                          <CardBody>
                            <h5 className='card-title mb-3'>
                              Información adicional
                            </h5>
                            <div className='table-responsive'>
                              <Table className='table-borderless mb-0'>
                                <tbody>
                                  <tr>
                                    <th className='ps-0' scope='row'>
                                      Asesor:
                                    </th>
                                    <td className='text-muted'>
                                      {credit.user ? (
                                        <>
                                          {credit.user.firstName}{' '}
                                          {credit.user.lastName}
                                        </>
                                      ) : (
                                        <span
                                          className='d-flex align-items-center gap-1'
                                          style={{ color: '#ff8787' }}
                                        >
                                          <i className='mdi mdi-account-cancel' />{' '}
                                          No aplica
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className='ps-0' scope='row'>
                                      Analista:
                                    </th>
                                    <td className='text-muted'>
                                      {credit.analyst ? (
                                        <>
                                          {credit.analyst.firstName}{' '}
                                          {credit.analyst.lastName}
                                        </>
                                      ) : (
                                        <span
                                          className='d-flex align-items-center gap-1'
                                          style={{ color: '#ff8787' }}
                                        >
                                          <i className='mdi mdi-account-cancel' />{' '}
                                          No aplica
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </CardBody>
                        </Card>
                        <Card>
                          <CardBody
                            className='d-flex align-items-center justify-content-center'
                            style={{ height: '100px' }}
                          >
                            <Button
                              onClick={() => {
                                handleDownloadDocument(
                                  'credit/request',
                                  'Solicitud-credito'
                                )
                              }}
                              disabled={loadingDown}
                              className='d-flex align-items-center gap-1'
                            >
                              {loadingDown ? (
                                <Spinner size='md' color='light' />
                              ) : (
                                <i className='mdi mdi-download fs-24 text-light' />
                              )}
                              Descargar solicitud de crédito
                            </Button>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={8} md={12}>
                        {credit &&
                          (credit?.cupoPreaprobado?.length === 0 ||
                            credit.cupoPreaprobado == null) &&
                          credit?.uuid && (
                            <Card>
                              <CardHeader className='d-flex align-items-center justify-content-end'>
                                <Button
                                  color='primary'
                                  onClick={() => {
                                    qglViewRequestLink({
                                      variables: {
                                        credit: credit.uuid
                                      }
                                    })
                                  }}
                                  disabled={loadingLink}
                                >
                                  {loadingLink && (
                                    <Spinner size='sm' color='light' />
                                  )}
                                  Ver solicitud
                                </Button>
                              </CardHeader>
                            </Card>
                          )}
                        {credit?.cupoPreaprobado?.length > 0 && (
                          <Card>
                            <>
                              <CardHeader className='d-flex align-items-center justify-content-between'>
                                <h5 className='card-title m-0 p-0'>
                                  Cupo preaprobado
                                </h5>
                                {credit.uuid && (
                                  <Button
                                    color='primary'
                                    onClick={() => {
                                      qglViewRequestLink({
                                        variables: {
                                          credit: credit.uuid
                                        }
                                      })
                                    }}
                                    disabled={loadingLink}
                                  >
                                    {loadingLink && (
                                      <Spinner size='sm' color='light' />
                                    )}
                                    Ver solicitud
                                  </Button>
                                )}
                              </CardHeader>
                              {credit.creditType ===
                                CREDIT_TYPES.TRADICIONAL && (
                                <div className='pt-3 px-4'>
                                  <Alert color='warning'>
                                    Si estás viendo esta información es porque
                                    el crédito tuvo una preaprobación pero fue
                                    cambiado a tipo{' '}
                                    <span className='fw-bold'>tradicional</span>
                                    , revisa bien la información del crédito
                                  </Alert>
                                </div>
                              )}
                              <CardBody id='table-cupo-preaprobado'>
                                {credit?.cupoPreaprobado?.length > 0 ? (
                                  <>
                                    <table className='table table-bordered table-nowrap mb-0'>
                                      <thead>
                                        <tr>
                                          {/* <th scope='col'>#</th> */}
                                          <th scope='col'>Cuotas</th>
                                          <th scope='col'>Cupo</th>
                                          <th scope='col'>Valor cuota</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <>
                                          {credit.cupoPreaprobado
                                            .slice(
                                              0,
                                              isViewMore ? undefined : 5
                                            )
                                            .map(
                                              (
                                                { terms, valorCuota, value },
                                                idx
                                              ) => (
                                                <tr key={idx}>
                                                  {/* <th scope='row'>{idx + 1}</th> */}
                                                  <td>{terms}</td>
                                                  <td>{parsePrice(value)}</td>
                                                  <td>
                                                    {parsePrice(valorCuota)}
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          {!isViewMore && (
                                            <tr>
                                              <th scope='row'>
                                                <i className='mdi mdi-dots-vertical' />
                                              </th>
                                              <td>
                                                <i className='mdi mdi-dots-vertical' />
                                              </td>
                                              <td>
                                                <i className='mdi mdi-dots-vertical' />
                                              </td>
                                              {/* <td>
                                                <i className='mdi mdi-dots-vertical' />
                                              </td> */}
                                            </tr>
                                          )}
                                        </>
                                      </tbody>
                                    </table>
                                    <Button
                                      color='link'
                                      onClick={() => {
                                        if (isViewMore) {
                                          window.scrollTo(0, 700)
                                        }
                                        setIsViewMore((p) => !p)
                                      }}
                                      className='d-flex align-items-center gap-1 mx-auto p-0'
                                    >
                                      {isViewMore ? (
                                        <span className='d-flex align-items-center gap-1'>
                                          Contraer{' '}
                                          <i className='mdi mdi-chevron-up fs-5' />
                                        </span>
                                      ) : (
                                        <span className='d-flex align-items-center gap-1'>
                                          Ver más
                                          <i className='mdi mdi-chevron-down fs-5' />
                                        </span>
                                      )}
                                    </Button>
                                  </>
                                ) : (
                                  <div className='w-100 d-flex align-items-center justify-content-center'>
                                    <div>
                                      <div>
                                        No hay cupo preaprobado para este
                                        crédito
                                      </div>
                                      <img
                                        src={imgNoCupo}
                                        alt='No hay cupo preaprobado'
                                        style={{
                                          maxHeight: '360px',
                                          width: 'auto'
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                                <Row>
                                  <Table borderless responsive>
                                    <tbody>
                                      <tr>
                                        <th>Score</th>
                                        <td>
                                          {credit?.statusSolucredit || 'N/A'}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Descripción</th>
                                        <td>
                                          {credit?.descriptionSolucredit ||
                                            'N/A'}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </Row>
                              </CardBody>
                            </>
                          </Card>
                        )}
                        {!credit?.cupoPreaprobado?.length &&
                          (credit?.statusSolucredit ||
                            credit?.descriptionSolucredit) && (
                            <Card>
                              <Row>
                                <Table borderless responsive>
                                  <tbody>
                                    <tr>
                                      <th>Score</th>
                                      <td>
                                        {credit?.statusSolucredit || 'N/A'}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Descripción</th>
                                      <td>
                                        {credit?.descriptionSolucredit || 'N/A'}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </Row>
                            </Card>
                          )}
                        <Card>
                          <CardBody>
                            {loadingFormData ||
                            !dataFormData ||
                            !dataFormData.getEntityResponse ? (
                              <div
                                className='d-flex align-items-center justify-content-center'
                                style={{ height: '30vh' }}
                              >
                                <Spinner
                                  style={{ width: '2.5em', height: '2.5em' }}
                                />
                              </div>
                            ) : (
                              <div className='p-2'>
                                {dataFormData.getEntityResponse.length ===
                                  0 && (
                                  <div>
                                    No hay información adicional sobre este
                                    crédito
                                  </div>
                                )}
                                {dataFormData.getEntityResponse.map(
                                  (strForm, idx) => {
                                    const oneFormParse = JSON.parse(strForm)
                                    const str = JSON.stringify([oneFormParse])
                                    return <FormDataView key={idx} data={str} />
                                  }
                                )}
                              </div>
                            )}
                          </CardBody>
                        </Card>

                        {hasPermission(
                          permissions,
                          ALL_PERMISSIONS.PUEDE_VER_LOG_CREDITO
                        ) && (
                          <Card>
                            <CardHeader>Historial</CardHeader>
                            <CardBody>
                              {timeLine.length > 0 ? (
                                <div className='horizontal-timeline my-3'>
                                  <Swiper
                                    className='timelineSlider py-1'
                                    navigation
                                    thumbs={{ swiper: null }}
                                    slidesPerView={3}
                                  >
                                    <div className='swiper-wrapper'>
                                      {timeLine.map(({ node: nodeTime }) => (
                                        <SwiperSlide
                                          key={nodeTime.id}
                                          style={{ marginLeft: '1px' }}
                                        >
                                          <div className='card pt-2  item-box text-center'>
                                            <div
                                              className='timeline-content p-3 rounded'
                                              style={{
                                                maxHeight: '130px',
                                                height: '130px'
                                              }}
                                            >
                                              <div className='w-100'>
                                                <p className='text-info fw-medium mb-0'>
                                                  {nodeTime.user.firstName}{' '}
                                                  {nodeTime.user.lastName}
                                                </p>
                                                <p className='text-muted fw-medium mb-0'>
                                                  {moment(
                                                    nodeTime.created
                                                  ).format('DD [de] MMM')}
                                                </p>
                                                <h6 className='mb-0'>
                                                  {nodeTime.title}
                                                </h6>
                                                <p
                                                  className='text-muted fs-12 truncate-ts'
                                                  title={nodeTime.description}
                                                >
                                                  {nodeTime.description}
                                                </p>
                                              </div>
                                            </div>
                                            <div className='time'>
                                              <span className='badge bg-success'>
                                                {moment(
                                                  nodeTime.created
                                                ).format('hh:mm A')}
                                              </span>
                                            </div>
                                          </div>
                                        </SwiperSlide>
                                      ))}
                                    </div>
                                    {/* <div className="swiper-button-next"></div>
                                        <div className="swiper-button-prev"></div> */}
                                  </Swiper>
                                </div>
                              ) : (
                                <div
                                  className='d-flex align-items-center justify-content-center'
                                  style={{ height: '30vh' }}
                                >
                                  <p>No hay información para este crédito</p>
                                </div>
                              )}
                            </CardBody>
                          </Card>
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabComments
                    comments={comments}
                    handleClickDown={handleClickDown}
                    setShowComment={setShowComment}
                  />
                  <TabSuports
                    handleClickDown={handleClickDown}
                    supports={supports}
                    refetch={refetchDataCredit}
                    creditStatus={credit.status}
                  />
                  {tabs.map(({ entity, locked }, idx) => {
                    if (locked) return null
                    return (
                      <TabPane key={entity} tabId={4 + idx + ''}>
                        {renderTab(
                          entity,
                          credit.status,
                          `${credit.firstName} ${credit.firstSurname}`
                        )}
                      </TabPane>
                    )
                  })}
                  {/* <TabPane tabId='4'></TabPane>
                  <TabPane tabId='5'>{renderTab(tabs[1]?.entity)}</TabPane>
                  <TabPane tabId='6'>{renderTab(tabs[2]?.entity)}</TabPane>
                  <TabPane tabId='7'>{renderTab(tabs[3]?.entity)}</TabPane> */}
                  {credit.closedDate && (
                    <TabDocuments
                      creditId={credit.id}
                      // creditLine={credit?.creditLine?.name}
                    />
                  )}
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={showEntity} toggle={toggleShowEntity}>
          <ModalHeader toggle={toggleShowEntity}>
            Solicitar enitidad
          </ModalHeader>
          <Form
            autoComplete='off'
            id='formUnlockEntities'
            onSubmit={(e) => {
              e.preventDefault()
              if (!data?.getCredit?.id) return
              const obj = Object.fromEntries(new FormData(e.target))
              if (!selectEntities || selectEntities?.length === 0) return
              const input = {
                creditId: parseId(data.getCredit.id),
                comment: obj.comment,
                entities: selectEntities.map(({ value }) => value)
              }
              gqlUnlockEntity({
                variables: {
                  input
                }
              })
            }}
          >
            <ModalBody>
              <FormGroup>
                <Label>Seleccione entidad</Label>
                <Select
                  placeholder='Selecciona una o varias opciones'
                  isMulti
                  className='basic-multi-select'
                  classNamePrefix='select'
                  value={selectEntities}
                  onChange={(e) => {
                    // console.log('e', e)
                    setSelectEntities(e)
                  }}
                  options={optionsChangeEntities}
                  name='select'
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? 'grey' : '#ced4da'
                    })
                  }}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor='textarea45'>Comentario</Label>
                <Input
                  type='textarea'
                  name='comment'
                  id='textarea45'
                  required
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                color='light'
                type='button'
                onClick={() => {
                  document.getElementById('formUnlockEntities')?.reset()
                  setSelectEntities(null)
                  setShowEntity(false)
                }}
              >
                Cancelar
              </Button>
              <Button
                type='submit'
                disabled={loadingUnlock}
                className='d-flex align-items-center gap-1'
              >
                {loadingUnlock && <Spinner color='light' size='sm' />}Solicitar
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
        {showComment && (
          <ModalAddComment
            showComment={showComment}
            toggleShowComment={toggleShowComment}
            creditId={credit.id}
            refetchDataCredit={refetchDataCredit}
          />
        )}
        <ModalChangeStatus
          closeModal={closeModalChangeStatus}
          creditId={credit.id}
          refetchDataCredit={refetchDataCredit}
          showChangeStatus={showChangeStatus}
          toggleShowChangeStatus={toggleShowChangeStatus}
        />
        <Modal isOpen={showChangeRevition}>
          <ModalHeader className='bg-soft-info p-4'>
            Cambiar a revisión
          </ModalHeader>
          <Form
            id='form-change-revition-credit'
            onSubmit={(e) => {
              e.preventDefault()
              const { comment } = Object.fromEntries(new FormData(e.target))
              gqlChangeRevision({
                variables: {
                  input: {
                    id: parseId(credit.id),
                    comment
                  }
                }
              })
            }}
          >
            <ModalBody>
              <FormGroup>
                <Label htmlFor='textarea45'>Comentario</Label>
                <Input
                  type='textarea'
                  name='comment'
                  id='textarea45'
                  required
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                color='light'
                type='button'
                onClick={() => {
                  // setShowComment(false)
                  setShowChangeRevition(false)
                  const form = document.getElementById(
                    'form-change-revition-credit'
                  )
                  form?.reset()
                }}
              >
                Cancelar
              </Button>
              <Button
                type='submit'
                disabled={loadingRevition}
                className='d-flex align-items-center gap-1'
              >
                {loadingRevition && <Spinner color='light' size='sm' />}
                Poner en revisión
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
        <ModalCloseCredit
          showClose={showClose}
          toggleClose={toggleClose}
          idCredit={credit.id}
          refetchDataCredit={refetchDataCredit}
        />

        <ModalReqDisbursement
          idCredit={credit.id}
          refetchDataCredit={refetchDataCredit}
          showReqDisbur={showReqDisbur}
          toggleReqDisbur={toggleReqDisbur}
        />

        <ModalEditInfo
          isOpen={showEditInfo}
          toggle={toggleEditInfo}
          credit={credit}
          refetchCredit={refetchDataCredit}
        />
      </div>

      {isOpen && (
        <Modal isOpen={isOpen} centered>
          <ModalBody>
            <div className='d-flex align-items-center justify-content-center'>
              <lord-icon
                src='/lotties/alert-lottie.json'
                trigger='loop'
                // colors='primary:#0ab39c,secondary:#405189'
                style={{ width: '120px', height: '120px' }}
              />
            </div>
            <div className='d-flex align-items-center justify-content-center'>
              <h5>Este crédito no está asignado a usted!</h5>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                navigate('/creditos')
              }}
              color='light'
            >
              Volver
            </Button>
            <Button onClick={onClose}>Continuar</Button>
          </ModalFooter>
        </Modal>
      )}

      <ModalDesitirCredit
        isOpen={isOpenDesistir}
        onClose={onCloseDesistir}
        creditId={credit.id}
      />

      {infoTasa && isOpenTasa && (
        <ModalChangeTasa
          infoTasa={infoTasa}
          onClose={onCloseTasa}
          isOpen={isOpenTasa}
          refetch={refetchDataCredit}
        />
      )}

      {isOpenChangeTrad && (
        <ModalChangeTrad
          isOpen={isOpenChangeTrad}
          onClose={onCloseChangeTrad}
          creditId={credit.id}
          refetch={refetchDataCredit}
        />
      )}
      {isOpenOldData && (
        <ModalChangeOldData
          isOpen={isOpenOldData}
          onClose={onCloseOldData}
          creditId={credit.id}
          refetch={refetchDataCredit}
        />
      )}
      {isOpenChangePending && (
        <ModalChangePending
          // pasa a incompleto
          isOpen={isOpenChangePending}
          onClose={onCloseChangePending}
          creditId={credit.id}
          refetch={refetchDataCredit}
        />
      )}
    </>
  )
}
