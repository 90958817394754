import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Input,
  Spinner,
  Table,
  UncontrolledTooltip
} from 'reactstrap'
// import TableContainer from '../../../Components/Common/TableContainer'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
/* import Flatpickr from 'react-flatpickr'
import { Spanish } from 'flatpickr/dist/l10n/es' */
/*
import {
    useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table' */
import { useLazyQuery, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { hasPermission, parseId, parsePrice } from '../../../helpers'
import {
  ALL_PERMISSIONS,
  ERROR_MESSAGES,
  STATUS_QUOATATIONS_ALIADOS
  // STATUS_QUOATATIONS_OBJ
} from '../../../helpers/consts'
import { useGetUser } from '../../../hooks/user'
import { GET_AGENCIAS_TO_CREDITS } from '../../../graphql/queries/getCredits'
import moment from 'moment'
// import { API } from '../../../graphql/client'
import Select from 'react-select'
import { GET_ALL_COTIZACIONES_ALIADOS } from '../../../graphql/queries/getCotizacionAliados'
// import { useDisclosure } from '../../../hooks/useDisclosure'
// import { ModalAddCotizAliado } from '../../../Components/Own/cotizacionAliados/ModalAddCotizAliado'
import { BadgeStatusCotAl } from '../../../Components/Own/cotizacionAliados/BadgeStatusCotAl'

export const CotizacionesOtro = () => {
  const [gqlGetQuotations, { data, loading, error }] = useLazyQuery(
    GET_ALL_COTIZACIONES_ALIADOS,
    {
      fetchPolicy: 'network-only'
    }
  )
  const { data: dataAgencies, loading: loadingAgencies } = useQuery(
    GET_AGENCIAS_TO_CREDITS
  )
  const navigate = useNavigate()
  const { logout, permissions } = useGetUser()
  const [dataTable, setDataTable] = useState([])

  // const { isOpen, onClose, onOpen } = useDisclosure()

  const [isSearch, setIsSearch] = useState(false)

  const [agencySelect, setAgencySelect] = useState('')
  const [searchInput, setSearchInput] = useState('')
  const [searchValue, setSearchValue] = useState({
    key: '',
    value: ''
  })
  /* const [datesValue, setDatesValue] = useState({
    createdDateGte: undefined,
    createdDateLte: undefined
  }) */
  const [statusSelect, setStatusSelect] = useState('')
  const [pagination, setPagination] = useState({
    offset: 0,
    page: 1
  })
  const [isPagination, setIsPagination] = useState(false)

  // const [loadingExport, setLoadingExport] = useState(false)

  useEffect(() => {
    if (!hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_COTIZACION)) {
      navigate('/dashboard')
    }
  }, [permissions])

  useEffect(() => {
    const variables = {
      offset: pagination.offset
    }
    if (searchValue.key) {
      variables[searchValue.key] = searchValue.value
    }
    /* if (datesValue.createdDateGte && datesValue.createdDateLte) {
      variables.createdDateGte = datesValue.createdDateGte
      variables.createdDateLte = datesValue.createdDateLte
    } */
    if (statusSelect) {
      variables.status_Iexact = statusSelect
    }
    if (agencySelect) {
      variables.agency_Id = agencySelect.split('***')[0]
    }
    // console.log('variables', variables)
    gqlGetQuotations({
      variables
    })
  }, [
    searchValue.value,
    // datesValue,
    statusSelect,
    pagination.offset,
    agencySelect
  ])

  useEffect(() => {
    if (data && data.listAllyQuote) {
      if (isPagination) {
        setDataTable((prev) => [...prev, ...data.listAllyQuote.edges])
        setPagination((prev) => ({ ...prev, page: prev.page + 1 }))
        setIsPagination(false)
      } else {
        setDataTable(data.listAllyQuote.edges)
      }
    }
  }, [data])

  /*   useTable({
    columns,
    data: dataTable.slice(10 * pagination.page - 10, 10 * pagination.page)
  }) */

  useEffect(() => {
    if (error) {
      toast.error(`Error! \n ${error.message}`)
      if (error.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [error])

  /* const searchForDates = (e) => {
    if (e.length === 1) return
    setPagination({ page: 1, offset: 0 })

    if (e && e.length === 2) {
      setIsSearch(true)
      const createdDateGte = new Date(e[0]).toISOString()
      const createdDateLte = new Date(e[1]).toISOString()

      return setDatesValue({
        createdDateGte,
        createdDateLte
      })
    }
    setDatesValue({
      createdDateGte: undefined,
      createdDateLte: undefined
    })
  } */

  const hanldeSubmit = (e) => {
    e.preventDefault()
    if (searchInput.length >= 3) {
      const key = `${Number(searchInput) ? 'documentNumber' : 'name'}`
      setIsSearch(true)
      setPagination({ page: 1, offset: 0 })
      setSearchValue({
        key,
        value: searchInput
      })
    }
  }

  /* const handleDownload = async () => {
    if (!datesValue.createdDateGte || !datesValue.createdDateLte) return
    const { token } = user.tokenAuth
    if (token) return
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    const requestOptions = {
      method: 'GET',
      headers: myHeaders
    }

    setLoadingExport(true)
    const init = new Date(datesValue.createdDateGte).toISOString().split('T')[0]
    const end = new Date(datesValue.createdDateLte).toISOString().split('T')[0]
    try {
      const res = await fetch(
        `${API}/export/quotations/${init}/${end}`,
        requestOptions
      )
      if (!res.ok) throw new Error('Error al exportar')
      const blob = await res.blob()
      // console.log('blob', blob)
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `cotizaciones-${moment(datesValue.createdDateGte).format(
        'DD-MM-YY'
      )}-${moment(datesValue.createdDateLte).format('DD-MM-YY')}.xlsx`
      document.body.appendChild(link)
      setLoadingExport(false)
      link.click()
    } catch (err) {
      setLoadingExport(false)
      toast.error(`ERROR: ${err.message}`)
    }
  } */

  /* const resetRefetch = () => {
    setPagination({
      offset: 0,
      page: 1
    })
    setAgencySelect('')
    setSearchInput('')
    setSearchValue({
      key: '',
      value: ''
    })
    refetch()
  } */

  document.title = 'Cotizaciones de aliados | TOTAL SALES'

  if (loading && !isSearch && !isPagination) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Ver cotizaciones' pageTitle='Cotizaciones' />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between border-0 gap-3 flex-md-column flex-lg-row flex-sm-column '>
                  <div className='w-100'>
                    <h5 className='card-title mb-0'>Cotizaciones otros</h5>
                  </div>
                  <div className='w-100'>
                    <p
                      className='placeholder-glow w-100 text-end'
                      style={{ height: '30px' }}
                    >
                      <span
                        className='placeholder col-4'
                        style={{ height: '100%' }}
                      />
                    </p>
                  </div>
                </CardHeader>
                <CardBody style={{ height: '30vh' }}>
                  <div className='d-flex justify-content-center align-items-center h-100'>
                    <Spinner style={{ width: '3em', height: '3em' }} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Ver cotizaciones' pageTitle='Cotizaciones' />
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className='d-flex align-items-center justify-content-between border-0 gap-3 flex-column flex-md-row'>
                <h5 className='card-title mb-0 d-flex align-items-center gap-1'>
                  Cotizaciones otros{' '}
                  {loading && isSearch && (
                    <Spinner style={{ width: '14px', height: '14px' }} />
                  )}
                </h5>
                <div className='d-flex align-items-center gap-2 flex-column flex-md-row'>
                  {/* <Button
                    disabled={
                      (!datesValue.createdDateGte &&
                        !datesValue.createdDateLte) ||
                      loadingExport ||
                      true
                    }
                    color='success'
                    className='d-flex align-items-center gap-2'
                    onClick={handleDownload}
                  >
                    {loadingExport ? (
                      <Spinner size='sm' />
                    ) : (
                      <i className='mdi mdi-microsoft-excel' />
                    )}
                    Descargar Excel
                  </Button> */}

                  {hasPermission(
                    permissions,
                    ALL_PERMISSIONS.PUEDE_CREAR_COTIZACIONES_ALIADOS
                  ) && (
                    <div className='flax-shrink-0 hstack gap-2'>
                      <Link
                        className='btn btn-primary'
                        to='/agregar-cotizacion'
                      >
                        Crear cotización
                      </Link>
                    </div>
                  )}
                </div>
              </CardHeader>
              <CardBody>
                <Row className='mb-3'>
                  <CardBody className='border border-dashed border-end-0 border-start-0'>
                    <Row className='g-4'>
                      <Col lg={4}>
                        <form onSubmit={hanldeSubmit}>
                          <div className='search-box me-2 mb-2 d-inline-block w-100'>
                            <input
                              id='search-bar-0'
                              type='search'
                              value={searchInput}
                              onChange={(e) => {
                                setSearchInput(e.target.value)
                                if (e.target.value === '') {
                                  setPagination({
                                    page: 1,
                                    offset: 0
                                  })
                                  setSearchValue({
                                    key: '',
                                    value: ''
                                  })
                                }
                              }}
                              name='searchInput'
                              className='form-control search /'
                              placeholder='Ingresa cédula o nombre'
                            />
                            <i className='bx bx-search-alt search-icon' />
                          </div>
                        </form>
                      </Col>

                      <Col lg={4}>
                        <select
                          className='form-select'
                          data-choices
                          data-choices-search-false
                          name='choices-single-default2'
                          id='choices-single-default2'
                          value={statusSelect}
                          onChange={(e) => {
                            setStatusSelect(e.target.value)
                            setPagination({ page: 1, offset: 0 })
                          }}
                        >
                          <option value=''>Escoja estado</option>
                          {STATUS_QUOATATIONS_ALIADOS.map((status) => (
                            <option
                              key={status[0]}
                              value={status[0]?.replace(/_/g, ' ')}
                            >
                              {status[1]}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col lg={4}>
                        {!dataAgencies || loadingAgencies ? (
                          <Spinner size='sm' />
                        ) : (
                          <>
                            <Select
                              // className='form-select'
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? 'blue'
                                    : 'lightgray'
                                })
                              }}
                              isClearable
                              isSearchable
                              noOptionsMessage={() => 'No se encontró agencia'}
                              placeholder='Seleccione agencia'
                              options={[
                                // { label: 'Seleccione agencia', value: '' },
                                ...dataAgencies?.listAgency?.edges?.map(
                                  ({ node }) => ({
                                    value: parseId(node.id),
                                    label: node.name
                                  })
                                )
                              ]}
                              value={
                                agencySelect
                                  ? {
                                      label: agencySelect.split('***')[1],
                                      value: agencySelect.split('***')[0]
                                    }
                                  : null
                              }
                              onChange={(e) => {
                                setPagination({ page: 1, offset: 0 })
                                if (e == null) {
                                  return setAgencySelect(null)
                                }
                                setAgencySelect(`${e.value}***${e.label}`)
                              }}
                            />
                          </>
                        )}
                      </Col>

                      {/* <Col lg={6}>
                        <div className='input-group'>
                          <span className='input-group-text' id='basic-addon1'>
                            <i className='ri-calendar-2-line' />
                          </span>
                          <Flatpickr
                            placeholder='Seleccione fecha(s)'
                            className='form-control'
                            onChange={(e) => searchForDates(e)}
                            options={{
                              mode: 'range',
                              dateFormat: 'd F y',
                              showMonths: 2,
                              locale: Spanish,
                              position: 'auto center'
                            }}
                          />
                        </div>
                      </Col> */}
                      {/* <Col lg={2} md={5} xs={5}>
                        <select
                          className='form-control'
                          data-choices
                          data-choices-search-false
                          name='choices-single-default'
                          id='choices-single-default'
                        >
                          <option defaultValue='all'>Motos</option>
                          <option value='Buy'>Sell</option>
                          <option value='Sell'>Buy</option>
                        </select>
                      </Col> */}
                    </Row>
                  </CardBody>
                </Row>

                {dataTable.length > 0 && (
                  <>
                    <div className='table-responsive table-card mb-1'>
                      <Table hover className='align-middle table-nowrap'>
                        <thead className='table-light text-muted'>
                          <tr>
                            <th>Agencia</th>
                            <th>Cliente</th>
                            <th>Teléfono</th>
                            <th>Estado</th>
                            <th>Producto</th>
                            {/* <th>Cuota</th> */}
                            <th>Valor</th>
                            <th>Inicial</th>
                            <th>Creado</th>
                            <th />
                          </tr>
                        </thead>

                        <tbody>
                          {dataTable
                            .slice(
                              10 * pagination.page - 10,
                              10 * pagination.page
                            )
                            .map(({ node }) => (
                              <tr key={node.id}>
                                <td>
                                  <span>{node.agency.name}</span>
                                </td>
                                <td>
                                  <span className='text-uppercase'>
                                    {node.firstName} {node.secondName || ''}{' '}
                                    {node.firstSurname}{' '}
                                    {node.secondSurname || ''}
                                  </span>
                                </td>
                                <td>{node.phoneNumber}</td>
                                <td>
                                  <BadgeStatusCotAl status={node.status} />
                                </td>
                                <td>{node.product}</td>
                                <td>{parsePrice(node.productValue)}</td>
                                <td>{parsePrice(node.initialFee)}</td>
                                {/* <td>{parsePrice(node.monthlyFee)}</td> */}
                                {/* <td>
                                  <ul className='list-gruop'>
                                    {node.products.edges.map(({ node }) => (
                                      <li
                                        className='list-group-item'
                                        key={node.id}
                                      >
                                        {node.name}
                                      </li>
                                    ))}
                                  </ul>
                                </td> */}
                                <td>
                                  {moment(node.created).format(
                                    'DD MMM [del] YY'
                                  )}
                                </td>
                                <td>
                                  <ul className='list-inline hstack gap-2 mb-0'>
                                    <li className='list-inline-item'>
                                      <Link
                                        to={`/cotizacion-otros/${parseId(
                                          node.id
                                        )}`}
                                        className='text-primary d-inline-block'
                                        id={
                                          'view' + parseId(node.id).toString()
                                        }
                                        target='_blank'
                                        rel='noopener noreferrer'
                                      >
                                        <i className='ri-eye-fill fs-16' />
                                      </Link>
                                      <UncontrolledTooltip
                                        placement='top'
                                        target={
                                          'view' + parseId(node.id).toString()
                                        }
                                        trigger='hover'
                                      >
                                        Ver
                                      </UncontrolledTooltip>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                    <Row className='justify-content-md-end justify-content-center align-items-center p-2'>
                      <Col className='col-md-auto'>
                        <div className='d-flex gap-1'>
                          <Button
                            color='primary'
                            disabled={pagination.page <= 1}
                            onClick={() => {
                              setPagination((prev) => ({
                                ...prev,
                                page: prev.page - 1
                              }))
                            }}
                            // onClick={previousPage}
                            // disabled={!canPreviousPage}
                          >
                            {'<'}
                          </Button>
                        </div>
                      </Col>
                      <Col className='col-md-auto d-none d-md-block'>
                        Pág{' '}
                        <strong>
                          {pagination.page} de{' '}
                          {Math.ceil(dataTable.length / 10)}
                        </strong>
                      </Col>
                      <Col className='col-md-auto'>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault()
                            if (!Number(pagination.page)) {
                              setPagination((prev) => ({
                                ...prev,
                                page: 1
                              }))
                            }
                            // console.log('pagination', pagination)
                          }}
                        >
                          <Input
                            type='number'
                            min={1}
                            style={{ width: 70 }}
                            // max={pageOptions.length}
                            value={pagination.page}
                            onChange={(e) => {
                              const { value } = e.target
                              if (value === '') {
                                setPagination((prev) => ({
                                  ...prev,
                                  page: ''
                                }))
                                return
                              }
                              if (!Number(value)) {
                                setPagination((prev) => ({
                                  ...prev,
                                  page: 1
                                }))
                                return
                              }
                              if (
                                Number(value) <=
                                Math.ceil(dataTable.length / 10)
                              ) {
                                setPagination((prev) => ({
                                  ...prev,
                                  page: Number(value)
                                }))
                              }
                            }}
                          />
                        </form>
                      </Col>

                      <Col className='col-md-auto'>
                        <div className='d-flex gap-1'>
                          <Button
                            color='primary'
                            disabled={
                              !data?.listQuotations?.pageInfo?.hasNextPage &&
                              pagination.page ===
                                Math.ceil(dataTable.length / 10)
                            }
                            onClick={() => {
                              if (
                                pagination.page <
                                Math.ceil(dataTable.length / 10)
                              ) {
                                return setPagination((prev) => ({
                                  ...prev,
                                  page: prev.page + 1
                                }))
                              }
                              const start = parseId(
                                data?.listQuotations?.pageInfo?.startCursor
                              )
                              setIsPagination(true)
                              setPagination((prev) => ({
                                ...prev,
                                offset: start + 10
                              }))
                            }}
                            // disabled={!canNextPage}
                          >
                            {loading ? (
                              <Spinner size='sm' color='light' />
                            ) : (
                              '>'
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                {loading && isSearch ? (
                  <div
                    className='d-flex justify-content-center align-items-center flex-column gap-2'
                    style={{ height: '20vh' }}
                  >
                    <Spinner style={{ width: '3em', height: '3em' }} />
                    <span className='d-block'>cargando...</span>
                  </div>
                ) : (
                  dataTable.length === 0 && (
                    <div
                      className='d-flex justify-content-center align-items-center'
                      style={{ height: '20vh' }}
                    >
                      No hay cotizaciones
                    </div>
                  )
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* {isOpen && (
        <ModalAddCotizAliado
          isOpen={isOpen}
          refetch={resetRefetch}
          onClose={onClose}
        />
      )} */}
    </div>
  )
}
