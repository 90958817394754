import { useQuery } from '@apollo/client'
import { useState, useEffect } from 'react'
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap'
import {
  GET_AGENCIAS_TO_EXPORT,
  GET_CATEGORIES_TO_EXPORT
} from '../../graphql/queries/getProducts'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { parseId } from '../../helpers'
import { UPPLOAD_FILE_PRODUCTS_STR } from '../../graphql/mutations/products'
import { useMutationFetch } from '../../hooks/useMutationFetch'
import { toast } from 'react-toastify'

const ModalUpploadExcel = ({
  modalUpload,
  toggleUpload,
  closeModalUppload,
  refetch
}) => {
  const { data: dataAgencies, loading: loadingAgencies } = useQuery(
    GET_AGENCIAS_TO_EXPORT,
    { fetchPolicy: 'network-only' }
  )
  const { data: dataCategories, loading: loadingCategories } = useQuery(
    GET_CATEGORIES_TO_EXPORT,
    { fetchPolicy: 'network-only' }
  )

  const [
    uploadFile,
    { data: dataUpload, loading: loadingUpload, error: errorUpload }
  ] = useMutationFetch()

  const [optionsAgencies, setOptionsAgencies] = useState([])
  const [optionsCategories, setOptionsCategories] = useState([])

  const validation = useFormik({
    initialValues: {
      agency: '',
      category: '',
      file: null
    },
    validationSchema: Yup.object({
      agency: Yup.string().required('Agencia requerida'),
      category: Yup.string().required('Categoría requerida'),
      file: Yup.mixed().required('Archivo requerido')
    }),
    onSubmit: (values) => {
      console.log('values', values)
      const formdata = new FormData()
      formdata.append('0', values.file, values.file.name)
      formdata.append('map', '{"0": ["variables.input.file"]}')
      const obj = {
        query: UPPLOAD_FILE_PRODUCTS_STR,
        variables: {
          input: {
            file: null,
            agency: values.agency,
            category: values.category
          }
        }
      }
      formdata.append('operations', JSON.stringify(obj))
      uploadFile(formdata)
    }
  })

  useEffect(() => {
    if (dataAgencies) {
      const arr = dataAgencies.listAgency.edges.map(({ node }) => ({
        label: node.name,
        value: parseId(node.id)
      }))
      setOptionsAgencies(arr)
    }
  }, [dataAgencies])

  useEffect(() => {
    if (dataCategories) {
      const arr = dataCategories.categoryList.edges.map(({ node }) => ({
        label: node.name,
        value: parseId(node.id)
      }))
      setOptionsCategories(arr)
    }
  }, [dataCategories])

  useEffect(() => {
    if (dataUpload) {
      if (dataUpload.masiveProductUpdate.success) {
        toast.success('Productos actualizados correctamente')
        refetch()
        validation.resetForm()
        closeModalUppload()
      } else {
        toast.error(`Error ${dataUpload.masiveProductUpdate.errors.message}`)
      }
    }
  }, [dataUpload])

  useEffect(() => {
    if (errorUpload) {
      toast.error(`ERROR: ${errorUpload}`)
    }
  }, [errorUpload])

  return (
    <Modal id='showModalUpload' isOpen={modalUpload} toggle={toggleUpload}>
      <ModalHeader className='bg-soft-info p-3' toggle={toggleUpload}>
        Carga masiva de productos
      </ModalHeader>
      {loadingAgencies || loadingCategories ? (
        <Spinner />
      ) : (
        <form onSubmit={validation.handleSubmit}>
          <ModalBody>
            <Row className='g-3'>
              <Col lg={12}>
                <div>
                  <Label htmlFor='name-field' className='form-label'>
                    Categoría
                  </Label>

                  <Input
                    name='category'
                    id='customername-field'
                    className='form-control'
                    placeholder='seleccione categoría'
                    type='select'
                    validate={{
                      required: { value: true }
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.category}
                    invalid={Boolean(
                      validation.touched.category && validation.errors.category
                    )}
                  >
                    <option value=''>Seleccione categoría</option>
                    {optionsCategories.map(({ label, value }) => (
                      <option key={label} value={value}>
                        {label}
                      </option>
                    ))}
                  </Input>
                  {validation.touched.category && validation.errors.category ? (
                    <FormFeedback type='invalid'>
                      {validation.errors.category}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={12}>
                <div>
                  <Label htmlFor='name-field' className='form-label'>
                    Agencia
                  </Label>

                  <Input
                    name='agency'
                    id='customername-field'
                    className='form-control'
                    placeholder='Seleccione agencia'
                    type='select'
                    validate={{
                      required: { value: true }
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.agency}
                    invalid={Boolean(
                      validation.touched.agency && validation.errors.agency
                    )}
                  >
                    <option value=''>Seleccione agencia</option>
                    {optionsAgencies.map(({ label, value }) => (
                      <option key={label} value={value}>
                        {label}
                      </option>
                    ))}
                  </Input>
                  {validation.touched.agency && validation.errors.agency ? (
                    <FormFeedback type='invalid'>
                      {validation.errors.agency}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={12}>
                <Label className='d-flex align-items-center gap-1'>
                  <i className='mdi mdi-microsoft-excel fs-4' />
                  Cargar archivo excel
                </Label>

                <Input
                  id='excel-upload'
                  placeholder='Seleccione un archivo Excel'
                  type='file'
                  accept='.xlsx, .xls'
                  name='file'
                  onBlur={validation.handleBlur}
                  invalid={Boolean(
                    validation.touched.file && validation.errors.file
                  )}
                  onChange={(e) => {
                    validation.setFieldValue('file', e.target.files[0] || null)
                  }}
                />
                {validation.touched.file && validation.errors.file && (
                  <FormFeedback type='invalid'>
                    {validation.errors.file}
                  </FormFeedback>
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className='d-flex gap-2 justify-content-end'>
              <Button
                type='button'
                color='light'
                onClick={() => {
                  validation.resetForm()
                  closeModalUppload()
                }}
              >
                Cancelar
              </Button>
              <Button
                type='submit'
                className='d-flex align-items-center gap-1'
                disabled={loadingUpload}
              >
                {loadingUpload && <Spinner size='sm' color='light' />}
                Subir archivo
              </Button>
            </div>
          </ModalFooter>
        </form>
      )}
    </Modal>
  )
}

export default ModalUpploadExcel
