import { gql } from '@apollo/client'

export const CREATE_QUOTATION = gql`
  mutation quotationCreate($quotation: QuotationInput!) {
    quotationCreate(quotation: $quotation) {
      errors {
        field
        message
      }
      quotation {
        id
        firstName
      }
      success
    }
  }
`

export const CREATE_COMMENT_QUOTATION = gql`
  mutation quotationCreateComment($comment: QuotationCommentInput!) {
    quotationCreateComment(comment: $comment) {
      errors {
        field
        message
      }
      success
      comment {
        comment
        created
      }
    }
  }
`

export const CHANGE_STATUS_QUOTATION = gql`
  mutation quotationChangeStatus($input: QuotationChangeStatusInput!) {
    quotationChangeStatus(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`

export const ADD_DISCOUNT_QUOTATION = gql`
  mutation quotationDiscountCreate($input: QuotationDiscountInput!) {
    quotationDiscountCreate(input: $input) {
      success
    }
  }
`
export const ADD_PRODUCT_TO_QUOTATION = gql`
  mutation quotationAddProduct($input: QuotationAddProductInput!) {
    quotationAddProduct(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`
