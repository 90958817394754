import React, { useEffect } from 'react'

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  FormFeedback,
  Button,
  Spinner,
  Label
} from 'reactstrap'

// Formik Validation
import * as Yup from 'yup'
import { useFormik } from 'formik'

import profileUser from '../../assets/images/profile-user.png'
import { useGetUser } from '../../hooks/user'
import { CHANGE_OWN_PASSWORD } from '../../graphql/mutations/userMutations'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

const UserProfile = () => {
  const [
    gqlChangePassword,
    { data: dataChangePass, loading: loadingChangePass, error: errorChangePass }
  ] = useMutation(CHANGE_OWN_PASSWORD)

  const { user, logout } = useGetUser()
  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      password: '',
      passwordConfirm: ''
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required('Contraseña antigua es requerida'),
      password: Yup.string()
        .required('Contraseña requerida')
        .min(8, 'La contraseña debe tener al menos 8 caracteres')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
          'La contraseña debe contener al menos una minúscula, una mayúscula y un número'
        ),
      passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
        .required('Debe confirmar la contraseña')
    }),
    onSubmit: (values) => {
      const input = { ...values }
      gqlChangePassword({
        variables: { input }
      })
    }
  })

  useEffect(() => {
    let time = 0
    if (!dataChangePass) return
    if (dataChangePass.passwordChange.success) {
      toast.success('Contraseña actualizada correctamente')
      formik.resetForm()
      time = setTimeout(() => {
        logout()
      }, 800)
    } else {
      toast.error(
        `ERROR: ${
          dataChangePass.passwordChange?.errors?.message ||
          'No se pudo cambiar contraseña'
        }`
      )
    }
    return () => {
      clearTimeout(time)
    }
  }, [dataChangePass])
  useEffect(() => {
    if (errorChangePass) toast.error(`Error: ${errorChangePass.message}`)
  }, [errorChangePass])

  document.title = 'Perfil | TOTAL SALES'
  return (
    <div className='page-content'>
      <Container fluid>
        <Row>
          <Col lg='12'>
            <Card>
              <CardBody>
                <div className='d-flex'>
                  <div className='mx-3'>
                    <img
                      src={profileUser}
                      alt=''
                      className='avatar-md rounded-circle img-thumbnail'
                    />
                  </div>
                  <div className='flex-grow-1 align-self-center'>
                    <div className='text-muted'>
                      <h5 className='text-uppercase'>{user.tokenAuth.rol}</h5>
                      <p className='mb-1'>
                        Correo: {user.tokenAuth.payload.email}
                      </p>
                      {user.tokenAuth.current?.entity && (
                        <p className='mb-0'>
                          {user.tokenAuth.current?.entity}:{' '}
                          {user.tokenAuth.current?.data?.name}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <h4 className='card-title mb-4'>Cambia contraseña</h4>

        <Card>
          <CardBody>
            <Form onSubmit={formik.handleSubmit}>
              <Row className='g-4'>
                <Col lg={12}>
                  <Label htmlFor='currentPassword'>Contraseña actual</Label>
                  <Input
                    placeholder='Ingrese contraseña actual'
                    name='currentPassword'
                    id='currentPassword'
                    type='password'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.errors.currentPassword &&
                        formik.touched.currentPassword
                    )}
                    value={formik.values.currentPassword}
                  />
                  {formik.errors.currentPassword &&
                    formik.touched.currentPassword && (
                      <FormFeedback>
                        {formik.errors.currentPassword}
                      </FormFeedback>
                    )}
                </Col>
                <Col lg={6} sm={12} xs={12}>
                  <Label htmlFor='password'>Contraseña nueva</Label>

                  <Input
                    id='password'
                    name='password'
                    type='password'
                    placeholder='Ingrese contraseña nueva'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.errors.password && formik.touched.password
                    )}
                    value={formik.values.password}
                  />
                  {formik.errors.password && formik.touched.password && (
                    <FormFeedback>{formik.errors.password}</FormFeedback>
                  )}
                </Col>
                <Col lg={6} sm={12} xs={12}>
                  <Label htmlFor='passwordConfirm'>Confirmar contraseña</Label>
                  <Input
                    placeholder='Confirme contraseña'
                    id='passwordConfirm'
                    type='password'
                    name='passwordConfirm'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.errors.passwordConfirm &&
                        formik.touched.passwordConfirm
                    )}
                    value={formik.values.passwordConfirm}
                  />
                  {formik.errors.passwordConfirm &&
                    formik.touched.passwordConfirm && (
                      <FormFeedback>
                        {formik.errors.passwordConfirm}
                      </FormFeedback>
                    )}
                </Col>
              </Row>
              <div className='d-flex justify-content-end mt-4'>
                <Button
                  className='d-flex align-items-center gap-1'
                  disabled={loadingChangePass}
                >
                  {loadingChangePass ? (
                    <Spinner color='light' size='sm' />
                  ) : (
                    <i className='bx bx-error' />
                  )}
                  Cambiar contraseña
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default UserProfile
