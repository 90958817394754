import { gql } from '@apollo/client'

export const GET_ALL_USERS = gql`
  query listUsers(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $firstName: String
    $lastName: String
    $email: String
    $currentRol: ID
    $id: Float
    $name: String
    $rol: String
  ) {
    listUsers(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      firstName: $firstName
      lastName: $lastName
      email: $email
      currentRol: $currentRol
      id: $id
      name: $name
      rol: $rol
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          id
          email
          firstName
          lastName
          isActive
          profileuser {
            phoneNumber
          }
          groups {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`
export const GET_ONE_USER_EDIT = gql`
  query listUsers($id: Float, $after: String) {
    listUsers(id: $id, after: $after) {
      edges {
        node {
          id
          email
          firstName
          lastName

          groups {
            edges {
              node {
                id
                name
              }
            }
          }
          profileuser {
            id
            agencies {
              edges {
                node {
                  id
                  name
                }
              }
            }
            zones {
              edges {
                node {
                  id
                  name
                  agencySet {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
            commercialCompany {
              id
              name
            }
            phoneNumber
          }
        }
      }
    }
  }
`
export const GET_ONE_USER_WIEW = gql`
  query listUsers($id: Float, $after: String) {
    listUsers(id: $id, after: $after) {
      edges {
        node {
          id
          email
          firstName
          lastName
          quotationcommentSet {
            edges {
              node {
                id
              }
            }
          }
          usedmotorcyclecommentSet {
            edges {
              node {
                id
                usedMotorcycle {
                  id
                }
              }
            }
          }
          quotationSet {
            edges {
              node {
                id
                billing {
                  status
                }
              }
            }
          }
          groups {
            edges {
              node {
                id
                name
              }
            }
          }
          profileuser {
            id
            agencies {
              edges {
                node {
                  name
                }
              }
            }
            zones {
              edges {
                node {
                  name
                }
              }
            }
            commercialCompany {
              name
            }
            phoneNumber
            created
          }
        }
      }
    }
  }
`

export const GET_ROLES_TO_USER = gql`
  query getRols {
    listRol {
      id
      name
    }
  }
`
export const GET_ROLES_TO_CREATE_USER = gql`
  query listGroups($after: String) {
    listGroups(after: $after) {
      edges {
        node {
          name
          id
        }
      }
    }
  }
`

export const GET_COMERCIAL_COMPANY_USER = gql`
  query listCommercialCompany {
    listCommercialCompany {
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`
export const GET_AGENCIES_USER = gql`
  query listAgency {
    listAgency {
      edges {
        node {
          id
          name
          zone {
            name
          }
        }
      }
    }
  }
`
export const GET_ZONES_USER = gql`
  query listZones {
    listZones {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`
export const GET_AGENCIES_OR_ZONES = gql`
  query zoneOrAgencies {
    listZonesAgenciesUser {
      entity
      data {
        id
        name
      }
    }
  }
`
export const ACTIVE_USER = gql`
  mutation actiateUser($input: ActivateUserInput!) {
    activateUser(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`

export const GET_ASSESORS = gql`
  query getAsesors {
    getAsesors {
      id
      name
      agencies {
        id
        name
        users {
          name
          budgetValue
          userId
          budgetId
        }
      }
    }
  }
`
