import React, { useEffect, useState } from 'react'
import { parseId, parsePrice } from '../../../helpers'
import { useQuery } from '@apollo/client'
import { GET_RESUME_OBLIGATION } from '../../../graphql/queries/getCreditsWallet'
import { toast } from 'react-toastify'
import SimpleBar from 'simplebar-react'
import { Badge, ListGroup, ListGroupItem, Spinner } from 'reactstrap'
import {
  INFO_ARR_KEYS_WALLET_RESUME,
  QOUTA_STATUS
} from '../../../helpers/consts'
import moment from 'moment'
import { useDisclosure } from '../../../hooks/useDisclosure'
import { ModalInfoOnePay } from './ModalInfoOnePay'

/**
 *
 * @param {{ obligation: {node: { id: string; value: string; term: string; montlyFee: string; endorsement: string; tea: string; initialDate: string; endDate: string; status: string;}} }} param0
 * @returns {JSX.Element}
 */
export const OneObligationInactive = ({ obligation }) => {
  const {
    data: dataResume,
    loading: loadingResume,
    error: errorResume
  } = useQuery(GET_RESUME_OBLIGATION, {
    variables: {
      obligationId: parseId(obligation.node.id)
    },
    fetchPolicy: 'no-cache'
  })

  const { isOpen, onClose, onOpen } = useDisclosure()

  const [infoOnePay, setInfoOnePay] = useState(null)

  useEffect(() => {
    if (errorResume) toast.error('Error: ' + errorResume.message)
  }, [errorResume])

  const getBadge = (status) => {
    const COLORS = {
      [QOUTA_STATUS['EN MORA']]: 'danger',
      [QOUTA_STATUS.PAGADA]: 'success',
      [QOUTA_STATUS.PARCIALMENTE_PAGADA]: 'info',
      [QOUTA_STATUS.PENDIENTE]: 'dark',
      [QOUTA_STATUS.ACTIVA]: 'primary',
      [QOUTA_STATUS.REFINANCIADA]: 'warning',
      [QOUTA_STATUS.PAGADA_CON_DESCUENTO]: 'secondary'
    }
    return (
      <Badge color={COLORS[QOUTA_STATUS[status]] || 'dark'}>
        {QOUTA_STATUS[status] || status}
      </Badge>
    )
  }

  const cuotas = obligation.node.quotaSet?.edges || []

  return (
    <>
      <div>
        <div className='table-responsive table-card'>
          <table className='table table-nowrap align-middle table-borderless mb-0'>
            <thead className='table-light text-muted'>
              <tr>
                <th className='text-center'>Valor de la obligacion</th>
                <th className='text-center'>Valor de aval</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='text-center'>
                  {parsePrice(obligation.node.value)}
                </td>
                <td className='text-center'>
                  {parsePrice(obligation.node.endorsement)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <h5>Información deuda</h5>
          {!dataResume || loadingResume ? (
            <div
              className='d-flex align-items-center justify-content-center'
              style={{ height: '215px' }}
            >
              <Spinner />
            </div>
          ) : (
            <SimpleBar style={{ maxHeight: '215px' }}>
              <ListGroup className='mb-1'>
                {INFO_ARR_KEYS_WALLET_RESUME.map((obj) => (
                  <ListGroupItem key={obj.key}>
                    <div className='d-flex align-items-center'>
                      <div className='flex-grow-1'>
                        <div className='d-flex'>
                          <div className='flex-shrink-0 avatar-xs'>
                            <div
                              className={`avatar-title bg-soft-${obj.color} text-${obj.color} rounded`}
                            >
                              <i className={obj.icon + ' fs-4'} />
                            </div>
                          </div>
                          <div className='flex-shrink-0 ms-2'>
                            <h6 className='fs-14 mb-0'>{obj.name}</h6>
                            <small className='text-muted'>
                              {obj.description}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className='flex-shrink-0'>
                        <span className='fw-bold'>
                          {parsePrice(dataResume.obligationResume[obj.key])}
                        </span>
                      </div>
                    </div>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </SimpleBar>
          )}
        </div>
        <div className='mt-4'>
          <div className='table-responsive table-card'>
            <table className='table table-nowrap align-middle table-borderless mb-0'>
              <thead className='table-light text-muted'>
                <tr>
                  <th scope='col' className='text-center'>
                    Cuota
                  </th>
                  <th scope='col' className='text-center'>
                    Estado
                  </th>
                  <th />
                  <th scope='col' className='text-center'>
                    Fecha de vencimiento
                  </th>
                  <th scope='col' className='text-center'>
                    Capital
                  </th>
                  <th scope='col' className='text-center'>
                    Interés
                  </th>
                  <th scope='col' className='text-center'>
                    Aval
                  </th>
                  <th scope='col' className='text-center'>
                    Valor de cuota{' '}
                  </th>
                  <th scope='col' className='text-center'>
                    Días vencidos
                  </th>
                  <th scope='col' className='text-center'>
                    Intereses de mora
                  </th>
                  <th scope='col' className='text-center'>
                    Gestión de cobro
                  </th>
                  <th scope='col' className='text-end'>
                    Total
                  </th>
                  <th scope='col' />
                </tr>
              </thead>
              <tbody>
                {cuotas.map(({ node }, idx) => (
                  <tr key={node.id}>
                    <th className='text-center'>{idx + 1}</th>
                    <td className='text-center'>{getBadge(node.status)}</td>
                    <td className='text-center'>
                      <div className='d-flex align-items-center gap-1'>
                        {node.payments.edges.length > 0 && (
                          <button
                            title='Ver pago'
                            className='border-0 p-0 m-0 bg-transparent'
                            onClick={() => {
                              onOpen()
                              setInfoOnePay({
                                info: node.payments.edges,
                                qouta: idx + 1,
                                endDate: node.endDate,
                                qoutaId: node.id
                              })
                            }}
                          >
                            <i className='fs-4 mdi mdi-eye text-secondary' />
                          </button>
                        )}
                      </div>
                    </td>
                    <td className='text-center'>
                      {moment(node.endDate).format('DD MMM YYYY')}
                    </td>
                    <td className='text-center'>{parsePrice(node.capital)}</td>
                    <td className='text-center'>{parsePrice(node.interest)}</td>
                    <td className='text-center'>
                      {parsePrice(node.endorsement.endorsement)}
                    </td>
                    <td className='text-center'>
                      {parsePrice(obligation.node.montlyFee)}
                    </td>
                    <td className='text-center'>
                      {moment(node.paymentDate || new Date()).diff(
                        node.endDate,
                        'days'
                      ) < 0 ? (
                        0
                      ) : (
                        <span className='text-danger'>
                          {moment(node.paymentDate || new Date()).diff(
                            node.endDate,
                            'days'
                          )}
                        </span>
                      )}
                    </td>
                    <td className='text-center'>
                      {parsePrice(node.penaltyInterest)}
                    </td>
                    <td className='text-center'>
                      {parsePrice(node.paymentManagement)}
                    </td>
                    <td className='text-center'>
                      {parsePrice(node.totalQuota)}
                    </td>
                    <td />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isOpen && infoOnePay && (
        <ModalInfoOnePay
          infoOnePay={infoOnePay}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  )
}
