import { useEffect, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
  UncontrolledDropdown
} from 'reactstrap'
import {
  GET_COSINGER_CREDIT,
  GET_FORM_CREDIT
} from '../../../graphql/queries/getCredits'
import {
  canEditOrAdd,
  getFormParse,
  hasPermission,
  parseId
} from '../../../helpers'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  ADD_COSINGER,
  DELETE_ENTITY,
  EDIT_COSINGER
} from '../../../graphql/mutations/creditMutations'
import moment from 'moment'
import { FormDinamyc } from './FormDinamyc'
import { FormDataView } from './FormDataView'
import {
  ALL_PERMISSIONS,
  ENTITIES_TO_DELETE,
  ERROR_MESSAGES
} from '../../../helpers/consts'
import { useGetUser } from '../../../hooks/user'
import { useDisclosure } from '../../../hooks/useDisclosure'
import { ModalInterchangeCosigner } from './ModalInterchangeCosigner'

const validationSchema = Yup.object().shape({
  documentNumber: Yup.string()
    .required('Número de documento es requerido')
    .matches(/^[0-9]+$/, 'El número de documento debe contener solo números')
    .max(10, 'Número muy largo')
    .min(6, 'Número demasiado corto'),
  documentType: Yup.string()
    .oneOf(['CE', 'CC'])
    .required('Tipo de documento es requerido'),
  expeditionDate: Yup.date()
    .max(new Date(), 'Fecha no válida')
    .when('dateBirth', (dateBirth, schema) => {
      if (!dateBirth) return schema
      const minDate = new Date(dateBirth)
      minDate.setFullYear(minDate.getFullYear() + 18)
      return schema.min(minDate, 'La fecha de expedición no es posible.')
    })
    .required('La fecha de expedición es obligatoria'),
  firstName: Yup.string().required('Primer nombre es requerido'),
  secondName: Yup.string(),
  firstSurname: Yup.string().required('Primer apellido es requerido'),
  secondSurname: Yup.string(),
  dateBirth: Yup.date()
    .required('Fecha de nacimiento es requerida')
    .test('is-valid-age', 'Debe ser mayor de edad', function (value) {
      const currentDate = new Date()
      const eighteenYearsAgo = new Date(currentDate)
      eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18)
      return value <= eighteenYearsAgo
    }),
  email: Yup.string()
    .email('Correo electrónico inválido')
    .required('Correo electrónico es requerido'),
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, 'El número de documento debe contener solo números')
    .max('10')
    .required('Número de teléfono es requerido')
})

export const TabCosinger = ({
  idCredit,
  refetchCredit,
  statusCredit,
  nameTitular
}) => {
  const [
    gqlAddCosinger,
    {
      data: dataAddCosinger,
      loading: loadingAddCosinger,
      error: errorAddCosinger
    }
  ] = useMutation(ADD_COSINGER)
  const [
    gqlEditCosinger,
    {
      data: dataEditCosinger,
      loading: loadingEditCosinger,
      error: errorEditCosinger
    }
  ] = useMutation(EDIT_COSINGER)
  const [
    gqlDeleteEntity,
    {
      data: dataDeleteEntity,
      loading: loadingDeleteEntity,
      error: errorDeleteEntity
    }
  ] = useMutation(DELETE_ENTITY)

  const { data, loading, error, refetch } = useQuery(GET_COSINGER_CREDIT, {
    variables: {
      creditId: parseId(idCredit)
    },
    fetchPolicy: 'no-cache'
  })
  const [
    gqlGetForm,
    { data: dataForm, loading: loadingForm, error: errorForm }
  ] = useLazyQuery(GET_FORM_CREDIT)

  const { logout, permissions } = useGetUser()

  const [showAddCosinger, setShowAddCosinger] = useState(false)
  const [namtrikForm, setNamtrikForm] = useState(null)
  /** @type {[{ id: string; firstName: string; secondName: string; firstSurname: string; secondSurname: string; documentType: 'CC' | 'CE'; documentNumber: string; dateBirth: string; expeditionDate: string; email: string; phoneNumber: string; created: string; formData: string;}, Dispatch<SetStateAction<{}>>]} */
  const [infoCosinger, setInfoCosinger] = useState(null)
  const [formDynamicValues, setFormDynamicValues] = useState(null)

  const { isOpen, onClose, onOpen } = useDisclosure()

  const [infoEdit, setInfoEdit] = useState({
    isEdit: false,
    id: null
  })
  const [infoDelete, setInfoDelete] = useState({
    isDelete: false,
    id: null,
    name: ''
  })

  const [infoInterchange, setInfoInterchange] = useState(null)

  const formik = useFormik({
    initialValues: {
      documentNumber: '',
      documentType: '',
      expeditionDate: new Date().toISOString().split('T')[0],
      firstName: '',
      secondName: '',
      firstSurname: '',
      secondSurname: '',
      dateBirth: new Date(new Date().setFullYear(new Date().getFullYear() - 18))
        .toISOString()
        .split('T')[0],
      email: '',
      phoneNumber: ''
    },
    validationSchema,
    onSubmit: (values) => {
      // Aquí puedes manejar la lógica de envío del formulario
      const input = { ...values }
      if (formDynamicValues) {
        input.form = { forms: formDynamicValues }
      }
      const creditId = parseId(idCredit)
      console.log('input', input)
      if (infoEdit.isEdit) {
        gqlEditCosinger({
          variables: {
            input: { creditId, id: parseId(infoEdit.id), ...input }
          }
        })
        return
      }
      gqlAddCosinger({
        variables: {
          input: {
            creditId,
            cosigners: [input]
          }
        }
      })
    }
  })

  useEffect(() => {
    if (error) {
      toast.error('Error: No se pudo cargar codeudor')
    }
  }, [error])

  useEffect(() => {
    if (!dataForm) return
    if (dataForm.getEntityForms && dataForm.getEntityForms.length > 0) {
      // const parse = JSON.parse(dataForm.getEntiyForm)
      setNamtrikForm(dataForm.getEntityForms.map((str) => JSON.parse(str)))
    }
  }, [dataForm])

  useEffect(() => {
    if (errorForm) {
      toast.error('Error: ' + errorForm.message)
    }
  }, [errorForm])

  useEffect(() => {
    if (errorAddCosinger) {
      toast.error('Error: ' + errorAddCosinger.message)
      if (errorAddCosinger.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorAddCosinger])

  useEffect(() => {
    if (!dataAddCosinger) return
    if (dataAddCosinger.creditAddCosigners?.success) {
      toast.success('Codeudor agregado correctamente')
      formik.resetForm()
      setShowAddCosinger(false)
      refetchCredit()
      refetch()
    } else if (dataAddCosinger?.creditAddCosigners?.errors?.message) {
      toast.error(`ERROR: ${dataAddCosinger.creditAddCosigners.errors.message}`)
    }
  }, [dataAddCosinger])

  useEffect(() => {
    if (!dataDeleteEntity) return
    if (dataDeleteEntity.creditDeleteInformation?.success) {
      toast.success('Codeudor eliminado correctamente')
      setInfoDelete({
        id: null,
        isDelete: false,
        name: ''
      })
      refetch()
    } else if (dataDeleteEntity.creditDeleteInformation?.errors?.message) {
      toast.error(
        `Error: ${dataDeleteEntity.creditDeleteInformation?.errors?.message}`
      )
    }
  }, [dataDeleteEntity])

  useEffect(() => {
    if (errorDeleteEntity) {
      toast.error('Error: ' + errorDeleteEntity.message)
    }
  }, [errorDeleteEntity])

  useEffect(() => {
    if (errorEditCosinger) {
      toast.error('Error: ' + errorEditCosinger.message)
      if (errorEditCosinger.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorEditCosinger])

  useEffect(() => {
    if (!dataEditCosinger) return
    if (dataEditCosinger.creditCosignerUpdate?.success) {
      toast.success('Codeudor agregado correctamente')
      formik.resetForm()
      setShowAddCosinger(false)
      refetchCredit()
      refetch()
    } else if (dataEditCosinger?.creditCosignerUpdate?.errors?.message) {
      toast.error(
        `ERROR: ${
          dataEditCosinger.creditCosignerUpdate.errors.message ||
          'No se pudo actualizar'
        }`
      )
    }
  }, [dataEditCosinger])

  const toggleShowAddCosinger = () => {
    setShowAddCosinger((p) => !p)
  }

  const toggleDelete = () => {
    infoDelete.isDelete &&
      setInfoDelete({
        id: null,
        isDelete: false,
        name: ''
      })
  }

  if (loading || data == null) {
    return (
      <Card>
        <CardBody
          className='d-flex align-items-center justify-content-center'
          style={{ height: '30vh' }}
        >
          <Spinner style={{ width: '3em', height: '3em' }} />
        </CardBody>
      </Card>
    )
  }
  /** @type {{node: {id: string, firstName:string, secondName: string, firstSurname: string, secondSurname: string, documentType: 'CC' | 'CE', documentNumber: string, dateBirth: string, expeditionDate: string, email: string, phoneNumber: string, created: string, formData: string}}[]} */
  const cosingers = data.getCosigners?.edges || []

  console.log('namtrikForm', namtrikForm)
  return (
    <>
      <Card>
        <CardHeader className='d-flex justify-content-between align-items-center'>
          <h4>Codeudores</h4>
          {canEditOrAdd({
            isAdd: true,
            permissionAdmin: ALL_PERMISSIONS.PUEDE_EDITAR_TODO_CREDITO,
            permissions,
            permissionToAdd: ALL_PERMISSIONS.PUEDE_AGREGAR_CODEUDOR,
            statusCredit
          }) && (
            <Button
              onClick={() => {
                setShowAddCosinger(true)
                setInfoEdit({
                  id: null,
                  isEdit: false
                })
                gqlGetForm({
                  variables: {
                    codename: 'CoSigner'
                  }
                })
              }}
            >
              Agregar codeudor
            </Button>
          )}
        </CardHeader>
        <CardBody>
          {cosingers.length > 0 ? (
            <Table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nombre(s)</th>
                  <th>Apellido(s)</th>
                  <th>Documento Id.</th>
                  <th>Email</th>
                  <th>Teléfono</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {cosingers.map(({ node }, idx) => (
                  <tr key={node.id}>
                    <td>{idx + 1}</td>
                    <td>
                      {node.firstName} {node.secondName}
                    </td>
                    <td>
                      {node.firstSurname} {node.secondSurname}
                    </td>
                    <td>
                      {node.documentType.toLowerCase()} {node.documentNumber}
                    </td>
                    <td>{node.email}</td>
                    <td>{node.phoneNumber}</td>
                    <td>
                      {/* <button
                        className='border-0 bg-transparent p-0 m-0'
                        onClick={() => setInfoCosinger(node)}
                      >
                        <i className='mdi mdi-eye-outline' />
                      </button> */}
                      <UncontrolledDropdown>
                        <DropdownToggle
                          // href='#'
                          className='btn btn-soft-secondary btn-sm'
                          tag='button'
                        >
                          <i className='ri-more-fill' />
                        </DropdownToggle>
                        <DropdownMenu className='dropdown-menu-end'>
                          <DropdownItem
                            // href='apps-ecommerce-product-details'
                            onClick={() => setInfoCosinger(node)}
                          >
                            <i className='ri-eye-fill align-bottom me-2 text-muted' />{' '}
                            Ver
                          </DropdownItem>

                          {canEditOrAdd({
                            isAdd: false,
                            permissionAdmin:
                              ALL_PERMISSIONS.PUEDE_EDITAR_TODO_CREDITO,
                            permissions,
                            permissionToEdit:
                              ALL_PERMISSIONS.PUEDE_AGREGAR_CODEUDOR,
                            statusCredit
                          }) && (
                            <DropdownItem
                              onClick={() => {
                                setShowAddCosinger(true)
                                setInfoEdit({
                                  id: node.id,
                                  isEdit: true
                                })
                                formik.setValues({
                                  documentNumber: node.documentNumber,
                                  documentType: node.documentType,
                                  expeditionDate: new Date(node.expeditionDate)
                                    .toISOString()
                                    .split('T')[0],
                                  firstName: node.firstName,
                                  secondName: node.secondName,
                                  firstSurname: node.firstSurname,
                                  secondSurname: node.secondSurname,
                                  dateBirth: new Date(node.dateBirth)
                                    .toISOString()
                                    .split('T')[0],
                                  email: node.email,
                                  phoneNumber: node.phoneNumber
                                })
                                setNamtrikForm(JSON.parse(node.formData))
                                /* gqlGetFormToEdit({
                                variables: {
                                  codename: 'CoSigner'
                                }
                              }) */
                              }}
                            >
                              <i className='ri-pencil-fill align-bottom me-2 text-muted' />{' '}
                              Editar
                            </DropdownItem>
                          )}

                          {hasPermission(
                            permissions,
                            ALL_PERMISSIONS.PUEDE_INTERCAMBIAR_CODEUDOR
                          ) && (
                            <DropdownItem
                              onClick={() => {
                                setInfoInterchange({
                                  titularName: nameTitular,
                                  cosingerName: `${node.firstName} ${node.firstSurname}`,
                                  cosignerId: node.id,
                                  creditId: idCredit
                                })
                                onOpen()
                              }}
                            >
                              <i className='mdi mdi-arrow-left-right-bold align-bottom me-2 text-muted' />{' '}
                              Intercambiar
                            </DropdownItem>
                          )}

                          {canEditOrAdd({
                            isAdd: false,
                            permissionAdmin:
                              ALL_PERMISSIONS.PUEDE_ELIMINAR_ENTIDAD_ADMIN,
                            permissions,
                            permissionToEdit:
                              ALL_PERMISSIONS.PUEDE_ELIMINAR_ENTIDAD,
                            statusCredit
                          }) && (
                            <>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={() => {
                                  setInfoDelete({
                                    isDelete: true,
                                    name: `${node.firstName} ${node.firstSurname}`,
                                    id: node.id
                                  })
                                }}
                              >
                                <i className='ri-delete-bin-fill align-bottom me-2 text-muted' />{' '}
                                Eliminar
                              </DropdownItem>
                            </>
                          )}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div
              style={{
                height: '30vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <h6>No hay codeudores</h6>
            </div>
          )}
        </CardBody>
      </Card>

      <Modal isOpen={showAddCosinger} size='lg'>
        <ModalHeader
          toggle={toggleShowAddCosinger}
          className='bg-soft-info p-4'
        >
          {infoEdit.isEdit ? 'Editar' : 'Agregar '} codeudor
          {loadingForm && <Spinner size='sm' className='ms-2' />}
        </ModalHeader>
        <form
          autoComplete='off'
          onSubmit={(e) => {
            e.preventDefault()
            const values = Object.fromEntries(new FormData(e.target))
            console.log('values', values)
            const arrayFinal = getFormParse(values)
            if (arrayFinal.length > 0) {
              setFormDynamicValues(arrayFinal)
            }
            formik.submitForm()
          }}
        >
          <ModalBody>
            <Row>
              <Col lg={6}>
                <FormGroup>
                  <Label htmlFor='firstName-cosinger'>
                    Primer nombre
                    <span
                      style={{ color: 'red', fontSize: '0.75em' }}
                      title='Requerido'
                    >
                      *
                    </span>
                  </Label>
                  <Input
                    id='firstName-cosinger'
                    name='firstName'
                    placeholder='Ingrese primer nombre'
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.firstName && formik.errors.firstName
                    )}
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <FormFeedback>{formik.errors.firstName}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Label htmlFor='secondName-cosinger'>Segundo nombre</Label>
                  <Input
                    id='secondName-cosinger'
                    name='secondName'
                    placeholder='Ingrese segundo nombre'
                    value={formik.values.secondName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.secondName && formik.errors.secondName
                    )}
                  />
                  {formik.touched.secondName && formik.errors.secondName && (
                    <FormFeedback>{formik.errors.secondName}</FormFeedback>
                  )}
                </FormGroup>
              </Col>

              <Col lg={6}>
                <FormGroup>
                  <Label htmlFor='firstSurname-cosinger'>
                    Apellido
                    <span
                      style={{ color: 'red', fontSize: '0.75em' }}
                      title='Requerido'
                    >
                      *
                    </span>
                  </Label>
                  <Input
                    id='firstSurname-cosinger'
                    name='firstSurname'
                    placeholder='Ingrese apellido'
                    value={formik.values.firstSurname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.firstSurname && formik.errors.firstSurname
                    )}
                  />
                  {formik.touched.firstSurname &&
                    formik.errors.firstSurname && (
                      <FormFeedback>{formik.errors.firstSurname}</FormFeedback>
                    )}
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Label htmlFor='secondSurname-cosinger'>
                    Segundo apellido
                  </Label>
                  <Input
                    id='secondSurname-cosinger'
                    name='secondSurname'
                    placeholder='Ingrese segundo apellido'
                    value={formik.values.secondSurname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.secondSurname &&
                        formik.errors.secondSurname
                    )}
                  />
                  {formik.touched.secondSurname &&
                    formik.errors.secondSurname && (
                      <FormFeedback>{formik.errors.secondSurname}</FormFeedback>
                    )}
                </FormGroup>
              </Col>

              <Col lg={5}>
                <FormGroup>
                  <Label htmlFor='documentType-cosinger'>
                    Tipo de documento
                    <span
                      style={{ color: 'red', fontSize: '0.75em' }}
                      title='Requerido'
                    >
                      *
                    </span>
                  </Label>
                  <Input
                    id='documentType-cosinger'
                    name='documentType'
                    type='select'
                    placeholder='Ingrese apellido'
                    value={formik.values.documentType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.documentType && formik.errors.documentType
                    )}
                  >
                    <option value=''>Seleccione un tipo</option>

                    <option value='CC'>Cédula ciudadanía</option>
                    <option value='CE'>Cédula extranjería</option>
                  </Input>
                  {formik.touched.documentType &&
                    formik.errors.documentType && (
                      <FormFeedback>{formik.errors.documentType}</FormFeedback>
                    )}
                </FormGroup>
              </Col>
              <Col lg={7}>
                <FormGroup>
                  <Label htmlFor='documentNumber-cosinger'>
                    No. documento
                    <span
                      style={{ color: 'red', fontSize: '0.75em' }}
                      title='Requerido'
                    >
                      *
                    </span>
                  </Label>
                  <Input
                    id='documentNumber-cosinger'
                    name='documentNumber'
                    placeholder='Ingrese número de documento'
                    value={formik.values.documentNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.documentNumber &&
                        formik.errors.documentNumber
                    )}
                  />
                  {formik.touched.documentNumber &&
                    formik.errors.documentNumber && (
                      <FormFeedback>
                        {formik.errors.documentNumber}
                      </FormFeedback>
                    )}
                </FormGroup>
              </Col>

              <Col lg={6}>
                <FormGroup>
                  <Label htmlFor='dateBirth-cosinger'>
                    Fecha de nacimiento
                  </Label>
                  <Input
                    type='date'
                    id='dateBirth-cosinger'
                    name='dateBirth'
                    placeholder='Ingrese fecha de nacimiento'
                    value={formik.values.dateBirth}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.dateBirth && formik.errors.dateBirth
                    )}
                  />
                  {formik.touched.dateBirth && formik.errors.dateBirth && (
                    <FormFeedback>{formik.errors.dateBirth}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Label htmlFor='expeditionDate-cosinger'>
                    Fecha de expedición
                  </Label>
                  <Input
                    type='date'
                    id='expeditionDate-cosinger'
                    name='expeditionDate'
                    placeholder='Ingrese fecha de expedición'
                    value={formik.values.expeditionDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.expeditionDate &&
                        formik.errors.expeditionDate
                    )}
                  />
                  {formik.touched.expeditionDate &&
                    formik.errors.expeditionDate && (
                      <FormFeedback>
                        {formik.errors.expeditionDate}
                      </FormFeedback>
                    )}
                </FormGroup>
              </Col>

              <Col lg={6}>
                <FormGroup>
                  <Label htmlFor='documentNumber-cosinger'>
                    Correo electrónico
                    <span
                      style={{ color: 'red', fontSize: '0.75em' }}
                      title='Requerido'
                    >
                      *
                    </span>
                  </Label>
                  <Input
                    id='email-cosinger'
                    name='email'
                    placeholder='Ingrese correo electrónico'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.email && formik.errors.email
                    )}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <FormFeedback>{formik.errors.email}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Label htmlFor='documentNumber-cosinger'>
                    Teléfono
                    <span
                      style={{ color: 'red', fontSize: '0.75em' }}
                      title='Requerido'
                    >
                      *
                    </span>
                  </Label>
                  <Input
                    id='phoneNumber-cosinger'
                    name='phoneNumber'
                    placeholder='Ingrese número de teléfono'
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={Boolean(
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    )}
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                    <FormFeedback>{formik.errors.phoneNumber}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
            </Row>
            {namtrikForm && <FormDinamyc namtrikForm={namtrikForm} />}
          </ModalBody>
          <ModalFooter>
            <Button
              color='light'
              type='button'
              onClick={() => {
                setShowAddCosinger(false)
                setInfoEdit({
                  id: null,
                  isEdit: false
                })
                formik.resetForm()
              }}
            >
              Cancelar
            </Button>
            <Button
              type='submit'
              disabled={loadingAddCosinger || loadingEditCosinger}
              className='d-flex align-items-center gap-1'
            >
              {loadingEditCosinger && <Spinner size='sm' color='light' />}
              {loadingAddCosinger && <Spinner size='sm' color='light' />}
              {infoEdit.isEdit ? 'Editar' : 'Agregar '}
            </Button>
          </ModalFooter>
        </form>
      </Modal>

      <Modal
        isOpen={Boolean(infoCosinger)}
        onClosed={() => setInfoCosinger(null)}
        toggle={() => setInfoCosinger(null)}
        size='lg'
      >
        <ModalHeader
          toggle={() => setInfoCosinger(null)}
          className='bg-soft-info p-4'
        >
          Información de {infoCosinger?.firstName}
        </ModalHeader>
        {infoCosinger && (
          <ModalBody>
            <Card id='contact-view-detail'>
              <CardBody className='text-center'>
                {/* <div className='position-relative d-inline-block'>
                <img
                  src={
                    process.env.REACT_APP_API_URL +
                    '/images/users/' +
                    (info.image_src || 'avatar-10.jpg')
                  }
                  alt=''
                  className='avatar-lg rounded-circle img-thumbnail'
                />
                <span className='contact-active position-absolute rounded-circle bg-success'>
                  <span className='visually-hidden' />
                </span>
              </div> */}
                <h5 className='mt-4 mb-1'>
                  {infoCosinger.firstSurname} {infoCosinger.secondSurname}
                </h5>
                <p className='text-muted'>
                  {infoCosinger.firstName} {infoCosinger.secondName}
                </p>

                {/* <ul className='list-inline mb-0'>
                <li className='list-inline-item avatar-xs'>
                  <Link
                    to='#'
                    className='avatar-title bg-soft-success text-success fs-15 rounded'
                  >
                    <i className='ri-phone-line' />
                  </Link>
                </li>
                <li className='list-inline-item avatar-xs'>
                  <Link
                    to='#'
                    className='avatar-title bg-soft-danger text-danger fs-15 rounded'
                  >
                    <i className='ri-mail-line' />
                  </Link>
                </li>
                <li className='list-inline-item avatar-xs'>
                  <Link
                    to='#'
                    className='avatar-title bg-soft-warning text-warning fs-15 rounded'
                  >
                    <i className='ri-question-answer-line' />
                  </Link>
                </li>
              </ul> */}
              </CardBody>
              <CardBody>
                <h6 className='text-muted text-uppercase fw-semibold mb-3'>
                  Información personal
                </h6>
                {/* <p className='text-muted mb-4'>
                Hello, I'm {infoCosinger..name || 'Tonya Noble'}, The most effective
                objective is one that is tailored to the job you are applying
                for. It states what kind of career you are seeking, and what
                skills and experiences.
              </p> */}
                <div className='table-responsive table-card'>
                  <Table className='table table-borderless mb-0'>
                    <tbody>
                      <tr>
                        <td className='fw-medium'>Documento</td>
                        <td>
                          {infoCosinger.documentType}{' '}
                          {infoCosinger.documentNumber}
                        </td>
                      </tr>
                      <tr>
                        <td className='fw-medium'>Fecha de nacimiento</td>
                        <td>
                          {moment(infoCosinger.dateBirth).format(
                            'DD MMM [del] YYYY'
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className='fw-medium'>Fecha de expedición</td>
                        <td>
                          {moment(infoCosinger.expeditionDate).format(
                            'DD MMM [del] YYYY'
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className='fw-medium'>Teléfono</td>
                        <td>{infoCosinger.phoneNumber}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                {infoCosinger.formData && (
                  <FormDataView data={infoCosinger.formData} />
                )}
              </CardBody>
            </Card>
          </ModalBody>
        )}
      </Modal>

      <Modal isOpen={infoDelete.isDelete} toggle={toggleDelete} centered>
        <ModalBody>
          <h4>
            ¿Seguro desea eliminar a{' '}
            <span className='text-info text-uppercase'>{infoDelete.name}</span>{' '}
            como codeudor?
          </h4>
        </ModalBody>
        <div className='p-2 m-2 d-flex gap-1'>
          <Button
            color='light'
            block
            onClick={() => {
              setInfoDelete({ id: null, isDelete: false, name: '' })
            }}
            disabled={loadingDeleteEntity}
          >
            No
          </Button>
          <Button
            color='danger'
            block
            disabled={loadingDeleteEntity}
            onClick={() => {
              if (!infoDelete.id) return
              const idDelete = parseId(infoDelete.id)
              const input = {
                entity: ENTITIES_TO_DELETE.CODEUDOR,
                id: idDelete
              }
              gqlDeleteEntity({
                variables: { input }
              })
            }}
          >
            <span className='d-flex align-items-center gap-1 justify-content-center'>
              {loadingDeleteEntity ? (
                <Spinner size='sm' color='light' />
              ) : (
                <i className='fs-4 mdi mdi-trash-can-outline' />
              )}
              Si
            </span>
          </Button>
        </div>
      </Modal>

      {isOpen && infoInterchange && (
        <ModalInterchangeCosigner
          isOpen={isOpen}
          onClose={onClose}
          info={infoInterchange}
          refetchCredit={refetchCredit}
          refetchCosigners={refetch}
        />
      )}
    </>
  )
}
