/* eslint-disable react/jsx-indent */
import React, { Fragment } from 'react'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { InputCurrency } from '../InputCurrency'

const TYPES = {
  boolean: 'checkbox',
  float: 'text',
  int: 'number',
  text: 'text',
  email: 'email',
  date: 'date',
  file: 'file',
  datetime: 'datetime-local',
  money: 'text',
  select: 'select',
  tel: 'tel'
}

/** @param {{namtrikForm: {form_id: string, form: string, description: string,sections: {section_id: string, description: string, section: string, fields: {name: string, attrs: {type: string, label: string, placeholder: string, required: boolean, choices?: string[]}}[]}[]}[]}} */
export const FormDinamyc = ({ namtrikForm }) => {
  /**
   * @returns {'file' | 'number' | 'checkbox' | 'date' | 'file' | 'text'}
   * @param {string} type
   */
  const getTypeForm = (type) => {
    return TYPES[type] || 'text'
  }
  /**
   * @param {string} num
   * @returns {string}
   */
  const getNumber = (num) => {
    const parseNum = num
      .replace(/[^\d.,]/g, '')
      .replace(/\./g, '')
      .replace(/,/g, '.')
    const strMoney = window.localStorage.getItem('money')
    if (!strMoney) return parseNum
    const { minimumfractiondigits } = JSON.parse(strMoney)
    if (!minimumfractiondigits) return parseNum
    return Number(parseNum).toFixed(Number(minimumfractiondigits))
  }
  /* const parseMoneyInput = (e) => {
    const newValue = e.target.value.replace(/\D/g, '')
    if (newValue === '') return (e.target.value = '')
    e.target.value = Number(newValue).toLocaleString()
  } */
  const parseFloatInput = (e) => {
    const input = e.target
    const valorValidado = input.value.replace(/[^\d,]/g, '') // Elimina caracteres no válidos

    // Verifica que no empiece con coma
    if (/^,/.test(valorValidado)) {
      input.value = ''
    } else if (/^0[^,]/.test(valorValidado)) {
      // Si comienza con cero, solo permitimos que la coma le siga directamente (por ejemplo, 0,5)
      input.value = valorValidado.substring(0, 1)
    } else {
      const comaIndex = valorValidado.indexOf(',')

      // Si hay más de una coma, la eliminamos
      if (
        comaIndex !== -1 &&
        valorValidado.indexOf(',', comaIndex + 1) !== -1
      ) {
        /* const valorSinComaExtra =
          valorValidado.substring(0, comaIndex) +
          valorValidado.substring(comaIndex + 1) */
        input.value = input.value.substring(
          0,
          valorValidado.indexOf(',', comaIndex + 1)
        )
      } else {
        input.value = valorValidado
      }
    }
  }
  return (
    <Row>
      {namtrikForm.map((form) => (
        <Fragment key={form.form_id}>
          <h5 className='text-center'>{form.form}</h5>
          <figure>
            <blockquote className='blockquote'>
              <p className='ms-1 text-muted lead'>{form.description}</p>
            </blockquote>
          </figure>
          {form.sections.map((section) => (
            <Fragment key={section.section_id}>
              <h6 className='ms-4 text-info'>{section.section}</h6>
              <blockquote className='blockquote'>
                <p className='ms-2' style={{ color: '#6396c3' }}>
                  {section.description}
                </p>
              </blockquote>
              {section.fields.map((field) => (
                <Col lg={12} key={field.name}>
                  <FormGroup check={field.attrs.type === 'boolean'}>
                    <Label
                      htmlFor={`${form.form_id}-${section.section_id}-${field.name}`}
                    >
                      {field.attrs.label}
                      {field.attrs.required && (
                        <span style={{ color: '#FF0000' }} title='Requerido'>
                          *
                        </span>
                      )}
                    </Label>
                    {field.attrs.type === 'boolean' && (
                      <input
                        type='hidden'
                        value=''
                        name={`${form.form_id}-${section.section_id}-${field.name}`}
                      />
                    )}
                    {field.attrs.type === 'money' ? (
                      <InputCurrency
                        id={`${form.form_id}-${section.section_id}-${field.name}`}
                        name={`${form.form_id}-${section.section_id}-${field.name}`}
                        placeholder={field.attrs.placeholder}
                        required={field.attrs.required}
                        defaultValue={
                          field.value
                            ? /^\d+(\.\d+)?$/.test(field.value)
                              ? field.value
                              : getNumber(field.value)
                            : undefined
                        }
                      />
                    ) : field.attrs.type === TYPES.select ? (
                      <Input
                        placeholder={field.attrs.placeholder}
                        required={field.attrs.required}
                        type={getTypeForm(field.attrs.type)}
                        id={`${form.form_id}-${section.section_id}-${field.name}`}
                        defaultValue={
                          field.attrs.type !== 'boolean' ? field.value : 'on'
                        }
                        name={`${form.form_id}-${section.section_id}-${field.name}`}
                        step={
                          field.attrs.type === 'int'
                            ? '0'
                            : field.attrs.type === 'float'
                            ? '.001'
                            : undefined
                        }
                        onInput={
                          field.attrs.type === 'float'
                            ? parseFloatInput
                            : undefined
                        }
                        defaultChecked={
                          field.attrs.type === 'boolean'
                            ? Boolean(field.value)
                            : undefined
                        }
                      >
                        {field.attrs.type === TYPES.select && (
                          <option value=''>
                            {field.attrs.placeholder || 'Seleccione una opción'}
                          </option>
                        )}
                        {field.attrs.type === TYPES.select &&
                          field.attrs.choices?.length > 0 &&
                          field.attrs.choices.map((value) => (
                            <option key={value} value={value}>
                              {value}
                            </option>
                          ))}
                      </Input>
                    ) : (
                      <Input
                        placeholder={field.attrs.placeholder}
                        required={field.attrs.required}
                        type={getTypeForm(field.attrs.type)}
                        id={`${form.form_id}-${section.section_id}-${field.name}`}
                        defaultValue={
                          field.attrs.type !== 'boolean' ? field.value : 'on'
                        }
                        name={`${form.form_id}-${section.section_id}-${field.name}`}
                        step={
                          field.attrs.type === 'int'
                            ? '0'
                            : field.attrs.type === 'float'
                            ? '.001'
                            : undefined
                        }
                        onInput={
                          field.attrs.type === 'float'
                            ? parseFloatInput
                            : undefined
                        }
                        defaultChecked={
                          field.attrs.type === 'boolean'
                            ? Boolean(field.value)
                            : undefined
                        }
                      />
                    )}
                  </FormGroup>
                </Col>
              ))}
            </Fragment>
          ))}
        </Fragment>
      ))}
    </Row>
  )
}
