import { gql } from '@apollo/client'

export const CREATE_BILLING = gql`
  mutation billingCreate($billing: BillingInput!) {
    billingCreate(billing: $billing) {
      billing {
        id
      }
      errors {
        field
        message
      }
      success
    }
  }
`
export const CREATE_BILLING_STR = `
  mutation billingCreate($billing: BillingInput!) {
    billingCreate(billing: $billing) {
      billing {
        id
      }
      errors {
        field
        message
      }
      success
    }
  }
`

export const CLOSE_BILLING_STR = `
mutation billingClose($input: BillingCloseInput!){
  billingClose(input: $input){
    success
    errors{
      field
      message
    }
  }
}
`
export const CREATE_COMMET_BILLING = gql`
  mutation billingComment($input: BillingCommentCreateMutationInput!) {
    billingComment(input: $input) {
      comment {
        id
      }
      errors {
        messages
        field
      }
    }
  }
`
export const DELETE_BILLING = gql`
  mutation billingDelete($input: BillingDeleteInput!) {
    billingDelete(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`
export const APPROVE_DISCOUNT = gql`
  mutation approveBillingDiscount($input: ApproveBillingDiscountInput!) {
    approveBillingDiscount(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`

export const APPROVE_RESIDUE = gql`
  mutation approveResidue($input: ApproveResidueInput!) {
    approveResidue(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`
