import React, { useEffect } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from 'reactstrap'
import { REQUEST_DISBURSEMENT } from '../../../graphql/mutations/creditMutations'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { parseId } from '../../../helpers'

export const ModalReqDisbursement = ({
  refetchDataCredit,
  toggleReqDisbur,
  idCredit,
  showReqDisbur
}) => {
  const [
    gqlReqDisbursement,
    { data: dataClose, loading: loadingClose, error: errorClosex }
  ] = useMutation(REQUEST_DISBURSEMENT)

  useEffect(() => {
    if (!dataClose) return
    if (dataClose?.disbursementRequestCreate?.success) {
      toast.success('Solicitud de desembolso creada con éxito')
      refetchDataCredit()
      toggleReqDisbur()
    } else if (dataClose?.disbursementRequestCreate?.errors) {
      toast.error(
        'ERROR: ' + dataClose?.disbursementRequestCreate?.errors.message ||
          'No se pudo cerrar realizar solicitud de desembolso'
      )
    }
  }, [dataClose])

  useEffect(() => {
    if (!errorClosex) return
    if (errorClosex) {
      toast.error(`ERROR: ${errorClosex.message}`)
    }
  }, [errorClosex])

  const handleCloseCredit = () => {
    if (!idCredit) return
    gqlReqDisbursement({
      variables: {
        input: {
          creditId: parseId(idCredit)
        }
      }
    })
  }
  return (
    <Modal isOpen={showReqDisbur} toggle={toggleReqDisbur} centered>
      <ModalHeader className='bg-soft-info p-4' toggle={toggleReqDisbur}>
        Solicitar desembolso
      </ModalHeader>
      <ModalBody>
        <p className='fw-semibold fs-4'>
          ¿Seguro que desea solicitar desembolso?
        </p>
      </ModalBody>
      <ModalFooter className='d-flex align-items-center justify-content-center gap-2'>
        <Button
          color='light'
          className='flex-1 text-center'
          onClick={() => toggleReqDisbur()}
        >
          No
        </Button>
        <Button
          color='danger'
          className='flex-1 text-center d-flex gap-1 align-items-center justify-content-center'
          onClick={handleCloseCredit}
        >
          {loadingClose && <Spinner size='sm' color='light' />}
          SI
        </Button>
      </ModalFooter>
    </Modal>
  )
}
