import moment from 'moment'
import React from 'react'
import { Card, CardBody, Col, Placeholder } from 'reactstrap'

export const LoadingBudgets = ({ init, end }) => {
  return (
    <>
      <Col lg={4} md={6}>
        <Card className='card-animate'>
          <CardBody>
            <div className='d-flex align-items-center'>
              <div className='flex-grow-1 overflow-hidden'>
                <p className='text-uppercase fw-medium text-muted text-truncate mb-0'>
                  Presupuesto
                </p>
              </div>
              {/* <div className='flex-shrink-0'>
                  <h5 className={'fs-14 mb-0 text-' + item.badgeClass}>
                    {item.badge ? (
                      <i className={'fs-13 align-middle ' + item.badge}></i>
                    ) : null}{' '}
                    {item.percentage} %
                  </h5>
                </div> */}
            </div>
            <div className='d-flex align-items-end justify-content-between mt-4'>
              <div>
                <h4 className='fs-22 fw-semibold ff-secondary mb-4'>
                  <span className='counter-value' data-target='559.25'>
                    <Placeholder
                      tag='div'
                      className='w-100 h-100 bg-soft-dark'
                      size='lg'
                      xs={12}
                      animation='wave'
                    />
                  </span>
                </h4>
                <span
                  className='text-decoration-underline'
                  style={{ fontSize: '11px' }}
                >
                  Meta de ventas
                </span>
              </div>
              <div className='avatar-sm flex-shrink-0'>
                <span className='avatar-title rounded fs-3 bg-soft-info'>
                  <i className='text-info mdi mdi-clipboard-clock-outline' />
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col lg={4} md={6}>
        <Card className='card-animate'>
          <CardBody>
            <div className='d-flex align-items-center'>
              <div className='flex-grow-1 overflow-hidden'>
                <p className='text-uppercase fw-medium text-muted text-truncate mb-0'>
                  Total facturado
                </p>
              </div>
              {/* <div className='flex-shrink-0'>
                  <h5 className={'fs-14 mb-0 text-' + item.badgeClass}>
                    {item.badge ? (
                      <i className={'fs-13 align-middle ' + item.badge}></i>
                    ) : null}{' '}
                    {item.percentage} %
                  </h5>
                </div> */}
            </div>
            <div className='d-flex align-items-end justify-content-between mt-4'>
              <div>
                <h4 className='fs-22 fw-semibold ff-secondary mb-4'>
                  <span className='counter-value' data-target='559.25'>
                    <Placeholder
                      tag='div'
                      className='w-100 h-100 bg-soft-dark'
                      size='lg'
                      xs={12}
                      animation='wave'
                    />
                  </span>
                </h4>
                <span
                  className='text-decoration-underline'
                  style={{ fontSize: '11px' }}
                >
                  Entre {moment(init).format('DD MMM')} y{' '}
                  {moment(end).format('DD MMM')}
                </span>
              </div>
              <div className='avatar-sm flex-shrink-0'>
                <span className='avatar-title rounded fs-3 bg-soft-success'>
                  <i className='text-success mdi mdi-cash-check' />
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col lg={4} md={6}>
        <Card className='card-animate'>
          <CardBody>
            <div className='d-flex align-items-center'>
              <div className='flex-grow-1 overflow-hidden'>
                <p className='text-uppercase fw-medium text-muted text-truncate mb-0'>
                  Cumplimiento
                </p>
              </div>
              {/* <div className='flex-shrink-0'>
                  <h5 className={'fs-14 mb-0 text-' + item.badgeClass}>
                    {item.badge ? (
                      <i className={'fs-13 align-middle ' + item.badge}></i>
                    ) : null}{' '}
                    {item.percentage} %
                  </h5>
                </div> */}
            </div>
            <div className='d-flex align-items-end justify-content-between mt-4'>
              <div>
                <h4 className='fs-22 fw-semibold ff-secondary mb-4'>
                  <span className='counter-value' data-target='559.25'>
                    <Placeholder
                      tag='div'
                      className='w-75 h-100 bg-soft-dark'
                      size='lg'
                      xs={12}
                      animation='wave'
                    />
                  </span>
                </h4>
                <span
                  className='text-decoration-underline'
                  style={{ fontSize: '11px' }}
                >
                  Porcentaje de cumplimiento
                </span>
              </div>
              <div className='avatar-sm flex-shrink-0'>
                <span className='avatar-title rounded fs-3 bg-soft-warning'>
                  <i className='text-warning mdi mdi-percent-outline' />
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  )
}
