import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  FormFeedback,
  FormGroup
} from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { InputCurrency } from '../InputCurrency'
import { useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { ADD_COTIZACION_ALIADO } from '../../../graphql/mutations/cotizacionAliadosMutation'
import { STORAGE_NAMES } from '../../../helpers/consts'

const initialValues = {
  firstName: '',
  secondName: '',
  firstSurname: '',
  secondSurname: '',
  phoneNumber: '',
  documentNumber: '',
  product: '',
  productValue: '',
  initialFee: '',
  creditLine: ''
}

const validationSchema = Yup.object({
  firstName: Yup.string().required('El primer nombre es requerido'),
  secondName: Yup.string(),
  firstSurname: Yup.string().required('El primer apellido es requerido'),
  secondSurname: Yup.string(),
  phoneNumber: Yup.string()
    .matches(/^\d+$/, 'Solo se permiten números')
    .max(15, 'El número de teléfono debe tener como máximo 15 caracteres')
    .required('El número de teléfono es requerido'),
  documentNumber: Yup.string()
    .matches(/^[0-9]+$/, 'Debe contener solo números')
    .max(10, 'Número demasiado largo')
    .min(6, 'Número demasiado corto'),
  productValue: Yup.string().required('El valor del producto es requerido'),
  product: Yup.string().required('El producto es requerido'),
  creditLine: Yup.string().required('Campo requerido')
})

export const ModalAddCotizAliado = ({ isOpen, onClose, refetch }) => {
  const [gqlAddCot, { data, loading, error }] = useMutation(
    ADD_COTIZACION_ALIADO
  )

  const [optionsCreditLine, setOptionsCreditLine] = useState([])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const input = { ...values }
      input.initialFee = values.initialFee ? values.initialFee : '0'
      gqlAddCot({ variables: { input } })
    }
  })

  useEffect(() => {
    const strArr = window.localStorage.getItem(STORAGE_NAMES.TYPE_COTIZ)
    if (!strArr) return
    const arr = JSON.parse(strArr)
    setOptionsCreditLine(arr)
  }, [])

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error.message}`)
    }
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.allyQuoteCreate.success) {
      toast.success('Cotización creada correctamente')
      onClose()
      refetch()
    } else if (data.allyQuoteCreate.errors) {
      toast.error(
        `Error ${data.allyQuoteCreate?.errors?.message || 'Algo ocurrió'}`
      )
    }
  }, [data])

  console.log('formik.values.creditLine', formik.values.creditLine)

  return (
    <Modal isOpen={isOpen} toggle={onClose} size='xl'>
      <ModalHeader className='bg-soft-info p-4' toggle={onClose}>
        Agregar cotización de aliado
      </ModalHeader>
      <Form id='form-change-revition-credit' onSubmit={formik.handleSubmit}>
        <ModalBody>
          <Row className='g-3'>
            <Col lg={6}>
              <div>
                <Label htmlFor='firstName-field' className='form-label'>
                  Nombre
                  <span style={{ color: 'red' }} title='Obligatorio'>
                    *
                  </span>
                </Label>
                <Input
                  type='text'
                  name='firstName'
                  id='firstName-field'
                  placeholder='Ingrese su primer nombre'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  invalid={Boolean(
                    formik.touched.firstName && formik.errors.firstName
                  )}
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <FormFeedback>{formik.errors.firstName}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <Label htmlFor='secondName-field' className='form-label'>
                  Segundo nombre
                </Label>
                <Input
                  type='text'
                  name='secondName'
                  id='secondName-field'
                  placeholder='Ingrese su segundo nombre'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.secondName}
                  invalid={Boolean(
                    formik.touched.secondName && formik.errors.secondName
                  )}
                />
                {formik.touched.secondName && formik.errors.secondName ? (
                  <FormFeedback>{formik.errors.secondName}</FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col lg={6}>
              <div>
                <Label htmlFor='firstSurname-field' className='form-label'>
                  Apellido
                  <span style={{ color: 'red' }} title='Obligatorio'>
                    *
                  </span>
                </Label>
                <Input
                  type='text'
                  name='firstSurname'
                  id='firstSurname-field'
                  placeholder='Ingrese su primer apellido'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstSurname}
                  invalid={Boolean(
                    formik.touched.firstSurname && formik.errors.firstSurname
                  )}
                />
                {formik.touched.firstSurname && formik.errors.firstSurname ? (
                  <FormFeedback>{formik.errors.firstSurname}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <Label htmlFor='secondSurname-field' className='form-label'>
                  Segundo apellido
                </Label>
                <Input
                  type='text'
                  name='secondSurname'
                  id='secondSurnname-field'
                  placeholder='Ingrese su segundo apellido'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.secondSurname}
                  invalid={Boolean(
                    formik.touched.secondSurname && formik.errors.secondSurname
                  )}
                />
                {formik.touched.secondSurname && formik.errors.secondSurname ? (
                  <FormFeedback>{formik.errors.secondSurname}</FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col lg={6}>
              <div>
                <Label htmlFor='phoneNumber-field' className='form-label'>
                  Número de teléfono
                  <span style={{ color: 'red' }} title='Obligatorio'>
                    *
                  </span>
                </Label>
                <Input
                  type='tel'
                  name='phoneNumber'
                  id='phoneNumber-field'
                  placeholder='Ingrese su número de teléfono'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                  invalid={Boolean(
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  )}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                  <FormFeedback>{formik.errors.phoneNumber}</FormFeedback>
                ) : null}
              </div>
            </Col>
            {/* <Col lg={6}>
              <div>
                <Label htmlFor='email-field' className='form-label'>
                  Correo electrónico
                </Label>
                <Input
                  type='email'
                  name='email'
                  id='email-field'
                  placeholder='Ingrese su correo electrónico'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  invalid={Boolean(formik.touched.email && formik.errors.email)}
                />
                {formik.touched.email && formik.errors.email ? (
                  <FormFeedback>{formik.errors.email}</FormFeedback>
                ) : null}
              </div>
            </Col> */}
            <Col lg={6}>
              <div>
                <Label htmlFor='documentNumber-field' className='form-label'>
                  Número de documento
                </Label>
                <Input
                  type='text'
                  name='documentNumber'
                  id='documentNumber-field'
                  placeholder='Ingrese su número de documento'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.documentNumber}
                  invalid={Boolean(
                    formik.touched.documentNumber &&
                      formik.errors.documentNumber
                  )}
                />
                {formik.touched.documentNumber &&
                formik.errors.documentNumber ? (
                  <FormFeedback>{formik.errors.documentNumber}</FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col lg={6}>
              <div>
                <FormGroup>
                  <Label for='exampleSelect2'>
                    Seleccione linea crédito{' '}
                    <span style={{ color: 'red' }} title='Obligatorio'>
                      *
                    </span>
                  </Label>
                  <Input
                    id='exampleSelect2'
                    name='creditLine'
                    type='select'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.creditLine}
                    invalid={Boolean(
                      formik.touched.creditLine && formik.errors.creditLine
                    )}
                  >
                    <option value=''>Seleccione una línea</option>
                    {optionsCreditLine.map(({ node }) => (
                      <option key={node.id} value={node?.name?.toUpperCase()}>
                        {node.legibleName}
                      </option>
                    ))}
                  </Input>
                  {formik.touched.creditLine && formik.errors.creditLine ? (
                    <FormFeedback>{formik.errors.creditLine}</FormFeedback>
                  ) : null}
                </FormGroup>
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <Label htmlFor='documentNumber-field' className='form-label'>
                  Producto
                  <span style={{ color: 'red' }} title='Obligatorio'>
                    *
                  </span>
                </Label>
                <Input
                  type='text'
                  name='product'
                  id='product-field'
                  placeholder='Ingrese el nombre del producto y referencia'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.product}
                  invalid={Boolean(
                    formik.touched.product && formik.errors.product
                  )}
                />
                {formik.touched.product && formik.errors.product ? (
                  <FormFeedback>{formik.errors.product}</FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col lg={6}>
              <div>
                <Label htmlFor='productValue-field' className='form-label'>
                  Valor del producto
                  <span style={{ color: 'red' }} title='Obligatorio'>
                    *
                  </span>
                </Label>
                <InputCurrency
                  name='productValue'
                  id='productValue-field'
                  // placeholder='Ingrese su número de documento'
                  onValueChange={(v, n) => formik.setFieldValue(n, v)}
                  onBlur={() => formik.setFieldTouched('productValue')}
                  value={formik.values.productValue}
                  /* invalid={Boolean(
                    formik.touched.productValue && formik.errors.productValue
                  )} */
                  className={`form-control ${
                    formik.touched.productValue && formik.errors.productValue
                      ? 'is-invalid'
                      : ''
                  } `}
                />
                {formik.touched.productValue && formik.errors.productValue ? (
                  <FormFeedback>{formik.errors.productValue}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <Label htmlFor='initialFee-field' className='form-label'>
                  Cuota inicial
                </Label>
                <InputCurrency
                  name='initialFee'
                  id='initialFee-field'
                  placeholder='Ingrese cuota incial'
                  onValueChange={(v, n) => formik.setFieldValue(n, v)}
                  onBlur={() => formik.setFieldTouched('initialFee')}
                  value={formik.values.initialFee}
                  /* invalid={Boolean(
                    formik.touched.initialFee && formik.errors.initialFee
                  )} */
                  className={`form-control ${
                    formik.touched.initialFee && formik.errors.initialFee
                      ? 'is-invalid'
                      : ''
                  } `}
                />
                {formik.touched.initialFee && formik.errors.initialFee ? (
                  <FormFeedback>{formik.errors.initialFee}</FormFeedback>
                ) : null}
              </div>
            </Col>

            {/* <Col lg={6}>
              <div>
                <Label htmlFor='quotationType-field' className='form-label'>
                  Tipo de cotización
                  <span style={{ color: 'red' }} title='Obligatorio'>
                    *
                  </span>
                </Label>
                <Input
                  type='select'
                  name='quotationType'
                  id='quotationType-field'
                  onChange={(e) => {
                    formik.handleChange(e)
                    setProductsSelected([])
                    setOptionsData((prev) => {
                      const arr = [...prev]
                      return arr.map((obj) => {
                        const newObj = { ...obj }
                        delete newObj.isDisabled
                        return newObj
                      })
                    })
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.quotationType}
                  invalid={Boolean(
                    formik.touched.quotationType && formik.errors.quotationType
                  )}
                >
                  <option value='CONTADO'>Contado</option>
                  <option value='CREDITO'>Crédito</option>
                  <option value='TERCEROS'>Terceros</option>
                </Input>
                {formik.touched.quotationType && formik.errors.quotationType ? (
                  <FormFeedback>{formik.errors.quotationType}</FormFeedback>
                ) : null}
              </div>
            </Col> */}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color='light'
            type='button'
            onClick={() => {
              onClose()
            }}
          >
            Cancelar
          </Button>
          <Button
            type='submit'
            disabled={loading}
            className='d-flex align-items-center gap-1'
          >
            {loading && <Spinner color='light' size='sm' />}
            Agregar cotización
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
