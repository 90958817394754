import { gql } from '@apollo/client'

export const CREATE_MOTO_USADA = gql`
  mutation usedMotorcycleCreateUdate(
    $input: UsedMotorcycleCreateUpdateMutatationInput!
  ) {
    usedMotorcycleCreateUpdate(input: $input) {
      clientMutationId
      errors {
        field
        messages
      }
      usedMotorcycle {
        id
      }
    }
  }
`

export const CREATE_COMMENT_USED_MOTOS = gql`
  mutation ($input: UsedMotorcycleCommentCreateMutationInput!) {
    usedMotorcycleComment(input: $input) {
      comment {
        id
      }
      errors {
        messages
      }
    }
  }
`
export const CHANGE_CELLAR_USED_MOTO = gql`
  mutation changeCellar($input: ChangeCellarUsedMotorcycleInput!) {
    changeCellarUsedMotorcycle(input: $input) {
      errors {
        field
        message
      }
      success
    }
  }
`
