import React, { useEffect } from 'react'
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap'
import { parseId } from '../../../helpers'
import { useMutation } from '@apollo/client'
import { CHANGE_CELLAR_USED_MOTO } from '../../../graphql/mutations/motosUsadas'
import { toast } from 'react-toastify'

export const ModalChangeCellar = ({
  showCellar,
  currentCellar,
  toggleCellar,
  cellars,
  refetchMoto,
  usedMotorcycleId
}) => {
  const [
    gqlChangeCellar,
    { data: dataChange, loading: loadingChange, error: errorChange }
  ] = useMutation(CHANGE_CELLAR_USED_MOTO)

  useEffect(() => {
    if (!dataChange) return
    if (dataChange.changeCellarUsedMotorcycle?.success) {
      toast.success('Bodega actualizada correctamente')
      refetchMoto()
      toggleCellar()
    } else if (dataChange.changeCellarUsedMotorcycle.errors?.message) {
      toast.error(
        'Error: ' + dataChange.changeCellarUsedMotorcycle.errors?.message ||
          'No se pudo cambiar de bodega'
      )
    }
  }, [dataChange])

  useEffect(() => {
    if (errorChange) {
      toast.error(`Error: ${errorChange.message}`)
    }
  }, [errorChange])

  return (
    <Modal isOpen={showCellar} toggle={toggleCellar}>
      <ModalHeader className='bg-soft-info p-4' toggle={toggleCellar}>
        Cambiar de bodega
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault()
          const { cellar, comment } = Object.fromEntries(new FormData(e.target))
          console.log('values--->', cellar, 'comment', comment)
          if (!cellar || !comment) {
            window.alert('Complete los campos')
            return
          }
          gqlChangeCellar({
            variables: {
              input: {
                cellarId: cellar,
                usedMotorcycleId: parseId(usedMotorcycleId),
                comment
              }
            }
          })
        }}
      >
        <ModalBody>
          <h6>
            Bodega actual: <b>{currentCellar}</b>
          </h6>
          <Row>
            <Col lg={12}>
              <FormGroup>
                <Input required name='cellar' type='select'>
                  <option value=''>Seleccione bodega</option>
                  {cellars.map(({ id, city, name }) => (
                    <option key={id} value={parseId(id)}>
                      {name} - {city}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col lg={12}>
              <FormGroup>
                <Input
                  required
                  type='textarea'
                  placeholder='Ingrese un comentario'
                  name='comment'
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color='light'
            onClick={() => {
              toggleCellar()
            }}
            type='button'
          >
            Cancelar
          </Button>
          <Button
            color='danger'
            type='submit'
            disabled={loadingChange}
            className='d-flex align-items-center gap-1'
          >
            {loadingChange && <Spinner size='sm' color='light' />}
            Cambiar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
