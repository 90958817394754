import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Input,
  Spinner,
  Table
} from 'reactstrap'
// import TableContainer from '../../../Components/Common/TableContainer'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
/*
import {
    useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table' */
import { useLazyQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { getColorBg, getIcon, parseId } from '../../../helpers'
import { ERROR_MESSAGES } from '../../../helpers/consts'
import { useGetUser } from '../../../hooks/user'
import { GET_ALL_FORMATS } from '../../../graphql/queries/getFormats'

export default function FormatsPage() {
  const [gqlGetFormats, { data, loading, error }] = useLazyQuery(
    GET_ALL_FORMATS,
    {
      fetchPolicy: 'no-cache'
    }
  )
  const { logout } = useGetUser()
  /**
   * @typedef {{node: {id: string, firstName: string, firstSurname: string, agency: { id: string, name: string }, productinformationSet: { edges: { node: { id: string, name: string }}[] }, lastComment: { id: string, comment: string, created: string, user: { id: string, firstName: string, lastName: string }},cosignerSet: { edges: {node:{id:string, firstName: string}}[]}}}} IData
   * @type {[IData[], React.Dispatch<React.SetStateAction<IData[]>>]}
   */
  const [dataTable, setDataTable] = useState([])

  // const [isSearch, setIsSearch] = useState(false)

  /* const [searchInput, setSearchInput] = useState('')
  const [searchValue, setSearchValue] = useState({
    key: '',
    value: ''
  }) */
  const [pagination, setPagination] = useState({
    offset: 0,
    page: 1
  })
  const [isPagination, setIsPagination] = useState(false)

  /* useEffect(() => {
    if (!hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_CREDITOS)) {
      navigate('/dashboard')
    }
  }, [permissions]) */

  useEffect(() => {
    const variables = {
      offset: pagination.offset
    }
    /* if (searchValue.key) {
      variables[searchValue.key] = searchValue.value
    } */
    // console.log('variables', variables)
    gqlGetFormats({
      variables
    })
  }, [pagination.offset])

  useEffect(() => {
    if (data && data.listFormats) {
      if (isPagination) {
        setDataTable((prev) => [...prev, ...data.listFormats.edges])
        setPagination((prev) => ({ ...prev, page: prev.page + 1 }))
        setIsPagination(false)
      } else {
        setDataTable(data.listFormats.edges)
      }
    }
  }, [data])

  /*   useTable({
    columns,
    data: dataTable.slice(10 * pagination.page - 10, 10 * pagination.page)
  }) */

  useEffect(() => {
    if (error) {
      toast.error(`Error! \n ${error.message}`)
      if (error.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [error])

  /* const hanldeSubmit = (e) => {
    e.preventDefault()
    if (searchInput.length >= 3) {
      const key = `${Number(searchInput) ? 'documentNumber' : 'name'}`
      setIsSearch(true)
      setPagination({ page: 1, offset: 0 })
      setSearchValue({
        key,
        value: searchInput
      })
    }
  } */

  document.title = 'Créditos | TOTAL SALES'
  const handleClickDown = ({ name, file }) => {
    const link = document.createElement('a')
    link.href = file
    link.download = name
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  const getType = (url) => {
    if (!url) return '-'
    const imgs = [
      'bmp',
      'gif',
      'jpg',
      'jpge',
      'tif',
      'png',
      'svg',
      'jpeg',
      'tiff'
    ]
    const excel = ['xlsx', 'xlsm', 'xlsb', 'xltx']
    const { pathname: pathFull } = new URL(url)
    if (!pathFull) return '-'
    const pathname = pathFull.split('.')[1]
    // console.log('pathname', pathname)
    if (imgs.includes(pathname.toLowerCase())) return 'Imagen'
    if (pathname.toLowerCase().includes('pdf')) {
      return 'PDF'
    }
    if (excel.includes(pathname.toLowerCase())) return 'Excel'
    if (pathname.toLowerCase().includes('docx')) return 'Word'
    return '-'
  }
  if (loading /* && !isSearch */ && !isPagination) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Ver formatos' pageTitle='Formatos' />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between border-0 gap-3 flex-md-column flex-lg-row flex-sm-column '>
                  <div className='w-100'>
                    <h5 className='card-title mb-0'>Créditos</h5>
                  </div>
                  <div className='w-100'>
                    <p
                      className='placeholder-glow w-100 text-end'
                      style={{ height: '30px' }}
                    >
                      <span
                        className='placeholder col-4'
                        style={{ height: '100%' }}
                      />
                    </p>
                  </div>
                </CardHeader>
                <CardBody style={{ height: '30vh' }}>
                  <div className='d-flex justify-content-center align-items-center h-100'>
                    <Spinner style={{ width: '3em', height: '3em' }} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Ver formatos' pageTitle='Formatos' />
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className='d-flex align-items-center justify-content-between border-0 gap-3 flex-md-column flex-lg-row flex-sm-column '>
                <h5 className='card-title mb-0 d-block'>Formatos</h5>
              </CardHeader>
              <CardBody>
                {/* <Row className='mb-3'>
                  <CardBody className='border border-dashed border-end-0 border-start-0'>
                    <Row className='g-4'>
                      <Col lg={4}>
                        <form onSubmit={hanldeSubmit}>
                          <div className='search-box me-2 mb-2 d-inline-block w-100'>
                            <input
                              id='search-bar-0'
                              type='search'
                              value={searchInput}
                              onChange={(e) => {
                                setSearchInput(e.target.value)
                                if (e.target.value === '') {
                                  setPagination({
                                    page: 1,
                                    offset: 0
                                  })
                                  setSearchValue({
                                    key: '',
                                    value: ''
                                  })
                                }
                              }}
                              name='searchInput'
                              className='form-control search /'
                              placeholder='Ingresa cédula o nombre'
                            />
                            <i className='bx bx-search-alt search-icon' />
                          </div>
                        </form>
                      </Col>
                    </Row>
                  </CardBody>
                </Row> */}

                {dataTable.length > 0 && (
                  <>
                    <div className='table-responsive table-card mb-1'>
                      <Table hover className='align-middle table-nowrap'>
                        <thead className='table-light text-muted'>
                          <tr>
                            <th>Nombre</th>
                            <th>tipo</th>
                            <th />
                          </tr>
                        </thead>

                        <tbody>
                          {dataTable
                            .slice(
                              10 * pagination.page - 10,
                              10 * pagination.page
                            )
                            .map(({ node }) => (
                              <tr key={node.id}>
                                <td>
                                  <div
                                    className='d-flex align-items-center gap-2'
                                    style={{ margin: 'auto' }}
                                  >
                                    <i
                                      className={`mdi ${getIcon(
                                        node.document
                                      )} fs-4 text-${getColorBg(
                                        node.document
                                      )}`}
                                    />
                                    {node.name}
                                  </div>
                                </td>
                                <td>{getType(node.document)}</td>
                                {/* <td>{renderStatus(node.status)}</td> */}
                                <td>
                                  <button
                                    className='border-0 p-0 m-0 bg-transparent'
                                    onClick={() => {
                                      handleClickDown({
                                        file: node.document,
                                        name: node.name
                                      })
                                    }}
                                  >
                                    <i className='mdi mdi-download fs-24 text-secondary' />
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                    <Row className='justify-content-md-end justify-content-center align-items-center p-2'>
                      <Col className='col-md-auto'>
                        <div className='d-flex gap-1'>
                          <Button
                            color='primary'
                            disabled={pagination.page <= 1}
                            onClick={() => {
                              setPagination((prev) => ({
                                ...prev,
                                page: prev.page - 1
                              }))
                            }}
                            // onClick={previousPage}
                            // disabled={!canPreviousPage}
                          >
                            {'<'}
                          </Button>
                        </div>
                      </Col>
                      <Col className='col-md-auto d-none d-md-block'>
                        Pág{' '}
                        <strong>
                          {pagination.page} de{' '}
                          {Math.ceil(dataTable.length / 10)}
                        </strong>
                      </Col>
                      <Col className='col-md-auto'>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault()
                            if (!Number(pagination.page)) {
                              setPagination((prev) => ({
                                ...prev,
                                page: 1
                              }))
                            }
                            // console.log('pagination', pagination)
                          }}
                        >
                          <Input
                            type='number'
                            min={1}
                            style={{ width: 70 }}
                            // max={pageOptions.length}
                            value={pagination.page}
                            onChange={(e) => {
                              const { value } = e.target
                              if (value === '') {
                                setPagination((prev) => ({
                                  ...prev,
                                  page: ''
                                }))
                                return
                              }
                              if (!Number(value)) {
                                setPagination((prev) => ({
                                  ...prev,
                                  page: 1
                                }))
                                return
                              }
                              if (
                                Number(value) <=
                                Math.ceil(dataTable.length / 10)
                              ) {
                                setPagination((prev) => ({
                                  ...prev,
                                  page: Number(value)
                                }))
                              }
                            }}
                          />
                        </form>
                      </Col>

                      <Col className='col-md-auto'>
                        <div className='d-flex gap-1'>
                          <Button
                            color='primary'
                            disabled={
                              !data?.walletCredits?.pageInfo?.hasNextPage &&
                              pagination.page ===
                                Math.ceil(dataTable.length / 10)
                            }
                            onClick={() => {
                              if (
                                pagination.page <
                                Math.ceil(dataTable.length / 10)
                              ) {
                                return setPagination((prev) => ({
                                  ...prev,
                                  page: prev.page + 1
                                }))
                              }
                              const start = parseId(
                                data?.walletCredits?.pageInfo?.startCursor
                              )
                              setIsPagination(true)
                              setPagination((prev) => ({
                                ...prev,
                                offset: start + 10
                              }))
                            }}
                            // disabled={!canNextPage}
                          >
                            {loading ? (
                              <Spinner size='sm' color='light' />
                            ) : (
                              '>'
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                {loading && isPagination ? (
                  <div
                    className='d-flex justify-content-center align-items-center flex-column gap-2'
                    style={{ height: '20vh' }}
                  >
                    <Spinner style={{ width: '3em', height: '3em' }} />
                    <span className='d-block'>cargando...</span>
                  </div>
                ) : (
                  dataTable.length === 0 && (
                    <div
                      className='d-flex justify-content-center align-items-center'
                      style={{ height: '20vh' }}
                    >
                      No hay Créditos en cartera
                    </div>
                  )
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
