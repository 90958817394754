import React, { useEffect } from 'react'
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from 'reactstrap'
import { parseId } from '../../../helpers'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { DESISTIR_CREDIT } from '../../../graphql/mutations/creditMutations'
import { useNavigate } from 'react-router-dom'

export const ModalDesitirCredit = ({ isOpen, onClose, creditId }) => {
  const [gqlDesistir, { data, loading, error }] = useMutation(DESISTIR_CREDIT)
  const navigate = useNavigate()

  useEffect(() => {
    if (error) {
      toast.error(`ERROR: ${error.message}`)
    }
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.withdrawnCredit?.success) {
      toast.success('Se desistió el crédito correctamente')
      navigate('/creditos')
    } else if (data.withdrawnCredit?.errors?.message) {
      toast.error(
        `Error ${data.withdrawnCredit?.errors?.message || 'algo ocurrió'}`
      )
    }
  }, [data])

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader className='bg-soft-info p-4' toggle={onClose}>
        Desistir crédito
      </ModalHeader>
      <Form
        id='form-change-revition-credit'
        onSubmit={(e) => {
          e.preventDefault()
          const { comment } = Object.fromEntries(new FormData(e.target))
          gqlDesistir({
            variables: {
              input: {
                creditId: parseId(creditId),
                comment
              }
            }
          })
        }}
      >
        <ModalBody>
          <Alert color='warning'>
            <h3 className='d-flex align-items-center gap-2'>
              <i className='mdi mdi-alert fs-2' /> ¡Cuidado!
            </h3>
            Si el cliente desiste del crédito o del proceso, haz uso de esta
            opción. Esta funcionalidad archivará el crédito y no aparecerá más
            en tu lista de créditos
          </Alert>
          <FormGroup>
            <Label htmlFor='textarea45'>Comentario</Label>
            <Input
              placeholder='Detalla el motivo del porqué se desiste del crédito.'
              type='textarea'
              name='comment'
              id='textarea45'
              required
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color='light'
            type='button'
            onClick={() => {
              onClose()
            }}
          >
            Cancelar
          </Button>
          <Button
            type='submit'
            disabled={loading}
            className='d-flex align-items-center gap-1'
          >
            {loading && <Spinner color='light' size='sm' />}
            Desistir
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
