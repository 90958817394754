import React from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner
} from 'reactstrap'
import profileBg from '../../../assets/images/bg-one-user.png'

export const LoadingPage = () => {
  return (
    <div className='page-content'>
      <Container fluid>
        <div className='profile-foreground position-relative mx-n4 mt-n4'>
          <div className='profile-wid-bg'>
            <img src={profileBg} alt='' className='profile-wid-img' />
          </div>
        </div>
        <div className='pt-4 mb-4 mb-lg-3 pb-lg-4'>
          <Row className='g-4'>
            <div className='col-auto'>
              <div className='profile-user position-relative d-inline-block mx-auto  mb-4'>
                <div className='col-auto'>
                  <div className='avatar-lg img-thumbnail rounded-circle flex-shrink-0'>
                    <div className='avatar-title text-uppercase border rounded-circle bg-light text-primary fs-1'>
                      <div className='placeholder-glow'>
                        <span className='placeholder col-12' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Col>
              <div className='p-2'>
                <h5 className='fs-16 mb-1'>
                  <div className='placeholder-glow'>
                    <span className='placeholder col-6' />
                  </div>
                </h5>
                <h5 className='fs-16 mb-1'>
                  <div className='placeholder-glow'>
                    <span className='placeholder col-3' />
                  </div>
                </h5>
              </div>
            </Col>
          </Row>
        </div>
        <Row style={{ marginTop: '3.5em' }}>
          <Col lg={4}>
            <Card className=''>
              <CardBody className='p-4'>
                <div className='text-center'>
                  <h5 className='fs-16 mb-1'>
                    <div className='placeholder-glow'>
                      <span className='placeholder col-9' />
                    </div>
                  </h5>
                  <p className='text-muted mb-0'>
                    <div className='placeholder-glow'>
                      <span className='placeholder col-5' />
                    </div>
                  </p>
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardBody
                className='d-flex align-items-center justify-content-center'
                style={{ height: '25vh' }}
              >
                <Spinner
                  color='dark'
                  style={{ height: '2.4em', width: '2.4em' }}
                />
              </CardBody>
            </Card>
          </Col>

          <Col lg={8}>
            <Card className=''>
              <CardHeader>
                <div>
                  <div>
                    <div className='placeholder-glow'>
                      <span className='placeholder col-3' />
                    </div>
                  </div>
                </div>
              </CardHeader>
              <CardBody
                className='d-flex align-items-center justify-content-center'
                style={{ height: '30vh' }}
              >
                <Spinner style={{ width: '3em', height: '3em' }} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
