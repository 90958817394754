import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Input,
  Spinner,
  Table,
  UncontrolledTooltip,
  Badge
} from 'reactstrap'
// import TableContainer from '../../../Components/Common/TableContainer'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import Flatpickr from 'react-flatpickr'
import { Spanish } from 'flatpickr/dist/l10n/es'
/*
import {
    useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table' */
import { useLazyQuery, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { hasPermission, parseId } from '../../../helpers'
import {
  ALL_PERMISSIONS,
  ERROR_MESSAGES,
  STATUS_CREDITS_OBJ,
  STORAGE_NAMES
} from '../../../helpers/consts'
import { useGetUser } from '../../../hooks/user'
import {
  GET_AGENCIAS_TO_CREDITS,
  GET_ALL_CREDITS,
  GET_ANALYST_CREDITS
} from '../../../graphql/queries/getCredits'
import moment from 'moment'
import { API } from '../../../graphql/client'
import { useDisclosure } from '../../../hooks/useDisclosure'
import { ModalChangeStatus } from '../../../Components/Own/creditPage/ModalChangeStatus'
import { InfoGeneralCredits } from '../../../Components/Own/creditPage/InfoGeneralCredits'
import Select from 'react-select'
import { BadgeCreditLine } from '../../../Components/Own/creditPage/BadgeCreditLine'
// import { DefaultColumnFilter } from '../../../Components/Common/filters'

// import TableContainer from '../../../Components/Common/TableContainer'
// import { Type, Quantity, OrderValue, AvgPrice, Price, Status } from './OrderCol'

const ROL_NAME = {
  ANALYST: 'Analista'
}

export default function CreditsPage() {
  const [gqlGetCredits, { data, loading, error, refetch }] = useLazyQuery(
    GET_ALL_CREDITS,
    {
      fetchPolicy: 'no-cache'
    }
  )
  const {
    data: dataAnlyst,
    loading: loadingAnlyst,
    error: errorAnlyst
  } = useQuery(GET_ANALYST_CREDITS, {
    variables: {
      rol: ROL_NAME.ANALYST
    },
    fetchPolicy: 'no-cache'
  })
  const { data: dataAgencies, loading: loadingAgencies } = useQuery(
    GET_AGENCIAS_TO_CREDITS
  )
  const navigate = useNavigate()
  const { logout, permissions, user } = useGetUser()
  /**
   * @typedef {{node: {id: string, creditType: string, status: string, firstName: string, firstSurname: string, uuid: string, documentNumber: string, created: string, user: {firstName: string, lastName: string},analyst: null | {firstName: string, lastName: string} ,agency: {id: string,   name: string }}}} IData
   * @type {[IData[], React.Dispatch<React.SetStateAction<IData[]>>]}
   */
  const [dataTable, setDataTable] = useState([])

  const [optionsTypeCreditLine, setOptionsTypeCreditLine] = useState([])

  const [isSearch, setIsSearch] = useState(false)

  const [searchInput, setSearchInput] = useState('')
  const [searchValue, setSearchValue] = useState({
    key: '',
    value: ''
  })
  const [datesValue, setDatesValue] = useState({
    createdGte: undefined,
    createdLte: undefined
  })
  const [statusSelect, setStatusSelect] = useState('')
  const [agencySelect, setAgencySelect] = useState('')
  const [typeSelect, setTypeSelect] = useState('')
  const [preAprovedSelect, setPreAprovedSelect] = useState('')
  const [closedSelect, setClosedSelect] = useState('')
  const [creditLineSelect, setCreditLineSelect] = useState('')
  const [pagination, setPagination] = useState({
    offset: 0,
    page: 1
  })
  const [isPagination, setIsPagination] = useState(false)
  const [first, setFirst] = useState(10)

  const [loadingExport, setLoadingExport] = useState(false)

  const [infoExtra, setInfoExtra] = useState(null)
  const { isOpen, onClose, onOpen } = useDisclosure()

  /** @type {[list: {label: string, value: string}[], set: React.Dispatch<React.SetStateAction<{}[]>>]} */
  const [dataAnalystSelect, setDataAnalystSelect] = useState([])
  const [analystSelect, setAnalystSelect] = useState('')

  useEffect(() => {
    if (!hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_CREDITOS)) {
      navigate('/dashboard')
    }
  }, [permissions])

  useEffect(() => {
    const strArr = window.localStorage.getItem(STORAGE_NAMES.TYPE_COTIZ)
    if (!strArr) return
    const arr = JSON.parse(strArr)
    setOptionsTypeCreditLine(arr)
  }, [])

  useEffect(() => {
    const variables = {
      offset: pagination.offset,
      first
    }
    if (searchValue.key) {
      variables[searchValue.key] = searchValue.value
    }
    if (datesValue.createdGte && datesValue.createdLte) {
      variables.createdGte = datesValue.createdGte
      variables.createdLte = datesValue.createdLte
    }
    if (analystSelect) {
      variables.analyst_Id = parseId(analystSelect)
    }
    if (statusSelect) {
      variables.status = statusSelect
    }
    if (agencySelect) {
      variables.agencyId = agencySelect.split('***')[0]
    }
    if (typeSelect) {
      variables.creditType = typeSelect
    }
    if (preAprovedSelect) {
      variables.preapproved = preAprovedSelect === 'SI'
    }
    if (closedSelect) {
      variables.closed = closedSelect === 'SI'
    }
    if (creditLineSelect) {
      variables.creditLine_Id = creditLineSelect.split('***')[0]
    }
    // console.log('variables', variables)
    gqlGetCredits({
      variables
    })
  }, [
    searchValue.value,
    datesValue,
    statusSelect,
    pagination.offset,
    agencySelect,
    analystSelect,
    typeSelect,
    preAprovedSelect,
    closedSelect,
    creditLineSelect,
    first
  ])

  useEffect(() => {
    if (data && data.creditList) {
      if (isPagination) {
        setDataTable((prev) => [...prev, ...data.creditList.edges])
        setPagination((prev) => ({ ...prev, page: prev.page + 1 }))
        setIsPagination(false)
      } else {
        setDataTable(data.creditList.edges)
      }
    }
  }, [data])

  useEffect(() => {
    if (!dataAnlyst) return

    const arrParse = dataAnlyst.listUsers.edges.map(({ node }) => ({
      label: `${node.firstName} ${node.lastName}`,
      value: node.id
    }))

    setDataAnalystSelect(arrParse)
  }, [dataAnlyst])

  /*   useTable({
    columns,
    data: dataTable.slice(10 * pagination.page - 10, 10 * pagination.page)
  }) */

  useEffect(() => {
    if (error) {
      toast.error(`Error! \n ${error.message}`)
      if (error.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [error])
  useEffect(() => {
    if (errorAnlyst) {
      toast.error(`Error! \n ${errorAnlyst.message}`)
      if (errorAnlyst.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorAnlyst])

  const searchForDates = (e) => {
    console.log('e', e)
    if (e.length === 1) return
    setPagination({ page: 1, offset: 0 })

    if (e && e.length === 2) {
      setIsSearch(true)
      const createdGte = new Date(e[0]).toISOString()
      const createdLte = new Date(e[1]).toISOString()

      return setDatesValue({
        createdGte,
        createdLte
      })
    }
    setDatesValue({
      createdGte: undefined,
      createdLte: undefined
    })
  }

  const hanldeSubmit = (e) => {
    e.preventDefault()
    if (searchInput.length >= 3) {
      const key = `${Number(searchInput) ? 'documentNumber' : 'name'}`
      setIsSearch(true)
      setPagination({ page: 1, offset: 0 })
      setSearchValue({
        key,
        value: searchInput
      })
    }
  }

  const renderStatus = (status) => {
    switch (STATUS_CREDITS_OBJ[status]) {
      case STATUS_CREDITS_OBJ.SIN_REVISAR:
        return (
          <span className='badge badge-soft-dark text-uppercase'>
            {status.replace(/_/g, ' ')}
          </span>
        )
      case STATUS_CREDITS_OBJ.PREAPROBADO:
        return (
          <span className='badge badge-soft-success text-uppercase'>
            {status.replace(/_/g, ' ')}
          </span>
        )
      case STATUS_CREDITS_OBJ.RECHAZADO:
        return (
          <span className='badge badge-soft-danger text-uppercase'>
            {status.replace(/_/g, ' ')}
          </span>
        )
      case STATUS_CREDITS_OBJ.POR_DESEMBOLSAR:
        return (
          <span
            className='badge text-uppercase'
            style={{ backgroundColor: '#3498db' }}
          >
            {status.replace(/_/g, ' ')}
          </span>
        )
      case STATUS_CREDITS_OBJ.DESEMBOLSADO:
        return (
          <span
            className='badge text-uppercase'
            style={{ backgroundColor: 'purple' }}
          >
            {status.replace(/_/g, ' ')}
          </span>
        )
      case STATUS_CREDITS_OBJ.FORMALIZADO:
        return (
          <span className='badge badge-soft-secondary text-uppercase'>
            {status.replace(/_/g, ' ')}
          </span>
        )
      case STATUS_CREDITS_OBJ.APROBADO:
        return (
          <span
            className='badge text-uppercase'
            style={{ backgroundColor: '#27ae60' }}
          >
            {status.replace(/_/g, ' ')}
          </span>
        )
      case STATUS_CREDITS_OBJ.ESTUDIO_FORMAL:
        return (
          <span className='badge badge-soft-primary text-uppercase'>
            {status.replace(/_/g, ' ')}
          </span>
        )
      case STATUS_CREDITS_OBJ.PENDIENTE:
        return (
          <span className='badge badge-soft-warning text-uppercase'>
            {status.replace(/_/g, ' ')}
          </span>
        )
      case STATUS_CREDITS_OBJ.INCOMPLETO:
        return (
          <span
            className='badge text-uppercase'
            style={{ backgroundColor: '#f39c12' }}
          >
            {status.replace(/_/g, ' ')}
          </span>
        )
      case STATUS_CREDITS_OBJ.NO_VIABLE:
        return (
          <span
            className='badge text-uppercase'
            style={{ backgroundColor: '#ff8c3a' }}
          >
            {status.replace(/_/g, ' ')}
          </span>
        )
      case STATUS_CREDITS_OBJ.DESISTIDO:
        return (
          <span
            className='badge text-bg-dark text-uppercase'
            style={{ backgroundColor: '#e74c3c' }}
          >
            {status.replace(/_/g, ' ')}
          </span>
        )
      default:
        return (
          <span className='badge badge-soft-dark text-uppercase'>
            {status.replace(/_/g, ' ')}
          </span>
        )
    }
  }

  const handleDownload = async () => {
    if (!datesValue.createdGte || !datesValue.createdLte) return
    const { token } = user.tokenAuth
    if (!token) return
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    const requestOptions = {
      method: 'GET',
      headers: myHeaders
    }

    setLoadingExport(true)
    const init = new Date(datesValue.createdGte).toISOString().split('T')[0]
    const end = new Date(datesValue.createdLte).toISOString().split('T')[0]
    try {
      const res = await fetch(
        `${API}/export/credits/${init}/${end}`,
        requestOptions
      )
      if (!res.ok) throw new Error('Error al exportar')
      const blob = await res.blob()
      // console.log('blob', blob)
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `creditos-${moment(datesValue.createdGte).format(
        'DD-MM-YY'
      )}-${moment(datesValue.createdLte).format('DD-MM-YY')}.xlsx`
      document.body.appendChild(link)
      setLoadingExport(false)
      link.click()
    } catch (err) {
      setLoadingExport(false)
      toast.error(`ERROR: ${err.message}`)
    }
  }

  document.title = 'Créditos | TOTAL SALES'

  if (loading && !isSearch && !isPagination) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Ver créditos' pageTitle='Créditos' />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between border-0 gap-3 flex-md-column flex-lg-row flex-sm-column '>
                  <div className='w-100'>
                    <h5 className='card-title mb-0'>Créditos</h5>
                  </div>
                  <div className='w-100'>
                    <p
                      className='placeholder-glow w-100 text-end'
                      style={{ height: '30px' }}
                    >
                      <span
                        className='placeholder col-4'
                        style={{ height: '100%' }}
                      />
                    </p>
                  </div>
                </CardHeader>
                <CardBody style={{ height: '30vh' }}>
                  <div className='d-flex justify-content-center align-items-center h-100'>
                    <Spinner style={{ width: '3em', height: '3em' }} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Ver créditos' pageTitle='Créditos' />
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className='d-flex align-items-center justify-content-between border-0 gap-3 fflex-column flex-md-row'>
                <h5 className='card-title mb-0 d-block'>Créditos</h5>
                <div className='d-flex align-items-center gap-2 flex-column flex-md-row'>
                  <Button
                    disabled={
                      (!datesValue.createdGte && !datesValue.createdLte) ||
                      loadingExport
                    }
                    color='success'
                    className='d-flex align-items-center gap-2'
                    onClick={handleDownload}
                  >
                    {loadingExport ? (
                      <Spinner size='sm' />
                    ) : (
                      <i className='mdi mdi-microsoft-excel' />
                    )}
                    Descargar Excel
                  </Button>
                  {hasPermission(permissions, ALL_PERMISSIONS.ANADIR_CREDITO) &&
                    !user.tokenAuth.crm && (
                      <div className='flax-shrink-0 hstack gap-2'>
                        <Link className='btn btn-primary' to='/agregar-credito'>
                          Crear crédito
                        </Link>
                      </div>
                    )}
                </div>
              </CardHeader>
              <CardBody>
                <InfoGeneralCredits
                  datesValue={datesValue}
                  statusSelect={statusSelect}
                  agencySelect={agencySelect}
                  typeSelect={typeSelect}
                  preAprovedSelect={preAprovedSelect}
                  analystSelect={analystSelect}
                />
                <Row className='mb-3'>
                  <CardBody className='border border-dashed border-end-0 border-start-0'>
                    <Row className='g-4 mb-4'>
                      <Col lg={6}>
                        <form onSubmit={hanldeSubmit}>
                          <div className='search-box me-2 mb-2 d-inline-block w-100'>
                            <input
                              id='search-bar-0'
                              type='search'
                              value={searchInput}
                              onChange={(e) => {
                                setSearchInput(e.target.value)
                                if (e.target.value === '') {
                                  setPagination({
                                    page: 1,
                                    offset: 0
                                  })
                                  setSearchValue({
                                    key: '',
                                    value: ''
                                  })
                                }
                              }}
                              name='searchInput'
                              className='form-control search /'
                              placeholder='Ingresa cédula o nombre'
                            />
                            <i className='bx bx-search-alt search-icon' />
                          </div>
                        </form>
                      </Col>

                      <Col lg={6}>
                        <div className='input-group'>
                          <span className='input-group-text' id='basic-addon1'>
                            <i className='ri-calendar-2-line' />
                          </span>
                          <Flatpickr
                            placeholder='Seleccione fecha(s)'
                            className='form-control'
                            onChange={(e) => searchForDates(e)}
                            options={{
                              mode: 'range',
                              dateFormat: 'd F y',
                              showMonths: 2,
                              locale: Spanish,
                              position: 'auto center'
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className='g-4'>
                      <Col lg={4}>
                        {!dataAgencies || loadingAgencies ? (
                          <Spinner size='sm' />
                        ) : (
                          <>
                            <Select
                              // className='form-select'
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? 'blue'
                                    : 'lightgray'
                                })
                              }}
                              isClearable
                              isSearchable
                              noOptionsMessage={() => 'No se encontró agencia'}
                              placeholder='Seleccione agencia'
                              options={[
                                // { label: 'Seleccione agencia', value: '' },
                                ...dataAgencies?.listAgency?.edges?.map(
                                  ({ node }) => ({
                                    value: parseId(node.id),
                                    label: node.name
                                  })
                                )
                              ]}
                              value={
                                agencySelect
                                  ? {
                                      label: agencySelect.split('***')[1],
                                      value: agencySelect.split('***')[0]
                                    }
                                  : null
                              }
                              onChange={(e) => {
                                setPagination({ page: 1, offset: 0 })
                                if (e == null) {
                                  return setAgencySelect(null)
                                }
                                setAgencySelect(`${e.value}***${e.label}`)
                              }}
                            />
                            {/* <select
                              className='form-select'
                              data-choices
                              data-choices-search-false
                              name='choices-single-default'
                              id='choices-single-defaultw3r'
                              value={agencySelect}
                              onChange={(e) => {
                                setAgencySelect(e.target.value)
                                setPagination({ page: 1, offset: 0 })
                              }}
                            >
                              <option value=''>Seleccione agencia</option>
                              <>
                                {dataAgencies?.listAgency?.edges?.map(
                                  ({ node }) => (
                                    <option
                                      key={node.id}
                                      value={parseId(node.id)}
                                    >
                                      {node.name}
                                    </option>
                                  )
                                )}
                              </>
                            </select> */}
                          </>
                        )}
                      </Col>
                      <Col lg={4}>
                        {loadingAnlyst ? (
                          <div className='d-flex align-items-center justify-content-center w-100'>
                            <Spinner />
                          </div>
                        ) : (
                          <select
                            className='form-select'
                            data-choices
                            data-choices-search-false
                            name='choices-single-default2'
                            id='choices-single-default2'
                            value={analystSelect}
                            onChange={(e) => {
                              setAnalystSelect(e.target.value)
                              setPagination({ page: 1, offset: 0 })
                            }}
                          >
                            <option value=''>Seleccione analista</option>
                            {dataAnalystSelect.map(({ label, value }) => (
                              <option key={value} value={value}>
                                {label}
                              </option>
                            ))}
                          </select>
                        )}
                      </Col>
                      <Col lg={4}>
                        <select
                          className='form-select'
                          data-choices
                          data-choices-search-false
                          name='choices-single-default2'
                          id='choices-single-default2'
                          value={statusSelect}
                          onChange={(e) => {
                            setStatusSelect(e.target.value)
                            setPagination({ page: 1, offset: 0 })
                          }}
                        >
                          <option value=''>Escoja estado</option>
                          {Object.keys(STATUS_CREDITS_OBJ).map((statusStr) => (
                            <option
                              key={statusStr}
                              value={STATUS_CREDITS_OBJ[statusStr]}
                            >
                              {STATUS_CREDITS_OBJ[statusStr]}
                            </option>
                          ))}
                        </select>
                      </Col>
                    </Row>
                    <Row className='g-4 mt-1'>
                      <Col lg={3}>
                        <select
                          className='form-select'
                          data-choices
                          data-choices-search-false
                          name='choices-tyupe-default2'
                          id='choices-tyupe-default2'
                          value={typeSelect}
                          onChange={(e) => {
                            setTypeSelect(e.target.value)
                            setPagination({ page: 1, offset: 0 })
                          }}
                        >
                          <option value=''>Escoja el tipo</option>
                          {['INMEDIATO', 'TRADICIONAL'].map((typeStr) => (
                            <option
                              key={typeStr}
                              value={typeStr}
                              style={{ textTransform: 'capitalize' }}
                            >
                              {typeStr}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col lg={3}>
                        <select
                          className='form-select'
                          data-choices
                          data-choices-search-false
                          name='choices-preparoved'
                          id='choices-preparoved'
                          value={preAprovedSelect}
                          onChange={(e) => {
                            setPreAprovedSelect(e.target.value)
                            setPagination({ page: 1, offset: 0 })
                          }}
                        >
                          <option value=''>¿Pre aprobación?</option>
                          {['SI', 'NO'].map((typeStr) => (
                            <option
                              key={typeStr}
                              value={typeStr}
                              style={{ textTransform: 'capitalize' }}
                            >
                              {typeStr}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col lg={3}>
                        <select
                          className='form-select'
                          data-choices
                          data-choices-search-false
                          name='choices-closed'
                          id='choices-closed'
                          value={closedSelect}
                          onChange={(e) => {
                            setClosedSelect(e.target.value)
                            setPagination({ page: 1, offset: 0 })
                          }}
                        >
                          <option value=''>¿Cerrado?</option>
                          {['SI', 'NO'].map((typeStr) => (
                            <option
                              key={typeStr}
                              value={typeStr}
                              style={{ textTransform: 'capitalize' }}
                            >
                              {typeStr}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col lg={3}>
                        <select
                          className='form-select'
                          data-choices
                          data-choices-search-false
                          name='choices-closed-line'
                          id='choices-closed-line'
                          value={creditLineSelect}
                          onChange={(e) => {
                            setCreditLineSelect(e.target.value)
                            setPagination({ page: 1, offset: 0 })
                          }}
                        >
                          <option value=''>Linea de crédito</option>
                          {optionsTypeCreditLine.map(({ node }) => (
                            <option
                              key={node.id}
                              value={`${parseId(node.id)}***${
                                node.legibleName
                              }`}
                              style={{ textTransform: 'capitalize' }}
                            >
                              {node.legibleName}
                            </option>
                          ))}
                        </select>
                      </Col>
                    </Row>
                  </CardBody>
                </Row>

                {dataTable.length > 0 && (
                  <>
                    <div className='table-responsive table-card mb-1'>
                      <Table hover className='align-middle table-nowrap'>
                        <thead className='table-light text-muted'>
                          <tr>
                            <th>ID</th>
                            <th>Nombre</th>
                            <th>Documento</th>
                            <th>Estado</th>
                            <th>Cerrado</th>
                            <th>Pre aprobación</th>
                            <th>Tipo</th>
                            <th>Linea de crédito</th>
                            <th>Agencia</th>
                            <th>Asesor</th>
                            <th>Analista</th>
                            <th>Creado</th>
                            <th>Ult. actualización</th>
                            <th />
                          </tr>
                        </thead>

                        <tbody>
                          {dataTable
                            .slice(
                              first * pagination.page - first,
                              first * pagination.page
                            )
                            .map(({ node }) => (
                              <tr key={node.id}>
                                <td>
                                  <Link
                                    to={`/credito/${parseId(node.id)}`}
                                    className='text-decoration-underline'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                  >
                                    {parseId(node.id)}
                                  </Link>
                                </td>
                                <td>
                                  <span>
                                    <span className='text-uppercase'>
                                      {node.firstName} {node.secondName || ''}{' '}
                                      {node.firstSurname}{' '}
                                      {node.secondSurname || ''}
                                    </span>
                                  </span>
                                </td>
                                <td>{node.documentNumber}</td>
                                <td>{renderStatus(node.status)}</td>
                                <td className='text-center'>
                                  {node.closedDate ? (
                                    <Badge color='info'>SI</Badge>
                                  ) : (
                                    <Badge color='dark'>NO</Badge>
                                  )}
                                </td>
                                <td className='text-center'>
                                  {node.preapproved ? (
                                    <Badge color='success'>SI</Badge>
                                  ) : (
                                    <Badge color='danger'>NO</Badge>
                                  )}
                                </td>
                                <td>{node.creditType}</td>
                                <td>
                                  <BadgeCreditLine
                                    name={node?.creditLine?.name}
                                    legibleName={node?.creditLine?.legibleName}
                                  />
                                </td>
                                <td>{node.agency.name}</td>
                                <td>
                                  {node.user?.firstName
                                    ? `${node.user?.firstName} ${node.user?.lastName}`
                                    : 'N/A'}
                                </td>
                                <td>
                                  {node.analyst ? (
                                    `${node.analyst.firstName} ${node.analyst.lastName}`
                                  ) : (
                                    <span style={{ color: '#ff7b7b' }}>
                                      No aplica
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {moment(node.created).format(
                                    'DD MMM [del] YY'
                                  )}
                                </td>
                                <td>{moment(node.updated).fromNow()}</td>
                                {/* <td>{renderStatus(node.status)}</td> */}
                                <td>
                                  <ul className='list-inline hstack gap-2 mb-0'>
                                    <li className='list-inline-item'>
                                      <Link
                                        to={`/credito/${parseId(node.id)}`}
                                        className='text-primary d-inline-block'
                                        id={
                                          'view' + parseId(node.id).toString()
                                        }
                                        target='_blank'
                                        rel='noopener noreferrer'
                                      >
                                        <i className='ri-eye-fill fs-16' />
                                      </Link>
                                      <UncontrolledTooltip
                                        placement='top'
                                        target={
                                          'view' + parseId(node.id).toString()
                                        }
                                        trigger='hover'
                                      >
                                        Ver
                                      </UncontrolledTooltip>
                                    </li>
                                    {hasPermission(
                                      permissions,
                                      ALL_PERMISSIONS.PUEDE_CAMBIAR_ESTADO_CREDITO
                                    ) && (
                                      <li className='list-inline-item'>
                                        <Button
                                          outline
                                          color='dark'
                                          size='sm'
                                          id={
                                            'edit' + parseId(node.id).toString()
                                          }
                                          className='d-flex'
                                          onClick={() => {
                                            setInfoExtra({
                                              name: `${node.firstName} ${node.firstSurname}`,
                                              document: node.documentNumber,
                                              value: node.productValue,
                                              intialQuota: node.initialFee,
                                              plazo: node.term,
                                              quotaValue: node.quotaValue,
                                              creditId: node.id
                                            })
                                            onOpen()
                                          }}
                                        >
                                          <i className='las la-sync fs-16' />
                                        </Button>
                                        <UncontrolledTooltip
                                          placement='top'
                                          target={
                                            'edit' + parseId(node.id).toString()
                                          }
                                          trigger='hover'
                                        >
                                          Cambiar de estado
                                        </UncontrolledTooltip>
                                      </li>
                                    )}
                                  </ul>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                    <Row className='justify-content-md-end justify-content-center align-items-center p-2'>
                      <Col className='col-md-auto'>
                        <div className='d-flex gap-1'>
                          <Button
                            color='primary'
                            disabled={pagination.page <= 1}
                            onClick={() => {
                              setPagination((prev) => ({
                                ...prev,
                                page: prev.page - 1
                              }))
                            }}
                            // onClick={previousPage}
                            // disabled={!canPreviousPage}
                          >
                            {'<'}
                          </Button>
                        </div>
                      </Col>
                      <Col className='col-md-auto d-none d-md-block'>
                        Pág{' '}
                        <strong>
                          {pagination.page} de{' '}
                          {Math.ceil(dataTable.length / first)}
                        </strong>
                      </Col>
                      <Col className='col-md-auto'>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault()
                            if (!Number(pagination.page)) {
                              setPagination((prev) => ({
                                ...prev,
                                page: 1
                              }))
                            }
                            // console.log('pagination', pagination)
                          }}
                        >
                          <Input
                            type='number'
                            min={1}
                            style={{ width: 70 }}
                            // max={pageOptions.length}
                            value={pagination.page}
                            onChange={(e) => {
                              const { value } = e.target
                              if (value === '') {
                                setPagination((prev) => ({
                                  ...prev,
                                  page: ''
                                }))
                                return
                              }
                              if (!Number(value)) {
                                setPagination((prev) => ({
                                  ...prev,
                                  page: 1
                                }))
                                return
                              }
                              if (
                                Number(value) <=
                                Math.ceil(dataTable.length / first)
                              ) {
                                setPagination((prev) => ({
                                  ...prev,
                                  page: Number(value)
                                }))
                              }
                            }}
                          />
                        </form>
                      </Col>

                      <Col className='col-md-auto'>
                        <div className='d-flex gap-1'>
                          <Button
                            color='primary'
                            disabled={
                              !data?.creditList?.pageInfo?.hasNextPage &&
                              pagination.page ===
                                Math.ceil(dataTable.length / first)
                            }
                            onClick={() => {
                              if (
                                pagination.page <
                                Math.ceil(dataTable.length / first)
                              ) {
                                return setPagination((prev) => ({
                                  ...prev,
                                  page: prev.page + 1
                                }))
                              }
                              const start = parseId(
                                data?.creditList?.pageInfo?.startCursor
                              )
                              setIsPagination(true)
                              setPagination((prev) => ({
                                ...prev,
                                offset: start + first
                              }))
                            }}
                            // disabled={!canNextPage}
                          >
                            {loading ? (
                              <Spinner size='sm' color='light' />
                            ) : (
                              '>'
                            )}
                          </Button>
                        </div>
                      </Col>

                      <Col className='col-md-auto'>
                        <div className='d-flex gap-1'>
                          <Input
                            id='exampleSelect'
                            name='select'
                            type='select'
                            value={first}
                            onChange={(e) => {
                              setFirst(Number(e.target.value))
                              setPagination({ page: 1, offset: 0 })
                            }}
                          >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={40}>40</option>
                            <option value={50}>50</option>
                          </Input>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                {loading && isSearch ? (
                  <div
                    className='d-flex justify-content-center align-items-center flex-column gap-2'
                    style={{ height: '20vh' }}
                  >
                    <Spinner style={{ width: '3em', height: '3em' }} />
                    <span className='d-block'>cargando...</span>
                  </div>
                ) : (
                  dataTable.length === 0 && (
                    <div
                      className='d-flex justify-content-center align-items-center'
                      style={{ height: '20vh' }}
                    >
                      No hay Créditos
                    </div>
                  )
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      {infoExtra && (
        <ModalChangeStatus
          closeModal={onClose}
          creditId={infoExtra.creditId}
          infoExtra={infoExtra}
          refetchDataCredit={refetch}
          showChangeStatus={isOpen}
          toggleShowChangeStatus={onClose}
        />
      )}
    </div>
  )
}
