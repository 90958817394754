import React, { useEffect } from 'react'
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  Table,
  UncontrolledAccordion
} from 'reactstrap'
import { parseId } from '../../../helpers'
import { useLazyQuery } from '@apollo/client'
import { GET_COSINGERS_CREDIT_WALLET } from '../../../graphql/queries/getCreditsWallet'
import { toast } from 'react-toastify'
import moment from 'moment'
import { FormDataView } from '../creditPage/FormDataView'

const TYPES_DOCUMENT = {
  CC: 'Cédula de cuidadanía',
  CE: 'Cédula de extranjería'
}

export const ModalShowInfoCosingers = ({
  showCosingers,
  idCreditWallet,
  toggle
}) => {
  const [gqlGetInfoUser, { data, loading, error }] = useLazyQuery(
    GET_COSINGERS_CREDIT_WALLET,
    { fetchPolicy: 'no-cache' }
  )
  useEffect(() => {
    if (!idCreditWallet) return
    gqlGetInfoUser({
      variables: {
        id: parseId(idCreditWallet)
      }
    })
  }, [idCreditWallet])

  useEffect(() => {
    if (!error) return
    toast.error('Error: ' + error.message)
  }, [error])

  if (loading || !data) {
    return (
      <Modal isOpen={showCosingers} toggle={toggle}>
        <ModalHeader toggle={toggle}>Información codeudores</ModalHeader>
        <ModalBody
          className='d-flex align-items-center justify-content-center'
          style={{ height: '30vh' }}
        >
          <Spinner color='dark' style={{ width: '3em', height: '3em' }} />
        </ModalBody>
      </Modal>
    )
  }
  /** @type {{node: {id: string, firstName: string, firstSurname: string, secondName: string, secondSurname: string, documentNumber: string, documentType: string, expeditionDate: Date, dateBirth: Date, email: string, phoneNumber: string, formData: string | null}}[]} */
  const users = data.walletCredits.edges[0].node.cosignerSet.edges
  const name = `${data.walletCredits.edges[0].node.firstName} 
    ${data.walletCredits.edges[0].node.firstSurname}`
  return (
    <Modal isOpen={showCosingers} toggle={toggle}>
      <ModalHeader toggle={toggle} className='p-4 bg-soft-info'>
        Información codeudores de {name}
      </ModalHeader>
      <ModalBody>
        <UncontrolledAccordion defaultOpen='1'>
          {users.map(({ node }, idx) => (
            <AccordionItem key={node.id}>
              <AccordionHeader targetId={idx + 1 + ''}>
                {node.firstName} {node.firstSurname}
              </AccordionHeader>
              <AccordionBody accordionId={idx + 1 + ''}>
                <Table>
                  <tbody>
                    <tr>
                      <th>Nombre(s)</th>
                      <td>
                        {node.firstName} {node.secondName}
                      </td>
                    </tr>
                    <tr>
                      <th>Apellido(s)</th>
                      <td>
                        {node.firstSurname} {node.secondSurname}
                      </td>
                    </tr>
                    <tr>
                      <th>Tipo documento</th>
                      <td>{TYPES_DOCUMENT[node.documentType]}</td>
                    </tr>
                    <tr>
                      <th>No. documento</th>
                      <td>{node.documentNumber}</td>
                    </tr>
                    <tr>
                      <th>Fecha de nacimiento</th>
                      <td>
                        {moment(node.dateBirth).format('DD MMM [del] YYYY')}
                      </td>
                    </tr>
                    <tr>
                      <th>Fecha de expedición</th>
                      <td>
                        {moment(node.expeditionDate).format(
                          'DD MMM [del] YYYY'
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Correo electrónico</th>
                      <td>{node.email}</td>
                    </tr>
                    <tr>
                      <th>Teléfono</th>
                      <td>{node.phoneNumber}</td>
                    </tr>
                  </tbody>
                </Table>
                {node.formData && (
                  <div>
                    <FormDataView data={node.formData} />
                  </div>
                )}
              </AccordionBody>
            </AccordionItem>
          ))}
        </UncontrolledAccordion>
      </ModalBody>
    </Modal>
  )
}
