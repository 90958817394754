import { gql } from '@apollo/client'

export const ALL_CERTIFICATES = gql`
  query listComplianceCertificate(
    $after: String
    $name: String
    $documentNumber: String
    $plaque: String
    $id: Float
    $status_Iexact: String
    $offset: Int
  ) {
    listComplianceCertificate(
      offset: $offset
      status_Iexact: $status_Iexact
      after: $after
      name: $name
      documentNumber: $documentNumber
      plaque: $plaque
      id: $id
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          brand
          names
          surnames
          user {
            firstName
            lastName
          }
          documentNumber
          line
          model
          plaque
          status
          created
          id
        }
      }
    }
  }
`
export const GET_ONE_CERTIFICATE = gql`
  query listComplianceCertificate($id: Float) {
    listComplianceCertificate(id: $id) {
      edges {
        node {
          brand
          chassisNumber
          color
          comment
          created
          documentNumber
          engineNumber
          id
          line
          model
          names
          obligationNumber
          organizationName
          organizationNit
          plaque
          status
          surnames
          updated
          updated
          user {
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`
