import { gql } from '@apollo/client'
export const CREATE_CREDIT = gql`
  mutation creditCreate($input: CreditCreateInput!) {
    creditCreate(input: $input) {
      credit {
        id
        creditType
        documentNumber
      }
      url
      success
      errors {
        field
        message
      }
    }
  }
`
export const UNLOCK_ENTITY = gql`
  mutation creditUnlockEntity($input: UnlockEntityInput!) {
    creditUnlockEntities(input: $input) {
      errors {
        field
        message
      }
      success
    }
  }
`
export const CREATE_COMMENT_CREDIT_STR = `
mutation creditComment($input: CreditCommentCreateInput!){
  creditComment(input: $input){
    success
    errors{
      field
      message
    }
    comment{
      id
    }
  }
}
`

export const ADD_COSINGER = gql`
  mutation creditAddCosigners($input: CreditCoSignerInput!) {
    creditAddCosigners(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`
export const ADD_SPOUSE_CREDIT = gql`
  mutation creditAddSpouse($input: CreditSpouseInput!) {
    creditAddSpouse(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`
export const ADD_REFERENCE_CREDIT = gql`
  mutation creditAddReferences($input: CreditReferenceInput!) {
    creditAddReferences(input: $input) {
      errors {
        field
        message
      }
      success
    }
  }
`
export const ADD_PRODUCT_INFO_CREDIT = gql`
  mutation creditAddInformationProduct($input: CreditInformationProductInput!) {
    creditAddInformationProduct(input: $input) {
      errors {
        field
        message
      }
      success
    }
  }
`
export const DELETE_ENTITY = gql`
  mutation deleteInformation($input: DeleteEntityInput!) {
    creditDeleteInformation(input: $input) {
      errors {
        field
        message
      }
      success
    }
  }
`

export const CHANGE_STATUS_CREDIT_STR = `
  mutation creditChangeStatus($input: CreditChangeStatusInput!){
    creditChangeStatus(input: $input){
      success
      errors{
        field
        message
      }
    }
  }
`

export const VIEW_REQUEST_LINK_CREDIT = gql`
  mutation viewRequestLink($credit: UUID!) {
    viewRequestLink(credit: $credit) {
      success
      errors {
        field
        message
      }
      url
    }
  }
`

// EDITAR
export const EDIT_REFERENCE_CREDIT = gql`
  mutation referenceUpdate($input: ReferenceUpdateInput!) {
    creditReferenceUpdate(input: $input) {
      errors {
        field
        message
      }
      success
    }
  }
`
export const EDIT_PRODUCT_INFO_CREDIT = gql`
  mutation informationProductUpdate($input: InformationProductUpdateInput!) {
    creditInformationProductUpdate(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`
export const EDIT_COSINGER = gql`
  mutation cosignerUpdate($input: CoSignerUpdateInput!) {
    creditCosignerUpdate(input: $input) {
      errors {
        field
        message
      }
      success
    }
  }
`
export const EDIT_SPOUSE_CREDIT = gql`
  mutation spouseUpdate($input: SpouseUpdateInput!) {
    creditSpouseUpdate(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`
export const CHANGE_CREDIT_TO_REVITION = gql`
  mutation creditRequestReview($input: CreditRequestReviewInput!) {
    creditRequestReview(input: $input) {
      errors {
        field
        message
      }
      success
    }
  }
`
export const CLOSE_CREDIT = gql`
  mutation creditClose($input: CreditCloseInput!) {
    creditClose(input: $input) {
      errors {
        field
        message
      }
      success
    }
  }
`
export const REQUEST_DISBURSEMENT = gql`
  mutation disbursementRequestCreate($input: DisbursementRequestCreateInput!) {
    disbursementRequestCreate(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`
export const EDIT_CREDIT = gql`
  mutation creditUpdate($input: CreditUpdateInput!) {
    creditUpdate(input: $input) {
      errors {
        field
        message
      }
      success
    }
  }
`
export const DELETE_SUPPORT = gql`
  mutation supportDelete($input: SupporDeleteInput!) {
    supportDelete(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`
export const DESISTIR_CREDIT = gql`
  mutation withdrawnCredit($input: WithdrawnCreditInput!) {
    withdrawnCredit(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`

export const CHANGE_CREDIT_TRADICIONAL = gql`
  mutation changeCreditType($input: ChangeCreditTypeInput!) {
    changeCreditType(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`

export const INTERCHANGE_COSIGNER = gql`
  mutation interchangeDebtor($input: InterChangeDebtorInput!) {
    interchangeDebtor(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`

export const CHANGE_CREDITO_INICIAL = gql`
  mutation changeOldUser($input: ReasingCreditInput!) {
    changeOldUser(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`

export const CHANGE_TO_PENDING = gql`
  mutation creditToIncomplete($input: ChangeCreditTypeInput!) {
    creditToIncomplete(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`
