import gql from 'graphql-tag'

export const GET_PRODUCTS = gql`
  query listProducts($id: Float, $active: Boolean, $name: String) {
    listProducts(id: $id, active: $active, name: $name) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          id
          image
          name
          sku
          basePrice
          active
          category {
            name
          }
        }
      }
    }
  }
`

export const GET_ONE_PRODUCT = gql`
  query listProducts($id: Float) {
    listProducts(id: $id) {
      edges {
        node {
          basePrice
          commercialCompany {
            name
            id
          }
          created
          id
          image
          name
          sku
          updated
          active
          specificationSet {
            edges {
              node {
                id
                name
                value
              }
            }
          }
          discountSet {
            edges {
              node {
                id
                name
                value
                discountType
                agency {
                  id
                  name
                  zone {
                    id
                    name
                  }
                }
              }
            }
          }
          category {
            id
            name
          }
          priceSet {
            edges {
              node {
                id
                name
                priceType
                value
                agency {
                  id
                  name
                  zone {
                    id
                    name
                  }
                }
              }
            }
          }
          quotationSet {
            edges {
              node {
                firstName
              }
            }
          }
        }
      }
    }
  }
`
export const GET_PRODUCTS_COTIZACION = gql`
  query listProducts($id: Float, $active: Boolean) {
    listProducts(id: $id, active: $active) {
      edges {
        node {
          id
          name
          sku
          active
        }
      }
    }
  }
`
export const GET_PRODUCT_INFO_COTIZACION = gql`
  query listProducts($id: Float, $agencyId: Float) {
    listProducts(id: $id) {
      edges {
        node {
          basePrice
          id
          name
          priceSet(agency_Id: $agencyId) {
            edges {
              cursor
              node {
                id
                name
                value
              }
            }
          }
          discountSet(agency_Id: $agencyId) {
            edges {
              node {
                name
                id
                value
              }
            }
          }
        }
      }
    }
  }
`

export const GET_CATEGORIES_TO_PRODUCT = gql`
  query categoryList($after: String, $id: Float, $name: String) {
    categoryList(after: $after, id: $id, name_Iexact: $name) {
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`
export const GET_ALL_CATEGORIES = gql`
  query categoryList($after: String, $id: Float, $name: String) {
    categoryList(after: $after, id: $id, name_Iexact: $name) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          created
          description
          id
          name
          productSet {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`
export const GET_AGENCIAS_TO_EXPORT = gql`
  query listAgency($id: Float) {
    listAgency(id: $id) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`
export const GET_CATEGORIES_TO_EXPORT = gql`
  query categoryList($after: String, $id: Float, $name: String) {
    categoryList(after: $after, id: $id, name_Iexact: $name) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`
export const GET_AGENCIAS_TO_PRODUCT = gql`
  query listAgency($id: Float) {
    listAgency(id: $id) {
      edges {
        node {
          id
          name
          zone {
            name
          }
        }
      }
    }
  }
`
export const GET_ZONES_TO_PRODUCT = gql`
  query listZones {
    listZones {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const GET_PRICES_TO_PRODUCT = gql`
  query listPrices($agency_Id: Float, $product_Id: Float) {
    listPrices(agency_Id: $agency_Id, product_Id: $product_Id) {
      edges {
        node {
          id
          name
          value
          priceType
          agency {
            id
            name
            zone {
              name
            }
          }
        }
      }
    }
  }
`
export const GET_DISCOUNTS_TO_PRODUCT = gql`
  query listDiscounts($agency_Id: Float, $product_Id: Float) {
    listDiscounts(agency_Id: $agency_Id, product_Id: $product_Id) {
      edges {
        node {
          id
          name
          value
          discountType
          agency {
            id
            name
            zone {
              name
            }
          }
        }
      }
    }
  }
`
