import React, { useRef, useState } from 'react'
import html2pdf from 'html2pdf.js'
import moment from 'moment'
import { Card, CardBody, Spinner, TabPane } from 'reactstrap'
import {
  getFullTotal,
  getTotal,
  getTotalWithOutParse,
  parseId,
  parsePrice
} from '../../../helpers'
import imgLog from '../../../assets/images/logo-light.png'

const VALUE_STANDAR = 1.19

/**
 *
 * @param {{billing: {id: string; addressResidence: string, invoice: string, cityResidence: string, billingDate: string | null, created: string, documentNumber: string, email: string, firstName: string, firstSurname: string, phoneNumber: string, secondName: string, secondSurname: string, status: string, biller:{firstName: string, lastName: string} ,billingcommentSet: {edges:{node:{comment:string, id:string, created: string, user:{firstName:string, lastName: string}}}[]} ,supportproductbillingSet: {edges: {node: {id: string, name: string, file: string}}[]} ,productauxbillingSet: {edges: {node: {basePrice: string, name: string, id: string, fieldformSet:{edges: {node:{id: string, name:string, value:string}}[]} ,discountauxbillingSet: {edges: {node: {id: string, name: string, discountType: string, value: string}}[]} ,priceauxbillingSet: {edges: {node: {id: string, name: string, value: string, priceType: string, }}[]} ,supportproductbillingSet: {edges: {node:{name: string, file: string}}[]} }}[]}, quotation: {id: string, quotationType: string, credit:{dateBirth: string, id: string} ,agency: {id: string, name: string,zone: {commercialCompany:{image: string | null}}}}}}} props
 * @returns {JSX.Element}
 */
export const TabDownBillingInfo = ({ billing }) => {
  const contentRef = useRef(null)
  const [loadingDown, setLoadingDown] = useState(false)
  const downloadPdf = () => {
    setLoadingDown(true)
    if (contentRef.current) {
      html2pdf()
        .set({
          pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
          margin: 2,
          image: { type: 'jpeg' },
          html2canvas: {
            scale: 2,
            letterRendering: true,
            useCORS: true
          },
          filename: `${moment().format('DD-MM')}-FACTURACION-${parseId(
            billing.id
          )}.pdf`
        })
        .from(contentRef.current)
        .save()
        .then(() => {
          setLoadingDown(false)
        })
    }
  }
  /**
   *
   * @param {number} l1
   * @param {number} l2
   */
  const getArr = (l1, l2) => {
    const max = Math.max(l1, l2)
    return Array.from({ length: max }, (_, i) => i)
  }
  /**
   * @param {{node: {id: string;name: string;value: string;priceType: string;}}[]} arr
   */
  const getTotalBruto = (arr) => {
    const names = ['IMPUESTOS', 'PRENDA', 'MATRICULA', 'SOAT']
    let tot = 0
    arr
      .filter(({ node }) => !names.includes(node.name))
      .forEach(({ node }) => {
        tot += Number(node.value)
      })
    return tot
  }
  const infoDeudor = [
    {
      id: 1,
      info1: {
        name: 'Documento de identidad',
        value: billing.documentNumber
      },
      info2: {
        name: 'De',
        value: billing.cityResidence
      }
    },
    {
      id: 2,
      info1: {
        name: 'Nombre',
        value: `${billing.firstName} ${billing.secondName} ${billing.firstSurname} ${billing.secondSurname}`
      },
      info2: {
        name: 'Edad',
        value: billing.quotation?.credit?.dateBirth
          ? moment().diff(billing.quotation.credit.dateBirth, 'years')
          : 'NR'
      }
    },
    {
      id: 3,
      info1: {
        name: 'Teléfono',
        value: billing.phoneNumber
      },
      info2: {
        name: 'Dirección',
        value: billing.addressResidence
      }
    }
  ]
  const ventaNegocio = billing.productauxbillingSet.edges.map(({ node }) => ({
    venta: [
      { name: 'Nombre', value: node.name },
      ...node.fieldformSet.edges.map(({ node: nodeField }) => ({
        name: nodeField.name,
        value: nodeField.value
      }))
    ],
    negocio: [
      {
        name: 'Valor',
        value: parsePrice(node.basePrice)
      },
      {
        name: '- Descuentos',
        value: getTotal(node.discountauxbillingSet.edges)
      },
      {
        name: 'Valor - descuento',
        value: parsePrice(
          Number(node.basePrice) -
            getTotalWithOutParse(node.discountauxbillingSet.edges)
        )
      },
      {
        name: 'Valor bruto',
        value: parsePrice(
          Number(
            node.basePrice -
              getTotalWithOutParse(node.discountauxbillingSet.edges)
          ) / VALUE_STANDAR
        )
      },
      {
        name: 'IVA',
        value: parsePrice(
          Math.abs(
            Number(node.basePrice) -
              getTotalWithOutParse(node.discountauxbillingSet.edges) -
              Number(
                node.basePrice -
                  getTotalWithOutParse(node.discountauxbillingSet.edges)
              ) /
                VALUE_STANDAR
          )
        )
      },
      {
        name: 'Total',
        value: parsePrice(
          Number(node.basePrice) -
            getTotalWithOutParse(node.discountauxbillingSet.edges)
        )
      }
    ],
    accesorios: [
      {
        name: 'Valor bruto',
        value: parsePrice(
          getTotalBruto(node.priceauxbillingSet.edges) / VALUE_STANDAR
        )
      },
      {
        name: 'IVA',
        value: parsePrice(
          getTotalBruto(node.priceauxbillingSet.edges) * (1 - 1 / VALUE_STANDAR)
        )
      },
      {
        name: 'Total',
        value: parsePrice(getTotalBruto(node.priceauxbillingSet.edges))
      }
    ],
    total: [
      {
        name: 'Valor producto',
        value: parsePrice(
          Number(node.basePrice) -
            getTotalWithOutParse(node.discountauxbillingSet.edges)
        )
      },
      ...node.priceauxbillingSet.edges.map(({ node: nodePrices }) => ({
        name: nodePrices.name,
        value: parsePrice(nodePrices.value)
      })),
      {
        name: 'TOTAL',
        value: getFullTotal(
          node.basePrice,
          node.discountauxbillingSet.edges,
          node.priceauxbillingSet.edges
        )
      }
    ]
  }))
  return (
    <TabPane tabId='5'>
      <Card>
        <CardBody>
          <div>
            <div className='d-sm-flex align-items-center'>
              <h5 className='card-title flex-grow-1 mb-0'>
                Descargar solicitud de facturación
              </h5>
              <div className='flex-shrink-0 mt-2 mt-sm-0'>
                <button
                  className='btn btn-dark mt-2 mt-sm-0 d-flex align-items-center gap-1'
                  onClick={() => downloadPdf()}
                  disabled={loadingDown}
                >
                  {loadingDown ? (
                    <Spinner size='sm' />
                  ) : (
                    <i className='mdi mdi-file-download align-middle me-1' />
                  )}
                  Descargar
                </button>
              </div>
            </div>

            <div className='mt-4'>
              <div
                ref={(r) => {
                  if (r) contentRef.current = r
                }}
                style={{ color: '#000', padding: '20px' }}
              >
                <div
                  style={{ display: 'flex', gap: '20px', alignItems: 'center' }}
                >
                  <div>
                    <img
                      alt='logo'
                      src={
                        billing?.quotation?.agency?.zone?.commercialCompany
                          .image || imgLog
                      }
                      style={{ maxWidth: '200px' }}
                      width='200'
                      height='auto'
                      // crossOrigin='*'
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0px'
                    }}
                  >
                    <h1 style={{ margin: 0, padding: 0 }}>
                      SOLICITUD DE FACTURA
                    </h1>
                    <div style={{ fontSize: '16px' }}>
                      <p style={{ margin: 0, padding: 0 }}>
                        <b>Código de factura:</b> {parseId(billing.id)}
                      </p>
                      <p style={{ margin: 0, padding: 0 }}>
                        <b>Código de crédito:</b>{' '}
                        {billing.quotation?.credit
                          ? parseId(billing.quotation.credit.id)
                          : 'N/A'}{' '}
                      </p>
                      <p style={{ margin: 0, padding: 0 }}>
                        <b>Fecha:</b>{' '}
                        {moment(billing.created).format('DD-MM-YYYY')}
                      </p>
                      <p style={{ margin: 0, padding: 0 }}>
                        <b>Agencia:</b>{' '}
                        {billing?.quotation?.agency?.name || 'N/A'}
                      </p>
                    </div>
                  </div>
                </div>

                <div>
                  <h5
                    style={{
                      color: '#000',
                      marginTop: '10px',
                      borderBottom: '6px double #000'
                    }}
                  >
                    Deudor / Información personal
                  </h5>
                  {infoDeudor.map(({ id, info1, info2 }) => (
                    <div
                      key={id}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        borderBottom: '1px solid #000'
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flex: 1
                        }}
                      >
                        <h6
                          style={{
                            padding: 0,
                            margin: 0,
                            fontWeight: 'bold',
                            fontSize: '10px',
                            color: '#000'
                          }}
                        >
                          {info1.name}
                        </h6>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            fontSize: '15px',
                            textTransform: 'uppercase'
                          }}
                        >
                          {info1.value}
                        </p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flex: 1
                        }}
                      >
                        <h6
                          style={{
                            padding: 0,
                            margin: 0,
                            fontWeight: 'bold',
                            fontSize: '10px',
                            color: '#000'
                          }}
                        >
                          {info2.name}
                        </h6>
                        <p
                          style={{
                            padding: 0,
                            margin: 0,
                            fontSize: '15px',
                            textTransform: 'uppercase'
                          }}
                        >
                          {info2.value}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>

                {ventaNegocio.map(
                  ({ negocio, venta, accesorios, total }, idx) => (
                    <div key={idx}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          borderBottom: '6px double #000'
                        }}
                      >
                        <h5
                          style={{ margin: 0, marginTop: '8px', color: '#000' }}
                        >
                          Detalle de la venta
                        </h5>
                        <h5
                          style={{ margin: 0, marginTop: '8px', color: '#000' }}
                        >
                          Condiciones del negocio
                        </h5>
                      </div>

                      <div>
                        {getArr(venta.length, negocio.length).map(
                          (_, index) => (
                            <div
                              key={index}
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                borderBottom: '1px solid #000'
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column'
                                }}
                              >
                                {venta[index] && (
                                  <>
                                    <h6
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                        fontWeight: 'bold',
                                        fontSize: '10px',
                                        color: '#000'
                                      }}
                                    >
                                      {venta[index].name}
                                    </h6>
                                    <p
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                        fontSize: '15px',
                                        textTransform: 'uppercase'
                                      }}
                                    >
                                      {venta[index].value}
                                    </p>
                                  </>
                                )}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'end',
                                  textAlign: 'right'
                                }}
                              >
                                {negocio[index] && (
                                  <>
                                    <h6
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                        fontWeight: 'bold',
                                        fontSize: '10px',
                                        color: '#000'
                                      }}
                                    >
                                      {negocio[index].name}
                                    </h6>
                                    <p
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                        fontSize: '15px',
                                        textTransform: 'uppercase'
                                      }}
                                    >
                                      {negocio[index].value}
                                    </p>
                                  </>
                                )}
                              </div>
                            </div>
                          )
                        )}
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          borderBottom: '6px double #000',
                          marginTop: '16px'
                        }}
                      >
                        <h5
                          style={{ margin: 0, marginTop: '8px', color: '#000' }}
                        >
                          Accesorios
                        </h5>
                        <h5
                          style={{ margin: 0, marginTop: '8px', color: '#000' }}
                        >
                          Total
                        </h5>
                      </div>

                      <div>
                        {total.map((_tot, i) => (
                          <div
                            key={i}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              borderBottom: '1px solid #000'
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column'
                              }}
                            >
                              {i < 3 && (
                                <>
                                  <h6
                                    style={{
                                      padding: 0,
                                      margin: 0,
                                      fontWeight: 'bold',
                                      fontSize: '10px',
                                      color: '#000'
                                    }}
                                  >
                                    {accesorios[i].name}
                                  </h6>
                                  <p
                                    style={{
                                      padding: 0,
                                      margin: 0,
                                      fontSize: '15px',
                                      textTransform: 'uppercase'
                                    }}
                                  >
                                    {accesorios[i].value}
                                  </p>
                                </>
                              )}
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'end',
                                textAlign: 'right'
                              }}
                            >
                              {total[i] && (
                                <>
                                  <h6
                                    style={{
                                      padding: 0,
                                      margin: 0,
                                      fontWeight: 'bold',
                                      fontSize: '10px',
                                      color: '#000'
                                    }}
                                  >
                                    {total[i].name}
                                  </h6>
                                  <p
                                    style={{
                                      padding: 0,
                                      margin: 0,
                                      fontSize: '15px',
                                      textTransform: 'uppercase'
                                    }}
                                  >
                                    {total[i].value}
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                )}
                <div>
                  <h5
                    style={{
                      color: '#000',
                      marginTop: '10px',
                      borderBottom: '6px double #000'
                    }}
                  >
                    Observaciones del crédito
                  </h5>

                  {billing?.quotation?.credit ? (
                    <div>
                      <ul>
                        <li>
                          El crédito tiene una cuota inicial de{' '}
                          <b>
                            {parsePrice(billing.quotation.credit.initialFee)}
                          </b>
                        </li>
                        <li>
                          El saldo a financiar es{' '}
                          <b>
                            {parsePrice(
                              Number(billing.quotation.credit.productValue) -
                                Number(billing.quotation.credit.initialFee)
                            )}
                          </b>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div>NR</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </TabPane>
  )
}
