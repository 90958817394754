import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  TabPane,
  Table
} from 'reactstrap'
import { getColorBg, getIcon, hasPermission, parseId } from '../../../helpers'
import moment from 'moment'
import { useDisclosure } from '../../../hooks/useDisclosure'
import { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { DELETE_SUPPORT } from '../../../graphql/mutations/creditMutations'
import { useGetUser } from '../../../hooks/user'
import { ALL_PERMISSIONS, STATUS_CREDITS_OBJ } from '../../../helpers/consts'

const ARR_CAN_STATUS = [
  STATUS_CREDITS_OBJ.INCOMPLETO,
  STATUS_CREDITS_OBJ.SIN_REVISAR,
  STATUS_CREDITS_OBJ.PENDIENTE
]

/**
 *
 * @param {{supports: {node: {id: string;name: string;file: string; deleted: boolean};}[], handleClickDown: ({ file,  name}: {file: string ,name: string})=>void, refetch: ()=>void, creditStatus: string}} props
 * @returns {JSX.Element}
 */
export const TabSuports = ({
  supports,
  handleClickDown,
  refetch,
  creditStatus
}) => {
  const [
    gqlDeleteSupport,
    { data: dataDelete, loading: loadingDelete, error: errorDelete }
  ] = useMutation(DELETE_SUPPORT)
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [infoDelete, setInfoDelete] = useState(null)

  const { permissions } = useGetUser()

  useEffect(() => {
    if (!errorDelete) return
    toast.error(`Error: ${errorDelete.message}`)
  }, [errorDelete])

  useEffect(() => {
    if (!dataDelete) return
    if (dataDelete.supportDelete.success) {
      toast.success('Soporte eliminado correctamente')
      refetch()
      setInfoDelete(null)
      onClose()
    } else if (dataDelete.supportDelete.errors) {
      toast.error(
        `Error: ${
          dataDelete.supportDelete.errors?.message ||
          'Algo ocurrió y no se pudo eliminar soporte'
        }`
      )
    }
  }, [dataDelete])

  const handleDeleteSupport = () => {
    if (!infoDelete) return
    gqlDeleteSupport({
      variables: {
        input: {
          supportId: parseId(infoDelete.id)
        }
      }
    })
  }
  const supportsView = supports.filter(({ node }) => !node.deleted)

  const canDeleteSupport = hasPermission(
    permissions,
    ALL_PERMISSIONS.PUEDE_ELIMINAR_ENTIDAD_ADMIN
  )
    ? true
    : ARR_CAN_STATUS.some((st) => st === creditStatus)

  return (
    <>
      <TabPane tabId='3'>
        <Card>
          <CardBody>
            <div className='d-flex align-items-center mb-4'>
              <h5 className='card-title flex-grow-1 mb-0'>Soportes</h5>
              {/* <div className='flex-shrink-0'>
                            <Input
                              className='form-control d-none'
                              type='file'
                              id='formFile'
                            />
                            <Label
                              htmlFor='formFile'
                              className='btn btn-danger'
                            >
                              <i className='ri-upload-2-fill me-1 align-bottom' />{' '}
                              Añadir soporte
                            </Label>
                          </div> */}
            </div>
            <Row>
              <Col lg={12}>
                <div className='table-responsive'>
                  {supportsView.length > 0 ? (
                    <Table className='table-borderless align-middle mb-0'>
                      <thead className='table-light'>
                        <tr>
                          <th scope='col' style={{ width: '60px' }}>
                            #
                          </th>
                          <th scope='col'>Nombre</th>
                          <th scope='col'>Fecha</th>
                          <th scope='col'>Tipo</th>
                          <th scope='col' />
                        </tr>
                      </thead>
                      <tbody>
                        {supportsView.map(({ node }, idx) => (
                          <tr key={node.id}>
                            <td>{idx + 1 < 10 ? `0${idx + 1}` : idx}</td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='avatar-sm'>
                                  <div
                                    className={`avatar-title bg-soft-${getColorBg(
                                      node.file
                                    )} text-${getColorBg(
                                      node.file
                                    )} rounded fs-36`}
                                  >
                                    <i
                                      className={`mdi ${getIcon(node.file)}`}
                                    />
                                  </div>
                                </div>
                                <div className='ms-3 flex-grow-1'>
                                  <h6 className='fs-15 mb-0'>{node.name}</h6>
                                </div>
                              </div>
                            </td>
                            <td>
                              {moment(node.created).format('DD MMM [del] YYYY')}{' '}
                              <small>
                                {moment(node.created).format('hh:mm A')}
                              </small>
                            </td>
                            <td>
                              {new URL(node.file).pathname.split('.')?.at(-1)}
                            </td>
                            <td>
                              <div className='d-flex align-items-center gap-2'>
                                <button
                                  className='border-0 p-0 m-0 bg-transparent'
                                  title='Descargar'
                                  onClick={() => {
                                    handleClickDown({
                                      file: node.file,
                                      name: node.name
                                    })
                                  }}
                                >
                                  <i className='mdi mdi-download fs-24 text-secondary' />
                                </button>
                                {canDeleteSupport && (
                                  <button
                                    className='border-0 p-0 m-0 bg-transparent'
                                    title='Eliminar'
                                    onClick={() => {
                                      setInfoDelete({
                                        id: node.id,
                                        name: node.name
                                      })
                                      onOpen()
                                    }}
                                  >
                                    <i className='mdi mdi-trash-can fs-24 text-danger' />
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <div style={{ height: '30vh' }}>
                      No hay soportes para este crédito
                    </div>
                  )}
                </div>
                {/* <div className='text-center mt-3'>
                              <Link to='#' className='text-success '>
                                <i className='mdi mdi-loading mdi-spin fs-20 align-middle me-2' />
                                Load more{' '}
                              </Link>
                            </div> */}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </TabPane>

      {infoDelete && (
        <Modal
          isOpen={isOpen}
          toggle={onClose}
          centered
          onClosed={() => {
            setInfoDelete(null)
          }}
        >
          <ModalHeader className='bg-soft-info p-4' toggle={onClose}>
            Eliminar soporte
          </ModalHeader>
          <ModalBody>
            <p className='fw-semibold fs-4'>
              ¿Seguro que desea eliminar este soporte?
            </p>
            <p className='text-muted'>
              Esta acción eliminará a{' '}
              <span style={{ fontStyle: 'italic', color: '#111' }}>
                "{infoDelete.name}"
              </span>{' '}
              del crédito
            </p>
          </ModalBody>
          <ModalFooter className='d-flex align-items-center justify-content-center gap-2'>
            <Button
              color='light'
              className='flex-1 text-center'
              onClick={onClose}
            >
              No
            </Button>
            <Button
              color='danger'
              className='flex-1 text-center d-flex gap-1 align-items-center justify-content-center'
              onClick={handleDeleteSupport}
            >
              {loadingDelete && <Spinner size='sm' color='light' />}
              SI
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
