import { gql } from '@apollo/client'

export const CREATE_PRODUCT = gql`
  mutation ($input: ProductCreateUpdateInput!) {
    productCreateUpdate(input: $input) {
      product {
        id
      }
      success
      errors {
        field
        message
      }
    }
  }
`

export const ADD_SPECIFICATIONS = gql`
  mutation specificationCreate(
    $specifications: [SpecificationInput!]!
    $productId: Int!
  ) {
    specificationCreate(
      specifications: $specifications
      productId: $productId
    ) {
      errors {
        field
        message
      }
      success
    }
  }
`
export const ADD_CATEGORIES = gql`
  mutation categoryCreateUpdate($input: CategoryCreateUpdateInput!) {
    categoryCreateUpdate(input: $input) {
      errors {
        field
        messages
      }
      category {
        created
        description
        id
        name
      }
    }
  }
`

export const ACTIVE_DESACTIVE_PRODUCT = gql`
  mutation activeInactiveProduct($input: ProductActivateDeactivateInput!) {
    activeInactiveProduct(input: $input) {
      errors {
        field
        message
      }
      success
      product {
        active
        basePrice
      }
    }
  }
`

export const ADD_PRICES = gql`
  mutation priceCreateUpdate($input: PriceCreateUpdateMutationInput!) {
    priceCreateUpdate(input: $input) {
      clientMutationId
      errors {
        field
        messages
      }
      price {
        name
        priceType
      }
    }
  }
`

export const ADD_DISCOUNT = gql`
  mutation discountCreateUpdate($input: DiscountCreateUpdateInput!) {
    discountCreateUpdate(input: $input) {
      clientMutationId
      discount {
        agency {
          name
        }
        discountType
        name
        value
      }
      errors {
        field
        messages
      }
    }
  }
`
export const UPPLOAD_FILE_PRODUCTS_STR = `
mutation masiveUpdateProduct($input: ProductMasiveUpdateInput!) {
  masiveProductUpdate(input: $input) {
    errors {
      field
      message
    }
    success
  }
}
`

export const UPDATE_SPECIFICATION = gql`
  mutation specificationUpdate($input: SpecificationInput!) {
    specificationUpdate(input: $input) {
      success
      errors {
        field
        message
      }
      specification {
        name
        value
      }
    }
  }
`
export const DELETE_SPECIFICATION = gql`
  mutation specificationDelete($specificationId: Int!) {
    specificationDelete(specificationId: $specificationId) {
      errors {
        field
        message
      }
      success
    }
  }
`

export const CREATE_MASIVE_PRODUCTS = `
mutation masiveProductCreate($input: ProductCreateMasiveInput!) {
  masiveProductCreate(input: $input){
    success
    errors{
      field
      message
    }
  }
}
`
export const DELETE_DISCOUNT_IN_PRODUCT = gql`
  mutation discountDelete($discountId: Int!) {
    discountDelete(discountId: $discountId) {
      errors {
        field
        message
      }
      success
    }
  }
`
