import { useState, useEffect, Fragment } from 'react'
import {
  /* Button,
  Table,
  Fade,
  CardFooter, */
  FormGroup,
  FormFeedback,
  CardBody,
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
  Button,
  Collapse,
  Alert
} from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation, useQuery } from '@apollo/client'
import {
  GET_AGENCIES_USER,
  GET_COMERCIAL_COMPANY_USER,
  GET_ROLES_TO_CREATE_USER,
  GET_ZONES_USER
} from '../../../graphql/queries/getUsuarios'
import { toast } from 'react-toastify'
import classnames from 'classnames'

import DualListBox from 'react-dual-listbox'
import 'react-dual-listbox/lib/react-dual-listbox.css'

import Select from 'react-select'
// import { parseId } from '../../../helpers'
import {
  ALL_PERMISSIONS,
  ERROR_MESSAGES,
  INIT_INFO_ROLES,
  LANG_SELECT_DUAL_LIST,
  ROLES
} from '../../../helpers/consts'
import { hasPermission, parseId } from '../../../helpers'
import { CREATE_USER } from '../../../graphql/mutations/userMutations'
import { Link, useNavigate } from 'react-router-dom'
import { useGetUser } from '../../../hooks/user'
/* import { ROLES } from '../../../helpers/consts' */
// import { parseId } from '../../../helpers'

const validationSchema = Yup.object({
  firstName: Yup.string().required('El nombre es obligatorio'),
  lastName: Yup.string().required('El apellido es obligatorio'),
  email: Yup.string()
    .email('Ingrese un correo electrónico válido')
    .required('El correo electrónico es obligatorio'),
  password: Yup.string()
    .required('La contraseña es obligatoria')
    .min(8, 'La contraseña debe tener al menos 8 caracteres')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
      'La contraseña debe contener al menos una minúscula, una mayúscula y un número'
    ),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
    .required('Debe confirmar la contraseña'),
  phoneNumber: Yup.string().required('El número de teléfono es obligatorio')
})

const INIT_VALUES_USER = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordConfirm: '',
  phoneNumber: '',
  roles: [],
  agencies: [],
  zones: [],
  commercialCompany: ''
}

export default function AddUser() {
  const [
    gqlCreateUser,
    { data: dataCreate, loading: loadingCreate, error: errorCreate }
  ] = useMutation(CREATE_USER)
  const {
    data: dataRol,
    loading: loadingRol,
    error: errorRol
  } = useQuery(GET_ROLES_TO_CREATE_USER, { fetchPolicy: 'no-cache' })
  const {
    data: dataCC,
    loading: loadingCC,
    error: errorCC
  } = useQuery(GET_COMERCIAL_COMPANY_USER, { fetchPolicy: 'no-cache' })
  const {
    data: dataAgency,
    loading: loadingAgency,
    error: errorAgency
  } = useQuery(GET_AGENCIES_USER, { fetchPolicy: 'no-cache' })
  const {
    data: dataZones,
    loading: loadingZones,
    error: errorZones
  } = useQuery(GET_ZONES_USER, { fetchPolicy: 'no-cache' })

  const navigate = useNavigate()

  const { logout, permissions } = useGetUser()

  const [user, setUser] = useState(INIT_VALUES_USER)

  const [infoRoles, setInfoRoles] = useState(INIT_INFO_ROLES)

  // const [seletedRoles, setSeletedRoles] = useState([])
  const [optionsDualSelect, setOptionsDualSelect] = useState([])
  const [selectAgencies, setSelectAgencies] = useState([])

  const [selectOneRol, setSelectOneRol] = useState(null)
  const [optionsData, setOptionsData] = useState([])

  const [selectOptionsCC, setSelectOptionsCC] = useState([])
  const [commercialCompany, setCommercialCompany] = useState(null)
  const [keySelect, setKeySelect] = useState(Date.now().toString())

  const [selectZonesData, setSelectZonesData] = useState([])
  const [zoneSelect, setZoneSelect] = useState([])
  // const [selectOneCC, setSelectOneCC] = useState(null)

  const [activeVerticalTab, setactiveVerticalTab] = useState(7)
  const [passedverticalSteps, setPassedverticalSteps] = useState([1])

  const [userIdCreate, setUserIdCreate] = useState(null)

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirm: '',
      phoneNumber: ''
    },
    validationSchema,
    onSubmit: (_values) => {
      toggleVerticalTab(activeVerticalTab + 1)
    }
  })

  useEffect(() => {
    if (!hasPermission(permissions, ALL_PERMISSIONS.PUEDE_ANADIR_USUARIO)) {
      navigate('/')
    }
  }, [permissions])

  useEffect(() => {
    if (errorRol) {
      toast.error(`ERROR: ${errorRol.message}`)
      if (errorRol.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorRol])
  useEffect(() => {
    if (errorAgency) toast.error(`ERROR: ${errorAgency.message}`)
  }, [errorAgency])
  useEffect(() => {
    if (errorCC) toast.error(`ERROR: ${errorCC.message}`)
  }, [errorCC])
  useEffect(() => {
    if (errorZones) toast.error(`ERROR: ${errorZones.message}`)
  }, [errorZones])

  useEffect(() => {
    if (errorCreate) toast.error(`ERROR: ${errorCreate.message}`)
  }, [errorCreate])

  useEffect(() => {
    if (dataRol) {
      const dataParse = dataRol.listGroups.edges.map(({ node }) => ({
        value: parseId(node.id),
        label: node.name,
        color: '#208aff'
      }))
      setOptionsData(dataParse)
    }
  }, [dataRol])

  useEffect(() => {
    if (dataCC) {
      const dataParse = dataCC.listCommercialCompany.edges.map(({ node }) => ({
        value: node.id,
        label: node.name
      }))
      setSelectOptionsCC(dataParse)
    }
  }, [dataCC])

  useEffect(() => {
    if (dataZones) {
      const arr = dataZones.listZones.edges.map(({ node }) => ({
        label: node.name,
        value: node.id,
        color: '#208aff'
      }))
      setSelectZonesData(arr)
    }
  }, [dataZones])

  useEffect(() => {
    if (dataAgency) {
      const arr = dataAgency.listAgency.edges
      const opciones = []

      arr.forEach(({ node }) => {
        const zona = node.zone.name
        const agente = { value: node.id, label: node.name }

        // Verificar si la zona ya existe en opciones
        const zonaExistente = opciones.find((opcion) => opcion.label === zona)

        if (zonaExistente) {
          zonaExistente.options.push(agente)
        } else {
          opciones.push({
            label: zona,
            options: [agente]
          })
        }
      })
      setOptionsDualSelect(opciones)
    }
  }, [dataAgency])

  useEffect(() => {
    if (dataCreate) {
      if (dataCreate.userCreateUpdate.success) {
        const newId = dataCreate.userCreateUpdate.user.id
        setUserIdCreate(parseId(newId))
        toggleVerticalTab(9)
      } else {
        toast.error(`ERROR: ${dataCreate.userCreateUpdate.errors.message}`)
      }
    }
  }, [dataCreate])

  const createUser = () => {
    const newUser = {
      ...user,
      ...formik.values,
      commercialCompany: parseId(user.commercialCompany)
    }
    gqlCreateUser({
      variables: {
        user: newUser
      }
    })
  }

  /* useEffect(() => {
    const roles = seletedRoles.map(({ id }) => id)
    formik.setFieldValue('rol', roles)
  }, [seletedRoles]) */

  const toggleVerticalTab = (tab) => {
    if (activeVerticalTab !== tab) {
      const modifiedSteps = [...passedverticalSteps, tab]

      if (tab >= 7 && tab <= 11) {
        setactiveVerticalTab(tab)
        setPassedverticalSteps(modifiedSteps)
      }
    }
  }

  const isVoidRoles = () => {
    return (
      !infoRoles.ADMIN.company &&
      infoRoles.ASESOR.position.length === 0 &&
      infoRoles.JEFE_ZONA.zones.length === 0 &&
      infoRoles.FACTURADOR.zones.length === 0 &&
      infoRoles.ANALISTA.zones.length === 0 &&
      !infoRoles.JEFE_CREDITO.select &&
      !infoRoles.CARTERA.select &&
      !infoRoles.GERENTE.select &&
      !infoRoles.CONTABILIDAD.select
    )
  }

  const getTotRoles = () => {
    let tot = 0
    if (infoRoles.ADMIN.company) tot++
    if (infoRoles.ASESOR.position.length > 0) tot++
    if (infoRoles.JEFE_ZONA.zones.length > 0) tot++
    if (infoRoles.FACTURADOR.zones.length > 0) tot++
    if (infoRoles.ANALISTA.zones.length > 0) tot++
    if (infoRoles.JEFE_CREDITO.select) tot++
    if (infoRoles.GERENTE.select) tot++
    if (infoRoles.CARTERA.select) tot++
    if (infoRoles.CONTABILIDAD.select) tot++

    return tot
  }

  const renderInfoAsesor = () => {
    const datosAgrupados = infoRoles.ASESOR.position.reduce(
      (acumulador, elemento) => {
        const { zone, ...resto } = elemento

        const zonaExistente = acumulador.find((opcion) => opcion.zone === zone)
        if (zonaExistente) {
          zonaExistente.datos.push(resto)
        } else {
          acumulador.push({ zone, datos: [resto] })
        }

        return acumulador
      },
      []
    )

    return datosAgrupados.map(({ zone, datos }) => (
      <Fragment key={zone}>
        <span>{optionsDualSelect[zone].label}</span>
        {datos.map(({ agen }) => (
          <small key={agen} className='text-muted d-block'>
            {optionsDualSelect[zone].options[agen].label}
          </small>
        ))}
      </Fragment>
    ))
  }

  const renderZoneNames = () => {
    return zoneSelect.map(({ value, label }) => (
      <small key={value} className='text-muted d-block'>
        {label}
      </small>
    ))
  }

  document.title = 'Usuarios | TOTAL SALES'

  if (loadingRol || loadingCC || loadingAgency || loadingZones) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Ver Usuarios' pageTitle='Usuarios' />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between border-0 gap-3 flex-md-column flex-lg-row flex-sm-column '>
                  <div className='w-100'>
                    <h5 className='card-title mb-0'>Usuarios</h5>
                  </div>
                  <div className='w-100'>
                    <p
                      className='placeholder-glow w-100 text-end'
                      style={{ height: '30px' }}
                    >
                      <span
                        className='placeholder col-4'
                        style={{ height: '100%' }}
                      />
                    </p>
                  </div>
                </CardHeader>
                <CardBody style={{ height: '30vh' }}>
                  <div className='d-flex justify-content-center align-items-center h-100'>
                    <Spinner style={{ width: '3em', height: '3em' }} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  if (!dataRol) return null
  if (!dataCC) return null
  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb
          title='Agregar Usuarios'
          pageTitle='Usuarios'
          back='/usuarios'
        />
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader>
                <h4 className='card-title mb-0'>Agregar usuario</h4>
              </CardHeader>
              <CardBody>
                <div className='vertical-navs-step form-steps'>
                  <Row className='gy-5'>
                    <Col lg={3}>
                      <Nav className='flex-column custom-nav nav-pills'>
                        <NavItem>
                          <NavLink
                            // href='#'
                            className={classnames({
                              active: activeVerticalTab === 7,
                              done:
                                activeVerticalTab <= 11 && activeVerticalTab > 7
                            })}
                            onClick={() => {
                              if (activeVerticalTab !== 9) {
                                toggleVerticalTab(7)
                              }
                            }}
                          >
                            <span className='step-title me-2'>
                              <i className='ri-close-circle-fill step-icon me-2' />
                              1:{' '}
                            </span>
                            Información general
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            href='#'
                            className={classnames({
                              active: activeVerticalTab === 8,
                              done:
                                activeVerticalTab <= 11 &&
                                activeVerticalTab >= 8
                            })}
                            onClick={() => {
                              if (!formik.isValid) return
                              if (!formik.values.firstName) return
                              if (activeVerticalTab !== 9) {
                                toggleVerticalTab(8)
                              }
                            }}
                          >
                            <span className='step-title me-2'>
                              <i className='ri-close-circle-fill step-icon me-2' />
                              2:{' '}
                            </span>
                            Roles
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeVerticalTab === 9,
                              done:
                                activeVerticalTab <= 10 &&
                                activeVerticalTab >= 9
                            })}
                            onClick={() => {
                              if (!formik.isValid) return
                              if (!formik.values.firstName) return
                              toggleVerticalTab(9)
                            }}
                          >
                            <span className='step-title me-2'>
                              <i className='ri-close-circle-fill step-icon me-2' />
                              3:{' '}
                            </span>
                            Fin
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                    <Col lg={6}>
                      <div className='px-lg-4'>
                        <TabContent activeTab={activeVerticalTab}>
                          <TabPane tabId={7}>
                            <form
                              onSubmit={formik.handleSubmit}
                              autoComplete='off'
                            >
                              <div>
                                <h5>Información básica</h5>
                                <p className='text-muted'>
                                  Completa todos los campos
                                </p>
                              </div>

                              <Row className='g-3'>
                                <Col lg={6}>
                                  <div>
                                    <Label
                                      htmlFor='firstName'
                                      className='form-label'
                                    >
                                      Primer Nombre
                                    </Label>

                                    <Input
                                      name='firstName'
                                      id='firstName'
                                      className='form-control'
                                      placeholder='Primer nombre'
                                      type='text'
                                      validate={{
                                        required: { value: true }
                                      }}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.firstName}
                                      invalid={Boolean(
                                        formik.touched.firstName &&
                                          formik.errors.firstName
                                      )}
                                    />
                                    {formik.touched.firstName &&
                                      formik.errors.firstName && (
                                        <FormFeedback type='invalid'>
                                          {formik.errors.firstName}
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div>
                                    <Label
                                      htmlFor='lastName'
                                      className='form-label'
                                    >
                                      Apellido
                                    </Label>
                                    <Input
                                      name='lastName'
                                      id='lastName'
                                      className='form-control'
                                      placeholder='Apellido'
                                      type='text'
                                      validate={{
                                        required: { value: true }
                                      }}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.lastName}
                                      invalid={
                                        !!(
                                          formik.touched.lastName &&
                                          formik.errors.lastName
                                        )
                                      }
                                    />
                                    {formik.touched.lastName &&
                                      formik.errors.lastName && (
                                        <FormFeedback type='invalid'>
                                          {formik.errors.lastName}
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div>
                                    <Label
                                      htmlFor='phone-field'
                                      className='form-label'
                                    >
                                      Teléfono
                                    </Label>
                                    <Input
                                      name='phoneNumber'
                                      id='phone-field'
                                      className='form-control'
                                      placeholder='Ingrese teléfono'
                                      type='tel'
                                      validate={{
                                        required: { value: true }
                                      }}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.phoneNumber}
                                      invalid={
                                        !!(
                                          formik.touched.phoneNumber &&
                                          formik.errors.phoneNumber
                                        )
                                      }
                                    />
                                    {formik.touched.phoneNumber &&
                                    formik.errors.phoneNumber ? (
                                      <FormFeedback type='invalid'>
                                        {formik.errors.phoneNumber}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div>
                                    <Label
                                      htmlFor='email'
                                      className='form-label'
                                    >
                                      Correo electrónico
                                    </Label>
                                    <Input
                                      name='email'
                                      id='email'
                                      className='form-control'
                                      placeholder='Correo electrónico'
                                      type='text'
                                      validate={{
                                        required: { value: true }
                                      }}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.email}
                                      invalid={Boolean(
                                        formik.touched.email &&
                                          formik.errors.email
                                      )}
                                    />
                                    {formik.touched.email &&
                                      formik.errors.email && (
                                        <FormFeedback type='invalid'>
                                          {formik.errors.email}
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col>

                                <Col lg={6}>
                                  <div>
                                    <Label
                                      htmlFor='password'
                                      className='form-label'
                                    >
                                      Contraseña
                                    </Label>
                                    <Input
                                      name='password'
                                      id='password'
                                      type='password'
                                      className='form-control'
                                      placeholder='Contraseña'
                                      validate={{
                                        required: { value: true }
                                      }}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.password}
                                      invalid={Boolean(
                                        formik.touched.password &&
                                          formik.errors.password
                                      )}
                                    />
                                    {formik.touched.password &&
                                      formik.errors.password && (
                                        <FormFeedback type='invalid'>
                                          {formik.errors.password}
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div>
                                    <Label
                                      htmlFor='passwordConfirm'
                                      className='form-label'
                                    >
                                      Confirme contraseña
                                    </Label>
                                    <Input
                                      name='passwordConfirm'
                                      type='password'
                                      id='passwordConfirm'
                                      className='form-control'
                                      placeholder='Confirme contraseña'
                                      validate={{
                                        required: { value: true }
                                      }}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.passwordConfirm}
                                      invalid={Boolean(
                                        formik.touched.passwordConfirm &&
                                          formik.errors.passwordConfirm
                                      )}
                                      onPaste={(e) => e.preventDefault()}
                                    />
                                    {formik.touched.passwordConfirm &&
                                      formik.errors.passwordConfirm && (
                                        <FormFeedback type='invalid'>
                                          {formik.errors.passwordConfirm}
                                        </FormFeedback>
                                      )}
                                  </div>
                                </Col>
                              </Row>

                              <div className='d-flex align-items-start gap-3 mt-4'>
                                <button
                                  type='submit'
                                  className='btn btn-success btn-label right ms-auto nexttab nexttab'
                                >
                                  <i className='ri-arrow-right-line label-icon align-middle fs-16 ms-2' />
                                  Siguiente
                                </button>
                              </div>
                            </form>
                          </TabPane>

                          <TabPane tabId={8}>
                            <div>
                              <h5>Añadir roles</h5>
                              <p className='text-muted'>
                                Ingresa el/los rol(es) que tendrá el usuario
                              </p>
                            </div>

                            <div>
                              <Row className='g-3'>
                                <Col lg={12}>
                                  <FormGroup>
                                    <Label>Seleccione Rol</Label>
                                    <Select
                                      key={keySelect}
                                      className='basic-single'
                                      classNamePrefix='select'
                                      placeholder='Seleccione un rol'
                                      isClearable
                                      isSearchable
                                      styles={{
                                        option: (
                                          styles,
                                          {
                                            data,
                                            isDisabled,
                                            isFocused,
                                            isSelected
                                          }
                                        ) => {
                                          return {
                                            ...styles,
                                            backgroundColor: isDisabled
                                              ? undefined
                                              : isSelected
                                              ? data.color
                                              : isFocused
                                              ? '#208aff96'
                                              : undefined
                                          }
                                        }
                                      }}
                                      onChange={(e) => {
                                        if (e) {
                                          setSelectOneRol({
                                            id: e.value,
                                            name: e.label
                                          })
                                        } else {
                                          setSelectOneRol(null)
                                        }
                                      }}
                                      id='products'
                                      name='product'
                                      options={optionsData}
                                      noOptionsMessage={() => {
                                        return 'No se encontró rol'
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>

                              <hr className='my-4 text-muted' />
                              <Collapse
                                isOpen={
                                  selectOneRol &&
                                  selectOneRol.name === ROLES.ADMIN
                                }
                              >
                                <Row className='g-3 my-2'>
                                  <Col lg={12}>
                                    <FormGroup>
                                      <Label for='exampleSelect'>
                                        Empresa comercial
                                      </Label>
                                      <Col sm={10}>
                                        <Input
                                          id='exampleSelect'
                                          name='select'
                                          type='select'
                                          value={commercialCompany}
                                          onChange={(e) => {
                                            setCommercialCompany(e.target.value)
                                          }}
                                        >
                                          <option value=''>
                                            Seleccione una empresa comercial
                                          </option>
                                          {selectOptionsCC.map(
                                            ({ label, value }) => (
                                              <option key={value} value={value}>
                                                {label}
                                              </option>
                                            )
                                          )}
                                        </Input>
                                      </Col>
                                    </FormGroup>
                                    <div className='d-flex justify-content-end'>
                                      <Button
                                        color='info'
                                        size='sm'
                                        disabled={!commercialCompany}
                                        className='d-flex align-items-center gap-1'
                                        onClick={() => {
                                          setUser((prev) => {
                                            const roles = [
                                              ...prev.roles,
                                              selectOneRol.id
                                            ]
                                            return {
                                              ...prev,
                                              roles,
                                              commercialCompany
                                            }
                                          })
                                          setOptionsData((prev) =>
                                            prev.map((arr) => {
                                              if (
                                                arr.value === selectOneRol.id
                                              ) {
                                                return {
                                                  ...arr,
                                                  isDisabled: true
                                                }
                                              }
                                              return arr
                                            })
                                          )
                                          setInfoRoles((prev) => ({
                                            ...prev,
                                            ADMIN: {
                                              ...prev.ADMIN,
                                              company: selectOptionsCC.find(
                                                (arr) =>
                                                  arr.value ===
                                                  commercialCompany
                                              )?.label
                                            }
                                          }))
                                          setSelectOneRol(null)
                                          setKeySelect(Date.now().toString())
                                        }}
                                      >
                                        <i className='bx bx-list-plus' />
                                        Agregar rol
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              </Collapse>
                              <Collapse
                                isOpen={
                                  selectOneRol &&
                                  selectOneRol.name === ROLES.JEFE_ZONA
                                }
                              >
                                <Row className='g-3 my-2'>
                                  <Col lg={12}>
                                    <FormGroup>
                                      <Label for='zonaSelect'>Zona</Label>
                                      <Col sm={10}>
                                        <Select
                                          className='basic-single'
                                          classNamePrefix='select'
                                          placeholder='Seleccione las zonas'
                                          isClearable
                                          isSearchable
                                          isMulti
                                          value={zoneSelect}
                                          styles={{
                                            control: (styles) => ({
                                              ...styles,
                                              backgroundColor: 'white'
                                            }),
                                            multiValueLabel: (styles) => ({
                                              ...styles,
                                              color: 'black'
                                            }),
                                            multiValue: (styles) => {
                                              return {
                                                ...styles,
                                                backgroundColor: '#00b8d91a'
                                              }
                                            },
                                            option: (
                                              styles,
                                              {
                                                data,
                                                isDisabled,
                                                isFocused,
                                                isSelected
                                              }
                                            ) => {
                                              return {
                                                ...styles,
                                                backgroundColor: isDisabled
                                                  ? undefined
                                                  : isSelected
                                                  ? data.color
                                                  : isFocused
                                                  ? '#208aff96'
                                                  : undefined
                                              }
                                            }
                                          }}
                                          onChange={(e) => {
                                            setZoneSelect(e)
                                          }}
                                          id='zonas'
                                          name='zonas'
                                          options={selectZonesData}
                                          noOptionsMessage={() => {
                                            return 'No se encontró zona'
                                          }}
                                        />
                                      </Col>
                                    </FormGroup>
                                    <div className='d-flex justify-content-end'>
                                      <Button
                                        size='sm'
                                        color='info'
                                        className='d-flex align-items-center gap-1'
                                        disabled={zoneSelect.length === 0}
                                        onClick={() => {
                                          setUser((prev) => {
                                            const roles = [
                                              ...prev.roles,
                                              selectOneRol.id
                                            ]
                                            const zones = zoneSelect.map(
                                              ({ value }) => parseId(value)
                                            )
                                            return {
                                              ...prev,
                                              roles,
                                              zones: [...prev.zones, ...zones]
                                            }
                                          })
                                          setOptionsData((prev) =>
                                            prev.map((obj) => {
                                              if (
                                                obj.value === selectOneRol.id
                                              ) {
                                                return {
                                                  ...obj,
                                                  isDisabled: true
                                                }
                                              }
                                              if (
                                                obj.label === ROLES.FACTURADOR
                                              ) {
                                                return {
                                                  ...obj,
                                                  isDisabled: true
                                                }
                                              }
                                              if (
                                                obj.label === ROLES.ANALISTA
                                              ) {
                                                return {
                                                  ...obj,
                                                  isDisabled: true
                                                }
                                              }
                                              return obj
                                            })
                                          )
                                          setInfoRoles((prev) => {
                                            // console.log(
                                            //   'zoneSelect',
                                            //   zoneSelect
                                            // )
                                            const zonesIds = zoneSelect.map(
                                              ({ value }) => parseId(value)
                                            )
                                            return {
                                              ...prev,
                                              JEFE_ZONA: {
                                                ...prev.JEFE_ZONA,
                                                zones: [
                                                  ...prev.JEFE_ZONA.zones,
                                                  ...zonesIds
                                                ]
                                              }
                                            }
                                          })
                                          setSelectOneRol(null)
                                          setKeySelect(Date.now().toString())
                                        }}
                                      >
                                        <i className='bx bx-list-plus' />
                                        Agregar rol
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              </Collapse>
                              <Collapse
                                isOpen={
                                  selectOneRol &&
                                  selectOneRol.name === ROLES.ASESOR
                                }
                              >
                                <Row className='g-3 my-2'>
                                  <Col lg={12}>
                                    <DualListBox
                                      options={optionsDualSelect}
                                      selected={selectAgencies}
                                      onChange={(v) => {
                                        setSelectAgencies(v)
                                      }}
                                      canFilter
                                      simpleValue={selectAgencies}
                                      showHeaderLabels
                                      lang={LANG_SELECT_DUAL_LIST}
                                      icons={{
                                        moveLeft: (
                                          <i className='bx bx-chevron-left' />
                                        ),
                                        moveAllLeft: (
                                          <i className='bx bx-chevrons-left' />
                                        ),
                                        moveRight: (
                                          <i className='bx bx-chevron-right' />
                                        ),
                                        moveAllRight: (
                                          <i className='bx bx-chevrons-right' />
                                        ),
                                        moveDown: (
                                          <i className='bx bx-chevron-down' />
                                        ),
                                        moveUp: (
                                          <i className='bx bx-chevron-up' />
                                        ),
                                        moveTop: (
                                          <i className='bx bx-double-angle-up' />
                                        ),
                                        moveBottom: (
                                          <i className='bx bx-double-angle-down' />
                                        )
                                      }}
                                    />
                                    <div className='mt-2 d-flex justify-content-end'>
                                      <Button
                                        size='sm'
                                        color='info'
                                        className='d-flex align-items-center gap-1'
                                        disabled={selectAgencies.length === 0}
                                        onClick={() => {
                                          const ides = selectAgencies.map(
                                            (id) => parseId(id)
                                          )
                                          setUser((prev) => {
                                            const roles = [
                                              ...prev.roles,
                                              selectOneRol.id
                                            ]
                                            return {
                                              ...prev,
                                              roles,
                                              agencies: [
                                                ...prev.agencies,
                                                ...ides
                                              ]
                                            }
                                          })
                                          setOptionsData((prev) =>
                                            prev.map((arr) => {
                                              if (
                                                arr.value === selectOneRol.id
                                              ) {
                                                return {
                                                  ...arr,
                                                  isDisabled: true
                                                }
                                              }
                                              return arr
                                            })
                                          )
                                          const pos = selectAgencies.map(
                                            (id) => {
                                              const posObj = {}
                                              optionsDualSelect.forEach(
                                                (option, i) => {
                                                  const find =
                                                    option.options.findIndex(
                                                      (el) => el.value === id
                                                    )
                                                  if (find >= 0) {
                                                    posObj.zone = i
                                                    posObj.agen = find
                                                  }
                                                }
                                              )
                                              return posObj
                                            }
                                          )
                                          setInfoRoles((prev) => ({
                                            ...prev,
                                            ASESOR: {
                                              ...prev.ASESOR,
                                              position: pos
                                            }
                                          }))
                                          setSelectOneRol(null)
                                          setKeySelect(Date.now().toString())
                                        }}
                                      >
                                        <i className='bx bx-list-plus' />
                                        Agregar rol
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              </Collapse>
                              <Collapse
                                isOpen={
                                  selectOneRol &&
                                  selectOneRol.name === ROLES.FACTURADOR
                                }
                              >
                                <Row className='g-3 my-2'>
                                  <Col lg={12}>
                                    <FormGroup>
                                      <Label for='zonaSelect'>Zona</Label>
                                      <Col sm={10}>
                                        <Select
                                          className='basic-single'
                                          classNamePrefix='select'
                                          placeholder='Seleccione las zonas'
                                          isClearable
                                          isSearchable
                                          isMulti
                                          value={zoneSelect}
                                          styles={{
                                            control: (styles) => ({
                                              ...styles,
                                              backgroundColor: 'white'
                                            }),
                                            multiValueLabel: (styles) => ({
                                              ...styles,
                                              color: 'black'
                                            }),
                                            multiValue: (styles) => {
                                              return {
                                                ...styles,
                                                backgroundColor: '#00b8d91a'
                                              }
                                            },
                                            option: (
                                              styles,
                                              {
                                                data,
                                                isDisabled,
                                                isFocused,
                                                isSelected
                                              }
                                            ) => {
                                              return {
                                                ...styles,
                                                backgroundColor: isDisabled
                                                  ? undefined
                                                  : isSelected
                                                  ? data.color
                                                  : isFocused
                                                  ? '#208aff96'
                                                  : undefined
                                              }
                                            }
                                          }}
                                          onChange={(e) => {
                                            setZoneSelect(e)
                                          }}
                                          id='zonas'
                                          name='zonas'
                                          options={selectZonesData}
                                          noOptionsMessage={() => {
                                            return 'No se encontró zona'
                                          }}
                                        />
                                      </Col>
                                    </FormGroup>
                                    <div className='d-flex justify-content-end'>
                                      <Button
                                        size='sm'
                                        color='info'
                                        className='d-flex align-items-center gap-1'
                                        disabled={zoneSelect.length === 0}
                                        onClick={() => {
                                          setUser((prev) => {
                                            const roles = [
                                              ...prev.roles,
                                              selectOneRol.id
                                            ]
                                            const zones = zoneSelect.map(
                                              ({ value }) => parseId(value)
                                            )
                                            return {
                                              ...prev,
                                              roles,
                                              zones: [...prev.zones, ...zones]
                                            }
                                          })
                                          setOptionsData((prev) =>
                                            prev.map((obj) => {
                                              if (
                                                obj.value === selectOneRol.id
                                              ) {
                                                return {
                                                  ...obj,
                                                  isDisabled: true
                                                }
                                              }
                                              if (
                                                obj.label === ROLES.JEFE_ZONA
                                              ) {
                                                return {
                                                  ...obj,
                                                  isDisabled: true
                                                }
                                              }
                                              if (
                                                obj.label === ROLES.ANALISTA
                                              ) {
                                                return {
                                                  ...obj,
                                                  isDisabled: true
                                                }
                                              }
                                              return obj
                                            })
                                          )
                                          setInfoRoles((prev) => {
                                            // console.log(
                                            //   'zoneSelect',
                                            //   zoneSelect
                                            // )
                                            const zonesIds = zoneSelect.map(
                                              ({ value }) => parseId(value)
                                            )
                                            return {
                                              ...prev,
                                              FACTURADOR: {
                                                ...prev.FACTURADOR,
                                                zones: [
                                                  ...prev.FACTURADOR.zones,
                                                  ...zonesIds
                                                ]
                                              }
                                            }
                                          })
                                          setSelectOneRol(null)
                                          setKeySelect(Date.now().toString())
                                        }}
                                      >
                                        <i className='bx bx-list-plus' />
                                        Agregar rol
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              </Collapse>
                              <Collapse
                                isOpen={
                                  selectOneRol &&
                                  selectOneRol.name === ROLES.ANALISTA
                                }
                              >
                                <Row className='g-3 my-2'>
                                  <Col lg={12}>
                                    <FormGroup>
                                      <Label for='zonaSelect'>Zona</Label>
                                      <Col sm={10}>
                                        <Select
                                          className='basic-single'
                                          classNamePrefix='select'
                                          placeholder='Seleccione las zonas'
                                          isClearable
                                          isSearchable
                                          isMulti
                                          value={zoneSelect}
                                          styles={{
                                            control: (styles) => ({
                                              ...styles,
                                              backgroundColor: 'white'
                                            }),
                                            multiValueLabel: (styles) => ({
                                              ...styles,
                                              color: 'black'
                                            }),
                                            multiValue: (styles) => {
                                              return {
                                                ...styles,
                                                backgroundColor: '#00b8d91a'
                                              }
                                            },
                                            option: (
                                              styles,
                                              {
                                                data,
                                                isDisabled,
                                                isFocused,
                                                isSelected
                                              }
                                            ) => {
                                              return {
                                                ...styles,
                                                backgroundColor: isDisabled
                                                  ? undefined
                                                  : isSelected
                                                  ? data.color
                                                  : isFocused
                                                  ? '#208aff96'
                                                  : undefined
                                              }
                                            }
                                          }}
                                          onChange={(e) => {
                                            setZoneSelect(e)
                                          }}
                                          id='zonas'
                                          name='zonas'
                                          options={selectZonesData}
                                          noOptionsMessage={() => {
                                            return 'No se encontró zona'
                                          }}
                                        />
                                      </Col>
                                    </FormGroup>
                                    <div className='d-flex justify-content-end'>
                                      <Button
                                        size='sm'
                                        color='info'
                                        className='d-flex align-items-center gap-1'
                                        disabled={zoneSelect.length === 0}
                                        onClick={() => {
                                          setUser((prev) => {
                                            const roles = [
                                              ...prev.roles,
                                              selectOneRol.id
                                            ]
                                            const zones = zoneSelect.map(
                                              ({ value }) => parseId(value)
                                            )
                                            return {
                                              ...prev,
                                              roles,
                                              zones: [...prev.zones, ...zones]
                                            }
                                          })
                                          setOptionsData((prev) =>
                                            prev.map((obj) => {
                                              if (
                                                obj.value === selectOneRol.id
                                              ) {
                                                return {
                                                  ...obj,
                                                  isDisabled: true
                                                }
                                              }
                                              if (
                                                obj.label === ROLES.JEFE_ZONA
                                              ) {
                                                return {
                                                  ...obj,
                                                  isDisabled: true
                                                }
                                              }
                                              if (
                                                obj.label === ROLES.FACTURADOR
                                              ) {
                                                return {
                                                  ...obj,
                                                  isDisabled: true
                                                }
                                              }
                                              return obj
                                            })
                                          )
                                          setInfoRoles((prev) => {
                                            // console.log(
                                            //   'zoneSelect',
                                            //   zoneSelect
                                            // )
                                            const zonesIds = zoneSelect.map(
                                              ({ value }) => parseId(value)
                                            )
                                            return {
                                              ...prev,
                                              ANALISTA: {
                                                ...prev.ANALISTA,
                                                zones: [
                                                  ...prev.ANALISTA.zones,
                                                  ...zonesIds
                                                ]
                                              }
                                            }
                                          })
                                          setSelectOneRol(null)
                                          setKeySelect(Date.now().toString())
                                        }}
                                      >
                                        <i className='bx bx-list-plus' />
                                        Agregar rol
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              </Collapse>
                              <Collapse
                                isOpen={
                                  selectOneRol &&
                                  selectOneRol.name === ROLES.JEFE_CREDITO
                                }
                              >
                                <Row className='g-3 my-2'>
                                  <Col lg={12}>
                                    <Alert
                                      color='warning'
                                      className='d-flex align-items-center gap-2'
                                    >
                                      <i className='mdi mdi-alert-outline fs-2' />
                                      Un usuario con este rol podrá administrar
                                      los créditos de los usuarios
                                    </Alert>
                                    <div className='d-flex justify-content-end'>
                                      <Button
                                        size='sm'
                                        color='danger'
                                        className='d-flex align-items-center gap-1'
                                        // disabled={zoneSelect.length === 0}
                                        onClick={() => {
                                          setUser((prev) => {
                                            const roles = [
                                              ...prev.roles,
                                              selectOneRol.id
                                            ]
                                            return {
                                              ...prev,
                                              roles
                                            }
                                          })
                                          setOptionsData((prev) =>
                                            prev.map((arr) => {
                                              if (
                                                arr.value === selectOneRol.id
                                              ) {
                                                return {
                                                  ...arr,
                                                  isDisabled: true
                                                }
                                              }
                                              return arr
                                            })
                                          )
                                          setInfoRoles((prev) => {
                                            return {
                                              ...prev,
                                              JEFE_CREDITO: {
                                                ...prev.JEFE_CREDITO,
                                                select: true
                                              }
                                            }
                                          })
                                          setSelectOneRol(null)
                                          setKeySelect(Date.now().toString())
                                        }}
                                      >
                                        <i className='bx bx-list-plus' />
                                        Agregar rol
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              </Collapse>
                              <Collapse
                                isOpen={
                                  selectOneRol &&
                                  selectOneRol.name === ROLES.GERENTE
                                }
                              >
                                <Row className='g-3 my-2'>
                                  <Col lg={12}>
                                    <Alert
                                      color='warning'
                                      className='d-flex align-items-center gap-2'
                                    >
                                      <i className='mdi mdi-alert-outline fs-2' />
                                      Un usuario con este rol podrá administrar
                                      toda la organización
                                    </Alert>
                                    <div className='d-flex justify-content-end'>
                                      <Button
                                        size='sm'
                                        color='danger'
                                        className='d-flex align-items-center gap-1'
                                        // disabled={zoneSelect.length === 0}
                                        onClick={() => {
                                          setUser((prev) => {
                                            const roles = [
                                              ...prev.roles,
                                              selectOneRol.id
                                            ]
                                            return {
                                              ...prev,
                                              roles
                                            }
                                          })
                                          setOptionsData((prev) =>
                                            prev.map((arr) => {
                                              if (
                                                arr.value === selectOneRol.id
                                              ) {
                                                return {
                                                  ...arr,
                                                  isDisabled: true
                                                }
                                              }
                                              return arr
                                            })
                                          )
                                          setInfoRoles((prev) => {
                                            return {
                                              ...prev,
                                              GERENTE: {
                                                ...prev.GERENTE,
                                                select: true
                                              }
                                            }
                                          })
                                          setSelectOneRol(null)
                                          setKeySelect(Date.now().toString())
                                        }}
                                      >
                                        <i className='bx bx-list-plus' />
                                        Agregar rol
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              </Collapse>
                              <Collapse
                                isOpen={
                                  selectOneRol &&
                                  selectOneRol.name === ROLES.CARTERA
                                }
                              >
                                <Row className='g-3 my-2'>
                                  <Col lg={12}>
                                    <Alert
                                      color='warning'
                                      className='d-flex align-items-center gap-2'
                                    >
                                      <i className='mdi mdi-alert-outline fs-2' />
                                      Un usuario con este rol podrá gestionar
                                      cartera
                                    </Alert>
                                    <div className='d-flex justify-content-end'>
                                      <Button
                                        size='sm'
                                        color='danger'
                                        className='d-flex align-items-center gap-1'
                                        // disabled={zoneSelect.length === 0}
                                        onClick={() => {
                                          setUser((prev) => {
                                            const roles = [
                                              ...prev.roles,
                                              selectOneRol.id
                                            ]
                                            return {
                                              ...prev,
                                              roles
                                            }
                                          })
                                          setOptionsData((prev) =>
                                            prev.map((arr) => {
                                              if (
                                                arr.value === selectOneRol.id
                                              ) {
                                                return {
                                                  ...arr,
                                                  isDisabled: true
                                                }
                                              }
                                              return arr
                                            })
                                          )
                                          setInfoRoles((prev) => {
                                            return {
                                              ...prev,
                                              CARTERA: {
                                                ...prev.CARTERA,
                                                select: true
                                              }
                                            }
                                          })
                                          setSelectOneRol(null)
                                          setKeySelect(Date.now().toString())
                                        }}
                                      >
                                        <i className='bx bx-list-plus' />
                                        Agregar rol
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              </Collapse>
                              <Collapse
                                isOpen={
                                  selectOneRol &&
                                  selectOneRol.name === ROLES.CONTABILIDAD
                                }
                              >
                                <Row className='g-3 my-2'>
                                  <Col lg={12}>
                                    <Alert
                                      color='warning'
                                      className='d-flex align-items-center gap-2'
                                    >
                                      <i className='mdi mdi-alert-outline fs-2' />
                                      Un usuario con este rol podrá gestionar
                                      cartera
                                    </Alert>
                                    <div className='d-flex justify-content-end'>
                                      <Button
                                        size='sm'
                                        color='danger'
                                        className='d-flex align-items-center gap-1'
                                        // disabled={zoneSelect.length === 0}
                                        onClick={() => {
                                          setUser((prev) => {
                                            const roles = [
                                              ...prev.roles,
                                              selectOneRol.id
                                            ]
                                            return {
                                              ...prev,
                                              roles
                                            }
                                          })
                                          setOptionsData((prev) =>
                                            prev.map((arr) => {
                                              if (
                                                arr.value === selectOneRol.id
                                              ) {
                                                return {
                                                  ...arr,
                                                  isDisabled: true
                                                }
                                              }
                                              return arr
                                            })
                                          )
                                          setInfoRoles((prev) => {
                                            return {
                                              ...prev,
                                              CONTABILIDAD: {
                                                ...prev.CONTABILIDAD,
                                                select: true
                                              }
                                            }
                                          })
                                          setSelectOneRol(null)
                                          setKeySelect(Date.now().toString())
                                        }}
                                      >
                                        <i className='bx bx-list-plus' />
                                        Agregar rol
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              </Collapse>
                            </div>
                            <div className='d-flex align-items-start gap-3 mt-4'>
                              <button
                                type='button'
                                className='btn btn-light btn-label previestab'
                                onClick={() => {
                                  toggleVerticalTab(activeVerticalTab - 1)
                                }}
                              >
                                <i className='ri-arrow-left-line label-icon align-middle fs-16 me-2' />{' '}
                                Volver
                              </button>
                              <button
                                type='button'
                                className='btn btn-success btn-label right ms-auto nexttab d-flex align-items-center'
                                disabled={
                                  selectOneRol != null ||
                                  user.roles.length === 0 ||
                                  loadingCreate
                                }
                                onClick={() => {
                                  // console.log('user', user)
                                  createUser()
                                  // toggleVerticalTab(activeVerticalTab + 1)
                                }}
                              >
                                {loadingCreate ? (
                                  <Spinner size='sm' color='light' />
                                ) : (
                                  <>
                                    <i className='ri-arrow-right-line label-icon align-middle fs-16 ms-2' />
                                    Crear usuario
                                  </>
                                )}
                              </button>
                            </div>
                          </TabPane>

                          <TabPane tabId={9}>
                            <div className='text-center pt-4 pb-2'>
                              <div className='mb-4'>
                                <lord-icon
                                  src='https://cdn.lordicon.com/lupuorrc.json'
                                  trigger='loop'
                                  colors='primary:#0ab39c,secondary:#405189'
                                  style={{ width: '120px', height: '120px' }}
                                />
                              </div>
                              <h5>Felicidades</h5>
                              <p className='text-muted'>
                                Usuario creado con éxito
                              </p>
                              <div className='d-flex justify-content-between align-items-center'>
                                <Link
                                  to={`/usuario/${userIdCreate}`}
                                  className='d-flex align-items-center gap-1 btn btn-success'
                                >
                                  <i className='mdi mdi-account-eye' />
                                  Ver
                                </Link>
                                <Button
                                  className='d-flex align-items-center gap-1'
                                  onClick={() => {
                                    formik.resetForm()
                                    setUserIdCreate(null)
                                    setUser(INIT_VALUES_USER)
                                    setInfoRoles(INIT_INFO_ROLES)
                                    setactiveVerticalTab(7)
                                    setOptionsData((prev) => {
                                      const newArr = [...prev]
                                      return newArr.map(
                                        ({ value, label, color }) => ({
                                          value,
                                          label,
                                          color
                                        })
                                      )
                                    })
                                  }}
                                >
                                  <i className='ri-pencil-fill' />
                                  Crear otro usuario
                                </Button>
                              </div>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                    </Col>

                    <Col lg={3}>
                      <div className='d-flex justify-content-between align-items-center mb-3'>
                        <h5 className='fs-14 text-primary mb-0'>
                          <i className='ri-shield-user-line align-middle me-2' />{' '}
                          Roles
                        </h5>
                        <span className='badge bg-danger rounded-pill'>
                          {getTotRoles()}
                        </span>
                      </div>
                      {isVoidRoles() ? (
                        <div>No hay roles asignados</div>
                      ) : (
                        <ul className='list-group mb-3'>
                          {infoRoles.ADMIN.company && (
                            <li className='list-group-item d-flex justify-content-between lh-sm'>
                              <div>
                                <h6 className='my-0'>{infoRoles.ADMIN.name}</h6>
                                <small className='text-muted'>
                                  {infoRoles.ADMIN.company}
                                </small>
                              </div>
                              <span
                                className='text-muted trash_add_user'
                                title='Eliminar rol'
                                onClick={() => {
                                  setUser((prev) => {
                                    let roles = [...prev.roles]
                                    const find = optionsData.find(
                                      (el) => el.label === ROLES.ADMIN
                                    )
                                    if (find) {
                                      roles = roles.filter(
                                        (num) => num !== find.value
                                      )
                                    }
                                    return {
                                      ...prev,
                                      commercialCompany: '',
                                      roles
                                    }
                                  })
                                  setOptionsData((prev) => {
                                    const newArr = [...prev]
                                    const findIdx = newArr.findIndex(
                                      (el) => el.label === ROLES.ADMIN
                                    )
                                    if (findIdx >= 0) {
                                      delete newArr[findIdx].isDisabled
                                    }
                                    return newArr
                                  })
                                  setInfoRoles((prev) => {
                                    const newObj = { ...prev }
                                    newObj.ADMIN.company = ''
                                    return newObj
                                  })
                                }}
                              >
                                <i className='bx bxs-trash' />
                              </span>
                            </li>
                          )}
                          {infoRoles.ASESOR.position.length > 0 && (
                            <li className='list-group-item d-flex justify-content-between lh-sm'>
                              <div>
                                <h6 className='my-0'>
                                  {infoRoles.ASESOR.name}
                                </h6>
                                {renderInfoAsesor()}
                              </div>
                              <span
                                className='text-muted trash_add_user'
                                title='Eliminar rol'
                                onClick={() => {
                                  setUser((prev) => {
                                    let roles = [...prev.roles]
                                    const find = optionsData.find(
                                      (el) => el.label === ROLES.ASESOR
                                    )
                                    if (find) {
                                      roles = roles.filter(
                                        (num) => num !== find.value
                                      )
                                    }
                                    return {
                                      ...prev,
                                      roles,
                                      agencies: []
                                    }
                                  })
                                  setOptionsData((prev) => {
                                    const newArr = [...prev]
                                    const findIdx = newArr.findIndex(
                                      (el) => el.label === ROLES.ASESOR
                                    )
                                    if (findIdx >= 0) {
                                      delete newArr[findIdx].isDisabled
                                    }
                                    return newArr
                                  })
                                  setInfoRoles((prev) => {
                                    const newObj = { ...prev }
                                    newObj.ASESOR.position = []
                                    return newObj
                                  })
                                  setSelectAgencies([])
                                }}
                              >
                                <i className='bx bxs-trash' />
                              </span>
                            </li>
                          )}
                          {infoRoles.JEFE_ZONA.zones.length > 0 && (
                            <li className='list-group-item d-flex justify-content-between lh-sm'>
                              <div>
                                <h6 className='my-0'>
                                  {infoRoles.JEFE_ZONA.name}
                                </h6>
                                <div>{renderZoneNames()}</div>
                              </div>
                              <span
                                className='text-muted trash_add_user'
                                title='Eliminar rol'
                                onClick={() => {
                                  setUser((prev) => {
                                    let roles = [...prev.roles]
                                    const find = optionsData.find(
                                      (el) => el.label === ROLES.JEFE_ZONA
                                    )
                                    if (find) {
                                      roles = roles.filter(
                                        (num) => num !== find.value
                                      )
                                    }
                                    return {
                                      ...prev,
                                      roles,
                                      zones: []
                                    }
                                  })
                                  setOptionsData((prev) => {
                                    const newArr = [...prev]
                                    const findIdx = newArr.findIndex(
                                      (el) => el.label === ROLES.JEFE_ZONA
                                    )
                                    if (findIdx >= 0) {
                                      delete newArr[findIdx].isDisabled
                                    }
                                    const findIdxFact = newArr.findIndex(
                                      (el) => el.label === ROLES.FACTURADOR
                                    )
                                    if (findIdxFact >= 0) {
                                      delete newArr[findIdxFact].isDisabled
                                    }
                                    return newArr
                                  })
                                  setInfoRoles((prev) => {
                                    const newObj = { ...prev }
                                    newObj.JEFE_ZONA.zones = []
                                    return newObj
                                  })
                                  setZoneSelect([])
                                }}
                              >
                                <i className='bx bxs-trash' />
                              </span>
                            </li>
                          )}
                          {infoRoles.FACTURADOR.zones.length > 0 && (
                            <li className='list-group-item d-flex justify-content-between lh-sm'>
                              <div>
                                <h6 className='my-0'>
                                  {infoRoles.FACTURADOR.name}
                                </h6>
                                <div>{renderZoneNames()}</div>
                              </div>
                              <span
                                className='text-muted trash_add_user'
                                title='Eliminar rol'
                                onClick={() => {
                                  setUser((prev) => {
                                    let roles = [...prev.roles]
                                    const find = optionsData.find(
                                      (el) => el.label === ROLES.FACTURADOR
                                    )
                                    if (find) {
                                      roles = roles.filter(
                                        (num) => num !== find.value
                                      )
                                    }
                                    return {
                                      ...prev,
                                      roles,
                                      zones: []
                                    }
                                  })
                                  setOptionsData((prev) => {
                                    const newArr = [...prev]
                                    const findIdx = newArr.findIndex(
                                      (el) => el.label === ROLES.JEFE_ZONA
                                    )
                                    if (findIdx >= 0) {
                                      delete newArr[findIdx].isDisabled
                                    }
                                    const findIdxFact = newArr.findIndex(
                                      (el) => el.label === ROLES.FACTURADOR
                                    )
                                    if (findIdxFact >= 0) {
                                      delete newArr[findIdxFact].isDisabled
                                    }
                                    return newArr
                                  })
                                  setInfoRoles((prev) => {
                                    const newObj = { ...prev }
                                    newObj.FACTURADOR.zones = []
                                    return newObj
                                  })
                                  setZoneSelect([])
                                }}
                              >
                                <i className='bx bxs-trash' />
                              </span>
                            </li>
                          )}
                          {infoRoles.ANALISTA.zones.length > 0 && (
                            <li className='list-group-item d-flex justify-content-between lh-sm'>
                              <div>
                                <h6 className='my-0'>
                                  {infoRoles.ANALISTA.name}
                                </h6>
                                <div>{renderZoneNames()}</div>
                              </div>
                              <span
                                className='text-muted trash_add_user'
                                title='Eliminar rol'
                                onClick={() => {
                                  setUser((prev) => {
                                    let roles = [...prev.roles]
                                    const find = optionsData.find(
                                      (el) => el.label === ROLES.ANALISTA
                                    )
                                    if (find) {
                                      roles = roles.filter(
                                        (num) => num !== find.value
                                      )
                                    }
                                    return {
                                      ...prev,
                                      roles,
                                      zones: []
                                    }
                                  })
                                  setOptionsData((prev) => {
                                    const newArr = [...prev]
                                    const findIdx = newArr.findIndex(
                                      (el) => el.label === ROLES.JEFE_ZONA
                                    )
                                    if (findIdx >= 0) {
                                      delete newArr[findIdx].isDisabled
                                    }
                                    const findIdxFact = newArr.findIndex(
                                      (el) => el.label === ROLES.FACTURADOR
                                    )
                                    if (findIdxFact >= 0) {
                                      delete newArr[findIdxFact].isDisabled
                                    }
                                    const findIdxAnalyst = newArr.findIndex(
                                      (el) => el.label === ROLES.ANALISTA
                                    )
                                    if (findIdxAnalyst >= 0) {
                                      delete newArr[findIdxAnalyst].isDisabled
                                    }
                                    return newArr
                                  })
                                  setInfoRoles((prev) => {
                                    const newObj = { ...prev }
                                    newObj.ANALISTA.zones = []
                                    return newObj
                                  })
                                  setZoneSelect([])
                                }}
                              >
                                <i className='bx bxs-trash' />
                              </span>
                            </li>
                          )}

                          {infoRoles.JEFE_CREDITO.select && (
                            <li className='list-group-item d-flex justify-content-between lh-sm'>
                              <div>
                                <h6 className='my-0'>
                                  {infoRoles.JEFE_CREDITO.name}
                                </h6>
                                <small className='text-muted'>
                                  Administrar créditos
                                </small>
                              </div>
                              <span
                                className='text-muted trash_add_user'
                                title='Eliminar rol'
                                onClick={() => {
                                  setUser((prev) => {
                                    let roles = [...prev.roles]
                                    const find = optionsData.find(
                                      (el) => el.label === ROLES.JEFE_CREDITO
                                    )
                                    if (find) {
                                      roles = roles.filter(
                                        (num) => num !== find.value
                                      )
                                    }
                                    return {
                                      ...prev,
                                      roles
                                    }
                                  })
                                  setOptionsData((prev) => {
                                    const newArr = [...prev]
                                    const findIdx = newArr.findIndex(
                                      (el) => el.label === ROLES.JEFE_CREDITO
                                    )
                                    if (findIdx >= 0) {
                                      delete newArr[findIdx].isDisabled
                                    }
                                    return newArr
                                  })
                                  setInfoRoles((prev) => {
                                    const newObj = { ...prev }
                                    newObj.JEFE_CREDITO.select = false
                                    return newObj
                                  })
                                }}
                              >
                                <i className='bx bxs-trash' />
                              </span>
                            </li>
                          )}
                          {infoRoles.GERENTE.select && (
                            <li className='list-group-item d-flex justify-content-between lh-sm'>
                              <div>
                                <h6 className='my-0'>
                                  {infoRoles.GERENTE.name}
                                </h6>
                                <small className='text-muted'>
                                  Administra organización
                                </small>
                              </div>
                              <span
                                className='text-muted trash_add_user'
                                title='Eliminar rol'
                                onClick={() => {
                                  setUser((prev) => {
                                    let roles = [...prev.roles]
                                    const find = optionsData.find(
                                      (el) => el.label === ROLES.GERENTE
                                    )
                                    if (find) {
                                      roles = roles.filter(
                                        (num) => num !== find.value
                                      )
                                    }
                                    return {
                                      ...prev,
                                      roles
                                    }
                                  })
                                  setOptionsData((prev) => {
                                    const newArr = [...prev]
                                    const findIdx = newArr.findIndex(
                                      (el) => el.label === ROLES.GERENTE
                                    )
                                    if (findIdx >= 0) {
                                      delete newArr[findIdx].isDisabled
                                    }
                                    return newArr
                                  })
                                  setInfoRoles((prev) => {
                                    const newObj = { ...prev }
                                    newObj.GERENTE.select = false
                                    return newObj
                                  })
                                }}
                              >
                                <i className='bx bxs-trash' />
                              </span>
                            </li>
                          )}
                          {infoRoles.CARTERA.select && (
                            <li className='list-group-item d-flex justify-content-between lh-sm'>
                              <div>
                                <h6 className='my-0'>
                                  {infoRoles.CARTERA.name}
                                </h6>
                                <small className='text-muted'>
                                  Gestiona cartera
                                </small>
                              </div>
                              <span
                                className='text-muted trash_add_user'
                                title='Eliminar rol'
                                onClick={() => {
                                  setUser((prev) => {
                                    let roles = [...prev.roles]
                                    const find = optionsData.find(
                                      (el) => el.label === ROLES.CARTERA
                                    )
                                    if (find) {
                                      roles = roles.filter(
                                        (num) => num !== find.value
                                      )
                                    }
                                    return {
                                      ...prev,
                                      roles
                                    }
                                  })
                                  setOptionsData((prev) => {
                                    const newArr = [...prev]
                                    const findIdx = newArr.findIndex(
                                      (el) => el.label === ROLES.CARTERA
                                    )
                                    if (findIdx >= 0) {
                                      delete newArr[findIdx].isDisabled
                                    }
                                    return newArr
                                  })
                                  setInfoRoles((prev) => {
                                    const newObj = { ...prev }
                                    newObj.CARTERA.select = false
                                    return newObj
                                  })
                                }}
                              >
                                <i className='bx bxs-trash' />
                              </span>
                            </li>
                          )}
                          {infoRoles.CONTABILIDAD.select && (
                            <li className='list-group-item d-flex justify-content-between lh-sm'>
                              <div>
                                <h6 className='my-0'>
                                  {infoRoles.CONTABILIDAD.name}
                                </h6>
                                <small className='text-muted'>
                                  Gestiona cartera
                                </small>
                              </div>
                              <span
                                className='text-muted trash_add_user'
                                title='Eliminar rol'
                                onClick={() => {
                                  setUser((prev) => {
                                    let roles = [...prev.roles]
                                    const find = optionsData.find(
                                      (el) => el.label === ROLES.CONTABILIDAD
                                    )
                                    if (find) {
                                      roles = roles.filter(
                                        (num) => num !== find.value
                                      )
                                    }
                                    return {
                                      ...prev,
                                      roles
                                    }
                                  })
                                  setOptionsData((prev) => {
                                    const newArr = [...prev]
                                    const findIdx = newArr.findIndex(
                                      (el) => el.label === ROLES.CONTABILIDAD
                                    )
                                    if (findIdx >= 0) {
                                      delete newArr[findIdx].isDisabled
                                    }
                                    return newArr
                                  })
                                  setInfoRoles((prev) => {
                                    const newObj = { ...prev }
                                    newObj.CONTABILIDAD.select = false
                                    return newObj
                                  })
                                }}
                              >
                                <i className='bx bxs-trash' />
                              </span>
                            </li>
                          )}
                        </ul>
                      )}
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

/*
<Card>
                <CardHeader className='d-flex align-items-center justify-content-between border-0 gap-3 flex-md-column flex-lg-row flex-sm-column '>
                  <h5 className='card-title mb-0 d-block'>Agregar usuario</h5>
                </CardHeader>
                <CardBody>
                  <Row className='g-3'>
                    <Col lg={6}>
                      <div>
                        <Label htmlFor='firstName' className='form-label'>
                          Primer Nombre
                        </Label>

                        <Input
                          name='firstName'
                          id='firstName'
                          className='form-control'
                          placeholder='Primer nombre'
                          type='text'
                          validate={{
                            required: { value: true }
                          }}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstName}
                          invalid={Boolean(
                            formik.touched.firstName && formik.errors.firstName
                          )}
                        />
                        {formik.touched.firstName &&
                          formik.errors.firstName && (
                            <FormFeedback type='invalid'>
                              {formik.errors.firstName}
                            </FormFeedback>
                          )}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div>
                        <Label htmlFor='lastName' className='form-label'>
                          Apellido
                        </Label>
                        <Input
                          name='lastName'
                          id='lastName'
                          className='form-control'
                          placeholder='Apellido'
                          type='text'
                          validate={{
                            required: { value: true }
                          }}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastName}
                          invalid={
                            !!(
                              formik.touched.lastName && formik.errors.lastName
                            )
                          }
                        />
                        {formik.touched.lastName && formik.errors.lastName && (
                          <FormFeedback type='invalid'>
                            {formik.errors.lastName}
                          </FormFeedback>
                        )}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div>
                        <Label htmlFor='phone-field' className='form-label'>
                          Teléfono
                        </Label>
                        <Input
                          name='phoneNumber'
                          id='phone-field'
                          className='form-control'
                          placeholder='Ingrese teléfono'
                          type='tel'
                          validate={{
                            required: { value: true }
                          }}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phoneNumber}
                          invalid={
                            !!(
                              formik.touched.phoneNumber &&
                              formik.errors.phoneNumber
                            )
                          }
                        />
                        {formik.touched.phoneNumber &&
                        formik.errors.phoneNumber ? (
                          <FormFeedback type='invalid'>
                            {formik.errors.phoneNumber}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div>
                        <Label htmlFor='email' className='form-label'>
                          Correo electrónico
                        </Label>
                        <Input
                          name='email'
                          id='email'
                          className='form-control'
                          placeholder='Correo electrónico'
                          type='text'
                          validate={{
                            required: { value: true }
                          }}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          invalid={Boolean(
                            formik.touched.email && formik.errors.email
                          )}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <FormFeedback type='invalid'>
                            {formik.errors.email}
                          </FormFeedback>
                        )}
                      </div>
                    </Col>

                    <Col lg={6}>
                      <div>
                        <Label htmlFor='password' className='form-label'>
                          Contraseña
                        </Label>
                        <Input
                          name='password'
                          id='password'
                          className='form-control'
                          placeholder='Contraseña'
                          type='text'
                          validate={{
                            required: { value: true }
                          }}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                          invalid={Boolean(
                            formik.touched.password && formik.errors.password
                          )}
                        />
                        {formik.touched.password && formik.errors.password && (
                          <FormFeedback type='invalid'>
                            {formik.errors.password}
                          </FormFeedback>
                        )}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div>
                        <Label htmlFor='passwordConfirm' className='form-label'>
                          Confirme contraseña
                        </Label>
                        <Input
                          name='passwordConfirm'
                          id='passwordConfirm'
                          className='form-control'
                          placeholder='Confirme contraseña'
                          type='text'
                          validate={{
                            required: { value: true }
                          }}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.passwordConfirm}
                          invalid={Boolean(
                            formik.touched.passwordConfirm &&
                              formik.errors.passwordConfirm
                          )}
                          onPaste={(e) => e.preventDefault()}
                        />
                        {formik.touched.passwordConfirm &&
                          formik.errors.passwordConfirm && (
                            <FormFeedback type='invalid'>
                              {formik.errors.passwordConfirm}
                            </FormFeedback>
                          )}
                      </div>
                    </Col>

                    <Col lg={12}>
                      <Row>
                        <Col lg={12}>
                          <FormGroup>
                            <Label>Seleccione Rol</Label>
                            <Select
                              className='basic-single'
                              classNamePrefix='select'
                              placeholder='Seleccione un producto'
                              // defaultValue={0}
                              isClearable
                              isSearchable
                              onChange={(e) => {
                                if (e) {
                                  setSelectOneRol({
                                    id: e.value,
                                    name: e.label
                                  })
                                } else {
                                  setSelectOneRol(null)
                                }
                              }}
                              id='products'
                              name='product'
                              options={optionsData}
                              noOptionsMessage={() => 'No se encontró rol'}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={12}>
                          <Fade in={Boolean(selectOneRol)}>
                            <Row>
                              {selectOneRol?.name === ROLES.ADMIN && (
                                <Col lg={12}>
                                  <h6>Seleccione empresa comercial</h6>
                                  <Select
                                    className='basic-single'
                                    classNamePrefix='select'
                                    placeholder='Seleccione empresa comercial'
                                    // defaultValue={0}
                                    isClearable
                                    isSearchable
                                    onChange={(e) => {
                                      if (e) {
                                        setSelectOneCC({
                                          id: e.value,
                                          name: e.label
                                        })
                                      } else {
                                        setSelectOneCC(null)
                                      }
                                    }}
                                    id='products'
                                    name='product'
                                    options={selectOptionsCC}
                                    noOptionsMessage={() => {
                                      return 'No se encontró rol'
                                    }}
                                  />
                                </Col>
                              )}

                              <Col log={12}>
                                <Button
                                  onClick={() => {
                                    if (selectOneRol) {
                                      setSeletedRoles((prev) => {
                                        const newArr = [...prev]
                                        const isAlready = newArr.some(
                                          (rol) => rol.id === selectOneRol.id
                                        )
                                        if (isAlready) return newArr
                                        return [...newArr, selectOneRol]
                                      })
                                    }
                                  }}
                                >
                                  Add
                                </Button>
                              </Col>
                            </Row>
                          </Fade>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <div className='hstack gap-2 justify-content-end'>
                    <Button
                      type='submit'
                      color='success'
                      id='add-btn'
                      // disabled={loadingCreate}
                    >
                      Agregar usuario
                    </Button>
                  </div>
                </CardFooter>
              </Card>
*/
