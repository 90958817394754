/* eslint-disable react/jsx-indent */
import React, { useState } from 'react'
import {
  Alert,
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
  Table,
  NavLink
} from 'reactstrap'
import profileBg from '../../../assets/images/bg-one-user.png'
import { CREDIT_TYPES } from '../../../helpers/consts'
import { Link } from 'react-router-dom'
import { parseId } from '../../../helpers'
import moment from 'moment'
import classnames from 'classnames'

import { StatusOneCredit } from '../StatusOneCredit'
import { TabInactiveOblg } from './TabInactiveOblg'
import { TabComments } from './TabComments'

export const NoInfoObligations = ({ credit, refetchDataCredit }) => {
  const [activeTab, setActiveTab] = useState('1')

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  const noActiveObligations = credit?.obligationSet?.edges || []
  // console.log('noActiveObligations', noActiveObligations)
  const comments = credit.creditcommentwalletSet.edges
  console.log('comments', comments)
  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <div className='profile-foreground position-relative mx-n4 mt-n4'>
            <div className='profile-wid-bg' style={{ height: '490px' }}>
              <img
                src={profileBg}
                alt=''
                className='profile-wid-img bg-img-h'
              />
            </div>
          </div>
          <div className='pt-4 mb-4 mb-lg-3 pb-lg-4'>
            <Row className='g-4'>
              <div className='col-auto'>
                <div className='avatar-lg'>
                  <img
                    src={
                      credit.agency.zone.commercialCompany.image ||
                      '/TotalSales-Positivo.png'
                    }
                    alt='user-img'
                    className='img-thumbnail rounded-circle'
                    style={{ height: '92px' }}
                  />
                </div>
              </div>

              <Col>
                <div className='p-2'>
                  <h3 className='text-white mb-1 d-flex align-items-center gap-2 flex-wrap'>
                    Crédito en cartera
                    <Badge
                      className='fs-4'
                      color={
                        credit.creditType === CREDIT_TYPES.INMEDIATO
                          ? 'dark'
                          : credit.creditType === CREDIT_TYPES.TRADICIONAL
                          ? 'info'
                          : 'primary'
                      }
                    >
                      {credit.creditType}
                    </Badge>
                  </h3>
                  {/* <div className='d-flex align-items-center gap-1'>
                    <p className='text-white-75 m-0'>{credit.uuid}</p>
                    <button
                      className='border-0 p-0 bg-transparent m-0 text-white'
                      title={isCopy ? 'Copiado' : 'Copiar'}
                      onClick={() => {
                        window.navigator.clipboard.writeText(credit.uuid)
                        setIsCopy(true)
                        setTimeout(() => {
                          setIsCopy(false)
                        }, 2000)
                      }}
                    >
                      <i
                        className={`mdi ${
                          isCopy
                            ? 'mdi-text-box-check-outline'
                            : 'mdi-text-box-multiple-outline'
                        }`}
                        style={{
                          color: isCopy ? '#3fff3f' : undefined,
                          fontSize: '1em'
                        }}
                      />
                    </button>
                  </div> */}
                  <div className='hstack text-white-50 gap-2'>
                    <div className='me-2'>
                      <i className='ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle' />
                      {credit.agency.name}
                    </div>
                    <div>
                      <i className='ri-building-line me-1 text-white-75 fs-16 align-middle' />
                      {credit.agency.zone.commercialCompany.name}
                    </div>

                    <div className='ms-2'>
                      <Link
                        to={`/credito/${parseId(credit.id)}`}
                        className='d-flex align-items-center text-reset text-decoration-underline'
                        style={{
                          color: 'white',
                          textDecoration: 'underline !important'
                        }}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <i className='mdi mdi-eye-outline me-1 text-white-75 fs-16 align-middle' />
                        Ver crédito
                      </Link>
                    </div>
                  </div>
                  {credit.closedDate && (
                    <div className='d-flex mt-2'>
                      <Alert className='flex-0' color='warning'>
                        Cerrado el{' '}
                        <b>
                          {moment(credit.closedDate).format(
                            'DD MMM [del] YY - hh:mm A'
                          )}
                        </b>
                      </Alert>
                    </div>
                  )}
                </div>
              </Col>

              <Col xs={12} className='col-lg-auto order-last'>
                <Row className='text text-white-50 text-center me-1'>
                  <Col lg={3} sm={3} xs={6}>
                    <div className='p-2'>
                      {credit.creditType === CREDIT_TYPES.TRADICIONAL ? (
                        <>
                          <h4 className='text-white mb-1'>N/A</h4>
                          <p className='fs-14 mb-0'>Inicial</p>
                        </>
                      ) : (
                        <>
                          <h4 className='text-white mb-1'>N/A</h4>
                          <p className='fs-14 mb-0'>Cupo</p>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col lg={3} sm={3} xs={6}>
                    <div className='p-2'>
                      {credit.creditType === CREDIT_TYPES.TRADICIONAL ? (
                        <h4 className='text-white mb-1'>N/A</h4>
                      ) : (
                        <h4 className='text-white mb-1'>N/A</h4>
                      )}
                      <p className='fs-14 mb-0'>Plazo</p>
                    </div>
                  </Col>
                  <Col lg={3} sm={3} xs={6}>
                    <div className='p-2'>
                      {credit.creditType === CREDIT_TYPES.TRADICIONAL ? (
                        <h4 className='text-white mb-1'>N/A</h4>
                      ) : (
                        <h4 className='text-white mb-1'>N/A</h4>
                      )}
                      <p className='fs-14 mb-0'>Cuota</p>
                    </div>
                  </Col>
                  <Col lg={3} sm={3} xs={6}>
                    <div className='p-2'>
                      {/* {credit.creditType === CREDIT_TYPES.TRADICIONAL ? (
                        <h4 className='text-white mb-1'>
                          {credit.productValue
                            ? parsePrice(credit.productValue)
                            : 'N/A'}
                        </h4>
                      ) : ( */}
                      <h4 className='text-white mb-1'>N/A</h4>
                      {/* )} */}
                      <p className='fs-14 mb-0'>Valor Producto</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Row>
            <Col lg={12}>
              <div>
                <div className='d-flex'>
                  <Nav
                    pills
                    className='animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1'
                    role='tablist'
                  >
                    <NavItem>
                      <NavLink
                        href='#overview-tab'
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => {
                          toggleTab('1')
                        }}
                      >
                        <i className='ri-airplay-fill d-inline-block d-md-none' />{' '}
                        <span className='d-none d-md-inline-block'>
                          Resumen
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href='#activities'
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => {
                          toggleTab('3')
                        }}
                      >
                        <i className='ri-list-unordered d-inline-block d-md-none' />{' '}
                        <span className='d-none d-md-inline-block'>
                          Comentarios
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href='#activities'
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => {
                          toggleTab('2')
                        }}
                      >
                        <i className='mdi mdi-alert-circle-outline d-inline-block' />{' '}
                        <span className='d-none d-md-inline-block'>
                          Obligaciones
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>

                <TabContent activeTab={activeTab} className='pt-4'>
                  {activeTab === '1' && (
                    <TabPane tabId='1'>
                      <Row>
                        <Col lg={3} md={12}>
                          <Card>
                            <CardBody className='d-flex justify-content-center'>
                              {/* <h5 className='card-title mb-0'>Estado</h5> */}
                              <StatusOneCredit status={credit.status} />
                            </CardBody>
                          </Card>

                          <Card>
                            <CardBody>
                              <h5 className='card-title mb-3'>
                                Información cliente
                              </h5>
                              <div className='table-responsive'>
                                <Table className='table-borderless mb-0'>
                                  <tbody>
                                    <tr>
                                      <th className='ps-0' scope='row'>
                                        Nombre(s):
                                      </th>
                                      <td className='text-muted'>
                                        {credit.firstName} {credit.secondName}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className='ps-0' scope='row'>
                                        Apellido(s):
                                      </th>
                                      <td className='text-muted'>
                                        {credit.firstSurname}{' '}
                                        {credit.secondSurname}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className='ps-0' scope='row'>
                                        Documento:
                                      </th>
                                      <td className='text-muted'>
                                        {credit.documentType.toLowerCase()}{' '}
                                        {credit.documentNumber}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className='ps-0' scope='row'>
                                        Teléfono:
                                      </th>
                                      <td className='text-muted'>
                                        {credit.phoneNumber}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className='ps-0' scope='row'>
                                        E-mail:
                                      </th>
                                      <td className='text-muted'>
                                        {credit.email}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className='ps-0' scope='row'>
                                        Fecha de nacimiento:
                                      </th>
                                      <td className='text-muted'>
                                        {moment(credit.dateBirth).format(
                                          'DD MMMM [de] YYYY'
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className='ps-0' scope='row'>
                                        Fecha de expedición:
                                      </th>
                                      <td className='text-muted'>
                                        {moment(credit.expeditionDate).format(
                                          'DD MMMM [de] YYYY'
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className='ps-0' scope='row'>
                                        Autorización
                                      </th>
                                      <td>
                                        {credit.firmaAutorizacion ? (
                                          <Badge color='success'>Firmada</Badge>
                                        ) : (
                                          <Badge color='danger'>
                                            No firmado
                                          </Badge>
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </CardBody>
                          </Card>
                          <Card>
                            <CardBody>
                              <h5 className='card-title mb-3'>
                                Información adicional
                              </h5>
                              <div className='table-responsive'>
                                <Table className='table-borderless mb-0'>
                                  <tbody>
                                    <tr>
                                      <th className='ps-0' scope='row'>
                                        Asesor:
                                      </th>
                                      <td className='text-muted'>
                                        {credit.user ? (
                                          <>
                                            {credit.user.firstName}{' '}
                                            {credit.user.lastName}
                                          </>
                                        ) : (
                                          <span
                                            className='d-flex align-items-center gap-1'
                                            style={{ color: '#ff8787' }}
                                          >
                                            <i className='mdi mdi-account-cancel' />{' '}
                                            No aplica
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className='ps-0' scope='row'>
                                        Analista:
                                      </th>
                                      <td className='text-muted'>
                                        {credit.analyst ? (
                                          <>
                                            {credit.analyst.firstName}{' '}
                                            {credit.analyst.lastName}
                                          </>
                                        ) : (
                                          <span
                                            className='d-flex align-items-center gap-1'
                                            style={{ color: '#ff8787' }}
                                          >
                                            <i className='mdi mdi-account-cancel' />{' '}
                                            No aplica
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg={9} md={12}>
                          <Card>
                            <CardBody
                              className='d-flex align-items-center justify-content-center'
                              style={{ height: '30vh' }}
                            >
                              No hay obligaciones financieras para este crédito
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                  )}
                  {activeTab === '2' && (
                    <TabPane tabId='2' className='p-4'>
                      {noActiveObligations.length > 0 ? (
                        <TabInactiveOblg obligations={noActiveObligations} />
                      ) : (
                        <Card>
                          <CardBody
                            className='d-flex align-items-center justify-content-center'
                            style={{ height: '38vh' }}
                          >
                            No hay obligaciones para este crédito 🤷‍♂️
                          </CardBody>
                        </Card>
                      )}
                    </TabPane>
                  )}
                  <TabComments
                    tabId='3'
                    comments={comments}
                    creditId={parseId(credit.id)}
                    refetchDataCredit={refetchDataCredit}
                  />
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
