import { gql } from '@apollo/client'

export const CHANGE_MONTHLY_FEE = gql`
  mutation settingUpdate($input: SettingInput!) {
    settingUpdate(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`
