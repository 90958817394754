import { gql } from '@apollo/client'

export const CREATE_USER = gql`
  mutation userCreateUpdate($user: UserCreateUpdateInput!) {
    userCreateUpdate(user: $user) {
      errors {
        field
        message
      }
      success
      user {
        id
      }
    }
  }
`

export const CHANGE_ROL = gql`
  mutation switchRol($id: Int!) {
    switchRole(id: $id) {
      errors {
        field
        message
      }
      success
      permissions
      agenciesZones {
        entity
        data {
          id
          name
        }
      }
    }
  }
`

export const CHANGE_AGENCIE_OR_ZONE = gql`
  mutation switchAgencyZone($input: SwitchAgencyZoneInput!) {
    switchAgencyZone(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`
export const CHANGE_PASSWORD_FROM_ADMIN = gql`
  mutation passwordChange($input: PasswordChangeAuxInput!) {
    passwordChangeAux(input: $input) {
      errors {
        field
        message
      }
      success
    }
  }
`
export const CHANGE_OWN_PASSWORD = gql`
  mutation passwordChange($input: PasswordChangeInput!) {
    passwordChange(input: $input) {
      errors {
        field
        message
      }
      success
    }
  }
`
export const CALCULATOR = gql`
  mutation calculator($input: CalculatorInput!) {
    calculator(input: $input) {
      success
      errors {
        field
        message
      }
      quota
    }
  }
`

export const CREATE_BUDGET = gql`
  mutation budgetCreateUpdate($input: BudgetCreateUpdateInput!) {
    budgetCreateUpdate(input: $input) {
      errors {
        field
        message
      }
      success
      budget {
        id
      }
    }
  }
`
