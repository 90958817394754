import React, { useEffect, useState } from 'react'
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  /* Nav,
  NavItem,
  TabContent,
  TabPane
  NavLink, */
  Row,
  Spinner,
  UncontrolledDropdown
} from 'reactstrap'
import classnames from 'classnames'

// import images
/* import avatar3 from '../../assets/images/users/avatar-3.jpg'
import { Link } from 'react-router-dom'
import avatar2 from '../../assets/images/users/avatar-2.jpg'
import avatar8 from '../../assets/images/users/avatar-8.jpg'
import avatar6 from '../../assets/images/users/avatar-6.jpg'
*/
import bell from '../../assets/images/svg/bell.svg'

// SimpleBar
import SimpleBar from 'simplebar-react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  GET_ALL_NOTIFICATIONS,
  GET_TOTAL_COUNT_NOTIFICATIONS
} from '../../graphql/queries/getNotifications'
import moment from 'moment'
import {
  MARK_ALL_NOTIFIACTIONS,
  MARK_NOTIFICATION
} from '../../graphql/mutations/notificationsMut'
import { parseId } from '../../helpers'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useGetUser } from '../../hooks/user'

const NAME_TABS = {
  GET_NOT_READED: '',
  GET_ALL: 'GET ALL'
}

const NotificationDropdown = () => {
  const {
    data: dataTotCount /* ,
    loading: loadingTotCount,
    error: errorTotCount */
  } = useQuery(GET_TOTAL_COUNT_NOTIFICATIONS, {
    fetchPolicy: 'no-cache'
  })
  const [gqlGetNotifi, { data, loading }] = useLazyQuery(
    GET_ALL_NOTIFICATIONS,
    {
      fetchPolicy: 'no-cache'
    }
  )
  const [
    gqlMarkNotification,
    {
      data: dataNotification,
      loading: loadingNotification,
      error: errorNotification
    }
  ] = useMutation(MARK_NOTIFICATION)
  const [
    gqlMarkAllNotification,
    {
      data: dataALLNotification,
      loading: loadingALLNotification,
      error: errorALLNotification
    }
  ] = useMutation(MARK_ALL_NOTIFIACTIONS)

  const {
    notifications,
    loadAllNotifications,
    updateOneNotificationReaded,
    totalNotifications,
    setTotalNotifications,
    incDecTotalNotifications
  } = useGetUser()

  const navigate = useNavigate()
  // Dropdown Toggle
  const [isNotificationDropdown, setIsNotificationDropdown] = useState(false)
  const [urlNavigate, setUrlNavigate] = useState('/')
  const [offset, setOffset] = useState(0)
  const [updateNotiId, setUpdateNotiId] = useState(0)
  const [isRead, setIsRead] = useState(NAME_TABS.GET_ALL)

  useEffect(() => {
    gqlGetNotifi({
      variables: {
        offset,
        isRead: isRead === NAME_TABS.GET_ALL ? undefined : Boolean(isRead)
      }
    })
  }, [offset, isRead])

  useEffect(() => {
    if (!dataNotification) return
    if (dataNotification.markReadNotification.success) {
      incDecTotalNotifications('subtract')
      setIsNotificationDropdown(false)
      navigate(urlNavigate)
      updateOneNotificationReaded(updateNotiId)
    }
  }, [dataNotification])
  useEffect(() => {
    if (errorNotification) {
      toast.error('Error: No se pudo ver notificación')
    }
  }, [errorNotification])
  useEffect(() => {
    if (errorALLNotification) {
      toast.error('Error: ' + errorALLNotification.message)
    }
  }, [errorALLNotification])
  useEffect(() => {
    if (!data) return
    /** @type {{id: string, extraData: string, isRead: boolean, createDate: string}[]} */

    const parseNotifications = data.listNotifications.edges.map(({ node }) => {
      const parse = JSON.parse(node.extraData)
      return {
        id: parseId(node.id),
        msg: parse.context.message.message,
        title: parse.context.message.title,
        url: parse.context.message.url,
        isReaded: node.isRead,
        createDate: node.createDate
      }
    })
    if (offset > 0) {
      const notiArr = [...notifications, ...parseNotifications]
      return loadAllNotifications(notiArr)
    }

    loadAllNotifications(parseNotifications)
  }, [data])

  useEffect(() => {
    if (!dataTotCount) return
    if (dataTotCount.countNotifications) {
      setTotalNotifications(dataTotCount.countNotifications)
    }
  }, [dataTotCount])

  useEffect(() => {
    if (!dataALLNotification) return
    if (dataALLNotification.markReadAllNotification.success) {
      gqlGetNotifi({
        variables: {
          offset: 0
        }
      })
      setTotalNotifications(0)
    } else {
      toast.error('Error: no se pudo realizar esta acción')
    }
  }, [dataALLNotification])

  const toggleNotificationDropdown = () => {
    setIsNotificationDropdown(!isNotificationDropdown)
  }

  const toggleTab = (str) => {
    setIsRead(str)
  }

  if ((offset === 0 && loading) || !notifications) {
    return (
      <Dropdown
        isOpen={isNotificationDropdown}
        toggle={toggleNotificationDropdown}
        className='topbar-head-dropdown ms-1 header-item'
      >
        <DropdownToggle
          type='button'
          tag='button'
          className='btn btn-icon btn-topbar btn-ghost-secondary rounded-circle'
        >
          <i className='bx bx-bell fs-22' />
          {/* <span className='position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger'>
            3<span className='visually-hidden'>mensajes sin leer</span>
          </span> */}
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu-lg dropdown-menu-end p-0'>
          <div className='dropdown-head bg-primary bg-pattern rounded-top'>
            <div className='p-3'>
              <Row className='align-items-center'>
                <Col>
                  <h6 className='m-0 fs-16 fw-semibold text-white'>
                    Notificaciones
                  </h6>
                </Col>
                <div className='col-auto dropdown-tabs'>
                  <Spinner size='sm' color='light' />
                </div>
              </Row>
            </div>
            <div className='px-2 pt-2'>
              <Nav className='nav-tabs dropdown-tabs nav-tabs-custom'>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: isRead === NAME_TABS.GET_ALL
                    })}
                    style={{ cursor: 'progress' }}
                  >
                    Todas
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: isRead === NAME_TABS.GET_NOT_READED
                    })}
                    style={{ cursor: 'progress' }}
                  >
                    No leídas
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>

          <div>
            <div style={{ maxHeight: '300px' }} className='pe-2'>
              <div className='text-reset notification-item d-block dropdown-item position-relative'>
                <div className='d-flex'>
                  <div className='avatar-xs me-3'>
                    <span className='avatar-title bg-soft-info text-success rounded-circle fs-16'>
                      <i className='mdi mdi-dots-circle' />
                    </span>
                  </div>
                  <div className='flex-1'>
                    <p className='card-text placeholder-glow'>
                      <span className='placeholder col-12' />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    )
  }

  if (!data && offset === 0) {
    return (
      <Dropdown
        isOpen={isNotificationDropdown}
        toggle={toggleNotificationDropdown}
        className='topbar-head-dropdown ms-1 header-item'
      >
        <DropdownToggle
          type='button'
          tag='button'
          className='btn btn-icon btn-topbar btn-ghost-secondary rounded-circle'
        >
          <i className='bx bx-bell fs-22' />
          {/* <span className='position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger'>
            3<span className='visually-hidden'>mensajes sin leer</span>
          </span> */}
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu-lg dropdown-menu-end p-0'>
          <div className='dropdown-head bg-primary bg-pattern rounded-top'>
            <div className='p-3'>
              <Row className='align-items-center'>
                <Col>
                  <h6 className='m-0 fs-16 fw-semibold text-white'>
                    Notificaciones
                  </h6>
                </Col>
              </Row>
            </div>
          </div>

          <div>
            <div style={{ maxHeight: '300px' }} className='pe-2'>
              <div className='text-reset notification-item d-block dropdown-item position-relative'>
                <div className='d-flex'>
                  <div className='avatar-xs me-3'>
                    <span className='avatar-title bg-soft-danger text-success rounded-circle fs-16'>
                      <i className='bx bx-error' />
                    </span>
                  </div>
                  <div className='flex-1'>
                    <p className='stretched-link'>
                      Oucrrió un error al cargar las notificaciones
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    )
  }

  if (data?.listNotifications?.edges?.length === 0) {
    return (
      <Dropdown
        isOpen={isNotificationDropdown}
        toggle={toggleNotificationDropdown}
        className='topbar-head-dropdown ms-1 header-item'
      >
        <DropdownToggle
          type='button'
          tag='button'
          className='btn btn-icon btn-topbar btn-ghost-secondary rounded-circle'
        >
          <i className='bx bx-bell fs-22' />
          {/* <span className='position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger'>
            3<span className='visually-hidden'>mensajes sin leer</span>
          </span> */}
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu-lg dropdown-menu-end p-0'>
          <div className='dropdown-head bg-primary bg-pattern rounded-top'>
            <div className='p-3'>
              <Row className='align-items-center'>
                <Col>
                  <h6 className='m-0 fs-16 fw-semibold text-white'>
                    Notificaciones
                  </h6>
                </Col>
              </Row>
            </div>
            <div className='px-2 pt-2'>
              <Nav className='nav-tabs dropdown-tabs nav-tabs-custom'>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: isRead === NAME_TABS.GET_ALL
                    })}
                    onClick={() => {
                      toggleTab(NAME_TABS.GET_ALL)
                      setOffset(0)
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    Todas
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: isRead === NAME_TABS.GET_NOT_READED
                    })}
                    onClick={() => {
                      toggleTab(NAME_TABS.GET_NOT_READED)
                      setOffset(0)
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    No leídas
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>

          <div>
            <div style={{ maxHeight: '300px' }} className='pe-2'>
              <div className='text-reset notification-item d-block dropdown-item position-relative'>
                <div className='w-25 w-sm-50 pt-3 mx-auto'>
                  <img src={bell} className='img-fluid' alt='user-pic' />
                </div>
                <div className='text-center pb-5 mt-2'>
                  <h6 className='fs-18 fw-semibold lh-base'>
                    No tienes Notificaciones{' '}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    )
  }

  // const allNotifications = data.listNotifications.edges
  // const totNotiNoRead = notifications.filter(({ isReaded }) => !isReaded).length
  const totNotiNoRead = totalNotifications
  return (
    <Dropdown
      isOpen={isNotificationDropdown}
      toggle={toggleNotificationDropdown}
      className='topbar-head-dropdown ms-1 header-item'
    >
      <DropdownToggle
        type='button'
        tag='button'
        className='btn btn-icon btn-topbar btn-ghost-secondary rounded-circle'
      >
        <i className='bx bx-bell fs-22' />
        {totNotiNoRead > 0 && (
          <span className='position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger'>
            {totNotiNoRead}
            <span className='visually-hidden'>mensajes sin leer</span>
          </span>
        )}
      </DropdownToggle>
      <DropdownMenu className='dropdown-menu-lg dropdown-menu-end p-0'>
        <div className='dropdown-head bg-primary bg-pattern rounded-top'>
          <div className='p-3'>
            <Row className='align-items-center'>
              <Col>
                <h6 className='m-0 fs-16 fw-semibold text-white'>
                  Notificaciones
                </h6>
              </Col>
              <div className='col-auto dropdown-tabs d-flex gap-1'>
                <span className='badge badge-soft-light fs-13'>
                  {totNotiNoRead} Nuevas
                </span>
                {totNotiNoRead > 0 && (
                  <>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        className='btn btn-soft-secondary btn-sm'
                        tag='button'
                        style={{ borderRadius: '100%' }}
                      >
                        <i className='mdi mdi-dots-horizontal text-light' />
                      </DropdownToggle>
                      <DropdownMenu className='dropdown-menu-end'>
                        <DropdownItem onClick={() => gqlMarkAllNotification()}>
                          {loadingALLNotification ? (
                            <Spinner
                              color='light'
                              style={{ width: '8px', height: '8px' }}
                            />
                          ) : (
                            <i className='mdi mdi-checkbox-multiple-marked align-bottom me-2 text-muted' />
                          )}{' '}
                          Marcar todas como leídas
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </>
                )}
              </div>
            </Row>
          </div>

          <div className='px-2 pt-2'>
            <Nav className='nav-tabs dropdown-tabs nav-tabs-custom'>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: isRead === NAME_TABS.GET_ALL
                  })}
                  onClick={() => {
                    toggleTab(NAME_TABS.GET_ALL)
                    setOffset(0)
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Todas
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: isRead === NAME_TABS.GET_NOT_READED
                  })}
                  onClick={() => {
                    toggleTab(NAME_TABS.GET_NOT_READED)
                    setOffset(0)
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  No leídas
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>

        <div>
          <SimpleBar style={{ maxHeight: '300px' }} className='pe-2'>
            {notifications.map(
              ({ title, msg, createDate, isReaded, url, id }) => (
                <button
                  className='text-reset notification-item d-block dropdown-item position-relative'
                  key={createDate + id}
                  onClick={() => {
                    const urlData = new URL(url)
                    if (isReaded) {
                      setIsNotificationDropdown(false)
                      return navigate(urlData.pathname)
                    }
                    setUrlNavigate(urlData.pathname)
                    gqlMarkNotification({
                      variables: {
                        notificationId: id
                      }
                    })
                    setUpdateNotiId(id)
                  }}
                  style={{
                    backgroundColor: isReaded ? '#f3f3f3' : undefined
                  }}
                  disabled={loadingNotification}
                >
                  <div className='d-flex align-items-center'>
                    {loadingNotification ? (
                      <div className='avatar-xs me-3'>
                        <Spinner size='sm' />
                      </div>
                    ) : (
                      <div className='avatar-xs me-3'>
                        <span
                          className={`avatar-title ${
                            isReaded
                              ? 'bg-soft-dark text-dark'
                              : 'bg-soft-info text-info'
                          } rounded-circle fs-16`}
                        >
                          {isReaded ? (
                            <i className='mdi mdi-email-open-multiple-outline' />
                          ) : (
                            <i className='mdi mdi-email-alert-outline' />
                          )}
                        </span>
                      </div>
                    )}
                    <div className='flex-1'>
                      <div className='stretched-link'>
                        <h6 className='mt-0 mb-2 lh-base'>{title}</h6>
                      </div>
                      <div className='fs-13 text-muted'>
                        <p className='mb-1'>{msg}</p>
                      </div>
                      <p className='mb-0 fs-11 fw-medium text-uppercase text-muted'>
                        <span>
                          <i className='mdi mdi-clock-outline' />{' '}
                          {moment(createDate).fromNow()}
                        </span>
                      </p>
                    </div>
                  </div>
                </button>
              )
            )}

            <div className='my-3 text-center'>
              {offset > 0 && loading ? (
                <div className='waves-effect waves-light'>
                  <Spinner size='sm' />
                </div>
              ) : data?.listNotifications?.pageInfo?.hasNextPage ? (
                <button
                  type='button'
                  className='btn btn-soft-success waves-effect waves-light'
                  onClick={() => {
                    setOffset((pre) => pre + 10)
                  }}
                >
                  Ver más{' '}
                  <i className='mdi mdi-bell-plus-outline align-middle' />
                </button>
              ) : null}
            </div>
          </SimpleBar>
        </div>

        {/*
          <TabContent activeTab={activeTab}>
            <TabPane tabId='1' className='py-2 ps-2'></TabPane>

            <TabPane tabId='2' className='py-2 ps-2'>
              <SimpleBar style={{ maxHeight: '300px' }} className='pe-2'>
                <div className='text-reset notification-item d-block dropdown-item'>
                  <div className='d-flex'>
                    <img
                      src={avatar3}
                      className='me-3 rounded-circle avatar-xs'
                      alt='user-pic'
                    />
                    <div className='flex-1'>
                      <Link to='#' className='stretched-link'>
                        <h6 className='mt-0 mb-1 fs-13 fw-semibold'>
                          James Lemire
                        </h6>
                      </Link>
                      <div className='fs-13 text-muted'>
                        <p className='mb-1'>
                          We talked about a project on linkedin.
                        </p>
                      </div>
                      <p className='mb-0 fs-11 fw-medium text-uppercase text-muted'>
                        <span>
                          <i className='mdi mdi-clock-outline' /> 30 min ago
                        </span>
                      </p>
                    </div>
                    <div className='px-2 fs-15'>
                      <div className='form-check notification-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='messages-notification-check01'
                        />
                        <label
                          className='form-check-label'
                          htmlFor='messages-notification-check01'
                        />
                      </div>
                      <input className="form-check-input" type="checkbox" />
                    </div>
                  </div>
                </div>

                <div className='text-reset notification-item d-block dropdown-item'>
                  <div className='d-flex'>
                    <img
                      src={avatar2}
                      className='me-3 rounded-circle avatar-xs'
                      alt='user-pic'
                    />
                    <div className='flex-1'>
                      <Link to='#' className='stretched-link'>
                        <h6 className='mt-0 mb-1 fs-13 fw-semibold'>
                          Angela Bernier
                        </h6>
                      </Link>
                      <div className='fs-13 text-muted'>
                        <p className='mb-1'>
                          Answered to your comment on the cash flow forecast's
                          graph 🔔.
                        </p>
                      </div>
                      <p className='mb-0 fs-11 fw-medium text-uppercase text-muted'>
                        <span>
                          <i className='mdi mdi-clock-outline' /> 2 hrs ago
                        </span>
                      </p>
                    </div>
                    <div className='px-2 fs-15'>
                      <div className='form-check notification-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='messages-notification-check02'
                        />
                        <label
                          className='form-check-label'
                          htmlFor='messages-notification-check02'
                        />
                      </div>
                      <input className="form-check-input" type="checkbox" />
                    </div>
                  </div>
                </div>

                <div className='text-reset notification-item d-block dropdown-item'>
                  <div className='d-flex'>
                    <img
                      src={avatar6}
                      className='me-3 rounded-circle avatar-xs'
                      alt='user-pic'
                    />
                    <div className='flex-1'>
                      <Link to='#' className='stretched-link'>
                        <h6 className='mt-0 mb-1 fs-13 fw-semibold'>
                          Kenneth Brown
                        </h6>
                      </Link>
                      <div className='fs-13 text-muted'>
                        <p className='mb-1'>
                          Mentionned you in his comment on 📃 invoice #12501.{' '}
                        </p>
                      </div>
                      <p className='mb-0 fs-11 fw-medium text-uppercase text-muted'>
                        <span>
                          <i className='mdi mdi-clock-outline' /> 10 hrs ago
                        </span>
                      </p>
                    </div>
                    <div className='px-2 fs-15'>
                      <div className='form-check notification-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='messages-notification-check03'
                        />
                        <label
                          className='form-check-label'
                          htmlFor='messages-notification-check03'
                        />
                      </div>
                      <input className="form-check-input" type="checkbox" />
                    </div>
                  </div>
                </div>

                <div className='text-reset notification-item d-block dropdown-item'>
                  <div className='d-flex'>
                    <img
                      src={avatar8}
                      className='me-3 rounded-circle avatar-xs'
                      alt='user-pic'
                    />
                    <div className='flex-1'>
                      <Link to='#' className='stretched-link'>
                        <h6 className='mt-0 mb-1 fs-13 fw-semibold'>
                          Maureen Gibson
                        </h6>
                      </Link>
                      <div className='fs-13 text-muted'>
                        <p className='mb-1'>
                          We talked about a project on linkedin.
                        </p>
                      </div>
                      <p className='mb-0 fs-11 fw-medium text-uppercase text-muted'>
                        <span>
                          <i className='mdi mdi-clock-outline' /> 3 days ago
                        </span>
                      </p>
                    </div>
                    <div className='px-2 fs-15'>
                      <div className='form-check notification-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='messages-notification-check04'
                        />
                        <label
                          className='form-check-label'
                          htmlFor='messages-notification-check04'
                        />
                      </div>
                      <input className="form-check-input" type="checkbox" />
                    </div>
                  </div>
                </div>

                <div className='my-3 text-center'>
                  <button
                    type='button'
                    className='btn btn-soft-success waves-effect waves-light'
                  >
                    View All Messages{' '}
                    <i className='ri-arrow-right-line align-middle' />
                  </button>
                </div>
              </SimpleBar>
            </TabPane>
            <TabPane tabId='3' className='p-4'>
              <div className='w-25 w-sm-50 pt-3 mx-auto'>
                <img src={bell} className='img-fluid' alt='user-pic' />
              </div>
              <div className='text-center pb-5 mt-2'>
                <h6 className='fs-18 fw-semibold lh-base'>
                  No tienes Notificaciones{' '}
                </h6>
              </div>
            </TabPane>
          </TabContent> */}
      </DropdownMenu>
    </Dropdown>
  )
}

export default NotificationDropdown
