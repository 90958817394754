import moment from 'moment'
import React from 'react'
import { CardBody, Modal, ModalBody, ModalHeader } from 'reactstrap'

/** @param {{infoComment: boolean, showInfoComment: {id: string,comment: string, created: string, user:{id: string, firstName: string, lastName: string}}, toggle: ()=>void}} */
export const ModalInfoComment = ({ infoComment, showInfoComment, toggle }) => {
  console.log('infoComment', infoComment)
  return (
    <Modal isOpen={showInfoComment} toggle={toggle}>
      <ModalHeader toggle={toggle}>Último comentario</ModalHeader>
      <ModalBody className='p-4'>
        <CardBody>
          <blockquote className='card-blockquote mb-0'>
            <p className='text-info mb-2'>
              Fecha:{' '}
              <span className='text-dark'>
                {moment(infoComment.created).format(
                  'DD MMM [del] YY - hh:mm A'
                )}
              </span>
            </p>
            <p className='text-muted mb-2'>
              Archivos: {infoComment.creditsupportwalletSet?.edges?.length || 0}
            </p>
            <figure className='mb-0'>
              <blockquote className='blockquote'>
                <p className='lead'>{infoComment.comment}</p>
              </blockquote>
              <figcaption className='blockquote-footer fs-13 text-end mb-0'>
                Comentó
                <cite title='Source Title' className='text-primary fw-semibold'>
                  {' '}
                  {infoComment.user.firstName} {infoComment.user.lastName}
                </cite>
              </figcaption>
            </figure>
          </blockquote>
        </CardBody>
      </ModalBody>
    </Modal>
  )
}
