import React, { useEffect, useState } from 'react'
import {
  Button,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Table
} from 'reactstrap'
import { parseId, parsePrice } from '../../../helpers'
import { useDisclosure } from '../../../hooks/useDisclosure'
import { useMutation } from '@apollo/client'
import { UPDATE_PRICE_QUOTATION } from '../../../graphql/queries/getCotizaciones'
import { toast } from 'react-toastify'

export const InfoPricesQuotation = ({
  products,
  refetch,
  quotationId,
  goHome
}) => {
  const [gqlUpdate, { data, loading, error }] = useMutation(
    UPDATE_PRICE_QUOTATION
  )

  const { isOpen, onClose, onOpen } = useDisclosure()
  const [infoProd, setInfoProd] = useState(null)

  useEffect(() => {
    if (error) toast.error('Error: ' + error.message)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.changePricesQuotation.success) {
      toast.success('Precio actualizado correctamente')
      onClose()
      refetch()
      goHome()
    } else if (data.changePricesQuotation.errors) {
      toast.error(
        `Error ${data.changePricesQuotation.errors?.message || 'Algo ocurrió'}`
      )
    }
  }, [data])

  const handleUpdate = () => {
    if (!infoProd) return
    const input = {
      productId: parseId(infoProd.id),
      quotationId
    }
    gqlUpdate({
      variables: { input }
    })
  }

  return (
    <>
      <CardBody>
        <div className='table-responsive'>
          <Table
            className='table text-center table-nowrap align-middle mb-0'
            style={{ fontSize: '11px' }}
          >
            <thead>
              <tr className='table-active'>
                <th scope='col'>#</th>
                <th className='text-start' scope='col'>
                  Producto
                </th>
                <th scope='col'>Precio</th>
                <th />
              </tr>
            </thead>
            <tbody id='products-list'>
              {products.map(({ node }, idx) => (
                <tr key={node.id}>
                  <th scope='row'>{idx < 9 ? `0${idx + 1}` : idx + 1}</th>
                  <td>
                    <div className='w-100 text-start'>
                      <p
                        className='fw-medium mb-0'
                        style={{
                          maxWidth: '100%',
                          whiteSpace: 'break-spaces'
                        }}
                      >
                        {node.product.name}
                      </p>
                      <p className='text-muted mb-0'>
                        <span>SKU: </span>
                        {node.product.sku}
                      </p>
                    </div>
                    {/* <p className='text-muted mb-0'>
                                        <span>Marca: </span>
                                        {node.product.}
                                      </p> */}
                  </td>
                  <td>{parsePrice(node.basePrice)}</td>
                  <td>
                    <Button
                      outline
                      color='danger'
                      onClick={() => {
                        setInfoProd({
                          id: node.product.id,
                          name: node.product.name,
                          price: node.basePrice
                        })
                        onOpen()
                      }}
                    >
                      <i className='mdi mdi-reload' /> Actualizar precio
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>

      {isOpen && infoProd && (
        <Modal id='showModalComment' isOpen={isOpen} toggle={onClose} centered>
          <ModalHeader className='bg-soft-info p-3' toggle={onClose}>
            Actualizar precio
          </ModalHeader>
          <ModalBody>
            ¿Seguro que desea actualizar el precio{' '}
            <span className='fw-bold'>{parsePrice(infoProd.price)}</span> de{' '}
            <span className='fw-bold text-info'>{infoProd.name}</span>?
          </ModalBody>
          <ModalFooter>
            <div className='hstack gap-2 justify-content-end'>
              <Button color='light' onClick={onClose}>
                Cancelar{' '}
              </Button>
              <Button
                type='submit'
                color='danger'
                id='add-btn'
                disabled={loading}
                onClick={handleUpdate}
              >
                {loading && <Spinner size='sm' color='light' />}
                Actualizar
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
