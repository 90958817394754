import { useLazyQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { GET_INACTIVE_USER } from '../../../graphql/queries/getEvents'
import { useGetUser } from '../../../hooks/user'
import { hasPermission } from '../../../helpers'
import { ALL_PERMISSIONS, ERROR_MESSAGES } from '../../../helpers/consts'
import { toast } from 'react-toastify'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
  Table
} from 'reactstrap'
import empty from '../../../assets/images/totalsales/empty.svg'
import moment from 'moment'
import SimpleBar from 'simplebar-react'

export const InactiveUsers = () => {
  const [
    gqlGetInactiveUsers,
    { data: dataUsersIn, loading: loadingUsersIn, error: errorUsersIn }
  ] = useLazyQuery(GET_INACTIVE_USER, { fetchPolicy: 'no-cache' })
  const { logout, permissions } = useGetUser()
  const canViewUsersIn = hasPermission(
    permissions,
    ALL_PERMISSIONS.PUEDE_VER_USUARIOS_INACTIVOS
  )

  const [dataTable, setDataTable] = useState([])
  const [isPaggination, setIsPaggination] = useState(false)

  useEffect(() => {
    if (canViewUsersIn) {
      gqlGetInactiveUsers()
    }
  }, [canViewUsersIn])

  useEffect(() => {
    if (!errorUsersIn) return
    toast.error('Error: ' + errorUsersIn.message)
    if (errorUsersIn.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
      logout()
    }
  }, [errorUsersIn])

  useEffect(() => {
    if (!dataUsersIn) return
    if (dataUsersIn.listUsersInactive) {
      setDataTable((prev) => [...prev, ...dataUsersIn.listUsersInactive.edges])
    }
  }, [dataUsersIn])

  if (!canViewUsersIn) return null

  if (loadingUsersIn && !isPaggination) {
    return (
      <Card>
        <CardBody
          className='d-flex align-items-center justify-content-center'
          style={{ height: '180px' }}
        >
          <Spinner />
        </CardBody>
      </Card>
    )
  }
  if (dataTable.length === 0) {
    return (
      <Card>
        <CardBody>
          <h6>No se han inactivado usuarios esta semana</h6>
          <div className='d-flex align-items-center justify-content-center'>
            <img
              src={empty.toString()}
              className='w-100 h-auto'
              style={{ maxWidth: '200px' }}
            />
          </div>
        </CardBody>
      </Card>
    )
  }
  return (
    <Card>
      <CardHeader>
        <CardTitle>Usuarios inactivos</CardTitle>
        <small style={{ color: '#a1a1a1' }}>
          Esta semana: {dataTable.length}{' '}
        </small>
      </CardHeader>
      <CardBody>
        <SimpleBar
          className=' mb-3'
          style={{ height: '100%', maxHeight: '380px', width: '100%' }}
        >
          <Table hover style={{ whiteSpace: 'nowrap' }}>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Inactivación</th>
              </tr>
            </thead>
            <tbody>
              {dataTable.map(({ node }) => (
                <tr key={node.id}>
                  <td>
                    <div className='d-flex align-items-start flex-column'>
                      <span>
                        {node.firstName} {node.lastName}
                      </span>
                      <small style={{ color: '#a4a4a4' }}>{node.email}</small>
                    </div>
                  </td>
                  <td>
                    {moment(node.inactivationDate).format('DD MMM [de] YYYY')}
                  </td>
                </tr>
              ))}
            </tbody>
            {dataUsersIn?.listUsersInactive?.pageInfo?.hasNextPage && (
              <tfoot>
                <tr>
                  <td colSpan={3}>
                    <Button
                      onClick={() => {
                        setIsPaggination(true)
                      }}
                    >
                      Cargar más
                    </Button>
                  </td>
                </tr>
              </tfoot>
            )}
          </Table>
        </SimpleBar>
      </CardBody>
    </Card>
  )
}

/*
{
      node: {
        id: '1',
        firstName: 'Name uno',
        lastName: 'Last dos',
        email: 'jose@jose.com',
        inactivationDate: new Date().toDateString()
      }
    }
    */
