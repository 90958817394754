import { gql } from '@apollo/client'

export const ADD_COTIZACION_ALIADO = gql`
  mutation allyQuoteCreate($input: AllyQuoteCreateInput!) {
    allyQuoteCreate(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`
