import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap'
import { INFO_ARR_KEYS_WALLET_RESUME } from '../../../helpers/consts'
import { parseId, parsePrice } from '../../../helpers'
import { useDisclosure } from '../../../hooks/useDisclosure'
import { useGetUser } from '../../../hooks/user'
import { CALCULATOR } from '../../../graphql/mutations/userMutations'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { CREATE_REFINACIACION_TOTAL } from '../../../graphql/mutations/creditWalletMutations'

/**
 *
 * @param {{infoObligation: {total: string, capital: string, endorsement: string, interest: string, penaltyInterest: string, paymentManagement: string}, obligationId: string, refetch: ()=>void, backToResume: ()=>void}} param0
 * @returns
 */
export const TabRefinanciacion = ({
  infoObligation,
  backToResume,
  obligationId,
  refetch
}) => {
  const [gqlCalculator, { data, loading, error }] = useMutation(CALCULATOR)

  const [
    gqlCreateRefin,
    { data: dataCreate, loading: loadingCreate, error: errorCreate }
  ] = useMutation(CREATE_REFINACIACION_TOTAL)

  const { user } = useGetUser()

  const { isOpen, onClose, onOpen } = useDisclosure()
  const {
    isOpen: isOpenConfirm,
    onClose: onCloseConfirm,
    onOpen: onOpenConfirm
  } = useDisclosure()

  const [quota, setQuota] = useState(null)
  const [term, setTerm] = useState(0)

  useEffect(() => {
    if (!error) return
    toast.error(`Error: ${error.message}`)
  }, [error])

  useEffect(() => {
    if (!data) return
    if (data.calculator.success) {
      setQuota(data.calculator.quota)
      onClose()
    } else if (data.calculator?.errors) {
      toast.error(
        'Error: ' + data.calculator.errors?.message ||
          'No se pudo calcular crédito'
      )
    }
  }, [data])

  useEffect(() => {
    if (!errorCreate) return
    toast.error(`Error: ${errorCreate.message}`)
  }, [errorCreate])

  useEffect(() => {
    if (!dataCreate) return
    if (dataCreate.refinanceObligation.success) {
      toast.success('Abono a capital creado correctamente')
      onCloseConfirm()
      backToResume()
      refetch()
    } else if (dataCreate.refinanceObligation?.errors) {
      toast.error(
        'Error: ' + dataCreate.refinanceObligation.errors?.message ||
          'No se pudo abonar capital'
      )
    }
  }, [dataCreate])

  if (!user) {
    return (
      <Card>
        <CardBody>
          <Spinner />
        </CardBody>
      </Card>
    )
  }

  const { max_quotation_fee: maxQuotationFee } = JSON.parse(
    user.tokenAuth.settings
  )

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Refinanciación</CardTitle>
        </CardHeader>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            if (obligationId && term && infoObligation.total) {
              onOpenConfirm()
            }
          }}
        >
          <CardBody>
            <Alert
              color='warning'
              className='alert-border-left d-flex align-items-center gap-2'
            >
              <i className='mdi mdi-alert-outline fs-2' />
              Usa esta opción cuando se requiere refinanciar la deuda
            </Alert>

            <div>
              <Row className='row-cols-md-3 row-cols-1'>
                {INFO_ARR_KEYS_WALLET_RESUME.map((item, idx) => (
                  <Col
                    className={idx !== 2 || idx !== 5 ? 'border-end' : ''}
                    key={item.key}
                  >
                    <div className='mt-3 mt-md-0 py-4 px-3'>
                      <h5 className='text-muted text-uppercase fs-13'>
                        {item.name}
                      </h5>
                      <div className='d-flex align-items-center'>
                        <div className='flex-shrink-0'>
                          <i className={'display-6 text-muted ' + item.icon} />
                        </div>
                        <div className='flex-grow-1 ms-3'>
                          <h2 className='mb-0'>
                            <span className='counter-value'>
                              {parsePrice(infoObligation[item.key])}
                            </span>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>

            <FormGroup>
              <Label htmlFor='value-field'>Valor total</Label>
              <Input defaultValue={parsePrice(infoObligation.total)} readOnly />
            </FormGroup>
            <div className='d-flex align-items-center gap-1 flex-column flex-md-row w-100 justify-content-between'>
              <FormGroup className='flex-1'>
                <Label>Plazo</Label>
                <Input type='number' disabled readOnly value={term} />
              </FormGroup>
              <div>
                <Label />
                <Button
                  className='d-block mb-2'
                  type='button'
                  color='soft-danger'
                  onClick={onOpen}
                >
                  Seleccionar plazo
                </Button>
              </div>
            </div>
            {quota && (
              <>
                <FormGroup className='flex-1'>
                  <Label>Valor de couta</Label>
                  <Input disabled readOnly defaultValue={parsePrice(quota)} />
                </FormGroup>
              </>
            )}
          </CardBody>
          <CardFooter>
            <div className='d-flex w-100 align-items-center justify-content-end'>
              <Button type='submit' disabled={quota == null}>
                Refinanciar obligación
              </Button>
            </div>
          </CardFooter>
        </form>
      </Card>

      {isOpen && (
        <Modal isOpen={isOpen} size='sm'>
          <ModalHeader>Calcular crédito</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for='exampleRange'>
                Valor a prestar: ({parsePrice(infoObligation.total)})
              </Label>
              <Input
                id='exampleRange'
                name='range'
                type='range'
                readOnly
                min={0}
                max={Number(infoObligation.total) * 2}
                defaultValue={Number(infoObligation.total)}
              />
            </FormGroup>
            <FormGroup>
              <Label for='rangeTerm'>Plazo: ({term} meses)</Label>
              <Input
                id='rangeTerm'
                type='range'
                name='term'
                min={0}
                max={Number(maxQuotationFee)}
                onChange={(e) => {
                  setTerm(Number(e.target.value))
                  setQuota(null)
                }}
                value={term}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color='light'
              onClick={() => {
                onClose()
              }}
            >
              Cancelar
            </Button>
            <Button
              className='d-flex align-items-center gap-2'
              disabled={
                !Number(infoObligation.total) || !Number(term) || loading
              }
              color='info'
              onClick={() => {
                gqlCalculator({
                  variables: {
                    input: {
                      term,
                      value: Number(infoObligation.total)
                    }
                  }
                })
              }}
            >
              {loading && <Spinner size='sm' />}
              Ver couta
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {isOpenConfirm && Number(term) && Number(term) !== 0 && (
        <Modal isOpen={isOpenConfirm} centered>
          <ModalHeader>¡CUIDADO!</ModalHeader>
          <ModalBody>
            <p>
              Se va a hacer una refinanciación de{' '}
              <span className='fw-bold'>
                {parsePrice(infoObligation.total)}
              </span>{' '}
              a un plazo de <span className='fw-bold'>{term}</span> meses y un
              valor de cuota de{' '}
              <span className='fw-bold'>{parsePrice(quota)}</span>.
            </p>
            <p className='fs-3 text-danger'>
              ¿Está seguro que desea continuar?
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              color='light'
              disabled={loadingCreate}
              onClick={onCloseConfirm}
            >
              Cancelar
            </Button>
            <Button
              className='d-flex align-items-center'
              color='danger'
              disabled={loadingCreate}
              onClick={() => {
                gqlCreateRefin({
                  variables: {
                    input: {
                      obligationId: parseId(obligationId),
                      term
                    }
                  }
                })
              }}
            >
              {loadingCreate && <Spinner size='sm' />}
              Aceptar
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
