/* eslint-disable react/jsx-indent */
import React, { useEffect, useState, useRef, useCallback } from 'react'
import {
  Card,
  CardBody,
  Col,
  Container,
  Tooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Badge,
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  Label,
  Input,
  FormFeedback,
  ModalFooter,
  Button,
  FormGroup,
  Table,
  Spinner,
  Fade,
  Alert
} from 'reactstrap'
import * as Yup from 'yup'

import Cropper from 'react-easy-crop'

// Simple bar
// import SimpleBar from 'simplebar-react'

import BreadCrumb from '../../../Components/Common/BreadCrumb'

/* import product1 from '../../../assets/images/products/img-1.png'
import product6 from '../../../assets/images/products/img-6.png' */
import product8 from '../../../assets/images/moto-generic.png'

// import { reviews } from '../../../common/data/ecommerce'

import Select from 'react-select'
// import { Swiper, SwiperSlide } from 'swiper/react'
import classnames from 'classnames'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'

// import SwiperCore, { FreeMode, Navigation, Thumbs } from 'swiper'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  GET_AGENCIAS_TO_PRODUCT,
  GET_CATEGORIES_TO_PRODUCT,
  GET_DISCOUNTS_TO_PRODUCT,
  GET_ONE_PRODUCT,
  GET_PRICES_TO_PRODUCT,
  GET_ZONES_TO_PRODUCT
} from '../../../graphql/queries/getProducts'
import { toast } from 'react-toastify'
import moment from 'moment'
import { hasPermission, parseId, parsePrice } from '../../../helpers'
import {
  ACTIVE_DESACTIVE_PRODUCT,
  ADD_DISCOUNT,
  ADD_PRICES,
  ADD_SPECIFICATIONS,
  DELETE_DISCOUNT_IN_PRODUCT,
  DELETE_SPECIFICATION,
  UPDATE_SPECIFICATION
} from '../../../graphql/mutations/products'
import { ErrorMessage, FieldArray, Formik, useFormik } from 'formik'
// import CurrencyInput from 'react-currency-input-field'
import getCroppedImg from '../../../helpers/toImgFile'
import { useGetUser } from '../../../hooks/user'
import { API } from '../../../graphql/client'
import { ALL_PERMISSIONS, ERROR_MESSAGES } from '../../../helpers/consts'
import { InputCurrency } from '../../../Components/Own/InputCurrency'

// SwiperCore.use([FreeMode, Navigation, Thumbs])

const initialValues = {
  fields: [{ key: '', value: '' }]
}

const validationSchema = Yup.object().shape({
  fields: Yup.array().of(
    Yup.object().shape({
      key: Yup.string().required('El nombre es requerido'),
      value: Yup.string().required('El valor es requerido')
    })
  )
})

const INIT_NEW_PRICE = {
  id: 0,
  value: '',
  name: '',
  touched: false,
  error: null,
  priceType: '',
  agency: 0,
  product: 0
}

const DISCOUNTS_TYPES = ['EMPRESA', 'ENSAMBLADORA']

function OneProduct(_props) {
  const [gqlGetProduct, { data, loading, error, refetch }] = useLazyQuery(
    GET_ONE_PRODUCT,
    { fetchPolicy: 'no-cache' }
  )
  const {
    data: dataEmpsCom
    /*     loading: loadingEmpsCom,
    error: errorEmpsCom */
  } = useQuery(GET_CATEGORIES_TO_PRODUCT, {
    fetchPolicy: 'no-cache'
  })
  const {
    data: dataAgencies,
    loading: loadingAgencies
    /*
    error: errorEmpsCom */
  } = useQuery(GET_AGENCIAS_TO_PRODUCT, {
    fetchPolicy: 'no-cache'
  })

  const [
    addSpecifications,
    { data: dataSpeci, loading: loadingSpeci, error: errorCreate }
  ] = useMutation(ADD_SPECIFICATIONS, {
    refetchQueries: [
      {
        query: GET_ONE_PRODUCT
      }
    ]
  })
  const [
    gqlActiveInactiveProduct,
    {
      data: dataActiveInactive,
      loading: loadingActiveInactive,
      error: errorActiveInactive
    }
  ] = useMutation(ACTIVE_DESACTIVE_PRODUCT, {
    refetchQueries: [
      {
        query: GET_ONE_PRODUCT
      }
    ]
  })

  const [
    gqlAddPrices,
    {
      data: dataCreatePrice,
      loading: loadingCreatePrice,
      error: errorCreatePrice
    }
  ] = useMutation(ADD_PRICES, {
    refetchQueries: [
      {
        query: GET_ONE_PRODUCT
      }
    ]
  })

  const [
    gqlAddDiscount,
    {
      data: dataAddDiscount,
      loading: loadingAddDiscount,
      error: errorAddDiscount
    }
  ] = useMutation(ADD_DISCOUNT, {
    refetchQueries: [
      {
        query: GET_ONE_PRODUCT
      }
    ]
  })
  const [
    gqlEditSpecifi,
    {
      data: dataEditSpecifi,
      loading: loadingEditSpecifi,
      error: errorEditSpecifi
    }
  ] = useMutation(UPDATE_SPECIFICATION, {
    refetchQueries: [
      {
        query: GET_ONE_PRODUCT
      }
    ]
  })
  const [
    gqlDeleteSpecifi,
    {
      data: dataDeleteSpecifi,
      loading: loadingDeleteSpecifi,
      error: errorDeleteSpecifi
    }
  ] = useMutation(DELETE_SPECIFICATION, {
    refetchQueries: [
      {
        query: GET_ONE_PRODUCT
      }
    ]
  })

  const [
    gqlDeleteDisc,
    { data: dataDeleteDisc, loading: loadingDeleteDisc, error: errorDeleteDisc }
  ] = useMutation(DELETE_DISCOUNT_IN_PRODUCT, {
    refetchQueries: [GET_ONE_PRODUCT]
  })

  const {
    data: dataZones,
    loading: loadingZones,
    error: errorZones
  } = useQuery(GET_ZONES_TO_PRODUCT, {
    fetchPolicy: 'no-cache'
  })

  const [
    gqlGetPrices,
    {
      data: dataPrices,
      loading: loadingPrices,
      error: errorPrices,
      refetch: refetchPrices
    }
  ] = useLazyQuery(GET_PRICES_TO_PRODUCT, { fetchPolicy: 'no-cache' })
  const [
    gqlGetDiscounts,
    {
      data: dataDiscounts,
      loading: loadingDiscounts,
      error: errorDiscounts,
      refetch: refetchDiscounts
    }
  ] = useLazyQuery(GET_DISCOUNTS_TO_PRODUCT, { fetchPolicy: 'no-cache' })

  const { logout, permissions } = useGetUser()

  const { id } = useParams()
  const navigate = useNavigate()
  const inputRef = useRef()

  const [loadingCreate, setLoadingCreate] = useState(false)
  // const [thumbsSwiper, setThumbsSwiper] = useState()
  const [imgToEdit, setImgToEdit] = useState(false)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedArea, setCroppedArea] = useState(null)

  const [ttop, setttop] = useState(false)

  const [ssize, setssize] = useState(false)
  const [modal, setModal] = useState(false)
  // const [infoEmpComer, setInfoEmpComer] = useState([])
  const [infoCategories, setInfoCategories] = useState([])

  const [modalEspecifi, setModalEspecifi] = useState(false)
  const [modalDisableProduct, setModalDisableProduct] = useState(false)
  const [modalPriceProduct, setModalPriceProduct] = useState(false)
  const [newPrice, setNewPrice] = useState(INIT_NEW_PRICE)
  // const [newDiscount, setNewDiscount] = useState(INIT_NEW_PRICE)

  /* const [msize, setmsize] = useState(false)
  const [lsize, setlsize] = useState(false)
  const [xlsize, setxlsize] = useState(false) */
  const [customActiveTab, setcustomActiveTab] = useState('1')

  // const [selectAgencies, setSelectAgencies] = useState([])
  // const [agencyValueSelect, setAgencyValueSelect] = useState(null)
  const [agenciesSelect, setAgenciesSelect] = useState([])
  const [agencyValueSelect, setAgencyValueSelect] = useState('')

  const [zoneSelect, setZoneSelect] = useState([])
  const [zoneSelectValue, setZoneSelectValue] = useState(null)

  const [agenciesSelectDiscount, setAgenciesSelectDiscount] = useState([])
  const [agencyValueSelectDiscount, setAgencyValueSelectDiscount] = useState('')

  // const [zoneSelectDiscount, setZoneSelectDiscount] = useState([])
  const [zoneSelectDiscountValue, setZoneSelectDiscountValue] = useState(null)

  const [modalAddDiscount, setModalAddDiscount] = useState(false)
  /** @type {[isEditDiscout: number | null, setIsEditDiscout: React.Dispatch<React.SetStateAction<number | null>>]} */
  const [isEditDiscout, setIsEditDiscout] = useState(null)
  const [infoAgencies, setInfoAgencies] = useState([])
  const [zonesCreateDiscount, setZonesCreateDiscount] = useState('')

  const [modalEditSpecifi, setModalEditSpecifi] = useState(false)
  const [modalDeleteSpecifi, setModalDeleteSpecifi] = useState(false)
  const [specificationId, setSpecificationId] = useState({ id: '', name: '' })

  const [infoDelete, setInfoDelete] = useState({
    name: '',
    id: ''
  })
  const [modalDeleteDiscount, setModalDeleteDiscount] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    // enableReinitialize: true,

    initialValues: {
      // img: (company && company.img) || '',
      name: '',
      sku: '',
      basePrice: '',
      image: null,
      category: '',
      id: null
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Ingrese nombre del producto'),
      basePrice: Yup.string()
        // .min(1000, 'Debe ser mayor a $1,000')
        .required('Ingrese precio'),
      sku: Yup.string().required('Ingrese SKU'),
      category: Yup.string().required('Categoría requerida')
    }),
    onSubmit: async (values) => {
      const companyInfo = { ...values }
      const category = companyInfo.category

      const input = {
        ...companyInfo,
        category
      }

      if (imgToEdit) {
        const res = await getCroppedImg(imgToEdit, croppedArea)
        const resBuff = await fetch(res)
        const buff = await resBuff.arrayBuffer()
        const newFile = new window.File([buff], `${Date.now()}-moto.png`, {
          type: 'image/png'
        })
        // console.log('newFile', newFile)
        input.image = newFile
      } else {
        delete input.image
      }
      const myHeaders = new Headers()
      const token = JSON.parse(window.localStorage.getItem('token'))

      if (!token) {
        return logout()
      }

      myHeaders.append('Authorization', `Bearer ${token}`)
      // console.log('input', input)

      const formdata = new FormData()
      formdata.append(
        'operations',
        `{"variables": {"input": {"id": ${input.id}, "name": "${
          input.name
        }","sku": "${input.sku}", "category":${input.category},"basePrice": ${
          input.basePrice
        }${
          imgToEdit ? ', "image": null' : ''
        }}}, "query": "mutation ($input: ProductCreateUpdateInput!){productCreateUpdate(input: $input){ errors{field message} product{id} success } }"}`
      )
      if (imgToEdit) {
        formdata.append('map', '{"0": ["variables.input.image"]}')
        formdata.append('0', input.image, input.image.name)
      }

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      }
      setLoadingCreate(true)
      fetch(API + '/api', requestOptions)
        .then((response) => response.json())
        .then((dataCreate) => {
          setLoadingCreate(false)
          // console.log('dataCreate', dataCreate)
          if (dataCreate?.data?.productCreateUpdate?.success) {
            // console.log('dataCreate', dataCreate);
            toast.success('Producto actualizado correctamente')
            validation.resetForm()
            setImgToEdit(null)
            refetch()
            toggle()
          } else {
            console.log('dataCreate.errors', dataCreate.errors)
            toast.error(`ERROR! ${dataCreate?.errors[0].message}`)
          }
        })
        .catch((errorCreate) => {
          console.log('errorCreate', errorCreate)
          setLoadingCreate(false)
          toast.error(`ERROR! ${errorCreate.message}`)
        })

      /* gqlUpdateProduct({
        variables: {
          input
        },
        refetchQueries: [
          {
            query: GET_ONE_PRODUCT
          }
        ]
      }) */

      /* gqlCreateEmpresaComercial({
          variables: {
            input: { ...companyInfo, id },
          },
          refetchQueries: [
            {
              query: GET_PUNTOS_EMP_COMER,
            },
          ],
        }); */
      // validation.resetForm();
    }
  })

  const formikDiscount = useFormik({
    initialValues: {
      name: '',
      value: '',
      discountType: '',
      agency: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Nombre requerido'),
      value: Yup.string().required('Precio requerido'),
      discountType: Yup.string().required('Tipo de descuento es requerido'),
      agency: Yup.string().required('Agencia es requerida')
    }),
    onSubmit: (values) => {
      // console.log('values', values)
      const input = { ...values, product: id }
      if (isEditDiscout) {
        input.id = isEditDiscout
      }
      gqlAddDiscount({
        variables: {
          input
        },
        refetchQueries: [GET_ONE_PRODUCT]
      })
    }
  })
  const formikEditSpecifi = useFormik({
    initialValues: {
      id: 0,
      name: '',
      value: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Nombre requerido'),
      value: Yup.string().required('Valor requerido')
    }),
    onSubmit: (values) => {
      // console.log('values', values)
      const input = { ...values }
      gqlEditSpecifi({
        variables: {
          input
        },
        refetchQueries: [GET_ONE_PRODUCT]
      })
    }
  })

  useEffect(() => {
    if (!hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_EL_PRODUCTO)) {
      navigate('/')
    }
  }, [permissions])

  useEffect(() => {
    if (!id || !Number(id)) {
      navigate('/')
      return
    }
    gqlGetProduct({
      variables: { id: Number(id) }
    })
  }, [id])

  useEffect(() => {
    if (error) {
      toast(`Error: ${error.message}`, {
        autoClose: true,
        position: 'top-right',
        closeOnClick: true,
        type: 'error'
      })
      if (error.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [error])
  useEffect(() => {
    if (errorZones) {
      toast(`Error: ${errorZones.message}`, {
        autoClose: true,
        position: 'top-right',
        closeOnClick: true,
        type: 'error'
      })
      if (errorZones.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorZones])
  useEffect(() => {
    if (errorPrices) {
      toast(`Error: ${errorPrices.message}`, {
        autoClose: true,
        position: 'top-right',
        closeOnClick: true,
        type: 'error'
      })
      if (errorPrices.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorPrices])
  useEffect(() => {
    if (errorDiscounts) {
      toast(`Error: ${errorDiscounts.message}`, {
        autoClose: true,
        position: 'top-right',
        closeOnClick: true,
        type: 'error'
      })
      if (errorDiscounts.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorDiscounts])
  useEffect(() => {
    if (errorActiveInactive) {
      toast(`Error: ${errorActiveInactive.message}`, {
        autoClose: true,
        position: 'top-right',
        closeOnClick: true,
        type: 'error'
      })
      if (errorActiveInactive.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorActiveInactive])

  useEffect(() => {
    if (dataEmpsCom) {
      /** @type {{ 'cursor': string, node: {id: string, name: string}}[]} arr */
      const arr = dataEmpsCom.categoryList.edges
      setInfoCategories(arr.map(({ node: { id, name } }) => ({ id, name })))
    }
  }, [dataEmpsCom])
  useEffect(() => {
    if (dataAgencies) {
      /** @type {{ 'cursor': string, node: {id: string, name: string, zone: {name: string}}}[]} arr */
      const arr = dataAgencies.listAgency.edges
      setInfoAgencies(
        arr.map(({ node: { id, name, zone } }) => ({
          id,
          name,
          zone: zone.name
        }))
      )
      setAgenciesSelect(
        arr.map(({ node: { id, name, zone } }) => ({
          id,
          agency: name,
          zone: zone.name
        }))
      )
      setAgenciesSelectDiscount(
        arr.map(({ node: { id, name, zone } }) => ({
          id,
          agency: name,
          zone: zone.name
        }))
      )
    }
  }, [dataAgencies])

  useEffect(() => {
    if (data && data.listProducts.edges.length > 0) {
      const prod = data.listProducts.edges[0].node
      validation.setValues({
        active: prod.active,
        basePrice: prod.basePrice,
        category: parseId(prod.category.id),
        image: prod.image,
        name: prod.name,
        sku: prod.sku,
        id: parseId(prod.id)
      })

      /** @type {{node: {id: string, name: string, value: string, priceType: string, agency: {id: string, name: string, zone: {id: string, name: string}}}}[]}  */
      const prices = data.listProducts.edges[0].node.priceSet.edges
      const agencies = []
      const zones = []
      prices.forEach(({ node }) => {
        const isZones = zones.includes(node.agency.zone.name)
        if (!isZones) {
          zones.push(node.agency.zone.name)
        }
        const isAgency = agencies.find(
          (zone) => zone.agency === node.agency.name
        )
        if (!isAgency) {
          agencies.push({
            agency: node.agency.name,
            zone: node.agency.zone.name
          })
        }
      })
      /* setZoneSelect(
        zones.map((zone) => ({
          value: zone,
          label: zone
        }))
      ) */
      // setAgenciesSelect(agencies)

      /** @type {{node: {id: string, name: string, value: string, discountType: string, agency: {id: string, name: string, zone: {id: string, name: string}}}}[]}  */
      const discounts = data.listProducts.edges[0].node.discountSet.edges
      const zonesDiscounts = []
      const agenciesDiscounts = []
      discounts.forEach(({ node }) => {
        const isZonesDiscount = zonesDiscounts.includes(node.agency.zone.name)
        if (!isZonesDiscount) {
          zonesDiscounts.push(node.agency.zone.name)
        }

        const isAgency = agenciesDiscounts.find(
          (agency) => agency.agency === node.agency.name
        )
        if (!isAgency) {
          agenciesDiscounts.push({
            agency: node.agency.name,
            zone: node.agency.zone.name
          })
        }
      })
      /* setZoneSelectDiscount(
        zonesDiscounts.map((zone) => ({ value: zone, label: zone }))
      ) */
      // setAgenciesSelectDiscount(agenciesDiscounts)
      // setImgToEdit(prod.image)
      /* fetch(prod.image)
        .then((res) => res.blob())
        .then((blob) => {
          setImgToEdit(URL.createObjectURL(blob))
        }) */
    }
  }, [data])

  useEffect(() => {
    if (dataSpeci) {
      if (dataSpeci.specificationCreate.success) {
        // console.log('dataSpeci', dataSpeci);
        toast.success('Especificaciones agregadas correctamente')
        toggleEspecifi()
        gqlGetProduct({
          variables: { id: Number(id) }
        })
        return
      }

      if (dataSpeci.specificationCreate?.errors) {
        toast.error(
          `ERROR! ${
            dataSpeci.specificationCreate?.errors?.message || 'Algo ocurrió'
          }`
        )
      }
    }
    if (errorCreate) {
      toast.error(`ERROR! ${errorCreate.message}`)
      if (errorCreate.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [dataSpeci, errorCreate])
  useEffect(() => {
    if (dataActiveInactive) {
      if (dataActiveInactive.activeInactiveProduct.success) {
        // console.log('dataSpeci', dataSpeci);
        toast.success('Producto actualizado correctamente')
        setModalDisableProduct(false)
        gqlGetProduct({
          variables: { id: Number(id) }
        })
      } else if (dataActiveInactive.activeInactiveProduct?.errors) {
        toast.error(
          `ERROR! ${
            dataActiveInactive.activeInactiveProduct?.errors?.message ||
            'Algo ocurrió'
          }`
        )
      }
    }
  }, [dataActiveInactive])

  useEffect(() => {
    if (!newPrice.value) {
      setNewPrice((prev) => ({ ...prev, error: 'Precio requerido' }))
    } else if (Number(newPrice.value) < 0) {
      setNewPrice((prev) => ({
        ...prev,
        error: 'El precio debe ser positivo'
      }))
    } else {
      setNewPrice((prev) => ({ ...prev, error: null }))
    }
  }, [newPrice.value])

  useEffect(() => {
    if (!errorCreatePrice) return
    toast.error(`ERROR! ${errorCreatePrice.message}`)
  }, [errorCreatePrice])

  useEffect(() => {
    if (!dataCreatePrice) return

    if (dataCreatePrice.priceCreateUpdate.price?.name) {
      // console.log('dataCreatePrice', dataCreatePrice);
      toast.success('Precio actualizado correctamente')
      setModalPriceProduct(false)
      setNewPrice(INIT_NEW_PRICE)
      refetchPrices()
      /* gqlGetProduct({
        variables: { id: Number(id) }
      }) */
      return
    }

    if (dataCreatePrice.priceCreateUpdate?.errors?.length > 0) {
      toast.error(
        `ERROR! ${
          dataCreatePrice.priceCreateUpdate?.errors[0]?.message ||
          'Algo ocurrió'
        }`
      )
    }
  }, [dataCreatePrice])

  useEffect(() => {
    if (!errorAddDiscount) return
    toast.error(`ERROR! ${errorAddDiscount.message}`)
  }, [errorAddDiscount])

  useEffect(() => {
    if (!dataAddDiscount) return

    if (dataAddDiscount.discountCreateUpdate.discount?.name) {
      // console.log('dataCreatePrice', dataCreatePrice);
      toast.success(
        `Descuento ${isEditDiscout ? 'actualizado' : 'creado'} correctamente`
      )
      setModalAddDiscount(false)
      setIsEditDiscout(null)
      formikDiscount.resetForm()
      if (isEditDiscout) {
        refetchDiscounts()
      } else {
        setAgencyValueSelectDiscount('')
      }
      /* gqlGetProduct({
        variables: { id: Number(id) }
      }) */
      return
    }

    if (dataAddDiscount.discountCreateUpdate?.errors?.length > 0) {
      toast.error(
        `ERROR! ${
          dataAddDiscount.discountCreateUpdate?.errors[0]?.messages[0] ||
          'Algo ocurrió'
        }`
      )
    }
  }, [dataAddDiscount])

  useEffect(() => {
    if (!errorEditSpecifi) return
    toast.error(`ERROR! ${errorEditSpecifi.message}`)
  }, [errorEditSpecifi])

  useEffect(() => {
    if (!dataEditSpecifi) return

    if (dataEditSpecifi.specificationUpdate.success) {
      // console.log('dataEditSpecifi', dataEditSpecifi);
      toast.success('Especificación actualizada correctamente')
      setModalEditSpecifi(false)
      formikEditSpecifi.resetForm()
      /* gqlGetProduct({
        variables: { id: Number(id) }
      }) */
      return
    }

    if (dataEditSpecifi.specificationUpdate?.errors) {
      toast.error(
        `ERROR! ${
          dataEditSpecifi.specificationUpdate?.errors?.message || 'Algo ocurrió'
        }`
      )
    }
  }, [dataEditSpecifi])
  useEffect(() => {
    if (!errorDeleteSpecifi) return
    toast.error(`ERROR! ${errorDeleteSpecifi.message}`)
  }, [errorDeleteSpecifi])

  useEffect(() => {
    if (!dataDeleteSpecifi) return

    if (dataDeleteSpecifi.specificationDelete.success) {
      // console.log('dataDeleteSpecifi', dataDeleteSpecifi);
      toast.success('Especificación eliminada correctamente')
      setModalDeleteSpecifi(false)
      setSpecificationId({ id: '', name: '' })
      /* gqlGetProduct({
        variables: { id: Number(id) }
      }) */
      return
    }

    if (dataDeleteSpecifi.specificationDelete?.errors) {
      toast.error(
        `ERROR! ${
          dataDeleteSpecifi.specificationDelete?.errors?.message ||
          'Algo ocurrió'
        }`
      )
    }
  }, [dataDeleteSpecifi])

  useEffect(() => {
    if (!errorDeleteDisc) return
    toast.error(`ERROR! ${errorDeleteDisc.message}`)
  }, [errorDeleteDisc])

  useEffect(() => {
    if (!dataDeleteDisc) return

    if (dataDeleteDisc.discountDelete.success) {
      // console.log('dataDeleteDisc', dataDeleteDisc);
      toast.success('Descuento eliminado correctamente')
      setModalDeleteDiscount(false)
      setInfoDelete({ id: '', name: '' })
      setAgencyValueSelectDiscount('')
      /* gqlGetProduct({
        variables: { id: Number(id) }
      }) */
      return
    }

    if (dataDeleteDisc.discountDelete?.errors) {
      toast.error(
        `ERROR! ${
          dataDeleteDisc.discountDelete?.errors?.message || 'Algo ocurrió'
        }`
      )
    }
  }, [dataDeleteDisc])

  useEffect(() => {
    if (!dataZones) return
    if (dataZones.listZones.edges.length > 0) {
      setZoneSelect(
        dataZones.listZones.edges.map(({ node }) => ({
          value: node.name,
          label: node.name
        }))
      )
    }
  }, [dataZones])

  const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels)
  }, [])

  const toggle = () => {
    setModal((prev) => !prev)
  }
  const toggleDisableProduct = () => {
    setModalDisableProduct((prev) => !prev)
  }
  const toggleEspecifi = () => {
    setModalEspecifi((prev) => !prev)
  }

  const togglePriceProduct = () => {
    setModalPriceProduct((prev) => !prev)
  }
  const toggleEditSpecifi = () => {
    setModalEditSpecifi((prev) => !prev)
  }
  const toggleDeleteSpecifi = () => {
    setModalDeleteSpecifi((prev) => !prev)
  }

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const toggleDiscount = () => {
    setModalAddDiscount((prev) => !prev)
  }
  const toggleDeleteDiscount = () => {
    setModalDeleteDiscount((p) => !p)
  }
  document.title = 'Producto | TOTAL SALES'

  if (loading || loadingAgencies || loadingZones) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            back='/productos'
            title='Información de producto'
            pageTitle='Productos'
          />
          <Row>
            <Col lg={12} style={{ height: '80vh' }}>
              <div className='d-flex justify-content-center w-100 h-100 align-items-center'>
                <div
                  className='spinner-border text-info'
                  role='status'
                  style={{ width: '5em', height: '5em' }}
                >
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  if (!data) return null
  if (!dataAgencies) return null

  if (data.listProducts.edges.length === 0) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            back='/productos'
            title='Información de producto'
            pageTitle='Productos'
          />
          <Row>
            <Col lg={12} style={{ height: '80vh' }}>
              <div className='d-flex justify-content-center w-100 h-100 align-items-center'>
                <div className='text-center'>
                  <h3>No se encontró producto...</h3>
                  <p className='text-info fs-5'>
                    <Link to='/productos'>
                      <i className='bx bx-arrow-back' />
                      Ver productos
                    </Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  // console.log('data', data)
  /** @type {{ basePrice:string; commercialCompany: {name: string}; created:  Date; id:string; image:string; name:string; sku:string; updated:Date; active: boolean; category: {id:string, name: string}; discountSet:[]; quotationSet:[];}} */
  const product = data.listProducts.edges[0].node
  /** @type {[{node: {id: string, name: string, value: string}}]} */
  const especifications = product.specificationSet.edges

  /** @type {{node: {id: string, name: string, value: string, priceType: string, agency: {id: string, name: string, zone: {id: string, name: string}}}}[]}  */
  // const prices = data.listProducts.edges[0].node.priceSet.edges
  /** @type {{node: {id: string, name: string, value: string, discountType: string, agency: {id: string, name: string, zone: {id: string, name: string}}}}[]}  */
  // const discounts = data.listProducts.edges[0].node.discountSet.edges
  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb
          back='/productos'
          title='Información de producto'
          pageTitle='Productos'
        />

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row className='gx-lg-5'>
                  <Col xl={4} md={8} className='mx-auto'>
                    <div className='product-img-slider sticky-side-div'>
                      <div
                        /* navigation
                        thumbs={
                          thumbsSwiper ? { swiper: thumbsSwiper } : undefined
                        } */
                        className='swiper product-thumbnail-slider p-2 rounded bg-light'
                      >
                        <div className='swiper-wrapper'>
                          <div>
                            <img
                              src={product.image || product8}
                              alt=''
                              className='img-fluid d-block'
                            />
                          </div>
                          {/* <SwiperSlide>
                            <img
                              src={product6}
                              alt=''
                              className='img-fluid d-block'
                            />
                          </SwiperSlide>
                          <SwiperSlide>
                            <img
                              src={product1}
                              alt=''
                              className='img-fluid d-block'
                            />
                          </SwiperSlide>
                          <SwiperSlide>
                            <img
                              src={product8}
                              alt=''
                              className='img-fluid d-block'
                            />
                          </SwiperSlide> */}
                        </div>
                      </div>

                      {/* <div className='product-nav-slider mt-2'>
                        <Swiper
                          onSwiper={setThumbsSwiper}
                          slidesPerView={4}
                          freeMode
                          watchSlidesProgress
                          spaceBetween={10}
                          className='swiper product-nav-slider mt-2 overflow-hidden'
                        >
                          <div className='swiper-wrapper'>
                            <SwiperSlide className='rounded'>
                              <div className='nav-slide-item'>
                                <img
                                  src={product8}
                                  alt=''
                                  className='img-fluid d-block rounded'
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className='nav-slide-item'>
                                <img
                                  src={product6}
                                  alt=''
                                  className='img-fluid d-block rounded'
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className='nav-slide-item'>
                                <img
                                  src={product1}
                                  alt=''
                                  className='img-fluid d-block rounded'
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className='nav-slide-item'>
                                <img
                                  src={product8}
                                  alt=''
                                  className='img-fluid d-block rounded'
                                />
                              </div>
                            </SwiperSlide>
                          </div>
                        </Swiper>
                      </div> */}
                    </div>
                  </Col>

                  <Col xl={8}>
                    <div className='mt-xl-0 mt-5'>
                      <div className='d-flex'>
                        <div className='flex-grow-1'>
                          <h4>{product.name}</h4>
                          <div className='hstack gap-3 flex-wrap'>
                            <div>
                              <Link
                                to='/empresa-comercial'
                                className='text-primary d-block'
                              >
                                {product.commercialCompany.name}
                              </Link>
                            </div>
                            <div className='vr' />
                            <div className='text-muted'>
                              Creado :{' '}
                              <span className='text-body fw-medium'>
                                {moment(product.created).format(
                                  'DD MMM [del] YY'
                                )}
                              </span>
                            </div>
                            <div className='vr' />
                            <div className='text-muted'>
                              Última actualización :{' '}
                              <span className='text-body fw-medium'>
                                {moment(product.created).fromNow()}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className='flex-shrink-0'>
                          {hasPermission(
                            permissions,
                            ALL_PERMISSIONS.PUEDE_CAMBIAR_EL_PRODUCTO
                          ) && (
                            <div>
                              <Tooltip
                                placement='top'
                                isOpen={ttop}
                                target='TooltipTop'
                                toggle={() => {
                                  setttop(!ttop)
                                }}
                              >
                                Editar
                              </Tooltip>
                              <button
                                id='TooltipTop'
                                className='btn btn-light'
                                onClick={() => setModal(true)}
                              >
                                <i className='ri-pencil-fill align-bottom' />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* <div className='d-flex flex-wrap gap-2 align-items-center mt-3'>
                        <div className='text-muted fs-16'>
                          <span className='mdi mdi-star text-warning' />
                          <span className='mdi mdi-star text-warning' />
                          <span className='mdi mdi-star text-warning' />
                          <span className='mdi mdi-star text-warning' />
                          <span className='mdi mdi-star text-warning' />
                        </div>
                        <div className='text-muted'>
                          ( 5.50k Customer Review )
                        </div>
                      </div> */}

                      <Row className='mt-4'>
                        <Col lg={4} sm={6}>
                          <div className='p-2 border border-dashed rounded'>
                            <div className='d-flex align-items-center'>
                              <div className='avatar-sm me-2'>
                                <div className='avatar-title rounded bg-transparent text-info fs-24'>
                                  <i className='ri-money-dollar-circle-fill' />
                                </div>
                              </div>
                              <div className='flex-grow-1'>
                                <p className='text-muted mb-1'>Precio base:</p>
                                <h5 className='mb-0'>
                                  {parsePrice(product.basePrice)}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={4} sm={6}>
                          <div className='p-2 border border-dashed rounded'>
                            <div className='d-flex align-items-center'>
                              <div className='avatar-sm me-2'>
                                <div className='avatar-title rounded bg-transparent text-info fs-24'>
                                  <i className='bx bx-barcode' />
                                </div>
                              </div>
                              <div className='flex-grow-1'>
                                <p className='text-muted mb-1'>SKU:</p>
                                <h5 className='mb-0'>{product.sku}</h5>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={4} sm={6}>
                          <div className='p-2 border border-dashed rounded'>
                            <div className='d-flex align-items-center'>
                              <div className='avatar-sm me-2'>
                                <div className='avatar-title rounded bg-transparent text-info fs-24'>
                                  <i className='bx bx-category' />
                                </div>
                              </div>
                              <div className='flex-grow-1'>
                                <p className='text-muted mb-1'>Categoría:</p>
                                <h5 className='mb-0 fs-6'>
                                  {product.category.name}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={6}>
                          <div className=' mt-4'>
                            <h5 className='fs-14'>Estado:</h5>
                            <div className='d-flex flex-wrap gap-2'>
                              <Tooltip
                                placement='top'
                                target='TooltipSSize'
                                isOpen={ssize}
                                toggle={() => setssize(!ssize)}
                              >
                                {`Este producto ${
                                  product.active ? '' : 'NO'
                                } se encuentra activo`}
                              </Tooltip>
                              <Badge
                                color={product.active ? 'success' : 'warning'}
                                id='TooltipSSize'
                                className='fs-5'
                              >
                                {`${product.active ? '' : 'NO'} ACTIVO`}
                              </Badge>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className='mt-4 text-muted'>
                        <h5 className='fs-14'>Descripción :</h5>
                        <p>N/A.</p>
                      </div>

                      <div>
                        <div className='p-2'>
                          <div className='d-flex align-items-center justify-content-end gap-2'>
                            {hasPermission(
                              permissions,
                              ALL_PERMISSIONS.PUEDE_ANADIR_PRODUCTO
                            ) && (
                              <>
                                <Button
                                  onClick={() => setModalEspecifi(true)}
                                  className='d-flex align-items-center btn-soft-info'
                                >
                                  <i className='ri ri-add-line me-1' />
                                  Añadir especificaciones
                                </Button>
                                <Button
                                  onClick={() => setModalDisableProduct(true)}
                                  className={`d-flex align-items-center btn-soft-${
                                    product.active ? 'danger' : 'success'
                                  }`}
                                >
                                  {product.active ? (
                                    <>
                                      <i className='mdi mdi-barcode-off me-1 fs-5' />
                                      Desactivar
                                    </>
                                  ) : (
                                    <>
                                      <i className='mdi mdi-checkbox-marked-circle-outline me-1 fs-5' />
                                      Activar
                                    </>
                                  )}
                                </Button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* <Row>
                        <Col sm={6}>
                          <div className='mt-3'>
                            <h5 className='fs-14'>Features :</h5>
                            <ul className='list-unstyled'>
                              <li className='py-1'>
                                <i className='mdi mdi-circle-medium me-1 text-muted align-middle' />{' '}
                                Full Sleeve
                              </li>
                              <li className='py-1'>
                                <i className='mdi mdi-circle-medium me-1 text-muted align-middle' />{' '}
                                Cotton
                              </li>
                              <li className='py-1'>
                                <i className='mdi mdi-circle-medium me-1 text-muted align-middle' />{' '}
                                All Sizes available
                              </li>
                              <li className='py-1'>
                                <i className='mdi mdi-circle-medium me-1 text-muted align-middle' />{' '}
                                4 Different Color
                              </li>
                            </ul>
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className='mt-3'>
                            <h5 className='fs-14'>Services :</h5>
                            <ul className='list-unstyled product-desc-list'>
                              <li className='py-1'>10 Days Replacement</li>
                              <li className='py-1'>
                                Cash on Delivery available
                              </li>
                            </ul>
                          </div>
                        </Col>
                      </Row> */}

                      <div className='product-content mt-5'>
                        <h5 className='fs-14 mb-3'>
                          Descripción del producto:
                        </h5>
                        <Nav tabs className='nav-tabs-custom nav-success'>
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({
                                active: customActiveTab === '1'
                              })}
                              onClick={() => {
                                toggleCustom('1')
                              }}
                            >
                              Especificaciones
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({
                                active: customActiveTab === '2'
                              })}
                              onClick={() => {
                                toggleCustom('2')
                              }}
                            >
                              Precios
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({
                                active: customActiveTab === '3'
                              })}
                              onClick={() => {
                                toggleCustom('3')
                              }}
                            >
                              Descuentos
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent
                          activeTab={customActiveTab}
                          className='border border-top-0 p-4'
                          id='nav-tabContent'
                        >
                          <TabPane id='nav-speci' tabId='1'>
                            <div className='table-responsive'>
                              {especifications.length > 0 && (
                                <table className='table mb-0'>
                                  <tbody>
                                    {especifications.map(({ node }) => (
                                      <tr key={node.id}>
                                        <th
                                          scope='row'
                                          style={{ width: '200px' }}
                                        >
                                          {node.name}
                                        </th>
                                        <td>{node.value}</td>
                                        <td className='d-flex gap-2 align-items-center'>
                                          {hasPermission(
                                            permissions,
                                            ALL_PERMISSIONS.PUEDE_CAMBIAR_ESPECIFICACION
                                          ) && (
                                            <Button
                                              outline
                                              className='border-0'
                                              title='Editar'
                                              onClick={() => {
                                                formikEditSpecifi.setValues({
                                                  id: node.id,
                                                  name: node.name,
                                                  value: node.value
                                                })
                                                setModalEditSpecifi(true)
                                              }}
                                            >
                                              <i className='bx bx-pencil' />
                                            </Button>
                                          )}
                                          {hasPermission(
                                            permissions,
                                            ALL_PERMISSIONS.PUEDE_ELIMINAR_ESPECIFICACION
                                          ) && (
                                            <Button
                                              outline
                                              className='border-0'
                                              color='danger'
                                              title='Eliminar'
                                              onClick={() => {
                                                setSpecificationId({
                                                  id: node.id,
                                                  name: node.name
                                                })
                                                setModalDeleteSpecifi(true)
                                              }}
                                            >
                                              <i className='mdi mdi-trash-can-outline' />
                                            </Button>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                              {especifications.length === 0 && (
                                <div
                                  className='d-flex justify-content-center align-items-center'
                                  style={{ height: '300px' }}
                                >
                                  <div>
                                    No hay especificaciones para este producto
                                  </div>
                                </div>
                              )}
                            </div>
                          </TabPane>
                          <TabPane id='nav-detail' tabId='2'>
                            <div>
                              <h5 className='font-size-16 mb-3'>
                                Detalles de precios para {product.name}
                              </h5>
                            </div>
                            <Row>
                              {/* {prices.length > 0 && ( */}
                              <Col lg={12}>
                                <Label>Seleccione zona</Label>
                                <Select
                                  className='basic-single'
                                  classNamePrefix='select'
                                  isClearable
                                  placeholder='Escoja una zona'
                                  options={zoneSelect}
                                  value={zoneSelectValue}
                                  onChange={(e) => {
                                    setZoneSelectValue(e)
                                    setAgencyValueSelect('')
                                  }}
                                  noOptionsMessage={() => (
                                    <span className='text-center p-2'>
                                      No hay precios
                                    </span>
                                  )}
                                />
                              </Col>
                              {/* )} */}

                              {zoneSelectValue && (
                                <Col lg={12} className='mt-3'>
                                  <Label>Seleccione agencia</Label>
                                  <select
                                    className='form-select'
                                    value={agencyValueSelect}
                                    onChange={(e) => {
                                      setAgencyValueSelect(e.target.value)
                                      if (e.target.value === '') return
                                      gqlGetPrices({
                                        variables: {
                                          agency_Id: e.target.value,
                                          product_Id: id
                                        }
                                      })
                                    }}
                                  >
                                    <option value=''>Escoja una agencia</option>
                                    {agenciesSelect
                                      .filter(
                                        (agency) =>
                                          agency.zone === zoneSelectValue.value
                                      )
                                      .map((agency) => (
                                        <option
                                          key={agency.agency}
                                          value={parseId(agency.id)}
                                        >
                                          {agency.agency}
                                        </option>
                                      ))}
                                  </select>
                                </Col>
                              )}

                              <Col lg={12}>
                                <div>
                                  {agencyValueSelect && zoneSelectValue ? (
                                    loadingPrices || !dataPrices ? (
                                      <div
                                        className='d-flex align-items-center justify-content-center'
                                        style={{ height: '20vh' }}
                                      >
                                        <Spinner
                                          style={{
                                            width: '2.4em',
                                            height: '2.4em'
                                          }}
                                          color='dark'
                                        />
                                      </div>
                                    ) : (
                                      <>
                                        <Fade
                                          in={Boolean(
                                            dataPrices.listPrices.edges.length >
                                              0
                                          )}
                                        >
                                          <Table responsive className='mt-2'>
                                            <thead>
                                              <tr>
                                                <th>Nombre</th>
                                                <th>Tipo</th>
                                                <th>Valor</th>
                                                <th>Zona</th>
                                                <th>Agencia</th>
                                                {hasPermission(
                                                  permissions,
                                                  ALL_PERMISSIONS.PUEDE_ANADIR_PRODUCTO
                                                ) && <th />}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {dataPrices.listPrices.edges.map(
                                                ({ node }) => (
                                                  <tr key={node.id}>
                                                    <td>{node.name}</td>
                                                    <td>
                                                      {node.priceType.replace(
                                                        /_/g,
                                                        ' '
                                                      )}
                                                    </td>
                                                    <td>
                                                      {parsePrice(node.value)}
                                                    </td>
                                                    <td>
                                                      {node.agency.zone.name}
                                                    </td>
                                                    <td>{node.agency.name}</td>
                                                    {hasPermission(
                                                      permissions,
                                                      ALL_PERMISSIONS.PUEDE_ANADIR_PRODUCTO
                                                    ) && (
                                                      <td>
                                                        <Button
                                                          outline
                                                          color='dark'
                                                          size='sm'
                                                          className='border-0'
                                                          onClick={() => {
                                                            setNewPrice({
                                                              id: parseId(
                                                                node.id
                                                              ),
                                                              name: node.name,
                                                              value:
                                                                Number(
                                                                  node.value
                                                                ) + '',
                                                              error: null,
                                                              touched: false,
                                                              agency: parseId(
                                                                node.agency.id
                                                              ),
                                                              product: parseId(
                                                                product.id
                                                              ),
                                                              priceType:
                                                                node.priceType.replace(
                                                                  /_/g,
                                                                  ' '
                                                                )
                                                            })
                                                            setModalPriceProduct(
                                                              true
                                                            )
                                                          }}
                                                        >
                                                          <i className='bx bx-pencil' />
                                                        </Button>
                                                      </td>
                                                    )}
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </Table>
                                        </Fade>
                                        {dataPrices?.listPrices?.edges
                                          .length === 0 && (
                                          <div
                                            className='d-flex align-items-center justify-content-center'
                                            style={{ height: '300px' }}
                                          >
                                            <Alert
                                              color='warning'
                                              className='d-flex align-items-center gap-2'
                                            >
                                              <span>
                                                <i className='mdi mdi-currency-usd-off fs-2' />
                                              </span>
                                              No hay precios
                                            </Alert>
                                          </div>
                                        )}
                                      </>
                                    )
                                  ) : (
                                    <div
                                      className='d-flex align-items-center justify-content-center'
                                      style={{ height: '300px' }}
                                    >
                                      <Alert
                                        color='warning'
                                        className='d-flex align-items-center gap-2'
                                      >
                                        <span>
                                          <i className=' bx bx-info-circle fs-4' />
                                        </span>
                                        Seleccione zona y agencia
                                      </Alert>
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane id='nav-detail' tabId='3'>
                            <Row>
                              <Col lg={12}>
                                <Row className='justify-content-between align-items-center mb-3'>
                                  <Col lg={6} sm={12} md={6}>
                                    <h5>Descuentos para {product.name}</h5>
                                  </Col>

                                  {hasPermission(
                                    permissions,
                                    ALL_PERMISSIONS.PUEDE_ANADIR_PRODUCTO
                                  ) && (
                                    <Col
                                      lg={6}
                                      sm={12}
                                      md={6}
                                      className='d-flex justify-content-lg-end justify-content-md-end mt-sm-4'
                                    >
                                      <Button
                                        outline
                                        color='dark'
                                        className='d-flex align-items-center gap-1'
                                        onClick={() => {
                                          setModalAddDiscount(true)
                                          setIsEditDiscout(null)
                                          setZonesCreateDiscount('')
                                        }}
                                      >
                                        <i className='bx bx-plus' />
                                        Agregar descuento
                                      </Button>
                                    </Col>
                                  )}
                                </Row>
                              </Col>
                              {/* {discounts.length > 0 && ( */}
                              <Col lg={12}>
                                <Label>Seleccione zona</Label>
                                <Select
                                  className='basic-single'
                                  classNamePrefix='select'
                                  isClearable
                                  placeholder='Escoja una zona'
                                  options={zoneSelect}
                                  value={zoneSelectDiscountValue}
                                  onChange={(e) => {
                                    setZoneSelectDiscountValue(e)
                                    setAgencyValueSelectDiscount('')
                                  }}
                                  noOptionsMessage={() => (
                                    <span className='text-center p-2'>
                                      No hay descuentos
                                    </span>
                                  )}
                                  // value={agencyValueSelect}
                                  // onChange={setAgencyValueSelect}
                                />
                              </Col>
                              {/* )} */}
                              {zoneSelectDiscountValue && (
                                <Col lg={12} className='mt-3'>
                                  <Label htmlFor='select-agency-discounts'>
                                    Seleccione agencia
                                  </Label>
                                  <select
                                    id='select-agency-discounts'
                                    className='form-select'
                                    value={agencyValueSelectDiscount}
                                    onChange={(e) => {
                                      setAgencyValueSelectDiscount(
                                        e.target.value
                                      )
                                      if (e.target.value === '') return
                                      gqlGetDiscounts({
                                        variables: {
                                          agency_Id: e.target.value,
                                          product_Id: id
                                        }
                                      })
                                    }}
                                  >
                                    <option value=''>Escoja una agencia</option>
                                    {agenciesSelectDiscount
                                      .filter(
                                        (info) =>
                                          info.zone ===
                                          zoneSelectDiscountValue.value
                                      )
                                      .map((info) => (
                                        <option
                                          key={info.agency}
                                          value={parseId(info.id)}
                                        >
                                          {info.agency}
                                        </option>
                                      ))}
                                  </select>
                                </Col>
                              )}
                              <Col lg={12}>
                                {/* <h5 className='font-size-16 mb-3'>Descuentos</h5> */}
                                {agencyValueSelectDiscount ? (
                                  loadingDiscounts || !dataDiscounts ? (
                                    <div
                                      className='d-flex align-items-center justify-content-center'
                                      style={{ height: '20vh' }}
                                    >
                                      <Spinner
                                        style={{
                                          width: '2.4em',
                                          height: '2.4em'
                                        }}
                                        color='dark'
                                      />
                                    </div>
                                  ) : (
                                    <>
                                      <Fade
                                        in={Boolean(
                                          dataDiscounts.listDiscounts.edges
                                            .length > 0
                                        )}
                                      >
                                        <Table responsive>
                                          <thead>
                                            <tr>
                                              <th>Nombre</th>
                                              <th>Tipo</th>
                                              <th>Valor</th>
                                              <th>Agencia</th>
                                              {hasPermission(
                                                permissions,
                                                ALL_PERMISSIONS.PUEDE_ANADIR_PRODUCTO
                                              ) && <th />}
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {dataDiscounts.listDiscounts.edges.map(
                                              ({ node }) => (
                                                <tr key={node.id}>
                                                  <td>{node.name}</td>
                                                  <td>
                                                    {node.discountType.replace(
                                                      /_/g,
                                                      ' '
                                                    )}
                                                  </td>
                                                  <td>
                                                    {parsePrice(node.value)}
                                                  </td>
                                                  <td>{node.agency.name}</td>
                                                  {hasPermission(
                                                    permissions,
                                                    ALL_PERMISSIONS.PUEDE_ANADIR_PRODUCTO
                                                  ) && (
                                                    <td className='d-flex justify-content-evenly'>
                                                      <Button
                                                        outline
                                                        color='dark'
                                                        size='sm'
                                                        className='border-0'
                                                        onClick={() => {
                                                          setIsEditDiscout(
                                                            parseId(node.id)
                                                          )
                                                          formikDiscount.setValues(
                                                            {
                                                              agency:
                                                                parseId(
                                                                  node.agency.id
                                                                ) + '',
                                                              discountType:
                                                                node.discountType,
                                                              name: node.name,
                                                              value: node.value
                                                            }
                                                          )
                                                          setModalAddDiscount(
                                                            true
                                                          )
                                                          setZonesCreateDiscount(
                                                            node.agency.zone
                                                              .name
                                                          )
                                                        }}
                                                      >
                                                        <i className='bx bx-pencil' />
                                                      </Button>
                                                      <Button
                                                        outline
                                                        color='danger'
                                                        size='sm'
                                                        className='border-0'
                                                        title='Eliminar'
                                                        onClick={() => {
                                                          setModalDeleteDiscount(
                                                            true
                                                          )
                                                          setInfoDelete({
                                                            id: parseId(
                                                              node.id
                                                            ),
                                                            name: node.name
                                                          })
                                                        }}
                                                      >
                                                        <i className='mdi mdi-trash-can-outline' />
                                                      </Button>
                                                    </td>
                                                  )}
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </Table>
                                      </Fade>
                                      {dataDiscounts.listDiscounts.edges
                                        .length === 0 && (
                                        <div
                                          className='d-flex align-items-center justify-content-center'
                                          style={{ height: '300px' }}
                                        >
                                          <Alert
                                            color='warning'
                                            className='d-flex align-items-center gap-2'
                                          >
                                            <span>
                                              <i className='mdi mdi-cash-minus fs-2' />
                                            </span>
                                            No hay descuentos
                                          </Alert>
                                        </div>
                                      )}
                                    </>
                                  )
                                ) : (
                                  <div
                                    className='d-flex align-items-center justify-content-center'
                                    style={{ height: '300px' }}
                                  >
                                    <Alert
                                      color='warning'
                                      className='d-flex align-items-center gap-2'
                                    >
                                      <span>
                                        <i className=' bx bx-info-circle fs-4' />
                                      </span>
                                      Seleccione una agencia
                                    </Alert>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal id='showModal' isOpen={modal} toggle={toggle} centered size='lg'>
        <ModalHeader className='bg-soft-info p-3' toggle={toggle}>
          Editar producto
        </ModalHeader>
        <Form className='tablelist-form' onSubmit={validation.handleSubmit}>
          <ModalBody>
            <input type='hidden' id='id-field' />
            <Row className='g-3'>
              {imgToEdit ? (
                <Col
                  lg={12}
                  style={{ height: '250px', backgroundColor: '#878787' }}
                  className='relative'
                >
                  <div
                    style={{ zIndex: 10 }}
                    className='position-absolute top-0 start-0'
                    onClick={() => {
                      // console.log('clik')
                      setImgToEdit(null)

                      validation.setFieldValue('image', null)
                      if (inputRef.current) {
                        inputRef.current.value = null
                      }
                    }}
                  >
                    <div className='mb-0'>
                      <div className='avatar-xs cursor-pointer'>
                        <div className='avatar-title bg-transparent text-light'>
                          {/* <i className='ri-image-fill' /> */}
                          <i
                            className='las la-times-circle'
                            style={{ fontSize: '24px' }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Cropper
                    image={imgToEdit}
                    crop={crop}
                    zoom={zoom}
                    aspect={1 / 1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </Col>
              ) : (
                <Col lg={12}>
                  <div className='text-center'>
                    <div className='position-relative d-inline-block'>
                      <div className='position-absolute bottom-0 end-0'>
                        <Label htmlFor='image' className='mb-0'>
                          <div className='avatar-xs cursor-pointer'>
                            <div className='avatar-title bg-light border rounded-circle text-muted'>
                              <i className='ri-image-fill' />
                            </div>
                          </div>
                        </Label>
                        <input
                          ref={inputRef}
                          name='image'
                          className='form-control d-none'
                          id='image'
                          type='file'
                          accept='image/png, image/gif, image/jpeg'
                          onChange={(e) => {
                            validation.setFieldValue(
                              'image',
                              e.currentTarget.files[0]
                            )
                            // eslint-disable-next-line no-undef
                            const reader = new FileReader()
                            reader.readAsDataURL(e.currentTarget.files[0])
                            reader.addEventListener('load', () => {
                              setImgToEdit(reader.result)
                            })
                          }}
                          // value={validation.values.image?.name || ''}
                        />
                      </div>
                      <div className='avatar-lg p-1'>
                        <div className='avatar-title bg-light rounded-circle'>
                          <img
                            src={
                              validation.values.image ||
                              'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/681px-Placeholder_view_vector.svg.png'
                            }
                            alt='multiUser'
                            id='productlogo-img'
                            className='avatar-md rounded-circle object-cover'
                          />
                        </div>
                      </div>
                    </div>
                    <h5 className='fs-13 mt-3'>Imagen del producto</h5>
                  </div>
                </Col>
              )}

              <Col lg={12}>
                <div>
                  <Label htmlFor='name-field' className='form-label'>
                    Nombre de producto
                  </Label>

                  <Input
                    name='name'
                    id='customername-field'
                    className='form-control'
                    placeholder='Nombre del producto'
                    type='text'
                    validate={{
                      required: { value: true }
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ''}
                    invalid={
                      !!(validation.touched.name && validation.errors.name)
                    }
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type='invalid'>
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col lg={12}>
                <div>
                  <Label htmlFor='category-field' className='form-label'>
                    Categoría
                  </Label>

                  <Input
                    name='category'
                    disabled
                    style={{ cursor: 'not-allowed' }}
                    type='select'
                    className='form-select'
                    id='category-field'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.category}
                  >
                    <option value=''>Seleccione una cateogría</option>
                    {infoCategories.map(({ name, id }) => (
                      <option value={parseId(id)} key={id}>
                        {name}
                      </option>
                    ))}
                  </Input>
                  {validation.touched.category && validation.errors.category ? (
                    <FormFeedback type='invalid'>
                      {validation.errors.category}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col lg={6}>
                <div>
                  <Label htmlFor='sku-field' className='form-label'>
                    SKU
                  </Label>
                  <Input
                    name='sku'
                    id='sku-field'
                    className='form-control'
                    placeholder='Ingrese SKU de producto'
                    type='text'
                    disabled
                    value={validation.values.sku}
                    onChange={validation.handleChange}
                    style={{
                      cursor: 'not-allowed'
                    }}
                  />
                  {validation.touched.sku && validation.errors.sku ? (
                    <FormFeedback type='invalid'>
                      {validation.errors.sku}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <Label htmlFor='basePrice' className='form-label'>
                    Precio
                  </Label>
                  <InputCurrency
                    id='basePrice'
                    name='basePrice'
                    placeholder='Precio base'
                    className={`form-control ${
                      validation.touched.basePrice &&
                      validation.errors.basePrice
                        ? 'is-invalid'
                        : ''
                    } `}
                    disabled={
                      !hasPermission(
                        permissions,
                        ALL_PERMISSIONS.PUEDE_CAMBIAR_PERCIO_BASE
                      )
                    }
                    // prefix='$ '
                    value={validation.values.basePrice}
                    // decimalsLimit={0}
                    // decimalSeparator=','
                    // groupSeparator='.'
                    onValueChange={(value, name) => {
                      validation.setFieldValue(name, value)
                    }}
                    // allowNegativeValue={false}
                    onBlur={() => validation.setFieldTouched('basePrice', true)}
                  />
                  {validation.touched.basePrice &&
                    validation.errors.basePrice && (
                      <div
                        style={{
                          width: '100%',
                          marginTop: '0.25rem',
                          fontSize: '0.875em',
                          color: '#f06548'
                        }}
                      >
                        {validation.errors.basePrice}
                      </div>
                    )}
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className='hstack gap-2 justify-content-end'>
              <Button
                color='light'
                onClick={() => {
                  setModal(false)
                  setImgToEdit(null)
                }}
              >
                Cancelar{' '}
              </Button>
              <Button
                type='submit'
                color='success'
                id='add-btn'
                disabled={loadingCreate}
              >
                {loadingCreate && (
                  <div
                    className='spinner-border me-2'
                    style={{ width: '16px', height: '16px' }}
                    role='status'
                  >
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                )}
                Actualiza{loadingCreate ? 'ando' : 'r'}
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        id='showModalEspecificaciones'
        isOpen={modalEspecifi}
        toggle={toggleEspecifi}
        centered
        size='lg'
      >
        <ModalHeader className='bg-soft-info p-3' toggle={toggleEspecifi}>
          Agregar especificaciones
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const specifications = values.fields.map((field) => ({
                name: field.key,
                value: field.value
              }))
              const variables = {
                specifications,
                productId: id
              }
              addSpecifications({
                variables,
                refetchQueries: [
                  {
                    query: GET_ONE_PRODUCT
                  }
                ]
              })
              // console.log('variables', variables)
            }}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur
            }) => (
              <Form onSubmit={handleSubmit}>
                <FieldArray name='fields'>
                  {({ push, remove }) => (
                    <>
                      {values.fields.map((field, index) => (
                        <FormGroup key={index}>
                          <Row>
                            <Col lg={5} className='order-lg-1 order-2 mt-2'>
                              <Label>Nombre</Label>
                              <Input
                                name={`fields[${index}].key`}
                                placeholder='Ingrese descripción'
                                value={field.key}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.fields?.[index]?.key &&
                                  !!errors.fields?.[index]?.key
                                }
                              />
                              <ErrorMessage
                                name={`fields[${index}].key`}
                                component='div'
                                className='text-danger'
                              />
                            </Col>
                            <Col lg={6} className='order-lg-2 order-3 mt-2'>
                              <Label>Valor</Label>
                              <Input
                                name={`fields[${index}].value`}
                                placeholder='Ingrese valor'
                                className={`form-control ${
                                  touched.fields?.[index]?.value &&
                                  !!errors.fields?.[index]?.value
                                    ? 'is-invalid'
                                    : ''
                                } `}
                                value={field.value}
                                /* onValueChange={(e) => {
                                  // console.log('e', e)
                                  handleChange(`fields[${index}].value`)(
                                    e || ''
                                  )
                                }} */
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage
                                name={`fields[${index}].value`}
                                component='div'
                                className='text-danger'
                              />
                            </Col>
                            <Col
                              lg={1}
                              className='d-flex align-items-center justify-content-end order-lg-3 order-1'
                            >
                              <button
                                onClick={() => remove(index)}
                                className='border-0 text-danger block'
                                style={{ backgroundColor: 'transparent' }}
                                title='Elimina este campo'
                                type='button'
                              >
                                <i
                                  className='ri-close-line fs-3'
                                  style={{
                                    ':hover': {
                                      color: 'blue'
                                    }
                                  }}
                                />
                              </button>
                            </Col>
                          </Row>
                        </FormGroup>
                      ))}
                      <Button
                        color='primary'
                        onClick={() => push({ key: '', value: '' })}
                      >
                        Agregar campo
                      </Button>
                    </>
                  )}
                </FieldArray>
                <Col lg={12} className='d-flex justify-content-end gap-4 mt-4'>
                  <Button
                    color='light'
                    type='submit'
                    onClick={() => setModalEspecifi(false)}
                  >
                    Cancelar
                  </Button>
                  <Button
                    color='success'
                    type='submit'
                    disabled={loadingSpeci || values.fields.length === 0}
                  >
                    {loadingSpeci && (
                      <div
                        className='spinner-border me-2'
                        style={{ width: '16px', height: '16px' }}
                        role='status'
                      >
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    )}
                    Guarda{loadingSpeci ? 'ndo' : 'r'}
                  </Button>
                </Col>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>

      <Modal
        id='showModal'
        isOpen={modalDisableProduct}
        toggle={toggleDisableProduct}
        centered
        size='sm'
      >
        <ModalBody>
          <h5>
            ¿Seguro que desea {product.active ? 'desactivar' : 'activar'} este
            producto?
          </h5>
          <div className='w-100 d-flex align-items-center justify-content-center gap-3 mt-4'>
            <Button
              color='light px-5'
              onClick={() => setModalDisableProduct(false)}
            >
              No
            </Button>
            <Button
              color='danger px-5'
              onClick={() => {
                gqlActiveInactiveProduct({
                  variables: {
                    input: {
                      productId: Number(id),
                      active: !product.active
                    }
                  }
                })
              }}
              disabled={loadingActiveInactive}
            >
              {loadingActiveInactive ? (
                <Spinner size='sm' color='white' />
              ) : (
                'Si'
              )}
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        id='showModalPrice'
        isOpen={modalPriceProduct}
        toggle={togglePriceProduct}
        centered
        size='sm'
        onOpened={() => document.getElementById('inputPrice').focus()}
      >
        <ModalHeader toggle={togglePriceProduct}>Cambiar precio</ModalHeader>

        <ModalBody>
          <Row>
            <Col lg={12}>
              <Label>Nuevo valor para {newPrice.name}</Label>
              <InputCurrency
                id='inputPrice'
                placeholder='Ingrese valor'
                // decimalsLimit={0}
                // decimalSeparator=','
                // groupSeparator='.'
                className={`form-control ${
                  newPrice.touched && newPrice.error ? 'is-invalid' : ''
                } `}
                // prefix='$ '
                value={newPrice.value}
                onValueChange={(value) => {
                  setNewPrice((prev) => ({
                    ...prev,
                    value: value || ''
                  }))
                }}
                onBlur={() => {
                  setNewPrice((prev) => ({
                    ...prev,
                    touched: true
                  }))
                }}
                // autoFocus
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div className='d-flex gap-2 align-items-center'>
            <Button
              onClick={() => {
                setNewPrice(INIT_NEW_PRICE)
                setModalPriceProduct(false)
              }}
              color='light'
            >
              Cancelar
            </Button>
            <Button
              color='success'
              disabled={Boolean(newPrice.error) || loadingCreatePrice}
              className='d-flex gap-1 align-items-center'
              onClick={() => {
                // console.log('newPrice', newPrice)
                const input = { ...newPrice, value: Number(newPrice.value) }
                delete input.error
                delete input.touched

                // console.log('input', input)
                gqlAddPrices({
                  variables: {
                    input
                  },
                  refetchQueries: [GET_ONE_PRODUCT]
                })
              }}
            >
              {loadingCreatePrice && <Spinner size='sm' color='light' />}
              Guardar
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      <Modal
        id='showModal'
        isOpen={modalAddDiscount}
        toggle={toggleDiscount}
        centered
        size='lg'
      >
        <ModalHeader className='bg-soft-info p-3' toggle={toggleDiscount}>
          {isEditDiscout ? 'Editar descuento' : 'Agregar descuento'}
        </ModalHeader>
        <Form className='tablelist-form' onSubmit={formikDiscount.handleSubmit}>
          <ModalBody>
            <Row className='g-3'>
              <Col lg={6} sm={12} xs={12}>
                <div>
                  <Label htmlFor='category-field-age' className='form-label'>
                    Zona
                  </Label>
                  <select
                    className='form-select'
                    value={zonesCreateDiscount}
                    onChange={(e) => {
                      setZonesCreateDiscount(e.target.value)
                      formikDiscount.setFieldValue('agency', '')
                    }}
                  >
                    <option value=''>Seleccione una zona</option>
                    {dataZones?.listZones.edges
                      /* .filter(({ node: item }, index) => {
                        return (
                          dataAgencies.listAgency.edges.findIndex(
                            ({ node: obj }) => obj.zone.name === item.zone.name
                          ) === index
                        )
                      }) */
                      .map(({ node }) => (
                        <option key={node.id} value={node.name}>
                          {node.name}
                        </option>
                      ))}
                  </select>
                </div>
              </Col>
              <Col lg={6} sm={12} xs={12}>
                <div>
                  <Label htmlFor='category-field-age' className='form-label'>
                    Agencia
                  </Label>

                  {zonesCreateDiscount ? (
                    <Input
                      name='agency'
                      type='select'
                      className='form-select'
                      id='agency-field-age'
                      onChange={formikDiscount.handleChange}
                      onBlur={formikDiscount.handleBlur}
                      value={formikDiscount.values.agency}
                      invalid={
                        !!(
                          formikDiscount.touched.agency &&
                          formikDiscount.errors.agency
                        )
                      }
                    >
                      <option value=''>Seleccione una agencia</option>
                      {infoAgencies
                        .filter(({ zone }) => zone === zonesCreateDiscount)
                        .map(({ name, id }) => (
                          <option value={parseId(id)} key={id}>
                            {name}
                          </option>
                        ))}
                    </Input>
                  ) : (
                    <Input
                      name='agency'
                      type='select'
                      className='form-select'
                      id='agency-field-age'
                      invalid={
                        !!(
                          formikDiscount.touched.agency &&
                          formikDiscount.errors.agency
                        )
                      }
                    >
                      <option value=''>Debe seleccionar una zona</option>
                    </Input>
                  )}
                  {formikDiscount.touched.agency &&
                  formikDiscount.errors.agency ? (
                    <FormFeedback type='invalid'>
                      {formikDiscount.errors.agency}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col lg={12}>
                <div>
                  <Label
                    htmlFor='discountType-field-age'
                    className='form-label'
                  >
                    Tipo de descuento
                  </Label>

                  <Input
                    name='discountType'
                    type='select'
                    className='form-select'
                    id='discountType-field-age'
                    onChange={formikDiscount.handleChange}
                    onBlur={formikDiscount.handleBlur}
                    value={formikDiscount.values.discountType}
                    invalid={
                      !!(
                        formikDiscount.touched.discountType &&
                        formikDiscount.errors.discountType
                      )
                    }
                  >
                    <option value=''>Seleccione el tipo de descuento</option>
                    {DISCOUNTS_TYPES.map((type) => (
                      <option value={type} key={type}>
                        {type}
                      </option>
                    ))}
                  </Input>
                  {formikDiscount.touched.discountType &&
                    formikDiscount.errors.discountType && (
                      <FormFeedback type='invalid'>
                        {formikDiscount.errors.discountType}
                      </FormFeedback>
                    )}
                </div>
              </Col>

              <Col lg={6} sm={12}>
                <div>
                  <Label htmlFor='name-field' className='form-label'>
                    Nombre del descuento
                  </Label>

                  <Input
                    name='name'
                    id='nameDiscount-field'
                    className='form-control'
                    placeholder='Nombre del descuento'
                    type='text'
                    validate={{
                      required: { value: true }
                    }}
                    onChange={formikDiscount.handleChange}
                    onBlur={formikDiscount.handleBlur}
                    value={formikDiscount.values.name}
                    invalid={
                      !!(
                        formikDiscount.touched.name &&
                        formikDiscount.errors.name
                      )
                    }
                  />
                  {formikDiscount.touched.name && formikDiscount.errors.name ? (
                    <FormFeedback type='invalid'>
                      {formikDiscount.errors.name}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6} sm={12}>
                <div>
                  <Label htmlFor='value' className='form-label'>
                    Precio
                  </Label>
                  <InputCurrency
                    id='value'
                    name='value'
                    placeholder='Precio base'
                    className={`form-control ${
                      formikDiscount.touched.value &&
                      formikDiscount.errors.value
                        ? 'is-invalid'
                        : ''
                    } `}
                    prefix='$ '
                    value={formikDiscount.values.value}
                    // decimalsLimit={0}
                    // decimalSeparator=','
                    // groupSeparator='.'
                    onValueChange={(value, name) => {
                      formikDiscount.setFieldValue(name, value || '')
                    }}
                    // allowNegativeValue={false}
                    onBlur={formikDiscount.handleBlur}
                  />
                  {formikDiscount.touched.value &&
                    formikDiscount.errors.value && (
                      <div
                        style={{
                          width: '100%',
                          marginTop: '0.25rem',
                          fontSize: '0.875em',
                          color: '#f06548'
                        }}
                      >
                        {formikDiscount.errors.value}
                      </div>
                    )}
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className='hstack gap-2 justify-content-end'>
              <Button
                color='light'
                onClick={() => {
                  setModalAddDiscount(false)
                  formikDiscount.resetForm()
                  setIsEditDiscout(null)
                  setZonesCreateDiscount('')
                }}
              >
                Cancelar{' '}
              </Button>
              <Button
                type='submit'
                color='success'
                id='add-btn'
                disabled={loadingAddDiscount}
                className='d-flex align-items-center gap-1'
              >
                {loadingAddDiscount && <Spinner size='sm' color='light' />}
                {isEditDiscout ? 'Editar' : 'Crear'}
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal
        id='showModal'
        isOpen={modalEditSpecifi}
        toggle={toggleEditSpecifi}
        centered
        size='lg'
      >
        <ModalHeader className='bg-soft-info p-3' toggle={toggleEditSpecifi}>
          Editar especificación
        </ModalHeader>
        <Form
          className='tablelist-form'
          onSubmit={formikEditSpecifi.handleSubmit}
        >
          <ModalBody>
            <Row className='g-3'>
              <Col lg={6} sm={12}>
                <div>
                  <Label htmlFor='name-field-specifi' className='form-label'>
                    Nombre
                  </Label>

                  <Input
                    name='name'
                    id='name-field-specifi'
                    className='form-control'
                    placeholder='Nombre del descuento'
                    type='text'
                    validate={{
                      required: { value: true }
                    }}
                    onChange={formikEditSpecifi.handleChange}
                    onBlur={formikEditSpecifi.handleBlur}
                    value={formikEditSpecifi.values.name}
                    invalid={
                      !!(
                        formikEditSpecifi.touched.name &&
                        formikEditSpecifi.errors.name
                      )
                    }
                  />
                  {formikEditSpecifi.touched.name &&
                  formikEditSpecifi.errors.name ? (
                    <FormFeedback type='invalid'>
                      {formikEditSpecifi.errors.name}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6} sm={12}>
                <div>
                  <Label htmlFor='value-field-specifi' className='form-label'>
                    Valor
                  </Label>

                  <Input
                    name='value'
                    id='value-field-specifi'
                    className='form-control'
                    placeholder='Nombre del descuento'
                    type='text'
                    validate={{
                      required: { value: true }
                    }}
                    onChange={formikEditSpecifi.handleChange}
                    onBlur={formikEditSpecifi.handleBlur}
                    value={formikEditSpecifi.values.value}
                    invalid={
                      !!(
                        formikEditSpecifi.touched.value &&
                        formikEditSpecifi.errors.value
                      )
                    }
                  />
                  {formikEditSpecifi.touched.value &&
                    formikEditSpecifi.errors.value && (
                      <FormFeedback type='invalid'>
                        {formikEditSpecifi.errors.value}
                      </FormFeedback>
                    )}
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className='hstack gap-2 justify-content-end'>
              <Button
                color='light'
                onClick={() => {
                  setModalEditSpecifi(false)
                  formikEditSpecifi.resetForm()
                }}
              >
                Cancelar
              </Button>
              <Button
                type='submit'
                color='success'
                id='add-btn'
                disabled={loadingEditSpecifi}
                className='d-flex align-items-center gap-1'
              >
                {loadingEditSpecifi && <Spinner size='sm' color='light' />}
                Editar
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        id='deleteSpecifi'
        isOpen={modalDeleteSpecifi}
        toggle={toggleDeleteSpecifi}
        centered
        size='md'
      >
        <ModalHeader
          className='bg-soft-danger p-4'
          toggle={toggleDeleteSpecifi}
        >
          ¿Seguro que desea eliminar esta especificación?
        </ModalHeader>
        <ModalBody>
          <p>
            Esta acción eliminará la especificación:{' '}
            <span className='text-uppercase text-danger fw-bold'>
              {specificationId.name}
            </span>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color='light px-5'
            onClick={() => {
              setModalDeleteSpecifi(false)
              setSpecificationId({ id: '', name: '' })
            }}
          >
            No
          </Button>
          <Button
            color='danger px-5'
            onClick={() => {
              if (!specificationId.id) return
              gqlDeleteSpecifi({
                variables: {
                  specificationId: specificationId.id
                },
                refetchQueries: [GET_ONE_PRODUCT]
              })
            }}
            disabled={loadingDeleteSpecifi}
            className='d-flex align-items-center gap-1'
          >
            {loadingDeleteSpecifi ? (
              <Spinner size='sm' color='white' />
            ) : (
              <>
                <i className='bx bx-error fs-4' /> SI
              </>
            )}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        id='deleteSpecifi'
        isOpen={modalDeleteDiscount}
        toggle={toggleDeleteDiscount}
        centered
        size='md'
      >
        <ModalHeader
          className='bg-soft-danger p-4'
          toggle={toggleDeleteDiscount}
        >
          ¿Seguro que desea eliminar este descuento?
        </ModalHeader>
        <ModalBody>
          <p>
            Esta acción eliminará el descuento:{' '}
            <span className='text-uppercase text-danger fw-bold'>
              {infoDelete.name}
            </span>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color='light px-5'
            onClick={() => {
              setModalDeleteDiscount(false)
              setInfoDelete({ id: '', name: '' })
            }}
          >
            No
          </Button>
          <Button
            color='danger px-5'
            onClick={() => {
              if (!infoDelete.id) return
              gqlDeleteDisc({
                variables: {
                  discountId: infoDelete.id
                },
                refetchQueries: [GET_ONE_PRODUCT]
              })
            }}
            disabled={loadingDeleteDisc}
            className='d-flex align-items-center gap-1'
          >
            {loadingDeleteDisc ? (
              <Spinner size='sm' color='white' />
            ) : (
              <>
                <i className='bx bx-error fs-4' /> SI
              </>
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default OneProduct
