import { gql } from '@apollo/client'

export const GET_ALL_COTIZACIONES_ALIADOS = gql`
  query listAllyQuote(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id: Float
    $status_Iexact: String
    $documentNumber: String
    $agency_Id: Float
    $name: String
  ) {
    listAllyQuote(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      id: $id
      status_Iexact: $status_Iexact
      documentNumber: $documentNumber
      agency_Id: $agency_Id
      name: $name
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          created
          firstName
          secondName
          firstSurname
          secondSurname
          initialFee
          monthlyFee
          status
          productValue
          product
          phoneNumber
          agency {
            id
            name
          }
        }
      }
    }
  }
`

export const GET_ONE_COTIZACION_ALIADOS = gql`
  query listAllyQuote(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id: Float
    $status_Iexact: String
    $documentNumber: String
    $agency_Id: Float
    $name: String
  ) {
    listAllyQuote(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      id: $id
      status_Iexact: $status_Iexact
      documentNumber: $documentNumber
      agency_Id: $agency_Id
      name: $name
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          created
          firstName
          secondName
          firstSurname
          secondSurname
          initialFee
          monthlyFee
          status
          productValue
          product
          phoneNumber
          user {
            firstName
            lastName
            profileuser {
              phoneNumber
            }
          }
          credit {
            id
            status
          }
          agency {
            id
            name
            city
          }
          paymentPlan
        }
      }
    }
  }
`
export const GET_QUOTATION_ALIADO_TO_CREDIT = gql`
  query listAllyQuote($id: Float) {
    listAllyQuote(id: $id) {
      edges {
        node {
          id
          created
          firstName
          secondName
          firstSurname
          secondSurname
          initialFee
          monthlyFee
          status
          productValue
          product
          phoneNumber
          paymentPlan
        }
      }
    }
  }
`
