import React from 'react'
import {
  Badge,
  Button,
  Card,
  CardBody,
  Row,
  TabPane,
  UncontrolledCollapse
} from 'reactstrap'
import { getColorBg, getIcon, parseId } from '../../../helpers'
import moment from 'moment'
import { ICONS_STATUS, STATUS_CREDITS_OBJ } from '../../../helpers/consts'

/**
 *
 * @param {{comments: {node: {user: { firstName: string; lastName: string; email: string} oldStatus: string; newStatus: string; created: string; comment: string; id: string; supportSet: { edges: { node: { file: string; name: string; id: string; deleted: boolean }}[] } }}[], setShowComment: ()=>void, handleClickDown: ()=>void}} param0
 * @returns
 */

export const TabComments = ({ comments, setShowComment, handleClickDown }) => {
  const getBadges = (status) => {
    const [icon, color] = ICONS_STATUS[status] || [
      'mdi-information-outline',
      'dark'
    ]
    return (
      <Badge color={color} className='d-flex algin-items-center gap-1'>
        <i className={`mdi ${icon}`} />
        {STATUS_CREDITS_OBJ[status] || status}
      </Badge>
    )
  }
  return (
    <TabPane tabId='2'>
      <Card>
        <CardBody>
          <div className='d-flex justify-content-between align-items-center flex-column flex-md-row gap-2'>
            <h5 className='card-title mb-3'>Comentarios</h5>
            <Button
              className='d-flex align-items-center gap-1'
              onClick={() => setShowComment(true)}
            >
              <i className='mdi mdi-comment-plus-outline' />
              Añadir comentario
            </Button>
          </div>
          <div className='profile-timeline'>
            <div />
            <div className='accordion accordion-flush' id='todayExample'>
              {comments.map(({ node: nodeComment }) => (
                <div className='accordion-item border-0' key={nodeComment.id}>
                  <div className='accordion-header' id='headingTwo'>
                    <button
                      className='accordion-button p-2 shadow-none'
                      id={`collapseTwo-${parseId(nodeComment.id)}`}
                    >
                      <div className='d-flex'>
                        <div className='flex-shrink-0 avatar-xs'>
                          <div className='avatar-title bg-light text-success rounded-circle'>
                            {nodeComment.user.firstName.charAt(0)}
                          </div>
                        </div>
                        <div className='flex-grow-1 ms-3'>
                          <div className='d-flex flex-column flex-md-row align-items-md-center align-items-start gap-2'>
                            <h6 className='fs-14 mb-0'>
                              {nodeComment.user.firstName}{' '}
                              {nodeComment.user.lastName}
                            </h6>
                            {nodeComment.oldStatus && (
                              <div className='d-flex align-items-center gap-1'>
                                {getBadges(nodeComment.oldStatus)}{' '}
                                {nodeComment.newStatus && (
                                  <>
                                    <i className='mdi mdi-arrow-right-thick fs-3' />{' '}
                                    {getBadges(nodeComment.newStatus)}
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                          <small
                            className='text-muted'
                            title={`Este comentario se realizó el ${moment(
                              nodeComment.created
                            ).format('DD MMMM [del] YYYY')} a las ${moment(
                              nodeComment.created
                            ).format('HH:mm A')}`}
                          >
                            {moment(nodeComment.created).format('DD MMMM')} -{' '}
                            {moment(nodeComment.created).format('HH:mm A')}
                          </small>
                        </div>
                      </div>
                    </button>
                  </div>
                  <UncontrolledCollapse
                    toggler={`collapseTwo-${parseId(nodeComment.id)}`}
                    defaultOpen
                  >
                    <div className='accordion-body ms-2 ps-5'>
                      <div className='mb-2 fs-6' style={{ color: '#111' }}>
                        {nodeComment.comment}
                      </div>
                      {nodeComment.supportSet.edges.length > 0 && (
                        <Row className='g-2'>
                          {nodeComment.supportSet.edges.map(
                            ({ node: { file, id, name, deleted } }) => (
                              <div key={id} className='col-6'>
                                <div className='d-flex border border-dashed p-2 rounded position-relative'>
                                  <div
                                    className='flex-shrink-0'
                                    style={{ margin: 'auto' }}
                                  >
                                    <i
                                      className={`mdi ${getIcon(
                                        file
                                      )} fs-4 text-${getColorBg(file)}`}
                                    />
                                  </div>
                                  <div className='flex-grow-1 ms-2'>
                                    {deleted ? (
                                      <h6 className='text-muted text-decoration-line-through fst-italic'>
                                        {name}
                                      </h6>
                                    ) : (
                                      <h6>
                                        <div className='stretched-link'>
                                          {name}
                                        </div>
                                      </h6>
                                    )}
                                    {deleted ? (
                                      <div className='d-flex align-items-center gap-1'>
                                        <i className='mdi mdi-alert-box-outline' />
                                        Eliminado
                                      </div>
                                    ) : (
                                      <button
                                        onClick={() => {
                                          handleClickDown({
                                            file,
                                            name
                                          })
                                        }}
                                        style={{
                                          zIndex: 3
                                        }}
                                        className='bg-transparent border-0 btn-hover-credit'
                                      >
                                        Descargar
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </Row>
                      )}
                    </div>
                  </UncontrolledCollapse>
                </div>
              ))}
              {comments.length === 0 && (
                <div
                  className='text-center d-flex align-items-center justify-content-center'
                  style={{ height: '30vh' }}
                >
                  <p>No hay comentarios para este crédito</p>
                </div>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </TabPane>
  )
}
