import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Alert,
  Badge,
  Card,
  CardBody,
  CardFooter,
  Col,
  Row,
  Spinner,
  Table,
  UncontrolledTooltip
} from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { useGetUser } from '../../../hooks/user'
import { hasPermission, parseId } from '../../../helpers'
import {
  ALL_PERMISSIONS,
  ERROR_MESSAGES,
  STATUS_CERTIFICATES
} from '../../../helpers/consts'
import { useLazyQuery } from '@apollo/client'
import { GET_ONE_CERTIFICATE } from '../../../graphql/queries/getCertificates'
import moment from 'moment'
import { ModalStatusCertificate } from '../../../Components/Own/certificates/ModalStatusCertificate'
import { API } from '../../../graphql/client'
import { toast } from 'react-toastify'

export const OneCertificatePage = () => {
  const [gqlGetCertificate, { data, loading, error, refetch }] = useLazyQuery(
    GET_ONE_CERTIFICATE,
    { fetchPolicy: 'no-cache' }
  )
  const navigate = useNavigate()
  const { permissions, logout, user } = useGetUser()
  const { id } = useParams()

  const [showChangeStatus, setShowChangeStatus] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)

  useEffect(() => {
    if (!hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_PAZ_Y_SALVOS)) {
      navigate('/dashboard')
    }
  }, [permissions])

  useEffect(() => {
    if (!id || !Number(id)) {
      navigate('/cotizaciones')
      return
    }
    gqlGetCertificate({
      variables: {
        id
      }
    })
  }, [id])

  useEffect(() => {
    if (!error) return
    if (error.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
      logout()
    }
  }, [error])

  const getStatus = (status, isColor = false) => {
    const st = STATUS_CERTIFICATES[status]
    switch (st) {
      case STATUS_CERTIFICATES.AUTORIZADO:
        return isColor ? 'success' : 'mdi-check-circle-outline'
      case STATUS_CERTIFICATES.SIN_REVISAR:
        return isColor ? 'primary' : 'mdi-email-alert-outline'
      case STATUS_CERTIFICATES.RECHAZADO:
        return isColor ? 'danger' : 'mdi-diameter-variant'

      default:
        return isColor ? 'dark' : 'mdi-square-outline'
    }
  }

  const toggleChangeStatus = () => {
    setShowChangeStatus(false)
  }

  const handleClickDown = async (idCertification) => {
    if (!idCertification) return
    const { token } = user.tokenAuth
    if (!token) return
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${token}`)

    const requestOptions = {
      method: 'GET',
      headers: myHeaders
    }

    setLoadingExport(true)

    try {
      const res = await fetch(
        `${API}/export/compliance/certificate/${parseId(idCertification)}`,
        requestOptions
      )
      if (!res.ok) throw new Error('Error al exportar')
      const blob = await res.blob()
      // console.log('blob', blob)
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `certificate-${parseId(
        idCertification
      )}.${blob.type.replace('application/', '')}`
      document.body.appendChild(link)
      setLoadingExport(false)
      link.click()
    } catch (err) {
      setLoadingExport(false)
      toast.error(`ERROR: ${err.message}`)
    }
  }

  document.title = 'Paz y Salvo | TOTAL SALES'

  if (loading || !data) {
    return (
      <div className='page-content'>
        <div className='container-fluid'>
          <BreadCrumb
            title='Paz y salvo'
            pageTitle='Paz y salvos'
            back='/paz_y_salvos'
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className='d-flex align-items-center justify-content-between'>
                    <h5
                      className='card-title placeholder-glow'
                      style={{ width: '20%' }}
                    >
                      <span className='placeholder col-12' />
                    </h5>
                    <div>
                      <div
                        style={{
                          width: '100px',
                          backgroundColor: '#d0d0d0',
                          padding: '1.1em',
                          borderRadius: '4px'
                        }}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={9}>
              <Card>
                <CardBody
                  className='d-flex align-items-center justify-content-center'
                  style={{ height: '30vh' }}
                >
                  <Spinner
                    color='dark'
                    style={{ width: '3em', height: '3em' }}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card>
                <CardBody
                  className='d-flex align-items-center justify-content-center'
                  style={{ height: '30vh' }}
                >
                  <Spinner
                    color='dark'
                    style={{ width: '3em', height: '3em' }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
  /** @type {{brand: string, chassisNumber: string, color: string, comment: null, created: Date, documentNumber:   string, engineNumber: string, id: string, line: string, model: string, names: string, obligationNumber: string, organizationName: string, organizationNit:  string, plaque: string, status: string, surnames: string, updated: Date, user: {firstName: string, lastName: string, email:string}}} */
  const certifaction = data.listComplianceCertificate.edges[0].node
  return (
    <>
      <div className='page-content'>
        <div className='container-fluid'>
          <BreadCrumb
            title='Paz y salvo'
            pageTitle='Paz y salvos'
            back='/paz_y_salvos'
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody className='bg-soft-light'>
                  <div className='d-flex align-items-center'>
                    <h6 className='card-title mb-0 flex-grow-1 fw-bold'>
                      Información de paz y salvo
                    </h6>
                    {hasPermission(
                      permissions,
                      ALL_PERMISSIONS.PUEDE_CAMBIAR_ESTADO_PAZ_Y_SALVO
                    ) &&
                      STATUS_CERTIFICATES[certifaction.status] ===
                        STATUS_CERTIFICATES.SIN_REVISAR && (
                        // eslint-disable-next-line react/jsx-indent
                        <div className='flex-shrink-0'>
                          <button
                            className='btn btn-soft-danger'
                            onClick={() => setShowChangeStatus(true)}
                          >
                            <i className='mdi mdi-arrow-left-right align-bottom me-1' />
                            <span className='d-none d-sm-inline-block'>
                              Cambiar estado
                            </span>
                          </button>
                        </div>
                      )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={12} lg={9}>
              <div id='job-list'>
                <Card className='joblist-card'>
                  <CardBody>
                    <div className='d-flex mb-4'>
                      <div className='ms-3 flex-grow-1'>
                        <h5 className='fs-3'>
                          {certifaction.brand} {certifaction.model}{' '}
                          {certifaction.line}
                        </h5>
                        <p className='company-name text-muted mb-0'>
                          Creado:{' '}
                          {moment(certifaction.created).format(
                            'DD MMM [del] YYYY - hh:mm A'
                          )}
                        </p>
                      </div>
                      <div>
                        <div className='d-flex mt-1'>
                          <div className='flex-shrink-0 avatar-xs align-self-center me-3'>
                            <div
                              className={`avatar-title bg-light rounded-circle fs-3 text-${getStatus(
                                certifaction.status,
                                true
                              )}`}
                            >
                              <i
                                className={`mdi ${getStatus(
                                  certifaction.status,
                                  false
                                )}`}
                              />
                            </div>
                          </div>
                          <div className='flex-grow-1 overflow-hidden'>
                            <p className='mb-1'>
                              <strong>Estado:</strong>
                            </p>
                            <h6 className='text-truncate mb-0'>
                              <Badge
                                color={getStatus(certifaction.status, true)}
                              >
                                {STATUS_CERTIFICATES[certifaction.status] ||
                                  certifaction.status}
                              </Badge>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    {certifaction.comment && (
                      <div className='p-4'>
                        <Alert color='warning'>
                          <h3 className='fs-4'>
                            Comentario{' '}
                            <>
                              <span id='info-reason'>
                                <i className='mdi mdi-information-variant' />
                              </span>
                              <UncontrolledTooltip
                                placement='top'
                                target='info-reason'
                              >
                                Razón del rechazo
                              </UncontrolledTooltip>
                            </>
                          </h3>
                          <div className='d-flex align-items-center gap-3'>
                            <i className='mdi mdi-chat-alert-outline fs-1' />
                            {certifaction.comment}
                          </div>
                        </Alert>
                      </div>
                    )}
                    <Table responsive align='center'>
                      <tbody>
                        <tr>
                          <th>Placa</th>
                          <td className='text-uppercase'>
                            {certifaction.plaque}
                          </td>
                        </tr>
                        <tr>
                          <th>No. Motor</th>
                          <td>{certifaction.engineNumber}</td>
                        </tr>
                        <tr>
                          <th>Marca</th>
                          <td>{certifaction.brand}</td>
                        </tr>
                        <tr>
                          <th>Linea</th>
                          <td>{certifaction.line}</td>
                        </tr>
                        <tr>
                          <th>Modelo</th>
                          <td>{certifaction.model}</td>
                        </tr>
                        <tr>
                          <th>Chasis</th>
                          <td>{certifaction.chassisNumber}</td>
                        </tr>
                        <tr className='border-0'>
                          <th className='border-0'>Color</th>
                          <td className='border-0'>{certifaction.color}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                  <CardFooter className='border-top-dashed'>
                    <div className='d-flex justify-content-between align-items-center flex-wrap gap-3'>
                      <div className='d-flex align-items-center justify-content-center flex-column'>
                        <div>
                          <i className='ri-briefcase-2-line align-bottom me-1' />{' '}
                          <span className='job-type'>
                            {certifaction.obligationNumber || 'N/A'}
                          </span>
                        </div>
                        <h6>No. Obligación</h6>
                      </div>
                      <div className='d-flex align-items-center justify-content-center flex-column'>
                        <div>
                          <i className='mdi mdi-office-building-marker-outline align-bottom me-1' />{' '}
                          <span className='job-type'>
                            {certifaction.organizationName || 'N/A'}
                          </span>
                        </div>
                        <h6>Organización</h6>
                      </div>
                      <div className='d-flex align-items-center justify-content-center flex-column'>
                        <div>
                          <i className='mdi mdi-content-save-alert-outline align-bottom me-1' />{' '}
                          <span className='job-type'>
                            {certifaction.organizationNit || 'N/A'}
                          </span>
                        </div>
                        <h6>Nit</h6>
                      </div>
                    </div>
                  </CardFooter>
                </Card>
              </div>
            </Col>
            <Col md={12} lg={3}>
              <Card>
                <CardBody>
                  <div className='mt-3'>
                    <h5 className='view-title'>
                      {certifaction.names} {certifaction.surnames}
                    </h5>
                    <div className='hstack gap-3 mb-3 fs-14'>
                      <span className='text-muted'>
                        <i className='mdi mdi-card-account-details-outline me-1 align-bottom' />{' '}
                        <span className='view-companyname'>
                          {certifaction.documentNumber}
                        </span>
                      </span>
                      {/* <span className='text-muted'>
                        <i className='ri-map-pin-2-line me-1 align-bottom' />{' '}
                        <span className='view-location'>United Kingdom</span>
                      </span> */}
                    </div>
                    <div className='py-3 border border-dashed border-start-0 border-end-0 mt-4'>
                      <Row>
                        <Col>
                          <div>
                            <p className='mb-2 text-uppercase fw-semibold fs-12 text-muted'>
                              Asesor
                            </p>
                            <h5 className='fs-14 mb-0 view-type'>
                              {certifaction.user.firstName}{' '}
                              {certifaction.user.lastName}
                            </h5>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  {STATUS_CERTIFICATES[certifaction.status] ===
                    STATUS_CERTIFICATES.AUTORIZADO && (
                    // eslint-disable-next-line react/jsx-indent
                    <div className='mt-4'>
                      <button
                        type='button'
                        onClick={() => handleClickDown(certifaction.id)}
                        disabled={loadingExport}
                        className=' btn btn-success w-100d-flex align-items-center gap-1'
                      >
                        {loadingExport && <Spinner color='light' size='sm' />}
                        Descargar certificado
                      </button>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <ModalStatusCertificate
          toggle={toggleChangeStatus}
          isOpen={showChangeStatus}
          refetch={refetch}
          creditId={certifaction.id}
        />
      </div>
    </>
  )
}
