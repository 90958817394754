import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap'
import { GET_ALL_EVENTS } from '../../../graphql/queries/getEvents'
import { toast } from 'react-toastify'
import { ALL_PERMISSIONS, ERROR_MESSAGES } from '../../../helpers/consts'
import { useGetUser } from '../../../hooks/user'
import SimpleBar from 'simplebar-react'
import FeatherIcon from 'feather-icons-react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import BootstrapTheme from '@fullcalendar/bootstrap'
import listPlugin from '@fullcalendar/list'
import moment from 'moment'
import { Link } from 'react-router-dom'
import CountUp from 'react-countup'

import { InactiveUsers } from '../../../Components/Own/home/InactiveUsers'
import { hasPermission } from '../../../helpers'
import { useDisclosure } from '../../../hooks/useDisclosure'
import { ModalChangeMonthlyFee } from '../../../Components/Own/home/ModalChangeMonthlyFee'
import { BudgetReport } from '../../../Components/Own/home/BudgetReport'
/* import { Calendar } from '@fullcalendar/core'
import esLocale from '@fullcalendar/core/locales/es'
Calendar.localize(esLocale) */
// pages/DashboardEcommerce

const CrmCustom = () => {
  const { loading, error, data } = useQuery(GET_ALL_EVENTS, {
    fetchPolicy: 'no-cache'
  })
  const { logout, user, permissions } = useGetUser()

  /** @type {[{id: string, date: Date, title: string, user: string, url: string}[],React.Dispatch<React.SetStateAction<{}[]>>]} */
  const [events, setEvents] = useState([])

  const [showInfo, setShowInfo] = useState(false)
  const [infoEvent, setInfoEvent] = useState(null)

  const { isOpen, onClose, onOpen } = useDisclosure()
  const [preMonthly, setPreMonthly] = useState(null)

  useEffect(() => {
    if (!error) return
    toast.error('Error: ' + error.message)
    if (error.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
      logout()
    }
  }, [error])
  useEffect(() => {
    if (!data) return
    if (data.eventSchedules.edges.length > 0) {
      setEvents(
        data.eventSchedules.edges.map(({ node }) => ({
          id: node.id,
          date: new Date(node.eventDate).toISOString(),
          title: node.description,
          user: node.user.firstName,
          url: '#'
        }))
      )
    }
  }, [data])

  const handleEventClick = (arg) => {
    const { id } = arg.event
    if (!id || !data) return
    const find = data.eventSchedules.edges.find(({ node }) => node.id === id)
    console.log('find', find)
    setShowInfo(true)
    setInfoEvent({ ...find.node })
  }
  const toggle = () => {
    setShowInfo(false)
  }

  document.title = 'Inicio | TOTAL SALES'

  if (loading || !data) {
    return (
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col>
              <div
                className='d-flex justify-content-center align-items-center'
                style={{ height: '50vh' }}
              >
                <div>
                  <img
                    src='/TotalSales-Positivo.png'
                    className='card-logo card-logo-dark loading-logo'
                    alt='logo dark'
                    height='auto'
                    width='300'
                  />
                  <img
                    src='/TotalSales-Negativo.png'
                    className='card-logo card-logo-light loading-logo'
                    alt='logo light'
                    height='auto'
                    width='300'
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  if (!user) return null

  const { monthly_fee: monthlyFee } = JSON.parse(user.tokenAuth.settings)
  const canViewMonthly = hasPermission(
    permissions,
    ALL_PERMISSIONS.PUEDE_VER_CONFIGURACIONES
  )
  const canChangeSettings = hasPermission(
    permissions,
    ALL_PERMISSIONS.PUEDE_CAMBIAR_SETTINGS
  )

  return (
    <div className='page-content'>
      <Container fluid>
        <BudgetReport />
        <Row>
          <Col xs={12}>
            <Row>
              <Col xl={3}>
                {canViewMonthly && monthlyFee && (
                  <Card className='mt-3 mt-md-0 py-4 px-3'>
                    <div className='d-flex align-items-center justify-content-between w-100'>
                      <h5 className='text-muted text-uppercase fs-13'>
                        Tasa mensual{' '}
                      </h5>
                      {canChangeSettings && (
                        <button
                          className='bg-transparent border-0 text-info'
                          title='Editar'
                          onClick={() => {
                            onOpen()
                            setPreMonthly(monthlyFee)
                          }}
                        >
                          <i className='mdi mdi-pencil' />
                        </button>
                      )}
                    </div>
                    <div className='d-flex align-items-center'>
                      <div className='flex-shrink-0'>
                        <i className='display-6 text-muted mdi mdi-sack-percent' />
                      </div>
                      <div className='flex-grow-1 ms-3'>
                        <h2 className='mb-0'>
                          <span className='counter-value'>
                            <CountUp
                              start={0}
                              prefix=''
                              suffix=''
                              // separator={item.separator}
                              end={monthlyFee}
                              decimals={2}
                              duration={3}
                            />
                          </span>
                        </h2>
                      </div>
                    </div>
                  </Card>
                )}
                <Card>
                  <CardBody className='bg-soft-info'>
                    <div className='d-flex'>
                      <div className='flex-shrink-0'>
                        <FeatherIcon
                          icon='calendar'
                          className='text-info icon-dual-info'
                        />
                      </div>
                      <div className='flex-grow-1 ms-3'>
                        <h6 className='fs-15'>Tus eventos</h6>
                        <p className='text-muted mb-0'>
                          Aquí podrás encontrar tus eventos y dirigirte a ellos,
                          entérate de lo que sucede en TOTAL SALES.
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <div>
                  <h5 className='mb-1'>Próximos eventos</h5>
                  {events.length > 0 && (
                    <p className='text-muted'>No olvides revisar tus eventos</p>
                  )}
                  <SimpleBar
                    className='pe-2 me-n1 mb-3'
                    style={{ height: '100%', maxHeight: '380px' }}
                  >
                    <div id='upcoming-event-list'>
                      {events
                        .sort((a, b) => new Date(a.date) - new Date(b.date))
                        .map(({ id, title, date, user }) => (
                          <Card className='mb-3' key={id}>
                            <CardBody>
                              <div className='d-flex mb-3'>
                                <div className='flex-grow-1'>
                                  <i className='mdi mdi-checkbox-blank-circle me-2 text-info' />
                                  <span className='fw-medium'>
                                    {moment(date).format('DD [de] MMMM')}
                                  </span>
                                </div>
                                <div className='flex-shrink-0'>
                                  <small className='badge badge-soft-primary ms-auto'>
                                    {moment(date).format('hh:mm A')}
                                  </small>
                                </div>
                              </div>
                              <h6 className='card-title fs-16'>{title}</h6>
                              <p className='text-muted text-truncate-two-lines mb-0'>
                                {user}
                              </p>
                            </CardBody>
                          </Card>
                        ))}
                      {events.length === 0 && (
                        <Card className='mb-3'>
                          <CardBody>
                            <div className='d-flex mb-3'>
                              <div className='flex-grow-1'>
                                <i className='mdi mdi-checkbox-blank-circle me-2 text-info' />
                                <span className='fw-medium'>Hoy</span>
                              </div>
                              <div className='flex-shrink-0'>
                                <small className='badge badge-soft-primary ms-auto'>
                                  {moment().format('hh:mm A')}
                                </small>
                              </div>
                            </div>
                            <h6 className='card-title fs-16'>
                              No hay eventos para ti
                            </h6>
                            <p className='text-muted text-truncate-two-lines mb-0'>
                              Por el momento no cuentas con ningun evento
                              registrado
                            </p>
                          </CardBody>
                        </Card>
                      )}
                    </div>
                  </SimpleBar>
                </div>
                <InactiveUsers />
              </Col>

              <Col xl={9}>
                <Card className='card-h-100'>
                  <CardBody>
                    <FullCalendar
                      buttonText={{
                        today: 'HOY',
                        month: 'Mes',
                        day: 'Día',
                        week: 'Semana'
                      }}
                      plugins={[
                        BootstrapTheme,
                        dayGridPlugin,
                        interactionPlugin,
                        listPlugin
                      ]}
                      locale='es'
                      initialView='dayGridMonth'
                      // slotDuration='00:15:00'
                      handleWindowResize
                      themeSystem='bootstrap'
                      headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,dayGridWeek,dayGridDay'
                      }}
                      editable
                      droppable
                      selectable
                      events={events}
                      eventClick={handleEventClick}
                      eventColor='light'
                      /* events={events}
                      dateClick={handleDateClick}
                      drop={onDrop} */
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <div style={{ clear: 'both' }} />
          </Col>
        </Row>
      </Container>

      {infoEvent && (
        <Modal isOpen={showInfo} id='event-modal' centered>
          <ModalHeader
            toggle={toggle}
            tag='h5'
            className='p-3 bg-soft-info modal-title'
          >
            {infoEvent.description}
          </ModalHeader>
          <ModalBody>
            <div
              className='needs-validation view-event'
              name='event-form'
              id='form-event'
            >
              <div className='event-details'>
                <div className='d-flex align-items-center mb-2'>
                  <div className='flex-shrink-0 me-3'>
                    <i className='mdi mdi-comment-account-outline text-muted fs-16' />
                  </div>
                  <div className='flex-grow-1'>
                    <h6 className='d-block fw-semibold mb-0'>
                      {' '}
                      <span id='event-location-tag'>
                        {infoEvent.user.firstName}
                      </span>
                    </h6>
                  </div>
                </div>
                <div className='d-flex mb-3'>
                  <div className='flex-shrink-0 me-3'>
                    <i className='mdi mdi-clock-time-five-outline text-muted fs-16' />
                  </div>
                  <div className='flex-grow-1'>
                    <p
                      className='d-block text-muted mb-0'
                      id='event-description-tag'
                    >
                      {moment(infoEvent.eventDate).format(
                        'DD MMM [del] YY - hh:mm A'
                      )}
                    </p>
                  </div>
                </div>
                <div className='d-flex mb-2'>
                  <div className='flex-grow-1 d-flex align-items-center'>
                    <div className='flex-shrink-0 me-3'>
                      <i className='mdi mdi-eye text-muted fs-16' />
                    </div>
                    <div className='flex-grow-1'>
                      <Link
                        to={infoEvent.url}
                        className='d-block fw-semibold mb-0'
                        id='event-start-date-tag'
                      >
                        Ver
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}

      {isOpen && preMonthly && (
        <ModalChangeMonthlyFee
          infoTasa={preMonthly}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </div>
  )
}

export default CrmCustom
