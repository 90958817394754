import { Fragment } from 'react'
import { trasnformToArr } from '../../../helpers'

export const InfoSectorReal = ({ vigentes, extinguidas }) => {
  /** @type {{ Calidad: string; ChequesDevueltos: string; Ciudad: string; ClausulaPermanencia: string; Comportamientos: string; CuotasCanceladas: string; EstadoContrato: string; EstadoObligacion: string; EstadoTitular: string; FechaApertura: string; FechaCorte: string; FechaPago: string; FechaPermanencia: string; IdentificadorLinea: string; LineaCredito: string; ModoExtincion: string; MoraMaxima: string; NombreEntidad: string; NumeroCuotasMora: string; NumeroCuotasPactadas: string; NumeroMesesClausula: string; NumeroMesesContrato: string; NumeroObligacion: string; PaqueteInformacion: string; ParticipacionDeuda: string; Periodicidad: string; Reestructurado: string; SaldoObligacion: string; Sucursal: string; TipoContrato: string; TipoEntidad: string; TipoPago: string; ValorCargoFijo: string; ValorCuota: string; ValorInicial: string; ValorMora: string; Vigencia: string;}[]} */

  const arrVigente = trasnformToArr(vigentes)
  /** @type {arrVigente} */
  const arrExting = trasnformToArr(extinguidas)
  return (
    <div className='pt-5'>
      <div style={{ overflowX: 'auto', width: '100%', fontSize: '10px' }}>
        <table
          className='w-100'
          style={{ borderCollapse: 'separate' }}
          cellSpacing='2px'
        >
          <thead>
            <tr style={{ backgroundColor: '#006685' }}>
              <th colSpan={18} className='text-center text-white fs-5'>
                Información Endeudamiento en Sector Real
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
                rowSpan={3}
              >
                FECHA CORTE
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
                rowSpan={2}
              >
                TIPO CONT
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
                rowSpan={2}
              >
                No. OBLIG
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
                rowSpan={2}
              >
                NOMBRE ENTIDAD
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
                rowSpan={2}
              >
                CIUDAD
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
                rowSpan={2}
              >
                CALD
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
                rowSpan={2}
              >
                VIG
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
                rowSpan={3}
              >
                CLA PER
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
                rowSpan={2}
              >
                F INICIO
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
                rowSpan={1}
                colSpan={3}
              >
                No. CUOTAS
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
                rowSpan={2}
              >
                CUPO APROBVLR INIC
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
                rowSpan={2}
              >
                PAGO MINIM-VLR CUOTA
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
                rowSpan={2}
              >
                SIT OBLIG
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
                rowSpan={2}
              >
                TIP PAG
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
                rowSpan={2}
              >
                REF
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
                rowSpan={2}
              >
                F PAGO-F EXTIN
              </th>
            </tr>
            <tr>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
                rowSpan={1}
              >
                PAC
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
                rowSpan={1}
              >
                PAG
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
                rowSpan={1}
              >
                MOR
              </th>
            </tr>
            <tr>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                CATELCRE
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                EST. CONTR
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                TIPO EMPR
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                SUCURSAL
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                EST TITU
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                MES
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                F TERM
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                PER
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
                colSpan={2}
              />

              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                CUPO UTILISALDO CORT
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                VALOR CARGO FIJO
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                VALOR MORA
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                MOD EXT
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                MOR MAX
              </th>
              <th
                style={{
                  backgroundColor: '#00a6ca',
                  padding: '4px 6px',
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                F PERMAN
              </th>
            </tr>

            <tr>
              <td style={{ backgroundColor: '#ededed' }} colSpan={18}>
                <div className='pt-4 d-flex algin-items-start justify-content-start fw-bold'>
                  OBLIGACIONES VIGENTES Y AL DÍA
                </div>
              </td>
            </tr>
            {arrVigente.map((n, idx) => (
              <Fragment key={idx}>
                <tr>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.FechaCorte || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.TipoContrato || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.NumeroObligacion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.NombreEntidad || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.Ciudad || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.Calidad || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.Vigencia || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.ClausulaPermanencia || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.FechaApertura || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.NumeroCuotasPactadas || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.CuotasCanceladas || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.NumeroCuotasMora || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.ValorInicial || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.ValorCuota || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.EstadoObligacion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.TipoPago || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.Reestructurado || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    -
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  />
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.LineaCredito || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.EstadoContrato || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.TipoEntidad || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.Sucursal || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.EstadoTitular || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.ModoExtincion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  />
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.FechaCorte || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.Periodicidad || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                    colSpan={2}
                  />
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.SaldoObligacion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.ValorCargoFijo || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'right'
                    }}
                  >
                    {n.ValorMora || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.ModoExtincion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.MoraMaxima || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.FechaPermanencia || '-'}
                  </td>
                </tr>

                <tr>
                  <td
                    colSpan={10}
                    style={{
                      backgroundColor: '#ededed',
                      padding: '4px 5px',
                      textAlign: 'center'
                    }}
                  >
                    <div className='d-flex align-items-end w-100'>
                      <div className='d-flex justify-content-end gap-1 w-100'>
                        <div className='d-flex algin-items-center text-muted fw-bold'>
                          {n.Comportamientos.split('|')
                            .map((c) => c.trim())
                            .slice(1, -1)
                            .map((str, i) => (
                              <span
                                key={i}
                                style={{
                                  display: 'inline-block',
                                  border: '1px solid lightblue',
                                  padding: '0 5px'
                                }}
                              >
                                {str || (
                                  <span style={{ paddingInline: '3px' }} />
                                )}
                              </span>
                            ))}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td
                    colSpan={4}
                    style={{
                      backgroundColor: '#ededed',
                      padding: '0',
                      textAlign: 'center'
                    }}
                  >
                    <div
                      className='w-100 h-100 fw-bold'
                      style={{
                        backgroundColor: '#00a6ca',
                        color: 'white',
                        clipPath:
                          'polygon(100% 0, 100% 50%, 100% 100%, 7% 99%, 0 54%, 7% 0)',
                        padding: '8px'
                      }}
                    >
                      COMPORTAMIENTOS
                    </div>
                  </td>
                  <td
                    colSpan={4}
                    style={{
                      backgroundColor: '#ededed',
                      padding: '4px 5px',
                      textAlign: 'center'
                    }}
                  />
                </tr>
              </Fragment>
            ))}

            <tr>
              <td style={{ backgroundColor: '#ededed' }} colSpan={18}>
                <div className='pt-4 d-flex algin-items-start justify-content-start fw-bold'>
                  OBLIGACIONES EXTINGUIDAS
                </div>
              </td>
            </tr>
            {arrExting.map((n, idx) => (
              <Fragment key={idx}>
                <tr>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.FechaCorte || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.TipoContrato || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.NumeroObligacion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.NombreEntidad || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.Ciudad || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.Calidad || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.Vigencia || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.ClausulaPermanencia || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.FechaApertura || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.NumeroCuotasPactadas || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.CuotasCanceladas || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.NumeroCuotasMora || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.ValorInicial || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.ValorCuota || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.EstadoObligacion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.TipoPago || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.Reestructurado || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    -
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  />
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.LineaCredito || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.EstadoContrato || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.TipoEntidad || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.Sucursal || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.EstadoTitular || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.ModoExtincion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  />
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.FechaCorte || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.Periodicidad || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                    colSpan={2}
                  />
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.SaldoObligacion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.ValorCargoFijo || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'right'
                    }}
                  >
                    {n.ValorMora || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.ModoExtincion || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.MoraMaxima || '-'}
                  </td>
                  <td
                    style={{
                      backgroundColor: '#ededed',
                      padding: '2px 5px',
                      textAlign: 'center'
                    }}
                  >
                    {n.FechaPermanencia || '-'}
                  </td>
                </tr>

                <tr>
                  <td
                    colSpan={10}
                    style={{
                      backgroundColor: '#ededed',
                      padding: '4px 5px',
                      textAlign: 'center'
                    }}
                  >
                    <div className='d-flex align-items-end w-100'>
                      <div className='d-flex justify-content-end gap-1 w-100'>
                        <div className='d-flex algin-items-center text-muted fw-bold'>
                          {n.Comportamientos.split('|')
                            .map((c) => c.trim())
                            .slice(1, -1)
                            .map((str, i) => (
                              <span
                                key={i}
                                style={{
                                  display: 'inline-block',
                                  border: '1px solid lightblue',
                                  padding: '0 5px'
                                }}
                              >
                                {str || (
                                  <span style={{ paddingInline: '3px' }} />
                                )}
                              </span>
                            ))}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td
                    colSpan={4}
                    style={{
                      backgroundColor: '#ededed',
                      padding: '0',
                      textAlign: 'center'
                    }}
                  >
                    <div
                      className='w-100 h-100 fw-bold'
                      style={{
                        backgroundColor: '#00a6ca',
                        color: 'white',
                        clipPath:
                          'polygon(100% 0, 100% 50%, 100% 100%, 7% 99%, 0 54%, 7% 0)',
                        padding: '8px'
                      }}
                    >
                      COMPORTAMIENTOS
                    </div>
                  </td>
                  <td
                    colSpan={4}
                    style={{
                      backgroundColor: '#ededed',
                      padding: '4px 5px',
                      textAlign: 'center'
                    }}
                  />
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
