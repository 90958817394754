import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
// import logo
import logoSm from '../assets/images/logo-sm.png'
import logoDark from '../assets/images/logo-dark.png'
// import logoLight from '../assets/images/logo-light.png'

// Import Components
import VerticalLayout from './VerticalLayouts'
import TwoColumnLayout from './TwoColumnLayout'
import { Alert, Container, Spinner } from 'reactstrap'
import HorizontalLayout from './HorizontalLayout'
import { useServiceWorker } from '../hooks/useServiceWorker'
import { MSG_SKIP_WAITING } from '../helpers/consts'

const Sidebar = ({ layoutType }) => {
  const { isNewVersion } = useServiceWorker()
  const [loadingReload, setLoadingReload] = useState(false)
  useEffect(() => {
    const verticalOverlay = document.getElementsByClassName('vertical-overlay')
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener('click', function () {
        document.body.classList.remove('vertical-sidebar-enable')
      })
    }
  })

  const addEventListenerOnSmHoverMenu = () => {
    // add listener Sidebar Hover icon on change layout from setting
    if (
      document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover'
    ) {
      document.documentElement.setAttribute(
        'data-sidebar-size',
        'sm-hover-active'
      )
    } else if (
      document.documentElement.getAttribute('data-sidebar-size') ===
      'sm-hover-active'
    ) {
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover')
    } else {
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover')
    }
  }

  return (
    <>
      <div className='app-menu navbar-menu'>
        <div className='navbar-brand-box'>
          <Link to='/' className='logo logo-dark'>
            <span className='logo-sm'>
              <img src={logoSm} alt='' height='22' />
            </span>
            <span className='logo-lg'>
              <img src={logoDark} alt='' height='17' />
            </span>
          </Link>

          <Link to='/' className='logo logo-light'>
            <span className='logo-sm'>
              <img src={logoSm} alt='' height='22' />
            </span>
            <span className='logo-lg'>
              <img src='/TotalSales-Negativo.png' alt='' height='65' />
            </span>
          </Link>
          <button
            onClick={addEventListenerOnSmHoverMenu}
            type='button'
            className='btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover'
            id='vertical-hover'
          >
            <i className='ri-record-circle-line' />
          </button>
        </div>
        {layoutType === 'horizontal' ? (
          <div id='scrollbar'>
            <Container fluid>
              <div id='two-column-menu' />
              <ul className='navbar-nav' id='navbar-nav'>
                <HorizontalLayout />
              </ul>
            </Container>
          </div>
        ) : layoutType === 'twocolumn' ? (
          <>
            <TwoColumnLayout layoutType={layoutType} />
            <div className='sidebar-background' />
          </>
        ) : (
          <>
            <SimpleBar id='scrollbar' className='h-100'>
              <Container fluid>
                <div id='two-column-menu' />
                {isNewVersion && (
                  <Alert
                    color='success'
                    className='d-flex align-items-center justify-content-between gap-2 m-2'
                  >
                    <div
                      style={{
                        background: '#fff',
                        borderRadius: '100%',
                        padding: '4px 8px'
                      }}
                    >
                      {loadingReload ? (
                        <Spinner size='sm' color='success' />
                      ) : (
                        <i className='mdi mdi-reload fs-4' />
                      )}
                    </div>
                    <div
                      style={{
                        fontSize: '11px',
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '100%',
                        flexWrap: 'wrap'
                      }}
                    >
                      <p className='mb-0 fw-bold'>¡Actualización dispobible!</p>
                      <button
                        className='mb-0 hover-underline d-flex align-items-center gap-1'
                        style={{
                          cursor: 'pointer',
                          padding: 0,
                          border: 0,
                          backgroundColor: 'transparent',
                          textAlign: 'left',
                          color: '#088675'
                        }}
                        onClick={() => {
                          setLoadingReload(true)
                          if ('serviceWorker' in window.navigator) {
                            navigator.serviceWorker
                              .getRegistration()
                              .then(function (registration) {
                                if (registration && registration.waiting) {
                                  // Envía un mensaje al Service Worker en espera para activar el nuevo Service Worker y eliminar el actual
                                  registration.waiting.postMessage({
                                    type: MSG_SKIP_WAITING
                                  })
                                }
                              })
                            setTimeout(() => {
                              window.location.reload()
                            }, 2000)
                          } else {
                            window.close()
                          }
                          /* if (navigator.serviceWorker.controller) {
                            navigator.serviceWorker.controller.postMessage({
                              type: MSG_SKIP_WAITING
                            })
                            // window.location.reload()
                          }  */
                        }}
                      >
                        Presione aquí para actualizar Total Sales{' '}
                        <i className='mdi mdi-arrow-right' />
                      </button>
                    </div>
                  </Alert>
                )}
                <ul className='navbar-nav' id='navbar-nav'>
                  <VerticalLayout layoutType={layoutType} />{' '}
                  {/* ROUTES OWN HERE */}
                </ul>
              </Container>
            </SimpleBar>
            <div className='sidebar-background' />
          </>
        )}
      </div>
      <div className='vertical-overlay' />
    </>
  )
}

export default Sidebar
