/* eslint-disable react/jsx-indent */
import { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Container,
  Spinner,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
  FormFeedback,
  Table,
  Alert,
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  FormGroup,
  UncontrolledAlert
} from 'reactstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import classnames from 'classnames'

// import Pdf from 'react-to-pdf'
// import JsPDF from 'jspdf'

// import logoDark from '../../../assets/images/logo-dark.png'
// import logoLight from '../../../assets/images/logo-light.png'
import { useLazyQuery, useMutation } from '@apollo/client'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { toast } from 'react-toastify'
/* import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  usePDF,
  Image
} from '@react-pdf/renderer' */

import { hasPermission, parseId, parsePrice } from '../../../helpers'
import {
  ALL_PERMISSIONS,
  ERROR_MESSAGES,
  QUOTATION_TYPES,
  STATUS_BILLINGS
} from '../../../helpers/consts'
import { useGetUser } from '../../../hooks/user'
import { GET_ONE_BILLING } from '../../../graphql/queries/getBillings'
import moment from 'moment'
// import TeamTab from '../../Projects/ProjectOverview/TeamTab'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  CLOSE_BILLING_STR,
  CREATE_COMMET_BILLING,
  DELETE_BILLING
} from '../../../graphql/mutations/billing'
import { useMutationFetch } from '../../../hooks/useMutationFetch'
import { TabDownBillingInfo } from '../../../Components/Own/oneBilling/TabDownBillingInfo'
import { useDisclosure } from '../../../hooks/useDisclosure'

import { loadAnimation } from 'lottie-web'
import { defineElement } from 'lord-icon-element'
import { ModalConfirmDiscount } from '../../../Components/Own/oneBilling/ModalConfirmDiscount'
import { ModalConfirmResidue } from '../../../Components/Own/oneBilling/ModalConfirmResidue'

// register lottie and define custom element
defineElement(loadAnimation)

const OneBilling = () => {
  const [
    gqlDeleteBilling,
    { data: dataDelete, loading: loadingDelete, error: errorDelete }
  ] = useMutation(DELETE_BILLING)
  const navigate = useNavigate()
  const [gqlGetBilling, { data, loading, error, refetch }] = useLazyQuery(
    GET_ONE_BILLING,
    { fetchPolicy: 'network-only' }
  )
  const [
    closeBilling,
    { data: dataClose, loading: loadingClose, error: errorClose }
  ] = useMutationFetch()
  const [
    gqlCreateComment,
    { data: dataCreate, loading: loadingCreate, error: errorCreate }
  ] = useMutation(CREATE_COMMET_BILLING, {
    refetchQueries: [GET_ONE_BILLING]
  })

  const { logout, permissions } = useGetUser()
  const { id } = useParams()

  const [activeTab, setActiveTab] = useState('1')
  const [modalCloseBilling, setModalCloseBilling] = useState(false)
  const [modalViewPrices, setModalViewPrices] = useState(false)

  const [modalComment, setModalComment] = useState(false)

  const [infoPrices, setInfoPrices] = useState({
    type: '',
    prices: [],
    product: null
  })

  const [infoResidue, setInfoResidue] = useState(null)

  const [infoConfirmDiscount, setInfoConfirmDiscount] = useState(null)

  const { isOpen, onClose, onOpen } = useDisclosure()
  const {
    isOpen: isOpenConfirmDiscount,
    onClose: onCloseConfirmDiscount,
    onOpen: onOpenConfirmDiscount
  } = useDisclosure()
  const {
    isOpen: isOpenAddResidue,
    onClose: onCloseAddResidue,
    onOpen: onOpenAddResidue
  } = useDisclosure()

  const formik = useFormik({
    initialValues: {
      invoice: null
    },
    validationSchema: Yup.object({
      invoice: Yup.mixed().required('Campo requerido')
    }),
    onSubmit: (values) => {
      const formdata = new FormData()
      formdata.append('0', values.invoice, values.invoice.name)
      formdata.append('map', '{"0": ["variables.input.invoice"]}')
      const obj = {
        query: CLOSE_BILLING_STR,
        variables: {
          input: {
            invoice: null,
            billingId: id
          }
        }
      }
      formdata.append('operations', JSON.stringify(obj))
      closeBilling(formdata)
    }
  })
  const formComment = useFormik({
    initialValues: {
      comment: ''
    },
    validationSchema: Yup.object({
      comment: Yup.string().required('Campo obligatorio')
    }),
    onSubmit: ({ comment }) => {
      if (Number(id) && comment) {
        gqlCreateComment({
          variables: {
            input: {
              comment,
              billing: id
            }
          },
          refetchQueries: [GET_ONE_BILLING]
        })
      }
    }
  })
  useEffect(() => {
    if (!hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_COTIZACION)) {
      navigate('/dashboard')
    }
  }, [permissions])

  useEffect(() => {
    if (!id || !Number(id)) {
      navigate('/cotizaciones')
    }
    gqlGetBilling({
      variables: {
        billingId: id
      },
      fetchPolicy: 'network-only'
    })
  }, [id])

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error.message}`)
      if (error.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [error])

  useEffect(() => {
    if (dataClose) {
      if (dataClose.billingClose.success) {
        toast.success('Facturación cerrada con éxito')
        refetch()
        formik.resetForm()
        setModalCloseBilling(false)
      } else {
        toast.error(`Error ${dataClose.billingClose.errors.message}`)
      }
    }
  }, [dataClose])

  useEffect(() => {
    if (errorClose) {
      toast.error(`ERROR: ${errorClose.message}`)
    }
  }, [errorClose])

  useEffect(() => {
    if (dataCreate) {
      if (dataCreate.billingComment && dataCreate.billingComment.comment.id) {
        toast.success('Comentario agregado con éxito')
        formComment.resetForm()
        setModalComment(false)
      } else {
        toast.error(
          `Error: ${
            dataCreate.billingComment?.errors?.message || 'Algo ocurrió'
          }`
        )
      }
    }
  }, [dataCreate])

  useEffect(() => {
    if (errorCreate) {
      toast.error(`Error: ${errorCreate.message}`)
      if (errorCreate.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorCreate])

  useEffect(() => {
    if (!errorDelete) return
    toast.error('Error: ' + errorDelete.message)
  }, [errorDelete])

  useEffect(() => {
    if (!dataDelete) return
    if (dataDelete.billingDelete?.success) {
      toast.success('Facturación eliminada correctamente')
      navigate('/facturaciones')
    } else if (dataDelete.billingDelete?.errors) {
      toast.error('Error ' + dataDelete.billingDelete?.errors.message)
    }
  }, [dataDelete])

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const toggleModalCloseBilling = () => {
    setModalCloseBilling((p) => !p)
  }

  const toggleModalViewPrices = () => {
    setModalViewPrices((p) => !p)
  }
  const toggleComment = () => {
    setModalComment((prev) => !prev)
  }
  const handleClickDown = ({ name, file }) => {
    const link = document.createElement('a')
    link.href = file
    link.download = name
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  /** @param {{ edges: { node: { id: string; name: string; value: string; priceType: string; }}[]}} obj */
  const getPrice = ({ edges }) => {
    let tot = 0
    edges.forEach(({ node }) => {
      tot += Number(node.value)
    })
    return parsePrice(tot + '')
  }

  /** @param {{base: string, prices: {edges: { node: { id: string; name: string; value: string; priceType: string; }}[]}, discounts: {edges: { node: { id: string; name: string; value: string; priceType: string; }}[]}}} */
  const getFullTot = ({ base, prices, discounts }) => {
    let tot = 0
    tot += Number(base)
    const totPricesStr = getPrice(prices)
    const totDiscountsStr = getPrice(discounts)
    const totPrice = Number(totPricesStr.replace(/\D/g, ''))
    const totDiscount = Number(totDiscountsStr.replace(/\D/g, ''))
    // console.log('totDiscount', totDiscount)
    tot -= totDiscount
    tot += totPrice
    return parsePrice(tot + '')
  }

  /** @param {[{ id: string, name: string, value: string, type: string}]} prices */
  const getSubTot = (prices) => {
    let tot = 0
    prices.forEach(({ value }) => {
      tot += Number(value)
    })
    return parsePrice(tot + '')
  }

  document.title = 'Facturación | TOTAL SALES'

  if (loading) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            back='/facturaciones'
            title='Resumen facturación'
            pageTitle='Facturaciones'
          />

          <Row
            className='justify-content-center align-items-center'
            style={{ height: '30vh' }}
          >
            <Spinner
              style={{
                width: '3em',
                height: '3em'
              }}
              color='dark'
            />
          </Row>
        </Container>
      </div>
    )
  }

  if (!data) {
    return null
  }

  if (data.getBilling === null) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            back='/cotizaciones'
            title='Resumen cotización'
            pageTitle='Cotizaciones'
          />

          <Row
            className='justify-content-center align-items-center'
            style={{ height: '30vh' }}
          >
            <Col
              lg={12}
              className='d-flex align-items-center h-100 justify-content-center bg-white'
            >
              <div>
                <h4>Ups! No se encontró facturación 😞</h4>
                <div className='d-flex justify-content-center'>
                  <Link to='/facturaciones' className='btn btn-info'>
                    Ir a facturaciones
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
  /** @type {{addressResidence: string, invoice: string, cityResidence: string, billingDate: string | null, created: string, documentNumber: string, email: string, firstName: string, firstSurname: string, phoneNumber: string, secondName: string, secondSurname: string, status: string, biller:{firstName: string, lastName: string} ,billingcommentSet: {edges:{node:{comment:string, id:string, created: string, user:{firstName:string, lastName: string}}}[]} ,supportproductbillingSet: {edges: {node: {id: string, name: string, file: string}}[]} ,productauxbillingSet: {edges: {node: {basePrice: string, name: string, id: string, fieldformSet:{edges: {node:{id: string, name:string, value:string}}[]} ,discountauxbillingSet: {edges: {node: {id: string, name: string, discountType: string, value: string}}[]} ,priceauxbillingSet: {edges: {node: {id: string, name: string, value: string, priceType: string, }}[]} ,supportproductbillingSet: {edges: {node:{name: string, file: string}}[]} }}[]}, quotation: {id: string, quotationType: string, agency: {id: string, name: string,zone: {commercialCompany:{image: string | null}}}}}} */
  // const billing = data.listBilling.edges[0].node
  const billing = data.getBilling
  const comments = billing.billingcommentSet.edges

  const orderComments = comments.reduce((prev, current) => {
    const date = moment(current.node.created).format('DD MM YY')
    const dateFind = prev.find(({ date: datePrev }) => datePrev === date)

    if (dateFind) {
      dateFind.comments.push({
        comment: current.node.comment,
        date: current.node.created,
        user: `${current.node.user.firstName} ${current.node.user.lastName}`
      })
    } else {
      prev.push({
        date,
        comments: [
          {
            comment: current.node.comment,
            date: current.node.created,
            user: `${current.node.user.firstName} ${current.node.user.lastName}`
          }
        ]
      })
    }
    return prev
  }, [])

  const isSomePending = billing.productauxbillingSet.edges.some(({ node }) =>
    node.discountauxbillingSet.edges.some(
      ({ node: nodeDisc }) => nodeDisc.pending
    )
  )

  const isSomeResidue = billing.productauxbillingSet.edges.some(({ node }) =>
    node.residueSet.edges.some(({ node: nodeResidue }) => nodeResidue.locked)
  )

  console.log('isSomeResidue', isSomeResidue)

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb
          back='/facturaciones'
          title='Resumen facturación'
          pageTitle='Facturación'
          className='d-none-print'
        />
        <Row className='justify-content-center'>
          <Col lg={12}>
            <Card className='mt-n4 mx-n4'>
              {isSomePending && (
                <UncontrolledAlert
                  color='danger'
                  className='mb-0 d-flex align-items-center gap-2'
                >
                  <i className='mdi mdi-alert-outline fs-3' />
                  <span>
                    Existe una solicitud de descuento pendiente de aprobar,
                    dirígete a la sección de precios de productos y finaliza el
                    proceso
                  </span>
                </UncontrolledAlert>
              )}
              <div className='bg-soft-warning'>
                <CardBody className='pb-0 px-4'>
                  <Row className='mb-3'>
                    <div className='col-md'>
                      <Row className='align-items-center g-3'>
                        <div className='col-md-auto'>
                          <div className='avatar-md'>
                            <div className='avatar-title bg-white rounded-circle'>
                              <img
                                src={
                                  billing.quotation?.agency?.zone
                                    .commercialCompany.image ||
                                  '/TotalSales-Positivo.png'
                                }
                                alt='logo comecial company'
                                className='avatar-xs'
                              />
                            </div>
                          </div>
                        </div>
                        <div className='col-md'>
                          <div>
                            <h4 className='fw-bold text-uppercase'>
                              {billing.firstName} {billing.secondName}{' '}
                              {billing.firstSurname} {billing.secondSurname}
                            </h4>
                            <div className='hstack gap-3 flex-wrap'>
                              <div>
                                <i className='ri-building-line align-bottom me-1' />{' '}
                                {billing.quotation?.agency.name}
                              </div>
                              <div className='vr' />
                              <div>
                                Creado:{' '}
                                <span className='fw-medium'>
                                  {moment(billing.created).format(
                                    'DD MMM [del] YYYY'
                                  )}
                                </span>
                              </div>
                              <div className='vr' />
                              <div>
                                Productos:{' '}
                                <span className='fw-medium'>
                                  {billing.productauxbillingSet.edges.length}
                                </span>
                              </div>
                              <div className='vr' />
                              {billing.quotation && (
                                <div>
                                  <Link
                                    to={`/cotizacion/${parseId(
                                      billing.quotation.id
                                    )}`}
                                    className='d-flex align-items-center gap-1 link-underline link-underline-opacity-0 link-underline-opacity-75-hover link-offset-2 link-offset-3-hover'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                  >
                                    <i className='mdi mdi-file-eye-outline' />
                                    Ver cotización
                                  </Link>
                                </div>
                              )}
                              <div className='vr' />
                              {billing?.quotation?.credit && (
                                <div>
                                  <Link
                                    to={`/credito/${parseId(
                                      billing.quotation.credit.id
                                    )}`}
                                    className='d-flex align-items-center gap-1 link-underline link-underline-opacity-0 link-underline-opacity-75-hover link-offset-2 link-offset-3-hover'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                  >
                                    <i className='mdi mdi-credit-card-outline' />
                                    Ver crédito
                                  </Link>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </Row>

                  <Nav
                    className='nav-tabs-custom border-bottom-0'
                    role='tablist'
                  >
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === '1' },
                          'fw-semibold'
                        )}
                        onClick={() => {
                          toggleTab('1')
                        }}
                        href='#'
                      >
                        Resumen
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === '2' },
                          'fw-semibold'
                        )}
                        onClick={() => {
                          toggleTab('2')
                        }}
                        href='#'
                      >
                        Información de productos
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === '3' },
                          'fw-semibold position-relative'
                        )}
                        onClick={() => {
                          toggleTab('3')
                        }}
                        href='#'
                      >
                        {isSomePending &&
                          hasPermission(
                            permissions,
                            ALL_PERMISSIONS.PUEDE_CAMBIAR_FATURACION
                          ) && (
                            <span
                              class='position-absolute translate-middle bg-danger border border-light rounded-circle blink_own'
                              style={{
                                padding: '6px',
                                top: '10px',
                                left: '12px'
                              }}
                              title='Hay descuentos por confirmar'
                            />
                          )}
                        {isSomeResidue &&
                          hasPermission(
                            permissions,
                            ALL_PERMISSIONS.PUEDE_CAMBIAR_FATURACION
                          ) && (
                            <span
                              class='position-absolute translate-middle bg-warning border border-light rounded-circle blink_own'
                              style={{
                                padding: '6px',
                                top: '10px',
                                left: '90%'
                              }}
                              title='Hay saldos contraentregas pendientes'
                            />
                          )}
                        Precios de productos
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === '4' },
                          'fw-semibold d-flex align-items-center gap-1'
                        )}
                        onClick={() => {
                          toggleTab('4')
                        }}
                        href='#'
                      >
                        <i className='mdi mdi-comment-text-outline' />
                        Comentarios
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === '5' },
                          'fw-semibold d-flex align-items-center gap-1'
                        )}
                        onClick={() => {
                          toggleTab('5')
                        }}
                        href='#'
                      >
                        <i className='mdi mdi-download' />
                        Facturación
                      </NavLink>
                    </NavItem>
                    {STATUS_BILLINGS[billing.status] !==
                      STATUS_BILLINGS.FACTURADO &&
                      hasPermission(
                        permissions,
                        ALL_PERMISSIONS.PUEDE_CAMBIAR_FATURACION
                      ) &&
                      !isSomePending &&
                      !isSomeResidue && (
                        // eslint-disable-next-line react/jsx-indent
                        <NavItem>
                          <Button
                            className='fw-semibold d-flex align-items-center gap-1 border-0'
                            onClick={() => {
                              setModalCloseBilling(true)
                            }}
                            href='#'
                            outline
                            color='danger'
                          >
                            <i className='ri ri-information-line' /> Cerrar
                          </Button>
                        </NavItem>
                      )}

                    {STATUS_BILLINGS[billing.status] ===
                      STATUS_BILLINGS.FACTURADO && (
                      // eslint-disable-next-line react/jsx-indent
                      <NavItem>
                        <Button
                          className='fw-semibold d-flex align-items-center gap-1 border-0'
                          onClick={() => {
                            handleClickDown({
                              name: `Facturacion-${moment(
                                billing.created
                              ).format('DD-MM-YY')}`,
                              file: billing.invoice
                            })
                          }}
                          href='#'
                          outline
                          color='success'
                        >
                          <i className='ri-file-download-line' />
                          Descargar
                        </Button>
                      </NavItem>
                    )}
                  </Nav>
                </CardBody>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <TabContent activeTab={activeTab} className='text-muted'>
              <TabPane tabId='1'>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <div className='text-muted'>
                          <h6 className='mb-3 fw-semibold text-uppercase'>
                            Información del cliente
                          </h6>
                          <Table borderless responsive>
                            <tbody>
                              <tr>
                                <th className='ps-0' scope='row'>
                                  Cédula
                                </th>
                                <td className='text-muted'>
                                  {billing.documentNumber}
                                </td>
                              </tr>
                              <tr>
                                <th className='ps-0' scope='row'>
                                  Cuidad de residencia
                                </th>
                                <td className='text-muted'>
                                  {billing.cityResidence}
                                </td>
                              </tr>
                              <tr>
                                <th className='ps-0' scope='row'>
                                  Dirección
                                </th>
                                <td className='text-muted'>
                                  {billing.addressResidence}
                                </td>
                              </tr>
                              <tr>
                                <th className='ps-0' scope='row'>
                                  Correo electrónico
                                </th>
                                <td className='text-muted'>{billing.email}</td>
                              </tr>
                              <tr>
                                <th className='ps-0' scope='row'>
                                  Teléfono
                                </th>
                                <td className='text-muted'>
                                  {billing.phoneNumber}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          <div className='pt-3 border-top border-top-dashed'>
                            <span className='fw-bold text-dark me-2'>
                              Facturador:
                            </span>
                            {billing?.biller?.firstName ? (
                              <span className='text-uppercase'>
                                {billing?.biller?.firstName}{' '}
                                {billing.biller.lastName}
                              </span>
                            ) : (
                              <span className='text-danger'>No registra</span>
                            )}
                          </div>
                          <div className='pt-3 border-top border-top-dashed mt-4'>
                            <Row>
                              <Col lg={3} sm={6}>
                                <div>
                                  <p className='mb-2 text-uppercase fw-medium'>
                                    Creado:
                                  </p>
                                  <h5 className='fs-15 mb-0'>
                                    {moment(billing.created).format(
                                      'DD MMM [del] YYYY'
                                    )}
                                  </h5>
                                </div>
                              </Col>
                              <Col lg={3} sm={6}>
                                <div>
                                  <p className='mb-2 text-uppercase fw-medium'>
                                    Facturado:
                                  </p>
                                  <h5 className='fs-15 mb-0'>
                                    {billing.billingDate
                                      ? moment(billing.billingDate).format(
                                          'DD MMM [del] YYYY'
                                        )
                                      : 'N/A'}
                                  </h5>
                                </div>
                              </Col>
                              <Col lg={3} sm={6}>
                                <div>
                                  <p className='mb-2 text-uppercase fw-medium'>
                                    Tipo :
                                  </p>
                                  <div
                                    className={`badge ${
                                      QUOTATION_TYPES[
                                        billing.quotation?.quotationType
                                      ] === QUOTATION_TYPES.CONTADO
                                        ? 'bg-info'
                                        : QUOTATION_TYPES[
                                            billing.quotation?.quotationType
                                          ] === QUOTATION_TYPES.CREDITO
                                        ? 'bg-warning'
                                        : 'bg-dark'
                                    } fs-12`}
                                  >
                                    {QUOTATION_TYPES[
                                      billing.quotation?.quotationType
                                    ] || 'N/A'}
                                  </div>
                                </div>
                              </Col>
                              <Col lg={3} sm={6}>
                                <div>
                                  <p className='mb-2 text-uppercase fw-medium'>
                                    Estado :
                                  </p>
                                  <div
                                    className={`badge ${
                                      STATUS_BILLINGS[billing.status] ===
                                      STATUS_BILLINGS.EN_FACTURACION
                                        ? 'bg-warning'
                                        : STATUS_BILLINGS[billing.status] ===
                                          STATUS_BILLINGS.FACTURADO
                                        ? 'bg-success'
                                        : 'bg-dark'
                                    } fs-12`}
                                  >
                                    {STATUS_BILLINGS[billing.status]}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>

                          <div className='pt-3 border-top border-top-dashed mt-4'>
                            <h6 className='mb-3 fw-semibold text-uppercase'>
                              Documentos de la solicitud
                            </h6>
                            <Row className='g-3'>
                              {billing.supportproductbillingSet.edges.map(
                                ({ node }) => (
                                  <Col xxl={4} lg={6} key={node.id}>
                                    <div className='border rounded border-dashed p-2'>
                                      <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0 me-3'>
                                          <div className='avatar-sm'>
                                            <div className='avatar-title bg-light text-secondary rounded fs-24'>
                                              <i className='mdi mdi-file-document-multiple-outline' />
                                            </div>
                                          </div>
                                        </div>
                                        <div className='flex-grow-1 overflow-hidden'>
                                          <h5 className='fs-13 mb-1'>
                                            <div className='text-body text-truncate d-block'>
                                              {node.name}
                                            </div>
                                          </h5>
                                        </div>
                                        <div className='flex-shrink-0 ms-2'>
                                          <div className='d-flex gap-1'>
                                            <button
                                              type='button'
                                              className='btn btn-icon text-muted btn-sm fs-18'
                                              onClick={() => {
                                                handleClickDown({
                                                  name: node.name,
                                                  file: node.file
                                                })
                                              }}
                                            >
                                              <i className='ri-download-2-line' />
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                )
                              )}
                            </Row>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  {hasPermission(
                    permissions,
                    ALL_PERMISSIONS.PUEDE_CAMBIAR_FATURACION
                  ) &&
                    billing.status !== STATUS_BILLINGS.FACTURADO && (
                      <Col lg={12}>
                        <div className='d-flex align-items-center gap-1 justify-content-end my-4 p-2'>
                          <Button
                            color='danger'
                            className='d-flex align-items-center gap-1'
                            onClick={onOpen}
                          >
                            <i className='mdi mdi-alert text-light fs-4' />
                            Eliminar facturación
                          </Button>
                        </div>
                      </Col>
                    )}
                </Row>
              </TabPane>
              <TabPane tabId='2'>
                <Card>
                  <CardBody>
                    <div className='d-flex align-items-center mb-4'>
                      <h5 className='card-title flex-grow-1'>
                        Información de productos
                      </h5>
                    </div>
                    <Row>
                      {billing.productauxbillingSet.edges.map(({ node }) => (
                        <Col lg={12} key={node.id} className='mt-4'>
                          <div className='table-responsive table-card'>
                            <Table className='table-borderless align-middle mb-0'>
                              <thead className='table-light'>
                                <tr>
                                  <th scope='col'>{node.name}</th>
                                  <th scope='col' />
                                </tr>
                              </thead>
                              <tbody>
                                {!node.documents && (
                                  <tr>
                                    <td colSpan={2}>
                                      <div>
                                        <Alert
                                          color='danger'
                                          className='mb-0 d-flex align-items-center gap-2'
                                        >
                                          <i className='mdi mdi-alert-circle fs-3' />
                                          Este producto no incluye documentos
                                        </Alert>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                                {node.fieldformSet.edges.map(
                                  ({ node: nodeForm }) => (
                                    <tr key={nodeForm.id}>
                                      <td>{nodeForm.name}</td>
                                      <td>{nodeForm.value}</td>
                                    </tr>
                                  )
                                )}
                                {node.supportproductbillingSet.edges.map(
                                  ({ node: nodeSupport }) => (
                                    <tr key={nodeSupport.name}>
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          <div className='avatar-sm'>
                                            <div className='avatar-title bg-light text-secondary rounded fs-24'>
                                              <i className='ri-folder-zip-line' />
                                            </div>
                                          </div>
                                          <div className='ms-3 flex-grow-1'>
                                            <h5 className='fs-14 mb-0'>
                                              <span className='text-dark'>
                                                {nodeSupport.name}
                                              </span>
                                            </h5>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <Button
                                          outline
                                          onClick={() => {
                                            handleClickDown({
                                              file: nodeSupport.file,
                                              name: nodeSupport.name
                                            })
                                          }}
                                        >
                                          <i className='ri-download-2-line' />
                                        </Button>
                                      </td>
                                    </tr>
                                  )
                                )}
                                {node.supportproductbillingSet.edges.length ===
                                  0 && (
                                  // eslint-disable-next-line react/jsx-indent
                                  <tr>
                                    <td>
                                      <Alert color='info'>
                                        No tiene soportes
                                      </Alert>
                                    </td>
                                    <td />
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId='3'>
                <Card>
                  <CardBody>
                    <h5 className='card-title mb-2'>Información de precios</h5>
                    <div className='mt-4'>
                      <UncontrolledAccordion defaultOpen='1'>
                        {billing.productauxbillingSet.edges.map(
                          ({ node }, idx) => (
                            <AccordionItem key={node.id}>
                              <AccordionHeader targetId={idx + 1 + ''}>
                                {node.name}
                              </AccordionHeader>
                              <AccordionBody accordionId={idx + 1 + ''}>
                                <Table responsive>
                                  <tbody>
                                    {!node.documents && (
                                      <tr>
                                        <td colSpan={3}>
                                          <div>
                                            <Alert
                                              color='danger'
                                              className='mb-0 d-flex align-items-center gap-2'
                                            >
                                              <i className='mdi mdi-alert-circle fs-3' />
                                              Este producto no incluye
                                              documentos
                                            </Alert>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                    <tr>
                                      <th>Precio base</th>
                                      <td>{parsePrice(node.basePrice)}</td>
                                      <td />
                                    </tr>
                                    <tr>
                                      <th>Otros precios</th>
                                      <td>
                                        {getPrice(node.priceauxbillingSet)}
                                      </td>
                                      <td>
                                        {node.priceauxbillingSet.edges.length >
                                          0 && (
                                          <button
                                            style={{
                                              border: 0,
                                              padding: 0,
                                              background: 'none'
                                            }}
                                            onClick={() => {
                                              setModalViewPrices(true)
                                              setInfoPrices({
                                                type: `otros precios para ${node.name}`,
                                                prices:
                                                  node.priceauxbillingSet.edges.map(
                                                    ({ node: nodePrices }) => ({
                                                      id: nodePrices.id,
                                                      name: nodePrices.name,
                                                      value: nodePrices.value,
                                                      type: nodePrices.priceType
                                                    })
                                                  )
                                              })
                                            }}
                                          >
                                            <i className='mdi mdi-table-eye fs-4 text-primary' />
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Descuentos</th>
                                      <td className='text-danger'>
                                        - {getPrice(node.discountauxbillingSet)}
                                      </td>
                                      <td>
                                        {node.discountauxbillingSet.edges
                                          .length > 0 && (
                                          <button
                                            style={{
                                              border: 0,
                                              padding: 0,
                                              background: 'none'
                                            }}
                                            onClick={() => {
                                              setModalViewPrices(true)
                                              setInfoPrices({
                                                type: `descuentos de ${node.name}`,
                                                prices:
                                                  node.discountauxbillingSet.edges.map(
                                                    ({ node: nodePrices }) => ({
                                                      id: nodePrices.id,
                                                      name: nodePrices.name,
                                                      value: nodePrices.value,
                                                      type: nodePrices.discountType,
                                                      pending:
                                                        nodePrices.pending
                                                    })
                                                  ),
                                                product: node.name
                                              })
                                            }}
                                          >
                                            <i className='mdi mdi-table-eye fs-4 text-primary' />
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>
                                        <span
                                          style={{
                                            display: 'block',
                                            width: '100%',
                                            textAlign: 'center'
                                          }}
                                          className='text-uppercase'
                                        >
                                          Total
                                        </span>
                                      </th>
                                      <td className='fw-bold'>
                                        {getFullTot({
                                          base: node.basePrice,
                                          discounts: node.discountauxbillingSet,
                                          prices: node.priceauxbillingSet
                                        })}
                                      </td>
                                      <td />
                                    </tr>
                                  </tbody>
                                </Table>
                                {node?.residueSet?.edges?.length > 0 && (
                                  <div className='d-flex align-items-center justify-content-center flex-column'>
                                    <div className='d-flex align-items-center gap-2'>
                                      <i className='fs-4 mdi mdi-alert' />
                                      Este producto tiene un saldo contraentrega
                                      {node?.residueSet?.edges[0].node
                                        .locked ? (
                                        <span className='text-danger'>
                                          {' '}
                                          pendiente
                                        </span>
                                      ) : (
                                        ''
                                      )}{' '}
                                      de{' '}
                                      <span
                                        className='fs-5'
                                        style={{
                                          fontWeight: 'bolder',
                                          color: 'black'
                                        }}
                                      >
                                        {parsePrice(
                                          node?.residueSet?.edges[0].node.value
                                        )}
                                      </span>
                                      {hasPermission(
                                        permissions,
                                        ALL_PERMISSIONS.PUEDE_CAMBIAR_RESIDUE
                                      ) &&
                                        node?.residueSet?.edges[0].node
                                          .locked && (
                                          <Button
                                            className='d-flex align-items-center gap-2 rounded-pill'
                                            outline
                                            size='sm'
                                            color='danger'
                                            onClick={() => {
                                              onOpenAddResidue()
                                              setInfoResidue({
                                                name: node.name,
                                                residueId: parseId(
                                                  node?.residueSet?.edges[0]
                                                    .node.id
                                                ),
                                                value:
                                                  node?.residueSet?.edges[0]
                                                    .node.value
                                              })
                                            }}
                                          >
                                            <i className='mdi mdi-check' />
                                            Aprobar
                                          </Button>
                                        )}
                                    </div>
                                    {node?.residueSet?.edges[0].node
                                      .comment && (
                                      <div>
                                        <small>
                                          *
                                          {
                                            node?.residueSet?.edges[0].node
                                              .comment
                                          }
                                        </small>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </AccordionBody>
                            </AccordionItem>
                          )
                        )}
                      </UncontrolledAccordion>
                    </div>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId='4'>
                <Card>
                  <CardBody>
                    <div>
                      <div className='d-sm-flex align-items-center'>
                        <h5 className='card-title flex-grow-1 mb-0'>
                          Comentarios
                        </h5>
                        <div className='flex-shrink-0 mt-2 mt-sm-0'>
                          <button
                            className='btn btn-soft-info btn-sm mt-2 mt-sm-0'
                            onClick={() => setModalComment(true)}
                          >
                            <i className='bx bx-comment align-middle me-1' />{' '}
                            Agregar comentario
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='profile-timeline'>
                      {orderComments.map(({ date, comments }) => (
                        <div className='accordion-item' key={date}>
                          <div className='' id='headingOne'>
                            <div className='accordion-button p-2 shadow-none'>
                              <div className='d-flex align-items-center'>
                                <div className='flex-shrink-0 avatar-xs'>
                                  <div className='avatar-title bg-success rounded-circle'>
                                    <i className='bx bx-comment-dots' />
                                  </div>
                                </div>
                                <div className='flex-grow-1 ms-3'>
                                  <h6 className='fs-15 mb-0 fw-semibold'>
                                    {moment(
                                      new Date(
                                        `20${date.split(' ')[2]}-${
                                          date.split(' ')[1]
                                        }-${date.split(' ')[0]}
                                    `
                                      )
                                    ).format('DD MMM [del] YYYY')}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id='collapseOne'
                            className='accordion-collapse'
                            // isOpen={col1}
                          >
                            {comments.map(
                              ({ comment, date: dateComment, user }, i) => (
                                <div
                                  className='accordion-body ms-2 ps-5 pt-0 mb-2'
                                  key={i}
                                >
                                  <h6 className='mb-1'>{comment}</h6>
                                  <div className='d-flex gap-1 align-items-center'>
                                    <p className='text-muted mb-0'>
                                      {moment(dateComment).format('hh:mm A')}
                                    </p>
                                    <span
                                      className='text-uppercase fs-bold d-flex align-items-center gap-1'
                                      style={{ color: '#4c76a1' }}
                                    >
                                      -
                                      <i className='ri ri-user-3-line' />
                                      {user}
                                    </span>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      ))}
                      {orderComments.length > 0 && (
                        <div className='accordion-item'>
                          <div className='' id='headingOne'>
                            <div className='accordion-button p-2 shadow-none'>
                              <div className='d-flex align-items-center'>
                                <div className='flex-shrink-0 avatar-xs'>
                                  <div className='avatar-title bg-success rounded-circle'>
                                    <i className='bx bx-calendar-minus' />
                                  </div>
                                </div>
                                <div className='flex-grow-1 ms-3'>
                                  <h6 className='fs-15 mb-0 fw-semibold'>
                                    Inicio
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {orderComments.length === 0 && (
                        <div
                          className='d-flex justify-content-center align-items-center'
                          style={{ height: '20vh' }}
                        >
                          No hay comentarios
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </TabPane>
              <TabDownBillingInfo billing={billing} />
            </TabContent>
          </Col>
        </Row>
      </Container>

      <Modal isOpen={modalCloseBilling} toggle={toggleModalCloseBilling}>
        <ModalHeader
          toggle={toggleModalCloseBilling}
          className='bg-soft-info p-3'
        >
          Cerrar facturación
        </ModalHeader>
        <Form onSubmit={formik.handleSubmit}>
          <ModalBody>
            <Row>
              {/* <Col lg={12}>
                <Label>Fecha de facturación</Label>
                <Input
                  type='date'
                  name='billingDate'
                  value={formik.values.billingDate}
                  onChange={formik.handleChange}
                  invalid={Boolean(
                    formik.errors.billingDate && formik.touched.billingDate
                  )}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.billingDate && formik.errors.billingDate && (
                  <FormFeedback>{formik.errors.billingDate}</FormFeedback>
                )}
              </Col> */}
              <Col lg={12} className='mt-2'>
                <Label>Factura</Label>
                <Input
                  type='file'
                  name='invoice'
                  onChange={(e) => {
                    // console.log('e.target.files[0]', e.target.files[0])
                    formik.setFieldValue('invoice', e.target.files[0] || null)
                  }}
                  onBlur={formik.handleBlur}
                  invalid={Boolean(
                    formik.errors.invoice && formik.touched.invoice
                  )}
                />
                {formik.touched.invoice && formik.errors.invoice && (
                  <FormFeedback>{formik.errors.invoice}</FormFeedback>
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className='d-flex align-items-center gap-2'>
            <Button
              color='dark'
              type='button'
              outline
              onClick={() => {
                setModalCloseBilling(false)
              }}
            >
              Cancelar
            </Button>
            <Button
              color='danger'
              type='submit'
              className='d-flex align-items-center gap-1'
              disabled={loadingClose}
            >
              {loadingClose && <Spinner size='sm' color='light' />}
              Cerrar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={modalViewPrices}
        toggle={toggleModalViewPrices}
        onClosed={() => setInfoConfirmDiscount(null)}
      >
        <ModalHeader
          toggle={toggleModalViewPrices}
          className='bg-soft-info p-3'
        >
          Detalle de {infoPrices.type}
        </ModalHeader>
        <ModalBody>
          <Table responsive>
            <thead>
              <th>Nombre</th>
              <th>tipo</th>
              <th>valor</th>
              <th />
            </thead>
            <tbody>
              {infoPrices.prices.map(
                ({ id: idPrice, name, value, type, pending }) => (
                  <tr key={idPrice}>
                    <td className='text-uppercase'>{name}</td>
                    <td>{type.replace(/_/g, ' ')}</td>
                    <td>{parsePrice(value)}</td>
                    {pending &&
                    hasPermission(
                      permissions,
                      ALL_PERMISSIONS.PUEDE_APROBAR_DESCUENTO_FATURACION
                    ) ? (
                      <td>
                        <button
                          className='bg-soft-danger text-danger border-0 rounded-pill'
                          title='Aprobar descuento'
                          onClick={() => {
                            setInfoConfirmDiscount({
                              billingId: id,
                              discountId: idPrice,
                              value,
                              productName: infoPrices.product || '',
                              discountName: name
                            })
                            onOpenConfirmDiscount()
                          }}
                        >
                          <i className='mdi mdi-cash-check fs-5' />
                        </button>
                      </td>
                    ) : (
                      <td />
                    )}
                  </tr>
                )
              )}
              <tr>
                <td />
                <th>TOTAL</th>
                <td>{getSubTot(infoPrices.prices)}</td>
                <td />
              </tr>
            </tbody>
          </Table>
        </ModalBody>
      </Modal>

      <Modal
        id='showModalComment'
        isOpen={modalComment}
        toggle={toggleComment}
        centered
        size='lg'
      >
        <ModalHeader className='bg-soft-info p-3' toggle={toggleComment}>
          Agregar comentario
        </ModalHeader>
        <Form
          className='tablelist-form'
          onSubmit={(e) => {
            e.preventDefault()
            formComment.handleSubmit()
            return false
          }}
        >
          <ModalBody>
            <input type='hidden' id='id-field' />
            <Row className='g-3'>
              <Col lg={12}>
                <FormGroup>
                  <Label htmlFor='comment' className='form-label'>
                    Comentario
                  </Label>
                  <Input
                    rows='3'
                    name='comment'
                    id='comment'
                    className='form-control'
                    placeholder='Ingrese un comentario'
                    type='textarea'
                    onChange={formComment.handleChange}
                    onBlur={formComment.handleBlur}
                    value={formComment.values.comment}
                    invalid={Boolean(
                      formComment.touched.comment && formComment.errors.comment
                    )}
                  />
                  {formComment.errors.comment ? (
                    <FormFeedback type='invalid'>
                      {formComment.errors.comment}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className='hstack gap-2 justify-content-end'>
              <Button
                color='light'
                onClick={() => {
                  setModalComment(false)
                }}
              >
                Cancelar{' '}
              </Button>
              <Button
                type='submit'
                color='success'
                id='add-btn'
                disabled={loadingCreate}
              >
                {loadingCreate && <Spinner size='sm' color='light' />}
                Agregar comentario
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={isOpen} toggle={onClose} centered>
        <ModalBody>
          <div className='d-flex align-items-center justify-content-center'>
            <lord-icon
              src='https://cdn.lordicon.com/ywkmznae.json'
              trigger='loop'
              colors='primary:#911710,secondary:#e8e230'
              style={{ width: '120px', height: '120px' }}
            />
          </div>
          <p className='p-4 fs-2 d-flex align-items-center gap-2'>
            ¿Seguro que desea eliminar esta facturación?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} color='light'>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              gqlDeleteBilling({
                variables: {
                  input: { billingId: id }
                }
              })
            }}
            color='danger'
            disabled={loadingDelete}
            className='d-flex align-items-center gap-1'
          >
            {loadingDelete && <Spinner size='sm' color='light' />}
            Eliminar
          </Button>
        </ModalFooter>
      </Modal>

      {infoConfirmDiscount && (
        <ModalConfirmDiscount
          info={infoConfirmDiscount}
          isOpen={isOpenConfirmDiscount}
          onClose={onCloseConfirmDiscount}
          refetch={refetch}
          closeParentModal={() => setModalViewPrices(false)}
        />
      )}
      {infoResidue && (
        <ModalConfirmResidue
          info={infoResidue}
          isOpen={isOpenAddResidue}
          onClose={onCloseAddResidue}
          refetch={refetch}
        />
      )}
    </div>
  )
}

export default OneBilling
