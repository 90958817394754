import { useQuery } from '@apollo/client'
import React, { useEffect } from 'react'
import { GET_PAYMENTS_CAPITAL_OBL } from '../../../graphql/queries/getCreditsWallet'
import { parseId, parsePrice } from '../../../helpers'
import { toast } from 'react-toastify'
import { ListGroup, ListGroupItem, Spinner } from 'reactstrap'
import imgEmpty from '../../../assets/images/totalsales/empty.svg'
import moment from 'moment'

export const TabViewAbonosCapitalInactive = ({ obligationId }) => {
  const { data, loading, error } = useQuery(GET_PAYMENTS_CAPITAL_OBL, {
    variables: { obligationId: parseId(obligationId) },
    fetchPolicy: 'no-cache'
  })
  useEffect(() => {
    if (!error) return
    toast.error(`Error: ${error.message}`)
  }, [error])

  if (loading || !data) {
    return (
      <div
        className='d-flex align-items-center justify-content-center'
        style={{ height: '40vh' }}
      >
        <Spinner />
      </div>
    )
  }
  /** @type {{edges: {node:{id: string, created: string, value: string}}[]}} */
  const { edges } = data.listPaymentCapital
  if (edges.length === 0) {
    return (
      <div className='w-100 d-flex flex-column justify-content-center align-items-center gap-1'>
        <p className='text-center'>
          No hay abonos de capital para esta obligación
        </p>
        <div>
          <img src={imgEmpty} style={{ width: '100%', maxWidth: '400px' }} />
        </div>
      </div>
    )
  }

  return (
    <div className='d-flex align-items-center justify-content-center w-100'>
      <div style={{ maxWidth: '400px', width: '100%' }}>
        <ListGroup className='mb-1'>
          {edges.map(({ node }, idx) => (
            <ListGroupItem key={node.id}>
              <div className='d-flex align-items-center'>
                <div className='flex-grow-1'>
                  <div className='d-flex'>
                    <div className='flex-shrink-0 ms-2'>
                      <h6 className='fs-14 mb-0'>Abono {idx + 1}</h6>
                      <small className='text-muted'>
                        {moment(node.created).format('DD MMMM [de] YYYY')}
                      </small>
                    </div>
                  </div>
                </div>
                <div className='flex-shrink-0'>
                  <span className='text-danger fs-5 fw-semibold'>
                    {parsePrice(node.value)}
                  </span>
                </div>
              </div>
            </ListGroupItem>
          ))}
        </ListGroup>
      </div>
    </div>
  )
}
