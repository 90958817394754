import { useMutation } from '@apollo/client'
import React, { useEffect } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner
} from 'reactstrap'
import { CHANGE_CREDITO_INICIAL } from '../../../graphql/mutations/creditMutations'
import { toast } from 'react-toastify'
import { parseId } from '../../../helpers'

export const ModalChangeOldData = ({ isOpen, creditId, refetch, onClose }) => {
  const [
    gqlChangeOldData,
    { data: dataChange, loading: loadingChange, error: errorChange }
  ] = useMutation(CHANGE_CREDITO_INICIAL)

  useEffect(() => {
    if (!dataChange) return
    if (dataChange?.changeOldUser?.success) {
      toast.success('Crédito reasignado correctamente')
      refetch()
      onClose()
    } else if (dataChange?.changeOldUser?.errors) {
      toast.error(
        'ERROR: ' + dataChange?.changeOldUser?.errors.message ||
          'No se pudo reasignar crédito'
      )
    }
  }, [dataChange])

  useEffect(() => {
    if (!errorChange) return
    if (errorChange) {
      toast.error(`ERROR: ${errorChange.message}`)
    }
  }, [errorChange])

  const handleChangeOldData = () => {
    if (!creditId) return
    gqlChangeOldData({
      variables: {
        input: {
          id: parseId(creditId)
        }
      }
    })
  }
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalHeader className='bg-soft-info p-4' toggle={onClose}>
        Reasignar crédito
      </ModalHeader>
      <ModalBody>
        <p className='fw-semibold fs-4'>
          ¿Está seguro que desea reasignar este crédito al usuario inicial?
        </p>
      </ModalBody>
      <ModalFooter className='d-flex align-items-center justify-content-center gap-2'>
        <Button color='light' className='flex-1 text-center' onClick={onClose}>
          No
        </Button>
        <Button
          color='danger'
          className='flex-1 text-center d-flex gap-1 align-items-center justify-content-center'
          onClick={handleChangeOldData}
        >
          {loadingChange && <Spinner size='sm' color='light' />}
          SI
        </Button>
      </ModalFooter>
    </Modal>
  )
}
